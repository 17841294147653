import { DICTONARY } from "const/Dictonary";

export const FormateaInfoResumen = transaccion => {
  const { residuo, tipoTransporte, sucursalTransaccion, tipoTratamiento } =
    transaccion;
  const { cantidad, formatoEntrega, tipoResiduo, sucursal } = residuo;
  const { nombreTipo } = tipoResiduo;
  const { empresa } = sucursal;
  const { nombreEmpresa } = empresa;

  return {
    cantidad,
    formatoEntrega,
    nombreTipo,
    nombreEmpresa,
    empresa,
    tipoTransporte,
    tipoTratamiento,
    sucursalTransaccion
  };
};

export const ObtieneSucursalesTransaccion = (
  codigoTransaccion,
  sucursalTransaccion,
  transaccion
) => {
  if (!transaccion) return;
  if (!codigoTransaccion) return;
  if (!sucursalTransaccion) return;

  const sucursalesTransaccion = transaccion.sucursalTransaccion;

  const sucursalOferente = sucursalesTransaccion.find(
    ({ nombreRol }) => nombreRol === DICTONARY.ROL.GENERADOR.NOMBRE
  );
  const sucursalDemandante = sucursalesTransaccion.find(
    ({ nombreRol }) => nombreRol === DICTONARY.ROL.RECEPTOR.NOMBRE
  );
  const sucursalAsesor = sucursalesTransaccion.find(
    ({ nombreRol }) => nombreRol === DICTONARY.ROL.ASESOR.NOMBRE
  );
  const sucursalTransportista = sucursalesTransaccion.find(
    ({ nombreRol }) => nombreRol === DICTONARY.ROL.TRANSPORTISTA.NOMBRE
  );
  const sucursalGestor = sucursalesTransaccion.find(
    ({ nombreRol }) => nombreRol === DICTONARY.ROL.GESTOR.NOMBRE
  );

  return {
    sucursalTransaccion,
    sucursalOferente,
    sucursalDemandante,
    sucursalAsesor,
    sucursalTransportista,
    sucursalGestor
  };
};
