import { useHistory } from 'react-router-dom';
import { RUTAS } from 'const/Rutas';
import { useState } from 'react';
import { useSubmitRecusosAdquiridos } from './useSubmitRecusosAdquiridos';
import { AlertaError } from 'utils/Alertas';

export const useHelpersRecursosAdquiridos = () => {
  const navigate = useHistory();

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  // PARA EL FUNCIONAMIENTO DEL MENSAJE DE CONFIRMACIÓN
  const handleClickOpen = (row, table) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedRow({});
    setOpen(false);
  };
  const { handleEliminarRecursoAdquirido } = useSubmitRecusosAdquiridos();

  const handleClickIngresar = () => {
    navigate.push(`${RUTAS.GESTIONA_RECURSO_ADQUIRIDO}/ingresar`, {
      formulario: { tipo: 'ingresar' }
    });
  };

  const handleClickEditar = (row, table) => {
    const original = row.original;

    // Se verifica si original y original.codigoResiduo no son nulos ni indefinidos
    if (!original || !original.codigoRecursoAdquirido) {
      // Si alguno de los dos es nulo o indefinido
      // se muestra un mensaje de error en la pantalla
      AlertaError(
        'Ha ocurrido un error al tratar de editar el Recurso adquirido: no es un Recurso válido.'
      );
      // Se devuelve el control inmediatamente y no se hace nada más
      return;
    }

    navigate.push(
      `${RUTAS.GESTIONA_RECURSO_ADQUIRIDO}/editar/${original.codigoRecursoAdquirido}`,
      { formulario: { tipo: 'editar' } }
    );
  };

  // LÓGICA PARA ELIMINAR UN RSD
  const handleClickEliminar = (
    row,
    recursosAdquiridos,
    setRecursosAdquiridos,
    setOpen,
    cargaRecursosAdquiridos
  ) => {
    const original = row.original;
    // Se verifica si original y original.codigoResiduo no son nulos ni indefinidos
    if (!original || !original.codigoRecursoAdquirido) {
      // Si alguno de los dos es nulo o indefinido
      // se muestra un mensaje de error en la pantalla
      AlertaError(
        'Ha ocurrido un error al tratar de eliminar el Recurso adquirido: no es un Recurso adquirido válido.'
      );
      // Se devuelve el control inmediatamente y no se hace nada más
      return;
    }
    const codigoRecursoAdquirido = original.codigoRecursoAdquirido;
    // Hooks
    handleEliminarRecursoAdquirido(
      codigoRecursoAdquirido,
      cargaRecursosAdquiridos
    );
    setRecursosAdquiridos(
      recursosAdquiridos.filter(
        r => r.codigoRecursoAdquirido !== codigoRecursoAdquirido
      )
    );
    // Eliminación de Residuo
    // eliminarResiduo(codigoResiduo, residuos, setResiduos,cargaResiduos);
    setOpen(false);
  };

  const handleClickDuplicar = (row, cargaRecursos) => {
    const original = row.original;
    // Se verifica si original y original.codigoResiduo no son nulos ni indefinidos
    if (!original || !original.codigoRecursoAdquirido) {
      // Si alguno de los dos es nulo o indefinido
      // se muestra un mensaje de error en la pantalla
      AlertaError(
        'Ha ocurrido un error al tratar de duplicar el Recurso asquirido :  no es un Recurso válido.'
      );
      // Se devuelve el control inmediatamente y no se hace nada más
      return;
    }
    const codigoRecursoAdquirido = original.codigoRecursoAdquirido;

    if (codigoRecursoAdquirido) {
      navigate.push({
        pathname: `${RUTAS.GESTIONA_RECURSO_ADQUIRIDO}/duplicar/${original.codigoRecursoAdquirido}`,
        state: {
          codigoResiduo: codigoRecursoAdquirido,
          original: original,
          formulario: { tipo: 'duplicar' }
        }
      });
    }
  };
  return {
    handleClickIngresar,
    handleClickEditar,
    handleClickEliminar,
    handleClickDuplicar,
    handleClickOpen,
    handleClose,
    setOpen,
    open,
    selectedRow
  };
};
