import Close from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden
} from '@mui/material';
import AlertComponent from 'components/design-system/Alert/Alert';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';

export const DialogoEliminarResiduo = ({
  isOpen,
  setIsOpen,
  handleClickEliminar,
  registroEliminar,
  recargarResiduos,
  isEliminando
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(null)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        className="border-b border-neutral-90"
      >
        <Heading type="h3" className="mb-0">
          Confirmación de eliminación
        </Heading>
      </DialogTitle>
      <DialogContent className="p-5">
        <DialogContentText id="alert-dialog-description" className="pt-4">
          <Text>
            ¿Estás seguro que deseas eliminar completamente el siguiente residuo
          </Text>
          <div className="my-4">
            <div className="flex items-center">
              <Heading type="h5" className="pr-10 mb-0 truncate">
                Código {registroEliminar?.original?.codigo} |{' '}
                {registroEliminar?.original.residuo}
              </Heading>
            </div>
          </div>
          <AlertComponent type="error">
            Esta operación es irreversible y{' '}
            <b>el registro quedará completamente eliminado de la plataforma</b>
          </AlertComponent>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="border-t border-neutral-90 p-4 justify-between">
        <ButtonComponent
          type="secondary"
          onClick={() => setIsOpen(null)}
          className="justify-center"
        >
          <Hidden smDown>Cancelar</Hidden>
          <Hidden smUp>
            <Close />
          </Hidden>
        </ButtonComponent>
        <ButtonComponent
          type="primary"
          disabled={isEliminando}
          onClick={() => {
            handleClickEliminar(registroEliminar, setIsOpen, recargarResiduos);
          }}
          autoFocus
          className="w-full xs:w-auto justify-center"
        >
          Confirmar
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};
