import { DICTONARY } from 'const/Dictonary';
import { useEffect, useState } from 'react';
import commons from 'services/commons';

export const useRellenosSanitarios = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [rellenosSanitarios, setRellenosStanitarios] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    commons
      .rellenosSanitarios(
        {
          totalPagina: DICTONARY.PAGINACION.ALL_DATA
        },
        DICTONARY.INTERCEPTOR.DESACTIVADO
      )
      .then(response => {
        if (response.data.error) {
          throw Error('No se ha podido cargar los rellenos sanitarios');
        }
        setIsLoading(false);
        setRellenosStanitarios(response.data.content);
      })
      .catch(error => {
        setIsLoading(false);
        setRellenosStanitarios([]);
      });
  }, []);

  return {
    rellenosSanitarios
  };
};
