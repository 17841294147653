import { DICTONARY } from 'const/Dictonary';
import { OrdenarAscendente } from 'helpers/manejoArreglos';
import { useEffect, useState } from 'react';
import residuosServices from 'services/residuos';

export const useResiduos = tipoResiduoSeleccionados => {
  const [residuos, setResiduos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const initValues = async () => {
    setIsLoading(true);
    residuosServices
      .tipoResiduos({ 
        totalPagina: DICTONARY.PAGINACION.DEFAULT,
        nivel: DICTONARY.TIPO_RESIDUOS.NIVEL_1
      })
      .then(response => {
        const data = OrdenarAscendente(
          response.data.content,
          'nombreTipo',
          'string'
        );

        setResiduos(
          data.map(residuo => {
            return {
              codigoTipoResiduo: residuo.codigoTipoResiduo,
              nombreTipo: residuo.nombreTipo,
              checked: tipoResiduoSeleccionados.includes(
                residuo.codigoTipoResiduo
              )
            };
          })
        );

        setIsLoading(false);
      })
      .catch(err => {
        console.error('error servicio tipoResiduos: ', err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    initValues();
  }, []);

  return {
    residuos,
    isLoading
  };
};
