import { useContext, useEffect } from 'react';
import Search from '@mui/icons-material/Search';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import ButtonComponent from 'components/design-system/Button/Button';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
import InputComponent from 'components/design-system/input/Input';
import { Form, Formik } from 'formik';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import { schemaMatchManual } from '../../schema/schemaMatchManual';
import { MatchContext } from '../../context/MatchContext';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Hidden } from '@mui/material';
import { DatePickerComponent } from 'components/design-system/DatePicker/DatePicker';

/**
 * @name PreviaMatchFormik
 * Formulaio de vista previa de match.
 */
export const PreviaMatchFormik = () => {
  const {
    getTipodeResiduos,
    listaTipoResiduos,
    listaTipoRecursos,
    handleMatchManual,
    handleMatchSinNegocio
  } = useContext(MatchContext);

  useEffect(() => {
    getTipodeResiduos();
  }, []);

  const today = new Date();
  const todayPlusMonth = new Date(today.setMonth(today.getMonth() + 1));
  const initialValues = {
    codigosTipoResiduo: '',
    codigosRecurso: '',
    fechaDisponibilidadInicio: new Date(),
    fechaDisponibilidadFin: todayPlusMonth,
    busquedaExtendida: false
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={false}
      validationSchema={schemaMatchManual}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitting(true);
          handleMatchManual(values);
          handleMatchSinNegocio();
        } catch (error) {
          console.log('error >>', error);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleChange,
        isSubmitting
      }) => (
        <Form id="previaMatch">
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
              <AutocompleteComponent
                name="codigosTipoResiduo"
                options={listaTipoResiduos || []}
                value={values.codigosTipoResiduo}
                getOptionLabel={option => (option ? option.nombreTipo : '')}
                clearOnEscape={true}
                openOnFocus={true}
                accesor={'nombreTipo'}
                noOptionsText="Sin opciones"
                onBlur={() => setFieldTouched('codigosTipoResiduo')}
                onChange={(ev, tipoResiduo, reason) => {
                  let data = {
                    target: { name: 'codigosTipoResiduo', value: tipoResiduo }
                  };
                  if (reason === 'clear') {
                    data.target.value = '';
                  }
                  handleChange(data);
                }}
              >
                <InputComponent
                  className="h-9"
                  estado={
                    errors.codigosTipoResiduo
                      ? { tipo: 'error', mensaje: errors.codigosTipoResiduo }
                      : null
                  }
                  fullWidth
                  label={<LabelComponent>RESIDUOS</LabelComponent>}
                  touched={touched.codigosTipoResiduo ? true : false}
                />
              </AutocompleteComponent>
            </div>
            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
              <AutocompleteComponent
                name="codigosRecurso"
                accesor="nombreRecurso"
                options={listaTipoRecursos || []}
                value={values.codigosRecurso}
                getOptionLabel={option => (option ? option.nombreRecurso : '')}
                clearOnEscape={true}
                openOnFocus={true}
                noOptionsText="Sin opciones"
                onBlur={() => setFieldTouched('codigosRecurso')}
                touched={touched.codigosRecurso ? true : false}
                onChange={(ev, tipoRecurso, reason) => {
                  let data = {
                    target: { name: 'codigosRecurso', value: tipoRecurso }
                  };

                  if (reason === 'clear') {
                    data.target.value = '';
                  }
                  handleChange(data);
                }}
              >
                <InputComponent
                  className="h-9"
                  fullWidth
                  label={<LabelComponent>RECURSOS</LabelComponent>}
                  touched={touched.codigosRecurso ? true : false}
                  estado={
                    errors.codigosRecurso
                      ? { tipo: 'error', mensaje: errors.codigosRecurso }
                      : null
                  }
                />
              </AutocompleteComponent>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <div className="grid grid-cols-12 gap-4">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={es}
                >
                  <div className="col-span-6 sm:col-span-4">
                    <DesktopDatePicker
                      className="mb-4"
                      disabled={true}
                      onChange={() => {}}
                      label={<LabelComponent>FECHA INICIAL</LabelComponent>}
                      inputFormat="dd/MM/yyyy"
                      defaultValue={values.fechaDisponibilidadInicio}
                      value={values.fechaDisponibilidadInicio}
                      slots={{ textField: DatePickerComponent }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-4">
                    <DesktopDatePicker
                      disabled={true}
                      onChange={() => {}}
                      label={<LabelComponent>FECHA TÉRMINO</LabelComponent>}
                      inputFormat="dd/MM/yyyy"
                      defaultValue={values.fechaDisponibilidadFin}
                      value={values.fechaDisponibilidadFin}
                      slots={{ textField: DatePickerComponent }}
                    />
                  </div>
                </LocalizationProvider>
                <div className="col-span-12 sm:col-span-4 sm:pt-7 mb-4 sm:mb-0">
                  <ButtonComponent
                    form="previaMatch"
                    fullWidth
                    className="justify-center"
                    buttonType="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <Hidden lgUp>
                          <Search className="ml-2" />
                        </Hidden>
                        <Hidden lgDown>
                          Buscando... <Search className="ml-2" />
                        </Hidden>
                      </>
                    ) : (
                      <>
                        <Hidden lgUp>
                          <Search className="ml-2" />
                        </Hidden>
                        <Hidden lgDown>
                          Buscar Match <Search className="ml-2" />
                        </Hidden>
                      </>
                    )}
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 lg:gap-5">
            <div className="col-span-6"></div>
            <div className="col-span-12 lg:col-span-6">
              <div className="bg-warning-light rounded px-4 py-2">
                <Text size="S">
                  Opciones de búsqueda adicionales para usuarios que hayan{' '}
                  <b className="text-black">registrado su negocio</b>
                </Text>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
