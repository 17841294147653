import { Divider, Skeleton } from "@mui/material";
import Heading from "components/design-system/Heading/Heading";

export const SkeletonChat = () => {

  const skeletonList = [0, 1, 2, 3, 4, 5, 6]

  return (
    <div className="grid grid-cols-12 min-h-[80vh]">
      <div className="col-span-12 md:col-span-3 lg:col-span-3 border-r border-solid border-r-neutral-90">
        <div className="mb-0 p-4">
          <Heading type="h4" className="my-0 py-0 text-uv-primary-0">Salas de Reunión</Heading>
        </div>
        <Divider />
        {skeletonList.map((u, index) =>
          <div key={index}>
            <div className="p-4">
              <Skeleton
                variant="rectangular"
                className="w-full h-12 rounded relative z-0 min-w-full flex justify-center items-center"
              />
            </div>
            <Divider />
          </div>
        )}
      </div>
      <div className="col-span-12 md:col-span-9 lg:col-span-9 relative">
        <div className="px-4 py-3">
          <Skeleton
            variant="rectangular"
            className="w-full h-8 rounded relative z-0 min-w-full flex justify-center items-center"
          />
        </div>
        <Divider />
        <div className="absolute bottom-0 left-0 right-0">
          <Divider />
          <div className="px-4 py-3">
            <Skeleton
              variant="rectangular"
              className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>
        </div>
      </div>
    </div>
  )
};