import IconComponent from 'components/design-system/icon/Icon';
import Warehouse from '@mui/icons-material/Warehouse';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import LinkComponent from 'components/design-system/Link/Link';
import { TooltipComponent } from 'components/design-system/Tooltip/Tooltip';
import People from '@mui/icons-material/People';
import Insights from '@mui/icons-material/Insights';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Help } from '@mui/icons-material';

import { PersonSearch, Publish } from '@mui/icons-material';
import { RUTAS } from 'const/Rutas';

export const EnlacesRecomendados = ({ codigoEmpresa, isRecicladorBase }) => {
  return (
    <div>
      <div className="text-center my-8">
        <Heading type="h4">¿Que puedo hacer ahora?</Heading>
      </div>

      {isRecicladorBase ? (
        <div className="grid grid-cols-12 gap-y-10 xs:gap-6 mb-10">
          <div className="col-span-12 xs:col-span-6 md:col-span-3 text-center bg-white shadow-md rounded p-4">
            <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px] m-auto mb-2 -mt-8">
              <IconComponent color="primary" className="text-white">
                <Publish className="w-8 h-8" />
              </IconComponent>
            </div>
            <Heading type="h3" className="mb-4">
              Publicar
            </Heading>
            <Text className="mb-4 xl:px-4">
              Ofrecer y/o requerir un residuo en el marketplace
            </Text>
            <LinkComponent href={`${RUTAS.RESIDUOS}`} type="button">
              Administrar
            </LinkComponent>
          </div>

          <div className="col-span-12 xs:col-span-6 md:col-span-3 text-center bg-white shadow-md rounded p-4">
            <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px] m-auto mb-2 -mt-8">
              <IconComponent color="primary" className="text-white">
                <PersonSearch className="w-8 h-8" />
              </IconComponent>
            </div>
            <Heading type="h4" className="mb-4">
              Buscar generadores
              <TooltipComponent
                className="float"
                title={
                  'Negocios que publican ofertas de residuos, subproductos o materias primas secundarias (MPS).'
                }
              >
                <Help className="text-uv-secondary-0" fontSize="small" />
              </TooltipComponent>
            </Heading>
            <Text className="mb-4 xl:px-4">
              Encuentra generadores de residuos para tu negocio
            </Text>
            <LinkComponent href={`${RUTAS.MARKETPLACE_RESIDUOS}`} type="button">
              Administrar
            </LinkComponent>
          </div>

          <div className="col-span-12 xs:col-span-6 md:col-span-3 text-center bg-white shadow-md rounded p-4">
            <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px] m-auto mb-2 -mt-8">
              <IconComponent color="primary" className="text-white">
                <PersonSearch className="w-8 h-8" />
              </IconComponent>
            </div>
            <Heading type="h4" className="mb-4">
              Buscar gestores
              <TooltipComponent
                className=""
                title={
                  'Negocios que conectan con otros actores para la recepción y almacenamiento de residuos, subproductos o materias primas secundarias (MPS) como negocio autorizado.'
                }
              >
                <Help className="text-uv-secondary-0" fontSize="small" />
              </TooltipComponent>
            </Heading>
            <Text className="mb-4 xl:px-4">
              Encuentra gestores de residuos para tu negocio
            </Text>
            <LinkComponent href={`${RUTAS.MARKETPLACE_RESIDUOS}`} type="button">
              Administrar
            </LinkComponent>
          </div>

          <div className="col-span-12 xs:col-span-6 md:col-span-3 text-left bg-white shadow-md rounded p-4 xl:px-6 py-4">
            <div className="h-full flex flex-col items-start justify-center">
              <Heading type="h3" className="mb-4">
                Volver al panel
                <br />
                de administración
              </Heading>
              <LinkComponent
                href={RUTAS.HOME_USER}
                type="button"
                className="pl-2"
              >
                <ChevronLeft className="mr-2" /> Volver
              </LinkComponent>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-12 gap-y-10 xs:gap-6 mb-10">
          <div className="col-span-12 xs:col-span-6 md:col-span-3 text-center bg-white shadow-md rounded p-4">
            <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px] m-auto mb-2 -mt-8">
              <IconComponent color="primary" className="text-white">
                <Warehouse className="w-8 h-8" />
              </IconComponent>
            </div>
            <Heading type="h3" className="mb-4">
              Sucursales
            </Heading>
            <Text className="mb-4 xl:px-4">
              Administra distintas sucursales para tu negocio
            </Text>
            <LinkComponent
              href={`${RUTAS.NEGOCIO}/${codigoEmpresa}/sucursales`}
              type="button"
            >
              Administrar
            </LinkComponent>
          </div>

          <div className="col-span-12 xs:col-span-6 md:col-span-3 text-center bg-white shadow-md rounded p-4">
            <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px] m-auto mb-2 -mt-8">
              <IconComponent color="primary" className="text-white">
                <People className="w-8 h-8" />
              </IconComponent>
            </div>
            <Heading type="h3" className="mb-4">
              Usuarios
            </Heading>
            <Text className="mb-4 xl:px-4">
              Invita nuevos usuarios a participar en tu negocio
            </Text>
            <LinkComponent
              href={`${RUTAS.NEGOCIO}/${codigoEmpresa}/usuarios`}
              type="button"
            >
              Administrar
            </LinkComponent>
          </div>

          <div className="col-span-12 xs:col-span-6 md:col-span-3 text-center bg-white shadow-md rounded p-4">
            <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px] m-auto mb-2 -mt-8">
              <IconComponent color="primary" className="text-white">
                <Insights className="w-8 h-8" />
              </IconComponent>
            </div>
            <Heading type="h3" className="mb-4">
              Indicadores
            </Heading>
            <Text className="mb-4 xl:px-4">
              Administra tus indicadores para conocer el impacto ambiental
            </Text>
            <LinkComponent
              href={`${RUTAS.NEGOCIO}/${codigoEmpresa}/indicadores`}
              type="button"
            >
              Administrar
            </LinkComponent>
          </div>

          <div className="col-span-12 xs:col-span-6 md:col-span-3 text-left bg-white shadow-md rounded p-4 xl:px-6 py-4">
            <div className="h-full flex flex-col items-start justify-center">
              <Heading type="h3" className="mb-4">
                Volver al panel
                <br />
                de administración
              </Heading>
              <LinkComponent
                href={RUTAS.HOME_USER}
                type="button"
                className="pl-2"
              >
                <ChevronLeft className="mr-2" /> Volver
              </LinkComponent>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
