import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
  Tooltip
} from '@mui/material';
import Heading from 'components/design-system/Heading/Heading';
import Table from 'components/design-system/Table/Table';
import Text from 'components/design-system/Text/Text';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { useContext, useEffect, useMemo, useState } from 'react';
import usuariosService from 'services/usuarios';
import { format, parseISO } from 'date-fns';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Email from '@mui/icons-material/Email';
import MailLock from '@mui/icons-material/MailLock';
import Person from '@mui/icons-material/Person';
import AlertComponent from 'components/design-system/Alert/Alert';
import ButtonComponent from 'components/design-system/Button/Button';
import { Link, useParams } from 'react-router-dom';
import { DICTONARY } from 'const/Dictonary';
import { RUTAS } from 'const/Rutas';
import { Add } from '@mui/icons-material';
import { AuthContext } from 'context';
import { AlertaError, AlertaExito } from 'utils/Alertas';

const Usuarios = () => {
  const {
    perfiles: { isSuperAdmin }
  } = useContext(AuthContext);
  const { codigoEmpresa } = useParams();
  const [usuarios, setUsuarios] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(1);
  const [pagination, setPagination] = useState(DICTONARY.PAGINACION.DEFAULT);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);

  const obtenerUsuarios = async () => {
    setIsLoading(true);
    const query = {};
    let correoElectronico = null;
    let nombreSucursal = null;
    let nombreEmpresa = null;

    if (columnFilters) {
      correoElectronico = columnFilters.find(filtro =>
        filtro.id.includes('correoElectronico')
      );
      nombreSucursal = columnFilters.find(filtro =>
        filtro.id.includes('nombreSucursal')
      );
      nombreEmpresa = columnFilters.find(filtro =>
        filtro.id.includes('nombreEmpresa')
      );
    }

    if (correoElectronico) {
      query.correoElectronico = correoElectronico.value;
    }

    if (nombreSucursal) {
      query.nombreSucursal = nombreSucursal.value;
    }

    if (nombreEmpresa) {
      query.nombreEmpresa = nombreEmpresa.value;
    }

    if (!isSuperAdmin) {
      query.codigoEmpresa = codigoEmpresa;
    }

    if (pagination) {
      query.numPagina = pagination.pageIndex;
      query.totalPagina = pagination.pageSize;
    }

    try {
      const response = await usuariosService.getUsuarios(query);
      if (response.status === 200 && response.data) {
        const { content, empty } = response.data;
        if (empty) {
          setUsuarios([]);
          setRowCount(0);
          return;
        } else if (Array.isArray(content)) {
          setUsuarios(content);
          setRowCount(response.data.totalElements);
        }
      }
    } catch (error) {
      setUsuarios([]);
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    obtenerUsuarios();
  }, [pagination.pageIndex, pagination.pageSize, columnFilters]);

  const columnTables = useMemo(
    () => [
      {
        header: 'Código',
        accessorKey: 'codigoUsuario',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Nombre',
        accessorKey: 'nombreUsuario',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Email',
        accessorKey: 'correoElectronico',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: true,
        enableColumnFilter: true
      },
      {
        header: 'Negocio',
        accessorKey: 'sucursal.empresa.nombreEmpresa',
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: true,
        enableColumnFilter: true
      },
      {
        header: 'Sucursal',
        accessorKey: 'sucursal.nombreSucursal',
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: true,
        enableColumnFilter: true
      },
      {
        header: 'Fecha de creación',
        accessorKey: 'fechaCreacionUsuario',
        Cell: ({ renderedCellValue }) => (
          <Text>{format(parseISO(renderedCellValue), 'dd-MM-yyyy HH:MM')}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Cuenta confirmada',
        accessorKey: 'confirmacionCuenta',
        Cell: ({ renderedCellValue }) => (
          <Text>
            {renderedCellValue ? (
              <div className="w-8 h-8 bg-success-light text-success-dark rounded-full flex justify-center items-center">
                <Check />
              </div>
            ) : (
              <div className="w-8 h-8 bg-error-light text-error-dark rounded-full flex justify-center items-center">
                <Close />
              </div>
            )}
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      }
    ],
    []
  );

  const [openConfirmacion, setOpenConfirmacion] = useState(false);
  const [registroEliminar, setRegistroEliminar] = useState({});
  const [isEnviandoEmail, setEnviandoEmail] = useState(false);

  const handleIniciarEliminacion = registro => {
    setRegistroEliminar(registro.original);
    setOpenConfirmacion(true);
  };

  const handleReenviarEmail = async registro => {
    setEnviandoEmail(true);
    try {
      const response = await usuariosService.confirmacionCorreoUsuarios(
        registro.original.correoElectronico
      );
      if (response.status === 200) {
        AlertaExito(
          'El correo de verificación ha sido enviado correctamente al usuario.'
        );
      }
    } catch (error) {
      AlertaError('No se ha podido enviar el correo de recuperación.');
    }
    setEnviandoEmail(false);
  };

  const handleConfirmarEliminacion = async () => {
    try {
      const response = await usuariosService.eliminarUsuario(
        registroEliminar.codigoUsuario
      );
      if (response.status === 200) {
        AlertaExito('El usuario ha sido eliminado correctamente');
        const usuariosAux = usuarios.filter(
          m => m.codigoUsuario !== registroEliminar.codigoUsuario
        );
        setUsuarios(usuariosAux);
        setOpenConfirmacion(false);
        setRegistroEliminar({});
      }
    } catch (error) {
      setOpenConfirmacion(false);
    }
  };

  return (
    <SidebarLayout>
      <div className="mb-10">
        <div className="md:flex items-center mb-4 justify-between">
          <div className="mb-4">
            <Heading type="h2" className="mb-0">
              Administración de Usuarios
            </Heading>
            <Text className="mb-4 sm:mb-0">
              {isSuperAdmin
                ? 'Crea, edita y elimina los registros de usuarios registrados en la plataforma.'
                : 'Edita y elimina los registros de usuarios asociados a tu negocio.'}
            </Text>
          </div>
          <div>
            {isSuperAdmin && (
              <Link to={RUTAS.ADMINISTRAR_USUARIOS_NUEVO}>
                <ButtonComponent className="mb-3 md:mb-0 mr-3" type="primary">
                  <Add className="mr-2" /> Crear usuario
                </ButtonComponent>
              </Link>
            )}
          </div>
        </div>

        <Table
          headTable={
            <Heading type="h4" className="p-2">
              Usuarios registrados
            </Heading>
          }
          data={usuarios}
          columns={columnTables}
          isLoading={isLoading}
          editing={true}
          rowCount={rowCount}
          manualFiltering
          enablePagination={true}
          enableGlobalFilter={false}
          onPaginationChange={setPagination}
          state={{
            columnFilters,
            pagination,
            isLoading,
            showProgressBars: false,
            globalFilter,
            columnVisibility: {
              'sucursal.empresa.nombreEmpresa': isSuperAdmin ? true : false
            }
          }}
          manualPagination
          enableEditing={true}
          actions={({ row, table }) => (
            <div className="min-w-[140px]">
              <Tooltip arrow placement="left" title="Editar">
                <Link
                  to={`${RUTAS.NEGOCIO}/usuarios/${row.original.codigoUsuario}`}
                >
                  <IconButtonComponent
                    size="small"
                    className="mr-2"
                    type="secondary"
                  >
                    <Edit />
                  </IconButtonComponent>
                </Link>
              </Tooltip>
              <Tooltip arrow placement="right" title="Eliminar">
                <IconButtonComponent
                  size="small"
                  type="error"
                  onClick={() => handleIniciarEliminacion(row, table)}
                >
                  <Delete />
                </IconButtonComponent>
              </Tooltip>

              {isSuperAdmin && (
                <Tooltip
                  arrow
                  placement="right"
                  title="Reenviar correo de recuperación"
                >
                  <IconButtonComponent
                    disabled={isEnviandoEmail}
                    size="small"
                    type="secondary"
                    className="ml-2"
                    onClick={() => handleReenviarEmail(row, table)}
                  >
                    <MailLock
                      className={`${
                        isEnviandoEmail ? 'opacity-50' : 'opacity-100'
                      }`}
                    />
                  </IconButtonComponent>
                </Tooltip>
              )}
            </div>
          )}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
        />
      </div>

      <Dialog
        open={openConfirmacion}
        onClose={() => setOpenConfirmacion(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="border-b border-neutral-90"
        >
          <Heading type="h3" className="mb-0">
            Confirmación de eliminación
          </Heading>
        </DialogTitle>
        <DialogContent className="p-5">
          <DialogContentText id="alert-dialog-description" className="pt-4">
            <Text>
              ¿Estás seguro que deseas eliminar completamente al siguiente
              usuario?
            </Text>
            <div className="my-4">
              <div className="flex items-center mb-2">
                <Person className="mr-2 text-uv-secondary-10" />
                <Heading type="h5" className="pr-10 mb-0">
                  {' '}
                  {registroEliminar.nombreUsuario}
                </Heading>
              </div>
              <div className="flex items-center">
                <Email className="mr-2 text-uv-secondary-10" />
                <Heading type="h5" className="pr-10 mb-0 truncate">
                  {' '}
                  {registroEliminar.correoElectronico}
                </Heading>
              </div>
            </div>
            <AlertComponent type="error">
              Esta operación es irreversible y{' '}
              <b>el usuario quedará completamente eliminado de la plataforma</b>
            </AlertComponent>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="border-t border-neutral-90 p-4 justify-between">
          <ButtonComponent
            type="secondary"
            onClick={() => setOpenConfirmacion(false)}
            className="justify-center"
          >
            <Hidden smDown>Cancelar</Hidden>
            <Hidden smUp>
              <Close />
            </Hidden>
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            onClick={() => handleConfirmarEliminacion()}
            autoFocus
            className="w-full xs:w-auto justify-center"
          >
            Confirmar
          </ButtonComponent>
        </DialogActions>
      </Dialog>
    </SidebarLayout>
  );
};

export default Usuarios;
