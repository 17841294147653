import { RUTAS } from 'const/Rutas';
import { AuthContext } from 'context';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import empresasService from 'services/empresas';
import { AlertaExito } from 'utils/Alertas';

export const useSucursal = (codigoSucursal, codigoEmpresa) => {
  const history = useHistory();
  const { logeduser, refrescarSesion, token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [sucursal, setSucursal] = useState({
    codigoEmpresa: '',
    nombreSucursal: '',
    telefono: '',
    region: '',
    comuna: '',
    nombreVia: '',
    pisoBloque: '',
    longitudDireccion: 0,
    latitudDireccion: 0,
    codigosTipoResiduo: []
  });
  const [tipoResiduoSeleccionados, setTipoResiduoSeleccionados] = useState([]);
  const [isNuevaSucursal, setIsNuevaSucursal] = useState(false);

  /**
   * @name cargarSucursal
   * Función para cargar la sucursal que se está desplegando, en el caso de que el código no viene se asume que es una nueva sucursal.
   */
  const cargarSucursal = async () => {
    setIsLoading(true);

    if (codigoSucursal !== 'nuevo') {
      try {
        const responseSucursal = await empresasService.getSucursal(
          codigoSucursal
        );
        if (responseSucursal.status === 200) {
          const sucursal = responseSucursal.data;
          const tipoResiduo = sucursal.sucursalesTipoResiduo.map(
            tipo => tipo.codigoTipoResiduo
          );
          setTipoResiduoSeleccionados(tipoResiduo);
          setSucursal({
            codigoSucursal: sucursal.codigoSucursal,
            codigoDireccion: sucursal.direccion.codigoDireccion,
            codigoEmpresa: sucursal.empresa.codigoEmpresa,
            nombreSucursal: sucursal.nombreSucursal,
            telefono: sucursal.telefonoSucursal,
            region: sucursal.direccion.comuna.region,
            comuna: {
              codigoComuna: sucursal.direccion.comuna.codigoComuna,
              nombreComuna: sucursal.direccion.comuna.nombreComuna
            },
            nombreVia: sucursal.direccion.nombreVia,
            pisoBloque: sucursal.direccion.pisoBloque,
            longitudDireccion: sucursal.direccion.longitudDireccion,
            latitudDireccion: sucursal.direccion.latitudDireccion,
            codigosTipoResiduo: sucursal.sucursalesTipoResiduo.map(tipo => ({
              checked: true,
              codigoTipoResiduo: tipo.codigoTipoResiduo,
              nombreTipo: tipo.nombreTipo
            }))
          });
        }
      } catch (error) {
        console.log('Error al cargar la sucursal', error);
      }
    } else {
      setIsNuevaSucursal(true);
    }
    setIsLoading(false);
  };

  /**
   * @name handleSubmit
   * Función que contiene la lógica del evento submit del fomrulario y define que subfunción ejecutar según si es una nueva sucursal o una actualización.
   */
  const handleSubmit = async valores => {
    try {
      if (isNuevaSucursal) {
        await submitNuevaSucursal(valores);
      } else {
        await submitActualizarSucursal(valores);
      }
    } catch (error) {
      throw new Error('Error en handleSubmit ', error);
    }
  };

  /**
   * @name submitNuevaSucursal
   * Función para crear una nueva sucursal
   * @param valores estructura formik para nueva sucursal
   */
  const submitNuevaSucursal = async valores => {
    try {
      const sucursalData = estructuraServicioNuevaSucursal(valores);
      const response = await empresasService.nuevaSucursal(sucursalData);
      if (response.status === 200) {
        history.push(`${RUTAS.NEGOCIO}/${codigoEmpresa}/sucursales`);
        AlertaExito('Se han guardado los datos de la sucursal correctamente');
      }
    } catch (error) {
      console.log('Error en la creación de nueva sucursal  ', error);
      throw new Error('Error en submitNuevaSucursal ', error);
    }
  };

  /**
   * @name submitActualizarSucursal
   * Función para actualizar los datos de una sucursal
   * @param valores estructura formik para nueva sucursal
   */
  const submitActualizarSucursal = async valores => {
    try {
      const sucursalData = estructuraServicioActualizarSucursal(valores);
      const response = await empresasService.actualizaSucursal(
        codigoSucursal,
        sucursalData
      );
      if (response.status === 200) {
        AlertaExito('Se han guardado los datos de la sucursal correctamente');
      }
      if (parseInt(codigoSucursal) === logeduser.sucursal?.codigoSucursal) {
        await refrescarSesion(token);
      }
    } catch (error) {
      console.log('Error en la creación de nueva sucursal  ', error);
      throw new Error('Error en submitNuevaSucursal ', error);
    }
  };

  /**
   * @name estructuraServicioNuevaSucursal
   * Función para estructurar los valores del formulario a la estructura requerida por el servicio
   * @param valores estructura formik para nueva sucursal
   * @return estructura servicio nueva sucursal
   */
  const estructuraServicioNuevaSucursal = valores => {
    try {
      const estructuraServicio = {
        codigoEmpresa: '',
        nombreSucursal: '',
        codigoComuna: 0,
        // tipoVia: 'Calle',
        nombreVia: '',
        pisoBloque: '',
        latitudDireccion: '',
        longitudDireccion: '',
        sucursalesTipoResiduo: [{ codigoTipoRsd: 0 }],
        telefonoSucursal: ''
      };
      estructuraServicio.codigoEmpresa = codigoEmpresa;
      estructuraServicio.nombreSucursal = valores.nombreSucursal;
      estructuraServicio.codigoComuna = valores.comuna.codigoComuna.valueOf();
      // estructuraServicio.tipoVia = valores.tipoVia.nombreTipoVia;
      estructuraServicio.nombreVia = valores.nombreVia;
      estructuraServicio.latitudDireccion = valores.latitudDireccion;
      estructuraServicio.longitudDireccion = valores.longitudDireccion;
      estructuraServicio.telefonoSucursal = valores.telefono;
      estructuraServicio.sucursalesTipoResiduo = valores.codigosTipoResiduo
        .filter(item => item.checked === true)
        .map(item => ({ codigoTipoResiduo: item.codigoTipoResiduo }));

      return estructuraServicio;
    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }
  };

  /**
   * @name estructuraServicioActualizarSucursal
   * Función para estructurar los valores del formulario a la estructura requerida por el servicio
   * @param valores estructura formik para actualizar sucursal
   * @return estructura servicio actualizar sucursal
   */
  const estructuraServicioActualizarSucursal = valores => {
    try {
      const estructuraServicio = {
        nombreSucursal: '',
        direccion: {
          codigoDireccion: 0,
          comuna: {
            codigoComuna: 0,
            region: {
              codigoRegion: 0
            }
          },
          nombreVia: '',
          pisoBloque: '',
          latitudDireccion: '',
          longitudDireccion: ''
        },
        telefonoSucursal: '',
        sucursalesTipoResiduo: [{ codigoTipoResiduo: 0 }]
      };

      estructuraServicio.direccion.codigoDireccion = sucursal.codigoDireccion;
      estructuraServicio.nombreSucursal = valores.nombreSucursal;
      estructuraServicio.direccion.comuna.codigoComuna =
        valores.comuna.codigoComuna.valueOf();
      estructuraServicio.direccion.comuna.region.codigoRegion =
        valores.region.codigoRegion.valueOf();
      estructuraServicio.direccion.nombreVia = valores.nombreVia;
      estructuraServicio.direccion.latitudDireccion = valores.latitudDireccion;
      estructuraServicio.direccion.longitudDireccion =
        valores.longitudDireccion;
      estructuraServicio.telefonoSucursal = valores.telefono;
      estructuraServicio.sucursalesTipoResiduo = valores.codigosTipoResiduo
        .filter(item => item.checked === true)
        .map(item => ({ codigoTipoResiduo: item.codigoTipoResiduo }));

      return estructuraServicio;
    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }
  };

  useEffect(() => {
    cargarSucursal();
  }, []);

  return {
    isLoading, //Boolean cuando se carga un servicio
    sucursal, //Datos de la sucursal a editar
    isNuevaSucursal, //Boolean define si es una nueva sucursal o edición
    tipoResiduoSeleccionados, //Listado de RSD seleccionados en la edición
    handleSubmit //Función que maneja la lógica de edición o creación.
  };
};
