
const InitialFormValuesCargaMasiva = {
  anyoDeclaracion: '',
  formatoBaseSII: '',
  baseDatosSII: '',
  formatoBaseRECT: '',
  baseDatosRECT: '',
  formatoBasePosiblesSinergias: '',
  baseDatosPosiblesSinergias: '',
  

};
export default InitialFormValuesCargaMasiva;
