import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import AlertComponent from 'components/design-system/Alert/Alert';
import LinkComponent from 'components/design-system/Link/Link';
import LabelComponent from 'components/design-system/Label/Label';
import InputComponent from 'components/design-system/input/Input';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Edit from '@mui/icons-material/Edit';
import Place from '@mui/icons-material/Place';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { SkeletonSucursales } from 'views/Sucursales/skeleton/SkeletonSucursales';
import { useParams } from 'react-router-dom';
import { useIndicadores } from './hooks/useIndicadores';
import { useContext } from 'react';
import { AuthContext } from 'context';
import { RUTAS } from 'const/Rutas';

const ListaIndicadores = () => {
  const { logeduser } = useContext(AuthContext);
  const { codigoEmpresa } = useParams();
  const {
    isLoading,
    isLoadingEmpresas,
    isSuperAdmin,

    sucursales,
    empresas,
    empresaSeleccionada,
    setEmpresaSeleccionada
  } = useIndicadores(parseInt(codigoEmpresa));

  const codigoEmpresaAux = isSuperAdmin
    ? typeof codigoEmpresa === 'number'
      ? codigoEmpresa
      : empresaSeleccionada?.codigoEmpresa
    : logeduser.sucursal.empresa.codigoEmpresa;

  return (
    <SidebarLayout>
      <div className="flex items-center mb-4 justify-between">
        <div>
          <Heading type="h2" className="mb-0">
            Datos para cálculos de indicadores
          </Heading>
          <Text className="mb-4">
            {isSuperAdmin
              ? 'Administra los datos de calculo de la empresa seleccionada.'
              : 'Para cada sucursal creada se establecen datos de cálculo'}
          </Text>
        </div>
      </div>

      {isSuperAdmin && (
        <div className="mb-5">
          <div className="bg-white rounded shadow-md">
            <div className="grid grid-cols-12 p-5 gap-4 items-center">
              <div className="col-span-4">
                <Heading type="h4">
                  Buscar sucursales
                  <br />
                  por empresa
                </Heading>
              </div>
              <div className="col-span-6">
                <AutocompleteComponent
                  id="empresa"
                  name="empresa"
                  accesor="nombreEmpresa"
                  options={empresas}
                  disabled={isLoadingEmpresas ? true : false}
                  onChange={(ev, object) => {
                    setEmpresaSeleccionada(object);
                  }}
                  value={empresaSeleccionada}
                  getOptionLabel={option => option.nombreEmpresa || ''}
                  /* isOptionEqualToValue={(option, value) =>
                    option.codigoEmpresa === empresaSeleccionada.codigoEmpresa
                  } */
                >
                  <InputComponent
                    fullWidth
                    className="h-9"
                    label={
                      <LabelComponent>
                        Selecciona una empresa para ver sus sucursales
                      </LabelComponent>
                    }
                  />
                </AutocompleteComponent>
              </div>
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <SkeletonSucursales />
      ) : (
        <>
          {sucursales.length > 0 ? (
            <>
              {isSuperAdmin && (
                <div>
                  <Heading type="h5" className="text-neutral-50 my-3">
                    Sucursales encontradas:{' '}
                  </Heading>
                </div>
              )}

              {sucursales.map((sucursal, index) => {
                const conIndicadores =
                  sucursal.consumoCombustible !== null &&
                  sucursal.consumoEnergetico !== null &&
                  sucursal.trabajadoresMasculino !== null &&
                  sucursal.trabajadoresFemenino !== null
                    ? true
                    : false;

                return (
                  <div className="mb-5" key={index}>
                    <div className="bg-white rounded shadow-md">
                      <div className="grid grid-cols-12 p-5 gap-4 items-center">
                        <div className="col-span-12 md:col-span-3">
                          <span className="mb-1 block font-secondary text-xs text-neutral-60 tracking-wider font-medium">
                            NOMBRE
                          </span>
                          <Heading type="H3">
                            <span>{sucursal.nombreSucursal}</span>
                          </Heading>
                        </div>

                        <div
                          className={
                            conIndicadores
                              ? 'col-span-12 md:col-span-6'
                              : 'col-span-12 md:col-span-9'
                          }
                        >
                          <span className="pl-7 mb-1 block font-secondary text-xs text-neutral-60 tracking-wider font-medium">
                            DIRECCION
                          </span>
                          <div className="flex items-center">
                            <Place className="mr-1 text-uv-secondary-10" />
                            <Text>
                              {sucursal.direccion?.nombreVia}
                              {', '}
                              {sucursal.direccion?.comuna?.nombreComuna}
                              {', Región '}
                              {sucursal.direccion?.comuna?.region?.nombreRegion}
                            </Text>
                          </div>

                          {!conIndicadores && (
                            <AlertComponent type="warning" className="mt-4">
                              <div className="lg:flex content-between items-center">
                                <div className="lg:w-1/2">
                                  <Text>
                                    <b className="text-black">
                                      No se han añadido datos
                                    </b>{' '}
                                    para la generación de indicadores en esta
                                    sucursal
                                  </Text>
                                </div>
                                <div className="lg:w-1/2 lg:text-end mt-3 lg:mt-0">
                                  <LinkComponent
                                    href={`${RUTAS.NEGOCIO}/${codigoEmpresaAux}/datos-indicadores/${sucursal.codigoSucursal}`}
                                    type="button"
                                    className="h-auto"
                                  >
                                    <Edit className="mr-2" /> Añadir datos de
                                    cálculo
                                  </LinkComponent>
                                </div>
                              </div>
                            </AlertComponent>
                          )}
                        </div>

                        {conIndicadores && (
                          <div className="col-span-12 md:col-span-3 lg:text-end">
                            <LinkComponent
                              href={`${RUTAS.NEGOCIO}/${codigoEmpresaAux}/datos-indicadores/${sucursal.codigoSucursal}`}
                              type="button"
                            >
                              <Edit className="mr-2" /> Editar datos
                            </LinkComponent>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div>
              {isSuperAdmin && empresaSeleccionada && (
                <AlertComponent type="info">
                  <div className="flex">
                    <div className="w-full">
                      <Text>
                        No se han encontrado sucursales asociadas a la empresa
                        seleccionada
                      </Text>
                    </div>
                  </div>
                </AlertComponent>
              )}

              {!isSuperAdmin && (
                <AlertComponent type="info">
                  <div className="flex">
                    <div className="w-full">
                      <Text>
                        No se han encontrado sucursales asociadas a la empresa
                      </Text>
                    </div>
                  </div>
                </AlertComponent>
              )}
            </div>
          )}
        </>
      )}
    </SidebarLayout>
  );
};

export default ListaIndicadores;
