import { useState, useContext } from 'react';
// Context
import { AuthContext } from 'context';
// Constants
import { DICTONARY } from 'const/Dictonary';
// Services
import adminService from 'services/admin';

// Utils
import { diccionarioVista } from '../utils/utils';
import {
  AlertaError,
  AlertaSinRegistros,
  ValidacionConexion
} from 'utils/Alertas';

export const useServicesPerfiles = () => {
  const {
    logged,
    logeduser,
    perfiles: { isSuperAdmin }
  } = useContext(AuthContext);
  const sucursal = logeduser.sucursal;

  // States Campos Listado de Recursos Adquiridos
  const [perfiles, setPerfiles] = useState([]);
  const [isLoadingLista, setIsLoadingLista] = useState(true);

  const [permisos, setPermisos] = useState([]);
  const [isLoadingPermisos, setIsLoadingPermisos] = useState(true);

  // States Paginacion
  const [rowCount, setRowCount] = useState(1);
  const [pagination, setPagination] = useState(DICTONARY.PAGINACION.DEFAULT);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);

  const ObtieneListaPerfiles = async () => {
    setIsLoadingLista(true);

    if (!logged) {
      ValidacionConexion();
      return;
    }

    const query = {};

    let nombrePerfil = null;

    if (columnFilters) {
      columnFilters.forEach(filter => {
        if (filter.id === 'nombrePerfil') {
          nombrePerfil = filter;
        }
      });
    }

    if (nombrePerfil) {
      query.nombrePerfil = nombrePerfil.value;
    }

    if (pagination) {
      query.numPagina = pagination.pageIndex;
      query.totalPagina = pagination.pageSize;
    }

    if (!isSuperAdmin) {
      query.codigoEmpresa = sucursal?.empresa?.codigoEmpresa;
    }

    try {
      const response = await adminService.getPerfiles();
      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log(
            `No hay ${diccionarioVista.nombreSingular} disponibles ::>`
          );

          throw new Error('SIN_REGISTROS');
        } else if (Array.isArray(content)) {
          setPerfiles(content);
          setRowCount(totalElements);
        }
      }
    } catch (err) {
      console.log('err :>> ', err);

      setRowCount(0);
      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError(
          'Ha ocurrido un error al tratar de obtener la lista de perfiles.'
        );
      }
    } finally {
      setIsLoadingLista(false);
    }
  };

  const ObtienePermisos = async () => {
    setIsLoadingPermisos(true);

    if (!logged) {
      ValidacionConexion();
      return;
    }
    const params = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA
    };
    try {
      const response = await adminService.getPermisos(params);
      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log(`No hay permisos disponibles ::>`);

          throw new Error('SIN_REGISTROS');
        } else if (Array.isArray(content)) {
          setPermisos(content);
        }
      }
    } catch (err) {
      console.log('err :>> ', err);

      if (err.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !err.response ||
        !err.response.data ||
        !err.response.data.codigoError
      ) {
        AlertaError(
          'Ha ocurrido un error al tratar de obtener la lista de permisos.'
        );
      }
    } finally {
      setIsLoadingPermisos(false);
    }
  };

  return {
    // Funciones
    ObtieneListaPerfiles,
    ObtienePermisos,

    // States Listado de Perfiles y Permisos
    perfiles,
    setPerfiles,
    permisos,
    isLoadingPermisos,
    // States Paginacion
    columnFilters,
    globalFilter,
    isLoadingLista,
    pagination,
    rowCount,
    setColumnFilters,
    setGlobalFilter,
    setIsLoadingLista,
    setPagination,
    setRowCount
  };
};
