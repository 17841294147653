import * as yup from 'yup';

const SchemaUsers = yup.object().shape({
  nombreSucursal: yup.string().trim().required('Este campo es requerido'),

  codigoSucursal: yup.number().required('Este campo es requerido'),

  emailUsuario: yup
    .string()
    .trim()
    .email('El correo electrónico no tiene el formato correcto')
    .required('El correo electrónico es requerido')
});

export { SchemaUsers };
