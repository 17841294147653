import { Add } from '@mui/icons-material';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import InputComponent from 'components/design-system/input/Input';
import { useState } from 'react';

export const ListaUsuarios = ({ usuarios, handleAddUserClick }) => {
  const handleAgregar = ev => {
    ev.preventDefault();
    handleAddUserClick(usuario);
  };
  const [usuario, setUsuario] = useState({});

  return (
    <div className="mx-3 flex items-center pt-2 pb-4 z-10 gap-4">
      <AutocompleteComponent
        options={usuarios}
        accesor={'nombreUsuario'}
        getOptionLabel={option =>
          option ? option.nombreUsuario : option.apellidoPaterno
        }
        onChange={(ev, selected) => {
          setUsuario(selected);
        }}
        clearIcon={null}
        groupBy={option =>
          option.sucursal.empresa?.razonSocial?.length > 20
            ? option.sucursal.empresa?.razonSocial.slice(0, 20).concat('...')
            : option.sucursal.empresa?.razonSocial
        }
      >
        <InputComponent
          className="h-9 min-w-[240px]"
          placeholder="Seleccionar usuario"
        />
      </AutocompleteComponent>
      <IconButtonComponent
        type="secondary"
        className="w-8 h-8 ml-1"
        onClick={ev => handleAgregar(ev)}
        edge="end"
      >
        <Add />
      </IconButtonComponent>
    </div>
  );
};
