import * as yup from 'yup';

const SchemaIngresaResiduosAT = yup.object().shape({

  anyoDeclaracion: yup
  .string()
  .trim()
  .required('Este campo es requerido'),

  nombreLER: yup
    .object()
    .typeError('Seleccione nombre LER')
    .required('Este campo es obligatorio'),

  nombreSubLER: yup
    .object()
    .typeError('Seleccione Subnombre LER')
    .required('Este campo es obligatorio'),

  nombreSubSubLER: yup
    .object()
    .typeError('Seleccione Subnombre LER')
    .required('Este campo es obligatorio'),

  tratamiento: yup.string().required('Es un campo obligatorio'),

  cantidad: yup
    .string()
    .min(1, 'Ingrese cantidad')
    .required('Ingrese cantidad'),

  empresaATOrigen: yup.object().when('tieneEmpresaATDestino', {
    is: true,
    then: () => {
      return yup
        .object()
        .typeError('Seleccione una empresa de origen')
        .required('Es un campo obligatorio');
    }
  }),

  empresaATDestino: yup.object().when('tieneEmpresaATOrigen', {
    is: true,
    then: () => {
      return yup
        .object()
        .typeError('Seleccione una empresa de destino')
        .required('Es un campo obligatorio');
    }
  })
});

export { SchemaIngresaResiduosAT };
