import Text from 'components/design-system/Text/Text';
import { formatoANumeroIngles } from 'helpers/formateoNumeros';
import { useMemo } from 'react';
import { Chart } from 'react-charts';

export const Grafico = ({ dataGrafico }) => {
  const data = useMemo(
    () => [
      {
        label: 'Residuos',
        data: dataGrafico.map(dummy => ({
          primary: dummy.provincia,
          secondary: dummy.residuos
        }))
      },
      {
        label: 'Empresas',
        data: dataGrafico.map(dummy => ({
          primary: dummy.provincia,
          secondary: dummy.empresas
        }))
      }
    ],
    []
  );

  const primaryAxis = useMemo(
    () => ({
      position: 'left',
      getValue: datum => datum.primary,
    }),
    []
  );

  const secondaryAxes = useMemo(
    () => [
      {
        position: 'bottom',
        getValue: datum => datum.secondary
      }
    ],
    []
  );

  const tooltip = useMemo(
    () => ({
      cursor: true,
      delay: 0,
      followCursor: true,
      offset: {
        left: 10,
        top: 10,
      },
      render: (props) => {
        const primary = props.focusedDatum?.primaryValue || '';
        const secondary =props.focusedDatum?.secondaryValue || '';
        const bgColor = props.focusedDatum?.style?.fill || '#333';
        const label = props.focusedDatum?.seriesLabel || '';
        const formatedNumber = formatoANumeroIngles(props.secondaryAxis.formatters.default(secondary)) || secondary;

        return (
          <div className={`!font-primary bg-neutral-10 !text-white !p-2 !rounded`}
            style={{backgroundColor: bgColor}}
          >
            <Text size="S" className="text-white text-center mb-2">{primary}</Text>
            <div className="flex gap-5">
              <Text className="text-white" size="S">{label === 'Residuos' ? 'Toneladas de residuos' : 'Nº de empresas'}</Text>
              <Text className="text-white font-bold" size="S">{formatedNumber}</Text>
            </div>
          </div>
        );
      },
    }),
    []
 );

  return (
    <div className="p-5">
      <div className="w-full h-full min-h-[400px]">
        <Chart
          id="1"
          className="!font-primary"
          options={{
            data,
            primaryAxis,
            secondaryAxes,
            defaultColors: ['#225B2A', '#43A7B6'],
            tooltip,
          }}
        />
      </div>
    </div>
  );
};
