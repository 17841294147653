import ValidateRut from 'helpers/validateRut';
import * as yup from 'yup';

const SchemaReceptor = yup.object().shape({
  rutEmpresaReceptor: yup
    .string()
    .trim()
    .nullable()
    .matches(/^[^.\s]*$/, 'No se permiten puntos en este campo')
    .max(12, 'La cantidad máxima de caracteres es 11')
    .min(9, 'La cantidad mínima de caracteres es 9')
    .matches(
      /^[0-9]+[-|‐]{1}[0-9kK]{1}$/,
      'El rut debe ser del tipo 12345678-9'
    )
    .test(
      'Evaluación de rut',
      'El rut ingresado es inválido',
      value => !value || ValidateRut.isValid(value)
    ),

  nombreEmpresaReceptor: yup
    .string()
    .trim()
    .max(128, 'La cantidad máxima de caracteres es 128')
    .min(2, 'La cantidad mínima de caracteres es 2')
    .nullable(),

  codigoSINADER: yup
    .string()
    .nullable()
    .min(4, 'La cantidad mínima de caracteres es de 4 dígitos')
    .max(7, 'La cantidad máxima de caracteres es de 7 dígitos')
});

export { SchemaReceptor };
