import { useState } from 'react';
import { AutoCompleteAsync } from './AutoCompleteAsync';
import { DICTONARY } from 'const/Dictonary';

export const AutoCompleteAsyncController = ({
  setFieldValue,
  isSubmitting,
  setFieldTouched,
  label,
  disabled,
  fetchCall,
  ...rest
}) => {
  const [rowCountEmpresas, setRowCountEmpresas] = useState(1);
  const [paginationEmpresas, setPaginationEmpresas] = useState(
    DICTONARY.PAGINACION.DEFAULT
  );
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  return (
    <AutoCompleteAsync
      disabled={isLoading || isSubmitting || disabled}
      isLoading={isLoading}
      data={data}
      fetchCall={inputValue =>
        fetchCall({
          setIsLoading: setIsLoading,
          setData: setData,
          paginationEmpresas: paginationEmpresas,
          inputValue: inputValue.toUpperCase(),
          setRowCountEmpresas: setRowCountEmpresas
        })
      }
      label={label}
      isSubmitting={isSubmitting}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      {...rest}
    />
  );
};
