import * as yup from 'yup';
import ValidateRut from 'helpers/validateRut';

const SchemaTrasaccion = yup.object().shape({
  tipoTratamiento: yup
    .object()
    .typeError('Seleccione un tipo de tratamiento')
    .required('Es un campo obligatorio'),

  tipoTransporte: yup
    .object()
    .typeError('Seleccione un tipo de transporte')
    .required('Es un campo obligatorio'),

  ejecutoTratamiento: yup.string().required('Es un campo obligatorio'),
  fechaRecepcion: yup.string().required('Seleccione una fecha'),
  rutTransportista: yup.string().when('sucursalTransportista', {
    is: sucursalTransportista => sucursalTransportista?.codigoSucursal === 0,
    then: () =>
      yup
        .string()
        .required('Ingresa el rut del transportista')
        .trim()
        .test(
          'Evaluación de rut',
          'El rut ingresado es inválido',
          value => !value || ValidateRut.isValid(value)
        )
  })
});

export { SchemaTrasaccion };
