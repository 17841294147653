import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Place from '@mui/icons-material/Place';
import Delete from '@mui/icons-material/Delete';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import LinkComponent from 'components/design-system/Link/Link';
import { Link, useParams } from 'react-router-dom';
import { RUTAS } from 'const/Rutas';
import { useSucursales } from './hooks/useSucursales';
import AlertComponent from 'components/design-system/Alert/Alert';
import { SkeletonSucursales } from './skeleton/SkeletonSucursales';
import ButtonComponent from 'components/design-system/Button/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden
} from '@mui/material';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { AccountBalance, Close, PlaceRounded } from '@mui/icons-material';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import { useContext } from 'react';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';

const Sucursales = () => {
  const { logeduser, isTienePermisos } = useContext(AuthContext);
  const { codigoEmpresa } = useParams();
  const {
    isLoading,
    loadingEmpresas,
    isSuperAdmin,

    sucursales,
    empresas,
    empresaSeleccionada,
    setEmpresaSeleccionada,

    //Eliminación de Sucursal
    handleIniciarEliminacion,
    handleConfirmarEliminacion,
    openConfirmacion,
    setOpenConfirmacion,
    sucursalEliminar
  } = useSucursales(parseInt(codigoEmpresa));

  const codigoEmpresaAux = isSuperAdmin
    ? typeof codigoEmpresa === 'number'
      ? codigoEmpresa
      : empresaSeleccionada?.codigoEmpresa
    : logeduser.sucursal.empresa.codigoEmpresa;

  return (
    <SidebarLayout>
      <div className="sm:flex items-center mb-4 justify-between">
        <div>
          <Heading type="h2" className="mb-0">
            Administración de sucursales
          </Heading>
          <Text className="mb-4 sm:mb-0">
            {isSuperAdmin
              ? 'Crea, edita y elimina los registros de las sucursales registradas en la plataforma.'
              : 'Crea, edita y elimina los registros de las sucursales asociadas a tu negocio.'}
          </Text>
        </div>
        <div>
          {!isLoading &&
            sucursales.length > 0 &&
            isTienePermisos(PERMISOS.crearSucursal) && (
              <Link
                to={`${RUTAS.NEGOCIO}/${codigoEmpresaAux}/sucursales/nuevo`}
              >
                <ButtonComponent type="primary">
                  <Add className="mr-2" /> Nueva sucursal
                </ButtonComponent>
              </Link>
            )}
        </div>
      </div>

      {isSuperAdmin && (
        <div className="mb-5">
          <div className="bg-white rounded shadow-md">
            <div className="grid grid-cols-12 p-5 gap-4 items-center">
              <div className="col-span-12 sm:col-span-4">
                <Heading type="h4">
                  Buscar sucursales <br className="hidden sm:block" />
                  por empresa
                </Heading>
              </div>
              <div className="col-span-12 sm:col-span-8 lg:col-span-6">
                <AutocompleteComponent
                  id="empresa"
                  name="empresa"
                  options={empresas}
                  accesor="nombreEmpresa"
                  disabled={loadingEmpresas ? true : false}
                  onChange={(ev, object) => {
                    setEmpresaSeleccionada(object);
                  }}
                  value={empresaSeleccionada}
                  getOptionLabel={option => option.nombreEmpresa || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.codigoEmpresa === empresaSeleccionada.codigoEmpresa
                  }
                >
                  <InputComponent
                    fullWidth
                    className="h-9"
                    label={
                      <LabelComponent>
                        Selecciona una empresa para ver sus sucursales
                      </LabelComponent>
                    }
                  />
                </AutocompleteComponent>
              </div>
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <SkeletonSucursales />
      ) : (
        <>
          {sucursales.length > 0 ? (
            <>
              {isSuperAdmin && (
                <div>
                  <Heading type="h5" className="text-neutral-50 my-3">
                    Sucursales encontradas:{' '}
                  </Heading>
                </div>
              )}

              {sucursales.map((sucursal, index) => {
                return (
                  <div className="mb-5" key={index}>
                    <div className="bg-white rounded shadow-md">
                      <div className="grid grid-cols-12 p-5 gap-4 items-center">
                        <div className="col-span-12 lg:col-span-3">
                          <span className="mb-1 block font-secondary text-xs text-neutral-60 tracking-wider font-medium">
                            NOMBRE
                          </span>
                          <Heading type="H3">
                            <span>{sucursal.nombreSucursal}</span>
                          </Heading>
                        </div>
                        <div className="col-span-12 sm:col-span-8 lg:col-span-6">
                          <span className="pl-7 mb-1 block font-secondary text-xs text-neutral-60 tracking-wider font-medium">
                            DIRECCIÓN
                          </span>
                          <div className="flex items-center">
                            <Place className="mr-1 text-uv-secondary-10" />
                            <Text>
                              {sucursal.direccion?.nombreVia}
                              {', '}
                              {sucursal.direccion?.comuna?.nombreComuna}
                              {', Región '}
                              {sucursal.direccion?.comuna?.region?.nombreRegion}
                            </Text>
                          </div>
                        </div>

                        {isSuperAdmin ? (
                          <div className="col-span-12 xs:col-span-12 sm:col-span-4 lg:col-span-3 text-start xs:text-end">
                            <Link
                              to={`${RUTAS.NEGOCIO}/${codigoEmpresaAux}/sucursales/${sucursal.codigoSucursal}`}
                            >
                              <IconButtonComponent type="secondary">
                                <Edit />
                              </IconButtonComponent>
                            </Link>

                            <IconButtonComponent
                              className={`${index === 0 && 'opacity-40'}`}
                              disabled={index === 0}
                              type="error"
                              onClick={() => handleIniciarEliminacion(sucursal)}
                            >
                              <Delete />
                            </IconButtonComponent>

                            {index === 0 && (
                              <Text className="bg-warning-light rounded p-2 mt-2">
                                No puedes eliminar la sucursal matríz
                              </Text>
                            )}
                          </div>
                        ) : (
                          <div className="col-span-12 sm:col-span-4 lg:col-span-3 text-start sm:text-end">
                            <VerificarPermisos
                              nombrePermiso={PERMISOS.editarSucursal}
                            >
                              <LinkComponent
                                href={`${RUTAS.NEGOCIO}/${codigoEmpresaAux}/sucursales/${sucursal.codigoSucursal}`}
                                type="button"
                              >
                                <Edit className="mr-2" /> Editar sucursal
                              </LinkComponent>
                            </VerificarPermisos>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div>
              {isSuperAdmin && empresaSeleccionada && (
                <AlertComponent type="info">
                  <div className="flex">
                    <div className="w-full">
                      <Text>
                        No se han encontrado sucursales asociadas a la empresa
                        seleccionada
                      </Text>
                    </div>
                  </div>
                </AlertComponent>
              )}

              {!isSuperAdmin && (
                <AlertComponent type="warning">
                  <div className="sm:flex mb-6 sm:mb-0">
                    <div className="w-full sm:w-2/3">
                      <Text className="mb-4">
                        Antes de poder añadir sucursales{' '}
                        <b className="text-black font-bold">
                          debes haber registrado un negocio,{' '}
                        </b>
                        al hacerlo esta sección y otras asociadas se habilitarán
                        automáticamente.
                      </Text>
                      <Text>
                        Registrar un negocio es un proceso simple, solamente te
                        tomará un par de minutos y quedarás habilitado para
                        utilizar gran parte de las funcionalidades de la
                        plataforma.
                      </Text>
                    </div>
                    <div className="w-full sm:w-1/3 my-4 sm:my-0 sm:text-end">
                      <LinkComponent
                        href={RUTAS.REGISTRAR_NEGOCIO}
                        type="button"
                      >
                        Registrar un negocio
                      </LinkComponent>
                    </div>
                  </div>
                </AlertComponent>
              )}
            </div>
          )}
        </>
      )}

      <Dialog
        open={openConfirmacion}
        onClose={() => setOpenConfirmacion(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="border-b border-neutral-90"
        >
          <Heading type="h3" className="mb-0">
            Confirmación de eliminación de sucursal
          </Heading>
        </DialogTitle>
        <DialogContent className="p-5">
          <DialogContentText id="alert-dialog-description" className="pt-4">
            <Text>
              ¿Estás seguro que deseas eliminar completamente la siguiente
              sucursal?
            </Text>
            <div className="my-4">
              <div className="flex items-center mb-2">
                <AccountBalance className="mr-2 text-uv-secondary-10" />
                <Heading type="h3" className="pr-10 mb-0">
                  {' '}
                  {sucursalEliminar?.nombreSucursal}
                </Heading>
              </div>
              <div className="flex items-center">
                <PlaceRounded className="mr-2 text-uv-secondary-10" />
                <Text>
                  {sucursalEliminar?.direccion?.nombreVia}
                  {', '}
                  {sucursalEliminar?.direccion?.comuna?.nombreComuna}
                  {',  Región '}
                  {sucursalEliminar?.direccion?.comuna?.region?.nombreRegion}
                </Text>
              </div>
            </div>
            <AlertComponent type="error">
              Esta operación es irreversible y{' '}
              <b>se perderán todos los datos asociados a esa sucursal</b>
            </AlertComponent>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="border-t border-neutral-90 p-4 justify-between">
          <ButtonComponent
            type="secondary"
            onClick={() => setOpenConfirmacion(false)}
            className="justify-center"
          >
            <Hidden smDown>Cancelar</Hidden>
            <Hidden smUp>
              <Close />
            </Hidden>
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            onClick={() => handleConfirmarEliminacion()}
            autoFocus
            className="w-full xs:w-auto justify-center"
          >
            Confirmar
          </ButtonComponent>
        </DialogActions>
      </Dialog>
    </SidebarLayout>
  );
};

export default Sucursales;
