import { useEffect, useState } from 'react';

import {
  ObtieneListaTipoResiduos,
} from 'hooks';

export const useResiduos = () => {

  // states
  const [listaSubTipoResiduos, setListaSubTipoResiduos] = useState([]);
  // Tiene Datos
  const [tieneTipoResiduos, setTieneTipoResiduos] = useState(true);
  // Cargando
  const [cargandoListaSubTipoResiduos, setCargandoListaSubTipoResiduos] = useState(true);

  // SERVICIO nombresTipoResiduos
  const {
    listaTipoResiduos,
    cargandoListaTipoResiduos,
    listaTipoResiduosNivelUno,
    listaTipoResiduoNivelUnoEdit,
    listaTipoResiduoNivelDosEdit,
  } = ObtieneListaTipoResiduos();

    //Calculo SUBTIPOS RESIDUOS
    const calculoSubTipoResiduos = (codigoTipoResiduo, setFieldValue) => {
      const subtipos = listaTipoResiduos.filter(
        t =>
          t.nivel === 2 && t.codigoNivelAnteriorTipoResiduo === codigoTipoResiduo
      );
  
      setTieneTipoResiduos(!!subtipos.length);
      setFieldValue('tieneSubTipoResiduo', !!subtipos.length);
  
      subtipos.length > 0 && setCargandoListaSubTipoResiduos(false);
  
      subtipos && setListaSubTipoResiduos(subtipos);
    };
  

  return {
    calculoSubTipoResiduos,
    setCargandoListaSubTipoResiduos,
    setTieneTipoResiduos,
    cargandoListaSubTipoResiduos,
    cargandoListaTipoResiduos,
    listaSubTipoResiduos,
    listaTipoResiduoNivelDosEdit,
    listaTipoResiduoNivelUnoEdit,
    listaTipoResiduos,
    listaTipoResiduosNivelUno,
    tieneTipoResiduos,
  }
}
