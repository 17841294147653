import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
import Heading from 'components/design-system/Heading/Heading';
import { CircularProgress } from '@mui/material';
import { useMemo, useState } from 'react';
import { useEffect } from 'react';
import {
  handleKeyPress,
  handlePaste
} from 'views/Business/utils/handleKeyPress';

const Transporte = ({
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
  // Dependencias
  // Empresas SII
  cargaEmpresasSIITransporte,
  empresasSIITransporte,
  isLoadingEmpresasSIITransporte,
  // Tipo de Transporte
  isLoadingTipoTransporte,
  tipoTransporte
}) => {
  const [razonSocialEmpresaTransporte, setRazonSocialEmpresaTransporte] =
    useState(values.transporteRazonSocial || '');

  useEffect(() => {
    if (!empresasSIITransporte) return;
    if (empresasSIITransporte.razonSocial) {
      setRazonSocialEmpresaTransporte(empresasSIITransporte.razonSocial);
      setFieldValue('transporteRazonSocial', empresasSIITransporte.razonSocial);
      if (touched.rutEmpresaTransporte) {
        setFieldValue(
          'nombreEmpresaTransporte',
          empresasSIITransporte.razonSocial
        );
      }
    } else {
      setRazonSocialEmpresaTransporte('Sin información');
    }
  }, [empresasSIITransporte, isLoadingEmpresasSIITransporte]);

  // Carga RUT al ingresar al formulario
  useMemo(() => {
    if (values.rutEmpresaTransporte) {
      cargaEmpresasSIITransporte(values.rutEmpresaTransporte);
    }
  }, [cargaEmpresasSIITransporte, values.rutEmpresaTransporte]);

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Heading type="h4" className="mb-0">
            Transporte
          </Heading>
        </div>
        <div className="col-span-12 xs:col-span-12">
          <div className="grid grid-cols-12 gap-4">
            {/* Rut empresa de transporte */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <InputComponent
                autoComplete="off"
                disabled={isSubmitting}
                fullWidth
                name={'rutEmpresaTransporte'}
                value={values.rutEmpresaTransporte || ''}
                onChange={event => {
                  const { value } = event.target;
                  if (
                    value.length >= 9 &&
                    value.charAt(value.length - 2) === '-'
                  ) {
                    setFieldValue('rutEmpresaTransporte', value.trim());
                    cargaEmpresasSIITransporte(value);
                  } else {
                    setFieldValue('rutEmpresaTransporte', value.trim());
                    setRazonSocialEmpresaTransporte('Sin información');
                  }
                }}
                onBlur={() => {
                  setFieldTouched('rutEmpresaTransporte');
                  setFieldTouched('region');
                }}
                outerInputProps={{
                  inputProps: {
                    maxLength: 12,
                    // minLenth: 9,
                    onKeyPress: handleKeyPress
                  }
                }}
                onPaste={handlePaste}
                touched={touched.rutEmpresaTransporte}
                placeholder="Ej: 12345678-9"
                estado={
                  errors.rutEmpresaTransporte
                    ? { tipo: 'error', mensaje: errors.rutEmpresaTransporte }
                    : null
                }
                label={<LabelComponent esOpcional>Rut</LabelComponent>}
              />
            </div>
            {/* Razon Social */}
            {razonSocialEmpresaTransporte && (
              <div className="col-span-12 md:col-span-6 xl:col-span-4">
                <LabelComponent
                  tooltipText={
                    <>
                      <b>La razón social</b> ha sido{' '}
                      <b>obtenida de forma automática</b> con la información
                      entregada por el{' '}
                      <b>Servicio de Impuestos Internos (SII).</b>
                    </>
                  }
                >
                  Razón social
                </LabelComponent>
                <div className="rounded bg-neutral-90 p-2.5 relative">
                  <Text className="min-h-[1.25rem]">
                    {empresasSIITransporte &&
                    empresasSIITransporte.razonSocial &&
                    !isLoadingEmpresasSIITransporte
                      ? razonSocialEmpresaTransporte
                      : !touched.rutEmpresaTransporte &&
                        !errors.rutEmpresaTransporte
                      ? ''
                      : 'Sin información'}
                    {isLoadingEmpresasSIITransporte &&
                      touched.rutEmpresaTransporte &&
                      !errors.rutEmpresaTransporte && (
                        <CircularProgress
                          color="inherit"
                          className="opacity-40 !w-5 !h-5 absolute right-2.5 top-2.5"
                        />
                      )}
                  </Text>
                </div>
              </div>
            )}

            {/* Nombre */}
            <div className="col-span-12 md:col-span-4">
              <InputComponent
                autoComplete="off"
                disabled={isSubmitting}
                fullWidth
                name={'nombreEmpresaTransporte'}
                value={values.nombreEmpresaTransporte || ''}
                onChange={handleChange}
                onBlur={() => {
                  setFieldTouched('nombreEmpresaTransporte');
                }}
                touched={touched.nombreEmpresaTransporte}
                estado={
                  errors.nombreEmpresaTransporte
                    ? { tipo: 'error', mensaje: errors.nombreEmpresaTransporte }
                    : null
                }
                label={
                  <LabelComponent
                    tooltipText={
                      <>
                        <b>Nombre de fantasía</b> que sirve para{' '}
                        <b>agrupar los residuos declarados</b> de esta empresa.
                        Si se obtuvo <b>el dato de razón social</b> este{' '}
                        <b>será sugerido como nombre de empresa</b>. Sin embargo
                        puede <b>editar el campo</b> e indicar otro nombre para
                        la empresa.
                      </>
                    }
                    esOpcional
                  >
                    Nombre empresa{' '}
                  </LabelComponent>
                }
              />
            </div>

            {/* Tipo Transporte */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <AutocompleteComponent
                accesor="nombreTipoTransporte"
                clearOnEscape={true}
                disabled={isSubmitting || isLoadingTipoTransporte}
                fullWidth
                name={'tipoTransporte'}
                options={tipoTransporte || []}
                value={values.tipoTransporte || ''}
                onChange={(event, newValue, reason) => {
                  let data = { name: 'tipoTransporte', value: newValue };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  setFieldValue('tipoTransporte', data.value);
                  setFieldTouched('rutEmpresaReceptor');
                }}
                onBlur={() => {
                  setFieldTouched('rutEmpresaReceptor');
                }}
                getOptionLabel={option => option.nombreTipoTransporte || ''}
                noOptionsText={
                  isLoadingTipoTransporte ? 'Cargando...' : 'Sin opciones'
                }
              >
                <InputComponent
                  autoComplete="off"
                  disabled={isSubmitting || isLoadingTipoTransporte}
                  fullWidth
                  onBlur={() => {
                    setFieldTouched('tipoTransporte');
                  }}
                  touched={touched.tipoTransporte}
                  placeholder={
                    isLoadingTipoTransporte
                      ? 'Cargando...'
                      : 'Selecciona una opción'
                  }
                  estado={
                    errors.tipoTransporte
                      ? { tipo: 'error', mensaje: errors.tipoTransporte }
                      : null
                  }
                  label={<LabelComponent>Tipo de transporte </LabelComponent>}
                />
              </AutocompleteComponent>
            </div>
            {/* Patente */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <InputComponent
                fullWidth
                disabled={isSubmitting}
                name={'patente'}
                value={(values.patente || '').toUpperCase()}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.patente}
                placeholder="Ej: AB1234"
                outerInputProps={{
                  inputProps: {
                    maxLength: 6
                  }
                }}
                onPaste={handlePaste}
                estado={
                  errors.patente
                    ? { tipo: 'error', mensaje: errors.patente }
                    : null
                }
                label={<LabelComponent esOpcional>Patente</LabelComponent>}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transporte;
