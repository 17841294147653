import Text from 'components/design-system/Text/Text';
import { toast } from 'sonner';
import styles from './toast.module.css';
import Close from '@mui/icons-material/Close';
import { IconButton, LinearProgress } from '@mui/material';
import { DICTONARY } from 'const/Dictonary';
import { useEffect, useState } from 'react';

export const ToastComponent = props => {
  const [progress, setProgress] = useState(100);
  const duration = DICTONARY.TOAST.DURATION;

  useEffect(() => {
    const startTime = Date.now();

    const timer = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const percentage = ((duration - elapsed) / duration) * 100;

      setProgress(percentage);

      if (elapsed >= duration) {
        clearInterval(timer);
        setProgress(0);
      }
    }, 100);

    return () => clearInterval(timer);
  }, []);

  const { type, mensaje } = props;
  const id = props.toast;

  const getIcon = tipo => {
    const BASE = DICTONARY.ESTADO[tipo.toUpperCase()];
    return <BASE.ICONO className="mr-2 text-2xl" />;
  };

  if (!type || !mensaje || !id) {
    return;
  }

  return (
    <div
      className={`
      font-medium flex items-center justify-between border-b-4 border-b-transparent rounded font-primary px-4 py-1 shadow-md gap-4 w-full max-w-lg relative min-w-[24rem]
      ${styles['feedback-container-' + type]}
    `}
    >
      <Text
        size="m"
        className={`
        font-medium flex items-center py-1
        ${styles['feedback' + type]}
      `}
      >
        {getIcon(type)}
        {mensaje}
      </Text>
      <IconButton onClick={() => toast.dismiss(id)}>
        <Close className={`${styles['feedback' + type]}`} />
      </IconButton>
      <LinearProgress
        variant="determinate"
        value={progress}
        className="absolute w-full -bottom-0.5 left-0 rounded opacity-30 h-1"
        color="inherit"
      />
    </div>
  );
};
