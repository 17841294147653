import { useContext, useState } from 'react';
import { AuthContext } from 'context';
import serviceResiduos from 'services/residuos';
import serviceCommons from 'services/commons';
import { toLocaleStringPLus } from 'utils';
import {
  AlertaError,
  AlertaExito,
  AlertaSinRegistros,
  ValidacionConexion
} from 'utils/Alertas';
import { DICTONARY } from 'const/Dictonary';
import { useCallback } from 'react';

export const useServicesResiduos = () => {
  const {
    logged,
    logeduser,
    perfiles: { isSuperAdmin }
  } = useContext(AuthContext);
  const sucursal = logeduser.sucursal;

  // States Campos Listado de Residuos
  const [residuos, setResiduos] = useState([]);
  const [isLoadingLista, setIsLoadingLista] = useState(true);

  // States Paginacion
  const [rowCount, setRowCount] = useState(1);
  const [pagination, setPagination] = useState(DICTONARY.PAGINACION.DEFAULT);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);

  // Obtiene la lista de residuos
  const obtieneListaResiduos = useCallback(async () => {
    setIsLoadingLista(true);

    if (!logged) {
      ValidacionConexion();
      return;
    }

    const query = {};

    const setQueryField = (fieldName, filter) => {
      if (filter) {
        query[fieldName] = filter.value;
      }
    };

    const findFilter = keyword =>
      columnFilters?.find(filtro => filtro.id.includes(keyword));

    setQueryField('nombreRol', findFilter('nombreRol'));
    setQueryField('nombreSucursal', findFilter('nombreSucursal'));
    setQueryField('nombreEmpresa', findFilter('nombreEmpresa'));

    if (!isSuperAdmin) {
      query.codigoEmpresa = sucursal?.empresa?.codigoEmpresa;
    }

    if (pagination) {
      Object.assign(query, {
        numPagina: pagination.pageIndex,
        totalPagina: pagination.pageSize
      });
    }

    try {
      const { status, data } = await serviceResiduos.residuos(query);

      if (status === 200) {
        const { content, totalElements, empty } = data;
        if (empty) {
          console.log('No hay residuos disponibles ::>');
          setResiduos([]);
          throw new Error('SIN_REGISTROS');
        } else if (Array.isArray(content)) {
          setResiduos(
            content.map(rsd => {
              const {
                recursos,
                nombreResiduo,
                nombreLER,
                cantidad,
                unidadMedida,
                fechaDisponibilidad
              } = rsd;

              return {
                ...rsd,
                nombreTipoRecurso: recursos?.nombreRecurso,
                nombre:
                  nombreResiduo ||
                  (nombreLER && nombreLER[0] && nombreLER[0].nombreLER) ||
                  'N/A',
                cantidadUnidad: cantidad
                  ? cantidad.toLocaleString
                    ? `${toLocaleStringPLus(cantidad)}  ${
                        '(' + unidadMedida.nombreUnidadMedida + ')'
                      } `
                    : ``
                  : 'N/A',
                fechaDisponibilidad: fechaDisponibilidad || 'N/A'
              };
            })
          );
        }
        setRowCount(totalElements);
      }
    } catch (error) {
      console.log('Error al obtener la lista de residuos ::>', error);
      setResiduos([]);
      setRowCount(0);
      if (error.message === 'SIN_REGISTROS') {
        AlertaSinRegistros();
      }
      if (
        !error.response ||
        !error.response.data ||
        !error.response.data.codigoError
      ) {
        AlertaError(
          'Ha ocurrido un error al tratar de obtener la lista de residuos.'
        );
      }
    } finally {
      setIsLoadingLista(false);
    }
  }, [logged, sucursal, pagination, columnFilters, isSuperAdmin]);

  return {
    // Funciones
    obtieneListaResiduos,
    // States
    residuos,
    setResiduos,
    isLoadingLista,
    setIsLoadingLista,
    rowCount,
    setRowCount,
    pagination,
    setPagination,
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters
  };
};

export const eliminarResiduo = async (
  codigoResiduo,
  residuos,
  setResiduos,
  cargaResiduos
) => {
  try {
    await serviceResiduos
      .eliminarResiduo(codigoResiduo)
      .then(result => {
        AlertaExito('Se ha eliminado correctamente el Residuo.');
        setResiduos(
          residuos.filter(rsd => rsd.codigoResiduo !== codigoResiduo)
        );
        cargaResiduos();
      })
      .catch(err => {
        if (err.response?.data?.codigoError) {
          AlertaError(
            'Ha ocurrido un error al tratar de eliminar el Residuo. Si el problema persiste, por favor contacte al administrador.'
          );
        }
      });
  } catch (error) {}
};

// Carga Info Residuo a Editar
export const cargaRecursoReemplazoFiltrado = async ({
  codigoRecursoReemplazo,
  setRecursoReemplazo
}) => {
  try {
    const response = await serviceCommons.obtieneRecursoReemplazo();
    if (response.status === 200) {
      const { data } = response;
      const { content } = data;

      const recursoReemplazoFiltrado = content.filter(
        r =>
          r.codigoRecursoReemplazo === codigoRecursoReemplazo && r.nivel === 1
      );
      setRecursoReemplazo(recursoReemplazoFiltrado[0]);
    }
  } catch (error) {
    setRecursoReemplazo([]);
    AlertaError(
      'Ha ocurrido un error al obtener recurso de reemplazo, si el problema persiste comunícate con el administrador del sitio'
    );
    return error;
  }
};

export const calculoSubTipoResiduos = async ({
  codigoTipoResiduo,
  setResiduo
}) => {
  try {
    const params = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA
    };
    const response = await serviceCommons.tipoRSD(params);
    if (response.status === 200) {
      const { data } = response;
      const { content } = data;

      const residuoFiltrado = content.filter(
        r => r.codigoTipoResiduo === codigoTipoResiduo && r.nivel === 1
      );

      setResiduo(residuoFiltrado[0]);
    }
  } catch (error) {
    setResiduo([]);
    AlertaError(
      'Ha ocurrido un error al obtener recurso de reemplazo, si el problema persiste comunícate con el administrador del sitio'
    );
    return error;
  }
};
