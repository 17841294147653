import * as yup from 'yup';

const schemaCargarMaterial = yup
  .object()
  .shape(
    {
    nombreArchivo: yup
      .string()
      .min(1, 'El nombre es demasiado corto')
      .max(255, 'El nombre es demasiado largo')
      .required('Este campo es obligatorio'),

    marcoLegal: yup.array().default([]),

    normativaTecnica: yup.array().default([]),

    publicaciones: yup.array().default([]),

    tipoResiduo: yup.object().default({}),

    tipoTratamientoResiduo: yup.object().default({}),

    comuna: yup.array().default([]),

    region: yup.array().default([]),

    archivos: yup.array().default([]),

    url: yup.array().default([])
  })
  .test(
    'categorias',
    'Validar que al menos se debe seleccionar una categoría',
    function (value) {
      const { marcoLegal, normativaTecnica, publicaciones } = value;

      if (
        marcoLegal.length > 0 ||
        normativaTecnica.length > 0 ||
        publicaciones.length > 0
      ) {
        return true;
      } else {
        return this.createError({
          message: 'Debes seleccionar al menos una categoría',
          path: 'ERROR_SIN_CATEGORIAS'
        });
      }
    }
  )
  .test(
    'documentos',
    'Validar que se cargue un documento o se añada un enlace ',
    function (value) {
      const { archivos, url } = value;

      if (archivos.length > 0 || url.length > 0) {
        return true;
      } else {
        return this.createError({
          message:
            'Debes cargar un documento o almenos añadir un enlace externo',
          path: 'ERROR_SIN_DOCUMENTOS'
        });
      }
    }
  )
  .test(
    'region',
    'Validar que se marque la comuna cuando se selecciona una región ',
    function (value) {
      const { comuna, region } = value;

      if (!Object.hasOwn(region, 'nombreRegion')) {
        return true;
      }

      if (
        Object.hasOwn(region, 'nombreRegion') &&
        Object.hasOwn(comuna, 'nombreComuna')
      ) {
        return true;
      } else {
        return this.createError({
          message: 'Debes seleccionar una comuna',
          path: 'ERROR_SIN_COMUNA'
        });
      }
    }
  );

export { schemaCargarMaterial };
