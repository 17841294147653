export const formeateaDireccion = object => {
  let direccion = '';

  try {
    if (!object || !Object.hasOwn(object, 'direccion')) {
      return '';
    }

    if (
      Object.hasOwn(object.direccion, 'nombreVia') &&
      object.direccion.nombreVia
    ) {
      direccion += object.direccion.nombreVia + ', ';
    }

    if (
      Object.hasOwn(object.direccion, 'comuna') &&
      Object.hasOwn(object.direccion.comuna, 'nombreComuna') &&
      object.direccion.comuna.nombreComuna
    ) {
      direccion += object.direccion.comuna.nombreComuna + ', ';
    }

    if (
      Object.hasOwn(object.direccion, 'comuna') &&
      Object.hasOwn(object.direccion.comuna, 'region') &&
      Object.hasOwn(object.direccion.comuna.region, 'nombreRegion') &&
      object.direccion.comuna.region.nombreRegion
    ) {
      direccion += 'Región ' + object.direccion.comuna.region.nombreRegion;
    }
  } catch (error) {
    console.log(error);
  }

  return direccion;
};
