import { withRouter,useHistory } from 'react-router-dom';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';

import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';

import { Form, Formik } from 'formik';
import { initialValuesIngresarEmpresaAT } from './data/initialValuesIngresarEmpresaAT';

import { SchemaIngresaEmpresaAT } from './schema/SchemaIngresaEmpresaAT';

import ButtonComponent from 'components/design-system/Button/Button';
import FormularioIngresarEmpresasAT from './components/FormularioIngresarEmpresasAT';
import { Divider, Hidden } from '@mui/material';
import { ChevronLeft, Close, Save } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';

import { DICTONARY } from 'const/Dictonary';
// useServices
import { useSubmitEmpresasAT } from '../Editar/utils/useSubmitEmpresasAT';

const IngresarEmpresaAT = () => {
  const navigate = useHistory();
  const [stateValidation, setStateValidation] = useState(false);

  const {
    isLoading,
    setIsLoading,
    handleSubmit,
  } = useSubmitEmpresasAT();

  useEffect(() => {}, [isLoading]);

  return (
    <SidebarLayout>
      <>
        <div className="mb-4">
          <Heading type="h2" className="mb-0">
            <IconButtonComponent
              onClick={() => navigate.goBack()}
              type="secondary"
              className="mr-2"
            >
              <ChevronLeft />
            </IconButtonComponent>
            Ingresar Empresa
          </Heading>
          <Text className="mb-0 ml-4">Análisis Territorial</Text>
        </div>
        <Formik
          initialValues={initialValuesIngresarEmpresaAT}
          validationSchema={SchemaIngresaEmpresaAT}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={async (values, { setSubmitting }) => {
            console.log('submiting final..');

            // console.log(request);
            try {
              setTimeout(async () => {
                await handleSubmit(values, 'ingresar');
              }, 1500);
            } catch (error) {
              console.log('error :>> ', error);
              setIsLoading(false);
              setSubmitting(false);
            }

          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleReset,
            handleSubmit,
            setFieldValue,
            handleBlur,
            setFieldError,
            setFieldTouched,
            isSubmitting,
            validateForm
          }) => (
            <Form
              onChange={event => {
                //  console.log('errors  :>> ', errors);
                // console.log('touched :>> ', touched);
                // console.log('values  :>> ', values);
                setStateValidation(true);
              }}
            >
              <div className="px-4 md:container md:mx-auto">
                <div className="bg-white py-5 rounded shadow-md">
                  <div className="col-span-12 lg:col-span-9 pl-4 pr-5">
                    <div className="grid grid-cols-12 gap-4 pb-6">
                      <FormularioIngresarEmpresasAT
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleReset={handleReset}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        setFieldError={setFieldError}
                        setFieldTouched={setFieldTouched}
                        isSubmitting={isSubmitting}
                        validateForm={validateForm}
                      />
                    </div>

                    <Divider />

                    <div className="my-5">
                      <div className="flex justify-between">
                        <div className="text-start">
                          <ButtonComponent
                            type="secondary"
                            className="w-full xs:w-auto justify-center"
                            isLoading={isSubmitting}
                            disabled={isSubmitting}
                            onClick={() => {
                              navigate.goBack();
                            }}
                          >
                            <>
                              <Hidden smDown>Cancelar</Hidden>
                              <Hidden smUp>
                                <Close />
                              </Hidden>
                            </>
                          </ButtonComponent>
                        </div>
                        <div className="text-end">
                          <ButtonComponent
                            type="primary"
                            buttonType="submit"
                            isLoading={isSubmitting}
                            disabled={
                              stateValidation &&
                              Object.keys(errors).length === 0
                                ? false
                                : true
                            }
                            className="w-full xs:w-auto justify-center"
                            onClick={() => {}}
                          >
                            <>
                              <Hidden smDown>
                                {isSubmitting
                                  ?  DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR_IS_LOADING
                                  :  DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR
                                }
                              </Hidden>
                              <Hidden smUp>
                                <Save />
                              </Hidden>
                            </>
                          </ButtonComponent>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </SidebarLayout>
  );
};

export default withRouter(IngresarEmpresaAT);
