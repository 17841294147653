import React, { useContext } from 'react';
import { DICTONARY } from 'const/Dictonary';
import {
  ArrowOutward as ArrowOutwardIcon,
  CallReceived as CallReceivedIcon,
  Group as GroupIcon,
  Handshake as HandshakeIcon,
  Warehouse as WarehouseIcon
} from '@mui/icons-material';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';
import { CuadroUsuarioResumen } from './CuadroUsuarioResumen';

export const CuadroNegocioResumen = ({ usuario, resumenCuenta, isLoading }) => {
  const { sucursal } = usuario;
  const { codigoEmpresa } = sucursal.empresa;
  const roles = sucursal.empresa.roles.map(roles => roles.codigoRol);

  const { isTienePermisos } = useContext(AuthContext);

  const nombresRoles = sucursal.empresa.roles.map(roles => {
    if (roles.codigoRol === DICTONARY.ROL.GENERADOR.CODIGO) {
      return DICTONARY.ROL.GENERADOR.TITULO;
    } else if (roles.codigoRol === DICTONARY.ROL.RECEPTOR.CODIGO) {
      return DICTONARY.ROL.RECEPTOR.TITULO;
    } else if (roles.codigoRol === DICTONARY.ROL.TRANSPORTISTA.CODIGO) {
      return DICTONARY.ROL.TRANSPORTISTA.TITULO;
    } else if (roles.codigoRol === DICTONARY.ROL.GESTOR.CODIGO) {
      return DICTONARY.ROL.GESTOR.TITULO;
    } else if (roles.codigoRol === DICTONARY.ROL.ASESOR.CODIGO) {
      return DICTONARY.ROL.ASESOR.TITULO;
    } else if (roles.codigoRol === DICTONARY.ROL.RECICLADORDEBASE.CODIGO) {
      return DICTONARY.ROL.RECICLADORDEBASE.TITULO;
    } else {
      return DICTONARY.ROL.ASESOR.TITULO;
    }
  });

  const rolEmpresa = nombresRoles.join(',') || '';

  let resumen = [];

  resumen = [
    {
      accesor: 'numeroSucursales',
      titulo: 'NEGOCIO',
      enabled: isTienePermisos(PERMISOS.sucursal),
      icono: <WarehouseIcon className="w-8 h-8" />,
      descripcion: (
        <>
          Sucursales
          <br />
          <strong>registradas</strong>
        </>
      )
    },
    {
      accesor: 'numeroUsuarios',
      titulo: '',
      icono: <GroupIcon className="w-8 h-8" />,
      enabled: isTienePermisos(PERMISOS.usuarios),
      descripcion: (
        <>
          Usuarios
          <br />
          <strong>asociados</strong>
        </>
      )
    },
    {
      accesor: 'numeroTransacciones',
      titulo: 'MODULO MARKETPLACE',
      icono: <HandshakeIcon className="w-8 h-8" />,
      enabled: isTienePermisos(PERMISOS.transacciones),
      descripcion: (
        <>
          Transacciones
          <br />
          <strong>concretadas</strong>
        </>
      )
    },
    {
      accesor: 'numeroResiduosOfertados',
      titulo: '',
      icono: <ArrowOutwardIcon className="w-8 h-8" />,
      enabled: isTienePermisos(PERMISOS.ofrecerResiduo),
      descripcion: (
        <>
          Residuos
          <br />
          <b>ofertados</b>
        </>
      )
    },
    {
      accesor: 'numeroResiduosRequeridos',
      titulo: '',
      icono: <CallReceivedIcon className="w-8 h-8" />,
      enabled: isTienePermisos(PERMISOS.requerirResiduo),
      descripcion: (
        <>
          Residuos
          <br />
          <b>requeridos</b>
        </>
      )
    },
    {
      accesor: '',
      titulo: '',
      icono: null,
      hidden: true,
      descripcion: <></>
    },
    {
      accesor: 'numeroResiduosDeclarados',
      titulo: 'MODULO GESTIONA',
      icono: <ArrowOutwardIcon className="w-8 h-8" />,
      enabled: isTienePermisos(PERMISOS.residuoDeclarado),
      descripcion: (
        <>
          Residuos
          <br />
          <strong>declarados</strong>
        </>
      )
    },
    {
      accesor: 'numeroRecursosAdquiridos',
      titulo: '',
      icono: <CallReceivedIcon className="w-8 h-8" />,
      enabled: isTienePermisos(PERMISOS.recursoAdquirido),
      descripcion: (
        <>
          Recursos
          <br />
          <strong>adquiridos</strong>
        </>
      )
    }
  ];

  return (
    <CuadroUsuarioResumen
      resumen={resumen}
      resumenCuenta={resumenCuenta}
      isLoading={isLoading}
      sucursal={sucursal}
      roles={roles}
      rolEmpresa={rolEmpresa}
      codigoEmpresa={codigoEmpresa}
      numColumn={3}
    />
  );
};
