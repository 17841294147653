import {
  ObtieneListaTipoRecursoReemplazo  
} from 'hooks';
import { useCalculoSubtipoRecursosReemplazo } from '../Helpers/useCalculoSubtipoRecursosReemplazo';

export const useRecursosReemplazos = () => {

  const {
    listaTipoRecursoReemplazo,
    listaTipoRecursoReemplazo2,
    cargandoListaTipoRecursoReemplazo,
    handleListaTipoRecursoReemplazo,
  } = ObtieneListaTipoRecursoReemplazo();

const {
    calculoSubTipoRecursosReeplazo,
    listaSubTipoRecursoReemplazo,
    cargandoListaSubTipoRecursoReemplazo,
  } =
    useCalculoSubtipoRecursosReemplazo({
      listaTipoRecursoReemplazo2,
    });

  return {
    listaTipoRecursoReemplazo,
    listaTipoRecursoReemplazo2,
    cargandoListaTipoRecursoReemplazo,
    listaSubTipoRecursoReemplazo,
    cargandoListaSubTipoRecursoReemplazo,
    calculoSubTipoRecursosReeplazo,
    handleListaTipoRecursoReemplazo,
  }
}
