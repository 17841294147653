import { Divider, FormControlLabel, RadioGroup } from '@mui/material';
import AlertComponent from 'components/design-system/Alert/Alert';
import Heading from 'components/design-system/Heading/Heading';
import LabelComponent from 'components/design-system/Label/Label';
import RadioComponent from 'components/design-system/Radio/Radio';
import Text from 'components/design-system/Text/Text';
import InputNumericFormat from 'components/design-system/input/InputNumericFormat';
import { DICTONARY } from 'const/Dictonary';
import { PERMISOS } from 'const/Permisos';
import { AuthContext } from 'context';
import { useContext } from 'react';

export const Campos = ({
  errors,
  handleChange,
  setFieldTouched,
  setFieldValue,
  touched,
  values
}) => {
  const { isTienePermisos } = useContext(AuthContext);

  return (
    <>
      <div className="col-span-12 xs:col-span-6 lg:col-span-3">
        <InputNumericFormat
          maxDecimals={DICTONARY.NUMERO_DECIMALES}
          name="trabajadoresMasculino"
          value={values.trabajadoresMasculino}
          onChange={handleChange}
          onBlur={() => setFieldTouched('trabajadoresMasculino')}
          touched={touched.trabajadoresMasculino ? true : false}
          label={<LabelComponent>Nº trabajadores hombres</LabelComponent>}
          estado={
            errors.trabajadoresMasculino
              ? { tipo: 'error', mensaje: errors.trabajadoresMasculino }
              : null
          }
        />
      </div>
      <div className="col-span-12 xs:col-span-6 lg:col-span-3">
        <InputNumericFormat
          maxDecimals={DICTONARY.NUMERO_DECIMALES}
          name="trabajadoresFemenino"
          value={values.trabajadoresFemenino}
          onChange={handleChange}
          onBlur={() => setFieldTouched('trabajadoresFemenino')}
          touched={touched.trabajadoresFemenino ? true : false}
          label={<LabelComponent>Nº trabajadoras mujeres</LabelComponent>}
          estado={
            errors.trabajadoresFemenino
              ? { tipo: 'error', mensaje: errors.trabajadoresFemenino }
              : null
          }
        />
      </div>
      <div className="col-span-12 xs:col-span-6 lg:col-span-3">
        <InputNumericFormat
          maxDecimals={DICTONARY.NUMERO_DECIMALES}
          name="consumoEnergetico"
          value={values.consumoEnergetico}
          onChange={handleChange}
          onBlur={() => setFieldTouched('consumoEnergetico')}
          touched={touched.consumoEnergetico ? true : false}
          label={
            <LabelComponent>Total consumo de energía (kWH)</LabelComponent>
          }
          estado={
            errors.consumoEnergetico
              ? { tipo: 'error', mensaje: errors.consumoEnergetico }
              : null
          }
        />
      </div>
      <div className="col-span-12 xs:col-span-6 lg:col-span-3">
        <InputNumericFormat
          maxDecimals={DICTONARY.NUMERO_DECIMALES}
          name="consumoCombustible"
          value={values.consumoCombustible}
          onChange={handleChange}
          onBlur={() => setFieldTouched('consumoCombustible')}
          touched={touched.consumoCombustible ? true : false}
          label={
            <LabelComponent>Total consumo de combustible (Lt)</LabelComponent>
          }
          estado={
            errors.consumoCombustible
              ? { tipo: 'error', mensaje: errors.consumoCombustible }
              : null
          }
        />
      </div>
      <div className="col-span-12 my-2">
        <Divider />
      </div>

      {/* OFERNTE */}
      {isTienePermisos(PERMISOS.residuoGenerado) && (
        <div className="col-span-12 md:col-span-6">
          <div className="rounded p-4 bg-background-primary">
            <div className="grid grid-cols-12 gap-4 items-center">
              <div className="col-span-12 xs:col-span-6">
                <Text className="font-semibold text-black">
                  Producto o residuo generado
                </Text>
                <Text size="s">Últimos 12 meses</Text>
              </div>
              <div className="col-span-12 xs:col-span-6">
                <InputNumericFormat
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  name="residuosGenerados"
                  value={values.residuosGenerados}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('residuosGenerados')}
                  touched={touched.residuosGenerados ? true : false}
                  label={<LabelComponent>Total en toneladas</LabelComponent>}
                  estado={
                    errors.residuosGenerados
                      ? { tipo: 'error', mensaje: errors.residuosGenerados }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isTienePermisos(PERMISOS.residuoProyectado) && (
        <div className="col-span-12 md:col-span-6">
          <div className="rounded p-4 bg-background-primary">
            <div className="grid grid-cols-12 gap-4 items-center">
              <div className="col-span-12 xs:col-span-6">
                <Text className="font-semibold text-black">
                  Producto o residuo proyectado
                </Text>
                <Text size="s">A vender en los siguientes 12 meses</Text>
              </div>
              <div className="col-span-12 xs:col-span-6">
                <InputNumericFormat
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  name="residuosProyectados"
                  value={values.residuosProyectados}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('residuosProyectados')}
                  touched={touched.residuosProyectados ? true : false}
                  label={<LabelComponent>Total en toneladas</LabelComponent>}
                  estado={
                    errors.residuosProyectados
                      ? { tipo: 'error', mensaje: errors.residuosProyectados }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* DEMANDANTE */}
      {isTienePermisos(PERMISOS.recibidoComprado) && (
        <div className="col-span-12 md:col-span-6">
          <div className="rounded p-4 bg-background-primary">
            <div className="grid grid-cols-12 gap-4 items-center">
              <div className="col-span-12 xs:col-span-6">
                <Text className="font-semibold text-black">
                  Producto o residuo recibido/comprado
                </Text>
                <Text size="s">Últimos 12 meses</Text>
              </div>
              <div className="col-span-12 xs:col-span-6">
                <InputNumericFormat
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  name="residuosCompradosRecibidos"
                  value={values.residuosCompradosRecibidos}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('residuosCompradosRecibidos')}
                  touched={touched.residuosCompradosRecibidos ? true : false}
                  label={<LabelComponent>Total en toneladas</LabelComponent>}
                  estado={
                    errors.residuosCompradosRecibidos
                      ? {
                          tipo: 'error',
                          mensaje: errors.residuosCompradosRecibidos
                        }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Gestión de residuo = Oferente o demandante */}
      {isTienePermisos(PERMISOS.procesoGestion) && (
        <>
          <div className="col-span-12">
            <div className="mt-2 mb-5">
              <Divider />
            </div>

            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-3">
                <Heading type="h4">
                  ¿Tiene proceso de
                  <br />
                  gestión del residuo?
                </Heading>
                <RadioGroup
                  aria-label="procesoGestion"
                  name="procesoGestion"
                  value={values.procesoGestion}
                  onChange={ev => {
                    if (ev.target.value === 'true') {
                      setFieldValue('procesoGestion', true);
                    } else {
                      setFieldValue('trabajadoresMasGestion', '');
                      setFieldValue('trabajadoresFemGestion', '');
                      setFieldValue('totalHH', '');
                      setFieldValue('remuneracionesPagadas', '');
                      setFieldValue('procesoGestion', false, true);
                    }
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={<RadioComponent />}
                    label={<LabelComponent>Si</LabelComponent>}
                  />
                  <FormControlLabel
                    checked={values.procesoGestion === false}
                    value={false}
                    control={<RadioComponent />}
                    label={<LabelComponent>No</LabelComponent>}
                  />
                </RadioGroup>
              </div>
              <div className="col-span-12 md:col-span-9 select-none">
                <div
                  className={`${
                    values.procesoGestion === true ? '' : 'opacity-50'
                  }`}
                >
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12">
                      <Heading type="h4">
                        Participantes en el proceso de gestión del residuo
                      </Heading>
                      <AlertComponent type="info" className="mb-2">
                        <Text className="text-info-dark mb-5 font-normal px-2">
                          Si usted no tiene información respecto a la cantidad
                          de participantes en el proceso de gestión de residuos
                          de su empresa, considere como referencia que un
                          participante podría gestionar 50 toneladas/año. Así,
                          por ejemplo, si usted declara que su empresa gestionó
                          100 Toneladas en los últimos 12 meses, eso equivale a
                          la participación de 2 personas.
                        </Text>

                        <Text className="text-info-dark mb-5 font-normal px-2">
                          Jornada completa equivalente. Trabajadores media
                          jornada equivale a 0,5 trabajadores. Por ejemplo si
                          tengo{' '}
                          <strong>10 trabajadoras jornada completa</strong> y{' '}
                          <strong>10 trabajadores media jornada</strong>, el
                          valor a ingresar es <strong>15</strong>.
                        </Text>

                        <Text className="text-info-dark font-normal px-2">
                          Si usted no tiene información respecto a la cantidad
                          de mujeres que participaron en el proceso de gestión
                          de residuos de su empresa, considere dicho valor como
                          0 (cero).
                        </Text>
                      </AlertComponent>
                    </div>

                    <div className="col-span-12 xs:col-span-6">
                      <InputNumericFormat
                        disabled={values.procesoGestion === true ? false : true}
                        maxDecimals={DICTONARY.NUMERO_DECIMALES}
                        name="trabajadoresMasGestion"
                        value={values.trabajadoresMasGestion}
                        onChange={handleChange}
                        onBlur={() => setFieldTouched('trabajadoresMasGestion')}
                        touched={touched.trabajadoresMasGestion ? true : false}
                        label={
                          <LabelComponent>Total de hombres</LabelComponent>
                        }
                        estado={
                          errors.trabajadoresMasGestion
                            ? {
                                tipo: 'error',
                                mensaje: errors.trabajadoresMasGestion
                              }
                            : null
                        }
                      />
                    </div>

                    <div className="col-span-12 xs:col-span-6">
                      <InputNumericFormat
                        disabled={values.procesoGestion === true ? false : true}
                        maxDecimals={DICTONARY.NUMERO_DECIMALES}
                        name="trabajadoresFemGestion"
                        value={values.trabajadoresFemGestion}
                        onChange={handleChange}
                        onBlur={() => setFieldTouched('trabajadoresFemGestion')}
                        touched={touched.trabajadoresFemGestion ? true : false}
                        label={
                          <LabelComponent>Total de mujeres</LabelComponent>
                        }
                        estado={
                          errors.trabajadoresFemGestion
                            ? {
                                tipo: 'error',
                                mensaje: errors.trabajadoresFemGestion
                              }
                            : null
                        }
                      />
                    </div>

                    <div className="col-span-12">
                      <AlertComponent type="info" className="mb-2">
                        <Text className="text-info-dark font-normal px-2">
                          Si no tiene el detalle de las HH y remuneraciones
                          pagadas en los últimos 12 meses, considere un total de
                          2160 HH/año*persona y $ 3.846.000/año*persona
                          (equivalentes a 180 HH/mes*persona y
                          $320.500/mes*persona).
                        </Text>
                      </AlertComponent>
                    </div>
                    <div className="col-span-12 xs:col-span-6">
                      <InputNumericFormat
                        maxDecimals={DICTONARY.NUMERO_DECIMALES}
                        disabled={values.procesoGestion === true ? false : true}
                        label={
                          <LabelComponent>
                            Total HH últimos meses
                          </LabelComponent>
                        }
                        name="totalHH"
                        value={values.totalHH}
                        onChange={handleChange}
                        onBlur={() => setFieldTouched('totalHH')}
                        touched={touched.totalHH ? true : false}
                        estado={
                          errors.totalHH
                            ? { tipo: 'error', mensaje: errors.totalHH }
                            : null
                        }
                      />
                    </div>
                    <div className="col-span-12 xs:col-span-6">
                      <InputNumericFormat
                        maxDecimals={DICTONARY.NUMERO_DECIMALES}
                        disabled={values.procesoGestion === true ? false : true}
                        label={
                          <LabelComponent>
                            Total remuneraciones pagadas
                          </LabelComponent>
                        }
                        name="remuneracionesPagadas"
                        value={values.remuneracionesPagadas}
                        onChange={handleChange}
                        onBlur={() => setFieldTouched('remuneracionesPagadas')}
                        touched={touched.remuneracionesPagadas ? true : false}
                        estado={
                          errors.remuneracionesPagadas
                            ? {
                                tipo: 'error',
                                mensaje: errors.remuneracionesPagadas
                              }
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* TRANSPORTISTA */}
      {isTienePermisos(PERMISOS.totalProyectadoTransportar) && (
        <div className="col-span-12 md:col-span-6">
          <div className="rounded p-4 bg-background-primary">
            <div className="grid grid-cols-12 gap-4 items-center">
              <div className="col-span-12 xs:col-span-6">
                <Text className="font-semibold text-black">
                  Total capacidad proyectada a transportar
                </Text>
              </div>
              <div className="col-span-12 xs:col-span-6">
                <InputNumericFormat
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  label={<LabelComponent>Total en toneladas</LabelComponent>}
                  name="capacidadProyectada"
                  value={values.capacidadProyectada}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('capacidadProyectada')}
                  touched={touched.capacidadProyectada ? true : false}
                  estado={
                    errors.capacidadProyectada
                      ? { tipo: 'error', mensaje: errors.capacidadProyectada }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isTienePermisos(PERMISOS.totalTransportadas) && (
        <div className="col-span-12 md:col-span-6">
          <div className="rounded p-4 bg-background-primary">
            <div className="grid grid-cols-12 gap-4 items-center">
              <div className="col-span-12 xs:col-span-6">
                <Text className="font-semibold text-black">
                  Toneladas transportadas
                </Text>
                <Text size="s">Últimos 12 meses</Text>
              </div>
              <div className="col-span-12 xs:col-span-6">
                <InputNumericFormat
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  label={<LabelComponent>Total en toneladas</LabelComponent>}
                  name="residuosTransportados"
                  value={values.residuosTransportados}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('residuosTransportados')}
                  touched={touched.residuosTransportados ? true : false}
                  estado={
                    errors.residuosTransportados
                      ? {
                          tipo: 'error',
                          mensaje: errors.residuosTransportados
                        }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isTienePermisos(PERMISOS.kmRecorridos) && (
        <div className="col-span-12 md:col-span-6">
          <div className="rounded p-4 bg-background-primary">
            <div className="grid grid-cols-12 gap-4 items-center">
              <div className="col-span-12 xs:col-span-6">
                <Text className="font-semibold text-black">
                  Total KM recorridos
                </Text>
                <Text size="s">Últimos 12 meses</Text>
              </div>
              <div className="col-span-12 xs:col-span-6">
                <InputNumericFormat
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  label={<LabelComponent>Total en toneladas</LabelComponent>}
                  name="kilometrosRecorridos"
                  value={values.kilometrosRecorridos}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('kilometrosRecorridos')}
                  touched={touched.kilometrosRecorridos ? true : false}
                  estado={
                    errors.kilometrosRecorridos
                      ? {
                          tipo: 'error',
                          mensaje: errors.kilometrosRecorridos
                        }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/*  Asesor */}
      {isTienePermisos(PERMISOS.totalGestionar) && (
        <div className="col-span-12 md:col-span-6">
          <div className="rounded p-4 bg-background-primary">
            <div className="grid grid-cols-12 gap-4 items-center">
              <div className="col-span-12 xs:col-span-6">
                <Text className="font-semibold text-black">
                  Capacidad total de residuos a gestionar (toneladas)
                </Text>
              </div>
              <div className="col-span-12 xs:col-span-6">
                <InputNumericFormat
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  label={<LabelComponent>Total en toneladas</LabelComponent>}
                  name="residuosGestionar"
                  value={values.residuosGestionar}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('residuosGestionar')}
                  touched={touched.residuosGestionar ? true : false}
                  estado={
                    errors.residuosGestionar
                      ? { tipo: 'error', mensaje: errors.residuosGestionar }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isTienePermisos(PERMISOS.totalGestionado) && (
        <div className="col-span-12 md:col-span-6">
          <div className="rounded p-4 bg-background-primary">
            <div className="grid grid-cols-12 gap-4 items-center">
              <div className="col-span-12 xs:col-span-6">
                <Text className="font-semibold text-black">
                  Total toneladas gestionadas
                </Text>
                <Text size="s">Últimos 12 meses</Text>
              </div>
              <div className="col-span-12 xs:col-span-6">
                <InputNumericFormat
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  label={<LabelComponent>Total en toneladas</LabelComponent>}
                  name="residuosGestionados"
                  value={values.residuosGestionados}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('residuosGestionados')}
                  touched={touched.residuosGestionados ? true : false}
                  estado={
                    errors.residuosGestionados
                      ? {
                          tipo: 'error',
                          mensaje: errors.residuosGestionados
                        }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Asesor */}
      {isTienePermisos(PERMISOS.totalAsesorado) && (
        <div className="col-span-12 md:col-span-6">
          <div className="rounded p-4 bg-background-primary">
            <div className="grid grid-cols-12 gap-4 items-center">
              <div className="col-span-12 xs:col-span-6">
                <Text className="font-semibold text-black">
                  Total toneladas asesoradas
                </Text>
              </div>
              <div className="col-span-12 xs:col-span-6">
                <InputNumericFormat
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  label={<LabelComponent>Total en toneladas</LabelComponent>}
                  name="residuosAsesorados"
                  value={values.residuosAsesorados}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('residuosAsesorados')}
                  touched={touched.residuosAsesorados ? true : false}
                  estado={
                    errors.residuosAsesorados
                      ? { tipo: 'error', mensaje: errors.residuosAsesorados }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
