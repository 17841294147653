import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { PopperComponent } from 'components/design-system/Popper/Popper';
import { AuthContext } from 'context';
import { useContext } from 'react';
import Text from 'components/design-system/Text/Text';
import TrendingUp from '@mui/icons-material/TrendingUp';
import SubMenuDifusion from '../responsive/submenu/SubMenuDifusion';
import { PERMISOS } from 'const/Permisos';

export const MenuReportes = ({ forceRefresh }) => {
  const { isTienePermisos, logeduser } = useContext(AuthContext);
  const tieneNegocio = logeduser?.sucursal ? true : false;

  return (
    <>
      {(isTienePermisos(PERMISOS.descargarReportes) ||
        isTienePermisos(PERMISOS.indicadoresOnline) ||
        (!tieneNegocio && isTienePermisos(PERMISOS.registrarNegocio))) && (
        <PopperComponent
          Button={
            <IconButtonComponent
              className="text-white rounded"
              aria-controls="impacta-menu"
              aria-haspopup="true"
            >
              <TrendingUp />
              <Text className="text-white ml-1 font-secondary font-normal">
                Reportes
              </Text>
            </IconButtonComponent>
          }
        >
          <div className="pop-section-item px-3 max-w-md">
            <SubMenuDifusion
              logeduser={logeduser}
              forceRefresh={forceRefresh}
            />
          </div>
        </PopperComponent>
      )}
    </>
  );
};
