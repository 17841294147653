import { useEffect, useState } from 'react';
import indicadores from 'services/indicadores';

export const useDisponibilidadCalculadora = () => {
  const [disponibilidad, setDisponibilidad] = useState({
    cuotaMaxima: 3,
    habilitado: true,
    cuotaActual: 0
  });
  const [isCargandoDisponibilidad, setIsCargandoDisponibilidad] =
    useState(true);

  const getDisponibilidad = () => {
    setIsCargandoDisponibilidad(true);
    indicadores
      .disponibilidadCalculadora()
      .then(response => {
        setDisponibilidad(response.data);
      })
      .catch(error => {
        setDisponibilidad({
          cuotaMaxima: 3,
          habilitado: false,
          cuotaActual: 3
        });
      })
      .finally(() => {
        setIsCargandoDisponibilidad(false);
      });
  };

  useEffect(() => {
    getDisponibilidad();
  }, []);

  return {
    disponibilidad,
    setDisponibilidad,
    isCargandoDisponibilidad
  };
};
