import * as yup from 'yup';

const schemaCargaMasiva = yup.object().shape({

  anyoDeclaracion: yup
    .string()
    .trim()
    .required('Este campo es requerido'),

  });





export { schemaCargaMasiva };
