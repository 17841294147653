import { AuthContext } from 'context';
import { Link as RouterLink } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { LoginForm, LoginFormContainer } from '../menu/LoginForm';
import ButtonComponent from 'components/design-system/Button/Button';
import MiCuenta from '../menu/MiCuenta';
import { RUTAS } from 'const/Rutas';

export const MenuLogin = ({ forceRefresh, history, match }) => {
  const { logged, logout, logeduser } = useContext(AuthContext);
  const [formState, setFormState] = useState({});

  const handleChange = event => {
    event.persist();
    const { name, value } = event.target;
    setFormState(formState => ({
      ...formState,
      [name]: name === 'email' ? value.toLowerCase() : value // Convierte el email a minúsculas
    }));
  };

  useEffect(() => {
    if (logeduser?.expiredUserEmail) {
      setFormState(formState => ({
        ...formState,
        email: logeduser?.expiredUserEmail
      }));
    }
  }, [logeduser?.expiredUserEmail]);

  if (logged) {
    return (
      <MiCuenta
        handleLogout={logout}
        forceRefresh={forceRefresh}
        history={history}
        match={match}
      />
    );
  }

  return (
    <div className="loginMenu d-hidden flex">
      <LoginFormContainer>
        <LoginForm
          handleChange={handleChange}
          formState={formState}
          setFormState={setFormState}
        />
      </LoginFormContainer>
      <RouterLink to={RUTAS.REGISTRARSE}>
        <ButtonComponent
          type="ghost"
          aria-controls="simple-menu"
          aria-haspopup="true"
          className="text-white hover:text-uv-primary-0 !px-2 sm:!px-4 !text-xs sm:text-sm"
        >
          Registrarse
        </ButtonComponent>
      </RouterLink>
    </div>
  );
};
