import Heading from 'components/design-system/Heading/Heading';
import LinkComponent from 'components/design-system/Link/Link';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import imgError from './../../../../../assets/images/business/new/error.svg';
import ButtonComponent from 'components/design-system/Button/Button';

export const BusinessError = ({ onReintentar }) => {
  return (
    <div className="shadow-md rounded-tl rounded-tr bg-white mb-20">
      <div className="px-5 pt-5 pb-8">
        <div className="text-center items-center flex flex-col gap-4 p-20">
          <img
            src={imgError}
            alt="Ha ocurrido un error"
            className="w-80 m-auto"
          />
          <Heading type="h2">Ha ocurrido un error</Heading>
          <Text>
            No hemos podido completar el proceso de creación del negocio.
            Intentalo nuevamente, si el problema persiste comunícate con nuestro
            centro de ayuda
          </Text>
          <div className="flex gap-4">
            <ButtonComponent onClick={() => onReintentar()}>
              Volver a intentar
            </ButtonComponent>
            <LinkComponent type="button" href={DICTONARY.URL.REPORTAR_PROBLEMA}>
              Centro de ayuda
            </LinkComponent>
          </div>
        </div>
      </div>
    </div>
  );
};
