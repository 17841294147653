import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { Skeleton } from '@mui/material';
import { DICTONARY } from 'const/Dictonary';
import { useMapaMatch } from 'hooks/useMapaMatch';

const DraggableMarker = ({
  position,
  setPosition,
  setLatLon,
  setErrorMessage,
  markerUsuario
}) => {
  const markerRef = useRef(null);

  const handleMarkerMove = e => {
    const newPosition = e.target.getLatLng();
    setPosition([newPosition.lat, newPosition.lng]);
    setLatLon([newPosition.lat, newPosition.lng]);
    setErrorMessage('');
  };

  return (
    <Marker
      icon={markerUsuario}
      position={position}
      ref={markerRef}
      draggable={true}
      eventHandlers={{ dragend: handleMarkerMove }}
    />
  );
};

const ErrorMessageControl = ({ message }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '10px',
        left: '10px',
        backgroundColor: 'white',
        padding: '10px',
        border: '1px solid red',
        zIndex: 1000
      }}
    >
      {message}
    </div>
  );
};

const MapAddress = props => {
  const {
    latLon,
    setLatLon,
    setIsPointEditing,
    isPointEditing,
    addressQuerysearch,
    addressQueryParams,
    buscandoDireccion
  } = props;

  const [zoom, setZoom] = useState(15);
  const [addressOptions, setAddressOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [temporalPosition, setTemporalPosition] = useState(latLon);
  const [editedCustomAddress, setEditedCustomAddress] = useState(false);
  const { markerUsuario } = useMapaMatch();

  const mapRef = useRef(null);

  const fetchAddressOptions = async () => {
    try {
      const url = new URL('https://nominatim.openstreetmap.org/search');
      url.search = new URLSearchParams(addressQueryParams).toString();
      const response = await fetch(url);
      const data = await response.json();
      if (data.length === 0) {
        !editedCustomAddress && setIsPointEditing(true);
        setAddressOptions([]);
        !editedCustomAddress
          ? setErrorMessage(DICTONARY.MAPA_MENSAJES.DIRECCION_NO_ENCONTRADA)
          : setErrorMessage(
              DICTONARY.MAPA_MENSAJES.DIRECCION_NO_ENCONTRADA_DESACTIVADA
            );
        setEditedCustomAddress(true);
      } else {
        setErrorMessage('');
        setEditedCustomAddress(false);
      }
      setAddressOptions(data);
      setIsLoading(false);
    } catch (error) {
      console.log('ERROR al actualizar dirección', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isPointEditing) return;
    /**
     * En caso que aun no se escriba la dirección, no se hace la consulta
     */
    if (!addressQuerysearch) {
      setIsLoading(false);
      return;
    } else {
      /**
       * En caso que se este escribiendo la dirección, se hace la consulta
       * con un timeout de 500ms
       * para no hacer tantas consultas
       * @type {NodeJS.Timeout}
       * @returns {void}
       * @param {string} addressQuerysearch
       * */
      setIsLoading(true);
      if (buscandoDireccion) {
        setIsLoading(false);
        const timeoutId = setTimeout(fetchAddressOptions, 500);
        return () => clearTimeout(timeoutId);
      }
      /**
       * En caso que se haya escrito la dirección, se hace la consulta
       */
      if (!buscandoDireccion && !isPointEditing) {
        setAddressOptions([
          {
            lat: latLon[0],
            lon: latLon[1]
          }
        ]);
        setIsLoading(false);
        setIsLoading(false);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addressQueryParams,
    addressQuerysearch,
    buscandoDireccion,
    isPointEditing
  ]);

  useEffect(() => {
    if (!addressOptions[0]) return;
    setLatLon([
      parseFloat(addressOptions[0].lat),
      parseFloat(addressOptions[0].lon)
    ]);
    setTemporalPosition([
      parseFloat(addressOptions[0].lat),
      parseFloat(addressOptions[0].lon)
    ]);
  }, [addressOptions, setLatLon]);

  const handleZoom = event => {
    setZoom(event.target._zoom);
  };

  useEffect(() => {
    if (mapRef.current && latLon && !isPointEditing) {
      mapRef.current.fitBounds([latLon, latLon]);
    }
  }, [latLon, isPointEditing]);

  useEffect(() => {
    if (mapRef.current && !isPointEditing) {
      mapRef.current.fitBounds([latLon, latLon]);
    }
  }, [latLon, isPointEditing]);

  useEffect(() => {}, [editedCustomAddress]);

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        className="w-full h-[300px] rounded relative z-0 flex justify-center items-center mt-3"
      />
    );
  }

  return (
    <MapContainer
      ref={mapRef}
      style={{ height: '300px' }}
      center={latLon}
      zoom={zoom}
      onZoomEnd={handleZoom}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />

      {isPointEditing ? (
        <DraggableMarker
          position={temporalPosition}
          setPosition={setTemporalPosition}
          setLatLon={setLatLon}
          setErrorMessage={setErrorMessage}
          markerUsuario={markerUsuario}
        />
      ) : (
        <Marker
          position={latLon}
          icon={markerUsuario}
          eventHandlers={{
            click: event => {
              if (isPointEditing) {
                const clickedLatLon = [event.latlng.lat, event.latlng.lng];
                setLatLon(clickedLatLon);
                setTemporalPosition(clickedLatLon);
                setIsPointEditing(false);
              }
            },
            dragend: event => {
              if (!isPointEditing) return;
              setTemporalPosition([event.latlng.lat, event.latlng.lng]);
            }
          }}
        />
      )}

      {errorMessage && <ErrorMessageControl message={errorMessage} />}
    </MapContainer>
  );
};

export default MapAddress;
