import { useMemo } from 'react';
import moment from 'moment';
import Text from 'components/design-system/Text/Text';

export const ColumnsTableLogs = () => {
  const columnTablesLogs = useMemo(
    //quiero crear un codigo que calcule el rut
    () => [
      {
        header: 'Código',
        enableGlobalFilter: false,
        accessorKey: 'codigoLog',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Empresa',
        accessorKey: 'usuario.sucursal.empresa.nombreEmpresa',
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Sucursal',
        accessorKey: 'usuario.sucursal.nombreSucursal',
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        )
      },
      {
        header: 'Correo usuario',
        enableGlobalFilter: false,
        accessorKey: 'usuario.correoElectronico',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Acción',
        accessorKey: 'accion',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>
      },
      {
        header: 'Descripción',
        enableGlobalFilter: false,
        accessorKey: 'descripcionAccion',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Fecha registro',
        enableGlobalFilter: false,
        accessorKey: 'fechaRegistroLog',
        Cell: ({ renderedCellValue }) => (
          <Text>{moment(renderedCellValue).format('DD-MM-YYYY')}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      }
    ],
    []
  );
  return columnTablesLogs;
};
