import { useContext } from 'react';
import { Marker } from 'react-leaflet';
import { MatchContext } from '../../../context/MatchContext';

/**
 * @name MarcadorAmpliada
 * Componente que muestra marcador en el mapa
 * @param sucursal datos de la sucursal asociada al marcador
 * @param markers referencia al marcador posicionado en el mapa
 * @param getMapIcon renderiza el tipo de icono a desplegar según el rol de la empresa
 */
export const MarcadorAmpliada = ({ sucursal, markers, getMapIcon }) => {
  const { setSucursalATSeleccionada, sucursalATSeleccionada } =
    useContext(MatchContext);

  const marcador = {
    codigoSucursal: sucursal.codigoSucursal,
    nombreEmpresa: sucursal.razonSocial,
    nombreSucursal: 'Sucursal',
    direccion: `${sucursal?.direccion?.nombreVia}, ${sucursal?.direccion?.comuna }, Región ${sucursal?.direccion?.region}`,
    coordenadas: [sucursal.direccion.latitud, sucursal.direccion.longitud],
    rol: sucursal.rol,
    rubro: sucursal.rubro || 'Sin rubro',
    actividad: sucursal.actividad || ''
  };

  const handleSeleccionarSucursal = ev => {
    setSucursalATSeleccionada(sucursal);
  };

  const getOpacity = () => {
    if (sucursalATSeleccionada === null) {
      return 1;
    }

    return sucursalATSeleccionada.codigoEmpresaAT === sucursal.codigoEmpresaAT
      ? 1
      : 0.2;
  };

  const getIndex = () => {
    if (sucursalATSeleccionada === null) {
      return 1;
    }
    return sucursalATSeleccionada.codigoEmpresaAT === sucursal.codigoEmpresaAT
      ? 100
      : 1;
  };

  return (
    <Marker
      eventHandlers={{
        click: e => {
          handleSeleccionarSucursal();
        }
      }}
      position={marcador.coordenadas}
      boundsOptions={[marcador.coordenadas]}
      ref={el => {
        markers.current = {
          ...markers.current,
          [marcador.codigoSucursal]: el
        };
      }}
      key={marcador.codigoSucursal}
      icon={getMapIcon(marcador.rol)}
      opacity={getOpacity()}
      zIndexOffset={getIndex()}
    ></Marker>
  );
};
