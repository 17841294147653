import Heading from 'components/design-system/Heading/Heading';
import imgHomeUser from 'assets/images/home/grafica-home-user.svg';
import { CuadroAdminResumen } from './componentes/CuadroAdminResumen';
import { CuadroRecomendaciones } from './componentes/CuadroRecomendaciones';
import {
  AccountBalance as AccountBalanceIcon,
  ArrowOutward as ArrowOutwardIcon,
  ArticleOutlined as ArticleOutlinedIcon,
  CallReceived as CallReceivedIcon,
  Domain as DomainIcon,
  FindReplace as FindReplaceIcon,
  Handshake as HandshakeIcon,
  InvertColors as InvertColorsIcon,
  Person as PersonIcon,
  Warehouse as WarehouseIcon
} from '@mui/icons-material';
import { useContext } from 'react';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';

export const HomeSuperAdminyAnalista = ({ logeduser, isLoading, data }) => {
  const {
    isTienePermisos,
    perfiles: { isSuperAdmin }
  } = useContext(AuthContext);

  const resumen = [
    {
      accesor: 'numeroEmpresas',
      titulo: 'PLATAFORMA',
      icono: <AccountBalanceIcon className="w-8 h-8" />,
      enabled: isSuperAdmin,
      descripcion: (
        <>
          Negocios
          <br />
          <b>registrados</b>
        </>
      )
    },
    {
      accesor: 'numeroSucursales',
      titulo: '',
      icono: <WarehouseIcon className="w-8 h-8" />,
      enabled: isSuperAdmin,
      descripcion: (
        <>
          Sucursales
          <br />
          <b>registradas</b>
        </>
      )
    },
    {
      accesor: 'numeroUsuarios',
      titulo: '',
      icono: <PersonIcon className="w-8 h-8" />,
      enabled: isSuperAdmin,
      descripcion: (
        <>
          Usuarios
          <br />
          <b>asociados</b>
        </>
      )
    },
    {
      accesor: 'numeroTransacciones',
      titulo: 'MODULO MARKETPLACE',
      icono: <HandshakeIcon className="w-8 h-8" />,
      enabled: isSuperAdmin,
      descripcion: (
        <>
          Transacciones
          <br />
          <b>concretadas</b>
        </>
      )
    },
    {
      accesor: 'numeroResiduosOfertados',
      titulo: '',
      icono: <ArrowOutwardIcon className="w-8 h-8" />,
      enabled: isSuperAdmin,
      descripcion: (
        <>
          Residuos
          <br />
          <b>ofertados</b>
        </>
      )
    },
    {
      accesor: 'numeroResiduosRequeridos',
      titulo: '',
      icono: <CallReceivedIcon className="w-8 h-8" />,
      enabled: isSuperAdmin,
      descripcion: (
        <>
          Residuos
          <br />
          <b>requeridos</b>
        </>
      )
    },
    {
      accesor: 'numeroResiduosDeclarados',
      titulo: 'MODULO GESTIONA',
      icono: <ArrowOutwardIcon className="w-8 h-8" />,
      enabled: isSuperAdmin,
      descripcion: (
        <>
          Residuos
          <br />
          <b>declarados</b>
        </>
      )
    },
    {
      accesor: 'numeroRecursosAdquiridos',
      titulo: '',
      icono: <CallReceivedIcon className="w-8 h-8" />,
      enabled: isSuperAdmin,
      descripcion: (
        <>
          Recursos
          <br />
          <b>adquiridos</b>
        </>
      )
    },
    {
      accesor: '',
      titulo: '',
      icono: null,
      enabled: false,
      descripcion: <></>
    },
    {
      accesor: 'numeroEmpresasAT',
      titulo: 'ANÁLISIS TERRITORIAL',
      icono: <DomainIcon className="w-8 h-8" />,
      enabled: isTienePermisos(PERMISOS.empresaAT) || isSuperAdmin,
      descripcion: (
        <>
          Empresas
          <br />
        </>
      )
    },
    {
      accesor: 'numeroResiduosAT',
      titulo: '',
      icono: <InvertColorsIcon className="w-8 h-8" />,
      enabled: isTienePermisos(PERMISOS.residuoAT) || isSuperAdmin,
      descripcion: (
        <>
          Residuos
          <br />
        </>
      )
    },
    {
      accesor: 'numeroPotencialSinergia',
      titulo: '',
      icono: <FindReplaceIcon className="w-8 h-8" />,
      enabled: isTienePermisos(PERMISOS.potencialSinergia) || isSuperAdmin,
      descripcion: (
        <>
          Potencial
          <br />
          <b>de sinergia</b>
        </>
      )
    },
    {
      accesor: 'numeroTransacciones',
      titulo: 'MODULO MARKETPLACE',
      icono: <HandshakeIcon className="w-8 h-8" />,
      enabled: isTienePermisos(PERMISOS.transaccionesAT) && !isSuperAdmin,
      descripcion: (
        <>
          Transacciones
          <br />
          <b>concretadas</b>
        </>
      )
    }
  ];

  let accesosDirectos = [
    isTienePermisos(PERMISOS.accesoBusquedaResiduos),
    isTienePermisos(PERMISOS.accesoResiduoDeclarado),
    isTienePermisos(PERMISOS.accesoResiduoOfertado),
    isTienePermisos(PERMISOS.accesoResiduoRequerido)
  ];

  return (
    <>
      {accesosDirectos.some(p => p) ? (
        <div className="hidden md:block h-8 relative">
          <Heading
            className="!text-uv-primary-0 absolute bottom-2 left-0 capitalize"
            type="H1"
          >
            Hola {logeduser.nombreUsuario.toLowerCase()}
          </Heading>
        </div>
      ) : (
        <div
          className="hidden md:block h-56 bg-no-repeat bg-[center_bottom_-24px] relative"
          style={{ backgroundImage: `url(${imgHomeUser})` }}
        >
          <Heading
            className="!text-uv-primary-0 absolute bottom-2 left-0 capitalize"
            type="H1"
          >
            Hola {logeduser.nombreUsuario.toLowerCase()}
          </Heading>
        </div>
      )}

      <div className="block md:hidden">
        <Heading className="!text-uv-primary-0 capitalize pb-4" type="H1">
          Hola {logeduser.nombreUsuario.toLowerCase()}
        </Heading>
      </div>

      {isTienePermisos(PERMISOS.dashboard) ? (
        <CuadroAdminResumen
          usuario={logeduser}
          resumenCuenta={data}
          isLoading={isLoading}
          resumen={resumen}
          numColumn={isSuperAdmin ? 3 : 4}
        />
      ) : null}

      <CuadroRecomendaciones isSuperAdmin={isSuperAdmin} />
    </>
  );
};
