import React, { useContext } from 'react';
import { List } from '@mui/material';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import { RequestQuote } from '@mui/icons-material';
import GavelIcon from '@mui/icons-material/Gavel';
import CalculateIcon from '@mui/icons-material/Calculate';
import { ContenidoRestringido } from 'components/design-system/ContenidoRestringido/ContenidoRestringido';
import Text from 'components/design-system/Text/Text';
import LinkComponent from 'components/design-system/Link/Link';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { PERMISOS } from 'const/Permisos';
import { AuthContext } from 'context';
import { RUTAS } from 'const/Rutas';

const SubMenuGestiona = React.forwardRef(({ forceRefresh }, ref) => {
  const {
    perfiles: { isSuperAdmin },
    logeduser
  } = useContext(AuthContext);
  const tieneNegocio = logeduser.sucursal ? true : false;

  return (
    <List ref={ref}>
      {!tieneNegocio && !isSuperAdmin ? (
        <div className="col-span-12">
          <ContenidoRestringido>
            <Text size="S">
              Solo{' '}
              <b className="text-black">usuarios con negocios registrados</b>{' '}
              pueden acceder a las opciones de reportes.
              <LinkComponent href={RUTAS.REGISTRAR_NEGOCIO} className="ml-2">
                Registrar un negocio
              </LinkComponent>
            </Text>
          </ContenidoRestringido>
        </div>
      ) : null}

      <VerificarPermisos nombrePermiso={PERMISOS.recursoAdquirido}>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.GESTIONA_RECURSO_ADQUIRIDO)}
          icon={<RequestQuote />}
          label={'Recurso Adquirido'}
        />
      </VerificarPermisos>

      <VerificarPermisos nombrePermiso={PERMISOS.residuoDeclarado}>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.GESTIONA_RESIDUO_DECLARADO)}
          icon={<GavelIcon />}
          label={'Residuo Declarado'}
        />
      </VerificarPermisos>

      <VerificarPermisos nombrePermiso={PERMISOS.calculadoraIndicadores}>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.FORMULARIO_CALCULO_INDICADORES)}
          icon={<CalculateIcon />}
          label={'Calculadora Indicadores'}
        />
      </VerificarPermisos>
    </List>
  );
});

export default SubMenuGestiona;
