import { useMemo } from 'react';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import MapAddress from 'components/MapAddress/MapAddress';
import MyLocation from '@mui/icons-material/MyLocation';
import Text from 'components/design-system/Text/Text';
import { Divider } from '@mui/material';
import { GpsOff } from '@mui/icons-material';
import { useDireccion } from '../../../hooks/useDireccion';
import { DICTONARY } from 'const/Dictonary';

export const SucursalCampos = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  setFieldTouched,
  isSubmitting
}) => {
  const {
    latLon,
    setLatLon,
    addressQuerysearch,
    addressQueryParams,
    regiones,
    cargaListaRegiones,
    handleObtenerDireccion,
    handleObtenerComunas,
    listadoComunas,
    cargaListaComunas,
    setBuscandoDireccion,
    buscandoDireccion,
  } = useDireccion();

  //  Servicio Comunas
  useMemo(() => {
    if (!values.region) return;
    if (values.region && values.region.codigoRegion) {
      handleObtenerComunas(values.region.codigoRegion);
    }
  }, [handleObtenerComunas, values.region]);

  // Estados de carga de servicios
  useMemo(() => {
    if (isSubmitting) return;
    handleObtenerDireccion(values);
  }, [isSubmitting, handleObtenerDireccion, values]);

  // Verificacion de cambios en los campos de direccion
  useMemo(() => {
    const { nombreVia, comuna, region, isPointEditing } = values;

    if (isSubmitting || !latLon) return;

    const hasNombreVia = nombreVia && touched.nombreVia;
    const hasComuna = comuna && touched.comuna;
    const hasRegion = region && touched.region;

    if ((hasNombreVia || hasComuna || hasRegion) || isPointEditing) {
      setBuscandoDireccion(true);
      setFieldValue('latitudDireccion', latLon[0]);
      setFieldValue('longitudDireccion', latLon[1]);
    }
  }, [isSubmitting, latLon, setBuscandoDireccion, setFieldValue, touched.comuna, touched.nombreVia, touched.region, values]);



  return (
    <>
      <div className="grid grid-cols-12 gap-4 pb-6">
        <div className="col-span-12">
          <Heading type="h4" className="mb-0">
            Información de la sucursal
          </Heading>
        </div>
        <div className="col-span-12 xs:col-span-6  lg:col-span-4">
          <InputComponent
            fullWidth
            name="nombreSucursal"
            value={values.nombreSucursal}
            onChange={handleChange}
            label={<LabelComponent>Nombre</LabelComponent>}
            onBlur={() => setFieldTouched('nombreSucursal')}
            touched={touched.nombreSucursal ? true : false}
            estado={
              errors.nombreSucursal
                ? { tipo: 'error', mensaje: errors.nombreSucursal }
                : null
            }
          />
        </div>
        <div className="col-span-12 xs:col-span-6 lg:col-span-4">
          <InputComponent
            fullWidth
            name="telefono"
            value={values.telefono}
            onBlur={() => setFieldTouched('telefono')}
            touched={touched.telefono ? true : false}
            label={<LabelComponent esOpcional>Teléfono</LabelComponent>}
            estado={
              errors.telefono
                ? { tipo: 'error', mensaje: errors.telefono }
                : null
            }
            onChange={event => {
              const value = event.target.value;
              if (/^\d*$/.test(value)) {
                setFieldValue('telefono', value || null); // establece el valor del campo
              }
            }}
          />
        </div>

        <div className="col-span-12 mt-3 mb-3">
          <Divider />
        </div>

        <div className="col-span-12">
          <Heading type="h4" className="mb-0">
            Dirección
          </Heading>
          <Text className="mb-2">
            Considera que la dirección que ingreses será usada para entregarte
            resultados basados en tu ubicación
          </Text>
        </div>
        {/* Región */}
        <div className="col-span-12 xs:col-span-6 lg:col-span-4">
          <AutocompleteComponent
            name="region"
            clearOnEscape={true}
            accesor="nombreRegion"
            openOnFocus={true}
            options={regiones}
            disabled={!cargaListaRegiones}
            onChange={(ev, region, reason) => {
              let data = { name: 'region', value: region };
              if (reason === 'clear') {
                data.value = [];
                setFieldValue('comuna', []);
              }
              setFieldValue('comuna', []);
              setFieldValue('region', data.value);

              // Tocar los 3 campos para realizar la busqueda
              setFieldTouched('region');
              setFieldTouched('comuna');
              setFieldTouched('nombreVia');

            }}
            onBlur={() => setFieldTouched('region')}
            value={values.region}
            getOptionLabel={option => option.nombreRegion || ''}
            isOptionEqualToValue={(option, value) =>
              option.nombreRegion === values.region.nombreRegion
            }
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              touched={touched.region ? true : false}
              estado={
                errors.region ? { tipo: 'error', mensaje: errors.region } : null
              }
              label={<LabelComponent>Región</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>

        {/* Comuna */}
        <div className="col-span-12 xs:col-span-6 lg:col-span-4">
          <AutocompleteComponent
            name="comuna"
            accesor="nombreComuna"
            options={listadoComunas}
            disabled={!cargaListaComunas}
            onChange={(ev, comuna, reason) => {

              let data = { name: 'comuna', value: comuna };

              if (reason === 'clear') {
                data.value = [];
              }

              setFieldValue('comuna', data.value);

              // Tocar los 3 campos para realizar la busqueda
              setFieldTouched('region');
              setFieldTouched('comuna');
              setFieldTouched('nombreVia');
            }}
            value={values.comuna}
            getOptionLabel={option => option.nombreComuna || ''}
            isOptionEqualToValue={(option, value) =>
              option.nombreComuna === values.comuna.nombreComuna
            }
            onBlur={() => setFieldTouched('comuna')}
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              touched={touched.comuna ? true : false}
              estado={
                errors.comuna ? { tipo: 'error', mensaje: errors.comuna } : null
              }
              label={<LabelComponent>Comuna</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>

        {/* NombreVia */}
        <div className="col-span-12 xs:col-span-8 lg:col-span-8">
          <InputComponent
            autoComplete={'off'}
            fullWidth
            label={<LabelComponent>Nombre y número calle</LabelComponent>}
            name="nombreVia"
            value={values.nombreVia}
            onChange={e => {
              handleChange(e);
              // Tocar los 3 campos para realizar la busqueda
              setFieldTouched('region');
              setFieldTouched('comuna');
              setFieldTouched('nombreVia');
            }}
            onBlur={() => setFieldTouched('nombreVia')}
            touched={touched.nombreVia ? true : false}
            placeholder="Ej: Providencia, 1091"
            estado={
              errors.nombreVia
                ? { tipo: 'error', mensaje: errors.nombreVia }
                : null
            }
          />
        </div>

        <div className="col-span-12  xxs:col-span-12 xs:col-span-12 ">
          <div className={`${values.isPointEditing ? ' bg-warning-light'  : 'bg-info-light '}
                           sm:flex px-4 py-2.5 justify-between rounded items-center`}>
            <Text>
              {values.isPointEditing 
                ? DICTONARY.MAPA_MENSAJES.INFO_DESACTIVAR_EDICION 
                : DICTONARY.MAPA_MENSAJES.INFO_ACTIVAR_EDICION}
           
            </Text>
            <ButtonComponent
              className="min-w-[190px] md:min-w-fit mt-4 sm:mt-0"
              onClick={() => {
                setFieldValue('isPointEditing', !values.isPointEditing);
              }}
            >
              {values.isPointEditing && latLon ? (
                <>
                  Desactivar edición <GpsOff className="ml-2" />
                </>
              ) : (
                <>
                  Edición manual <MyLocation className="ml-2" />
                </>
              )}
            </ButtonComponent>
          </div>
        </div>
        <div className="col-span-12 rounded overflow-hidden">
          <MapAddress
            addressQuerysearch={addressQuerysearch}
            addressQueryParams={addressQueryParams}
            latLon={latLon}
            setLatLon={setLatLon}
            isPointEditing={values.isPointEditing}
            setIsPointEditing={value => setFieldValue('isPointEditing', value)}
            isLoading={isSubmitting}
            buscandoDireccion={buscandoDireccion}
          />
        </div>
        <div className="col-span-12 mt-3 mb-3">
          <Divider />
        </div>
      </div>
    </>
  );
};
