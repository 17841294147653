
import { Divider } from "@mui/material"


export const SaltoLinia = (text) => {
  return <div className="col-span-12 my-8">
    <Divider />
  </div>
}


