import { Divider } from '@mui/material';

export const SaltoLinia = text => {
  return (
    <div className="col-span-12 my-8">
      <Divider />
    </div>
  );
};

export const diccionarioVista = {
  tituloListado: 'Listado de residuos declarados',
  tituloTabla: 'Residuos declarados ingresados',
  nombrePrincipal: 'Residuo Declarado',
  nombrePrincipalMayuscula: 'RESIDUO DECLARADO',
  nombrePrincipalSingular: 'residuo declarado',
  nombrePrincipalPlural: 'residuos declarados',
  primerNombrePrincipal: 'residuo'
};
