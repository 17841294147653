export const ICON_RECICLADORBASE_TIPO_C = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 108 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1101_107)">
        <path
          d="M104 82.5085V27.5085L54.0089 0L4 27.5085V82.5085L54.0089 110L104 82.5085Z"
          fill="url(#paint0_linear_1101_107)"
        />
        <path
          d="M101.965 81.1493V28.613L54.2207 2.34485L6.45898 28.613V81.1493L54.2207 107.4L101.965 81.1493Z"
          fill="url(#paint1_linear_1101_107)"
        />
        <path
          d="M99.7927 79.7901V29.5645L54.1533 4.45178L8.49609 29.5645V79.7901L54.1533 104.886L99.7927 79.7901Z"
          fill="url(#paint2_linear_1101_107)"
        />
        <path
          d="M54.4891 10.3814L94.7423 32.5208V76.7994L54.4891 98.9557L14.2358 76.8164V32.5378L54.4891 10.3814ZM54.4891 9.68481L13.5938 32.181V77.1732L54.4891 99.6694L95.3844 77.1732V32.181L54.4891 9.68481Z"
          fill="url(#paint3_linear_1101_107)"
        />
        <path
          d="M54.2007 85.2953L55.6275 88.0308L58.7843 88.4726L56.5014 90.5965L57.0365 93.6039L54.2007 92.1936L51.365 93.6039L51.9179 90.5965L49.6172 88.4726L52.7918 88.0308L54.2007 85.2953Z"
          fill="#FCE2CD"
        />
      </g>
      <g filter="url(#filter1_d_1101_107)">
        <path
          d="M37.7484 39.7315L41.6341 33.5962L44.4329 29.1696C45.4926 27.4869 48.0468 27.4869 49.1066 29.1696L53.1281 35.5378L49.7859 40.8705L47.0686 45.0642L37.7484 39.7315ZM81.0887 54.7977L76.7411 47.9117L67.3394 53.0891L73.3445 62.6414H76.415C78.4801 62.6414 80.3551 61.5283 81.2789 59.768C81.6593 59.0432 81.8495 58.2665 81.8495 57.464C81.8495 56.5321 81.5778 55.6261 81.0887 54.7977ZM65.546 75.5849H69.6219C71.687 75.5849 73.5619 74.4718 74.4858 72.7115L78.4258 65.2301H65.546V60.0527L54.677 70.4075L65.546 80.7623V75.5849ZM49.2424 65.2301H37.5582L35.2757 68.8802C34.4606 70.1746 34.4062 71.7795 35.1127 73.1516C35.8735 74.6271 37.4767 75.5849 39.2429 75.5849H49.2424V65.2301ZM38.6995 58.3701L43.4003 61.0623L39.6777 46.8504L24.7871 50.3969L29.4064 53.0374L28.2924 54.8236C27.3413 56.3509 27.2598 58.2148 28.1022 59.7939L32.5313 68.233L38.6995 58.3701ZM68.3176 34.5282L64.7852 28.9107C63.7798 27.3575 62.0136 26.3997 60.1115 26.3997H50.5196L58.9974 39.8609L54.3237 42.5273L69.2415 46.0738L72.9641 31.8618L68.3176 34.5282Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1101_107"
          x="0"
          y="0"
          width="108"
          height="118"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1101_107"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1101_107"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1101_107"
          x="20.7871"
          y="26.3997"
          width="65.0625"
          height="62.3627"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1101_107"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1101_107"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1101_107"
          x1="-3.08596"
          y1="97.0102"
          x2="94.9966"
          y2="17.4912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FCE2CD" />
          <stop offset="0.2181" stop-color="#C7885D" />
          <stop offset="0.527" stop-color="#FCE2CD" />
          <stop offset="0.5568" stop-color="#F8DDC8" />
          <stop offset="0.5923" stop-color="#EECFBA" />
          <stop offset="0.6307" stop-color="#DDB8A2" />
          <stop offset="0.6711" stop-color="#C59880" />
          <stop offset="0.7127" stop-color="#A77055" />
          <stop offset="0.7278" stop-color="#9B5F44" />
          <stop offset="1" stop-color="#5F2A16" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1101_107"
          x1="17.9016"
          y1="32.2076"
          x2="103.367"
          y2="90.9956"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1371" stop-color="#C7885D" />
          <stop offset="0.9324" stop-color="#9B5F44" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1101_107"
          x1="85.8812"
          y1="95.5178"
          x2="28.8561"
          y2="14.6551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FCE2CD" />
          <stop offset="0.4884" stop-color="#C7885D" />
          <stop offset="1" stop-color="#9B5F44" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1101_107"
          x1="13.5991"
          y1="54.6744"
          x2="95.387"
          y2="54.6744"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.003861" stop-color="#FCE2CD" />
          <stop offset="1" stop-color="#C7885D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
