import { Form, Formik } from 'formik';
import { NuevosCriteriosCampos } from './NuevosCriteriosCampos';
import { useContext } from 'react';
import { MatchContext } from '../../../context/MatchContext';
import Heading from 'components/design-system/Heading/Heading';
import { FormControlLabel } from '@mui/material';
import SwitchComponent from 'components/design-system/Switch/Switch';
import Text from 'components/design-system/Text/Text';
import { memo } from 'react';
import { schemaMatchManual } from 'views/Marketplace/Residuos/schema/schemaMatchManual';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { PERMISOS } from 'const/Permisos';

/**
 * @name NuevosCriteriosMatch
 * Componente con formulario formilk + campos para establecer nuevos criterios en match manual
 */
export const NuevosCriteriosMatch = memo(() => {
  const today = new Date();
  const todayPlusMonth = new Date(today.setMonth(today.getMonth() + 1));
  const {
    setIsNuevosCriterios,
    handleMatchManual,
    criteriosMatch,
    tieneNegocio
  } = useContext(MatchContext);

  const getInitialValues = () => {
    if (tieneNegocio) {
      return {
        codigosTipoResiduo: '',
        codigosRecurso: '',
        fechaDisponibilidadInicio: new Date(),
        fechaDisponibilidadFin: todayPlusMonth,
        busquedaExtendida: criteriosMatch.isBusquedaAmpliada
      };
    } else {
      const tipoRecursos =
        criteriosMatch.tipoRecursos.length > 0
          ? {
              nombreRecurso: criteriosMatch.tipoRecursos[0].nombre,
              codigoRecurso: criteriosMatch.tipoRecursos[0].codigo
            }
          : '';
      const tipoResiduos =
        criteriosMatch.tipoResiduos.length > 0
          ? {
              nombreTipo: criteriosMatch.tipoResiduos[0].nombre,
              codigoTipoResiduo: criteriosMatch.tipoResiduos[0].codigo
            }
          : '';

      return {
        codigosTipoResiduo: tipoResiduos,
        codigosRecurso: tipoRecursos,
        fechaDisponibilidadInicio: new Date(),
        fechaDisponibilidadFin: todayPlusMonth,
        busquedaExtendida: criteriosMatch.isBusquedaAmpliada
      };
    }
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={tieneNegocio ? null : schemaMatchManual}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitting(true);
          let formValues;
          if (tieneNegocio) {
            formValues = values;
          } else {
            formValues = {
              ...values,
              fechaDisponibilidadInicio: new Date(),
              fechaDisponibilidadFin: todayPlusMonth
            };
          }
          handleMatchManual(formValues);
          setSubmitting(false);
        } catch (error) {
          console.log('error nuevosCriteriosMatch >>', error);
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        isSubmitting,
        validateForm,
        setFieldTouched
      }) => (
        <Form
          id="nuevosCriterios"
          className="grid grid-cols-12 items-start content-end py-5"
        >
          <div className="col-span-4 lg:col-span-4 md:pr-5">
            <Heading
              className="flex content-center items-center font-medium mb-4"
              type="H4"
            >
              <span>
                <b className="text-black font-bold">Establecer criterios</b>{' '}
                para <br className="hidden md:block" />
                búsqueda de match
              </span>
            </Heading>

            <VerificarPermisos
              nombrePermiso={PERMISOS.busquedaEmpresaExtendido}
            >
              <FormControlLabel
                className="mb-4 block xs:flex xs:flex-col sm:flex-row xs:mx-0"
                name="busquedaExtendida"
                control={
                  <SwitchComponent
                    disabled={isSubmitting}
                    checked={values.busquedaExtendida}
                    onChange={(ev, val) => {
                      handleChange({
                        target: { name: 'busquedaExtendida', value: val }
                      });
                    }}
                    name="limitarRadio"
                  />
                }
                label={
                  <Text className="font-semibold">
                    Activar búsqueda extendida
                  </Text>
                }
              />
              <Text size="S">
                La busqueda extendida permite realizar match con empresas que no
                están registradas en la plataforma pero registradas en el
                servicio de impuestos internos (SII)
              </Text>
            </VerificarPermisos>
          </div>
          <div className="col-span-8 lg:col-span-8 pl-5">
            <div className="grid grid-cols-12 gap-4 items-end content-end pl-2">
              <NuevosCriteriosCampos
                handleChange={handleChange}
                values={values}
                errors={errors}
                touched={touched}
                setFieldTouched={setFieldTouched}
                isSubmitting={isSubmitting}
                validateForm={validateForm}
                setIsNuevosCriterios={setIsNuevosCriterios}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
});
