import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { CallReceived } from '@mui/icons-material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { LayoutOpciones } from './LayoutOpciones';
import { PERMISOS } from 'const/Permisos';
import { useContext } from 'react';
import { AuthContext } from 'context';
import { RUTAS } from 'const/Rutas';

export const CuadroAccesosRapidos = () => {
  const history = useHistory();
  const { isTienePermisos } = useContext(AuthContext);

  const obtenerBotones = () => {
    let botonesNavegacion = [];

    if (isTienePermisos(PERMISOS.accesoBusquedaResiduos)) {
      botonesNavegacion.push({
        onClick: () => history.push(RUTAS.MARKETPLACE_RESIDUOS),
        texto: (
          <>
            Iniciar una búsqueda
            <br />
            <strong>Residuo-recurso</strong>
            <b className="text-black"></b>
          </>
        ),
        icono: <Inventory2OutlinedIcon />
      });
    }

    // if (isTienePermisos(PERMISOS.residuoDeclarado)) {
    //   botonesNavegacion.push({
    //     onClick: () => history.push(RUTAS.MARKETPLACE_RESIDUOS),
    //     texto: (
    //       <>
    //         Iniciar una búsqueda
    //         <br />
    //         <strong>Residuo-recurso</strong>
    //         <b className="text-black"></b>
    //       </>
    //     ),
    //     icono: <Inventory2OutlinedIcon />
    //   });
    // }

    if (isTienePermisos(PERMISOS.accesoResiduoDeclarado)) {
      botonesNavegacion.push({
        onClick: () => history.push(RUTAS.INGRESAR_RESIDUO_DECLARADO),
        texto: (
          <>
            <strong>Residuo</strong> declarado
            <br />
            <b className="text-black"></b>
          </>
        ),
        icono: <Inventory2OutlinedIcon />
      });
    }

    if (isTienePermisos(PERMISOS.accesoResiduoOfertado)) {
      botonesNavegacion.push({
        onClick: () => history.push(RUTAS.OFRECER_RESIDUO),
        texto: (
          <>
            <b className="text-oferente">Ofrecer</b> un residuo
          </>
        ),
        icono: (
          <>
            <Inventory2OutlinedIcon />
            <div className="absolute right-0 top-0 w-5 h-5 bg-oferente text-white rounded">
              <CallReceived className="rotate-180 -ml-1 mt-0" />
            </div>
          </>
        )
      });
    }

    if (isTienePermisos(PERMISOS.accesoResiduoRequerido)) {
      botonesNavegacion.push({
        onClick: () => history.push(RUTAS.REQUERIR_RESIDUO),
        texto: (
          <>
            <b className="text-demandante">Requerir</b> un residuo
          </>
        ),
        icono: (
          <>
            <Inventory2OutlinedIcon />
            <div className="absolute right-0 top-0 w-5 h-5 bg-demandante text-white rounded">
              <CallReceived className="ml-0 -mt-1.5" />
            </div>
          </>
        )
      });
    }

    return botonesNavegacion;
  };

  return <LayoutOpciones opciones={obtenerBotones()} />;
};
