import Heading from 'components/design-system/Heading/Heading';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { diccionarioVista } from '../utils/utils';
import { useCallback, useContext, useEffect } from 'react';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';
import ButtonComponent from 'components/design-system/Button/Button';
import Table from 'components/design-system/Table/Table';
import { useColumnTablesListadoPerfiles } from '../hooks/useColumnTablesListadoPerfiles';
// Material
import { Add, Close, Delete, Edit } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Hidden,
  Tooltip,
  DialogActions
} from '@mui/material';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { useServicesPerfiles } from '../hooks/useServicesPerfiles';
import { useHelpersPerfiles } from '../hooks/useHelpersPerfiles';
import Text from 'components/design-system/Text/Text';
import AlertComponent from 'components/design-system/Alert/Alert';
const Perfiles = () => {
  const { isTienePermisos } = useContext(AuthContext);

  // Columns
  const columnTables = useColumnTablesListadoPerfiles();

  // Helpers
  const {
    handleClickIngresar,
    handleClickEditar,
    handleClickEliminar,
    handleClickOpen,
    handleClose,
    setOpen,
    open,
    selectedRow
  } = useHelpersPerfiles();

  // Exportaciones de useServicesRecursosAdquiridos
  const {
    //Funciones
    ObtieneListaPerfiles,
    // States
    perfiles,
    setPerfiles,
    columnFilters,
    globalFilter,
    isLoadingLista,
    pagination,
    rowCount,
    setColumnFilters,
    setGlobalFilter,
    setIsLoadingLista,
    setPagination,
    setRowCount
  } = useServicesPerfiles();

  const cargaPerfiles = useCallback(async () => {
    ObtieneListaPerfiles(
      columnFilters,
      setIsLoadingLista,
      setPerfiles,
      pagination,
      setRowCount
    );
  }, [
    ObtieneListaPerfiles,
    columnFilters,
    pagination,
    setIsLoadingLista,
    setPerfiles,
    setRowCount
  ]);

  useEffect(() => {
    cargaPerfiles(
      columnFilters,
      setIsLoadingLista,
      setPerfiles,
      pagination,
      setRowCount
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    columnFilters,
    pagination,
    setIsLoadingLista,
    setPerfiles,
    setRowCount
  ]);

  return (
    <SidebarLayout>
      <div className="mb-10">
        <div className="flex flex-col sm:flex-row sm:items-center mb-4 sm:justify-between gap-4">
          <div>
            <Heading type="h2" className="mb-0">
              {diccionarioVista.tituloListado || 'Sin título'}
            </Heading>
          </div>
          {isTienePermisos(PERMISOS.eliminarPerfil) && (
            <ButtonComponent type="primary" onClick={handleClickIngresar}>
              <Add className="mr-2" />{' '}
              {`Nuevo ${diccionarioVista.nombrePrincipal}`}
            </ButtonComponent>
          )}
        </div>
      </div>
      <Table
        headTable={
          <Heading type="h4" className="p-2">
            {diccionarioVista.tituloTabla || 'Sin título'}
          </Heading>
        }
        data={perfiles || []}
        columns={columnTables}
        rowCount={rowCount}
        isLoading={isLoadingLista}
        enablePagination={true}
        enableRowActions={
          isTienePermisos(PERMISOS.eliminarPerfil) ||
          isTienePermisos(PERMISOS.editarPerfil)
        }
        enableGlobalFilter={false}
        onPaginationChange={setPagination}
        state={{
          columnFilters,
          pagination,
          isLoading: isLoadingLista,
          showProgressBars: isLoadingLista,
          globalFilter
        }}
        manualPagination
        actions={({ row, table }) => (
          <div>
            {isTienePermisos(PERMISOS.crearPerfil) && (
              <Tooltip arrow placement="left" title="Editar">
                <IconButtonComponent
                  size="small"
                  className="mr-2"
                  disabled={isLoadingLista}
                  type={isLoadingLista ? 'disabled' : 'secondary'}
                  onClick={() => handleClickEditar(row, table)}
                >
                  <Edit />
                </IconButtonComponent>
              </Tooltip>
            )}

            {isTienePermisos(PERMISOS.eliminarPerfil) && (
              <Tooltip arrow placement="right" title="Eliminar">
                <IconButtonComponent
                  size="small"
                  disabled={isLoadingLista}
                  type={isLoadingLista ? 'disabled' : 'error'}
                  onClick={() => handleClickOpen(row, table)}
                >
                  <Delete />
                </IconButtonComponent>
              </Tooltip>
            )}
          </div>
        )}
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="border-b border-neutral-90"
        >
          <Heading type="h3" className="mb-0">
            Confirmación de eliminación del perfil
          </Heading>
        </DialogTitle>
        <DialogContent className="p-5">
          <DialogContentText
            id="alert-dialog-description"
            className="pt-4 pb-0"
          >
            <Text className="pb-4">
              ¿Estás seguro que deseas eliminar completamente este perfil?
            </Text>

            <AlertComponent type="error">
              Esta operación es irreversible y{' '}
              <b>se perderán todos los datos asociados a este perfil</b>
            </AlertComponent>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="border-t border-neutral-90 p-4 justify-between">
          <ButtonComponent
            type="secondary"
            onClick={handleClose}
            className="justify-center"
          >
            <Hidden smDown>Cancelar</Hidden>
            <Hidden smUp>
              <Close />
            </Hidden>
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            onClick={() =>
              handleClickEliminar(
                selectedRow,
                perfiles,
                setPerfiles,
                setOpen,
                cargaPerfiles
              )
            }
            autoFocus
            className="w-full xs:w-auto justify-center"
          >
            Confirmar
          </ButtonComponent>
        </DialogActions>
      </Dialog>
    </SidebarLayout>
  );
};

export default Perfiles;
