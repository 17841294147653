import React from 'react';
import { withRouter } from 'react-router-dom';
import { MatchProvider } from './context/MatchProvider';
import { InitMatch } from './InitMatch';

/**
 * @name MarketPlaceResiduos
 * Componente inicial donde se implementa context.
 */
const MarketPlaceResiduos = () => {
  return (
    <MatchProvider>
      <InitMatch />
    </MatchProvider>
  );
};

export default withRouter(MarketPlaceResiduos);
