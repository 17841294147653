import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { PopperComponent } from 'components/design-system/Popper/Popper';
import { PERMISOS } from 'const/Permisos';
import { AuthContext } from 'context';
import { useContext } from 'react';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import Text from 'components/design-system/Text/Text';
import SubMenuGestiona from '../responsive/submenu/SubMenuGestiona';

export const MenuGestiona = ({ forceRefresh }) => {
  const { isTienePermisos, logeduser } = useContext(AuthContext);
  const tieneNegocio = logeduser.sucursal ? true : false;

  return (
    <>
      {(isTienePermisos(PERMISOS.recursoAdquirido) ||
        isTienePermisos(PERMISOS.residuoDeclarado) ||
        isTienePermisos(PERMISOS.calculadoraIndicadores) ||
        (!tieneNegocio && isTienePermisos(PERMISOS.registrarNegocio))) && (
        <PopperComponent
          Button={
            <IconButtonComponent
              className="text-white rounded"
              aria-controls="gestiona-menu"
              aria-haspopup="true"
            >
              <ManageHistoryIcon />
              <Text className="text-white ml-1 font-secondary font-normal">
                Gestiona
              </Text>
            </IconButtonComponent>
          }
        >
          <div className="px-3 max-w-md">
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12">
                <div className="pop-section-item">
                  <SubMenuGestiona forceRefresh={forceRefresh} />
                </div>
              </div>
            </div>
          </div>
        </PopperComponent>
      )}
    </>
  );
};
