import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import LabelComponent from 'components/design-system/Label/Label';
import InputComponent from 'components/design-system/input/Input';
import InputNumber from 'components/design-system/input/InputNumber';
import { DICTONARY } from 'const/Dictonary';
import { SaltoLinia } from '../Formulario/utils/utils';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import Text from 'components/design-system/Text/Text';
import AlertComponent from 'components/design-system/Alert/Alert';
import LinkComponent from 'components/design-system/Link/Link';
import { ChevronRight } from '@mui/icons-material';

export const LimiteSuperado = ({ disponibilidad }) => {
  return (
    <SidebarLayout>
      <Heading type="h3">Generación de indicadores</Heading>
      <div className="flex w-full align-middle items-center relative">
        <div className="absolute z-20 text-center w-full">
          <div className="mx-auto w-2/3">
            <AlertComponent type="warning" className="text-left">
              La cálculadora no se encuentra disponibile debido a que has
              excedido el{' '}
              <b className="text-black font-bold">límite máximo mensual </b>{' '}
              para el cálculo de indicadores (cuota máxima de{' '}
              {disponibilidad.cuotaMaxima} cálculos al mes). Para modificar este
              límite ponte en contacto con nosotros
              <div className="mt-2">
                <LinkComponent
                  href={{ pathname: DICTONARY.JIRA_CONTACTO_URL }}
                  type="button"
                  target="_blank"
                >
                  Contactar <ChevronRight />
                </LinkComponent>
              </div>
            </AlertComponent>
            <Text className="mb-4"></Text>
          </div>
        </div>

        <div className="absolute z-10 w-full h-full"></div>

        <div className="bg-white rounded p-5 shadow-sm mb-5 blur-sm w-full opacity-60">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-12">
                  <Heading type="h4" className="mb-0">
                    Formulario
                  </Heading>
                </div>
                <div className="col-span-12 xs:col-span-12">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 md:col-span-6 xl:col-span-4">
                      <InputNumber
                        autoComplete={'off'}
                        maxDecimals={DICTONARY.NUMERO_DECIMALES}
                        type="text"
                        label={
                          <LabelComponent>
                            Cantidad <br />
                            <span className="text-neutral-60">(Toneladas)</span>
                          </LabelComponent>
                        }
                        name="cantidad"
                        onChange={event => {}}
                        onBlur={() => {}}
                        variant="outlined"
                        size="small"
                        value={''}
                        readOnly
                      />
                    </div>

                    <div className="col-span-12 md:col-span-6 xl:col-span-4">
                      <AutocompleteComponent
                        accesor="nombreTipo"
                        className="mb-3 "
                        clearOnEscape={true}
                        name="tipoResiduo"
                        openOnFocus={true}
                        options={[]}
                        value={''}
                        size="small"
                        variant="outlined"
                        getOptionLabel={option => option.nombreTipo || ''}
                        onChange={event => {}}
                      >
                        <InputComponent
                          fullWidth
                          onBlur={() => {}}
                          placeholder={'Seleccione tipo de residuo'}
                          label={
                            <LabelComponent>
                              Residuo
                              <br />
                              <span className="text-neutral-60">
                                (incluye subproductos)
                              </span>
                            </LabelComponent>
                          }
                        />
                      </AutocompleteComponent>
                    </div>

                    <div className="col-span-12 md:col-span-6 xl:col-span-4">
                      <AutocompleteComponent
                        accesor="nombreTipo"
                        className="mb-3 "
                        clearOnEscape={true}
                        name="subTipoResiduo"
                        openOnFocus={false}
                        options={[]}
                        size="small"
                        variant="outlined"
                        value={''}
                        getOptionLabel={option => option.nombreTipo || ''}
                        onChange={event => {}}
                      >
                        <InputComponent
                          fullWidth
                          onBlur={() => {}}
                          placeholder={'Seleccione subtipo de residuo'}
                          label={
                            <LabelComponent>
                              Subtipo de residuo
                              <br />
                              <span className="text-neutral-60">
                                (incluye subproductos)
                              </span>
                            </LabelComponent>
                          }
                        />
                      </AutocompleteComponent>
                    </div>
                  </div>
                  <SaltoLinia />
                  {/* Fila 2 */}
                  <div className="grid grid-cols-12 gap-4 mt-4">
                    <div className="col-span-12 md:col-span-12">
                      <Heading type="h4" className="mb-0">
                      {`Dirección ${DICTONARY.ROL.GENERADOR.NOMBRE}`}
                      </Heading>
                      <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-3 xl:col-span-3">
                          <div className="col-span-12 xs:col-span-6 md:col-span-3">
                            <AutocompleteComponent
                              name="regionOferente"
                              clearOnEscape={true}
                              accesor="nombreRegion"
                              openOnFocus={true}
                              options={[]}
                              onChange={event => {}}
                              onBlur={event => {}}
                              getOptionLabel={option =>
                                option.nombreRegion || ''
                              }
                            >
                              <InputComponent
                                autoComplete={'off'}
                                fullWidth
                                label={<LabelComponent>Región</LabelComponent>}
                              />
                            </AutocompleteComponent>
                          </div>
                        </div>
                        {/* Comuna */}
                        <div className="col-span-12 md:col-span-3 xl:col-span-3">
                          <div className="col-span-12 xs:col-span-6 md:col-span-3">
                            <AutocompleteComponent
                              name="comunaOferente"
                              accesor="nombreComuna"
                              options={[]}
                              onChange={event => {}}
                              onBlur={event => {}}
                              getOptionLabel={option =>
                                option.nombreComuna || ''
                              }
                            >
                              <InputComponent
                                autoComplete={'off'}
                                fullWidth
                                label={<LabelComponent>Comuna</LabelComponent>}
                              />
                            </AutocompleteComponent>
                          </div>
                        </div>
                        {/* nombreNumeroViaOferente */}
                        <div className="col-span-12 md:col-span-6 xl:col-span-6">
                          <div className="col-span-12 xs:col-span-8 lg:col-span-6">
                            <InputComponent
                              autoComplete={'off'}
                              fullWidth
                              label={
                                <LabelComponent>
                                  Nombre y número calle
                                </LabelComponent>
                              }
                              name="nombreNumeroViaOferente"
                              onChange={event => {}}
                              onBlur={event => {}}
                              placeholder="Ej: Providencia, 1091"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SidebarLayout>
  );
};
