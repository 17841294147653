import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import LinkComponent from 'components/design-system/Link/Link';
import Text from 'components/design-system/Text/Text';
import usuariosService from 'services/usuarios';
import { schemaSignUp } from './schema/SchemaSIgnup';
import iconoBeneficio1 from 'assets/images/registro/icono-registro-1.svg';
import iconoBeneficio2 from 'assets/images/registro/icono-registro-2.svg';
import iconoBeneficio3 from 'assets/images/registro/icono-registro-3.svg';
import iconoBeneficio4 from 'assets/images/registro/icono-registro-4.svg';
import { SignupForm } from './SignupForm';
import { AlertaError, AlertaExito, AlertaWarning } from 'utils/Alertas';

const Signup = props => {
  const history = useHistory();
  localStorage.removeItem('verificaUsuario');
  localStorage.removeItem('verificaUsuarioSucursal');

  const correoInvitado = history.location.state?.correoElectronico;
  const [cargando, setCargando] = useState(false);
  const descripcion =
    '¡No desperdicies recursos de tu empresa! Dale valor a través de la plataforma y aporta a una región más circular y sustentable.';

  const [token] = useState(history.location.state?.token || '');

  useEffect(() => {
    if (token) {
      AlertaExito('Verificación completada correctamente');
    }
  }, [token]);

  return (
    <section className="bg-gradient-to-b from-[#EFF5F4] to-white py-10 m-0 w-full">
      <div className="px-4 md:container md:mx-auto">
        <div className="grid grid-cols-12 gap-4 items-center">
          <div className="col-span-12 md:col-span-6 md:pr-10">
            <Heading type="h1">Únete a la industria circular</Heading>
            <Text className="mb-4">{descripcion}</Text>
            <Heading type="h5">Beneficios:</Heading>
            <ul className="mt-4 mb-4">
              <li className="flex items-center mb-4">
                <IconComponent className="mr-2 min-w-[36px] w-9">
                  <img
                    src={iconoBeneficio1}
                    alt="Beneficio uno"
                    className="w-10"
                  />
                </IconComponent>
                <Text>
                  Accede al Marketplace georeferenciado y conéctate con otras
                  empresas para valorizar recursos subutilizados (residuos no
                  peligrosos, subproductos industriales o materias primas
                  secundarias)
                </Text>
              </li>

              <li className="flex items-center mb-4">
                <IconComponent className="mr-2 min-w-[36px] w-9">
                  <img
                    src={iconoBeneficio2}
                    alt="Beneficio dos"
                    className="w-10"
                  />
                </IconComponent>
                <Text>
                  Encuentra potenciales compradores y socios para tu negocio de
                  valorización. Podrás utilizar nuestro algoritmo de búsqueda
                  automática para explorar nuevas oportunidades
                </Text>
              </li>

              <li className="flex items-center mb-4">
                <IconComponent className="mr-2 min-w-[36px] w-9">
                  <img
                    src={iconoBeneficio3}
                    alt="Beneficio tres"
                    className="w-10"
                  />
                </IconComponent>
                <Text>
                  Respalda los datos de gestión y valorización de recursos
                  subutilizados
                </Text>
              </li>

              <li className="flex items-center mb-2">
                <IconComponent className="mr-2 min-w-[36px] w-9">
                  <img
                    src={iconoBeneficio4}
                    alt="Beneficio cuatro"
                    className="w-10"
                  />
                </IconComponent>
                <Text>
                  Genera reportes en formato RETC e indicadores de desempeño
                  ambiental calculados con Análisis de Ciclo de Vida
                </Text>
              </li>
            </ul>
          </div>

          <div className="col-span-12 md:col-span-6">
            <div className="bg-white rounded shadow-md p-6">
              <Formik
                initialValues={{
                  name: '',
                  email: correoInvitado || '',
                  password1: '',
                  password2: '',
                  emailModificado: false
                }}
                validateOnMount={true}
                initialTouched={{
                  name: false,
                  email: correoInvitado ? true : false
                }}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values, { resetForm }) => {
                  setCargando(true);
                  const userName = values.name.split(' ');
                  let nombreUsuario = '';
                  let apellidoPaterno = '';
                  let apellidoMaterno = '';

                  if (userName.length > 3) {
                    nombreUsuario = userName[0] + ' ' + userName[1];
                    apellidoPaterno = userName[2];
                    apellidoMaterno = userName[3];
                  } else {
                    nombreUsuario = userName[0];
                    apellidoPaterno = userName[1];
                    apellidoMaterno = userName[2];
                  }

                  const formatResponse = {
                    correoElectronico: values.email,
                    nombreUsuario: nombreUsuario,
                    apellidoPaterno: apellidoPaterno,
                    apellidoMaterno: apellidoMaterno,
                    password: values.password1
                  };

                  // Servicio Autoregistro
                  usuariosService
                    .autoRegistro(
                      token
                        ? {
                            correoElectronico: formatResponse.correoElectronico,
                            nombreUsuario: formatResponse.nombreUsuario,
                            apellidoPaterno: formatResponse.apellidoPaterno,
                            apellidoMaterno: formatResponse.apellidoMaterno,
                            password: formatResponse.password,
                            token: token
                          }
                        : {
                            correoElectronico: formatResponse.correoElectronico,
                            nombreUsuario: formatResponse.nombreUsuario,
                            apellidoPaterno: formatResponse.apellidoPaterno,
                            apellidoMaterno: formatResponse.apellidoMaterno,
                            password: formatResponse.password
                          }
                    )
                    .then(response => {
                      const { data } = response;
                      if (data.error) {
                        AlertaWarning(data.error.mensaje);
                      } else {
                        history.push(
                          `/signup/verificacion?correo=${formatResponse.correoElectronico}`
                        );
                      }
                    })
                    .catch(error => {
                      AlertaError(error);
                    })
                    .finally(() => {
                      setCargando(false);
                    });
                }}
                validationSchema={schemaSignUp}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                  validateForm
                }) => (
                  <SignupForm
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    validateForm={validateForm}
                    cargando={cargando}
                    correoInvitado={correoInvitado}
                  />
                )}
              </Formik>
            </div>
            <div className="text-center my-3">
              <Text>
                ¿Ya tienes cuenta?{' '}
                <LinkComponent href="#" className="underline">
                  inicia sesión aquí
                </LinkComponent>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signup;
