import { useEffect, useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
// Components
import Loading from 'components/Loading/Loading';
// Design System
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import ButtonComponent from 'components/design-system/Button/Button';
import Text from 'components/design-system/Text/Text';
// Material
import { ChevronLeft, Close, Save } from '@mui/icons-material';
import { Divider, Hidden } from '@mui/material';
// Formik
import { Form, Formik } from 'formik';
// Schema
import { SchemaIngresaEmpresaAT } from '../Ingresar/schema/SchemaIngresaEmpresaAT';
//Formulario
// import FormularioIngresarEmpresasAT from '../Ingresar/components/FormularioIngresarEmpresasAT';
import FormularioEditarEmpresasAT from './components/FormularioEditarEmpresasAT';

import {
  obtenerEmpresaAT
} from 'views/CargaMasiva/hooks/useServices';
import { useSubmitEmpresasAT } from './utils/useSubmitEmpresasAT';
import { DICTONARY } from 'const/Dictonary';
const EditarEmpresaAT = () => {
  const { codigoEmpresa } = useParams();
  const navigate = useHistory();

  const [stateValidation, setStateValidation] = useState(true);

  const [empresaAT, setEmpresaAt] = useState([]);

  const [isLoadingEmpresa, setIsLoadingEmpresa] = useState(true);

  useEffect(() => {
    obtenerEmpresaAT({ codigoEmpresa, setEmpresaAt, setIsLoadingEmpresa });
  }, [codigoEmpresa, isLoadingEmpresa]);

  const {
    isLoading,
    setIsLoading,
    handleSubmit,
  } = useSubmitEmpresasAT(codigoEmpresa);

  return (
    <SidebarLayout>
      <>
        <div className="mb-4">
          <Heading type="h2" className="mb-0">
            <IconButtonComponent
              onClick={() => navigate.goBack()}
              type="secondary"
              className="mr-2"
            >
              <ChevronLeft />
            </IconButtonComponent>
            Editar Empresa
          </Heading>
          <Text className="mb-0 ml-4">Análisis Territorial</Text>
        </div>
        {!isLoadingEmpresa ? (
          // console.log(empresaAT),
          <Formik
            initialValues={{
              rutEmpresa: empresaAT?.rutEmpresa || '',
              razonSocial: empresaAT?.razonSocial || '',
              tramo: empresaAT?.tramo || {},
              actividad: empresaAT?.actividad || {},
              nombreVia: empresaAT?.direccion?.nombreVia || '',
              comuna: empresaAT?.direccion?.comuna || {},
              region: empresaAT?.direccion?.comuna?.region || {},
              latitudDireccion: empresaAT?.direccion?.latitudDireccion || '',
              longitudDireccion: empresaAT?.direccion?.longitudDireccion || '',
              codigoEmpresaAT: empresaAT.codigoEmpresaAT || '',
              potencialSinergias: empresaAT?.potencialSinergias || '',
            }}
            validationSchema={SchemaIngresaEmpresaAT}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={async (values, { setSubmitting }) => {
              //  console.log(values);
              setIsLoading(true);
              console.log('submiting final..');
              try {
                setTimeout(async () => {
                  await handleSubmit(values, 'editar');
                }, 1500);
              } catch (error) {
                console.log('error :>> ', error);
                setIsLoading(false);
                setSubmitting(false);
              }

            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleReset,
              handleSubmit,
              setFieldValue,
              handleBlur,
              setFieldError,
              setFieldTouched,
              isSubmitting,
              validateForm
            }) => (
              <Form
                onChange={event => {
                  //  console.log('errors  :>> ', errors);
                  // console.log('touched :>> ', touched);
                  //  console.log('values  :>> ', values);
                  setStateValidation(true);
                }}
              >
                <div className="px-4 md:container md:mx-auto">
                  <div className="bg-white py-5 rounded shadow-md">
                    <div className="col-span-12 lg:col-span-9 pl-4 pr-5">
                      <div className="grid grid-cols-12 gap-4 pb-6">
                        <FormularioEditarEmpresasAT
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleReset={handleReset}
                          handleSubmit={handleSubmit}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          setFieldError={setFieldError}
                          setFieldTouched={setFieldTouched}
                          isSubmitting={isSubmitting}
                          validateForm={validateForm}
                        />
                      </div>

                      <Divider />

                      <div className="my-5">
                        <div className="flex justify-between">
                          <div className="text-start">
                            <ButtonComponent
                              type="secondary"
                              className="w-full xs:w-auto justify-center"
                              isLoading={isSubmitting}
                              disabled={isSubmitting}
                              onClick={() => {
                                navigate.goBack();
                              }}
                            >
                              <>
                                <Hidden smDown>Cancelar</Hidden>
                                <Hidden smUp>
                                  <Close />
                                </Hidden>
                              </>
                            </ButtonComponent>
                          </div>
                          <div className="text-end">
                            <ButtonComponent
                              type="primary"
                              buttonType="submit"
                              isLoading={isLoading}
                              disabled={
                                stateValidation && !isSubmitting && !isLoading &&
                                  Object.keys(errors).length === 0
                                  ? false
                                  : true
                              }
                              className="w-full xs:w-auto justify-center"
                              onClick={() => { }}
                            >
                              <>
                                <Hidden smDown>
                                  {isLoading
                                    ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR_IS_LOADING
                                    : DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR
                                  }
                                </Hidden>
                                <Hidden smUp>
                                  <Save />
                                </Hidden>
                              </>
                            </ButtonComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </>
    </SidebarLayout>
  );
};

export default withRouter(EditarEmpresaAT);
