import { PlaceRounded } from '@mui/icons-material';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { Popup } from 'react-leaflet';

export const PopupMarker = ({ nombreEmpresa, nombreSucursal, direccion }) => {
  return (
    <Popup>
      <div className="market-popup">
        <div className="p-4">
          <div className="relative">
            <Heading type="h4" className="pr-10">
              {nombreEmpresa}
            </Heading>
            <div className="relative">
              <span className="pl-8 mb-1 block font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase">
                {nombreSucursal}
              </span>
              <div className="flex items-center">
                <PlaceRounded className="mr-2 text-uv-secondary-10" />
                <Text className="!m-0">{direccion}</Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};
