import { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AuthContext } from 'context';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import usuariosService from 'services/usuarios';
import Skeleton from '@mui/material/Skeleton';
import { RUTAS } from 'const/Rutas';

const ConfirmEmail = props => {
  const { logged, logout } = useContext(AuthContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const history = useHistory();

  const [verificandoCorreo, setVeficandoCorreo] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifyAccount = async () => {
      try {
        const result = await usuariosService.verificarCuentaUsuarios(
          token,
          false
        );
        if (result.status === 200) {
          setVeficandoCorreo(true);
          setTimeout(() => {
            history.push(`${RUTAS.HOME}`);
          }, 5000);
        } else {
          setVeficandoCorreo(false);
        }
      } catch (error) {
        console.log('error :>> ', error);
        setError(error);
        setVeficandoCorreo(false);
      } finally {
        setTimeout(() => {
          setVeficandoCorreo(true);
          if (!logged) {
            history.push(`${RUTAS.HOME}`);
          } else {
            setTimeout(() => {
              logout();
            }, 50);
          }
        }, 5000);
      }
    };

    const timeoutId = setTimeout(verifyAccount, 2000);

    return () => clearTimeout(timeoutId);
  }, [logout, history, logged, token]);

  return (
    <div className="m-auto">
      <div className="max-w-[550px] w-full text-center m-auto mb-2">
        {!error ? (
          <div className="bg-white rounded shadow-md py-10 px-14 mt-5">
            <Heading type="h1" className="mb-8">
              {verificandoCorreo ? 'Verificación completada' : 'Verificando'}
            </Heading>
            {verificandoCorreo ? (
              <Text>Serás redireccionado automáticamente a tu cuenta</Text>
            ) : (
              <></>
            )}

            <div className="flex items-center justify-center pt-5">
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                className="m-1"
              />
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                className="m-1"
              />
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                className="m-1"
              />
            </div>
          </div>
        ) : (
          <div className="bg-white rounded shadow-md py-10 px-14 mt-5">
            <Heading type="h1" className="mb-8">
              Error de verificación
            </Heading>
            <Text>
              No se ha podido verificar correctamente el correo electrónico.
              <br />
              Puede que la URL a la cual has ingresado no es correcta, para eso
              vuelve a revisar el enlace original recibido.
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmEmail;
