import React from 'react';
import ReactDOM from 'react-dom/client'
import * as serviceWorker from './serviceWorker';
import { StyledEngineProvider } from '@mui/material'
import App from './App';
import './assets/scss/index.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/index.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  <StyledEngineProvider injectFirst>
    <App />
  </StyledEngineProvider>
  
)

serviceWorker.unregister();
/* <React.StrictMode> */
  /* </React.StrictMode> */