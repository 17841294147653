import {
  DocumentosDeRespaldo,
  Receptor,
  ResiduoDeclarado,
  Transporte
} from '../components/SeccionesResiduosDeclarados';
import Generador from '../components/SeccionesResiduosDeclarados/Generador';
import { SchemaDocumentosDeRespaldo } from '../schema/SchemaDocumentosDeRespaldo';
import { SchemaGenerador } from '../schema/SchemaGenerador';
import { SchemaReceptor } from '../schema/SchemaReceptor';
import { SchemaResiduo } from '../schema/SchemaResiduo';
import { SchemaTransporte } from '../schema/SchemaTransporte';

const { PERMISOS } = require('const/Permisos');
const { AuthContext } = require('context');
const { useContext, useState } = require('react');

export const useObtenerPasos = () => {
  const { isTienePermisos } = useContext(AuthContext);

  const obtenerPasos = () => {
    const steps = [
      {
        nombrePaso: 'Residuo',
        codigoPaso: 'residuo',
        opcional: false,
        Componente: ResiduoDeclarado,
        schema: SchemaResiduo(isTienePermisos)
      }
    ];

    if (isTienePermisos(PERMISOS.empresaGeneradoraResiduoDeclarado)) {
      steps.push({
        nombrePaso: 'Generador',
        codigoPaso: 'generador',
        opcional: false,
        Componente: Generador,
        schema: SchemaGenerador
      });
    }

    const stepsFinal = [
      {
        nombrePaso: 'Transporte',
        codigoPaso: 'transporte',
        opcional: false,
        Componente: Transporte,
        schema: SchemaTransporte
      },
      {
        nombrePaso: 'Receptor',
        codigoPaso: 'receptor',
        opcional: true,
        Componente: Receptor,
        schema: SchemaReceptor
      },
      {
        nombrePaso: 'Documentos de respaldo',
        codigoPaso: 'documentos-de-respaldo',
        opcional: true,
        Componente: DocumentosDeRespaldo,
        schema: SchemaDocumentosDeRespaldo
      }
    ];

    return [...steps, ...stepsFinal];
  };

  const [pasosFormulario, setPasosFormulario] = useState(obtenerPasos());

  return {
    pasosFormulario
  };
};
