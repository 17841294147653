import { useContext } from 'react';
import Sidebar from './Sidebar';
import { SIDEBAR_MENU } from './SidebarMenus';
import { AuthContext } from 'context';

export const SidebarLayout = ({
  containerClassName,
  childrenContainerClassName,
  children
}) => {
  const { perfiles, logeduser, tipos } = useContext(AuthContext);
  const codigoEmpresa = logeduser?.sucursal
    ? logeduser.sucursal.empresa.codigoEmpresa
    : null;
  const tieneNegocio = codigoEmpresa ? true : false;
  const MENU = SIDEBAR_MENU(
    { ...perfiles, ...tipos },
    codigoEmpresa,
    tieneNegocio
  );

  return (
    <section className="w-full bg-gradient-to-b from-[#EFF5F4] to-white">
      <div
        className={
          containerClassName
            ? containerClassName
            : 'px-4 md:container md:mx-auto'
        }
      >
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2 hidden lg:block">
            <Sidebar menu={MENU} />
          </div>
          <div
            className={
              childrenContainerClassName
                ? childrenContainerClassName
                : 'col-span-12 lg:col-span-10 pt-10'
            }
          >
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};
