import React, { useEffect, useState } from 'react';
// Design System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';

import {
  CargaListasLER,
  obtenerTodasEmpresasAT
} from 'views/CargaMasiva/hooks/useServices';
import {
  calculoListaSubLer,
  calculoListaSubSubLer
} from '../utils/handleNombresLER';
import { DICTONARY } from 'const/Dictonary';
import InputNumber from 'components/design-system/input/InputNumber';
import AlertComponent from 'components/design-system/Alert/Alert';
import { AutoCompleteAsyncController } from 'components/design-system/AutoCompleteAsync/AutoCompleteAsyncController';

const FormularioIngresarResiduosAT = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  editing,
  isSubmitting
}) => {
  // Listas NombresLER
  const [listaLer, setListaLer] = useState([]);
  const [listaLerNivelUno, setListaLerNivelUno] = useState([]);
  const [listaLerNivelDos, setListaLerNivelDos] = useState([]);
  const [listaLerNivelTres, setListaLerNivelTres] = useState([]);
  const [cargandoListaLer, setCargandoListaLer] = useState(true);
  const [tieneSubtiposLer, setTieneSubtiposLer] = useState(false);
  const [tieneSubSubtiposLer, setTieneSubSubtiposLer] = useState(false);

  // Paginación ( no utilizada )
  const [paginationEmpresas, setPaginationEmpresas] = useState(
    DICTONARY.PAGINACION.ALL_DATA
  );
  const [rowCountEmpresas, setRowCountEmpresas] = useState(1);

  useEffect(() => {
    CargaListasLER({ setListaLer, setListaLerNivelUno, setCargandoListaLer });
  }, []);

  const handleFocusCantidad = event => {
    setFieldTouched('cantidad');
    if (!event) return;
    if (!event.target) return;
    if (!event.target.value) return;
    if (typeof event.target.value !== 'string') return;
    if (event.target.value === '')
      return setFieldValue('cantidad_str', '') && setFieldValue('cantidad', '');
    let stringValue = event.target.value.split('.').join('') || '';
    // transformar a number
    setFieldValue('cantidad_str', stringValue);
  };

  useEffect(() => {}, [values.cantidad_str, values.cantidad]);

  // Funcionalidad Empresas Origen y Destino
  const [empresasOrigen, setEmpresasOrigen] = useState(false);
  const [empresasDestino, setEmpresasDestino] = useState(false);

  const [loadingEmpresasOrigen, setLoadingEmpresasOrigen] = useState(false);
  const [loadingEmpresasDestino, setLoadingEmpresasDestino] = useState(false);

  const [textoFiltraEmpresasOrigen, setTextoFiltraEmpresasOrigen] =useState('');
  const [textoFiltraEmpresasDestino, setTextoFiltraEmpresasDestino] =useState('');

  const [isMaxCaracterEOri, setIsMaxCarcaterEOri] = useState(false);
  const [isMaxCaracterEDes, setIsMaxCarcaterEDes] = useState(false);

  useEffect(() => {

    if (values.empresaATOrigen?.value) {
      setFieldValue('tieneEmpresaATOrigen', true);
    }

    if (values.empresaATDestino?.value) {
      setFieldValue('tieneEmpresaATDestino', true);
    }
  }, [
    setFieldError,
    setFieldValue,
    values.empresaATDestino?.value,
    values.empresaATOrigen,
    values.tieneEmpresaATOrigen
  ]);

  useEffect(() => {
    if (textoFiltraEmpresasOrigen.length < 4) {
      setEmpresasOrigen(false);
      setFieldValue('empresaATOrigen', '');
    }
  }, [
    isMaxCaracterEOri,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    textoFiltraEmpresasOrigen
  ]);

  useEffect(() => {
    if (textoFiltraEmpresasDestino.length < 4) {
      setEmpresasDestino(false);
      setFieldValue('empresaATDestino', '');
    }
  }, [
    isMaxCaracterEDes,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    textoFiltraEmpresasDestino
  ]);

  return (
    <>
      {/* NombreLER  */}

      {/*  Nombre LER (Listado europeo de residuos) */}
      <div
        className={'col-span-4 xxs:col-span-12  xs:col-span-12   lg:col-span-4'}
      >
        <AutocompleteComponent
          name="nombreLER"
          accesor={'nombreLER'}
          clearOnEscape={true}
          disabled={cargandoListaLer}
          fullWidth
          loading={!listaLerNivelUno.length && cargandoListaLer}
          loadingText={!listaLerNivelUno.length && 'Cargando...'}
          noOptionsText="No hay coincidencias"
          openOnFocus={false}
          options={listaLerNivelUno || []}
          size="small"
          onBlur={() => setFieldTouched('nombreLER')}
          onChange={(ev, selected, reason) => {
            // Limpieza al cambiar de opción
            setFieldTouched('anyoDeclaracion',true)
            setFieldValue('nombreSubLER', []);
            setFieldValue('nombreSubSubLER', []);

            let data = { name: 'nombreLER', value: selected };
            if (reason === 'clear') {
              data.value = '';
            }
            setFieldValue('nombreLER', data.value);

            calculoListaSubLer(
              selected,
              setFieldValue,
              listaLer,
              setListaLerNivelDos,
              setTieneSubtiposLer,
              setListaLerNivelTres
            );

            setFieldTouched('codigoSubLER');
          }}
          value={values.nombreLER}
          getOptionLabel={option => option.nombreLER || ''}
        >
          <InputComponent
            className="h-9"
            fullWidth
            touched={touched.nombreLER}
            placeholder={listaLerNivelUno.length ? 'Seleccione nombre LER' : ''}
            estado={
              errors.nombreLER
                ? {
                    tipo: 'error',
                    mensaje: errors.nombreLER
                  }
                : null
            }
            label={
              <>
                <LabelComponent>
                  Nombre LER (Listado europeo de residuos)
                </LabelComponent>
              </>
            }
          />
        </AutocompleteComponent>
      </div>

      {/*  Nombre SubLER  */}

      <div
        className={'col-span-4 xxs:col-span-12  xs:col-span-12   lg:col-span-4'}
      >
        <AutocompleteComponent
          name="nombreSubLER"
          accesor={'nombreLER'}
          className="mt-1"
          clearOnEscape={true}
          disabled={cargandoListaLer && !editing}
          loading={!listaLerNivelDos.length && cargandoListaLer}
          loadingText={!listaLerNivelDos.length && 'Cargando...'}
          noOptionsText="No hay coincidencias"
          openOnFocus={false}
          options={listaLerNivelDos}
          size="small"
          onBlur={() => setFieldTouched('nombreSubLER')}
          value={values.nombreSubLER || ''}
          getOptionLabel={option => option.nombreLER || ''}
          onChange={(ev, selected, reason) => {
            let data = { name: 'nombreSubLER', value: selected };
            if (reason === 'clear') {
              data.value = '';
            }
            setFieldValue('nombreSubLER', data.value);

            //Calculo SUB SUB LER
            calculoListaSubSubLer(
              selected,
              setFieldValue,
              listaLer,
              setListaLerNivelTres,
              setTieneSubSubtiposLer
            );

            setFieldTouched('nombreSubSubLER');
            setFieldTouched('tipoResiduo');
          }}
        >
          <InputComponent
            className="h-9"
            fullWidth
            touched={touched.codigoSubLER ? true : false}
            placeholder={listaLerNivelDos.length ? 'Seleccione' : ''}
            estado={
              errors.nombreSubLER
                ? {
                    tipo: 'error',
                    mensaje: errors.nombreSubLER
                  }
                : null
            }
            label={
              <>
                <LabelComponent>{/* Sub Tipo */}</LabelComponent>
              </>
            }
          />
        </AutocompleteComponent>
      </div>

      {/*  Nombre SubSubLER  */}

      <div
        className={'col-span-4 xxs:col-span-12  xs:col-span-12   lg:col-span-4'}
      >
        <AutocompleteComponent
          name="nombreSubSubLER"
          accesor={'nombreLER'}
          className="mt-1"
          openOnFocus={false}
          clearOnEscape={true}
          disabled={cargandoListaLer && !editing}
          loading={!listaLerNivelTres.length && cargandoListaLer}
          loadingText={!listaLerNivelTres.length && 'Cargando...'}
          size="small"
          options={listaLerNivelTres}
          noOptionsText="No hay coincidencias"
          onBlur={() => setFieldTouched('nombreSubSubLER')}
          value={values.nombreSubSubLER || ''}
          getOptionLabel={option => option.nombreLER || ''}
          onChange={(ev, selected, reason) => {
            let data = { name: 'nombreSubSubLER', value: selected };
            if (reason === 'clear') {
              data.value = '';
            }
            setFieldValue('nombreSubSubLER', data.value);

            setFieldTouched('codigoSubSubLER');
            setFieldTouched('tipoResiduo');
          }}
        >
          <InputComponent
            className="h-9"
            fullWidth
            touched={touched.nombreSubSubLER ? true : false}
            placeholder={listaLerNivelTres.length ? 'Seleccione' : ''}
            estado={
              errors.nombreSubSubLER
                ? {
                    tipo: 'error',
                    mensaje: errors.nombreSubSubLER
                  }
                : null
            }
            label={
              <>
                <LabelComponent>{/* Sub Sub Tipo LER */}</LabelComponent>
              </>
            }
          />
        </AutocompleteComponent>
      </div>

      <div className="col-span-12"></div>

      {/* Tratamiento */}
      <div className=" col-span-8 xxs:col-span-12  xs:col-span-12   lg:col-span-8">
        <InputComponent
          autoComplete={'off'}
          fullWidth
          label={<LabelComponent>Tratamiento</LabelComponent>}
          name="tratamiento"
          onBlur={e => {
            setFieldTouched('tratamiento', true);
            setFieldTouched('cantidad', true);
          }}
          onChange={ev => {
            ev && handleChange(ev) && setFieldTouched('tratamiento');
            !ev && setFieldValue('tratamiento', '');
          }}
          value={values.tratamiento ? values.tratamiento : ''}
          touched={touched.tratamiento}
          estado={
            errors.tratamiento
              ? { tipo: 'error', mensaje: errors.tratamiento }
              : null
          }
        />
      </div>

      {/* Cantidad */}
      <div className="col-span-4 xxs:col-span-12  xs:col-span-12   lg:col-span-4">
        <InputNumber
          maxDecimals={DICTONARY.NUMERO_DECIMALES}
          type="text"
          autoComplete={'off'}
          fullWidth
          label={<LabelComponent>Cantidad:</LabelComponent>}
          name="cantidad"
          onChange={ev => {
            if (!ev.target.value)
              return (
                setFieldValue('cantidad_str', '') &&
                setFieldValue('cantidad', '')
              );
            const value = ev.target.value;
            const valueWithoutDots = value.split('.').join('');
            let doubleValue = valueWithoutDots.replace(',', '.');
            setFieldValue('cantidad', parseFloat(doubleValue));
          }}
          onBlur={e => {
            setFieldTouched('empresaATOrigen', true);
            setFieldTouched('cantidad', true);
          }}
          onFocus={handleFocusCantidad}
          touched={touched.cantidad}
          value={values.cantidad_str || values.cantidad}
          variant="outlined"
          size="small"
          placeholder={!values.cantidad ? 'Ingrese cantidad' : ''}
          estado={
            errors.cantidad
              ? {
                  tipo: 'error',
                  mensaje: errors.cantidad
                }
              : null
          }
        />
      </div>

      {/* Alerta mensaje con indicaciones */}
      <div className="col-span-12">
        <AlertComponent className="mb-2" type="info">
          Para <strong>desplegar</strong> las opciones de{' '}
          <strong>empresas registradas</strong> en la base de datos por favor{' '}
          <strong>ingresar</strong> un texto mínimo de <strong>4 letras</strong>
          ,
          <br /> luego se <strong>filtrará</strong> por la{' '}
          <strong>razón social.</strong>
        </AlertComponent>
      </div>

      {/* Empresa Origen */}
      <div className="col-span-6  xxs:col-span-12  xs:col-span-12  lg:col-span-6">
        <AutoCompleteAsyncController
          name="empresaATOrigen"
          accesor="label"
          limitTags={2}
          disabled={loadingEmpresasOrigen}
          isLoading={loadingEmpresasOrigen}
          setFieldValue={data => setFieldValue('empresaATOrigen', data)}
          setFieldTouched={() => setFieldTouched('empresaATOrigen')}
          isSubmitting={isSubmitting}
          fetchCall={controllerValues => 
            // console.log(controllerValues)
              obtenerTodasEmpresasAT({
              setLoadingEmpresas: controllerValues.setIsLoading,
              setEmpresas: controllerValues.setData,
              paginationEmpresas: controllerValues.paginationEmpresas,
              razonSocial: controllerValues.inputValue,
              setRowCountEmpresas: controllerValues.setRowCountEmpresas
          })
        }
        renderOptions={(props, option, { selected }) => {
          <li {...props}>
            <span>{option.label}</span>

          </li>
        }}
        >

        </AutoCompleteAsyncController>
      </div>
      {/* Empresa Destino */}
      <div className="col-span-6  xxs:col-span-12  xs:col-span-12  lg:col-span-6"> 
        <AutoCompleteAsyncController
          name="empresaATOrigen"
          accesor="label"
          limitTags={2}
          disabled={loadingEmpresasDestino}
          isLoading={loadingEmpresasDestino}
          setFieldValue={data => setFieldValue('empresaATDestino', data)}
          setFieldTouched={() => setFieldTouched('empresaATDestino')}
          isSubmitting={isSubmitting}
          fetchCall={controllerValues => 
            // console.log(controllerValues)
              obtenerTodasEmpresasAT({
              setLoadingEmpresas: controllerValues.setIsLoading,
              setEmpresas: controllerValues.setData,
              paginationEmpresas: controllerValues.paginationEmpresas,
              razonSocial: controllerValues.inputValue,
              setRowCountEmpresas: controllerValues.setRowCountEmpresas
          })
        }
        renderOptions={(props, option, { selected }) => {
          <li {...props}>
            <span>{option.label}</span>

          </li>
        }}
        >
       </AutoCompleteAsyncController>
      </div>
    </>
  );
};

export default FormularioIngresarResiduosAT;
