import { useMemo } from 'react';
import moment from 'moment';
import Text from 'components/design-system/Text/Text';
import { TransformarTipoPublicacion } from 'helpers/logicaTipoPublicacion';

export const useColumnTablesListadoResiduos = () => {
  const columnTables = useMemo(
    () => [
      {
        header: 'Código',
        size: 1,
        enableGlobalFilter: false,
        accessorKey: 'codigoResiduo',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        headerStyle: { textAlign: 'center' }, // Centra el encabezado
        cellStyle: { textAlign: 'center' } // Centra el texto de la celda
      },
      {
        header: 'Tipo publicación',
        size: 1,
        enableGlobalFilter: false,
        accessorKey: 'rol',
        Cell: ({ renderedCellValue }) => (
          <Text className="capitalize">
            {TransformarTipoPublicacion(
              renderedCellValue && renderedCellValue.nombreRol
            )}
          </Text>
        )
      },
      {
        header: 'Residuo',
        accessorKey: 'tipoResiduo',
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue && renderedCellValue.nombreTipo}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Empresa',
        accessorKey: 'sucursal.empresa.nombreEmpresa',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>
      },
      {
        header: 'Sucursal',
        accessorKey: 'sucursal',
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue && renderedCellValue.nombreSucursal}</Text>
        )
      },

      {
        header: 'Cantidad (unidad)',
        enableGlobalFilter: false,
        accessorKey: 'cantidad',
        Cell: ({ row }) => {
          const { cantidadUnidad } = row.original;
          return <Text>{`${cantidadUnidad}`}</Text>;
        },
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Fecha disponibilidad',
        size: 200,
        enableGlobalFilter: false,
        accessorKey: 'fechaDisponibilidad',
        Cell: ({ renderedCellValue }) => (
          <Text>
            {' '}
            {renderedCellValue !== 'N/A'
              ? moment(renderedCellValue).format('DD-MM-YYYY')
              : 'N/A'}
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      }
    ],
    []
  );

  return columnTables;
};
