import { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AuthContext } from 'context';
import jwt from 'jwt-decode';
import { RUTAS } from 'const/Rutas';
import Heading from 'components/design-system/Heading/Heading';
import { Skeleton } from '@mui/material';
import Text from 'components/design-system/Text/Text';
import { AlertaExito } from 'utils/Alertas';

const VerificaUsuario = props => {
  const { logged, logout } = useContext(AuthContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const decifrarToken = token => {
    const decifrado = jwt(token);
    return decifrado;
  };
  const [tokenDecifrado] = useState(decifrarToken(token));
  const { correoElectronico, codigoSucursal } = tokenDecifrado;

  useEffect(() => {
    setTimeout(() => {
      if (!logged) {
        setIsLoading(false);
        AlertaExito('Verificación completada correctamente');
        history.push(`${RUTAS.REGISTRARSE}?token=${token}`, {
          token: token,
          correoElectronico: correoElectronico,
          codigoSucursal: codigoSucursal
        });
      } else {
        setTimeout(() => {
          setIsLoading(false);
          localStorage.setItem('verificaUsuario', true);
          logout();
        }, 3000);
      }
    }, 3000);
  }, [codigoSucursal, correoElectronico, logout, history, logged, token]);

  return isLoading ? (
    <div className="max-w-[550px] w-full text-center m-auto">
      <div className="bg-white rounded shadow-md py-10 px-14 mt-5">
        <Heading type="h1" className="mb-8">
          {isLoading && 'Verificando ...'}
        </Heading>
        <Text>
          {logged
            ? 'Se cerrará la sesión actual'
            : 'Serás redireccionado a la página de registro.'}
        </Text>
        <div className="flex items-center justify-center pt-5">
          <Skeleton variant="circular" width={40} height={40} className="m-1" />
          <Skeleton variant="circular" width={40} height={40} className="m-1" />
          <Skeleton variant="circular" width={40} height={40} className="m-1" />
        </div>
      </div>
    </div>
  ) : null;
};

export default VerificaUsuario;
