import { Avatar, Divider } from '@mui/material';
import imgEditarInformacion from 'assets/images/usuario/editar-informacion.svg';
import InputComponent from 'components/design-system/input/Input';
// import AlertComponent from 'components/design-system/Alert/Alert';
import LabelComponent from 'components/design-system/Label/Label';
import Dropzone from 'components/design-system/Dropzone/Dropzone';
import Text from 'components/design-system/Text/Text';
import IconComponent from 'components/design-system/icon/Icon';
import TaskAlt from '@mui/icons-material/TaskAlt';
import { DICTONARY } from 'const/Dictonary';
import { useState } from 'react';

export const CuentaCampos = ({
  values,
  handleChange,
  setFieldTouched,
  setFieldValue,
  touched,
  errors,
  isSubmitting,
  imagenPerfil
}) => {
  // Modificaciones para llamar servicio de validación
  const [originalEmailValue, setOriginalEmailValue] = useState('');
  return (
    <div>
      <div className="grid grid-cols-12 gap-4 items-center">
        <div className="col-span-12 md:col-span-3 text-center mx-auto">
          <img
            src={imgEditarInformacion}
            alt="Actualizar información de contacto"
          />
        </div>
        <div className="col-span-12 md:col-span-9">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="rutUsuario"
                value={values.rutUsuario}
                onChange={handleChange}
                label={<LabelComponent esOpcional>Rut</LabelComponent>}
                onBlur={() => setFieldTouched('rutUsuario')}
                touched={touched.rutUsuario ? true : false}
                estado={
                  errors.rutUsuario
                    ? { tipo: 'error', mensaje: errors.rutUsuario }
                    : null
                }
                helperText={
                  <Text size="S">Sin puntos y con guión, ej: 11200300-K</Text>
                }
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="profesion"
                value={values.profesion}
                onChange={handleChange}
                label={<LabelComponent esOpcional>Profesión</LabelComponent>}
                onBlur={() => setFieldTouched('profesion')}
                touched={touched.profesion ? true : false}
                estado={
                  errors.profesion
                    ? { tipo: 'error', mensaje: errors.profesion }
                    : null
                }
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="primerNombre"
                value={values.primerNombre}
                onChange={handleChange}
                label={<LabelComponent>Primer nombre</LabelComponent>}
                onBlur={() => setFieldTouched('primerNombre')}
                touched={touched.primerNombre ? true : false}
                estado={
                  errors.primerNombre
                    ? { tipo: 'error', mensaje: errors.primerNombre }
                    : null
                }
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="segundoNombre"
                value={values.segundoNombre}
                onChange={handleChange}
                label={
                  <LabelComponent esOpcional>Segundo nombre</LabelComponent>
                }
                onBlur={() => setFieldTouched('segundoNombre')}
                touched={touched.segundoNombre ? true : false}
                estado={
                  errors.segundoNombre
                    ? { tipo: 'error', mensaje: errors.segundoNombre }
                    : null
                }
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="apellidoPaterno"
                value={values.apellidoPaterno}
                onChange={handleChange}
                label={<LabelComponent>Apellido paterno</LabelComponent>}
                onBlur={() => setFieldTouched('apellidoPaterno')}
                touched={touched.apellidoPaterno ? true : false}
                estado={
                  errors.apellidoPaterno
                    ? { tipo: 'error', mensaje: errors.apellidoPaterno }
                    : null
                }
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="apellidoMaterno"
                value={values.apellidoMaterno}
                onChange={handleChange}
                label={<LabelComponent>Apellido materno</LabelComponent>}
                onBlur={() => setFieldTouched('apellidoMaterno')}
                touched={touched.apellidoMaterno ? true : false}
                estado={
                  errors.apellidoMaterno
                    ? { tipo: 'error', mensaje: errors.apellidoMaterno }
                    : null
                }
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="correoElectronico"
                value={values.correoElectronico}
                onChange={e => {
                  handleChange(e);
                  setFieldValue('emailModificado', true);
                }}
                label={<LabelComponent>Email</LabelComponent>}
                onBlur={e => {
                  if (originalEmailValue !== e.target.value) {
                    setFieldTouched('correoElectronico');
                    setFieldValue('emailModificado', true);
                  }
                }}
                onFocus={e => {
                  setOriginalEmailValue(e.target.value);
                }}
                onClick={e => {
                  setFieldValue('emailModificado', false);
                }}
                touched={touched.correoElectronico ? true : false}
                estado={
                  errors.correoElectronico
                    ? { tipo: 'error', mensaje: errors.correoElectronico }
                    : null
                }
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <InputComponent
                fullWidth
                disabled={isSubmitting ? true : false}
                name="telefonoUsuario"
                value={values.telefonoUsuario}
                onChange={handleChange}
                label={<LabelComponent esOpcional>Teléfono</LabelComponent>}
                onBlur={() => setFieldTouched('telefonoUsuario')}
                touched={touched.telefonoUsuario ? true : false}
                estado={
                  errors.telefonoUsuario
                    ? { tipo: 'error', mensaje: errors.telefonoUsuario }
                    : null
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-span-12 my-5">
        <Divider />
      </div>

      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-3">
          <div className="logo-thumbnail pt-05">
            <Avatar
              alt="Imagen de perfil"
              src={`data:image/jpg;base64,${imagenPerfil}`}
              className="biggest-logo"
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-9">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-12">
              <LabelComponent esOpcional>Imagen de perfíl</LabelComponent>
              <Dropzone
                setDataFiles={data => setFieldValue('files', data)}
                dataFiles={values.files}
                enableEditingFile={false}
                disabled={isSubmitting ? true : false}
                {...DICTONARY.DROPZONE.CONFIG_UPLOAD_PROFILE_FILES}
              >
                <Text className="text-black font-medium">
                  Solo se aceptan los siguientes tipos de archivos
                </Text>
                <ul className="flex mt-1">
                  <li className="flex items-center mb-1">
                    <IconComponent className="mr-2">
                      <TaskAlt className="text-base text-uv-primary-20" />
                    </IconComponent>
                    <Text className="text-neutral-40">
                      Formatos JPG, JPGE y PNG
                    </Text>
                  </li>
                  <li className="flex items-center mb-1 ml-6">
                    <IconComponent className="mr-2">
                      <TaskAlt className="text-base text-uv-primary-20" />
                    </IconComponent>
                    <Text className="text-neutral-40">
                      Tamaño no superior a 5 MB
                    </Text>
                  </li>
                </ul>
              </Dropzone>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
