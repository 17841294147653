import { DICTONARY } from 'const/Dictonary';
import { RUTAS } from 'const/Rutas';
import { useState } from 'react';
import chatServices from 'services/chat';
import empresasService from 'services/empresas';

export const useReunion = () => {
  const [isCreandoReunion, setIsCreandoReunion] = useState(false);
  const handleCrearReunion = async data => {
    setIsCreandoReunion(true);

    try {
      const responseSucursal = await empresasService.getSucursal(
        data.codigoSucursal,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      const errorSucursal = responseSucursal.data.error;
      if (errorSucursal) throw new Error('ERROR_CREAR_REUNION');

      const response = await chatServices.crearSalaChat({
        codigoEmpresa: responseSucursal.data.empresa.codigoEmpresa,
        ...data
      });

      if (response.status === 200 && !response.data.error) {
        if (response.data.error) {
          return;
        }

        window.open(`${RUTAS.CHAT}/${response.data.codigoSalaChat}`, '_blank');
      }
    } catch (error) {
      console.log('ERROR_AL_CREAR_REUNION ', error);
    } finally {
      setIsCreandoReunion(false);
      return false;
    }
  };

  return {
    isCreandoReunion,
    handleCrearReunion
  };
};
