import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/styles';
import Routes from 'routers/Routes';
import theme from './theme';
import { AuthProvider } from 'context';
import { Toaster } from 'sonner';
import { DICTONARY } from 'const/Dictonary';
//import ResponsiveBarHelper from 'helpers/ResponsiveBar';
export default class App extends Component {
  render() {
    return (
      <AuthProvider>
        {/* <ResponsiveBarHelper /> */}
        <Toaster
          position="top-center"
          richColors
          className="font-primary text-sm max-w-md w-full"
          duration={DICTONARY.TOAST.DURATION}
        />
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    );
  }
}
