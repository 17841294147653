import Text from 'components/design-system/Text/Text';
import { useMemo } from 'react';

export const ColumnsTableResiduos = () => {
  const columnTables = useMemo(
    () => [
      {
        header: 'Código',
        accessorKey: 'empresaATOrigen.codigoEmpresaAT',
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Rut',
        accessorKey: 'empresaATOrigen.rutEmpresa',
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Nombre Ler',
        accessorKey: 'nombreLER.nombreLER',
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Tratamiento',
        accessorKey: 'tratamiento',
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Cantidad',
        accessorKey: 'cantidad',
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Rut(destinatario)',
        accessorKey: 'empresaATDestino.rutEmpresa',
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      }
    ],
    []
  );
  return columnTables;
};
