import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Link, useParams, useHistory } from 'react-router-dom';
// Layout
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';

// Desgin System
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';

// Material
import { ChevronLeft } from '@mui/icons-material';

// Librarys
import { SkeletonRecursosAdquiridos } from 'views/RecursoAdquirido/skeleton/SkeletonRecursosAdquiridos';

// Diccionary
import { DICTONARY } from 'const/Dictonary';
import { RUTAS } from 'const/Rutas';

// Hooks
import { useServicesRecursosAdquiridos } from '../../hooks/useServicesRecursosAdquiridos';
import useCargaServiciosRecursosAdquiridos from 'views/RecursoAdquirido/hooks/useCargaServiciosRecursosAdquiridos';
import { useSubmitRecusosAdquiridos } from 'views/RecursoAdquirido/hooks/useSubmitRecusosAdquiridos';

// Utils

// Components
import { CamposRecursosAdquiridos } from '../../components';
import { schemaRecursosAdquiridos } from 'views/RecursoAdquirido/schema/schemaRecursosAdquiridos';

const RecursosAdquiridosFormik = () => {
  const params = useParams();

  const history = useHistory();

  const { location } = history;

  // Tipo de Formulario
  const tipo = location?.state?.formulario?.tipo;
  const pathName = location?.pathname;
  const lastWord = pathName.split('/').filter(Boolean).pop();

  const [codigoRecursoAdquirido, setCodigoRecursoAdquirido] = useState(
    params.codigoRecursoAdquirido || 'nuevo'
  ); // codigoRecursoAdquirido registrado en la base de datos (Integer) [nuevo
  const [tipoFormulario, setTipoFormulario] = useState(tipo || lastWord || '');
  const [operacionFinalizada, setOperacionFinalizada] = useState(false);

  /**
   *  LLamada de servicios de useServicesRecursosAdquiridos
   *  @description  hooks utilizado en la creación de servicios para Recursos Adquiridos
   *  @see          src/views/RecursoAdquirido/hooks/useServicesRecursosAdquiridos
   *  @params       no aplica
   *  @returns      states y funciones de servicios
   */
  const {
    // Funciones
    ObtieneComunasPorRegion,
    ObtieneEmpresasSII,
    ObtieneEnvaseEmbalaje,
    ObtieneLevantamiento,
    // ObtieneListaRecursosAdquiridos,
    ObtieneRegiones,
    ObtieneSucursales,
    ObtieneTipoTransporte,
    ObtieneTiposRecursosAdquiridos,
    ObtieneUnidadMedida,

    // States Campos Listado de Recursos Adquiridos
    tipoRecursosAdquiridos,
    setTipoRecursosAdquiridos,
    isLoadingTipo,
    setIsLoadingTipo,

    // States Sucursales
    sucursales,
    setSucursales,
    isLoadingSucursales,
    setIsLoadingSucursales,

    // States Levantamiento de datos
    levantamiento,
    setLevantamiento,
    isLoadingLevantamiento,
    setIsLoadingLevantamiento,

    // States Unidad de medida
    unidadMedida,
    setUnidadMedida,
    isLoadingUnidadMedida,
    setIsLoadingUnidadMedida,

    // States Empresas SII
    empresasSII,
    setEmpresasSII,
    isLoadingEmpresasSII,
    setIsLoadingEmpresasSII,

    // States tipo de transporte
    tipoTransporte,
    setTipoTransporte,
    isLoadingTipoTransporte,
    setIsLoadingTipoTransporte,

    // States envase embalaje
    tipoEmbalaje,
    setTipoEmbalaje,
    isLoadingTipoEmbalaje,
    setIsLoadingTipoEmbalaje
  } = useServicesRecursosAdquiridos();

  /**
   *  Cargando Servicios de useCargaServicios
   *  @description  hooks utilizado para la ejecución de  servicios para Recursos Adquiridos
   *  @see          src/views/RecursoAdquirido/hooks/useCargaServicios
   *  @params       todas las funciones a cargar en el hook
   *  @returns      funciones de carga de servicios para ser utilizadas en otra parte del formulario
   *
   * */

  const { cargaComunasPorRegion, cargaEmpresasSII } =
    useCargaServiciosRecursosAdquiridos({
      ObtieneComunasPorRegion,
      ObtieneEmpresasSII,
      ObtieneEnvaseEmbalaje,
      ObtieneLevantamiento,
      ObtieneRegiones,
      ObtieneSucursales,
      ObtieneTipoTransporte,
      ObtieneTiposRecursosAdquiridos,
      ObtieneUnidadMedida
    });

  // Hooks
  const {
    recursoAdquirido,
    cargarRecursoAdquirido,
    isLoading,
    // setIsLoading,
    cargaData,
    handleSubmit
  } = useSubmitRecusosAdquiridos(codigoRecursoAdquirido);

  // ejecutar cargarRecursoAdquirido() si se refresca la pagina
  useEffect(() => {
    if (!tipoFormulario) return;
    cargarRecursoAdquirido();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoFormulario]);

  useEffect(() => {
    if (!cargaData) return;
    // console.log('recursoAdquirido formik ::>', recursoAdquirido);
  }, [cargaData, isLoading, recursoAdquirido]);

  // Tipos de Formulario
  const tiposPosibles = ['ingresar', 'editar', 'duplicar'];
  const tipoForm = tiposPosibles.reduce((obj, tipo) => {
    obj[tipo] = tipoFormulario === tipo;
    return obj;
  }, {});
  const isFormIngresar = tipoFormulario === 'ingresar';
  return (
    <SidebarLayout>
      <Heading type="h2" className="mb-4 text-lg md:text-2xl">
        <div className="flex items-center">
          <Link to={`${RUTAS.GESTIONA_RECURSO_ADQUIRIDO}`}>
            <IconButtonComponent type="secondary" className="mr-2">
              <ChevronLeft />
            </IconButtonComponent>
          </Link>
          <span>
            {` ${
              tipoForm.ingresar
                ? 'Ingresar'
                : tipoForm.editar
                ? 'Editar'
                : tipoForm.duplicar
                ? 'Duplicar'
                : ''
            }
                    recurso adquirido`}
          </span>
        </div>
      </Heading>

      {isLoading && !cargaData && !isFormIngresar ? (
        <SkeletonRecursosAdquiridos tipoForm={tipoForm} />
      ) : (
        <Formik
          initialValues={recursoAdquirido}
          validationSchema={schemaRecursosAdquiridos}
          validateOnChange={true}
          validateOnBlur={true}
          validateOnMount={true}
          onSubmit={async (values, { resetForm }) => {
            // console.log('submit finalizado ::>', values);
            //await onSubmit(recurso.codigoRecursoAdquirido, values);
            await handleSubmit(values, tipoFormulario);
            // resetForm();
            setOperacionFinalizada(true);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleReset,
            handleSubmit,
            isSubmitting,
            setFieldError,
            setFieldTouched,
            setFieldValue,
            touched,
            validateForm,
            values
          }) => (
            <Form id="editarRecursosAdquiridos">
              <div className="bg-white rounded p-5 shadow-sm mb-5">
                <div className="grid grid-cols-12 gap-4">
                  <CamposRecursosAdquiridos
                    // Formik
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleReset={handleReset}
                    handleSubmit={handleSubmit}
                    setFieldError={setFieldError}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    validateForm={validateForm}
                    values={values}
                    isSubmitting={isSubmitting}
                    // Dependencias
                    cargaComunasPorRegion={cargaComunasPorRegion}
                    cargaEmpresasSII={cargaEmpresasSII}
                    // Listado de Recursos Adquiridos
                    tipoRecursosAdquiridos={tipoRecursosAdquiridos}
                    setTipoRecursosAdquiridos={setTipoRecursosAdquiridos}
                    isLoadingTipo={isLoadingTipo}
                    setIsLoadingTipo={setIsLoadingTipo}
                    // Sucursales
                    sucursales={sucursales}
                    setSucursales={setSucursales}
                    isLoadingSucursales={isLoadingSucursales}
                    setIsLoadingSucursales={setIsLoadingSucursales}
                    // Levantamiento de datos
                    levantamiento={levantamiento}
                    setLevantamiento={setLevantamiento}
                    isLoadingLevantamiento={isLoadingLevantamiento}
                    setIsLoadingLevantamiento={setIsLoadingLevantamiento}
                    // Unidad de medida
                    unidadMedida={unidadMedida}
                    setUnidadMedida={setUnidadMedida}
                    isLoadingUnidadMedida={isLoadingUnidadMedida}
                    setIsLoadingUnidadMedida={setIsLoadingUnidadMedida}
                    // Empresas SII
                    empresasSII={empresasSII}
                    setEmpresasSII={setEmpresasSII}
                    isLoadingEmpresasSII={isLoadingEmpresasSII}
                    setIsLoadingEmpresasSII={setIsLoadingEmpresasSII}
                    // Tipo de transporte
                    tipoTransporte={tipoTransporte}
                    isLoadingTipoTransporte={isLoadingTipoTransporte}
                    setIsLoadingTipoTransporte={setIsLoadingTipoTransporte}
                    setTipoTransporte={setTipoTransporte}
                    // Envase Embalaje
                    tipoEmbalaje={tipoEmbalaje}
                    setTipoEmbalaje={setTipoEmbalaje}
                    isLoadingTipoEmbalaje={isLoadingTipoEmbalaje}
                    setIsLoadingTipoEmbalaje={setIsLoadingTipoEmbalaje}
                    // Tipo de Formulario
                    tipoForm={tipoForm}
                  />

                  <div className="col-span-12 justify-between">
                    <div className="flex justify-between">
                      <Link to={`${RUTAS.GESTIONA_RECURSO_ADQUIRIDO}`}>
                        <ButtonComponent type="secondary" className="mr-2">
                          <ChevronLeft /> Volver
                        </ButtonComponent>
                      </Link>

                      {operacionFinalizada ? (
                        <Link to={`${RUTAS.GESTIONA_RECURSO_ADQUIRIDO}`}>
                          <ButtonComponent type="primary" className="mr-2">
                            Finalizar
                          </ButtonComponent>
                        </Link>
                      ) : (
                        <ButtonComponent
                          form="editarRecursosAdquiridos"
                          buttonType="submit"
                          isLoading={isSubmitting || isLoading}
                          disabled={
                            isSubmitting || Object.keys(errors).length > 0
                          }
                        >
                          {tipoFormulario === 'ingresar'
                            ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD
                                .INGRESAR + ' recurso '
                            : tipoFormulario === 'editar'
                            ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR
                            : tipoFormulario === 'duplicar'
                            ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD
                                .DUPLICAR + ' recurso '
                            : ''}
                        </ButtonComponent>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </SidebarLayout>
  );
};

export default RecursosAdquiridosFormik;
