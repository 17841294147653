import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import ButtonComponent from 'components/design-system/Button/Button';
import { Form, Formik } from 'formik';
import { Divider } from '@mui/material';
import { CuentaCampos } from './CuentaCampos';
import { schemaEditarCuenta } from './schema/schemaCuenta';
import { useEditarCuenta } from './hooks/useEditarCuenta';
import { CuentaSkeleton } from './CuentaSkeleton';
import { DICTONARY } from 'const/Dictonary';
import { AlertaExito } from 'utils/Alertas';

const CuentaFormik = () => {
  const { valorInicial, onSubmit, imagenPerfil, isLoading } = useEditarCuenta();

  return (
    <div>
      <div className="mb-4">
        <Heading type="h2" className="mb-0">
          Mi cuenta
        </Heading>
        <Text className="mb-0">
          Actualiza la información personal de tu cuenta, así como la
          información de contacto
        </Text>
      </div>
      <div className="bg-white rounded shadow-md p-5 mb-6">
        {isLoading ? (
          <CuentaSkeleton />
        ) : (
          <Formik
            initialValues={valorInicial()}
            validateOnMount={false}
            validationSchema={schemaEditarCuenta}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
              try {
                setTimeout(async () => {
                  const resultado = await onSubmit(values);
                  setFieldValue('files', []);
                  setSubmitting(false);
                  AlertaExito(
                    'Se han actualizado correctamente los datos del usuario'
                  );
                }, 1500);
              } catch (error) {
                console.log('error >', error);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              handleChange
            }) => (
              <Form id="editarCuenta">
                <CuentaCampos
                  values={values}
                  handleChange={handleChange}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  isSubmitting={isSubmitting}
                  imagenPerfil={imagenPerfil}
                />
                <div className="col-span-12 my-5">
                  <Divider />
                </div>

                <div className="col-span-12 justify-between">
                  <div className="text-end">
                    <ButtonComponent
                      form="editarCuenta"
                      buttonType="submit"
                      isLoading={isSubmitting}
                      disabled={
                        Object.keys(errors).length === 0 && !isSubmitting
                          ? false
                          : true
                      }
                    >
                      {isSubmitting
                        ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD
                            .EDITAR_IS_LOADING
                        : DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR}
                    </ButtonComponent>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default CuentaFormik;
