import { Skeleton } from '@mui/material';
import Heading from 'components/design-system/Heading/Heading';

export const MapaBaseSkeleton = () => {
  return (
    <div className="h-full w-full relative max-h-[600px] lg:min-h-full shadow-md bg-white flex flex-col">
      <div className="py-4 px-5 bg-white">
        <Heading type="h4" className="text-uv-primary-0 m-0">
          Sectores económicos de empresas
        </Heading>
      </div>
      <Skeleton
        variant="rounded"
        className="w-full h-full relative z-0 min-w-full flex justify-center items-center"
      />
    </div>
  );
};
