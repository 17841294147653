import React, { useEffect, useState } from 'react';
// Design System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import { obtenerTodasEmpresasAT } from 'views/CargaMasiva/hooks/useServices';
import { DICTONARY } from 'const/Dictonary';
import InputNumber from 'components/design-system/input/InputNumber';

import AlertComponent from 'components/design-system/Alert/Alert';
import { AutoCompleteAsyncController } from 'components/design-system/AutoCompleteAsync/AutoCompleteAsyncController';
import { CalculoListaLer, CalculoListaSubLer } from 'utils/useUtilidades';
import { ObtieneListaLer } from 'hooks';

const FormularioEditarResiduosAT = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  editing,
  isSubmitting
}) => {
  // Listas NombresLER
  // SERVICIO nombresLER
  const {
    listaLer,
    cargandoListaLer,
    // handlelistaler,
    listaLerNivelUno,
    listaLerNivelDosEdit,
    listaLerNivelUnoEdit
  } = ObtieneListaLer();
  //Calculo SUBLER
  const {
    calculoListaSubLer,
    listaLerNivelDos,
    listaLerNivelTres,
    tieneSubtiposLer,
    setListaLerNivelTres
  } = CalculoListaLer();

  //Calculo SUB SUBLER
  // Calculo lista SubLer
  const { calculoListaSubSubLer } = CalculoListaSubLer();

  // Tiene Datos
  const [tieneSubSubtiposLer, setTieneSubSubtiposLer] = useState(true);
  const [paginationEmpresas, setPaginationEmpresas] = useState(
    DICTONARY.PAGINACION.DEFAULT
  );
  const [rowCountEmpresas, setRowCountEmpresas] = useState(1);

  const handleFocusCantidad = event => {
    setFieldTouched('cantidad');
    if (!event) return;
    if (!event.target) return;
    if (!event.target.value) return;
    if (typeof event.target.value !== 'string') return;
    if (event.target.value === '')
      return setFieldValue('cantidad_str', '') && setFieldValue('cantidad', '');
    let stringValue = event.target.value.split('.').join('') || '';
    // transformar a number
    setFieldValue('cantidad_str', stringValue);
  };

  useEffect(() => {}, [values.cantidad_str, values.cantidad]);

  // Lena selectores de LER para edición
  useEffect(() => {
    if (
      listaLerNivelDosEdit.length &&
      values.nombreSubSubLER.codigoNivelAnterior
    ) {
      const nivelDosEdit = listaLerNivelDosEdit.find(
        ler => ler.codigoLER === values.nombreSubSubLER.codigoNivelAnterior
      );
      setFieldValue('nombreSubLER', nivelDosEdit);
    }
  }, [
    listaLer.length,
    listaLerNivelDos,
    listaLerNivelDosEdit,
    setFieldValue,
    values.nombreSubSubLER.codigoLER,
    values.nombreSubSubLER.codigoNivelAnterior
  ]);

  useEffect(() => {
    if (
      listaLerNivelUnoEdit.length &&
      values.nombreSubLER.codigoNivelAnterior
    ) {
      const nivelUnoEdit = listaLerNivelUnoEdit.find(
        ler => ler.codigoLER === values.nombreSubLER.codigoNivelAnterior
      );
      setFieldValue('nombreLER', nivelUnoEdit);
    }
  }, [
    listaLer.length,
    listaLerNivelUno,
    listaLerNivelUnoEdit,
    setFieldValue,
    values.nombreSubLER,
    values.nombreSubLER.codigoLER,
    values.nombreSubLER.codigoNivelAnterior
  ]);

  // Funcionalidad Empresas Origen y Destino
  const [empresasOrigen, setEmpresasOrigen] = useState([]);
  const [empresasDestino, setEmpresasDestino] = useState(false);

  const [loadingEmpresasOrigen, setLoadingEmpresasOrigen] = useState(false);
  const [loadingEmpresasDestino, setLoadingEmpresasDestino] = useState(false);

  const [textoFiltraEmpresasOrigen, setTextoFiltraEmpresasOrigen] =
    useState('');
  const [textoFiltraEmpresasDestino, setTextoFiltraEmpresasDestino] =
    useState('');

  const [isMaxCaracterEOri, setIsMaxCarcaterEOri] = useState(false);
  const [isMaxCaracterEDes, setIsMaxCarcaterEDes] = useState(false);

  useEffect(() => {
    // Se agrega el valor de la empresa origen al campo empresaATOrigen
    if (values.empresaATOrigen.value) {
      setFieldValue('tieneEmpresaATOrigen', true);
    }

    if (values.empresaATDestino.value) {
      setFieldValue('tieneEmpresaATDestino', true);
    }
  }, [
    values.empresaATOrigen.value,
    values.empresaATDestino.value,
    setFieldValue
  ]);

  useEffect(() => {
    if (textoFiltraEmpresasOrigen.length < 4) {
      setEmpresasOrigen(false);
      // setFieldValue('empresaATOrigen', '');
    }
  }, [
    isMaxCaracterEOri,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    textoFiltraEmpresasOrigen
  ]);

  useEffect(() => {
    if (textoFiltraEmpresasDestino.length < 4) {
      setEmpresasDestino(false);
      // setFieldValue('empresaATDestino', '');
    }
  }, [
    isMaxCaracterEDes,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    textoFiltraEmpresasDestino
  ]);

  return (
    <>
      {/* NombreLER  */}

      {/*  Nombre LER (Listado europeo de residuos) */}
      <div
        className={
          'col-span-4 xxs:col-span-12  xs:col-span-12   lg:col-span-4 pt-0'
        }
      >
        {/*  Nombre LER (Listado europeo de residuos) */}
        <AutocompleteComponent
          className="mb-3 "
          clearOnEscape={true}
          disabled={cargandoListaLer}
          fullWidth
          accesor="nombreLER"
          loading={!listaLerNivelUno.length && cargandoListaLer}
          loadingText={!listaLerNivelUno.length && 'Cargando...'}
          name="nombreLER"
          noOptionsText="No hay coincidencias"
          openOnFocus={true}
          options={listaLerNivelUno}
          size="small"
          variant="outlined"
          onBlur={() => setFieldTouched('nombreLER')}
          value={values.nombreLER || ''}
          getOptionLabel={option => option.nombreLER || ''}
          onChange={(ev, nombreLERSelected, reason) => {
            const data = { name: 'nombreLER', value: nombreLERSelected };
            if (reason === 'clear') {
              data.value = '';
              setFieldValue('nombreSubLER', []);
              setFieldValue('nombreSubSubLER', []);
              setFieldValue('nombreSubSubSubLER', []);
              setTieneSubSubtiposLer(false);
            }
            // Limpieza al cambiar de opción
            setFieldTouched('anyoDeclaracion', true);
            setFieldValue('nombreSubLER', []);
            setFieldValue('nombreSubSubLER', []);
            setFieldValue('nombreSubSubSubLER', []);
            setTieneSubSubtiposLer(false);

            data.value
              ? setFieldValue('nombreLER', {
                  codigoLER: data.value.codigoLER,
                  nombreLER: data.value.nombreLER
                })
              : setFieldValue('nombreLER', []);

            calculoListaSubLer(listaLer, data.value, setFieldValue);

            setFieldTouched('codigoSubLER');
          }}
          isOptionEqualToValue={(option, value) =>
            option.nombreLER === value.nombreLER
          }
        >
          <InputComponent
            className="h-9"
            fullWidth
            touched={touched.nombreLER ? true : false}
            placeholder={listaLerNivelUno.length ? 'Seleccione nombre LER' : ''}
            estado={
              errors.nombreLER
                ? {
                    tipo: 'error',
                    mensaje: errors.nombreLER
                  }
                : null
            }
            label={
              <>
                <LabelComponent>
                  Nombre LER (Listado europeo de residuos)
                </LabelComponent>
              </>
            }
          />
        </AutocompleteComponent>
      </div>
      <div
        className={
          'col-span-4 xxs:col-span-12  xs:col-span-12   lg:col-span-4 pt-1.5'
        }
      >
        {/*  Nombre SubLER  */}
        {tieneSubtiposLer ? (
          <AutocompleteComponent
            className="mb-3 "
            clearOnEscape={true}
            accesor="nombreLER"
            disabled={listaLerNivelDos.length === 0 || cargandoListaLer}
            loading={!listaLerNivelDos.length && cargandoListaLer}
            loadingText={!listaLerNivelDos.length && 'Cargando...'}
            name="nombreSubLER"
            noOptionsText="No hay coincidencias"
            openOnFocus={false}
            options={listaLerNivelDos}
            size="small"
            variant="outlined"
            onBlur={() => setFieldTouched('nombreSubLER')}
            value={values.nombreSubLER || ''}
            getOptionLabel={option => option.nombreLER || ''}
            onChange={(ev, nombreSubLERSelected, reason) => {
              const data = {
                name: 'nombreSubLER',
                value: nombreSubLERSelected
              };

              if (reason === 'clear') {
                data.value = '';
                setFieldValue('nombreSubSubLER', []);
                setFieldValue('nombreSubSubSubLER', []);
                setTieneSubSubtiposLer(false);
              }

              // Limpieza al cambiar de opción
              setFieldValue('nombreSubSubLER', []);
              data.value
                ? setFieldValue('nombreSubLER', {
                    codigoLER: data.value.codigoLER,
                    nombreLER: data.value.nombreLER
                  })
                : setFieldValue('nombreSubLER', []);

              //Calculo SUB SUB LER
              calculoListaSubSubLer(
                listaLer,
                data.value,
                setFieldValue,
                setListaLerNivelTres,
                setTieneSubSubtiposLer
              );

              setFieldTouched('nombreSubSubLER');
              setFieldTouched('tipoResiduo');
            }}
          >
            <InputComponent
              className="h-9"
              fullWidth
              touched={touched.codigoSubLER ? true : false}
              placeholder={listaLerNivelDos.length ? 'Seleccione' : ''}
              estado={
                errors.nombreSubLER
                  ? {
                      tipo: 'error',
                      mensaje: errors.nombreSubLER
                    }
                  : null
              }
              label={
                <>
                  <LabelComponent>{/* Sub Tipo */}</LabelComponent>
                </>
              }
            />
          </AutocompleteComponent>
        ) : null}
      </div>
      <div
        className={
          'col-span-4 xxs:col-span-12  xs:col-span-12   lg:col-span-4 pt-1.5'
        }
      >
        {/*  Nombre SubSubLER  */}
        {tieneSubSubtiposLer ? (
          <AutocompleteComponent
            className="mb-3 "
            accesor="nombreLER"
            clearOnEscape={true}
            disabled={listaLerNivelTres.length === 0 || cargandoListaLer}
            loading={!listaLerNivelTres.length && cargandoListaLer}
            loadingText={!listaLerNivelTres.length && 'Cargando...'}
            name="nombreSubSubLER"
            openOnFocus={false}
            size="small"
            variant="outlined"
            options={listaLerNivelTres}
            noOptionsText="No hay coincidencias"
            onBlur={() => setFieldTouched('nombreSubSubLER')}
            value={values.nombreSubSubLER || ''}
            getOptionLabel={option => option.nombreLER || ''}
            onChange={(ev, nombreSubSubLERSelected, reason) => {
              const data = {
                name: 'nombreSubSubLER',
                value: nombreSubSubLERSelected
              };
              if (reason === 'clear') {
                data.value = '';
                setFieldValue('nombreSubSubSubLER', []);
              }

              data.value
                ? setFieldValue('nombreSubSubLER', {
                    codigoLER: data.value.codigoLER,
                    nombreLER: data.value.nombreLER
                  })
                : setFieldValue('nombreSubSubLER', []);

              setFieldTouched('codigoSubSubLER');
              setFieldTouched('tipoResiduo');
            }}
          >
            <InputComponent
              className="h-9"
              fullWidth
              touched={touched.nombreSubSubLER ? true : false}
              placeholder={listaLerNivelTres.length ? 'Seleccione' : ''}
              estado={
                errors.nombreSubSubLER
                  ? {
                      tipo: 'error',
                      mensaje: errors.nombreSubSubLER
                    }
                  : null
              }
              label={
                <>
                  <LabelComponent>{/* Sub Sub Tipo LER */}</LabelComponent>
                </>
              }
            />
          </AutocompleteComponent>
        ) : null}
      </div>

      <div className="col-span-12"></div>

      {/* Tratamiento */}
      <div className=" col-span-8 xxs:col-span-12  xs:col-span-12   lg:col-span-8">
        <InputComponent
          autoComplete={'off'}
          fullWidth
          label={<LabelComponent>Tratamiento</LabelComponent>}
          name="tratamiento"
          onBlur={e => {
            setFieldTouched('tratamiento', true);
            setFieldTouched('cantidad', true);
          }}
          onChange={ev => {
            ev && handleChange(ev) && setFieldTouched('tratamiento');
            !ev && setFieldValue('tratamiento', '');
          }}
          value={values.tratamiento ? values.tratamiento : ''}
          touched={touched.tratamiento}
          estado={
            errors.tratamiento
              ? { tipo: 'error', mensaje: errors.tratamiento }
              : null
          }
        />
      </div>

      {/* Cantidad */}
      <div className="col-span-4 xxs:col-span-12  xs:col-span-12   lg:col-span-4">
        <InputNumber
          maxDecimals={DICTONARY.NUMERO_DECIMALES}
          type="text"
          autoComplete={'off'}
          fullWidth
          label={<LabelComponent>Cantidad:</LabelComponent>}
          name="cantidad"
          onChange={ev => {
            if (!ev.target.value)
              return (
                setFieldValue('cantidad_str', '') &&
                setFieldValue('cantidad', '')
              );
            const value = ev.target.value;
            const valueWithoutDots = value.split('.').join('');
            let doubleValue = valueWithoutDots.replace(',', '.');
            setFieldValue('cantidad', parseFloat(doubleValue));
          }}
          onFocus={handleFocusCantidad}
          touched={touched.cantidad}
          value={values.cantidad_str || values.cantidad}
          variant="outlined"
          size="small"
          placeholder={!values.cantidad ? 'Ingrese cantidad' : ''}
          onBlur={e => {
            setFieldTouched('empresaATOrigen', true);
            setFieldTouched('cantidad', true);
          }}
          estado={
            errors.cantidad
              ? {
                  tipo: 'error',
                  mensaje: errors.cantidad
                }
              : null
          }
        />
      </div>

      {/* Alerta mensaje con indicaciones */}
      <div className="col-span-12">
        <AlertComponent className="mb-2" type="info">
          Para <strong>desplegar</strong> las opciones de{' '}
          <strong>empresas registradas</strong> en la base de datos por favor{' '}
          <strong>ingresar</strong> un texto mínimo de <strong>4 letras</strong>
          ,
          <br /> luego se <strong>filtrará</strong> por la{' '}
          <strong>razón social.</strong>
        </AlertComponent>
      </div>
      {/* Empresa Origen */}
      <div className="col-span-6  xxs:col-span-12  xs:col-span-12  lg:col-span-6">
        <AutoCompleteAsyncController
          name="empresaATOrigen"
          accesor="label"
          limitTags={2}
          disabled={loadingEmpresasOrigen}
          setFieldValue={data => setFieldValue('empresaATOrigen', data)}
          setFieldTouched={() => setFieldTouched('empresaATOrigen')}
          value={
            values.empresaATOrigen && values.primeraCargaEmpresaATOrigen
              ? values.empresaATOrigen
              : values.empresaATOrigenEdit
          }
          isSubmitting={isSubmitting}
          onChange={(event, empresasOrigenSelected, reason) => {
            if (reason === 'clear') {
              empresasOrigenSelected = '';
              setFieldValue('primeraCargaEmpresaATOrigen', false);
            }
            setFieldValue('empresaATOrigenEdit', empresasOrigenSelected);
            setFieldValue('empresaATOrigen', empresasOrigenSelected);
          }}
          fetchCall={controllerValues =>
            // console.log(controllerValues)
            obtenerTodasEmpresasAT({
              setLoadingEmpresas: controllerValues.setIsLoading,
              setEmpresas: controllerValues.setData,
              paginationEmpresas: controllerValues.paginationEmpresas,
              razonSocial: controllerValues.inputValue,
              setRowCountEmpresas: controllerValues.setRowCountEmpresas
            })
          }
          renderOptions={(props, option, { selected }) => {
            <li {...props}>
              <span>{option?.label}</span>
            </li>;
          }}
        ></AutoCompleteAsyncController>
      </div>
      {/* Empresa Destino */}
      <div className="col-span-6  xxs:col-span-12  xs:col-span-12  lg:col-span-6">
        <AutoCompleteAsyncController
          name="empresaATDestino"
          accesor="label"
          limitTags={2}
          disabled={loadingEmpresasOrigen}
          setFieldValue={data => setFieldValue('empresaATDestino', data)}
          setFieldTouched={() => setFieldTouched('empresaATDestino')}
          isSubmitting={isSubmitting}
          value={
            values.empresaATDestino && values.primeraCargaEmpresaATDestino
              ? values.empresaATDestino
              : values.empresaATDestinoEdit
          }
          onChange={(event, empresasDestinoSelected, reason) => {
            if (reason === 'clear') {
              empresasDestinoSelected = '';
              setFieldValue('primeraCargaEmpresaATDestino', false);
            }
            setFieldValue('empresaATDestinoEdit', empresasDestinoSelected);
            setFieldValue('empresaATDestino', empresasDestinoSelected);
          }}
          fetchCall={controllerValues =>
            // console.log(controllerValues)
            obtenerTodasEmpresasAT({
              setLoadingEmpresas: controllerValues.setIsLoading,
              setEmpresas: controllerValues.setData,
              paginationEmpresas: controllerValues.paginationEmpresas,
              razonSocial: controllerValues.inputValue,
              setRowCountEmpresas: controllerValues.setRowCountEmpresas
            })
          }
          renderOptions={(props, option, { selected }) => {
            <li {...props}>
              <span>{option?.label}</span>
            </li>;
          }}
        ></AutoCompleteAsyncController>
      </div>
    </>
  );
};

export default FormularioEditarResiduosAT;
