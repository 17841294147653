import { useContext } from 'react';
import { NuevosCriteriosMatch } from './NuevosCriteriosMatch/NuevosCriteriosMatch';
import { MatchContext } from '../../context/MatchContext';
import { CriteriosMatch } from './CriteriosMatch/CriteriosMatch';

/**
 * @name CriteriosBusqueda
 * Oculta o muestra componente de nuevos criterios o resumen de criterios según lo que realice el usuario.
 */
export const CriteriosBusqueda = () => {
  const { isNuevosCriterios } = useContext(MatchContext);

  return (
    <>
      <div className="bg-white shadow-xl py-3 z-20 relative min-h-[64px]">
        <div className="px-4 md:container md:mx-auto">
          <div>
            <div className="grid grid-cols-12 items-end">
              <CriteriosMatch />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`
        ${isNuevosCriterios ? 'block' : 'hidden'}
      bg-white shadow-md py-3 z-10 relative min-h-[64px]`}
      >
        <div className="px-4 md:container md:mx-auto">
          <NuevosCriteriosMatch />
        </div>
      </div>
    </>
  );
};
