import React from 'react';
import List from '@mui/material/List';
import { CloudUpload, FindInPage, Link } from '@mui/icons-material';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import { DICTONARY } from 'const/Dictonary';
import { PERMISOS } from 'const/Permisos';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { RUTAS } from 'const/Rutas';

const SubMenuTutoriales = React.forwardRef(({ forceRefresh }, ref) => {
  return (
    <List>
      <VerificarPermisos nombrePermiso={PERMISOS.buscarMaterialInformacion}>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.MATERIAL_DIFUSION)}
          icon={<FindInPage />}
          label={'Buscar material de información'}
        />
      </VerificarPermisos>

      <VerificarPermisos nombrePermiso={PERMISOS.cargarMaterialInformacion}>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.MATERIAL_DIFUSION_CARGAR)}
          icon={<CloudUpload />}
          label={'Subir material de información'}
        />
      </VerificarPermisos>

      <ListItemButtonComponent
        onClick={() =>
          window.open(DICTONARY.URL.PORTAL_DIFUSION_VIDEOS, '_blank')
        }
        icon={<Link />}
        label={'Ver tutoriales'}
      />
      <ListItemButtonComponent
        onClick={() => window.open(DICTONARY.URL.PORTAL_DIFUSION, '_blank')}
        icon={<Link />}
        label={'Portal de información'}
      />
    </List>
  );
});

export default SubMenuTutoriales;
