import { useContext } from 'react';
import { MatchContext } from '../../../context/MatchContext';
import { ResultadoItem } from './ResultadoItem';
import AlertComponent from 'components/design-system/Alert/Alert';
import Text from 'components/design-system/Text/Text';

/**
 * @name Resultados
 * Componente que itera sobre el match para desplegar el resumen de cada residuo
 */
export const Resultados = () => {
  const { matchFiltrado } = useContext(MatchContext);

  return (
    <>
      {matchFiltrado.numeroSucursales === 0 && (
        <div className="m-4">
          <AlertComponent type="info">
            <Text size="S">
              <strong className="text-black">
                No se han encontrado resultados
              </strong>
              . Intenta realizando una nueva busqueda y modificando los
              criterios.
            </Text>
          </AlertComponent>
        </div>
      )}

      {matchFiltrado?.residuos?.map((residuo, index) => (
        <ResultadoItem residuo={residuo} key={index} />
      ))}
    </>
  );
};
