import { useCallback, useContext, useEffect, useState } from 'react';
import { DICTONARY } from 'const/Dictonary';
import serviceDocumentos from 'services/documentos';
import serviceCommons from 'services/commons';
import serviceResiduos from 'services/residuos';
import { AuthContext } from 'context';
import { AlertaError, AlertaInfo } from 'utils/Alertas';

export const useBuscarDifusion = () => {
  const { logeduser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingComunas, setIsLoadingComunas] = useState(false);
  const [listaMarcoLegal, setListaMarcoLegal] = useState([]);
  const [listaNormativaTecnica, setListaNormativaTecnica] = useState([]);
  const [listaPublicaciones, setListaPublicaciones] = useState([]);
  const [listaTipoResiduo, setlistaTipoResiduo] = useState([]);
  const [listaComunas, setListaComunas] = useState([]);
  const [listaRegiones, setListaRegiones] = useState([]);
  const [listaTipoTratamiento, setListaTipoTratamiento] = useState([]);

  const onSubmit = useCallback(async values => {
    try {
      const queryDocumento = {
        codigosMarcoLegal: values.marcoLegal?.map(
          categoria => categoria.codigoMarcoLegal
        ),
        codigosNormativaTecnica: values.normativaTecnica?.map(
          categoria => categoria.codigoNormativaTecnica
        ),
        codigosPublicacion: values.publicaciones?.map(
          categoria => categoria.codigoPublicacion
        ),
        codigosComuna: values.comuna?.map(comuna => comuna.codigoComuna),
        codigosRegion: values.region?.map(region => region.codigoRegion),
        codigoTipoResiduo: values.tipoResiduo?.codigoTipoResiduo,
        codigoTipoTratamiento:
          values.tipoTratamientoResiduo?.codigoTipoTratamiento,
        nombreArchivo: values.nombreArchivo
      };

      Object.keys(queryDocumento).forEach(key => {
        if (!queryDocumento[key] || queryDocumento[key].length === 0) {
          delete queryDocumento[key];
        }
      });

      // console.log(queryDocumento);

      const response = await serviceDocumentos.obtenerListaMaterialInformacion(
        queryDocumento,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );

      if (response.data.error) throw new Error('SIN_REGISTROS');

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (error.message === 'SIN_REGISTROS') {
        AlertaInfo('No se han encontrado resultados');
        return [];
      } else {
        AlertaError(
          'Ha ocurrido un error al buscar los documentos. Recarga la página para volver a intentarlo de lo contrario comunícate con el administrador si el problema persiste.'
        );
        return [];
      }
    }
  }, []);

  const handleObtenerComunas = useCallback(
    async (codigoRegion, setFieldValue) => {
      if (!codigoRegion) return;
      setIsLoadingComunas(true);

      const params = {
        codigosRegion: codigoRegion,
        totalPagina: DICTONARY.PAGINACION.ALL_DATA
      };

      try {
        const response = await serviceCommons.obtieneComunas(params);

        if (response.length === 0) return;

        const content = response.data.content;

        setListaComunas(content);

        setFieldValue('comuna', content);
      } catch (err) {
        console.error('error: ', err);
      } finally {
        setIsLoadingComunas(false);
      }
    },
    [setIsLoadingComunas, setListaComunas]
  );

  // OBTENER LISTA TIPO RSD, LISTA TIPO TRATAMIENTO Y LISTA COMUNAS
  useEffect(() => {
    if (!logeduser) {
      return;
    }

    setIsLoading(true);

    const promiseMarcoLegal = new Promise((resolve, reject) => {
      serviceDocumentos
        .obtenerListaMarcosLegales(
          {
            totalPagina: DICTONARY.PAGINACION.ALL_DATA
          },
          DICTONARY.INTERCEPTOR.DESACTIVADO
        )
        .then(result => {
          const content = result.data.content;

          if (Array.isArray(content)) {
            const listaOrdenada = content.sort((a, b) =>
              a.nombreMarcoLegal > b.nombreMarcoLegal ? 1 : -1
            );

            resolve(listaOrdenada);
          } else {
            resolve([]);
          }
        })
        .catch(err => {
          reject(err);
        });
    });

    const promiseNormativaTecnica = new Promise((resolve, reject) => {
      serviceDocumentos
        .obtenerListaNormativasTecnicas(
          {
            totalPagina: DICTONARY.PAGINACION.ALL_DATA
          },
          DICTONARY.INTERCEPTOR.DESACTIVADO
        )
        .then(result => {
          const content = result.data.content;

          if (Array.isArray(content)) {
            const listaOrdenada = content.sort((a, b) =>
              a.nombreNormativaTecnica > b.nombreNormativaTecnica ? 1 : -1
            );

            resolve(listaOrdenada);
          } else {
            resolve([]);
          }
        })
        .catch(err => {
          reject(err);
        });
    });

    const promisePublicaciones = new Promise((resolve, reject) => {
      serviceDocumentos
        .obtenerListaPublicaciones(
          {
            totalPagina: DICTONARY.PAGINACION.ALL_DATA
          },
          DICTONARY.INTERCEPTOR.DESACTIVADO
        )
        .then(result => {
          if (Array.isArray(result.data.content)) {
            const listaOrdenada = result.data.content.sort((a, b) =>
              a.nombrePublicacion > b.nombrePublicacion ? 1 : -1
            );

            resolve(listaOrdenada);
          } else {
            resolve([]);
          }
        })
        .catch(err => {
          reject(err);
        });
    });

    const promiseTipoRSD = new Promise((resolve, reject) => {
      serviceCommons
        .tipoRSD(
          {
            nivel: DICTONARY.TIPO_RESIDUOS.NIVEL_1,
            totalPagina: DICTONARY.PAGINACION.ALL_DATA
          },
          DICTONARY.INTERCEPTOR.DESACTIVADO
        )
        .then(result => {
          if (Array.isArray(result.data.content)) {
            let listaRSDTemp = result.data.content;
            listaRSDTemp.push(
              {
                codigoTipoResiduo: 999_999,
                nombreTipo: 'Material particulado'
              },
              {
                codigoTipoResiduo: 999_998,
                nombreTipo: 'Sustancias peligrosas'
              }
            );
            listaRSDTemp.sort((a, b) => (a.nombreTipo > b.nombreTipo ? 1 : -1));

            resolve(listaRSDTemp);
          } else {
            resolve([]);
          }
        })
        .catch(err => {
          reject(err);
        });
    });

    const promiseTipoTratamiento = new Promise((resolve, reject) => {
      const params = {
        totalPagina: DICTONARY.PAGINACION.ALL_DATA
      };
      serviceResiduos
        .obtenerListaTipoTratamiento(params, DICTONARY.INTERCEPTOR.DESACTIVADO)
        .then(result => {
          let newLista = [];
          if (Array.isArray(result.data.content)) {
            newLista = result.data.content.reduce((filtered, curr) => {
              const exixst = filtered.find(
                t => t.nombreTipoTratamiento === curr.nombreTipoTratamiento
              );
              if (exixst) {
                return filtered;
              } else {
                return [...filtered, curr];
              }
            }, []);
          }
          resolve(newLista);
        })
        .catch(err => {
          reject(err);
        });
    });

    const promiseRegiones = new Promise((resolve, reject) => {
      serviceCommons
        .regiones({}, DICTONARY.INTERCEPTOR.DESACTIVADO)
        .then(result => {
          if (Array.isArray(result.data.content)) {
            resolve(result.data.content);
          } else {
            resolve([]);
          }
        })
        .catch(err => {
          reject(err);
          console.error('Error al obtener la lista de regiones: ', err);
        });
    });

    Promise.all([
      promiseMarcoLegal,
      promiseNormativaTecnica,
      promisePublicaciones,
      promiseTipoRSD,
      promiseTipoTratamiento,
      promiseRegiones
    ])
      .then(
        ([
          listaMarcoLegal,
          listaNormativaTecnica,
          listaPublicaciones,
          listaRSDTemp,
          listaTipoTratamiento,
          listaRegiones
        ]) => {
          setListaMarcoLegal(listaMarcoLegal);
          setListaNormativaTecnica(listaNormativaTecnica);
          setListaPublicaciones(listaPublicaciones);
          setlistaTipoResiduo(listaRSDTemp);
          setListaTipoTratamiento(listaTipoTratamiento);
          setListaRegiones(listaRegiones);

          setIsLoading(false);
          setIsLoadingComunas(false);
        }
      )
      .catch(err => {
        setIsLoading(false);
        console.error('Error al tratar de obtener las listas >> ', err);
      });
  }, []);

  return {
    onSubmit,
    handleObtenerComunas,
    isLoading,
    isLoadingComunas,
    listaMarcoLegal,
    listaNormativaTecnica,
    listaPublicaciones,
    listaTipoResiduo,
    listaTipoTratamiento,
    listaComunas,
    listaRegiones
  };
};
