// Design System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import InputNumber from 'components/design-system/input/InputNumber';
import LabelComponent from 'components/design-system/Label/Label';
// Diccionarity
import { DICTONARY } from 'const/Dictonary';

const EnvaseEmbalaje = ({
  // Formik
  errors,
  // handleBlur,
  // handleChange,
  // handleReset,
  // handleSubmit,
  isSubmitting,
  // setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  // validateForm,
  values,
  // Dependencias
  tipoEmbalaje,
  isLoadingTipoEmbalaje
}) => {
  return (
    <>
      <div className="col-span-12">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12">
            <Heading type="h4" className="mb-0">
              Envase - embalaje
            </Heading>
          </div>
          <div className="col-span-12 xs:col-span-12">
            <div className="grid grid-cols-12 gap-4">
              {/* Tipo embalaje */}
              <div className="col-span-12 sm:col-span-6 xl:col-span-4">
                <AutocompleteComponent
                  accesor="nombreTipoEmbalaje"
                  clearOnEscape={true}
                  disabled={isSubmitting || isLoadingTipoEmbalaje}
                  fullWidth
                  name={'embalaje'}
                  options={tipoEmbalaje || []}
                  value={values.embalaje || ''}
                  onChange={(event, newValue, reason) => {
                    let data = { name: 'embalaje', value: newValue };
                    if (reason === 'clear') {
                      data.value = '';
                    }
                    setFieldValue('embalaje', data.value);
                  }}
                  getOptionLabel={option => option.nombreTipoEmbalaje || ''}
                  noOptionsText={
                    isLoadingTipoEmbalaje ? 'Cargando...' : 'Sin opciones'
                  }
                >
                  <InputComponent
                    autoComplete="off"
                    disabled={isSubmitting || isLoadingTipoEmbalaje}
                    fullWidth
                    onBlur={() => {
                      setFieldTouched('embalaje');
                    }}
                    touched={touched.embalaje}
                    placeholder={
                      isLoadingTipoEmbalaje
                        ? 'Cargando...'
                        : 'Selecciona una opción'
                    }
                    estado={
                      errors.embalaje
                        ? { tipo: 'error', mensaje: errors.embalaje }
                        : null
                    }
                    label={
                      <LabelComponent>
                        Tipo de envase o embalaje{' '}
                        <span className="text-neutral-60">(opcional)</span>
                      </LabelComponent>
                    }
                  />
                </AutocompleteComponent>
              </div>
              {/* Cantidad embalaje */}
              <div className="col-span-12 sm:col-span-6 xl:col-span-4">
                <InputNumber
                  autoComplete="off"
                  disabled={isSubmitting}
                  label={
                    <LabelComponent>
                      Cantidad (kg) del embalaje{' '}
                      <span className="text-neutral-60">(opcional)</span>
                    </LabelComponent>
                  }
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  name={'cantidadEmbalaje'}
                  placeholder={!values.cantidadEmbalaje ? '' : ''}
                  touched={touched.cantidadEmbalaje}
                  type="text"
                  value={
                    values.cantidadEmbalaje_str || values.cantidadEmbalaje || ''
                  }
                  onChange={event => {
                    const { value } = event.target;
                    const sanitizedValue = value
                      .replaceAll('.', '')
                      .replaceAll(',', '.');
                    const floatValue = parseFloat(sanitizedValue);
                    if (!isNaN(floatValue)) {
                      setFieldValue('cantidadEmbalaje', floatValue);
                    }
                  }}
                  onFocus={event => {
                    const { value } = event.target;
                    setFieldTouched('cantidadEmbalaje');
                    if (
                      !event ||
                      !event.target ||
                      typeof event.target.value !== 'string'
                    ) {
                      return;
                    }
                    let sanitizedValue = value.split('.').join('') || '';
                    setFieldValue('cantidadEmbalaje_str', sanitizedValue);
                  }}
                  onBlur={() => {
                    setFieldTouched('cantidadEmbalaje');
                  }}
                  estado={
                    errors.cantidadEmbalaje
                      ? { tipo: 'error', mensaje: errors.cantidadEmbalaje }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnvaseEmbalaje;
