import { useState, useEffect, useMemo, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { Link, useParams, useHistory } from 'react-router-dom';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { ChevronLeft } from '@mui/icons-material';
import { DICTONARY } from 'const/Dictonary';
import { RUTAS } from 'const/Rutas';
import { schemaPerfiles } from 'views/Perfiles/schema/schemaPerfiles';
import CamposPerfiles from './CamposPerfiles';
import { useServicesPerfiles } from 'views/Perfiles/hooks/useServicesPerfiles';
import { useSubmitPerfiles } from 'views/Perfiles/hooks/useSubmitPerfiles';
import { SkeletonPerfiles } from 'views/Perfiles/skeleton/SkeletonPerfiles';

const PerfilesFormik = () => {
  const params = useParams();

  const history = useHistory();

  const { location } = history;

  const [codigoPerfil, setCodigoPerfil] = useState(
    params.codigoPerfil || 'nuevo'
  );
  const [operacionFinalizada, setOperacionFinalizada] = useState(false);

  // Tipo de Formulario
  const tipo = location?.state?.formulario?.tipo;

  const [tipoFormulario, setTipoFormulario] = useState(tipo || '');

  const {
    //Funciones
    ObtienePermisos,
    // States
    permisos,
    isLoadingPermisos
  } = useServicesPerfiles();

  const cargaPermisos = useCallback(async () => {
    await ObtienePermisos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No hay dependencias intencionalmente

  useMemo(() => {
    cargaPermisos();
  }, [cargaPermisos]);

  // Hooks
  const {
    perfil,
    cargarPerfil,
    isLoading,
    setIsLoading,
    cargaData,
    handleSubmit
  } = useSubmitPerfiles(codigoPerfil);

  // ejecutar cargarRecursoAdquirido() si se refresca la pagina
  useEffect(() => {
    if (!tipoFormulario) return;
    cargarPerfil();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoFormulario]);

  useEffect(() => {
    if (!cargaData) return;
    // console.log('perfil formik ::>', perfil);
  }, [cargaData, isLoading, perfil]);

  // Tipos de Formulario
  const tiposPosibles = ['ingresar', 'editar'];
  const tipoForm = tiposPosibles.reduce((obj, tipo) => {
    obj[tipo] = tipoFormulario === tipo;
    return obj;
  }, {});
  const isFormIngresar = tipoFormulario === 'ingresar';

  return (
    <SidebarLayout>
      <Heading type="h2" className="mb-4 text-lg md:text-2xl">
        <div className="flex items-center">
          <Link to={`${RUTAS.ADMINISTRAR_PERFILES}`}>
            <IconButtonComponent type="secondary" className="mr-2">
              <ChevronLeft />
            </IconButtonComponent>
          </Link>
          <span>{` ${tipoForm.ingresar ? 'Ingresar' : 'Editar'} Perfil`}</span>
        </div>
        {isLoading && !cargaData && !isFormIngresar ? (
          <SkeletonPerfiles tipoForm={tipoFormulario} />
        ) : (
          <Formik
            initialValues={perfil}
            validationSchema={schemaPerfiles}
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount={true}
            onSubmit={async (values, { resetForm }) => {
              //  console.log('submit finalizado ::>', values);

              await handleSubmit(values, tipoFormulario);
              // resetForm();
              setOperacionFinalizada(true);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleReset,
              handleSubmit,
              isSubmitting,
              setFieldError,
              setFieldTouched,
              setFieldValue,
              touched,
              validateForm,
              values
            }) => (
              <Form
                id="editarPerfiles"
                onChange={event => {
                  //  console.log('touched ::>', touched);
                  //  console.log('errors ::>', errors);
                  //  console.log('values ::>', values);
                }}
              >
                <div className="bg-white rounded p-5 shadow-sm mb-5">
                  <div className="grid grid-cols-12 gap-4">
                    <CamposPerfiles
                      // Formik
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      handleReset={handleReset}
                      handleSubmit={handleSubmit}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      validateForm={validateForm}
                      values={values}
                      isSubmitting={isSubmitting}
                      tipoForm={tipoForm}
                      // Dependencias
                      permisos={permisos}
                      isLoadingPermisos={isLoadingPermisos}
                    />

                    <div className="col-span-12 justify-between">
                      <div className="flex justify-between">
                        <Link to={`${RUTAS.ADMINISTRAR_PERFILES}`}>
                          <ButtonComponent type="secondary" className="mr-2">
                            <ChevronLeft /> Volver
                          </ButtonComponent>
                        </Link>

                        {operacionFinalizada ? (
                          <Link to={`${RUTAS.ADMINISTRAR_PERFILES}`}>
                            <ButtonComponent type="primary" className="mr-2">
                              Finalizar
                            </ButtonComponent>
                          </Link>
                        ) : (
                          <ButtonComponent
                            form="editarPerfiles"
                            buttonType="submit"
                            isLoading={isSubmitting || isLoading}
                            disabled={
                              isSubmitting || Object.keys(errors).length > 0
                            }
                          >
                            {tipoFormulario === 'ingresar'
                              ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD
                                  .INGRESAR + ' perfil '
                              : tipoFormulario === 'editar'
                              ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD
                                  .EDITAR + ' perfil '
                              : ''}
                          </ButtonComponent>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Heading>
    </SidebarLayout>
  );
};

export default PerfilesFormik;
