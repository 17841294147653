import { useEffect, useState } from 'react';
// Design System
import AlertComponent from 'components/design-system/Alert/Alert';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import InputNumber from 'components/design-system/input/InputNumber';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
import { Help } from '@mui/icons-material';
import { TooltipComponent } from 'components/design-system/Tooltip/Tooltip';

// Diccionarity
import { DICTONARY } from 'const/Dictonary';
import { CalculoListaLer, CalculoListaSubLer } from 'utils/useUtilidades';
import {
  ObtieneListaLer,
  ObtieneListaTipoResiduos,
  ObtieneListaTipoTratamiento
} from 'hooks';

// hooks

const GeneracionResiduo = ({
  // Formik
  errors,
  // handleBlur,
  handleChange,
  // handleReset,
  // handleSubmit,
  isSubmitting,
  // setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  // validateForm,
  values
  // Dependencias
}) => {
  const [listaSubTipoResiduos, setListaSubTipoResiduos] = useState([]);

  // Tiene Datos
  const [tieneSubSubtiposLer, setTieneSubSubtiposLer] = useState(true);
  const [tieneTipoResiduos, setTieneTipoResiduos] = useState(true);

  // Cargando
  const [cargandoListaSubTipoResiduos, setCargandoListaSubTipoResiduos] =
    useState(true);

  //Calculo SUBLER
  const {
    calculoListaSubLer,
    listaLerNivelDos,
    listaLerNivelTres,
    tieneSubtiposLer,
    setListaLerNivelTres
  } = CalculoListaLer();

  // SERVICIO nombresLER
  const {
    listaLer,
    cargandoListaLer,
    // handlelistaler,
    listaLerNivelUno,
    listaLerNivelDosEdit,
    listaLerNivelUnoEdit
  } = ObtieneListaLer();

  // SERVICIO nombresTipoResiduos
  const {
    listaTipoResiduos,
    cargandoListaTipoResiduos,
    // handleTipoResiuduos,
    listaTipoResiduosNivelUno,
    listaTipoResiduoNivelUnoEdit
  } = ObtieneListaTipoResiduos();

  // Calculo lista SubLer
  const { calculoListaSubSubLer } = CalculoListaSubLer();

  //Calculo SUBTIPOS RESIDUOS
  const calculoSubTipoResiduos = (codigoTipoResiduo, setFieldValue) => {
    const subtipos = listaTipoResiduos.filter(
      t =>
        t.nivel === 2 && t.codigoNivelAnteriorTipoResiduo === codigoTipoResiduo
    );

    setTieneTipoResiduos(!!subtipos.length);
    setFieldValue('tieneSubTipoResiduo', !!subtipos.length);

    subtipos.length > 0 && setCargandoListaSubTipoResiduos(false);

    subtipos && setListaSubTipoResiduos(subtipos);
  };

  // Lena selectores de LER para edición
  useEffect(() => {
    if (
      listaLerNivelDosEdit.length &&
      values.nombreSubSubLER?.codigoNivelAnterior
    ) {
      const nivelDosEdit = listaLerNivelDosEdit.find(
        ler => ler.codigoLER === values.nombreSubSubLER?.codigoNivelAnterior
      );
      setFieldValue('nombreSubLER', nivelDosEdit);
    }
  }, [
    listaLer.length,
    listaLerNivelDos,
    listaLerNivelDosEdit,
    setFieldValue,
    values.nombreSubSubLER?.codigoLER,
    values.nombreSubSubLER?.codigoNivelAnterior
  ]);

  useEffect(() => {
    if (
      listaLerNivelUnoEdit.length &&
      values.nombreSubLER?.codigoNivelAnterior
    ) {
      const nivelUnoEdit = listaLerNivelUnoEdit.find(
        ler => ler.codigoLER === values.nombreSubLER.codigoNivelAnterior
      );
      setFieldValue('nombreLER', nivelUnoEdit);
    }
  }, [
    listaLer.length,
    listaLerNivelUno,
    listaLerNivelUnoEdit,
    setFieldValue,
    values.nombreSubLER?.codigoLER,
    values.nombreSubLER?.codigoNivelAnterior
  ]);

  // Lena selectores de LER para edición
  useEffect(() => {
    if (
      listaTipoResiduoNivelUnoEdit.length &&
      values.subTipoResiduo?.codigoNivelAnteriorTipoResiduo
    ) {
      const nivelUnoEdit = listaTipoResiduoNivelUnoEdit.find(
        ler =>
          ler.codigoTipoResiduo ===
          values.subTipoResiduo.codigoNivelAnteriorTipoResiduo
      );

      setFieldValue('tipoResiduo', nivelUnoEdit);
    }
  }, [
    listaTipoResiduoNivelUnoEdit,
    listaTipoResiduoNivelUnoEdit.length,
    setFieldValue,
    values.nombreSubSubLER?.codigoLER,
    values.nombreSubSubLER?.codigoNivelAnterior,
    values.subTipoResiduo?.codigoNivelAnterior,
    values.subTipoResiduo?.codigoNivelAnteriorTipoResiduo
  ]);

  // Servicio tipo tratamiento
  const {
    listaTipoTratamiento,
    isLoadingTipoTratamiento
    // handleListaTipoTratamiento,
  } = ObtieneListaTipoTratamiento(values.subTipoResiduo?.codigoTipoResiduo);

  return (
    <>
      <div className="col-span-12">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12">
            <Heading type="h4" className="mb-0">
              Estimación de residuo a generar
            </Heading>
          </div>
          <div className="col-span-12 xs:col-span-12">
            <div className="grid grid-cols-12 gap-4">
              {/* NombreLER */}
              <div className="col-span-12 md:col-span-6 xl:col-span-4">
                <AutocompleteComponent
                  accesor="nombreLER"
                  className=""
                  clearOnEscape={true}
                  disabled={isSubmitting || cargandoListaLer}
                  fullWidth
                  getOptionLabel={option => option.nombreLER || ''}
                  loading={!listaLerNivelUno.length && cargandoListaLer}
                  loadingText={!listaLerNivelUno.length && 'Cargando...'}
                  name="nombreLER"
                  noOptionsText="No hay coincidencias"
                  openOnFocus={true}
                  options={listaLerNivelUno || []}
                  size="small"
                  value={values.nombreLER || ''}
                  variant="outlined"
                  onChange={(ev, selected) => {
                    // Limpieza al cambiar de opción
                    setFieldValue('nombreSubLER', []);
                    setFieldValue('nombreSubSubLER', []);

                    selected
                      ? setFieldValue('nombreLER', {
                          codigoLER: selected.codigoLER,
                          nombreLER: selected.nombreLER
                        })
                      : setFieldValue('nombreLER', []);

                    calculoListaSubLer(listaLer, selected, setFieldValue);

                    setFieldTouched('codigoSubLER');
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.nombreLER === value.nombreLER
                  }
                >
                  <InputComponent
                    className=""
                    fullWidth
                    onBlur={() => setFieldTouched('nombreLER')}
                    touched={touched.nombreLER}
                    placeholder={
                      listaLerNivelUno.length ? 'Seleccione nombre LER' : ''
                    }
                    estado={
                      errors.nombreLER
                        ? {
                            tipo: 'error',
                            mensaje: errors.nombreLER
                          }
                        : null
                    }
                    label={
                      <LabelComponent>
                        Nombre LER (Listado europeo de residuos)
                        <br />
                        <span className="text-neutral-60">Categoría</span>
                      </LabelComponent>
                    }
                  />
                </AutocompleteComponent>
              </div>

              {/* NombreSubLER */}
              <div className="col-span-12 md:col-span-6 xl:col-span-4">
                <AutocompleteComponent
                  accesor="nombreLER"
                  className=""
                  clearOnEscape={true}
                  disabled={isSubmitting || cargandoListaLer}
                  getOptionLabel={option => option.nombreLER || ''}
                  loading={!listaLerNivelDos.length && cargandoListaLer}
                  loadingText={!listaLerNivelDos.length && 'Cargando...'}
                  name="nombreSubLER"
                  noOptionsText="No hay coincidencias"
                  openOnFocus={false}
                  options={listaLerNivelDos || []}
                  size="small"
                  value={values.nombreSubLER || ''}
                  variant="outlined"
                  onChange={(ev, selected) => {
                    // Limpieza al cambiar de opción
                    setFieldValue('nombreSubSubLER', []);
                    selected
                      ? setFieldValue('nombreSubLER', {
                          codigoLER: selected.codigoLER,
                          nombreLER: selected.nombreLER
                        })
                      : setFieldValue('nombreSubLER', []);

                    //Calculo SUB SUB LER
                    calculoListaSubSubLer(
                      listaLer,
                      selected,
                      setFieldValue,
                      setListaLerNivelTres,
                      setTieneSubSubtiposLer
                    );

                    setFieldTouched('nombreSubSubLER');
                    setFieldTouched('tipoResiduo');
                  }}
                >
                  <InputComponent
                    fullWidth
                    disabled={listaLerNivelDos.length === 0 || cargandoListaLer}
                    onBlur={() => setFieldTouched('nombreSubLER')}
                    touched={touched.nombreSubLER}
                    placeholder={
                      listaLerNivelDos.length ? 'Seleccione nombre LER' : ''
                    }
                    estado={
                      errors.nombreSubLER
                        ? {
                            tipo: 'error',
                            mensaje: errors.nombreSubLER
                          }
                        : null
                    }
                    label={
                      <LabelComponent className="md:mt-5">
                        <span className="text-neutral-60">Capítulo</span>
                      </LabelComponent>
                    }
                  />
                </AutocompleteComponent>
              </div>

              {/* NombreSubSubLER */}
              <div className="col-span-12 md:col-span-6 xl:col-span-4">
                <AutocompleteComponent
                  accesor="nombreLER"
                  className=""
                  clearOnEscape={true}
                  disabled={isSubmitting || cargandoListaLer}
                  getOptionLabel={option => option.nombreLER || ''}
                  loading={!listaLerNivelTres.length && cargandoListaLer}
                  loadingText={!listaLerNivelTres.length && 'Cargando...'}
                  name="nombreSubSubLER"
                  noOptionsText="No hay coincidencias"
                  openOnFocus={false}
                  options={listaLerNivelTres || []}
                  size="small"
                  value={values.nombreSubSubLER || ''}
                  variant="outlined"
                  onChange={(ev, selected) => {
                    selected
                      ? setFieldValue('nombreSubSubLER', {
                          codigoLER: selected.codigoLER,
                          nombreLER: selected.nombreLER
                        })
                      : setFieldValue('nombreSubSubLER', []);

                    setFieldTouched('codigoSubSubLER');
                    setFieldTouched('tipoResiduo');
                  }}
                >
                  <InputComponent
                    fullWidth
                    onBlur={() => setFieldTouched('nombreSubSubLER')}
                    touched={touched.nombreSubSubLER}
                    placeholder={
                      listaLerNivelTres.length ? 'Seleccione nombre LER' : ''
                    }
                    estado={
                      errors.nombreSubSubLER
                        ? {
                            tipo: 'error',
                            mensaje: errors.nombreSubSubLER
                          }
                        : null
                    }
                    label={
                      <LabelComponent className="xl:mt-5">
                        <span className="text-neutral-60">Sub capítulo</span>
                      </LabelComponent>
                    }
                  />
                </AutocompleteComponent>
              </div>

              {/* Info */}
              <div className="col-span-12 xl:col-span-8">
                <AlertComponent type="info">
                  <Text>
                    En esta casilla tienes la opción de incorporar el nombre que
                    utilizas habitualmente del residuo, que genera el recurso
                    registrado, en otras palabras, es una forma abreviada de
                    reemplazar el nombre LER. Ejemplo: LER (Madera sin
                    sustancias peligrosas) despuntes de Tablones, cuartones,
                    planchas, listones, entre otros.
                  </Text>
                </AlertComponent>
              </div>

              <div className="col-span-12 xl:col-span-4"></div>

              {/* Tipo de residuo */}
              <div className="col-span-12 sm:col-span-6 xl:col-span-4">
                <AutocompleteComponent
                  accesor="nombreTipo"
                  className="mb-3 "
                  clearOnEscape={true}
                  disabled={isSubmitting || cargandoListaTipoResiduos}
                  fullWidth
                  loading={cargandoListaTipoResiduos}
                  loadingText={cargandoListaTipoResiduos && 'Cargando...'}
                  name="tipoResiduo"
                  openOnFocus={true}
                  options={listaTipoResiduosNivelUno || []}
                  size="small"
                  value={values.tipoResiduo || ''}
                  variant="outlined"
                  getOptionLabel={option => option.nombreTipo || ''}
                  onChange={(ev, selected) => {
                    if (!selected) {
                      setCargandoListaSubTipoResiduos(true);

                      // setSubTipoRSDdisabled(true);

                      setTieneTipoResiduos(false);

                      setFieldValue('tieneSubTipoResiduo', false);
                    }

                    // Limpieza subTipoResiduo
                    setFieldValue('subTipoResiduo', []);
                    // Limpieza tipoRecurso
                    setFieldValue('tipoRecurso', []);

                    selected
                      ? setFieldValue('tipoResiduo', {
                          codigoTipoResiduo: selected.codigoTipoResiduo,
                          nombreTipo: selected.nombreTipo
                        })
                      : setFieldValue('tipoResiduo', []);

                    // Calculo Sub Tipos de Residuos
                    selected &&
                      calculoSubTipoResiduos(
                        selected.codigoTipoResiduo,
                        setFieldValue
                      );

                    selected && setFieldTouched('subTipoResiduo');
                  }}
                >
                  <InputComponent
                    fullWidth
                    onBlur={() => setFieldTouched('tipoResiduo')}
                    touched={touched.tipoResiduo}
                    placeholder={
                      listaTipoResiduosNivelUno.length
                        ? 'Seleccione tipo de residuo'
                        : ''
                    }
                    estado={
                      errors.tipoResiduo
                        ? {
                            tipo: 'error',
                            mensaje: errors.tipoResiduo
                          }
                        : null
                    }
                    label={
                      <LabelComponent>
                        Residuo
                        <br />
                        <span className="text-neutral-60">
                          (incluye subproductos)
                        </span>
                      </LabelComponent>
                    }
                  />
                </AutocompleteComponent>
              </div>

              {/* Subtipo de residuo */}
              <div className="col-span-12 sm:col-span-6 xl:col-span-4">
                <AutocompleteComponent
                  accesor="nombreTipo"
                  className="mb-3 "
                  clearOnEscape={true}
                  disabled={isSubmitting || !touched.tipoResiduo}
                  loading={cargandoListaSubTipoResiduos}
                  name="subTipoResiduo"
                  openOnFocus={false}
                  options={listaSubTipoResiduos || []}
                  size="small"
                  variant="outlined"
                  noOptionsText={
                    cargandoListaSubTipoResiduos
                      ? 'Cargando...'
                      : 'Sin opciones'
                  }
                  value={values.subTipoResiduo || ''}
                  getOptionLabel={option => option.nombreTipo || ''}
                  onChange={(ev, selected) => {
                    if (selected) {
                      setFieldValue('subTipoResiduo', {
                        codigoTipoResiduo: selected.codigoTipoResiduo,
                        nombreTipo: selected.nombreTipo
                      });
                      setFieldValue('tipoTratamiento', []);
                      // handleListaTipoTratamiento(selected.codigoTipoResiduo)
                    } else {
                      setFieldValue('subTipoResiduo', []);
                    }

                    // Limpieza tipoRecurso
                    setFieldValue('tipoRecurso', []);
                    setFieldValue('otroTipoRecurso', []);
                    setFieldValue('_otraMateriaPrima', '');

                    if (selected && selected.recursos.length > 0) {
                      setFieldValue('tieneTipoRecursos', true);
                      setFieldTouched('tipoRecurso');
                    } else {
                      setFieldValue('tieneTipoRecursos', false);
                      setFieldTouched('_otraMateriaPrima');
                    }
                  }}
                >
                  <InputComponent
                    fullWidth
                    onBlur={() => setFieldTouched('subTipoResiduo')}
                    touched={touched.subTipoResiduo}
                    placeholder={
                      listaSubTipoResiduos.length
                        ? 'Seleccione subtipo de residuo'
                        : ''
                    }
                    estado={
                      errors.subTipoResiduo
                        ? {
                            tipo: 'error',
                            mensaje: errors.subTipoResiduo
                          }
                        : null
                    }
                    label={
                      <LabelComponent>
                        Subtipo de residuo
                        <br />
                        <span className="text-neutral-60">
                          (incluye subproductos)
                        </span>
                      </LabelComponent>
                    }
                  />
                </AutocompleteComponent>
              </div>
              {/* Tipo Tratamiento */}
              <div className="col-span-12 sm:col-span-6 xl:col-span-4">
                <AutocompleteComponent
                  fullWidth
                  accesor="nombreTipoTratamiento"
                  clearOnEscape={true}
                  disabled={isSubmitting || isLoadingTipoTratamiento}
                  name="tipoTratamiento"
                  touched={touched.tipoTratamiento}
                  onBlur={() => setFieldTouched('tipoTratamiento')}
                  value={values.tipoTratamiento || ''}
                  onChange={(event, newValue, reason) => {
                    let data = { name: 'tipoTratamiento', value: newValue };
                    if (reason === 'clear') {
                      data.value = '';
                    }
                    setFieldValue('tipoTratamiento', data.value);
                  }}
                  options={listaTipoTratamiento || []}
                  getOptionLabel={option => option.nombreTipoTratamiento || ''}
                  noOptionsText={
                    isLoadingTipoTratamiento ? 'Cargando...' : 'Sin resultados'
                  }
                >
                  <InputComponent
                    fullWidth
                    autoComplete="off"
                    disabled={isSubmitting || isLoadingTipoTratamiento}
                    touched={touched.tipoTratamiento}
                    onBlur={() => {
                      setFieldTouched('tipoTratamiento');
                    }}
                    placeholder={
                      isLoadingTipoTratamiento
                        ? 'Cargando...'
                        : listaTipoTratamiento.length === 0 &&
                          !isLoadingTipoTratamiento
                        ? ''
                        : 'Seleccione una opción'
                    }
                    estado={
                      errors.tipoTratamiento
                        ? { tipo: 'error', mensaje: errors.tipoTratamiento }
                        : null
                    }
                    label={
                      <LabelComponent>
                        Tipo de tratamiento
                        <br />
                        <span className="text-neutral-60">(opcional)</span>
                      </LabelComponent>
                    }
                  />
                </AutocompleteComponent>
              </div>

              <div className="col-span-12 sm:col-span-12 xl:mt-4"></div>
              {/* Proceso productivo */}
              <div className="col-span-12 sm:col-span-6 xl:col-span-4">
                <InputComponent
                  autoComplete="off"
                  diabled={isSubmitting}
                  name={'procesoProductivo'}
                  value={values.procesoProductivo || ''}
                  fullWidth
                  onChange={handleChange}
                  onBlur={() => {
                    setFieldTouched('procesoProductivo');
                  }}
                  touched={touched.procesoProductivo}
                  placeholder=""
                  estado={
                    errors.procesoProductivo
                      ? { tipo: 'error', mensaje: errors.procesoProductivo }
                      : null
                  }
                  label={
                    <LabelComponent className="w-full">
                      Proceso productivo relacionado <br />
                      <span className="text-neutral-60">(opcional)</span>
                      <TooltipComponent
                        className="float-right"
                        title={
                          'Incorpora la actividad o el proceso de tu sucursal que genera el residuo registrado.'
                        }
                      >
                        <Help
                          className="text-uv-secondary-0"
                          fontSize="small"
                        />
                      </TooltipComponent>
                    </LabelComponent>
                  }
                />
              </div>

              {/* Cantidad residuo */}
              <div className="col-span-12 sm:col-span-6 xl:col-span-4">
                <InputNumber
                  autoComplete="off"
                  disabled={isSubmitting}
                  suffix="%"
                  isAllowed={values => {
                    const { floatValue } = values;
                    if (!floatValue) return true;
                    return floatValue <= 10000000;
                  }}
                  label={
                    <LabelComponent>
                      % estimado a generar <br />
                      <span className="text-neutral-60">(opcional)</span>
                    </LabelComponent>
                  }
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  name={'cantidadResiduo'}
                  placeholder="% estimado a generar"
                  value={
                    values.cantidadResiduo_str || values.cantidadResiduo || ''
                  }
                  onChange={event => {
                    const { value } = event.target;
                    const sanitizedValue = value
                      .replaceAll('.', '')
                      .replaceAll(',', '.');
                    const floatValue = parseFloat(sanitizedValue);
                    if (!isNaN(floatValue)) {
                      setFieldValue('cantidadResiduo', floatValue);
                    }
                  }}
                  onFocus={event => {
                    const { value } = event.target;
                    setFieldTouched('cantidadResiduo');
                    if (
                      !event ||
                      !event.target ||
                      typeof event.target.value !== 'string'
                    ) {
                      return;
                    }
                    let sanitizedValue = value.split('.').join('') || '';
                    setFieldValue('cantidadResiduo_str', sanitizedValue);
                  }}
                  onBlur={() => {
                    setFieldTouched('cantidadResiduo');
                  }}
                  estado={
                    errors.cantidadResiduo
                      ? { tipo: 'error', mensaje: errors.cantidadResiduo }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneracionResiduo;
