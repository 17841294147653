import { useContext, useEffect, useState } from 'react';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import InputNumber from 'components/design-system/input/InputNumber';
import LabelComponent from 'components/design-system/Label/Label';
import {
  ObtieneListaTipoResiduos,
  ObtieneListaTipoTransporte,
  ObtieneListaTipoTratamiento
} from 'hooks';
import { DICTONARY } from 'const/Dictonary';
import { SaltoLinia } from '../Formulario/utils/utils';
import { DireccionOferente } from '../Formulario/Secciones/DireccionOferente';
import { DireccionDemandante } from '../Formulario/Secciones/DireccionDemandante';
import ButtonComponent from 'components/design-system/Button/Button';
import { MensajeCuota } from './Mensajes/MensajeCuota';
import { AuthContext } from 'context';

export const CamposCalculoIndicadores = ({
  errors,
  handleChange,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
  disponibilidad,
  setDisponibilidad
}) => {
  const {
    perfiles: { isSuperAdmin }
  } = useContext(AuthContext);
  const [listaSubTipoResiduos, setListaSubTipoResiduos] = useState([]);
  // Tiene Datos
  const [tieneTipoResiduos, setTieneTipoResiduos] = useState(true);
  // Cargando
  const [cargandoListaSubTipoResiduos, setCargandoListaSubTipoResiduos] =
    useState(true);
  // SERVICIO nombresTipoResiduos
  const {
    listaTipoResiduos,
    cargandoListaTipoResiduos,
    listaTipoResiduosNivelUno
  } = ObtieneListaTipoResiduos();

  //Calculo SUBTIPOS RESIDUOS
  const calculoSubTipoResiduos = (codigoTipoResiduo, setFieldValue) => {
    const subtipos = listaTipoResiduos.filter(
      t =>
        t.nivel === 2 && t.codigoNivelAnteriorTipoResiduo === codigoTipoResiduo
    );

    setTieneTipoResiduos(!!subtipos.length);
    setFieldValue('tieneSubTipoResiduo', !!subtipos.length);

    subtipos.length > 0 && setCargandoListaSubTipoResiduos(false);

    subtipos && setListaSubTipoResiduos(subtipos);
  };

  // Servicio tipo transporte
  const { listaTipoTransporte, isLoadingTipoTransporte } =
    ObtieneListaTipoTransporte();

  // Servicio tipo tratamiento
  const {
    listaTipoTratamiento,
    isLoadingTipoTratamiento,
    handleListaTipoTratamiento
  } = ObtieneListaTipoTratamiento();

  useEffect(() => {
    if (values.subTipoResiduo?.codigoTipoResiduo) {
      handleListaTipoTratamiento({
        codigoTipoResiduo: values.subTipoResiduo.codigoTipoResiduo
      });
    }
  }, [values.subTipoResiduo]);

  return (
    <div>
      <Heading type="h2" className="mb-4 text-lg md:text-2xl">
        <div className="flex items-center">
          <span>Calculadora Indicadores</span>
        </div>
      </Heading>

      <div className="bg-white rounded p-5 shadow-sm mb-5">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-12">
                <Heading type="h4" className="mb-0">
                  Formulario
                </Heading>
              </div>
              <div className="col-span-12 xs:col-span-12">
                {/* Fila 1 */}
                <div className="grid grid-cols-12 gap-4">
                  {/* Cantidad */}
                  <div className="col-span-12 md:col-span-6 xl:col-span-4">
                    {/* Cantidad */}
                    <InputNumber
                      autoComplete={'off'}
                      maxDecimals={DICTONARY.NUMERO_DECIMALES}
                      type="text"
                      label={
                        <LabelComponent>
                          Cantidad <br />
                          <span className="text-neutral-60">(Toneladas)</span>
                        </LabelComponent>
                      }
                      name="cantidad"
                      onChange={event => {
                        const { value } = event.target;
                        const sanitizedValue = value
                          .replaceAll('.', '')
                          .replaceAll(',', '.');
                        const floatValue = parseFloat(sanitizedValue);
                        if (!isNaN(floatValue)) {
                          setFieldValue('cantidad', floatValue);
                        } else {
                          setFieldValue('cantidad', null);
                        }
                      }}
                      onBlur={() => {
                        setFieldTouched('cantidad');
                      }}
                      touched={touched.cantidad}
                      value={values.cantidad_str || values.cantidad}
                      variant="outlined"
                      size="small"
                      placeholder={!values.cantidad ? 'Ingrese cantidad' : ''}
                      estado={
                        errors.cantidad
                          ? {
                              tipo: 'error',
                              mensaje: errors.cantidad
                            }
                          : null
                      }
                    />
                  </div>

                  {/* Residuo */}
                  <div className="col-span-12 md:col-span-6 xl:col-span-4">
                    <AutocompleteComponent
                      accesor="nombreTipo"
                      className="mb-3 "
                      clearOnEscape={true}
                      disabled={isSubmitting || cargandoListaTipoResiduos}
                      fullWidth
                      loading={cargandoListaTipoResiduos}
                      loadingText={cargandoListaTipoResiduos && 'Cargando...'}
                      name="tipoResiduo"
                      openOnFocus={true}
                      options={listaTipoResiduosNivelUno || []}
                      size="small"
                      value={values.tipoResiduo || ''}
                      variant="outlined"
                      getOptionLabel={option => option.nombreTipo || ''}
                      onChange={(ev, selected) => {
                        if (!selected) {
                          setCargandoListaSubTipoResiduos(true);
                          setTieneTipoResiduos(false);
                          setFieldValue('tieneSubTipoResiduo', false);
                        }

                        // Limpieza subTipoResiduo
                        setFieldValue('subTipoResiduo', []);
                        // Limpieza tipoRecurso
                        setFieldValue('tipoRecurso', []);

                        selected
                          ? setFieldValue('tipoResiduo', {
                              codigoTipoResiduo: selected.codigoTipoResiduo,
                              nombreTipo: selected.nombreTipo
                            })
                          : setFieldValue('tipoResiduo', []);

                        // Calculo Sub Tipos de Residuos
                        selected &&
                          calculoSubTipoResiduos(
                            selected.codigoTipoResiduo,
                            setFieldValue
                          );

                        selected && setFieldTouched('subTipoResiduo');
                      }}
                    >
                      <InputComponent
                        fullWidth
                        onBlur={() => setFieldTouched('tipoResiduo')}
                        touched={touched.tipoResiduo}
                        placeholder={
                          listaTipoResiduosNivelUno.length
                            ? 'Seleccione tipo de residuo'
                            : ''
                        }
                        estado={
                          errors.tipoResiduo
                            ? {
                                tipo: 'error',
                                mensaje: errors.tipoResiduo
                              }
                            : null
                        }
                        label={
                          <LabelComponent>
                            Residuo
                            <br />
                            <span className="text-neutral-60">
                              (incluye subproductos)
                            </span>
                          </LabelComponent>
                        }
                      />
                    </AutocompleteComponent>
                  </div>
                  {/* Subtipo de residuo */}
                  <div className="col-span-12 md:col-span-6 xl:col-span-4">
                    <AutocompleteComponent
                      accesor="nombreTipo"
                      className="mb-3 "
                      clearOnEscape={true}
                      disabled={isSubmitting || !touched.tipoResiduo}
                      loading={cargandoListaSubTipoResiduos}
                      name="subTipoResiduo"
                      openOnFocus={false}
                      options={listaSubTipoResiduos || []}
                      size="small"
                      variant="outlined"
                      noOptionsText={
                        cargandoListaSubTipoResiduos
                          ? 'Cargando...'
                          : 'Sin opciones'
                      }
                      value={values.subTipoResiduo || ''}
                      getOptionLabel={option => option.nombreTipo || ''}
                      onChange={(ev, selected) => {
                        selected
                          ? setFieldValue('subTipoResiduo', {
                              codigoTipoResiduo: selected.codigoTipoResiduo,
                              nombreTipo: selected.nombreTipo
                            })
                          : setFieldValue('subTipoResiduo', []);

                        // Limpieza tipoRecurso
                        setFieldValue('tipoRecurso', []);
                        setFieldValue('otroTipoRecurso', []);
                        setFieldValue('_otraMateriaPrima', '');

                        if (selected && selected.recursos.length > 0) {
                          setFieldValue('tieneTipoRecursos', true);
                          setFieldTouched('tipoRecurso');
                        } else {
                          setFieldValue('tieneTipoRecursos', false);
                          setFieldTouched('_otraMateriaPrima');
                        }
                        setFieldTouched('sucursal');
                      }}
                    >
                      <InputComponent
                        fullWidth
                        onBlur={() => setFieldTouched('subTipoResiduo')}
                        touched={touched.subTipoResiduo}
                        placeholder={
                          listaSubTipoResiduos.length
                            ? 'Seleccione subtipo de residuo'
                            : ''
                        }
                        estado={
                          errors.subTipoResiduo
                            ? {
                                tipo: 'error',
                                mensaje: errors.subTipoResiduo
                              }
                            : null
                        }
                        label={
                          <LabelComponent>
                            Subtipo de residuo
                            <br />
                            <span className="text-neutral-60">
                              (incluye subproductos)
                            </span>
                          </LabelComponent>
                        }
                      />
                    </AutocompleteComponent>
                  </div>
                </div>
                <SaltoLinia />
                {/* Fila 2 */}
                <div className="grid grid-cols-12 gap-4 mt-4">
                  <div className="col-span-12 md:col-span-12">
                    <Heading type="h4" className="mb-0">
                      Dirección Generador
                    </Heading>

                    <div className="grid grid-cols-12 gap-4">
                      <DireccionOferente
                        errors={errors}
                        handleChange={handleChange}
                        isSubmitting={isSubmitting}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                      />
                    </div>
                  </div>
                </div>
                <SaltoLinia />
                {/* Fila 3 */}
                <div className="grid grid-cols-12 gap-4 mt-4">
                  <div className="col-span-12 md:col-span-12">
                    <Heading type="h4" className="mb-0">
                      Dirección Receptor
                    </Heading>
                    <div className="grid grid-cols-12 gap-4">
                      <DireccionDemandante
                        errors={errors}
                        handleChange={handleChange}
                        isSubmitting={isSubmitting}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                      />
                    </div>
                  </div>
                </div>
                <SaltoLinia />
                {/* Fila 4 */}
                <div className="grid grid-cols-12 gap-4 mt-4">
                  <div className="col-span-12 md:col-span-12">
                    <Heading type="h4" className="mb-0">
                      Datos de empresa transportista
                    </Heading>
                  </div>
                  {/* numero de trabajadores HOMBRES */}
                  <div className="col-span-12 md:col-span-4 xl:col-span-4">
                    <InputNumber
                      autoComplete={'off'}
                      maxDecimals={DICTONARY.NUMERO_SIN_DECIMALES}
                      type="text"
                      label={
                        <LabelComponent esOpcional>
                          Nº trabajadores hombres
                        </LabelComponent>
                      }
                      name="nTrabajadoresHombresTransporte"
                      onChange={event => {
                        const { value } = event.target;
                        const sanitizedValue = value
                          .replaceAll('.', '')
                          .replaceAll(',', '.');
                        const floatValue = parseFloat(sanitizedValue);
                        if (!isNaN(floatValue)) {
                          setFieldValue(
                            'nTrabajadoresHombresTransporte',
                            floatValue
                          );
                        }
                        setFieldTouched('nTrabajadoresMujeresTransporte', true);
                      }}
                      onFocus={event => {
                        const { value } = event.target;
                        setFieldTouched('nTrabajadoresHombresTransporte');
                        if (
                          !event ||
                          !event.target ||
                          typeof event.target.value !== 'string'
                        ) {
                          return;
                        }
                        let sanitizedValue = value.split('.').join('') || '';
                        setFieldValue(
                          'nTrabajadoresHombresTransporte_str',
                          sanitizedValue
                        );
                      }}
                      onBlur={() => {
                        setFieldTouched('nTrabajadoresHombresTransporte');
                      }}
                      touched={touched.nTrabajadoresHombresTransporte}
                      value={
                        values.nTrabajadoresHombresTransporte_str ||
                        values.nTrabajadoresHombresTransporte
                      }
                      variant="outlined"
                      size="small"
                      placeholder={
                        !values.nTrabajadoresHombresTransporte
                          ? 'Ingrese número '
                          : ''
                      }
                      estado={
                        errors.nTrabajadoresHombresTransporte
                          ? {
                              tipo: 'error',
                              mensaje: errors.nTrabajadoresHombresTransporte
                            }
                          : null
                      }
                    />
                  </div>
                  {/* numero de trabajadores MUJERES*/}
                  <div className="col-span-12 md:col-span-4 xl:col-span-4">
                    <InputNumber
                      autoComplete={'off'}
                      maxDecimals={DICTONARY.NUMERO_SIN_DECIMALES}
                      type="text"
                      label={
                        <LabelComponent esOpcional>
                          Nº trabajadores mujeres
                        </LabelComponent>
                      }
                      name="nTrabajadoresMujeresTransporte"
                      onChange={event => {
                        const { value } = event.target;
                        const sanitizedValue = value
                          .replaceAll('.', '')
                          .replaceAll(',', '.');
                        const floatValue = parseFloat(sanitizedValue);
                        if (!isNaN(floatValue)) {
                          setFieldValue(
                            'nTrabajadoresMujeresTransporte',
                            floatValue
                          );
                        }
                        setFieldTouched('formatoEntrega', true);
                      }}
                      onFocus={event => {
                        const { value } = event.target;
                        setFieldTouched('nTrabajadoresMujeresTransporte');
                        if (
                          !event ||
                          !event.target ||
                          typeof event.target.value !== 'string'
                        ) {
                          return;
                        }
                        let sanitizedValue = value.split('.').join('') || '';
                        setFieldValue(
                          'nTrabajadoresMujeresTransporte_str',
                          sanitizedValue
                        );
                      }}
                      onBlur={() => {
                        setFieldTouched('cantidadToneladasTransportadas');
                      }}
                      touched={touched.nTrabajadoresMujeresTransporte}
                      value={
                        values.nTrabajadoresMujeresTransporte_str ||
                        values.nTrabajadoresMujeresTransporte
                      }
                      variant="outlined"
                      size="small"
                      placeholder={
                        !values.nTrabajadoresMujeresTransporte
                          ? 'Ingrese número '
                          : ''
                      }
                      estado={
                        errors.nTrabajadoresMujeresTransporte
                          ? {
                              tipo: 'error',
                              mensaje: errors.nTrabajadoresMujeresTransporte
                            }
                          : null
                      }
                    />
                  </div>
                  {/* numero de trabajadores MUJERES*/}
                  <div className="col-span-12 md:col-span-4 xl:col-span-4">
                    <InputNumber
                      autoComplete={'off'}
                      maxDecimals={DICTONARY.NUMERO_DECIMALES}
                      type="text"
                      label={
                        <LabelComponent esOpcional>
                          Toneladas transportadas
                        </LabelComponent>
                      }
                      name="cantidadToneladasTransportadas"
                      onChange={event => {
                        const { value } = event.target;
                        const sanitizedValue = value
                          .replaceAll('.', '')
                          .replaceAll(',', '.');
                        const floatValue = parseFloat(sanitizedValue);
                        if (!isNaN(floatValue)) {
                          setFieldValue(
                            'cantidadToneladasTransportadas',
                            floatValue
                          );
                        }
                        setFieldTouched('totalRemuneracionesPagadas', true);
                      }}
                      onFocus={event => {
                        const { value } = event.target;
                        setFieldTouched('cantidadToneladasTransportadas');
                        if (
                          !event ||
                          !event.target ||
                          typeof event.target.value !== 'string'
                        ) {
                          return;
                        }
                        let sanitizedValue = value.split('.').join('') || '';
                        setFieldValue(
                          'cantidadToneladasTransportadas_str',
                          sanitizedValue
                        );
                      }}
                      onBlur={() => {
                        setFieldTouched('totalRemuneracionesPagadas');
                      }}
                      touched={touched.cantidadToneladasTransportadas}
                      value={
                        values.cantidadToneladasTransportadas_str ||
                        values.cantidadToneladasTransportadas
                      }
                      variant="outlined"
                      size="small"
                      placeholder={
                        !values.cantidadToneladasTransportadas
                          ? 'Ingrese cantidad'
                          : ''
                      }
                      estado={
                        errors.cantidadToneladasTransportadas
                          ? {
                              tipo: 'error',
                              mensaje: errors.cantidadToneladasTransportadas
                            }
                          : null
                      }
                    />
                  </div>
                  {/* Total remuneraciones pagadas*/}
                  <div className="col-span-12 md:col-span-4 xl:col-span-4">
                    <InputNumber
                      autoComplete={'off'}
                      maxDecimals={DICTONARY.NUMERO_SIN_DECIMALES}
                      type="text"
                      label={
                        <LabelComponent esOpcional>
                          Total remuneraciones pagadas
                        </LabelComponent>
                      }
                      name="totalRemuneracionesPagadas"
                      onChange={event => {
                        const { value } = event.target;
                        const sanitizedValue = value
                          .replaceAll('.', '')
                          .replaceAll(',', '.');
                        const floatValue = parseFloat(sanitizedValue);
                        if (!isNaN(floatValue)) {
                          setFieldValue(
                            'totalRemuneracionesPagadas',
                            floatValue
                          );
                        }
                        setFieldTouched('totalRemuneracionesPagadas', true);
                      }}
                      onFocus={event => {
                        const { value } = event.target;
                        setFieldTouched('totalRemuneracionesPagadas');
                        if (
                          !event ||
                          !event.target ||
                          typeof event.target.value !== 'string'
                        ) {
                          return;
                        }
                        let sanitizedValue = value.split('.').join('') || '';
                        setFieldValue(
                          'totalRemuneracionesPagadas_str',
                          sanitizedValue
                        );
                      }}
                      onBlur={() => {
                        setFieldTouched('totalRemuneracionesPagadas');
                      }}
                      touched={touched.totalRemuneracionesPagadas}
                      value={
                        values.totalRemuneracionesPagadas_str ||
                        values.totalRemuneracionesPagadas
                      }
                      variant="outlined"
                      size="small"
                      placeholder={
                        !values.totalRemuneracionesPagadas
                          ? 'Ingrese número '
                          : ''
                      }
                      estado={
                        errors.totalRemuneracionesPagadas
                          ? {
                              tipo: 'error',
                              mensaje: errors.totalRemuneracionesPagadas
                            }
                          : null
                      }
                    />
                  </div>
                  {/* Tipo Transporte */}
                  <div className="col-span-12 md:col-span-6 xl:col-span-8">
                    <AutocompleteComponent
                      accesor="nombreTipoTransporte"
                      clearOnEscape={true}
                      disabled={isSubmitting || isLoadingTipoTransporte}
                      fullWidth
                      name={'tipoTransporte'}
                      options={listaTipoTransporte}
                      value={values.tipoTransporte || ''}
                      onChange={(event, newValue, reason) => {
                        let data = { name: 'tipoTransporte', value: newValue };
                        if (reason === 'clear') {
                          data.value = '';
                        }
                        setFieldValue('tipoTransporte', data.value);
                      }}
                      getOptionLabel={option =>
                        option.nombreTipoTransporte || ''
                      }
                      noOptionsText={
                        isLoadingTipoTransporte
                          ? 'Cargando...'
                          : 'Sin resultados'
                      }
                    >
                      <InputComponent
                        autoComplete="off"
                        disabled={isSubmitting || isLoadingTipoTransporte}
                        fullWidth
                        touched={touched.tipoTransporte}
                        onBlur={() => {
                          setFieldTouched('tipoTransporte');
                        }}
                        placeholder={
                          isLoadingTipoTransporte
                            ? 'Cargando...'
                            : listaTipoTransporte.length === 0 &&
                              !isLoadingTipoTransporte
                            ? ''
                            : 'Seleccione una opción'
                        }
                        estado={
                          errors.tipoTransporte
                            ? { tipo: 'error', mensaje: errors.tipoTransporte }
                            : null
                        }
                        label={
                          <LabelComponent className="w-full">
                            Tipo de transporte
                          </LabelComponent>
                        }
                      />
                    </AutocompleteComponent>
                  </div>
                </div>
                <SaltoLinia />
                {/* Fila 5 */}
                <div className="grid grid-cols-12 gap-4 mt-4">
                  <div className="col-span-12 md:col-span-12">
                    <Heading type="h4" className="mb-0">
                      Datos de empresa que realizo el tratamiento
                    </Heading>
                  </div>
                  {/* numero de trabajadores HOMBRES */}
                  <div className="col-span-12 md:col-span-4 xl:col-span-4">
                    <InputNumber
                      autoComplete={'off'}
                      maxDecimals={DICTONARY.NUMERO_SIN_DECIMALES}
                      type="text"
                      label={
                        <LabelComponent esOpcional>
                          Nº trabajadores hombres
                        </LabelComponent>
                      }
                      name="nTrabajadoresHombresTratamiento"
                      onChange={event => {
                        const { value } = event.target;
                        const sanitizedValue = value
                          .replaceAll('.', '')
                          .replaceAll(',', '.');
                        const floatValue = parseFloat(sanitizedValue);
                        if (!isNaN(floatValue)) {
                          setFieldValue(
                            'nTrabajadoresHombresTratamiento',
                            floatValue
                          );
                        } else {
                          setFieldValue(
                            'nTrabajadoresHombresTratamiento',
                            null
                          );
                        }
                      }}
                      onBlur={() => {
                        setFieldTouched('nTrabajadoresHombresTratamiento');
                      }}
                      touched={touched.nTrabajadoresHombresTratamiento}
                      value={
                        values.nTrabajadoresHombresTratamiento_str ||
                        values.nTrabajadoresHombresTratamiento
                      }
                      variant="outlined"
                      size="small"
                      placeholder={
                        !values.nTrabajadoresHombresTratamiento
                          ? 'Ingrese número '
                          : ''
                      }
                      estado={
                        errors.nTrabajadoresHombresTratamiento
                          ? {
                              tipo: 'error',
                              mensaje: errors.nTrabajadoresHombresTratamiento
                            }
                          : null
                      }
                    />
                  </div>
                  {/* numero de trabajadores MUJERES*/}
                  <div className="col-span-12 md:col-span-4 xl:col-span-4">
                    <InputNumber
                      autoComplete={'off'}
                      maxDecimals={DICTONARY.NUMERO_SIN_DECIMALES}
                      type="text"
                      label={
                        <LabelComponent esOpcional>
                          Nº trabajadores mujeres
                        </LabelComponent>
                      }
                      name="nTrabajadoresMujeresTratamiento"
                      onChange={event => {
                        const { value } = event.target;
                        const sanitizedValue = value
                          .replaceAll('.', '')
                          .replaceAll(',', '.');
                        const floatValue = parseFloat(sanitizedValue);
                        if (!isNaN(floatValue)) {
                          setFieldValue(
                            'nTrabajadoresMujeresTratamiento',
                            floatValue
                          );
                        } else {
                          setFieldValue(
                            'nTrabajadoresMujeresTratamiento',
                            null
                          );
                        }
                      }}
                      onBlur={() => {
                        setFieldTouched('nTrabajadoresMujeresTratamiento');
                      }}
                      touched={touched.nTrabajadoresMujeresTratamiento}
                      value={
                        values.nTrabajadoresMujeresTratamiento_str ||
                        values.nTrabajadoresMujeresTratamiento
                      }
                      variant="outlined"
                      size="small"
                      placeholder={
                        !values.nTrabajadoresMujeresTratamiento
                          ? 'Ingrese número '
                          : ''
                      }
                      estado={
                        errors.nTrabajadoresMujeresTratamiento
                          ? {
                              tipo: 'error',
                              mensaje: errors.nTrabajadoresMujeresTratamiento
                            }
                          : null
                      }
                    />
                  </div>
                  {/* numero de trabajadores MUJERES*/}
                  <div className="col-span-12 md:col-span-4 xl:col-span-4">
                    <InputNumber
                      autoComplete={'off'}
                      maxDecimals={DICTONARY.NUMERO_DECIMALES}
                      type="text"
                      label={
                        <LabelComponent esOpcional>
                          Toneladas gestionadas
                        </LabelComponent>
                      }
                      name="cantidadToneladasTratamiento"
                      onChange={event => {
                        const { value } = event.target;
                        const sanitizedValue = value
                          .replaceAll('.', '')
                          .replaceAll(',', '.');
                        const floatValue = parseFloat(sanitizedValue);
                        if (!isNaN(floatValue)) {
                          setFieldValue(
                            'cantidadToneladasTratamiento',
                            floatValue
                          );
                        } else {
                          setFieldValue('cantidadToneladasTratamiento', null);
                        }
                      }}
                      onBlur={() => {
                        setFieldTouched('cantidadToneladasTratamiento');
                      }}
                      touched={touched.cantidadToneladasTratamiento}
                      value={
                        values.cantidadToneladasTratamiento_str ||
                        values.cantidadToneladasTratamiento
                      }
                      variant="outlined"
                      size="small"
                      placeholder={
                        !values.cantidadToneladasTratamiento
                          ? 'Ingrese cantidad'
                          : ''
                      }
                      estado={
                        errors.cantidadToneladasTratamiento
                          ? {
                              tipo: 'error',
                              mensaje: errors.cantidadToneladasTratamiento
                            }
                          : null
                      }
                    />
                  </div>
                  {/* Total remuneraciones pagadas*/}
                  <div className="col-span-12 md:col-span-4 xl:col-span-4">
                    <InputNumber
                      autoComplete={'off'}
                      maxDecimals={DICTONARY.NUMERO_SIN_DECIMALES}
                      type="text"
                      label={
                        <LabelComponent esOpcional>
                          Total remuneraciones pagadas
                        </LabelComponent>
                      }
                      name="totalRemuneracionesPagadasTratamiento"
                      onChange={event => {
                        const { value } = event.target;
                        const sanitizedValue = value
                          .replaceAll('.', '')
                          .replaceAll(',', '.');
                        const floatValue = parseFloat(sanitizedValue);
                        if (!isNaN(floatValue)) {
                          setFieldValue(
                            'totalRemuneracionesPagadasTratamiento',
                            floatValue
                          );
                        } else {
                          setFieldValue(
                            'totalRemuneracionesPagadasTratamiento',
                            null
                          );
                        }
                      }}
                      onBlur={() => {
                        setFieldTouched(
                          'totalRemuneracionesPagadasTratamiento'
                        );
                      }}
                      touched={touched.totalRemuneracionesPagadasTratamiento}
                      value={
                        values.totalRemuneracionesPagadasTratamiento_str ||
                        values.totalRemuneracionesPagadasTratamiento
                      }
                      variant="outlined"
                      size="small"
                      placeholder={
                        !values.totalRemuneracionesPagadasTratamiento
                          ? 'Ingrese número '
                          : ''
                      }
                      estado={
                        errors.totalRemuneracionesPagadasTratamiento
                          ? {
                              tipo: 'error',
                              mensaje:
                                errors.totalRemuneracionesPagadasTratamiento
                            }
                          : null
                      }
                    />
                  </div>
                  {/* Tipo Tratamiento */}
                  <div className="col-span-12 md:col-span-6 xl:col-span-8">
                    <AutocompleteComponent
                      fullWidth
                      accesor="nombreTipoTratamiento"
                      clearOnEscape={true}
                      disabled={isSubmitting || isLoadingTipoTratamiento}
                      name="tipoTratamiento"
                      touched={touched.tipoTratamiento}
                      onBlur={() => setFieldTouched('tipoTratamiento')}
                      value={values.tipoTratamiento || ''}
                      onChange={(event, newValue, reason) => {
                        let data = { name: 'tipoTratamiento', value: newValue };
                        if (reason === 'clear') {
                          data.value = '';
                        }
                        setFieldValue('tipoTratamiento', data.value);
                      }}
                      options={listaTipoTratamiento || []}
                      getOptionLabel={option =>
                        option.nombreTipoTratamiento || ''
                      }
                      noOptionsText={
                        isLoadingTipoTratamiento
                          ? 'Cargando...'
                          : 'Sin resultados'
                      }
                    >
                      <InputComponent
                        fullWidth
                        autoComplete="off"
                        disabled={isSubmitting || isLoadingTipoTratamiento}
                        touched={touched.tipoTratamiento}
                        onBlur={() => {
                          setFieldTouched('tipoTratamiento');
                        }}
                        placeholder={
                          isLoadingTipoTratamiento
                            ? 'Cargando...'
                            : listaTipoTratamiento.length === 0 &&
                              !isLoadingTipoTratamiento
                            ? ''
                            : 'Seleccione una opción'
                        }
                        estado={
                          errors.tipoTratamiento
                            ? { tipo: 'error', mensaje: errors.tipoTratamiento }
                            : null
                        }
                        label={
                          <LabelComponent>Tipo de tratamiento</LabelComponent>
                        }
                      />
                    </AutocompleteComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 justify-between">
          <SaltoLinia />
          <div className="flex justify-end">
            <MensajeCuota
              disableButton={isSubmitting || Object.keys(errors).length > 0}
              disponibilidad={disponibilidad}
            >
              <ButtonComponent
                form="formResiduoDeclarado"
                buttonType="submit"
                disabled={isSubmitting || Object.keys(errors).length > 0}
              >
                {isSuperAdmin ? 'Calcular indicadores' : 'Ok, continuar'}
              </ButtonComponent>
            </MensajeCuota>
          </div>
        </div>
      </div>
    </div>
  );
};
