import { Box, CircularProgress } from '@mui/material';
import Text from 'components/design-system/Text/Text';
import Tab from 'components/design-system/Tab/Tab';
import Tabs from 'components/design-system/Tabs/Tabs';
import { MatchContext } from '../../context/MatchContext';
import { useContext } from 'react';
import { TabBusquedaAmpliada } from './Tabs/TabBusquedaAmpliada';
import { TabEmpresas } from './Tabs/TabEmpresas';
import { DICTONARY } from 'const/Dictonary';
import { TabResiduos } from './Tabs/TabResiduos';
import { Ordenamiento } from './Ordenamiento/Ordenamiento';
import { ResponsiveDisplay } from 'components/design-system/ResponsiveDisplay/ResponsiveDisplay';
import { MatchResiduoDetalle } from '../Mapa/MapaPopup/MatchResiduoDetalle';
import { EmpresaDetalle } from '../Mapa/MapaPopup/EmpresaDetalle';
import { EmpresaATDetalle } from '../Mapa/MapaPopup/EmpresaATDetalle';

/**
 * @name BarraLateral
 * Componente utiliza  do para desplegar los resultados de match para usuarios con negocio en barra lateral izquierda.
 */
export const BarraLateral = () => {
  const {
    isCargandoMatchResiduos,
    isCargandoMatchEmpresas,
    isCargandoMatchAmpliada,
    criteriosMatch,
    matchFiltrado,
    matchEmpresasFiltrado,
    matchAmpliadaFiltrado,
    setTabActiva,
    tabActiva,
    tieneNegocio,
    residuoSeleccionado,
    sucursalATSeleccionada,
    sucursalSeleccionada,
    DISABLE_TAB_RESIDUOS,
    DISABLE_TAB_EMPRESAS
  } = useContext(MatchContext);

  const handleChange = (event, newValue) => {
    setTabActiva(newValue);
  };

  return (
    <div className="bg-white shadow-md relative w-full">
      <div className="flex flex-col h-[80vh]">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabActiva}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              hidden={DISABLE_TAB_RESIDUOS}
              value={DICTONARY.MATCH.TABS.RESIDUOS}
              label={
                <div className="text-left pt-2 px-1">
                  <Text
                    type="span"
                    className="text-uv-primary-0 mb-0 font-semibold"
                  >
                    RESIDUOS-
                    <br />
                    RECURSOS
                    <br />
                    <span className="font-normal mt-1 text-xs text-[11px] truncate">
                      {isCargandoMatchResiduos ? (
                        <>
                          <CircularProgress
                            className="mr-1 !w-3.5 !h-3.5"
                            color="inherit"
                          />{' '}
                          cargando
                        </>
                      ) : (
                        `${matchFiltrado.numeroResiduos} resultados`
                      )}
                    </span>
                  </Text>
                </div>
              }
            />
            <Tab
              hidden={DISABLE_TAB_EMPRESAS}
              value={DICTONARY.MATCH.TABS.EMPRESAS}
              label={
                <div className="text-left pt-2 px-1">
                  <Text
                    type="span"
                    className="text-uv-primary-0 mb-0 font-semibold"
                  >
                    <br />
                    NEGOCIOS
                    <br />
                    <span className="font-normal mt-1 text-xs text-[11px] truncate">
                      {isCargandoMatchEmpresas ? (
                        <>
                          <CircularProgress
                            className="mr-1 !w-3.5 !h-3.5"
                            color="inherit"
                          />{' '}
                          cargando
                        </>
                      ) : (
                        `${matchEmpresasFiltrado.numeroSucursales} resultados`
                      )}
                    </span>
                  </Text>
                </div>
              }
            />

            {criteriosMatch.isBusquedaAmpliada && (
              <Tab
                value={DICTONARY.MATCH.TABS.AMPLIADA}
                label={
                  <div className="text-left pt-2 px-1">
                    <Text
                      type="span"
                      className="text-uv-primary-0 mb-0 font-semibold"
                    >
                      BUSQUEDA
                      <br />
                      EXTENDIDA
                      <br />
                      <span className="font-normal mt-1 text-xs text-[11px] truncate">
                        {isCargandoMatchAmpliada ? (
                          <>
                            <CircularProgress
                              className="mr-1 !w-3.5 !h-3.5"
                              color="inherit"
                            />{' '}
                            cargando
                          </>
                        ) : (
                          `${matchAmpliadaFiltrado.numeroSucursales} resultados`
                        )}
                      </span>
                    </Text>
                  </div>
                }
              />
            )}
          </Tabs>
        </Box>

        {tieneNegocio && <Ordenamiento />}

        {!DISABLE_TAB_RESIDUOS && (
          <CustomTabPanel
            value={tabActiva}
            index={DICTONARY.MATCH.TABS.RESIDUOS}
          >
            <ResponsiveDisplay
              breakPoint={DICTONARY.RESPONSIVE_BREAKPOINT.MD}
              variableLeer={residuoSeleccionado}
              componentePrimario={<TabResiduos />}
              componenteSecundario={<MatchResiduoDetalle />}
            />
          </CustomTabPanel>
        )}

        {!DISABLE_TAB_EMPRESAS && (
          <CustomTabPanel
            value={tabActiva}
            index={DICTONARY.MATCH.TABS.EMPRESAS}
          >
            <ResponsiveDisplay
              breakPoint={DICTONARY.RESPONSIVE_BREAKPOINT.MD}
              variableLeer={sucursalSeleccionada}
              componentePrimario={<TabEmpresas />}
              componenteSecundario={<EmpresaDetalle />}
            />
          </CustomTabPanel>
        )}

        {criteriosMatch.isBusquedaAmpliada && (
          <CustomTabPanel
            value={tabActiva}
            index={DICTONARY.MATCH.TABS.AMPLIADA}
          >
            <ResponsiveDisplay
              breakPoint={DICTONARY.RESPONSIVE_BREAKPOINT.MD}
              variableLeer={sucursalATSeleccionada}
              componentePrimario={<TabBusquedaAmpliada />}
              componenteSecundario={<EmpresaATDetalle />}
            />
          </CustomTabPanel>
        )}
      </div>
    </div>
  );
};

const CustomTabPanel = props => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="py-2 h-auto overflow-y-scroll scrollbar-uv"
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};
