import { BotonBase } from "./BotonBase";
import home1Opciones from "assets/images/home/home1Opciones.svg";

export const Layout1Opciones = ({opciones}) => {
  const [primeraOpcion]  = opciones;

  return(
    <>
      <div>
        <BotonBase
          onClick={primeraOpcion.onClick}
          texto={primeraOpcion.texto}
          icono={primeraOpcion.icono}
        />
      </div>

      <div>
        <img src={home1Opciones} alt="Sucursal" />
      </div>
    </>
  )
};