import { DICTONARY } from 'const/Dictonary';
import serviceEmpresas from 'services/empresas';
import { AlertaError, AlertaExito, AlertaWarning } from 'utils/Alertas';

export const verificaEmpresaRegistrada = async rutEmpresa => {
  try {
    const params = { rutEmpresa: rutEmpresa };
    const response = await serviceEmpresas.empresaRegistrada(
      params,
      DICTONARY.INTERCEPTOR.ACTIVADO
    );

    if (response.status === 200) {
      const data = response.data;
      if (data) {
        AlertaWarning('El rut ingresado no está disponible');
        return true;
      } else {
        AlertaExito('El rut ingresado está disponible');
        return false;
      }
    }
  } catch (error) {
    AlertaError(error);
    return false;
  }

  return false;
};

export const verificaEmpresaRegistradaNombre = async nombre => {
  try {
    const params = { nombreEmpresa: nombre };
    const response = await serviceEmpresas.empresaRegistrada(
      params,
      DICTONARY.INTERCEPTOR.DESACTIVADO
    );

    if (response.status === 200) {
      const data = response.data;
      if (data) {
        AlertaWarning('El nombre ingresado no está disponible');
        return true;
      } else {
        AlertaExito('El nombre ingresado está disponible');
        return false;
      }
    }
  } catch (error) {
    console.log(error);
    AlertaError(error);
  }

  return false;
};
