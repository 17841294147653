import React, { useEffect, useState, useMemo } from 'react';
// Desdin System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import ButtonComponent from 'components/design-system/Button/Button';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';

import { GpsOff, MyLocation } from '@mui/icons-material';

import MapAddress from 'components/MapAddress/MapAddress';
import {
  CargaActividades,
  obtenerEmpresasAT
} from 'views/CargaMasiva/hooks/useServices';
import { handleKeyPress } from '../utils/handleKeyPress';
import { handleKeyPressNumeros } from '../utils/handleKeyPressNumeros';
import TransferListComponent from 'components/design-system/TransferList/TransferList';
import AlertComponent from 'components/design-system/Alert/Alert';
// Hooks
import { useDireccion } from 'hooks/useDireccion';
import { DICTONARY } from 'const/Dictonary';

const FormularioEditarEmpresasAT = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  isSubmitting
}) => {
  // Actividades
  const [actividades, setActividades] = useState([]);
  const [isLoadingActividades, setIsLoadingActividades] = useState(true);

  useEffect(() => {
    CargaActividades({ setIsLoadingActividades, setActividades });
  }, []);

  const {
    latLon,
    setLatLon,
    addressQuerysearch,
    addressQueryParams,
    regiones,
    cargaListaRegiones,
    handleObtenerDireccion,
    handleObtenerComunas,
    listadoComunas,
    cargaListaComunas,
    setBuscandoDireccion,
    buscandoDireccion
  } = useDireccion();

  //  Servicio Comunas
  useMemo(() => {
    if (!values.region) return;
    if (values.region && values.region.codigoRegion) {
      handleObtenerComunas(values.region.codigoRegion);
    }
  }, [handleObtenerComunas, values.region]);

  useMemo(() => {
    if (isSubmitting) return;
    handleObtenerDireccion(values);
  }, [isSubmitting, handleObtenerDireccion, values]);

  useMemo(() => {
    const { nombreVia, comuna, region, isPointEditing } = values;

    if (isSubmitting || !latLon) return;

    const hasNombreVia = nombreVia && touched.nombreVia;
    const hasComuna = comuna && touched.comuna;
    const hasRegion = region && touched.region;

    if (hasNombreVia || hasComuna || hasRegion || isPointEditing) {
      setBuscandoDireccion(true);
      setFieldValue('latitudDireccion', latLon[0]);
      setFieldValue('longitudDireccion', latLon[1]);
    }
  }, [
    isSubmitting,
    latLon,
    setBuscandoDireccion,
    setFieldValue,
    touched.comuna,
    touched.nombreVia,
    touched.region,
    values
  ]);

  //Sinergias
  const [empresasAT, setEmpresasAt] = useState([]);
  const [isLoadingEmpresasAt, setIsLoadingEmpresasAt] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [paginationEmpresasAt, setPaginationEmpresasAt] = useState({
    pageIndex: 0,
    pageSize: 50
  });

  useEffect(() => {
    obtenerEmpresasAT({
      setIsLoading: setIsLoadingEmpresasAt,
      pagination: paginationEmpresasAt,
      setEmpresasAt: setEmpresasAt,
      setRowCount: setRowsPerPage
    });
  }, [paginationEmpresasAt]);

  useEffect(() => {
    if (!empresasAT) return;
  }, [empresasAT]);

  return (
    <>
      {/* RUT  */}
      <div className="col-span-3  xxs:col-span-12 xs:col-span-12  lg:col-span-3">
        <InputComponent
          autoComplete={'off'}
          fullWidth
          label={<LabelComponent>Rut empresa</LabelComponent>}
          name="rutEmpresa"
          onChange={e => {
            handleChange(e);
          }}
          onBlur={e => {
            handleBlur(e);
          }}
          value={values.rutEmpresa}
          outerInputProps={{
            inputProps: {
              maxLength: 11,
              onKeyPress: handleKeyPress
            }
          }}
          touched={touched.rutEmpresa}
          estado={
            errors.rutEmpresa
              ? { tipo: 'error', mensaje: errors.rutEmpresa }
              : null
          }
        />
      </div>
      {/* Razon Social */}
      <div className="col-span-9 xxs:col-span-12  xs:col-span-12   lg:col-span-9">
        <InputComponent
          autoComplete={'off'}
          // disabled={
          //   errors.rutEmpresa || !values.rutEmpresa ? true : false
          // }
          fullWidth
          label={<LabelComponent>Razón social</LabelComponent>}
          name="razonSocial"
          onBlur={e => {
            handleBlur(e);
          }}
          onChange={ev => {
            ev && handleChange(ev) && setFieldTouched('rubro');
            !ev && setFieldValue('razonSocial', '');
          }}
          value={values.razonSocial ? values.razonSocial : ''}
          touched={touched.razonSocial}
          estado={
            errors.razonSocial
              ? { tipo: 'error', mensaje: errors.razonSocial }
              : null
          }
        />
      </div>
      {/* Tramo */}
      <div className="col-span-3 xxs:col-span-12  xs:col-span-12   lg:col-span-3">
        <InputComponent
          autoComplete={'off'}
          // disabled={
          //   errors.rutEmpresa || !values.rutEmpresa ? true : false
          // }
          fullWidth
          label={<LabelComponent>Tramo</LabelComponent>}
          name="tramo"
          onBlur={e => {
            handleBlur(e);
          }}
          onChange={ev => {
            ev && handleChange(ev) && setFieldTouched('tramo');
            !ev && setFieldValue('tramo', '');
          }}
          value={values.tramo ? values.tramo : ''}
          outerInputProps={{
            inputProps: {
              maxLength: 100,
              onKeyPress: handleKeyPressNumeros
            }
          }}
          touched={touched.tramo}
          estado={
            errors.tramo ? { tipo: 'error', mensaje: errors.tramo } : null
          }
        />
      </div>
      {/* Actividad */}
      <div className="col-span-9 xxs:col-span-12  xs:col-span-12   lg:col-span-9">
        <AutocompleteComponent
          name="actividad"
          accesor={'label'}
          clearOnEscape={true}
          openOnFocus={false}
          options={actividades || []}
          isLoading={isLoadingActividades}
          // disabled={isLoadingActividades}
          onBlur={() => setFieldTouched('actividad')}
          onChange={(ev, selected, reason) => {
            let data = { name: 'actividad', value: selected };
            if (reason === 'clear') {
              data.value = '';
            }
            setFieldValue('actividad', data.value);
          }}
          value={values.actividad || ''}
          getOptionLabel={option =>
            option.label || values.actividad?.nombreActividad || ''
          }
          touched={touched.actividad}
        >
          <InputComponent
            autoComplete={'off'}
            // disabled={errors.rutEmpresa}
            fullWidth
            label={<LabelComponent>Actividad</LabelComponent>}
            touched={touched.actividad}
            estado={
              errors.actividad
                ? { tipo: 'error', mensaje: errors.actividad }
                : null
            }
          />
        </AutocompleteComponent>
      </div>

      {/* Región */}
      <div className="col-span-3  xxs:col-span-12  xs:col-span-12  lg:col-span-3">
        <AutocompleteComponent
          name="region"
          clearOnEscape={true}
          accesor="nombreRegion"
          openOnFocus={true}
          options={regiones}
          disabled={!cargaListaRegiones}
          onChange={(ev, region, reason) => {
            let data = { name: 'region', value: region };
            if (reason === 'clear') {
              data.value = '';
            }
            setFieldValue('comuna', null);
            setFieldValue('region', data.value);
            // Tocar los 3 campos para realizar la busqueda
            setFieldTouched('region');
            setFieldTouched('comuna');
            setFieldTouched('nombreVia');
          }}
          onBlur={() => setFieldTouched('region')}
          value={values.region}
          getOptionLabel={option => option.nombreRegion || ''}
          isOptionEqualToValue={(option, value) =>
            option.nombreRegion === values.region.nombreRegion
          }
        >
          <InputComponent
            autoComplete={'off'}
            fullWidth
            touched={touched.region ? true : false}
            estado={
              errors.region ? { tipo: 'error', mensaje: errors.region } : null
            }
            label={<LabelComponent>Región</LabelComponent>}
          />
        </AutocompleteComponent>
      </div>

      {/* Comuna */}
      <div className="col-span-3  xxs:col-span-12  xs:col-span-12  lg:col-span-3">
        <AutocompleteComponent
          name="comuna"
          accesor="nombreComuna"
          options={listadoComunas}
          disabled={!cargaListaComunas}
          onChange={(ev, comuna, reason) => {
            let data = { name: 'comuna', value: comuna };

            if (reason === 'clear') {
              data.value = '';
            }

            setFieldValue('comuna', data.value);

            // Tocar los 3 campos para realizar la busqueda
            setFieldTouched('region');
            setFieldTouched('comuna');
            setFieldTouched('nombreVia');
          }}
          value={values.comuna}
          getOptionLabel={option => option.nombreComuna || ''}
          isOptionEqualToValue={(option, value) =>
            option.nombreComuna === values.comuna.nombreComuna
          }
          onBlur={() => setFieldTouched('comuna')}
        >
          <InputComponent
            autoComplete={'off'}
            fullWidth
            touched={touched.comuna ? true : false}
            estado={
              errors.comuna ? { tipo: 'error', mensaje: errors.comuna } : null
            }
            label={<LabelComponent>Comuna</LabelComponent>}
          />
        </AutocompleteComponent>
      </div>

      {/* NombreVia */}
      <div className="col-span-6 xxs:col-span-12  xs:col-span-12  lg:col-span-6">
        <InputComponent
          autoComplete={'off'}
          fullWidth
          label={<LabelComponent>Nombre y número calle</LabelComponent>}
          name="nombreVia"
          onChange={e => {
            handleChange(e);
            // Tocar los 3 campos para realizar la busqueda
            setFieldTouched('region');
            setFieldTouched('comuna');
            setFieldTouched('nombreVia');
          }}
          onBlur={e => {
            handleBlur(e);
          }}
          value={values.nombreVia}
          placeholder="Ej: Providencia, 1091"
          touched={touched.nombreVia}
          estado={
            errors.nombreVia
              ? { tipo: 'error', mensaje: errors.nombreVia }
              : null
          }
        />
      </div>
      {/* Mapa */}
      <div className="col-span-12  xxs:col-span-12 xs:col-span-12 ">
        <div
          className={`${
            values.isPointEditing ? ' bg-warning-light' : 'bg-info-light '
          }
                           sm:flex px-4 py-2.5 justify-between rounded items-center`}
        >
          <Text>
            {values.isPointEditing
              ? DICTONARY.MAPA_MENSAJES.INFO_DESACTIVAR_EDICION
              : DICTONARY.MAPA_MENSAJES.INFO_ACTIVAR_EDICION}
          </Text>
          <ButtonComponent
            className="min-w-[190px] md:min-w-fit mt-4 sm:mt-0"
            onClick={() => {
              setFieldValue('isPointEditing', !values.isPointEditing);
            }}
          >
            {values.isPointEditing && latLon ? (
              <>
                Desactivar edición <GpsOff className="ml-2" />
              </>
            ) : (
              <>
                Edición manual <MyLocation className="ml-2" />
              </>
            )}
          </ButtonComponent>
        </div>
      </div>
      <div className="col-span-12 rounded overflow-hidden">
        <MapAddress
          addressQuerysearch={addressQuerysearch}
          addressQueryParams={addressQueryParams}
          latLon={latLon}
          setLatLon={setLatLon}
          isPointEditing={values.isPointEditing}
          setIsPointEditing={value => setFieldValue('isPointEditing', value)}
          isLoading={isSubmitting}
          buscandoDireccion={buscandoDireccion}
        />
      </div>

      {/* TransferList */}
      <div className="col-span-12  xxs:col-span-12 xs:col-span-12 ">
        <Text className="text-secondary font-semibold mt-2 mb-2">
          Potencial de sinergías
        </Text>
        {/* Alerta mensaje con indicaciones */}
        <div className="col-span-12">
          <AlertComponent className="mb-2" type="info">
            <strong>Selecciona </strong> una o más empresas de la columna*{' '}
            <strong>Empresas de análisis territorial*</strong> y agrégalas a la
            columna de <strong>Empresa Destino*</strong> para indicar la
            relación de potencial de sinergia de la empresa a crear.
          </AlertComponent>
        </div>

        <TransferListComponent
          accesor={'razonSocial'}
          codigo={'codigoEmpresaAT'}
          isLoading={isLoadingEmpresasAt}
          label=""
          labelEditar={'empresaATDestino'}
          mostrarLabel={'razonSocial'}
          name="potencialSinergias"
          options={empresasAT || []}
          pageIndex={paginationEmpresasAt.pageIndex}
          pageSize={paginationEmpresasAt.pageSize}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          setPagination={setPaginationEmpresasAt}
          title="Empresas de análisis territorial"
          titleDestino="Empresa Destino"
          total={rowsPerPage}
          touched={touched.potencialSinergias}
          values={values.potencialSinergias || []}
          disabled={true}
          estado={
            errors.potencialSinergias
              ? { tipo: 'error', mensaje: errors.potencialSinergias }
              : null
          }
        />
      </div>
    </>
  );
};

export default FormularioEditarEmpresasAT;
