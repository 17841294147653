import { List } from '@mui/material';
import { Clear, CompareArrows, Done } from '@mui/icons-material';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { PERMISOS } from 'const/Permisos';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import { MenuItemComponente } from 'components/design-system/MenuItem/MenuItem';

// COMPONENTE SECUNDARIO
export const SimplePopper = props => {
  const {
    handleClickTerminarYAprobar,
    handleClickTerminarYRechazar,
    transaccion
  } = props;

  return (
    <div className="w-full">
      <MenuItemComponente
        className="rounded-none bg-gray-100"
        nombre="Cerrar transacción"
        collapsable={true}
        icono={<CompareArrows className="text-uv-primary-0" />}
        subItems={
          <List className="my-0 py-0">
            <VerificarPermisos nombrePermiso={PERMISOS.cerrarTransaccion}>
              <ListItemButtonComponent
                type="secondary"
                onClick={e => handleClickTerminarYAprobar(e, transaccion)}
                icon={<Done className="text-success-dark" />}
                label={
                  <span className="text-success-dark">Terminar y aprobar</span>
                }
              />
            </VerificarPermisos>
            <VerificarPermisos nombrePermiso={PERMISOS.cerrarTransaccion}>
              <ListItemButtonComponent
                type="secondary"
                onClick={e => handleClickTerminarYRechazar(e, transaccion)}
                icon={<Clear className="text-error-dark" />}
                label={
                  <span className="text-error-dark">Terminar y rechazar</span>
                }
              />
            </VerificarPermisos>
          </List>
        }
      />
    </div>
  );
};
