import { useCallback, useState } from 'react';
import ENV from 'const/Environments';
import commons from 'services/commons';
import { DICTONARY } from 'const/Dictonary';
const useServices = () => {
  const cargaIndicadores = ENV.REACT_APP_CARGA_INDICADORES_ON;
  const localIndicadoresString = localStorage.getItem('indicadores') || null;
  const localIndicadores = JSON.parse(localIndicadoresString);

  const [isLoadingUsuarios, setIsLoadingUsuarios] = useState(true);
  const [isLoadingTransacciones, setIsLoadingTransacciones] = useState(true);
  const [isLoadingResiduos, setIsLoadingResiduos] = useState(true);
  const [isLoadingReduccion, setIsLoadingReduccion] = useState(true);
  const [isLoadingResumen, setIsLoadingResumen] = useState(true);
  const [isLoadingDeclarado, setIsLoadingDeclarado] = useState(true);
  const [isLoadingAmbiental, setIsLoadingAmbiental] = useState(true);

  const [dataUsuario, setDataUsuario] = useState([]);
  const [dataTransaccion, setDataTransaccion] = useState([]);
  const [dataDeclarado, setDeclarado] = useState([]);
  const [dataResiduo, setDataResiduo] = useState([]);
  const [dataReduccion, setDataReduccion] = useState([]);
  const [dataAmbiental, setDataAmbiental] = useState([]);
  const [dataResumen, setDataResumen] = useState({
    demanda: [],
    oferta: []
  });

  const cargaUsuarios = useCallback(async () => {
    if (!cargaIndicadores) {
      setIsLoadingUsuarios(false);
      return;
    }
    if (localIndicadores?.usuario) {
      setDataUsuario({ usuario: localIndicadores?.usuario });
      setIsLoadingUsuarios(false);
      return;
    }
    try {
      const query = {
        tipoIndicador: 'usuario'
      };
      const response = await commons.indicadores(
        query,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      if (response?.status === 200) {
        setDataUsuario(response.data);
      } else {
        console.log('Error en cargaIndicadores de usuario ', response);
      }
    } catch (error) {
      console.log('Error en cargaIndicadores de usuario ', error);
    } finally {
      setIsLoadingUsuarios(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cargaTransacciones = useCallback(async () => {
    if (!cargaIndicadores) {
      setIsLoadingTransacciones(false);
      return;
    }
    if (localIndicadores?.transaccion) {
      setDataTransaccion({ transaccion: localIndicadores?.transaccion });
      setIsLoadingTransacciones(false);
      return;
    }
    try {
      const query = {
        tipoIndicador: 'transaccion'
      };
      const response = await commons.indicadores(
        query,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      if (response.status === 200) {
        setDataTransaccion(response.data);
      }
    } catch (error) {
      console.log('Error en cargaIndicadores de transacciones ', error);
    } finally {
      setIsLoadingTransacciones(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cargarDeclaraciones = useCallback(async () => {
    if (!isLoadingDeclarado) {
      setIsLoadingDeclarado(false);
      return;
    }
    if (localIndicadores?.declarado) {
      setDeclarado({ declarado: localIndicadores?.declarado });
      setIsLoadingDeclarado(false);
      return;
    }
    try {
      const query = {
        tipoIndicador: 'declarado'
      };
      const response = await commons.indicadores(
        query,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      if (response.status === 200) {
        setDeclarado(response.data);
      }
    } catch (error) {
      console.log('Error en cargaIndicadores de transacciones ', error);
    } finally {
      setIsLoadingDeclarado(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cargaResiduos = useCallback(async () => {
    if (!cargaIndicadores) {
      setIsLoadingResiduos(false);
      return;
    }
    if (localIndicadores?.residuo) {
      setDataResiduo({
        residuo: {
          valorizado: localIndicadores?.residuo.valorizado,
          declarado: localIndicadores?.residuo.declarado
        }
      });
      setIsLoadingResiduos(false);
      return;
    }

    try {
      const query = {
        tipoIndicador: 'residuo'
      };
      const response = await commons.indicadores(
        query,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      if (response.status === 200) {
        setDataResiduo(response.data);
      }
    } catch (error) {
      console.log('Error en cargaIndicadores de residuos ', error);
    } finally {
      setIsLoadingResiduos(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cargaAmbiental = useCallback(async () => {
    if (!cargaIndicadores) {
      setIsLoadingAmbiental(false);
      return;
    }
    if (localIndicadores?.reduccionCambioClimatico) {
      setDataReduccion({
        reduccion: {
          reduccionCambioClimatico: localIndicadores?.reduccionCambioClimatico,
          reduccionRecursoFosil: localIndicadores?.reduccionRecursoFosil,
          reduccionMaterialParticulado:
            localIndicadores?.reduccionMaterialParticulado,
          reduccionDemandaAcumulada: localIndicadores?.reduccionDemandaAcumulada
        }
      });
      setIsLoadingReduccion(false);
      return;
    }
    try {
      const query = {
        tipoIndicador: 'ambiental'
      };
      const response = await commons.indicadores(
        query,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      if (response.status === 200) {
        setDataAmbiental(response.data);
      }
    } catch (error) {
      console.log('Error en cargaIndicadores de reduccion ', error);
    } finally {
      setIsLoadingAmbiental(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cargaReduccion = useCallback(async () => {
    if (!cargaIndicadores) {
      setIsLoadingReduccion(false);
      return;
    }
    if (localIndicadores?.reduccionCambioClimatico) {
      setDataReduccion({
        reduccion: {
          reduccionCambioClimatico: localIndicadores?.reduccionCambioClimatico,
          reduccionRecursoFosil: localIndicadores?.reduccionRecursoFosil,
          reduccionMaterialParticulado:
            localIndicadores?.reduccionMaterialParticulado,
          reduccionDemandaAcumulada: localIndicadores?.reduccionDemandaAcumulada
        }
      });
      setIsLoadingReduccion(false);
      return;
    }
    try {
      const query = {
        tipoIndicador: 'reduccion'
      };
      const response = await commons.indicadores(
        query,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      if (response.status === 200) {
        setDataReduccion(response.data);
      }
    } catch (error) {
      console.log('Error en cargaIndicadores de reduccion ', error);
      setIsLoadingReduccion(false);
    } finally {
      setIsLoadingReduccion(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cargaResumenIndicadores = useCallback(async () => {
    if (!cargaIndicadores) {
      setIsLoadingResumen(false);
      return;
    }
    if (localIndicadores?.demanda) {
      setDataResumen({
        resumen: {
          demanda: localIndicadores?.demanda,
          oferta: localIndicadores?.oferta
        }
      });
      setIsLoadingResumen(false);
      return;
    }
    try {
      const query = {
        tipoIndicador: 'resumen'
      };
      const response = await commons.indicadores(
        query,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      if (response.status === 200) {
        setDataResumen(response.data);
      }
    } catch (error) {
      console.log('Error en cargaIndicadores de resumen ', error);
    } finally {
      setIsLoadingResumen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    cargaUsuarios,
    cargaTransacciones,
    cargaResiduos,
    cargaReduccion,
    cargaResumenIndicadores,
    cargarDeclaraciones,
    cargaAmbiental,
    isLoadingUsuarios,
    isLoadingTransacciones,
    isLoadingResiduos,
    isLoadingReduccion,
    isLoadingResumen,
    isLoadingDeclarado,
    isLoadingAmbiental,
    dataUsuario,
    dataTransaccion,
    dataResiduo,
    dataReduccion,
    dataResumen,
    dataDeclarado,
    dataAmbiental
  };
};

export default useServices;
