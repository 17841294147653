import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RUTAS } from 'const/Rutas';
import { useSubmitPerfiles } from './useSubmitPerfiles';
import { AlertaError } from 'utils/Alertas';

export const useHelpersPerfiles = () => {
  const navigate = useHistory();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  // PARA EL FUNCIONAMIENTO DEL MENSAJE DE CONFIRMACIÓN
  const handleClickOpen = (row, table) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedRow({});
    setOpen(false);
  };

  const { handleEliminarPerfil } = useSubmitPerfiles();

  const handleClickIngresar = () => {
    navigate.push(`${RUTAS.ADMINISTRAR_PERFILES_INGRESAR}`, {
      formulario: { tipo: 'ingresar' }
    });
  };

  const handleClickEditar = (row, table) => {
    const original = row.original;

    // Se verifica si original y original.codigoResiduo no son nulos ni indefinidos
    if (!original || !original.codigoPerfil) {
      // Si alguno de los dos es nulo o indefinido
      // se muestra un mensaje de error en la pantalla
      AlertaError(
        'Ha ocurrido un error al tratar de editar el Perfil: no es un Perfil válido.'
      );
      // Se devuelve el control inmediatamente y no se hace nada más
      return;
    }

    navigate.push(
      `${RUTAS.ADMINISTRAR_PERFILES}/editar/${original.codigoPerfil}`,
      { formulario: { tipo: 'editar' } }
    );
  };

  const handleClickEliminar = (
    row,
    perfiles,
    setPerfiles,
    setOpen,
    cargaPerfiles
  ) => {
    const original = row.original;
    // Se verifica si original y original.codigoResiduo no son nulos ni indefinidos
    if (!original || !original.codigoPerfil) {
      // Si alguno de los dos es nulo o indefinido
      // se muestra un mensaje de error en la pantalla
      AlertaError(
        'Ha ocurrido un error al tratar de eliminar el Perfil: no es un Perfil válido.'
      );
      // Se devuelve el control inmediatamente y no se hace nada más
      return;
    }
    const codigoPerfil = original.codigoPerfil;
    // Hooks
    // console.log('Eliminar Perfil ::>', codigoPerfil);

    handleEliminarPerfil(codigoPerfil, cargaPerfiles);
    setPerfiles(perfiles.filter(r => r.codigoPerfil !== codigoPerfil));

    // Eliminación de Residuo
    // eliminarResiduo(codigoResiduo, residuos, setResiduos,cargaResiduos);
    setOpen(false);
  };

  return {
    handleClickIngresar,
    handleClickEditar,
    handleClickEliminar,
    handleClickOpen,
    handleClose,
    setOpen,
    open,
    selectedRow
  };
};
