export const UseTocarCamposFormik = (values, setFieldTouched) => {
  // evitar tocar campos nombreNumeroVia, comuna y region
  // Exclusiones
  const camposNoTocar = ['nombreNumeroVia', 'comuna', 'region', 'numero'];
  const camposTocar = Object.keys(values).filter(
    item => !camposNoTocar.includes(item)
  );
  camposTocar.forEach(campo => {
    setFieldTouched(campo);
  });
  return {
    camposTocar
  };
};
