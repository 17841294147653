export const ICON_MATERIAL_PARTICULADO = () => {
  return (
    <svg
      width="34"
      height="30"
      viewBox="0 0 34 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_610_133"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="34"
        height="30"
      >
        <path d="M34 0H0V29.6596H34V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_610_133)">
        <path
          d="M22.5606 10.1992C22.2299 8.51935 21.3851 6.98391 20.1432 5.80542C18.9013 4.62694 17.3237 3.86371 15.6289 3.62143C13.934 3.37914 12.2058 3.66977 10.6834 4.45308C9.16109 5.2364 7.91992 6.47364 7.13179 7.99351C5.44818 8.17384 3.89784 8.99301 2.80016 10.2823C1.70248 11.5715 1.14107 13.2327 1.23157 14.9235C1.32207 16.6143 2.0576 18.206 3.28663 19.3708C4.51567 20.5355 6.14459 21.1845 7.83784 21.1841H22.1743C23.6065 21.1955 24.9866 20.6475 26.021 19.6569C27.0553 18.6662 27.6622 17.311 27.7126 15.8796C27.7629 14.4482 27.2527 13.0537 26.2905 11.9928C25.3283 10.9319 23.99 10.2884 22.5606 10.1992ZM22.1743 18.9777H7.83711C6.96435 18.9777 6.11119 18.7189 5.38551 18.234C4.65984 17.7491 4.09424 17.0599 3.76025 16.2536C3.42626 15.4473 3.33887 14.56 3.50914 13.704C3.67941 12.848 4.09968 12.0617 4.71682 11.4446C5.33395 10.8275 6.12023 10.4072 6.97623 10.2369C7.83222 10.0667 8.71948 10.1541 9.52581 10.488C10.3321 10.822 11.0213 11.3876 11.5062 12.1133C11.9911 12.839 12.2499 13.6921 12.2499 14.5649H14.4555C14.4561 13.1176 13.9817 11.7102 13.1051 10.5586C12.2285 9.40704 10.9981 8.57502 9.60294 8.19027C10.3621 7.16657 11.425 6.40853 12.6401 6.02411C13.8552 5.63969 15.1607 5.64851 16.3705 6.0493C17.5803 6.45009 18.6328 7.22242 19.3781 8.25628C20.1234 9.29014 20.5234 10.5328 20.5213 11.8073V12.3636H22.1757C23.0447 12.3764 23.8738 12.7307 24.4837 13.3497C25.0937 13.9688 25.4356 14.803 25.4356 15.6721C25.4356 16.5412 25.0937 17.3754 24.4837 17.9944C23.8738 18.6135 23.0447 18.9677 22.1757 18.9806L22.1743 18.9777Z"
          fill="#226355"
        />
        <path
          d="M25.6809 24.5958C29.876 24.5958 33.2767 21.1951 33.2767 17C33.2767 12.805 29.876 9.4043 25.6809 9.4043C21.4859 9.4043 18.0852 12.805 18.0852 17C18.0852 21.1951 21.4859 24.5958 25.6809 24.5958Z"
          fill="#43A7B6"
        />
        <path
          d="M27.1125 20.6242L28.7416 18.9951L25.2692 15.5228L22.4234 18.3686L19.3055 15.2283L20.3081 14.2257L22.4234 16.3554L25.2692 13.5095L29.7543 17.9845L31.3834 16.3554V20.6235L27.1125 20.6242Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
