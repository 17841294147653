import React, { useContext, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthContext } from 'context';
import { RUTAS } from 'const/Rutas';

const ALLOWED = [
  '/',
  '/not-found',
  '/registro',
  '/verifica-usuario',
  '/sin-autorizacion'
];

const RouteWithLayout = props => {
  let history = useHistory();
  const { isTienePermisos } = useContext(AuthContext);
  const [permited, setPermited] = useState(null);
  const {
    layout: Layout,
    component: Component,
    store,
    permiso,
    ...rest
  } = props;

  useEffect(() => {
    if (permiso && permiso.length > 0) {
      let isPermisoHabilitado = false;
      permiso.forEach(permisoItem => {
        if (isTienePermisos(permisoItem)) {
          isPermisoHabilitado = true;
          return;
        }
      });

      setPermited(isPermisoHabilitado);
      return;
    }

    if (ALLOWED.find(ruta => props.path.includes(ruta))) {
      setPermited(true);
      return;
    }

    try {
    } catch (error) {
      setPermited(false);
    }
  }, [rest.computedMatch.url]);

  if (permited === null) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={matchProps => {
        if (permited) {
          return (
            <Layout>
              <Component {...matchProps} store={store} />
            </Layout>
          );
        } else {
          if (matchProps.match.path != '/') {
            history.push(RUTAS.NOT_ALLOWED);
          }
        }
      }}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  store: PropTypes.any,
  path: PropTypes.string
};

export default RouteWithLayout;
