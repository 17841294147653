import { Skeleton } from '@mui/material';

export const MapaSinergiasSkeleton = () => {
  return (
    <div className="h-full w-full relative max-h-[600px] min-h-[600px] lg:min-h-full shadow-md bg-white flex flex-col">
      <Skeleton
        variant="square"
        className="w-full h-full relative z-0 min-w-full flex justify-center items-center"
      />
    </div>
  );
};
