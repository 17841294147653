import { useRef } from 'react';
import { Alerta } from './Alertas';
import { DICTONARY } from 'const/Dictonary';

const useDispatchAlert = () => {
  const alertCache = useRef(new Map()); // Cache para controlar las alertas

  const dispatchAlert = code => {
    const now = Date.now();
    const cacheDuration = DICTONARY.TOAST.DURATION;
    // Evitar alertas repetidas
    if (alertCache.current.has(code)) {
      const lastTime = alertCache.current.get(code);
      if (now - lastTime < cacheDuration) {
        return; // No disparar la alerta si está dentro del intervalo
      }
    }

    alertCache.current.set(code, now); // Actualizar el tiempo de la alerta
    Alerta(code); // Disparar la alerta
  };

  return { dispatchAlert };
};

export default useDispatchAlert;
