import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { MapaSinergiasSkeleton } from './MapaSinergiasSkeleton';
import { MensajeMapa } from 'views/AnalisisTerritorial/LineaBase/MapaBase/MensajeMapa';
import Text from 'components/design-system/Text/Text';
import { numeroBaseDatosAFront } from 'helpers/formateoNumeros';
import { useMapaMatch } from 'hooks/useMapaMatch';
import Leaflet from 'leaflet';
import { RenderCanvasData } from './RenderCanvasData';
import { LocationOff } from '@mui/icons-material';

export const MapaSinergias = ({
  isLoading,
  sucursales,
  setRegistroSeleccionado,
  coordenadasMapa,
  setCoordenadasMapa,
  setFilaTablaSeleccionada,
  setFiltros,
  filtro,
  mostrarMensajeSinDireccion,
  setMostrarMensajeSinDireccion
}) => {
  const map = useRef();
  const [inner, setInner] = useState(coordenadasMapa);
  const { titlesurl, attribution } = useMapaMatch();

  useEffect(() => {
    if(coordenadasMapa){
      setInner(coordenadasMapa);
      if (map != null && map.current != null) {
        map.current.flyTo(coordenadasMapa, 14);
      }
    }
  }, [coordenadasMapa]);

  if (isLoading) {
    return <MapaSinergiasSkeleton />;
  }

  const numeroEmpresas = new Set(sucursales.map(item => item.codigoEmpresaAT))
    .size;

  return (
    <MapContainer
      className="h-full w-full relative min-h-[600px] lg:min-h-full shadow-md"
      center={inner}
      ref={map}
      zoom="12"
      scrollWheelZoom={false}
      bounds={null}
      preferCanvas={true}
      renderer={Leaflet.canvas()}
    >
      <TileLayer url={titlesurl} attribution={attribution} />

      {mostrarMensajeSinDireccion &&
        <div className="absolute w-full z-[1000] p-3 top-[45%]">
          <div className="shadow-md max-w-md mx-auto rounded-3xl p-4 bg-white">
            <div className="relative flex justify-center items-center">
              <Text size="M"><LocationOff className='text-red-600' /> <b>No hay dirección</b> para la empresa seleccionada</Text>
            </div>
          </div>
        </div>
      }

      <div className="absolute w-full z-[1000] p-3 top-2">
        <MensajeMapa>
          <Text size="S">
            Se {numeroEmpresas === 1 ? 'ha' : 'han'} graficado{' '}
            <b>{numeroBaseDatosAFront(numeroEmpresas)}</b>{' '}
            {numeroEmpresas === 1 ? 'empresa' : 'empresas'} debido a datos
            incompletos o a que las direcciones no pudieron ser encontradas.
          </Text>
        </MensajeMapa>
      </div>

      <RenderCanvasData
        data={sucursales}
        setRegistroSeleccionado={setRegistroSeleccionado}
        setFilaTablaSeleccionada={setFilaTablaSeleccionada}
        setFiltros={setFiltros}
        filtros={filtro}
        setCoordenadasMapa={setCoordenadasMapa}
        setMostrarMensajeSinDireccion={setMostrarMensajeSinDireccion}
      />
    </MapContainer>
  );
};
