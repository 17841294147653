import { DICTONARY } from 'const/Dictonary';

export const TransformarTipoPublicacion = valor => {
  if (!valor) return;

  if (valor === DICTONARY.ROL.GENERADOR.NOMBRE) {
    return DICTONARY.ROL.GENERADOR.VERBO_1;
  }

  if (valor === DICTONARY.ROL.RECEPTOR.NOMBRE) {
    return DICTONARY.ROL.RECEPTOR.VERBO_1;
  }

  return '';
};
