export const unionRecursos = values => {
  // Union de tipos de recursos del listado y
  const arrayTipoRecurso = values.tipoRecurso.map(item => ({
    codigoRecurso: item.codigoRecurso
  }));

  values.otroTipoRecurso.forEach(item => {
    arrayTipoRecurso.push({
      nombreRecurso: item.nombreRecurso
    });
  });

  return arrayTipoRecurso;
};
