import API from './api';

const cacheUsuarios = {};
const empresasService = {
  usuarios: function (query, conInterceptor = true, { cache } = {}) {
    const queryHash = JSON.stringify(query);
    if (cache && cacheUsuarios[queryHash]) {
      return cacheUsuarios[queryHash];
    }

    cacheUsuarios[queryHash] = API.axios({
      method: 'get',
      url: '/usuarios',
      params: query,
      interceptor: conInterceptor
    });

    return cacheUsuarios[queryHash];
  },

  sucursales: function (query, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/lista-sucursales',
      params: query,
      interceptor: conInterceptor
    });
  },

  obtieneSucursales: function (query, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/sucursales',
      params: query,
      interceptor: conInterceptor
    });
  },

  getSucursal: function (codigoSucursal, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/sucursales/${codigoSucursal}`,
      interceptor: conInterceptor
    });
  },

  actualizarIndicadores: function (
    codigoSucursal,
    data,
    conInterceptor = true
  ) {
    return API.axios({
      method: 'put',
      url: `/sucursales/${codigoSucursal}/datos-indicadores`,
      data: data,
      interceptor: conInterceptor
    });
  },

  actualizaSucursal: function (codigoSucursal, data, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: `/sucursales/${codigoSucursal}`,
      data: data,
      interceptor: conInterceptor
    });
  },

  listaTransacciones: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/transacciones',
      params: params,
      interceptor: conInterceptor
    });
  },

  listaMatchEmpresa: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/lista-match-empresa',
      params: params,
      interceptor: conInterceptor
    });
  },

  datosEmpresasSII: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/empresas/datos-empresa-sii',
      params: params,
      interceptor: conInterceptor
    });
  },

  rubros: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/rubros',
      params: params,
      interceptor: conInterceptor
    });
  },

  actividades: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/actividades',
      params: params,
      interceptor: conInterceptor
    });
  },

  empresa: function (empresaData, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/empresas',
      data: empresaData,
      interceptor: conInterceptor
    });
  },

  obtenerTransacciones(params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/transacciones',
      params: params,
      interceptor: conInterceptor
    });
  },

  obtenerTipoTratamiento: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/tipos-tratamiento',
      params: params,
      interceptor: conInterceptor
    });
  },

  obtenerTipoTransaporte: function (
    numPagina,
    totalPagina,
    ordenarPor,
    conInterceptor = true
  ) {
    return API.axios({
      method: 'get',
      url: '/tipos-transporte',
      params: numPagina,
      totalPagina,
      ordenarPor,
      interceptor: conInterceptor
    });
  },

  matchAutomatico: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/empresas/match-automatico',
      params: params,
      interceptor: conInterceptor
    });
  },

  matchManual: function (filtros, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/empresas/match',
      params: filtros,
      interceptor: conInterceptor
    });
  },

  nuevaSucursal: function (sucursal, conInterceptor = true) {
    return API.axios({
      method: 'POST',
      url: '/sucursales',
      data: sucursal,
      interceptor: conInterceptor
    });
  },

  eliminarSucursal: function (codigoSucursal, conInterceptor = true) {
    return API.axios({
      method: 'DELETE',
      url: `/sucursales/${codigoSucursal}`,
      interceptor: conInterceptor
    });
  },

  eliminarEmpresa: function (codigoEmpresa, conInterceptor = true) {
    return API.axios({
      method: 'DELETE',
      url: `/empresas/${codigoEmpresa}`,
      interceptor: conInterceptor
    });
  },

  obtieneEmpresasSuperAdmin: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/empresas',
      params: params,
      interceptor: conInterceptor
    });
  },

  obtieneEmpresas: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/transacciones/empresas',
      params: params,
      interceptor: conInterceptor
    });
  },

  obtenerEmpresa: function (codigoEmpresa, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/empresas/${codigoEmpresa}`,
      interceptor: conInterceptor
    });
  },

  editarEmpresa: function (codigoEmpresa, data, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: `/empresas/${codigoEmpresa}`,
      data: data,
      interceptor: conInterceptor
    });
  },

  empresaRegistrada: function (data, conInterceptor = true) {
    return API.axios({
      method: 'POST',
      url: '/empresas/empresa-registrada',
      data: data,
      interceptor: conInterceptor
    });
  },

  // GET - Empresas análisis territorial (Lista paginada)
  obtenerListaEmpresasAT: function (params, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: '/empresas/analisis-territorial',
      params: params,
      interceptor: conInterceptor
    });
  },
  // GET - Empresas análisis territorial (Lista paginada optimizado)
  obtenerListaEmpresasOptimizadoAT: function (params, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: '/empresas/analisis-territorial-lista',
      params: params,
      interceptor: conInterceptor
    });
  },
  //  GET - Empresas análisis territorial (un registro)
  obtenerListaEmpresaAT: function (codigoEmpresaAT, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: `/empresas/analisis-territorial/${codigoEmpresaAT}`,
      interceptor: conInterceptor
    });
  },

  // DELETE - Empresas análisis territorial (un registro)
  eliminarEmpresaAT: function (codigoEmpresaAT, conInterceptor = true) {
    return API.axios({
      method: 'DELETE',
      url: `/empresas/analisis-territorial/${codigoEmpresaAT}`,
      interceptor: conInterceptor
    });
  },

  // PUT - Empresas análisis territorial (un registro)
  editarEmpresaAT: function (codigoEmpresaAT, data, conInterceptor = true) {
    return API.axios({
      method: 'PUT',
      url: `/empresas/analisis-territorial/${codigoEmpresaAT}`,
      data: data,
      interceptor: conInterceptor
    });
  },

  // POST - Empresas análisis territorial (un registro)
  crearEmpresaAT: function (data, conInterceptor = true) {
    return API.axios({
      method: 'POST',
      url: '/empresas/analisis-territorial',
      data: data,
      interceptor: conInterceptor
    });
  }
};

export default empresasService;
