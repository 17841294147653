import React from 'react';
import { Topbar, Footer } from './components';
import PropTypes from 'prop-types';
import styles from './styles/main.module.css';
//import ResponsiveBarHelper from 'helpers/ResponsiveBar';

const Main = props => {
  const { children } = props;
  const { history, match } = props.children.props;

  return (
    <div className="flex min-h-screen flex-col">
      {/* <ResponsiveBarHelper /> */}
      <Topbar className={styles.header} history={history} match={match} />
      <main className={styles.content}>{children}</main>
      <Footer />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
