import React from 'react';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';

const NotFound = () => {

  return (
    <div className="px-4 py-10 md:container md:mx-auto">
      <div className="grid grid-cols-12 gap-5 pb-8">
        <div className="col-span-2"></div>
        <div className="col-span-12 md:col-span-8">
          <div className="text-center">
            <Heading type="h1">404: La página que estás buscando no existe</Heading>
            <Text>Verifica que no hayas escrito erroneamente la URL a la cual estas intentando acceder. Si no es el caso prueba recargando la página.</Text>
            <img
              alt="Pagina no encontrada"
              className="mt-12 max-w-full"
              src="/images/undraw_page_not_found_su7k.svg"
            />
          </div>
        </div>
        <div className="col-span-2"></div>
      </div>
    </div>
  );
};

export default NotFound;
