export const ICON_DEMANDA_ACUMULADA = () => {
  return (
    <svg
      width="29"
      height="34"
      viewBox="0 0 29 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_610_143"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="29"
        height="34"
      >
        <path d="M28.1951 0H0V34H28.1951V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_610_143)">
        <path
          d="M4.89012 30.042V16.0995H1.76544L6.479 1.31201H17.0497L12.9722 9.41645H17.0497L4.89012 30.042ZM8.5953 3.20191L4.64798 14.072H7.00559V22.7519L13.6928 11.4291H9.62442L13.9325 3.20109L8.5953 3.20191Z"
          fill="#226355"
        />
        <path
          d="M17.8294 28.1952C22.6383 28.1952 26.5367 24.2968 26.5367 19.4878C26.5367 14.6789 22.6383 10.7805 17.8294 10.7805C13.0205 10.7805 9.12207 14.6789 9.12207 19.4878C9.12207 24.2968 13.0205 28.1952 17.8294 28.1952Z"
          fill="#43A7B6"
        />
        <path
          d="M19.4703 23.6427L21.3378 21.7752L17.3574 17.7947L14.095 21.057L10.5209 17.4572L11.6702 16.3078L14.095 18.7492L17.3574 15.4868L22.4988 20.6167L24.3663 18.7492V23.6418L19.4703 23.6427Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
