import React, { useEffect, useState, useRef, useContext } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import SendIcon from '@mui/icons-material/Send';
import GetApp from '@mui/icons-material/GetApp';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { CircularProgress, Divider, Hidden } from '@mui/material';
import io from 'socket.io-client';
import usuariosServices from 'services/usuarios';
import chatServices from 'services/chat';
import empresasServices from 'services/empresas';
import { getBase64 } from 'utils';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import getEnv from 'helpers/getEnv';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Text from 'components/design-system/Text/Text';
import InputComponent from 'components/design-system/input/Input';
import { EncabezadoEmpresa } from './componentes/Encabezado/EncabezadoEmpresa';
import { EncabezadoResiduo } from './componentes/Encabezado/EncabezadoResiduo';
import Heading from 'components/design-system/Heading/Heading';
import ButtonComponent from 'components/design-system/Button/Button';
import { ChevronRight } from '@mui/icons-material';
import { DICTONARY } from 'const/Dictonary';
import residuos from 'services/residuos';
import LinkComponent from 'components/design-system/Link/Link';
import serviceChat from 'services/chat';
import { downloadFile, getFormatoCantidadUnidad } from './utils/utils';
import moment from 'moment';
import { isBloquearBotonTransaccionChat } from 'helpers/logicaTransacciones';
import { AuthContext } from 'context';
import {
  Alerta,
  AlertaError,
  AlertaExito,
  AlertaInfo,
  AlertaWarning
} from 'utils/Alertas';

const ENDPOINT = getEnv('REACT_APP_CHAT_URL') || '//34.66.177.66:5001';

const Room = ({
  roomid,
  roomData,
  rsdData,
  listaUsuariosSala,
  codigoEmpresaDemandante,
  toggleMenu,
  transacciones
}) => {
  const messageListEl = useRef(null);
  const { logeduser } = useContext(AuthContext);
  const [autoScroll, setAutoScroll] = useState(true);
  const [isFinPaginacion, setIsFinPaginacion] = useState(false);
  const [loadMessagePagination, setLoadMessagePagination] = useState(0);
  const [loadMessages, setLoadMessages] = useState([]);
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);
  const [isIniciandoTransaccion, setIsIniciandoTransaccion] = useState(false);

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [userId, setUserId] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [usersAvatar, setUsersAvatar] = useState({});
  const [socket, setSocket] = useState(null);

  const [isFileMessaje, setIsFileMessaje] = useState(false);
  const [preUploadFile, setPreUploadFile] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);

  const [transaccionIniciada, setTransaccionIniciada] = useState(false);
  const [transaccionFinalizada, setTransaccionFinalizada] = useState(false);
  const [mostrarBotonTransaccionar, setMostrarBotonTransaccionar] =
    useState(false);

  const [rsd, setRSD] = useState(null);
  const [listaTransacciones, setListaTransacciones] = useState([]);
  const [esRSDDemandante, setEsRSDDemandante] = useState(false);
  const [esUsuarioLogueadoDemandante, setEsUsuarioLogueadoDemandante] =
    useState(false);

  // PARA MENSAJE DE CONFIRMACIÓN
  const [open, setOpen] = useState(false);

  // REVIZAR SI EL USUARIO LOGUEADO ES EL USUARIO DEMANDANTE EN
  // LA TRANSACCIÓN
  useEffect(() => {
    if (!logeduser) return;

    if (logeduser.empresa) {
      if (logeduser.empresa.codigoEmpresa == codigoEmpresaDemandante) {
        setEsUsuarioLogueadoDemandante(true);
      }
    }
  }, [codigoEmpresaDemandante, logeduser, roomid]);

  // AL CAMBIAR LA SALA
  useEffect(() => {
    if (!logeduser || !roomid) {
      return;
    }
    setUserId(logeduser.codigoUsuario);
    setLoadMessagePagination(1);
    socketNewConnection();
    cargarTransacciones();
  }, [logeduser, roomid]);

  // MANEJO TIPO DE ORIGEN DE LA SALA DE CHAT
  useEffect(() => {
    if (!rsdData || !roomData) {
      return;
    }
    if (rsdData.origenSalaChat === 'residuo') {
      // LA SALA SE GENERÓ A PARTIR DE UN RSD (VISTA DE OPORTUNIDADES)
      try {
        let rsdTemp = rsdData;
        rsdTemp.fechaDisponibilidad = getFormatedDate(
          rsdData.fechaDisponibilidad
        );
        setRSD(rsdTemp);
        setEsRSDDemandante(
          rsdTemp.rol.codigoRol === DICTONARY.ROL.RECEPTOR.CODIGO ||
            rsdTemp.rol.nombreRol === DICTONARY.ROL.RECEPTOR.NOMBRE
        );
      } catch (err) {
        console.error(err);
      }
    }
  }, [rsdData, roomData]);

  useEffect(() => {
    if (!loadMessagePagination) return;

    loadStoreMessages();
  }, [loadMessagePagination]);

  // ENVIAR INTEGRANTES DE LA SALA AL SERVIDOR DEL CHAT
  useEffect(() => {
    if (isConnected && listaUsuariosSala.length) {
      socket.emit('setRoomUsers', { roomUsers: listaUsuariosSala });
    }
  }, [listaUsuariosSala, isConnected]);

  const socketNewConnection = () => {
    const socket = io(ENDPOINT, { reconnection: false });
    setSocket(socket);
  };

  const socketSuscribers = () => {
    socket.on('message', message => {
      setMessages(messages => [...messages, message]);
    });

    socket.on('roomData', ({ users }) => {
      setIsConnected(true);
    });

    socket.on('roomMessages', ({ room, messages }) => {
      loadSocketMessages(messages);
    });

    socket.on('messageSent', ({ room, message }) => {
      markMessageSent(message);
    });

    socket.on('connect_error', () => {
      Alerta('CHAT500');
      setTimeout(() => {
        socketNewConnection();
        setIsConnected(false);
      }, 2000);
    });

    socket.on('disconnect', () => {
      setTimeout(() => {
        socketNewConnection();
        setIsConnected(false);
      }, 500);
    });
  };

  const getSocketUser = () => {
    const {
      // codigoSucursal,
      nombreUsuario,
      apellidoPaterno,
      codigoUsuario
    } = logeduser;

    const room = roomid + '';
    const name = nombreUsuario + ' ' + apellidoPaterno;
    const userId = codigoUsuario;

    return { name, room, userId };
  };

  // AL INGRESAR A LA SALA
  useEffect(() => {
    if (socket === null) return;

    socketSuscribers();

    socket.emit('join', getSocketUser(), error => {
      if (error) {
        console.error('error desde el socket');
        console.error(error);
      }
    });

    //desmontar el componente o crear una nueva coneccion
    return () => {
      socket.removeAllListeners('message');
      socket.removeAllListeners('roomData');
      socket.removeAllListeners('roomMessages');
      socket.removeAllListeners('messageSent');
      socket.removeAllListeners('connect_error');
      socket.removeAllListeners('disconnect');
      socket.close();
    };
  }, [socket]);

  //busqueda la imagen de avatar de los usuarios
  useEffect(() => {
    const _ids = [...messages, ...loadMessages].map(m => m.user.userId);
    const ids = [...new Set(_ids)];

    ids.forEach(id => {
      if (!usersAvatar[id]) {
        if (id === 0) {
          return;
        }
        usuariosServices
          .getAvatar(id, DICTONARY.INTERCEPTOR.DESACTIVADO, { cache: true })
          .then(result => {
            try {
              const avatar = result.data.base64Avatar;
              const aux = avatars => {
                return {
                  ...avatars,
                  [id]: 'data:image/png;base64,' + avatar
                };
              };
              setUsersAvatar(aux);
            } catch (error) {}
          })
          .catch(err => {});
      }
    });

    if (autoScroll && messageListEl && !isLoadingMessages)
      messageListEl.current.scrollTop = messageListEl.current.scrollHeight;
  }, [messages, loadMessages]);

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendMessage();
    }
  };

  const sendMessage = async () => {
    if (message) {
      const msg = {
        text: message,
        user: getSocketUser(),
        clientTime: Date.now()
      };

      if (isFileMessaje && preUploadFile) {
        try {
          setUploadingFile(true);
          AlertaInfo('Enviando Archivo');
          const { base64, file } = preUploadFile;

          const [desc, data] = base64.split(',');
          const uploadRes = await serviceChat.subirArchivo({
            nombreArchivo: file.name,
            codigoSalaChat: roomid,
            base64: data,
            tipoArchivo: file.type
          });

          const codFile = uploadRes.data.codigoArchivoSalaChat;
          msg.text = '${' + codFile + '}' + msg.text;
          setUploadingFile(false);
        } catch (error) {
          setUploadingFile(false);
          AlertaError('No se pudo enviar el Archivo.');
          return error;
        }
      }

      setMessages(oldmessages => {
        return [...oldmessages, msg];
      });

      if (socket) {
        socket.emit('sendMessage', msg, () => {
          setMessage('');
          setIsFileMessaje(false);
          setPreUploadFile(null);
        });
      } else {
        //reconectando
      }
    }
  };

  const enviarMensajeTransaccionIniciada = async () => {
    if (!logeduser) return;

    const socketUser = getSocketUser();
    socketUser.userId = 0;
    socketUser.name = '';
    const msg = {
      text:
        'El usuario ' +
        logeduser.nombreUsuario +
        ' ha iniciado la transacción.',
      user: socketUser,
      clientTime: Date.now()
    };

    setMessages(oldmessages => {
      return [...oldmessages, msg];
    });

    if (socket) {
      socket.emit('sendMessage', msg, () => {
        setMessage('');
        setIsFileMessaje(false);
        setPreUploadFile(null);
      });
    } else {
      //reconectando
    }
  };

  const loadSocketMessages = socketMessages => {
    setMessages(oldmessages => {
      //mensajes que estan en la vista y no en el servidor
      const notSend = oldmessages.filter(
        m => !socketMessages.find(sm => sm.clientTime == m.clientTime)
      );

      return [...socketMessages, ...notSend];
    });
  };

  const markMessageSent = message => {
    setMessages(oldmessages => {
      for (let i = 0; i < oldmessages.length; i++) {
        if (oldmessages[i].clientTime === message.clientTime) {
          oldmessages[i] = message;
          break;
        }
      }
      return oldmessages;
    });
  };

  const loadStoreMessages = () => {
    if (!autoScroll && !isFinPaginacion) {
      AlertaInfo('Cargando mensajes antiguos');
    }
    setIsLoadingMessages(true);
    chatServices
      .listaMensajes(
        { codigoSalaChat: roomid },
        DICTONARY.INTERCEPTOR.DESACTIVADO
      )
      .then(result => {
        try {
          if (!result.data.mensaje) {
            setIsFinPaginacion(true);
            return;
          }

          const messages = JSON.parse(result.data.mensaje)[0];
          if (Array.isArray(messages)) {
            setLoadMessages(oldmsgs => {
              const msgs = [...messages, ...oldmsgs];

              if (msgs.length < 30) {
                setLoadMessagePagination(loadMessagePagination + 1);
              }
              return msgs;
            });
          }
        } catch (error) {}
      })
      .catch(err => {})
      .finally(() => {
        setIsLoadingMessages(false);
      });
  };

  useEffect(() => {
    if (!isLoadingMessages) {
      messageListEl.current.onscroll = function () {
        if (messageListEl.current.scrollTop === 0) {
          setAutoScroll(false);
          setLoadMessagePagination(pag => {
            messageListEl.current.scrollTop = 1;
            return pag + 1;
          });
        }
      };
    }
  }, [isLoadingMessages]);

  // LÓGICA PARA INICIAR UNA TRANSACCIÓN
  const handleClickTransaccionar = (event, rsd) => {
    setIsIniciandoTransaccion(true);

    if (!rsd) {
      AlertaError(
        'Ha ocurrido un error al tratar de iniciar la transacción: el Residuo no es válido.'
      );
      return;
    }

    if (!logeduser) {
      AlertaError(
        'Ha ocurrido un error al tratar de iniciar la transacción: debe de iniciar sesión.'
      );
      return;
    }

    if (!logeduser.sucursal.codigoSucursal) {
      AlertaError(
        'Ha ocurrido un error al tratar de iniciar la transacción: debe pertenecer a una sucursal.'
      );
      return;
    }

    let query = {
      residuo: {
        codigoResiduo: rsd.codigoResiduo
      },
      salaChat: {
        codigoSalaChat: parseInt(roomid)
      }
    };

    AlertaInfo('Iniciando la transacción...');
    //return; //ELIMINAR

    residuos
      .iniciarTransaccion(query)
      .then(result => {
        cargarTransacciones();
        AlertaExito(
          '¡La transacción ha sido iniciada con éxito! Vaya a Conecta > Transacciones para finalizarla.'
        );
        handleClickClose();
        enviarMensajeTransaccionIniciada();
        setMostrarBotonTransaccionar(false);
        setIsIniciandoTransaccion(false);
      })
      .catch(err => {
        console.error(err);
        let mensajeError =
          'Erro, no se pudo iniciar la transacción. Por favor inténtelo más tarde.';
        if (err.response.data == 'Error_al_crear_datos') {
          mensajeError =
            'Error al crear datos, no se pudo iniciar la transacción. Por favor inténtelo más tarde.';
        }
        setIsIniciandoTransaccion(false);
        AlertaError(mensajeError);
      });
  };

  // PARA OBTENER LAS TRANSACCIONES ACTUALMENTE INICIADAS
  const cargarTransacciones = () => {
    let query = {
      codigoEmpresa: logeduser.sucursal
        ? logeduser.sucursal.empresa.codigoEmpresa
        : null
    };

    empresasServices
      .obtenerTransacciones(query)
      .then(result => {
        if (result.data.content === 0 || !Array.isArray(result.data.content)) {
          setListaTransacciones([]);
        } else if (Array.isArray(result.data.content)) {
          setListaTransacciones(result.data.content);
        }
      })
      .catch(err => {
        console.error(err);
        AlertaError(
          'Error, no se pudo obtener la lista de transacciones. Por favor inténtelo más tarde.'
        );
        setListaTransacciones([]);
      });
  };

  // LÓGICA PARA SABER EL ESTADO DE LA TRANSACCIÓN
  // (HABILITAR/DESHABILITAR EL BOTÓN DE TRANSACCIONAR Y ENVÍO
  // DE MENSAJES)
  useEffect(() => {
    if (!rsd || !logeduser || roomData.origenSalaChat === 'empresa') {
      setMostrarBotonTransaccionar(false);
      return;
    }

    setTransaccionIniciada(false);
    setTransaccionFinalizada(false);

    if (listaTransacciones.length == 0 || !listaTransacciones) {
      setTransaccionIniciada(false);
      return;
    }

    // NO SE DEBE PODER INICIAR OTRA TRANSACCIÓN SI YA EXISTE UNA
    // TRANSACCIÓN EN CURSO CON EL MISMO RSD
    if (isBloquearBotonTransaccionChat(listaTransacciones, rsd.codigoResiduo)) {
      setTransaccionIniciada(true);
      setMostrarBotonTransaccionar(false);
    } else {
      setMostrarBotonTransaccionar(true);
    }

    // SI LA TRANSACCIÓN ESTÁ FINALIZADA, SE DEBE BLOQUEAR
    // EL ENVÍO DE MENSAJES
    if (
      listaTransacciones.find(
        tr =>
          tr.codigoRSD == rsd.codigoResiduo &&
          tr.estado == 'Finalizado Concretado'
        // || tr.estado == 'Finalizado No Concretado'
      )
    ) {
      setTransaccionFinalizada(true);
    }
  }, [rsd, listaTransacciones]);

  const handleChangeMessage = e => {
    if (isFileMessaje) {
      setIsFileMessaje(false);
      setMessage('');
    } else {
      setMessage(e.target.value);
    }
  };

  const handleChangeFile = event => {
    event.persist();
    const file = event.target.files[0];
    if (!file) return;

    // var filesize = (file.size / 1024 / 1024).toFixed(4); // MB
    setIsFileMessaje(true);

    // setMessage(`${file.name} ${filesize} MB`)
    setMessage(`${file.name}`);

    getBase64(file)
      .then(async base64 => {
        setPreUploadFile({ base64, file });
      })
      .catch(error => {
        console.log(error);
      });
  };

  // PARA FUNCIONAMIENTO DEL MENSAJE DE CONFIRMACIÓN
  const handleClickClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  // PARA DARLE EL FORMATO CORRECTO A LA FECHA
  var getFormatedDate = strDate => {
    if (!strDate) return '';
    const fechaCortada = strDate.substring(0, strDate.indexOf('T'));
    const fechaMoment = moment(fechaCortada, 'YYYY-MM-DD');
    const fechaFormateada = fechaMoment.format('DD-MM-YYYY');

    return fechaFormateada;
  };

  const getNombreSala = () => {
    try {
      if (rsd.rol.nombreRol.toLowerCase() === DICTONARY.ROL.GENERADOR.NOMBRE) {
        return `LER ${rsd.nombreLER[0].codigoLER}` || '';
      } else {
        return rsd.nombre || '';
      }
    } catch (error) {
      return '';
    }
  };

  return (
    <div className="flex flex-col min-h-[80vh]  h-full">
      {isLoadingMessages ? (
        <div className="w-full h-full flex items-center justify-center">
          <CircularProgress
            thickness={4}
            size={40}
            className="text-neutral-50 relative z-10 visible"
          />
        </div>
      ) : (
        <>
          {rsdData.origenSalaChat === 'empresa' && roomData && (
            <EncabezadoEmpresa roomData={roomData} toggleMenu={toggleMenu} />
          )}

          {rsdData.origenSalaChat === 'residuo' && (
            <EncabezadoResiduo
              rsd={rsdData}
              toggleMenu={toggleMenu}
              mostrarBotonTransaccionar={mostrarBotonTransaccionar}
              transaccionIniciada={transaccionIniciada}
              handleClickOpen={handleClickOpen}
              roomData={roomData}
            />
          )}

          <div className="flex-grow flex bg-neutral-100">
            <List
              className="py-4 pt-10 max-h-[66vh] min-h-[66vh] overflow-y-auto scrollbar-uv w-full"
              ref={messageListEl}
            >
              {loadMessages.map((m, i) => (
                <ListItem key={i}>
                  <div
                    className={`
                    container flex flex-row messageChat
                    ${m.user.userId === 0 ? 'justify-center' : ''}
                    ${
                      m.user.userId === userId ? 'justify-end' : 'justify-start'
                    } 
                  `}
                  >
                    {m.user.userId !== 0 ? (
                      <Avatar
                        className="w-7 h-7 mr-05"
                        alt={m.user.name}
                        src={
                          usersAvatar[m.user.userId]
                            ? usersAvatar[m.user.userId]
                            : null
                        }
                      />
                    ) : null}
                    <Message
                      text={m.text}
                      time={getMessageTime(m)}
                      userName={m.user.userId === 0 ? '' : m.user.name}
                      fromMessage={
                        m.user.userId === 0
                          ? DICTONARY.CHAT.TIPO_MENSAJES.USUARIO_SISTEMA
                          : m.user.userId === userId
                          ? DICTONARY.CHAT.TIPO_MENSAJES.USUARIO_ACTUAL
                          : DICTONARY.CHAT.TIPO_MENSAJES.USUARIO_CONTRAPARTE
                      }
                    />
                  </div>
                </ListItem>
              ))}

              {messages.map((m, i) => (
                <ListItem key={i}>
                  <div
                    className={`
                    container flex flex-row messageChat
                    ${m.user.userId === 0 ? 'justify-center' : ''}
                    ${
                      m.user.userId === userId ? 'justify-end' : 'justify-start'
                    } 
                  `}
                  >
                    {m.user.userId !== 0 ? (
                      <Avatar
                        className="w-7 h-7 mr-05"
                        alt={m.user.name}
                        src={
                          usersAvatar[m.user.userId]
                            ? usersAvatar[m.user.userId]
                            : null
                        }
                      />
                    ) : null}
                    <Message
                      text={m.text}
                      time={getMessageTime(m)}
                      userName={m.user.userId === 0 ? '' : m.user.name}
                      fromMessage={
                        m.user.userId === 0
                          ? DICTONARY.CHAT.TIPO_MENSAJES.USUARIO_SISTEMA
                          : m.user.userId === userId
                          ? DICTONARY.CHAT.TIPO_MENSAJES.USUARIO_ACTUAL
                          : DICTONARY.CHAT.TIPO_MENSAJES.USUARIO_CONTRAPARTE
                      }
                    />
                  </div>
                </ListItem>
              ))}
            </List>
          </div>

          <div className="p-3 border-t border-solid border-t-neutral-90">
            {roomid ? (
              <div className="grid grid-cols-12">
                <div className="col-span-2 sm:col-span-1">
                  <input
                    className={'hidden'}
                    id="icon-button-file"
                    type="file"
                    onChange={handleChangeFile}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButtonComponent
                      type="secondary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <AttachFileIcon />
                    </IconButtonComponent>
                  </label>
                </div>

                <div className="col-span-8 sm:col-span-9">
                  <InputComponent
                    placeholder={'Escribe aquí tu mensaje'}
                    onChange={handleChangeMessage}
                    value={message}
                    onKeyPress={handleKeyPress}
                    disabled={
                      uploadingFile ||
                      transaccionFinalizada ||
                      isLoadingMessages
                    }
                    fullWidth
                  />
                </div>

                <div className="col-span-2 text-end">
                  <Hidden smDown>
                    <ButtonComponent
                      type="primary"
                      disabled={
                        uploadingFile ||
                        transaccionFinalizada ||
                        isLoadingMessages
                      }
                      onClick={sendMessage}
                      className="w-10 h-10 p-4"
                    >
                      <SendIcon />
                    </ButtonComponent>
                  </Hidden>
                  <Hidden smUp>
                    <IconButtonComponent
                      type="primary"
                      component="span"
                      onClick={sendMessage}
                    >
                      <SendIcon />
                    </IconButtonComponent>
                  </Hidden>
                </div>

                {esRSDDemandante && esUsuarioLogueadoDemandante ? (
                  <Grid item xs={12} align="left">
                    <span className="text-xs text-gray-800 block mx-4">
                      Se han contactado con usted debido a la publicación de su
                      Residuo demandando. Para poder iniciar la transacción,
                      debe buscar el Residuo del oferente perteneciente a la
                      empresa que la ha contactado y con la cual desea
                      establecer el acuerdo.
                    </span>
                  </Grid>
                ) : null}

                {transaccionFinalizada ? (
                  <Grid item xs={12} align="left">
                    <span className="text-xs text-gray-800 block mx-4">
                      Se ha detectado que la transacción ha sido finalizada, por
                      lo que ya no podrá enviar mensajes.
                    </span>
                  </Grid>
                ) : null}
              </div>
            ) : null}
          </div>
        </>
      )}

      {/* MENSAJE DE CONFIRMACIÓN PARA INICIAR TRANSACCIÓN */}
      <Dialog
        open={open}
        onClose={isIniciandoTransaccion ? null : handleClickClose}
        aria-labelledby="iniciar-transaccion"
        aria-describedby="iniciar-transaccion"
      >
        <div className="p-4">
          <Heading type="h4" className="pb-0 mb-0">
            ¿Está seguro de que quiere iniciar la transacción?
          </Heading>
        </div>
        <Divider />

        <DialogContent>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-4 text-right">
              <Text className="font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase">
                Nombre
              </Text>
            </div>
            <div className="col-span-8 text-left">
              <Text className="text-black">{getNombreSala()}</Text>
            </div>

            <div className="col-span-4 text-right">
              <Text className="font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase">
                Cantidad
              </Text>
            </div>
            <div className="col-span-8 text-left">
              <Text className="text-black">
                {rsd ? getFormatoCantidadUnidad(rsd) : ''}
              </Text>
            </div>

            <div className="col-span-4 text-right">
              <Text className="font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase">
                Disponibilidad
              </Text>
            </div>
            <div className="col-span-8 text-left">
              <Text className="text-black">
                {rsd ? rsd.fechaDisponibilidad : ''}
              </Text>
            </div>

            <div className="col-span-4 text-right">
              <Text className="font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase">
                Formato de entrega
              </Text>
            </div>
            <div className="col-span-8 text-left">
              <Text className="text-black">
                {rsd ? rsd.formatoEntrega : ''}
              </Text>
            </div>

            <div className="col-span-4 text-right">
              <Text className="font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase">
                Dirección
              </Text>
            </div>
            <div className="col-span-8 text-left">
              <Text className="text-black">
                {rsd && rsd.sucursal
                  ? (rsd.sucursal?.direccion?.nombreVia || '') +
                    ' ' +
                    (rsd.sucursal?.direccion?.comuna?.nombreComuna || '') +
                    ', Región ' +
                    (rsd.sucursal?.direccion?.comuna?.region?.nombreRegion ||
                      '')
                  : ''}
              </Text>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6">
              <ButtonComponent
                disabled={isIniciandoTransaccion}
                type="secondary"
                onClick={handleClickClose}
                fullWidth
                className="justify-center"
              >
                Cancelar
              </ButtonComponent>
            </div>
            <div className="col-span-6">
              <ButtonComponent
                disabled={isIniciandoTransaccion}
                type="primary"
                className="justify-center"
                onClick={e => handleClickTransaccionar(e, rsd)}
                fullWidth
                isLoading={isIniciandoTransaccion}
              >
                {isIniciandoTransaccion
                  ? 'Iniciando transacción'
                  : 'Iniciar transacción'}
                {!isIniciandoTransaccion && <ChevronRight />}
              </ButtonComponent>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function Message({ text, time, userName, fromMessage }) {
  return (
    <div
      className={`
      bg-white shadow-md rounded min-w-[50%] max-w-[60%] py-2 px-3
        ${
          fromMessage === DICTONARY.CHAT.TIPO_MENSAJES.USUARIO_ACTUAL
            ? 'bg-uv-secondary-90'
            : ''
        }
        ${
          fromMessage === DICTONARY.CHAT.TIPO_MENSAJES.USUARIO_SISTEMA
            ? 'bg-warning-light'
            : ''
        }
    `}
    >
      <Text className="font-semibold tracking-wide text-uv-secondary-0">
        {userName}
      </Text>
      <Text className="text-black font-normal">
        <MessageText key={time} text={text} />
      </Text>
      <Text className="text-neutral-60 text-right font-normal">{time}</Text>
    </div>
  );
}

const MessageText = ({ text }) => {
  const regex = /\$\{([0-9]+?)\}(.*?)$/;
  const fileId = text.match(regex);
  if (fileId) {
    const [full, codigoArchivoSalaChat, filename] = fileId;
    return (
      <LinkComponent
        type="a"
        onClick={handleClickFileMessage({ codigoArchivoSalaChat, filename })}
      >
        <GetApp className="mr-05" />
        {filename}
      </LinkComponent>
    );
  } else {
    return text;
  }
};

const getMessageTime = message => {
  if (typeof message.order === 'undefined') {
    return 'enviando';
  } else {
    const time = new Date(message.time);
    return time.getHours() + ':' + time.getMinutes();
  }
};

const handleClickFileMessage =
  ({ codigoArchivoSalaChat }) =>
  e => {
    e.preventDefault();
    serviceChat
      .obtenerArchivo(codigoArchivoSalaChat)
      .then(res => {
        downloadFile(res.data);
      })
      .catch(error => {
        AlertaWarning(
          'No se ha logrado descargar el documento, puede que el documento ya no exista o no este temporalmente disponible.'
        );
      });
  };

export default Room;
