import { Skeleton } from '@mui/material';
import AlertComponent from 'components/design-system/Alert/Alert';
import Heading from 'components/design-system/Heading/Heading';
export const SkeletonResiduoDeclarado = () => {
  return (
    <>
      <div className="shadow-md rounded-tl rounded-tr bg-white">
        <div className="px-4 sm:px-5 py-4">
          <Heading
            type="h2"
            className="mb-0 text-xl text-[22px] sm:h-10 flex items-center gap-4"
          >
            <Skeleton variant="rounded" className="w-10 h-10 rounded" />
            <Skeleton variant="rounded" className="w-64 h-10 rounded" />
          </Heading>
        </div>

        <div className="border-t py-1.5 px-2 sm:px-5 flex justify-between border-b-4">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10"></div>
            <Skeleton variant="rounded" className="w-20 h-5 rounded" />
          </div>
        </div>
      </div>

      <div className="bg-white  p-5 shadow-md">
        <div className="col-span-12">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 md:col-span-8">
              <AlertComponent type="warning" className="h-20"></AlertComponent>
            </div>

            <div className="col-span-12 md:col-span-12">
              <Heading type="h4" className="mb-0">
                Residuo
              </Heading>
            </div>

            <div className="col-span-12 xs:col-span-12">
              <div className="grid grid-cols-12 gap-8">
                <div className="col-span-12 md:col-span-6 xl:col-span-4">
                  <Skeleton variant="rounded" className="w-full h-10 rounded" />
                </div>
                <div className="col-span-12 md:col-span-6 xl:col-span-4">
                  <Skeleton variant="rounded" className="w-full h-10 rounded" />
                </div>
                <div className="col-span-12 md:col-span-6 xl:col-span-4">
                  <Skeleton variant="rounded" className="w-full h-10 rounded" />
                </div>
                <div className="col-span-12 md:col-span-6 xl:col-span-4">
                  <Skeleton variant="rounded" className="w-full h-10 rounded" />
                </div>
                <div className="col-span-12 md:col-span-6 xl:col-span-4">
                  <Skeleton variant="rounded" className="w-full h-10 rounded" />
                </div>
                <div className="col-span-12 md:col-span-6 xl:col-span-4">
                  <Skeleton variant="rounded" className="w-full h-10 rounded" />
                </div>
                <div className="col-span-12 md:col-span-6 xl:col-span-4">
                  <Skeleton variant="rounded" className="w-full h-10 rounded" />
                </div>
                <div className="col-span-12 md:col-span-6 xl:col-span-4">
                  <Skeleton variant="rounded" className="w-full h-10 rounded" />
                </div>
                <div className="col-span-12 mt-4 -mb-4">
                  <Skeleton variant="rounded" className="w-40 h-10 rounded" />
                </div>
                <div className="col-span-12 md:col-span-6 xl:col-span-4">
                  <Skeleton variant="rounded" className="w-full h-10 rounded" />
                </div>
                <div className="col-span-12 md:col-span-6 xl:col-span-4">
                  <Skeleton variant="rounded" className="w-full h-10 rounded" />
                </div>
                <div className="col-span-12 md:col-span-6 xl:col-span-4">
                  <Skeleton variant="rounded" className="w-full h-10 rounded" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white rounded p-5 shadow-md flex items-end border-t justify-end mb-10">
        <Skeleton variant="rounded" className="w-64 h-10 rounded" />
      </div>
    </>
  );
};
