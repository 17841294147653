import * as yup from 'yup';

const schemaAgregarEnlaces = yup.object().shape({
  url: yup
    .string()
    .required('Campo obligatorio para añadir un enlace')
    .matches(
      /^(http(s)?:\/\/)?(www\.)?[\w.-]+\.[a-zA-Z]{2,3}(\/\S*)?$/,
      'Enlace no válido  (Formato incorrecto) '
    ),

  descripcion: yup.string().required('Campo obligatorio para añadir un enlace')
});

export { schemaAgregarEnlaces };
