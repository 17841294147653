import Text from 'components/design-system/Text/Text';
import { useMemo } from 'react';

export const ColumnsTableEmpresa = () => {
  const columnTables = useMemo(
    () => [
      {
        header: 'Rut',
        accessorKey: 'rutEmpresa',
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: true,
        enableColumnFilter: true
      },
      {
        header: 'Razón social',
        accessorKey: 'razonSocial',
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Tramo',
        accessorKey: 'tramo',
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      },
      {
        header: 'Actividad',
        accessorKey: 'actividad.nombreActividad',
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: true,
        enableColumnFilter: true
      },
      {
        header: 'Region',
        accessorKey: 'direccion.comuna.region.nombreRegion',
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: true,
        enableColumnFilter: true
      },
      {
        header: 'Comuna',
        accessorKey: 'direccion.comuna.nombreComuna',
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: true,
        enableColumnFilter: true
      },
      {
        header: 'Nombre y número calle',
        accessorKey: `nombreCalleyNumero`,
        enableGlobalFilter: false,
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      }
    ],
    []
  );
  return columnTables;
};
