import { Search } from '@mui/icons-material';
import { Form, Formik } from 'formik';
import { useBuscarDifusion } from './hooks/useBuscarDifusion';
import { BuscarMaterialCampos } from './BuscarMaterialCampos';
import ButtonComponent from 'components/design-system/Button/Button';
import AlertComponent from 'components/design-system/Alert/Alert';
import { Divider } from '@mui/material';
import { schemaBuscarMaterial } from './schema/schemaBuscarMaterial';
import { SkeletonBuscarMaterial } from './skeleton/SkeletonBuscarMaterial';

export const BuscarMaterialFormik = ({
  setBuscando,
  setListaDocumentos,
  setBusquedaEfectuada
}) => {
  const {
    isLoading,
    isLoadingComunas,
    listaMarcoLegal,
    listaNormativaTecnica,
    listaPublicaciones,
    listaTipoTratamiento,
    listaRegiones,
    listaComunas,
    listaTipoResiduo,
    handleObtenerComunas,
    onSubmit
  } = useBuscarDifusion();

  return (
    <Formik
      initialValues={{
        marcoLegal: [],
        normativaTecnica: [],
        publicaciones: [],
        tipoResiduo: {},
        tipoTratamientoResiduo: {},
        comuna: [], // importante que sea un array para que funcione el componente multiple
        region: [], // importante que sea un array para que funcione el componente multiple
        nombreArchivo: ''
      }}
      validateOnMount={false}
      validationSchema={schemaBuscarMaterial}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitting(true);
          setBuscando(true);
          setListaDocumentos([]);
          const response = await onSubmit(values);

          if (response.error) {
            throw new Error(
              'No se han encontrado resultados a la búsqueda efectuada'
            );
          } else {
            setListaDocumentos(response.content || []);
          }
        } catch (error) {
          
          console.log('error >', error);
          
          setListaDocumentos([]);
        }
        setBuscando(false);
        setSubmitting(false);
        setBusquedaEfectuada(true);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleChange,
        isSubmitting,
        resetForm,
        validateForm
      }) => (
        <Form id="busquedaMaterialDifusion">
          {isLoading ? (
            <SkeletonBuscarMaterial />
          ) : (
            <div>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 lg:col-span-10">
                  <AlertComponent
                    type={`${errors.ERROR_VALIDACION ? 'error' : 'info'}`}
                  >
                    Ingrese el <strong>nombre del material de información</strong> o
                    seleccione{' '}
                    <strong>al menos uno de los valores de las categorías</strong>{' '}
                    de las listas desplegables para buscar material de información
                  </AlertComponent>
                </div>

                <BuscarMaterialCampos
                  values={values}
                  handleChange={handleChange}
                  setFieldTouched={setFieldTouched}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  listaMarcoLegal={listaMarcoLegal}
                  listaNormativaTecnica={listaNormativaTecnica}
                  listaPublicaciones={listaPublicaciones}
                  listaTipoResiduo={listaTipoResiduo}
                  listaTipoTratamiento={listaTipoTratamiento}
                  listaRegiones={listaRegiones}
                  listaComunas={listaComunas}
                  handleObtenerComunas={handleObtenerComunas}
                  isLoadingComunas={isLoadingComunas}
                  isSubmitting={isSubmitting}
                />
              </div>

              <div className="grid grid-cols-12">
                <div className="col-span-12 mt-10 mb-4">
                  <Divider />
                </div>

                <div className="col-span-12">
                  <div className="flex flex-col xs:flex-row gap-4">
                    <div className="w-full xs:w-1/2 sm:w-auto">
                      <ButtonComponent
                        type="secondary"
                        onClick={() => resetForm()}
                        disabled={isSubmitting}
                        className="w-full items-center justify-center"
                      >
                        Limpiar filtros
                      </ButtonComponent>
                    </div>

                    <div className="w-full xs:w-1/2 sm:w-auto">
                      <ButtonComponent
                        form="busquedaMaterialDifusion"
                        buttonType="submit"
                        type="primary"
                        className="w-full items-center justify-center"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={() => {
                          validateForm()
                            .then(result => {
                              console.log('result :>> ', result);
                            })
                            .catch(err => {
                              console.log(err);
                            });
                        }}
                      >
                        {isSubmitting ? (
                          <>
                            Buscando...{' '}
                            <Search className="ml-2 block xs:hidden sm:block" />
                          </>
                        ) : (
                          <>
                            Buscar Material{' '}
                            <Search className="ml-2 block xs:hidden sm:block" />
                          </>
                        )}
                      </ButtonComponent>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};
