import * as yup from 'yup';

const SchemaBusinessRSD = yup.object().shape({
  codigosTipoResiduo: yup
    .array()
    .of(yup.string())
    .min(1, 'Debe seleccionar al menos un residuo')
    .required('El campo es obligatorio.')
});

export { SchemaBusinessRSD };
