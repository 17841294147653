import React, { useContext } from 'react';
import { useGraficoBarra } from '../hooks/useGraficoBarra';
import { LineaBaseContext } from '../context/LineaBaseContext';
import { Grafico } from './Grafico';
import { Skeleton } from '@mui/material';

export const GraficoBarras = () => {
  const { aplicarFiltros, isCargando, setCargando } =
    useContext(LineaBaseContext);
  const { dataGrafico } = useGraficoBarra(aplicarFiltros, setCargando);
  const isCargandoGrafico = isCargando.graficoBarrasCargando;

  if (isCargandoGrafico) {
    return (
      <Skeleton
        variant="square"
        className="w-full h-full relative z-0 min-w-full flex justify-center items-center min-h-[460px]"
      />
    );
  } else {
    return <Grafico dataGrafico={dataGrafico} />;
  }
};
