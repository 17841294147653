import { Skeleton } from '@mui/material';

export const MapaSkeleton = () => {
  return (
    <div className="shadow-md h-full bg-white">
      <Skeleton
        variant="rounded"
        className="w-full h-full rounded relative z-0 min-w-full flex justify-center items-center"
      />
    </div>
  );
};
