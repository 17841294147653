import { Link as RouterLink } from 'react-router-dom';
import img_logoHeader from '../../../../../assets/images/plataforma-industria-circular.svg';
import img_logoHeaderResponsive from '../../../../../assets/images/responsive-plataforma-industria-circular.svg';
import { useContext } from 'react';
import { AuthContext } from 'context';
import { RUTAS } from 'const/Rutas';

export const MenuLogo = () => {
  const { logged } = useContext(AuthContext);

  return (
    <div className="col-span-4 lg:col-span-3">
      <RouterLink to={logged ? RUTAS.HOME_USER : RUTAS.HOME}>
        <img
          alt="Logo"
          src={img_logoHeader}
          className="hidden sm:block max-w-[220px]"
        />
        <img
          alt="Logo"
          src={img_logoHeaderResponsive}
          className="block sm:hidden"
        />
      </RouterLink>
    </div>
  );
};
