import {
  EspesificacionOferta,
  IdentificacionResiduo,
  MaterialAdjunto
} from './Generador/secciones';
import { FormularioReceptor } from './Receptor/FormularioReceptor';

export const CamposResiduosFormik = props => {
  return (
    <>
      {
        // Formulario Generador
        props.isNombreRolOferente ? (
          <>
            <IdentificacionResiduo {...props} />
            <EspesificacionOferta {...props} />
            <MaterialAdjunto {...props} />
          </>
        ) : (
          // Formulario Receptor
          <FormularioReceptor {...props} />
        )
      }
    </>
  );
};
