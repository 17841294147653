import { Search } from "@mui/icons-material";
import { Divider, Skeleton } from "@mui/material";
import AlertComponent from "components/design-system/Alert/Alert";
import ButtonComponent from "components/design-system/Button/Button";
import Heading from "components/design-system/Heading/Heading";

export const SkeletonBuscarMaterial = () => {

  return(
    <div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-10">
          <AlertComponent type={`info`}>
            Ingrese el <strong>nombre del documento</strong> o
            seleccione{' '}
            <strong>al menos uno de los valores de categorías</strong>{' '}
            de las listas desplegables para buscar documentos
          </AlertComponent>
        </div>
        <div className="col-span-12 md:col-span-4 lg:col-span-4">
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <Heading type="h4" className="text-uv-primary-0">Buscar por nombre</Heading>
                </div>
                <div className="col-span-12">
                  <Skeleton variant="rounded"
                    className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-8 lg:col-span-8 pl-4">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <Heading type="h4" className="text-uv-primary-0">Buscar por categoría asociada al documento</Heading>
                </div>

                <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                  <Skeleton variant="rounded"
                    className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                  />
                </div>
              
              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                <Skeleton variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>
              
              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                <Skeleton variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>

              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                <Skeleton variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>

              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                <Skeleton variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>

              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                <Skeleton variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>

              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                <Skeleton variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>          
    </div>       
    <div className="grid grid-cols-12">
      <div className="col-span-12 mt-10 mb-4">
        <Divider />
      </div>
      
      <div className="col-span-12">
        <ButtonComponent
          type="secondary"
          disabled={true}
        >
          Limpiar filtros
        </ButtonComponent>

        <ButtonComponent
          form="busquedaMaterialDifusion"
          buttonType="submit"
          type="primary"
          className="ml-4"
          disabled={true}
        >
          Buscar Material <Search className="ml-2" />
        </ButtonComponent>
      </div>
    </div>
  </div>
  )
};