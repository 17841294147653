import { types } from './types/types';

export const authReducer = (state, action) => {
  switch (action.type) {
    case types.login:
      return {
        ...state,
        logged: true,
        ...action.payload
      };

    case types.logout:
      return {
        logged: undefined,
        token: undefined,
        logeduser: undefined,
        permisos: {},
        tipoNegocio: [],
        perfiles: {
          isSuperAdmin: false,
          isDemandante: false,
          isOferente: false,
          isGestor: false,
          isTransportista: false,
          isAsesor: false,
          isAnalistaTerritorial: false,
          isUsuario: false,
          isAdmin: false
        }
      };

    case types.setToken:
      return {
        ...state,
        token: action.payload
      };

    default:
      return state;
  }
};
