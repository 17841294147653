import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { CargarMaterialFormik } from './CargarMaterial/CargarMaterialFormik';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { DICTONARY } from 'const/Dictonary';
import PropTypes from 'prop-types';
import documentos from 'services/documentos';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { RUTAS } from 'const/Rutas';

const Difusion = props => {
  const params = useParams() || null;
  const { codigoMaterialInformacion } = useParams() || null;
  const [isLoading, setIsLoading] = useState(true);
  const [material, setMaterial] = useState(null);
  const [archivo, setArchivo] = useState(null);

  useEffect(() => {
    if (Object.keys(params).length === 0) {
      setMaterial(null);
      setArchivo(null);
      setIsLoading(false);
      // Realizar un f5 en la página de creación, redirecciona a la lista
      // de materiales de difusión
    }
  }, [params]);

  useMemo(() => {
    // Si no hay codigoMaterialInformacion, se trata de una creación
    const fetchMaterialInformacion = async () => {
      try {
        const response = await documentos.obtenerMaterialInformacion(
          codigoMaterialInformacion,
          DICTONARY.INTERCEPTOR.DESACTIVADO
        );
        const material = response.data;
        setMaterial(material);
      } catch (error) {
        console.log('ERROR_AL_CARGAR_MATERIAL', error);
        props.history.replace(RUTAS.MATERIAL_DIFUSION);
      } finally {
        // setIsLoading(false); // necesario para que se muestre el documento
      }
    };

    const fetchMaterialInformacionBase64 = async () => {
      try {
        const response = await documentos.obtenerMaterialInformacionBase64(
          codigoMaterialInformacion,
          DICTONARY.INTERCEPTOR.DESACTIVADO
        );
        const archivo = response.data;
        setArchivo(archivo);
      } catch (error) {
        console.log('ERROR_AL_CARGAR_DOCUMENTO', error);
        props.history.replace(RUTAS.MATERIAL_DIFUSION);
      } finally {
        setIsLoading(false);
      }
    };

    if (codigoMaterialInformacion) {
      fetchMaterialInformacion();
      fetchMaterialInformacionBase64();
    } else {
      setIsLoading(false);
    }
    // window.scrollTo(0, 0);
  }, [codigoMaterialInformacion, props.history]);

  return (
    <SidebarLayout>
      <div className="px-4 md:container md:mx-auto pt-5">
        {!isLoading && (
          <CargarMaterialFormik
            documento={material}
            archivo={material ? archivo : false}
            isEdicion={material ? true : false}
          />
        )}
      </div>
    </SidebarLayout>
  );
};

Difusion.propTypes = {
  history: PropTypes.object
};

export default withRouter(Difusion);
