import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import TaskAlt from '@mui/icons-material/TaskAlt';
import markerRecicladordeBaseTipoA from 'assets/images/smp/markerRecicladordeBaseTipoA.svg';
import markerRecicladordeBaseTipoB from 'assets/images/smp/markerRecicladordeBaseTipoB.svg';
import markerRecicladordeBaseTipoC from 'assets/images/smp/markerRecicladordeBaseTipoC.svg';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ButtonComponent from 'components/design-system/Button/Button';
import { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';

export const TiposDeRecicladorBase = () => {
  const [mostrar, setMostrar] = useState(false);

  return (
    <div className="border rounded p-5 mt-5">
      <div className="sm:flex justify-between">
        <Heading type="h3" className="mb-4 sm:mb-0">
          Tipos de Reciclador de Base
        </Heading>
        <ButtonComponent type="secondary" onClick={() => setMostrar(!mostrar)}>
          {mostrar ? (
            <>
              <ExpandLess /> Ver menos
            </>
          ) : (
            <>
              <ExpandMore /> Ver más
            </>
          )}
        </ButtonComponent>
      </div>

      {mostrar && (
        <div>
          <div className="grid grid-cols-12 gap-4 animate-fade-in mt-5">
            <div className="col-span-12">
              <Text>
                Existe 3 tipos de Reciclador de Base, que están identificados
                por medio de una medalla de color. Cada uno de ellos permite a
                los usuarios de la plataforma poder identificar aquellos que
                poseen las autorizaciones sanitarios para la prestación de sus
                servicios las cuales son las siguientes:
              </Text>
              <ul className="pt-4">
                <li className="flex items-center gap-2 py-1">
                  <TaskAlt className="text-xl text-uv-primary-0" />{' '}
                  <Text className="text-uv-primary-0">
                    Autorización sanitaria en transporte
                  </Text>
                </li>
                <li className="flex items-center gap-2 py-1">
                  <TaskAlt className="text-xl text-uv-primary-0" />{' '}
                  <Text className="text-uv-primary-0">
                    Autorización sanitaria lugar de acopio
                  </Text>
                </li>
                <li className="flex items-center gap-2 py-1">
                  <TaskAlt className="text-xl text-uv-primary-0" />{' '}
                  <Text className="text-uv-primary-0">
                    Registro nacional de recicladores de base
                  </Text>
                </li>
              </ul>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-y-4 sm:gap-8 mt-5">
            <div className="col-span-12 sm:col-span-4">
              <div className="flex sm:block md:flex gap-4 items-start">
                <img
                  src={markerRecicladordeBaseTipoA}
                  alt="Tipo A"
                  className="md:mx-auto mb-4 w-12"
                />
                <div>
                  <Heading type="h4">Tipo A</Heading>
                  <Text>Cumple con todas las caracteristicas</Text>
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-4">
              <div className="flex sm:block md:flex gap-4 items-start">
                <img
                  src={markerRecicladordeBaseTipoB}
                  alt="Tipo B"
                  className="md:mx-auto mb-4 w-12"
                />
                <div>
                  <Heading type="h4">Tipo B</Heading>
                  <Text>Cumple con al menos una caracteristicas</Text>
                </div>
              </div>
            </div>
            <div className="col-span-12 sm:col-span-4">
              <div className="flex sm:block md:flex gap-4 items-start">
                <img
                  src={markerRecicladordeBaseTipoC}
                  alt="Tipo C"
                  className="md:mx-auto mb-4 w-12"
                />
                <div>
                  <Heading type="h4">Tipo C</Heading>
                  <Text>No cumple con ninguna característica</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
