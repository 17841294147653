import { Skeleton } from '@mui/material';
import AlertComponent from 'components/design-system/Alert/Alert';
import Heading from 'components/design-system/Heading/Heading';
import LabelComponent from 'components/design-system/Label/Label';

import { SaltoLinia } from 'views/RecursoAdquirido/utils/utils';

export const SkeletonRecursosAdquiridos = ({ tipoForm }) => {
  const row2 = [0, 1];
  const minrow2 = [0, 1];
  const row3 = [0, 1, 2];

  return (
    <div className="bg-white rounded p-5 shadow-sm mb-5">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          {/* Recurso Adquirido */}
          <div className="col-span-12  mb-4">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-12">
                <Heading type="h4" className="mb-0">
                  Recurso adquirido
                </Heading>
              </div>
              <div className="col-span-12 xs:col-span-12">
                <div className="grid grid-cols-12 gap-4">
                  {row2.map((item, index) => (
                    <div className="col-span-12 xs:col-span-4" key={index}>
                      <Skeleton
                        variant="rounded"
                        className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                      />
                    </div>
                  ))}
                  {minrow2.map((item, index) => (
                    <div className="col-span-12 xs:col-span-2" key={index}>
                      <Skeleton
                        variant="rounded"
                        className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                      />
                    </div>
                  ))}
                  {row3.map((item, index) => (
                    <div className="col-span-12 xs:col-span-4" key={index}>
                      <Skeleton
                        variant="rounded"
                        className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <SaltoLinia />
          {/* Generador - Vendedor del recurso */}
          <div className="col-span-12 mt-4 mb-4">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-12">
                <Heading type="h4" className="mb-0">
                  Generador - Vendedor del recurso
                </Heading>
              </div>
              <div className="col-span-12 xs:col-span-12">
                <div className="grid grid-cols-12 gap-4">
                  {row2.map((item, index) => (
                    <div className="col-span-12 xs:col-span-6" key={index}>
                      <Skeleton
                        variant="rounded"
                        className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                      />
                    </div>
                  ))}

                  {row2.map((item, index) => (
                    <div className="col-span-12 xs:col-span-3" key={index}>
                      <Skeleton
                        variant="rounded"
                        className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                      />
                    </div>
                  ))}
                  <div className="col-span-12 xs:col-span-6">
                    <Skeleton
                      variant="rounded"
                      className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                    />
                  </div>
                  <div className="col-span-12 xs:col-span-12">
                    <Skeleton
                      variant="rounded"
                      className="w-full h-[300px] rounded relative z-0 flex justify-center items-center mt-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SaltoLinia />
          {/* Transporte */}
          <div className="col-span-12 mt-4 mb-4">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-12">
                <Heading type="h4" className="mb-0">
                  Transporte
                </Heading>
              </div>
              <div className="col-span-12 xs:col-span-12">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 xs:col-span-4">
                    <Skeleton
                      variant="rounded"
                      className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SaltoLinia />

          {/* Envase - embalaje */}
          <div className="col-span-12 mt-4 mb-4">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-12">
                <Heading type="h4" className="mb-0">
                  Contenido reciclado y renovable del recurso
                </Heading>
              </div>

              <div className="col-span-12 xs:col-span-12">
                <div className="grid grid-cols-12 gap-4">
                  {row2.map((item, index) => (
                    <div className="col-span-12 xs:col-span-4" key={index}>
                      <Skeleton
                        variant="rounded"
                        className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <SaltoLinia />
          {/* Envase - embalaje */}
          <div className="col-span-12 mt-4 mb-4">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-12">
                <Heading type="h4" className="mb-0">
                  Envase - embalaje
                </Heading>
              </div>

              <div className="col-span-12 xs:col-span-12">
                <div className="grid grid-cols-12 gap-4">
                  {row2.map((item, index) => (
                    <div className="col-span-12 xs:col-span-4" key={index}>
                      <Skeleton
                        variant="rounded"
                        className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <SaltoLinia />
          <div className="col-span-12 mb-8">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-12">
                <Heading type="h4" className="mb-0">
                  Residuo
                </Heading>
              </div>
              <div className="col-span-12 xs:col-span-12">
                <div className="grid grid-cols-12 gap-4">
                  {row3.map((item, index) => (
                    <div className="col-span-12 xs:col-span-4" key={index}>
                      <Skeleton
                        variant="rounded"
                        className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                      />
                    </div>
                  ))}
                  <div className="col-span-12 xs:col-span-8">
                    <AlertComponent type="info">
                      <Skeleton
                        variant="rounded"
                        className="w-full h-24 rounded relative z-0 flex justify-center items-center mt-3"
                      />
                    </AlertComponent>
                  </div>

                  <div className="col-span-4"></div>

                  {row2.map((item, index) => (
                    <div className="col-span-12 xs:col-span-4" key={index}>
                      <Skeleton
                        variant="rounded"
                        className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                      />
                    </div>
                  ))}

                  <div className="col-span-12 xs:col-span-12 mt-4"></div>

                  {row2.map((item, index) => (
                    <div className="col-span-12 xs:col-span-4" key={index}>
                      <Skeleton
                        variant="rounded"
                        className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <SaltoLinia />

          {/* Documentos de respaldo */}
          <div className="col-span-12">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-12">
                <Heading type="h4" className="mb-0">
                  Documentos de respaldo
                </Heading>
              </div>
              <div className="col-span-12 xs:col-span-12">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 xs:col-span-12">
                    <LabelComponent>
                      Cargar documento de respaldo, Ej: Guía de despacho,
                      factura u otro tipo de documento.{' '}
                      <span className="text-neutral-60">(opcional)</span>
                    </LabelComponent>
                    <div className="col-span-12 xs:col-span-12">
                      <Skeleton
                        variant="rounded"
                        className="w-full h-[200px] rounded relative z-0 flex justify-center items-center mt-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
