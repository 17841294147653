import * as yup from 'yup';

const schemaPerfiles = yup.object().shape({
  nombrePerfil: yup
    .string()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio'),
  descripcionPerfil: yup
    .string()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio'),
  permisos: yup
    .array()
    .min(1, 'Debe seleccionar al menos un elemento')
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio')
});

export { schemaPerfiles };
