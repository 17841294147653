//Calculo SUB LER
export const calculoListaSubLer = (
  selected,
  setFieldValue,
  listaLer,
  setListaLerNivelDos,
  setTieneSubtiposLer,
  setListaLerNivelTres
) => {
  if (selected) {
    const newOptions = listaLer.filter(
      ler => ler.codigoNivelAnterior === selected.codigoLER
    );
    setListaLerNivelDos(newOptions);
    setTieneSubtiposLer(true);
  } else {
    setTieneSubtiposLer(false);
    setListaLerNivelDos([]);
    setFieldValue('codigoSubLER', '');
    setFieldValue('nombreSubLER', '');
    setListaLerNivelTres([]);
    setFieldValue('codigoSubSubLER', '');
    setFieldValue('nombreSubSubLER', '');
  }
};

export const calculoListaSubSubLer = (
  selected,
  setFieldValue,
  listaLer,
  setListaLerNivelTres,
  setTieneSubSubtiposLer
) => {
  if (selected) {
    const newOptions = listaLer.filter(
      ler => ler.codigoNivelAnterior === selected.codigoLER
    );

    setListaLerNivelTres(newOptions);
    setTieneSubSubtiposLer(true);
  } else {
    setTieneSubSubtiposLer(false);
    setListaLerNivelTres([]);
    setFieldValue('codigoSubSubLER', '');
    setFieldValue('nombreSubSubLER', '');
  }
};
