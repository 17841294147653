import { useContext } from 'react';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';
import { DICTONARY } from 'const/Dictonary';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Dashboard from '@mui/icons-material/Dashboard';
import GavelIcon from '@mui/icons-material/Gavel';
import GroupIcon from '@mui/icons-material/Group';
import InsightsIcon from '@mui/icons-material/Insights';
import InventoryIcon from '@mui/icons-material/Inventory';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Assignment from '@mui/icons-material/Assignment';
import { SIDEBAR_MI_CUENTA } from '../SidebarMenus';
import { RequestQuote } from '@mui/icons-material';
import Warehouse from '@mui/icons-material/Warehouse';
import { RUTAS } from 'const/Rutas';

export const MENU = (codigoNegocio, tieneNegocio) => {
  const {
    isTienePermisos,
    logeduser,
    perfiles: { isSuperAdmin }
  } = useContext(AuthContext);

  return [
    {
      section: 'Panel de Administración',
      enabled: true,
      items: [
        {
          nombre: isSuperAdmin ? 'Negocios' : 'Editar mi negocio',
          icon: <AccountBalanceIcon />,
          path: codigoNegocio
            ? `${RUTAS.NEGOCIO}/editar/${codigoNegocio}`
            : RUTAS.ADMINISTRAR_NEGOCIOS,
          subItems: [],
          enabled: codigoNegocio
            ? isTienePermisos(PERMISOS.editarNegocio) && tieneNegocio
            : isTienePermisos(PERMISOS.editarNegocio)
        },
        {
          nombre: 'Editar casa matriz',
          icon: <Warehouse />,
          path: codigoNegocio
            ? `${RUTAS.NEGOCIO}/editar/sucursales/${logeduser.sucursal.codigoSucursal}`
            : RUTAS.ADMINISTRAR_NEGOCIOS,
          subItems: [],
          enabled:
            !isSuperAdmin &&
            tieneNegocio &&
            isTienePermisos(PERMISOS.editarSucursal)
        },
        {
          nombre: 'Residuo-recurso',
          icon: <InventoryIcon />,
          subItems: [
            {
              nombre: 'Administrar residuo - recurso',
              path: RUTAS.RESIDUOS,
              enabled: tieneNegocio && isTienePermisos(PERMISOS.residuoRecurso)
            },
            {
              nombre: 'Requerir residuo',
              path: `${RUTAS.RESIDUOS}/${DICTONARY.ROL.RECEPTOR.NOMBRE}/ingresar`,
              enabled: tieneNegocio && isTienePermisos(PERMISOS.requerirResiduo)
            },
            {
              nombre: 'Ofrecer residuo',
              path: `${RUTAS.RESIDUOS}/${DICTONARY.ROL.GENERADOR.NOMBRE}/ingresar`,
              enabled: tieneNegocio && isTienePermisos(PERMISOS.ofrecerResiduo)
            }
          ],
          enabled:
            (isTienePermisos(PERMISOS.residuoRecurso) ||
              isTienePermisos(PERMISOS.requerirResiduo) ||
              isTienePermisos(PERMISOS.ofrecerResiduo)) &&
            tieneNegocio
        },
        {
          nombre: 'Sucursales',
          icon: <WarehouseIcon />,
          subItems: [
            {
              nombre: 'Administrar sucursales',
              path: codigoNegocio
                ? `${RUTAS.NEGOCIO}/${codigoNegocio}/sucursales`
                : RUTAS.ADMINISTRAR_SUCURSALES,
              enabled: isTienePermisos(PERMISOS.sucursal)
            },
            {
              nombre: 'Nueva sucursal',
              path: `${RUTAS.NEGOCIO}/${codigoNegocio}/sucursales/nuevo`,
              enabled: isTienePermisos(PERMISOS.crearSucursal)
            }
          ],
          enabled: codigoNegocio
            ? (isTienePermisos(PERMISOS.sucursal) && tieneNegocio) ||
              (isTienePermisos(PERMISOS.crearSucursal) && tieneNegocio)
            : isTienePermisos(PERMISOS.sucursal) ||
              isTienePermisos(PERMISOS.crearSucursal)
        },
        {
          nombre: 'Usuarios',
          icon: <GroupIcon />,
          subItems: [
            {
              nombre: 'Administrar usuarios',
              path: codigoNegocio
                ? `${RUTAS.NEGOCIO}/${codigoNegocio}/usuarios`
                : RUTAS.ADMINISTRAR_USUARIOS,
              enabled: isTienePermisos(PERMISOS.usuarios)
            },
            {
              nombre: 'Invitar usuarios',
              path: RUTAS.INVITAR_USUARIOS,
              enabled: isTienePermisos(PERMISOS.invitarUsuarios)
            }
          ],
          enabled: codigoNegocio
            ? (isTienePermisos(PERMISOS.usuarios) && tieneNegocio) ||
              (isTienePermisos(PERMISOS.invitarUsuarios) && tieneNegocio)
            : isTienePermisos(PERMISOS.usuarios) ||
              isTienePermisos(PERMISOS.invitarUsuarios)
        },
        {
          nombre: 'Residuo declarado',
          icon: <GavelIcon />,
          subItems: [
            {
              nombre: 'Administrar residuos declarados',
              path: RUTAS.GESTIONA_RESIDUO_DECLARADO,
              enabled: isTienePermisos(PERMISOS.residuoDeclarado)
            },
            {
              nombre: 'Nuevo residuo declarado',
              path: RUTAS.INGRESAR_RESIDUO_DECLARADO,
              enabled: isTienePermisos(PERMISOS.crearResiduoDeclarado)
            }
          ],
          enabled:
            isTienePermisos(PERMISOS.residuoDeclarado) ||
            isTienePermisos(PERMISOS.crearResiduoDeclarado)
        },
        {
          nombre: 'Recurso adquirido',
          icon: <RequestQuote />,
          subItems: [
            {
              nombre: 'Administrar recursos adquiridos',
              path: RUTAS.GESTIONA_RECURSO_ADQUIRIDO,
              enabled: isTienePermisos(PERMISOS.recursoAdquirido)
            },
            {
              nombre: 'Nuevo recurso adquirido',
              path: `${RUTAS.GESTIONA_RECURSO_ADQUIRIDO}/ingresar`,
              enabled: isTienePermisos(PERMISOS.crearRecursoAdquirido)
            }
          ],
          enabled:
            isTienePermisos(PERMISOS.recursoAdquirido) ||
            isTienePermisos(PERMISOS.crearRecursoAdquirido)
        },
        {
          nombre: 'Configuración para indicadores',
          icon: <InsightsIcon />,
          path: `${RUTAS.NEGOCIO}/${codigoNegocio}/datos-indicadores`,
          subItems: [],
          enabled: isTienePermisos(PERMISOS.configurarIndicadores)
        },
        {
          nombre: 'Registro de actividad',
          icon: <Assignment />,
          path: `${RUTAS.ADMINISTRAR_LOGS_ACTIVIDAD}`,
          subItems: [],
          enabled: isTienePermisos(PERMISOS.registroActividad)
        },
        {
          nombre: 'Análisis territorial',
          icon: <Dashboard />,
          subItems: [
            {
              nombre: 'Cargar base de datos',
              path: RUTAS.GESTION_TERRITORIAL,
              enabled: isTienePermisos(PERMISOS.cargaBaseDatos)
            },
            {
              nombre: 'Empresas de análisis territorial',
              path: RUTAS.LISTA_EMPRESAS_AT,
              enabled: isTienePermisos(PERMISOS.empresaAT)
            },
            {
              nombre: 'Residuos de análisis territorial',
              path: RUTAS.LISTA_RESIDUOS_AT,
              enabled: isTienePermisos(PERMISOS.residuoAT)
            }
          ],
          enabled:
            isTienePermisos(PERMISOS.cargaBaseDatos) ||
            isTienePermisos(PERMISOS.empresaAT) ||
            isTienePermisos(PERMISOS.residuoAT)
        },
        {
          nombre: 'Perfiles',
          icon: <GroupIcon />,
          path: RUTAS.ADMINISTRAR_PERFILES,
          subItems: [],
          enabled: isTienePermisos(PERMISOS.crearPerfil)
        }
      ]
    },

    { ...SIDEBAR_MI_CUENTA() }
  ];
};
