import React, { useContext, useEffect, useState } from 'react';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { HomeSuperAdminyAnalista } from './HomeSuperAdminyAnalista';
import { HomeUsuario } from './HomeUsuario';
import usuariosService from 'services/usuarios';
import { DICTONARY } from 'const/Dictonary';
import { useCallback } from 'react';
import { formateoDataSeparadorMiles } from 'helpers/formateoNumeros';
import { AuthContext } from 'context';

const HomeUsuarioLogeado = () => {
  const { logeduser, token, refrescarSesion } = useContext(AuthContext);

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const tieneNegocioRegistrado = logeduser && logeduser.sucursal ? true : false;

  const [usuarioInvitado, setUsuarioInvitado] = useState(
    localStorage.getItem('verificaUsuarioSucursal') || null
  );

  useEffect(() => {
    // Refrescar usuario si el usuario es un usuario invitado al cual se le agrego una sucursal
    if (usuarioInvitado) {
      refrescarSesion(token);
      localStorage.removeItem('verificaUsuarioSucursal');
    }
  }, [refrescarSesion, token, usuarioInvitado]);

  const cargarDatos = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await usuariosService.getResumenUsuario(
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      const error = response.data.error;
      if (error) throw new Error('ERROR_CARGAR_RESUMEN');
      const dataResumenUsuarios = response.data;
      const datosFormateados = formateoDataSeparadorMiles(dataResumenUsuarios);

      setData(datosFormateados);
    } catch (error) {
      setData({});
      console.log('Error al cargar resumen ', error);
    }
    setIsLoading(false);
  }, [logeduser?.codigoUsuario]);

  useEffect(() => {
    if (logeduser && logeduser.codigoUsuario) {
      cargarDatos();
    }
  }, [cargarDatos, logeduser?.codigoUsuario]);

  return (
    <SidebarLayout>
      <HomeSuperAdminyAnalista
        logeduser={logeduser}
        isLoading={isLoading}
        data={data}
      />

      <HomeUsuario
        data={data}
        tieneNegocioRegistrado={tieneNegocioRegistrado}
        isLoading={isLoading}
      />
    </SidebarLayout>
  );
};

export default HomeUsuarioLogeado;
