import {
  Dashboard,
  Description,
  Event,
  Forum,
  ContentCopy
} from '@mui/icons-material';
import Table from 'components/design-system/Table/Table';
import Text from 'components/design-system/Text/Text';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DICTONARY } from 'const/Dictonary';
import {
  Tooltip,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { SimplePopper } from 'views/Transaccion/components';
import { ObtieneUltimaTransaccion } from 'views/Transaccion/utils';
import serviceChat from 'services/chat';
import serviceRSD from 'services/residuos';
import empresasServices from 'services/empresas';
import { useHistory } from 'react-router-dom';
import { ModalTransacciones } from '../ModalTransacciones';
import { numeroBaseDatosAFront } from 'helpers/formateoNumeros';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';
import { AlertaError, AlertaExito } from 'utils/Alertas';

export const TablaTransacciones = ({
  listaTransacciones,
  pagination,
  setPagination,
  handleListaTransaccion,
  setData,
  setTotal,

  registroTablaSeleccionado,
  setRegistroTablaSeleccionado,
  setCoordinadasCentro,

  total,
  isLoading,
  filtros
}) => {
  const {
    perfiles: {
      isSuperAdmin,
      isAdmin,
      isAnalistaTerritorial,
      isRecicladorDeBase,
      isOferente,
      isDemandante
    },
    logeduser,
    isTienePermisos
  } = useContext(AuthContext);
  const history = useHistory();
  const [listaSucursalesUsuarioLogueado] = useState([]);
  const [listaSalasChat, setListaSalasChat] = useState([]);
  const [transaccionSelected, setTransaccionSelected] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setTransaccionSelected(false);
    setOpenModal(false);
  };

  // OBTENER LISTAS EMPRESAS GESTOR, ASESOR, TRANSPORTISTA,
  // TIPO EMPRESA USUARIO,
  // LISTA DE SALAS DE CHAT,
  // LISTA DE TRANSACCIONES, LISTA DE TIPOS DE TRANSPORTE
  useEffect(() => {
    if (!logeduser) {
      return;
    }

    // OBTENER EL TIPO EMPRESA DEL USUARIO (DEMANDANTE/OFERENTE)
    try {
    } catch (err) {
      AlertaError(
        'Ha ocurrido un error al tratar de obtener el tipo de empresa del usuario.'
      );
      console.error(err);
    }

    let params = {
      codigoEmpresa: logeduser.sucursal
        ? logeduser.sucursal.empresa.codigoEmpresa
        : null,
      numPagina: pagination.pageIndex,
      totalPagina: pagination.pageSize,
      ...filtros
    };

    if (isAnalistaTerritorial) {
      const codigoRegion =
        logeduser.region?.codigoRegion ??
        DICTONARY.DEFAULT_REGION.REGION_METROPOLITANA;
      params.codigoRegion = codigoRegion;
    }

    // Ejecuta servicio de transacciones
    handleListaTransaccion(params);

    // SALAS DE CHAT
    if (!isSuperAdmin) {
      // SUPERADMIN NO PUEDE VER LAS CONVERSACIONES
      try {
        serviceChat
          .salaChat(null, DICTONARY.INTERCEPTOR.DESACTIVADO)
          .then(result => {
            const { data } = result;
            const { content, empty } = data;
            if (empty) {
              setListaSalasChat([]);
              console.log('LISTA DE SALAS DE CHAT VACÍA!');
            } else if (Array.isArray(content)) {
              // console.log('Salas de chat >> ', content);
              setListaSalasChat(content);
            }
          })
          .catch(err => {
            AlertaError(
              'Ha ocurrido un error al tratar de obtener la lista de salas de reunión.'
            );
            console.error(err);
          });
      } catch (err) {
        AlertaError(
          'Ha ocurrido un error al tratar de obtener la lista de salas de reunión.'
        );
        console.error(err);
      }
    }
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    handleListaTransaccion,
    isSuperAdmin,
    setData,
    setTotal,
    logeduser.sucursal,
    filtros
  ]);

  // SOLO PARA DEMANDANTES
  const handleClickTerminarYAprobarDemandante = (event, transaccion) => {
    const { transaccionEstado, codigoTransaccion } = transaccion;

    let ultimaTrasaccion = ObtieneUltimaTransaccion(
      codigoTransaccion,
      transaccionEstado
    );

    setTransaccionSelected({
      nombreEstado: ultimaTrasaccion.nombreEstado,
      codigoEstado: ultimaTrasaccion.codigoEstado,
      ...transaccion
    });

    handleOpenModal();
  };

  // SOLO PARA OFERENTES
  const handleClickTerminarYAprobarOferente = (event, transaccion) => {
    const { codigoTransaccion, transaccionEstado } = transaccion;

    let ultimaTrasaccion = ObtieneUltimaTransaccion(
      codigoTransaccion,
      transaccionEstado
    );

    setTransaccionSelected({
      nombreEstado: ultimaTrasaccion.nombreEstado,
      codigoEstado: ultimaTrasaccion.codigoEstado,
      ...transaccion
    });

    const dataFormateada = {
      transaccionEstado: [
        {
          estado: {
            codigoEstado: isOferente ? 4 : ultimaTrasaccion.codigoEstado
          }
        }
      ]
    };

    try {
      serviceRSD
        .cerrarTransacciones(dataFormateada, codigoTransaccion)
        .then(result => {
          //SI TIENE EXITO LA TRANSACCION, ENVIAR A VISTA RESUMEN DE TRANSACCION
          let stateTemp = {
            codigoTransaccion: codigoTransaccion
          };
          history.push({
            pathname: '/transacciones/resumen',
            state: stateTemp
          });
        });
    } catch (error) {
      console.log('ERROR AL CERRAR LA TRANSACCION:');
      console.error(error);
      AlertaError(
        'Ha ocurrido un error al tratar de cerrar la transacción. Por favor inténtelo más tarde.'
      );
    }
  };

  // UTILIZADO POR OFERENTE Y DEMANDANTE
  const handleClickTerminarYRechazar = async (event, transaccion) => {
    try {
      const { codigoTransaccion } = transaccion;
      const estadoTransaccion =
        transaccion.transaccionEstado.length > 0
          ? ObtieneUltimaTransaccion(
              transaccion.codigoEstado,
              transaccion.transaccionEstado
            )
          : null;

      const roldeMiEmpresaEnTransaccion = transaccion.sucursalTransaccion.find(
        registro =>
          registro.sucursal.empresa.codigoEmpresa ===
          logeduser.sucursal.empresa.codigoEmpresa
      );

      const dataFormateada = {
        transaccionEstado: [
          {
            estado: {
              codigoEstado:
                roldeMiEmpresaEnTransaccion.nombreRol ===
                DICTONARY.ROL.RECEPTOR.NOMBRE
                  ? 3
                  : roldeMiEmpresaEnTransaccion.nombreRol ===
                    DICTONARY.ROL.GENERADOR.NOMBRE
                  ? 5
                  : estadoTransaccion.codigoEstado
            }
          }
        ]
      };

      serviceRSD
        .cerrarTransacciones(dataFormateada, codigoTransaccion)
        .then(result => {
          //SI TIENE EXITO LA TRANSACCION, ENVIAR A VISTA RESUMEN DE TRANSACCION
          // console.log('RESULT >> ', result);
          let stateTemp = {
            codigoTransaccion: codigoTransaccion
          };
          history.push({
            pathname: '/transacciones/resumen',
            state: stateTemp
          });
        });
    } catch (error) {
      console.log('ERROR AL CERRAR LA TRANSACCION:');
      console.error(error);
      AlertaError(
        'Ha ocurrido un error al tratar de cerrar la transacción. Por favor inténtelo más tarde.'
      );
    }
  };

  const handleClickResumenTransaccion = codigoTransaccion => {
    let stateTemp = {
      codigoTransaccion: codigoTransaccion,
      tipo: 'resumen'
    };
    history.push({
      pathname: `/transacciones/resumen`,
      state: stateTemp
    });
  };

  const handleClickGuardarResiduoDeclarado = async codigoTransaccion => {
    try {
      console.log('codigoTransaccion: ', codigoTransaccion);
      serviceRSD
        .declararTransaccion({ codigoTransaccion: codigoTransaccion })
        .then(result => {
          if (result.status === 200) {
            AlertaExito(
              `La transacción ha sido guardada como residuo declarado exitosamente. Código residuo declarado: ${result.data?.codigoResiduoDeclarado}`
            );
          }
        })
        .catch(error => {
          AlertaError(
            'Ha ocurrido un error al tratar de guardar la transacción como residuo declarado.'
          );
          console.error(error);
        });
    } catch (error) {
      console.log('ERROR AL GUARDAR RESIDUO DECLARADO:');
      console.error(error);
      AlertaError(
        'Ha ocurrido un error al tratar de guardar la transacción como residuo declarado. Por favor inténtelo más tarde.'
      );
    }
  };

  // LÓGICA PARA IR A LA SALA DE CHAT
  const handleClickSalaChat = codigoTransaccion => {
    if (!codigoTransaccion) return;
    if (!listaSalasChat) return;

    const salaChat = listaSalasChat.find(
      sala => sala.transaccion?.codigoTransaccion === codigoTransaccion
    );

    if (!salaChat) {
      AlertaError(
        'Esta transacción no tiene una sala de reunión asignada. Debe solicitar la sala primero.'
      );
      return;
    }

    history.push('/chat/' + salaChat.codigoSalaChat);
  };

  // DETERMINAR EL ROL QUE TIENE EL USUARIO EN LA TRANSACCIÓN
  const determinarRolEnTransaccion = transaccion => {
    if (!transaccion || !logeduser || !listaSucursalesUsuarioLogueado) return;

    try {
      let codigoSucursalUsuarioLogueado = isSuperAdmin
        ? 0
        : logeduser.sucursal.codigoSucursal;

      const roles = DICTONARY.ROLES;

      let sucursales = {};

      roles.forEach(rol => {
        sucursales[rol] = transaccion.sucursalTransaccion.find(
          sucursal => sucursal.nombreRol === rol
        );
      });

      // EN PRIMER LUGAR SE REVISA POR SUCURSAL
      const esDemandante =
        sucursales.receptor &&
        sucursales.receptor.sucursal.codigoSucursal ===
          codigoSucursalUsuarioLogueado;
      const esOferente =
        sucursales.generador &&
        sucursales.generador.sucursal.codigoSucursal ===
          codigoSucursalUsuarioLogueado;
      const esGestor =
        sucursales.gestor &&
        sucursales.gestor.sucursal.codigoSucursal ===
          codigoSucursalUsuarioLogueado;
      const esAsesor =
        sucursales.asesor &&
        sucursales.asesor.sucursal.codigoSucursal ===
          codigoSucursalUsuarioLogueado;
      const esTransportista =
        sucursales.transportista &&
        sucursales.transportista.sucursal.codigoSucursal ===
          codigoSucursalUsuarioLogueado;

      if (esDemandante) {
        return DICTONARY.ROL.RECEPTOR.NOMBRE;
      } else if (esOferente) {
        return DICTONARY.ROL.GENERADOR.NOMBRE;
      } else if (esGestor) {
        return DICTONARY.ROL.GESTOR.NOMBRE;
      } else if (esAsesor) {
        return DICTONARY.ROL.ASESOR.NOMBRE;
      } else if (esTransportista) {
        return DICTONARY.ROL.TRANSPORTISTA.NOMBRE;
      }

      // LUEGO SE REVISA POR EMPRESA SI LA SUCURSAL DEL USUARIO LOGUEADO
      // NO PARTICIPA EN LA TRANSACCIÓN

      //   CASO ESPECIAL, SI LA TRANSACCIÓN ES UNA TRANSACCIÓN
      //   INTERNA DE LA EMPRESA (SOLO APLICA PARA ADMINISTRADOR)
      if (isAdmin) {
        const sucursalDemandante = listaSucursalesUsuarioLogueado.find(
          sucursal =>
            sucursal.codigoSucursal === sucursales.receptor.codigoSucursal
        );

        const sucursalOferente = listaSucursalesUsuarioLogueado.find(
          sucursal =>
            sucursal.codigoSucursal === sucursales.generador.codigoSucursal
        );

        // Verifica que ambas sucursales pertenecen a la empresa
        if (sucursalDemandante && sucursalOferente) {
          // El rol del admin depende del estado de la transacción
          if (
            transaccion.estado === 'Iniciada' ||
            transaccion.estado === 'Cerrada Oferta Concretada'
          ) {
            return DICTONARY.ROL.RECEPTOR.NOMBRE;
          } else if (transaccion.estado === 'Cerrada Demanda Concretada') {
            return DICTONARY.ROL.GENERADOR.NOMBRE;
          }
        }
      }

      // EMPRESA ES DEMANDANTE
      //Buscar coincidencias en la lista de sucursales del usuario logueado
      const buscarCoincidencias = sucursal => {
        return listaSucursalesUsuarioLogueado.find(
          sucursalUsuario =>
            sucursalUsuario.codigoSucursal === sucursal.codigoSucursal
        );
      };

      //Verificar si la empresa es demandante y si tiene alguna coincidencia
      if (sucursales.receptor && buscarCoincidencias(sucursales.receptor)) {
        return DICTONARY.ROL.RECEPTOR.NOMBRE;
      }

      //Verificar si la empresa es oferente y si tiene alguna coincidencia
      if (sucursales.generador && buscarCoincidencias(sucursales.generador)) {
        return DICTONARY.ROL.GENERADOR.NOMBRE;
      }

      // EMPRESA ES GESTOR
      const sucursalGestor = sucursales.gestor;

      if (
        sucursalGestor &&
        listaSucursalesUsuarioLogueado.find(
          sucursal => sucursal.codigoSucursal === sucursalGestor.codigoSucursal
        )
      ) {
        return DICTONARY.ROL.GESTOR.NOMBRE;
      }

      // EMPRESA ES ASESOR
      // si la sucursal tiene un asesor
      if (
        sucursales.asesor &&
        // y el usuario ha iniciado sesión en una sucursal que tiene el mismo código de sucursal que el asesor
        listaSucursalesUsuarioLogueado.find(
          sucursal =>
            sucursal.codigoSucursal === sucursales.asesor.codigoSucursal
        )
      )
        // entonces el usuario es un asesor para esa sucursal
        return DICTONARY.ROL.ASESOR.NOMBRE;

      // EMPRESA ES TRANSPORTISTA
      if (
        sucursales.transportista &&
        listaSucursalesUsuarioLogueado.some(
          sucursal =>
            sucursal.codigoSucursal === sucursales.transportista.codigoSucursal
        )
      )
        return DICTONARY.ROL.TRANSPORTISTA.NOMBRE;
    } catch (err) {
      AlertaError(
        'Ha ocurrido un error al tratar de determinar el rol del usuario en la transacción.'
      );
      console.error(err);
      return 'error';
    }

    // NI LA EMPRESA NI LA SUCURSAL DEL USUARIO
    // LOGUEADO PARTICIPAN EN LA TRANSACCIÓN?
    return 'none';
  };

  const handleMostrarBotonResumenTransaccion = transaccion => {
    const transaccionesPermitidas = DICTONARY.TRANSACCIONES_PERMITIDAS;

    return transaccionesPermitidas.includes(transaccion.estado);
  };

  const muestraOcultaBotonCerrarTransaccion = (
    codigoTransaccion,
    transaccionEstado,
    transaccion
  ) => {
    if (!isDemandante && !isOferente && !isRecicladorDeBase) {
      return false;
    }

    if (!isTienePermisos(PERMISOS.cerrarTransaccion)) {
      return false;
    }

    if (!codigoTransaccion) return;
    const ultimoEstado = ObtieneUltimaTransaccion(
      codigoTransaccion,
      transaccionEstado
    );

    const isRoleOferente =
      transaccion.infoOferente.nombre ===
      logeduser.sucursal.empresa.nombreEmpresa;
    const isRoleDemandante =
      transaccion.infoDemandante.nombre ===
      logeduser.sucursal.empresa.nombreEmpresa;

    if (isRoleOferente) {
      if (
        ultimoEstado.nombreEstado ===
          DICTONARY.ESTADOS_TRANSACCION.INICIADA.NOMBRE ||
        ultimoEstado.nombreEstado ===
          DICTONARY.ESTADOS_TRANSACCION['CERRADA DEMANDA CONCRETADA'].NOMBRE ||
        ultimoEstado.nombreEstado ===
          DICTONARY.ESTADOS_TRANSACCION['CERRADA DEMANDA NO CONCRETADA'].NOMBRE
      )
        return true;
      else {
        return false;
      }
    }

    if (isRoleDemandante) {
      if (
        ultimoEstado.nombreEstado ===
          DICTONARY.ESTADOS_TRANSACCION.INICIADA.NOMBRE ||
        ultimoEstado.nombreEstado ===
          DICTONARY.ESTADOS_TRANSACCION['CERRADA OFERTA CONCRETADA'].NOMBRE ||
        ultimoEstado.nombreEstado ===
          DICTONARY.ESTADOS_TRANSACCION['CERRADA OFERTA NO CONCRETADA'].NOMBRE
      )
        return true;
      else {
        return false;
      }
    }

    return false;
  };

  const muestraOcultaBotonGuardarResiduo = (
    codigoTransaccion,
    transaccionEstado
  ) => {
    //El usuario no tiene permisos para guardar un residuo.
    if (!isDemandante && !isOferente && !isRecicladorDeBase) {
      return false;
    }

    //El residuo no tiene los datos suficientes para guardar el residuo
    if (!codigoTransaccion) return;
    const ultimoEstado = ObtieneUltimaTransaccion(
      codigoTransaccion,
      transaccionEstado
    );

    //El residuo debe solo permitir guardarse dependiendo del estado final de la transacción.
    if (
      ultimoEstado.nombreEstado ===
      DICTONARY.ESTADOS_TRANSACCION['FINALIZADO CONCRETADO'].NOMBRE
    )
      return true;
    else {
      return false;
    }
  };

  const transaccionConSalaChat = codigoTransaccion => {
    if (!codigoTransaccion) return;
    if (!listaSalasChat) return;

    let salaChat = listaSalasChat.find(
      sala => sala.transaccion?.codigoTransaccion === codigoTransaccion
    );

    if (salaChat) {
      return true;
    }

    return false;
  };

  const calcularCentro = arrMarkers => {
    const totalMarkers = arrMarkers.length;
    if (totalMarkers === 0) return [0, 0];

    let totalLat = 0;
    let totalLon = 0;

    arrMarkers.forEach(marker => {
      totalLat += marker.latitud;
      totalLon += marker.longitud;
    });

    setCoordinadasCentro([totalLat / totalMarkers, totalLon / totalMarkers]);
  };

  const handleSeleccionarEmpresa = empresa => {
    if (empresa !== registroTablaSeleccionado) {
      setRegistroTablaSeleccionado(empresa.codigoTransaccion);
      calcularCentro(
        listaTransacciones.dataMapa.filter(
          item => item.codigoTransaccion === empresa.codigoTransaccion
        )
      );
    }
  };

  useEffect(() => {
    setRowSelection({ row: -1 });
    setRegistroTablaSeleccionado(null);
    calcularCentro(listaTransacciones.dataMapa);
  }, [listaTransacciones]);

  const [listadoTiposTransporte, setListadoTiposTransporte] = useState([]);
  const [listadoEmpresasGestor, setListadoEmpresasGestor] = useState([]);
  const [listadoEmpresasAsesor, setListadoEmpresasAsesor] = useState([]);
  const [listadoEmpresasTransportista, setListadoEmpresasTransportista] =
    useState([]);
  const [rowSelection, setRowSelection] = useState([]);

  useEffect(() => {
    const queryEmpresa = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA
    };

    // Servicio Tipo de Transporte
    const obtenerTiposTransporte = async () => {
      try {
        const response = await empresasServices.obtenerTipoTransaporte(
          null,
          null,
          null,
          DICTONARY.INTERCEPTOR.DESACTIVADO
        );
        const content = response.data.content;
        setListadoTiposTransporte(content);
      } catch (error) {
        console.log(error);
        setListadoTiposTransporte([]);
      }
    };

    // Llamadas a funciones Gestor, Asesor, Transportista
    obtenerTiposTransporte();

    const obtenerEmpresasPorRol = (nombreRol, callback) => {
      try {
        const query = {
          ...queryEmpresa,
          nombreRol
        };
        empresasServices
          .obtieneSucursales(
            query,
            null,
            null,
            null,
            DICTONARY.INTERCEPTOR.DESACTIVADO
          )
          .then(response => {
            const { content } = response.data;
            if (content) {
              callback(
                content.map(sucursal => ({
                  codigoSucursal: sucursal.codigoSucursal,
                  nombreSucursal:
                    sucursal.nombreSucursal +
                    ' - ' +
                    sucursal.empresa.nombreEmpresa,
                  desdeServicio: true
                }))
              );
            }
          });
      } catch (error) {
        callback([]);
        console.log(error);
      }
    };

    if (isSuperAdmin || isDemandante) {
      obtenerEmpresasPorRol('transportista', setListadoEmpresasTransportista);
      obtenerEmpresasPorRol('gestor', setListadoEmpresasGestor);
      obtenerEmpresasPorRol('asesor', setListadoEmpresasAsesor);
    }
  }, [isDemandante, isSuperAdmin]);

  const obtenerMenuItems = row => {
    const opcionesMenu = [];

    if (
      (isOferente || isDemandante) &&
      isTienePermisos(PERMISOS.salasReunion)
    ) {
      opcionesMenu.push(
        <ListItemButton
          disabled={!transaccionConSalaChat(row.original.codigoTransaccion)}
          onClick={e => handleClickSalaChat(row.original.codigoTransaccion)}
        >
          <ListItemIcon className="min-w-[40px]">
            <Forum className="text-uv-primary-0" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Text
                className="font-secondary text-uv-primary-0 tracking-wider"
                size="M"
              >
                Sala de reunión
              </Text>
            }
          />
        </ListItemButton>
      );
    }

    if (
      muestraOcultaBotonCerrarTransaccion(
        row.original.codigoTransaccion,
        row.original.transaccionEstado,
        row.original
      )
    ) {
      opcionesMenu.push(
        <Box display="flex" alignItems="center">
          <SimplePopper
            transaccion={row.original}
            handleClickTerminarYAprobar={
              determinarRolEnTransaccion(row.original) ===
              DICTONARY.ROL.RECEPTOR.NOMBRE
                ? handleClickTerminarYAprobarDemandante
                : handleClickTerminarYAprobarOferente
            }
            handleClickTerminarYRechazar={handleClickTerminarYRechazar}
          />
        </Box>
      );
    }

    if (isTienePermisos(PERMISOS.resumenTransaccion)) {
      opcionesMenu.push(
        <ListItemButton
          disabled={
            handleMostrarBotonResumenTransaccion(row.original) ||
            row.original.ultimaTrasaccion === 'Iniciada'
          }
          onClick={e =>
            handleClickResumenTransaccion(row.original.codigoTransaccion)
          }
        >
          <ListItemIcon className="min-w-[40px]">
            <Description className="text-uv-primary-0" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Text
                className="font-secondary text-uv-primary-0 tracking-wider"
                size="M"
              >
                Resumen de transacción
              </Text>
            }
          />
        </ListItemButton>
      );
    }

    if (
      muestraOcultaBotonGuardarResiduo(
        row.original.codigoTransaccion,
        row.original.transaccionEstado
      ) &&
      isTienePermisos(PERMISOS.resumenTransaccion)
    ) {
      opcionesMenu.push(
        <ListItemButton
          className="max-w-xs"
          onClick={e =>
            handleClickGuardarResiduoDeclarado(row.original.codigoTransaccion)
          }
          disabled={row.original.residuoDeclarado}
        >
          <ListItemIcon className="min-w-[40px]">
            <ContentCopy className="text-uv-primary-0" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Text
                className="font-secondary text-uv-primary-0 tracking-wider"
                size="M"
              >
                {row.original.residuoDeclarado
                  ? 'El registro ya fue guardado como residuo declarado'
                  : 'Guardar como residuo declarado'}
              </Text>
            }
          />
        </ListItemButton>
      );
    }

    return opcionesMenu;
  };

  const empresaColumnTable = useMemo(
    () => [
      {
        header: 'Código',
        accessorKey: 'codigoTransaccion',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        size: 50
      },
      {
        header: 'Generador',
        accessorKey: 'infoOferente',
        Cell: ({ renderedCellValue }) => (
          <>
            <Text className="text-black">{renderedCellValue.nombre}</Text>
            <Text>{renderedCellValue.direccion} </Text>
          </>
        )
      },
      {
        header: 'Materia transaccionada',
        accessorKey: 'residuo',
        size: 260,
        Cell: ({ renderedCellValue }) => (
          <>
            <Text className="text-black">
              {renderedCellValue.tipoResiduo.nombreTipo || ''}
            </Text>
            <Text size="S" className="mb-1.5 text-neutral-30">
              {renderedCellValue.nombreRecurso || ''}
            </Text>
            <Text className="mb-1.5">
              <Dashboard className="text-uv-secondary-0 mr-1" />
              {numeroBaseDatosAFront(renderedCellValue.cantidad) || ''}{' '}
              {renderedCellValue.unidad || ''}{' '}
              {renderedCellValue.unidadMedida?.nombreUnidadMedida || ''}
            </Text>
            <Text>
              <Event className="text-uv-secondary-0 mr-1" />
              {renderedCellValue.fechaDisponibilidad}
            </Text>
          </>
        )
      },
      {
        header: 'Receptor',
        accessorKey: 'infoDemandante',
        Cell: ({ renderedCellValue }) => (
          <>
            <Text className="text-black">{renderedCellValue.nombre}</Text>
            <Text>{renderedCellValue.direccion}</Text>
          </>
        )
      },
      {
        header: 'Estado',
        accessorKey: 'ultimaTrasaccion',
        Cell: ({ renderedCellValue }) => (
          <>
            <Tooltip arrow title={renderedCellValue.nombreEstado}>
              <img
                src={
                  DICTONARY.ESTADOS_TRANSACCION[
                    renderedCellValue.nombreEstado.toUpperCase()
                  ].ICONO
                }
                alt={renderedCellValue}
              />
            </Tooltip>
          </>
        )
      }
    ],
    []
  );

  return (
    <div className="col-span-12 lg:col-span-8">
      <Table
        muiTableContainerProps={{ sx: { height: '600px' } }}
        columns={empresaColumnTable}
        data={listaTransacciones.dataTabla}
        className="h-full"
        rowCount={total}
        isLoading={isLoading}
        manualPagination
        enableColumnActions={true}
        enableColumnFilters={false}
        enablePagination={true}
        enableTopToolbar={false}
        muiTableBodyCellProps={{
          sx: {
            verticalAlign: 'top'
          }
        }}
        getRowId={row => row.codigoTransaccion}
        muiTableBodyRowProps={({ row }) => ({
          onClick: ev => {
            if (ev.target.parentElement.nodeName === 'BUTTON') {
              //Desactiva el desmarcar al presionar un botón
              return;
            }
            if (row.id === rowSelection.id) {
              setRowSelection({ row: -1 });
              setRegistroTablaSeleccionado(null);
              return;
            }
            setRowSelection(row);
            handleSeleccionarEmpresa(row.original);
          },
          selected: rowSelection.id === row.id,
          sx: {
            cursor: 'pointer'
          }
        })}
        enableRowActions
        renderRowActionMenuItems={({ row }) => obtenerMenuItems(row)}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: 'Acciones',
            size: 100
          }
        }}
        positionActionsColumn="last"
        onPaginationChange={setPagination}
        state={{
          isLoading,
          showProgressBars: false,
          pagination,
          columnVisibility: {
            nombreRegion: isSuperAdmin ? true : false
          }
        }}
      />

      <ModalTransacciones
        listadoTiposTransporte={listadoTiposTransporte}
        listadoEmpresasGestor={listadoEmpresasGestor}
        listadoEmpresasAsesor={listadoEmpresasAsesor}
        listadoEmpresasTransportista={listadoEmpresasTransportista}
        transaccionSelected={transaccionSelected}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

/* 

const TableMenuAction = ({ condition, disabled, onClick, children }) => {


  return(
    <>
      {(isOferente || isDemandante) &&
        isTienePermisos(PERMISOS.salasReunion) && (
          <ListItemButton
            disabled={
              !transaccionConSalaChat(row.original.codigoTransaccion)
            }
            onClick={e =>
              handleClickSalaChat(row.original.codigoTransaccion)
            }
          >
            <ListItemIcon className="min-w-[40px]">
              <Forum className="text-uv-primary-0" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Text
                  className="font-secondary text-uv-primary-0 tracking-wider"
                  size="M"
                >
                  Sala de reunión
                </Text>
              }
            />
          </ListItemButton>
        )}
    </>
  )
} */
/* 
{(isOferente || isDemandante) &&
  isTienePermisos(PERMISOS.salasReunion) && (
    <ListItemButton
      disabled={
        !transaccionConSalaChat(row.original.codigoTransaccion)
      }
      onClick={e =>
        handleClickSalaChat(row.original.codigoTransaccion)
      }
    >
      <ListItemIcon className="min-w-[40px]">
        <Forum className="text-uv-primary-0" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Text
            className="font-secondary text-uv-primary-0 tracking-wider"
            size="M"
          >
            Sala de reunión
          </Text>
        }
      />
    </ListItemButton>
  )} */
