import Close from '@mui/icons-material/Close';
import PlaceRounded from '@mui/icons-material/PlaceRounded';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Text from 'components/design-system/Text/Text';
import { useContext } from 'react';
import { MatchContext } from 'views/Marketplace/Residuos/context/MatchContext';

export const EmpresaATDetalle = () => {
  const { sucursalATSeleccionada, setSucursalATSeleccionada } =
    useContext(MatchContext);

  if (!sucursalATSeleccionada) {
    return;
  }

  return (
    <div className="relative lg:absolute z-[1000] lg:top-3 lg:left-3">
      <div className="w-full shadow-md bg-white">
        <div className="h-[80vh] lg:h-[48vh] scrollbar-uv overflow-y-auto p-4 lg:min-w-[22rem] md:min-w-[22rem] lg:max-w-[22rem]">
          <div>
            <div className="relative">
              <Heading type="h3" className="pr-10 mb-2">
                {sucursalATSeleccionada.razonSocial
                  ? sucursalATSeleccionada.razonSocial
                  : 'Nombre no definido'}
                <div className="absolute -right-1 top-0">
                  <IconButtonComponent
                    onClick={() => setSucursalATSeleccionada(null)}
                    size="small"
                    type="neutral"
                  >
                    <Close />
                  </IconButtonComponent>
                </div>
              </Heading>
              <div className="relative">
                <span className="pl-8 block font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase">
                  Sucursal
                </span>
                <div className="flex items-center">
                  <PlaceRounded className="mr-2 text-uv-secondary-10" />
                  <Text>
                    {sucursalATSeleccionada.direccion ? (
                      <>
                        {sucursalATSeleccionada.direccion?.nombreVia}
                        {', '}
                        {sucursalATSeleccionada.direccion?.comuna}
                        {',  Región '}
                        {sucursalATSeleccionada.direccion?.region}
                      </>
                    ) : (
                      'Dirección no definida'
                    )}
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4 mt-6">
            <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
              RUBRO
            </span>
            <Text className="text-black">{sucursalATSeleccionada.rubro}</Text>
          </div>

          <div className="mb-4">
            <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5 uppercase">
              Actividad económica
            </span>
            <Text className="text-black">
              {sucursalATSeleccionada.actividad}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
