import { Skeleton } from '@mui/material';

export const SkeletonSucursales = () => {
  return (
    <div className="mb-5">
      <div className="bg-white rounded shadow-md">
        <div className="grid grid-cols-12 p-5 gap-4 items-center">
          <div className="col-span-3">
            <Skeleton
              variant="rounded"
              className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>
          <div className="col-span-6">
            <Skeleton
              variant="rounded"
              className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>
          <div className="col-span-3 text-end">
            <Skeleton
              variant="rounded"
              className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
