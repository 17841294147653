import { Skeleton } from '@mui/material';
import Heading from 'components/design-system/Heading/Heading';

export const SkeletonPerfiles = ({ tipoForm }) => {
  const row2 = [0, 1];
  const minrow2 = [0, 1];
  const row3 = [0, 1, 2];

  return (
    <div className="bg-white rounded p-5 shadow-sm mb-5">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          {/* Recurso Adquirido */}
          <div className="col-span-12  mb-4">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-12">
                <Heading type="h4" className="mb-0">
                  Perfiles
                </Heading>
              </div>
              <div className="col-span-12 xs:col-span-12">
                <div className="grid grid-cols-12 gap-12">
                  <div className="col-span-12 xs:col-span-12">
                    <Skeleton
                      variant="rounded"
                      className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                    />
                  </div>

                  <div className="col-span-12 xs:col-span-12">
                    <Skeleton
                      variant="rounded"
                      className="w-full h-32 rounded relative z-0 flex justify-center items-center mt-3"
                    />
                  </div>

                  <div className="col-span-12 xs:col-span-12">
                    <Skeleton
                      variant="rounded"
                      className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
