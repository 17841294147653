import { useEffect } from 'react';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import { TooltipComponent } from 'components/design-system/Tooltip/Tooltip';
import CheckboxComponent from 'components/design-system/Checkbox/Checkbox';

const CamposPerfiles = ({
  // Formik
  errors,
  handleBlur,
  handleChange,
  handleReset,
  handleSubmit,
  isSubmitting,
  setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  validateForm,
  values,
  tipoForm,

  // Dependencias
  permisos,
  isLoadingPermisos
}) => {
  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Heading type="h4" className="mb-0">
            Formulario de Perfiles
          </Heading>
        </div>
        <div className="col-span-12 xs:col-span-12 mb-12">
          <div className="grid grid-cols-12 gap-4">
            {/* Nombre */}
            <div className="col-span-12">
              <InputComponent
                autoComplete="off"
                diabled={isSubmitting}
                name={'nombrePerfil'}
                value={values.nombrePerfil || ''}
                fullWidth
                onChange={handleChange}
                onBlur={() => {
                  setFieldTouched('nombrePerfil');
                }}
                touched={touched.nombrePerfil}
                placeholder=""
                estado={
                  errors.nombrePerfil
                    ? { tipo: 'error', mensaje: errors.nombrePerfil }
                    : null
                }
                label={
                  <LabelComponent className="w-full">
                    Nombre <br />
                    {/* <span className="text-neutral-60">(opcional)</span> */}
                    <TooltipComponent
                      className="float-right"
                      title={''}
                    ></TooltipComponent>
                  </LabelComponent>
                }
              />
            </div>
            {/* Descripción */}
            <div className="col-span-12">
              <InputComponent
                autoComplete="off"
                diabled={isSubmitting}
                name={'descripcionPerfil'}
                type="text"
                fullWidth
                multiline
                value={values.descripcionPerfil || ''}
                rows={6}
                size={'small'}
                onChange={handleChange}
                onBlur={() => {
                  setFieldTouched('descripcionPerfil');
                }}
                touched={touched.descripcionPerfil}
                placeholder=""
                estado={
                  errors.descripcionPerfil
                    ? { tipo: 'error', mensaje: errors.descripcionPerfil }
                    : null
                }
                label={<LabelComponent>Descripción</LabelComponent>}
              />
            </div>
            {/* Permisos */}
            <div className="col-span-12">
              <AutocompleteComponent
                id="permisos"
                disableCloseOnSelect
                clearOnEscape={true}
                disabled={isSubmitting || isLoadingPermisos}
                fullWidth
                limitTags={4}
                multiple
                name={'permisos'}
                options={permisos}
                value={values.permisos}
                onBlur={() => setFieldTouched('permisos', true)}
                onChange={(ev, permisoSelected, reason) => {
                  let data = {
                    name: 'permisos',
                    value: permisoSelected
                  };
                  if (reason === 'clear') {
                    data.value = [];
                  }
                  setFieldValue('permisos', data.value);
                }}
                accesor="nombrePermiso"
                getOptionLabel={option => option.nombrePermiso || ''}
                noOptionsText={
                  isLoadingPermisos ? 'Cargando...' : 'Sin opciones'
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <CheckboxComponent
                      size="small"
                      className="py-1"
                      checked={selected}
                    />
                    {option.nombrePermiso}
                  </li>
                )}
              >
                <InputComponent
                  autoComplete="off"
                  disabled={isSubmitting || isLoadingPermisos}
                  fullWidth
                  touched={touched.permisos}
                  estado={
                    errors.permisos
                      ? { tipo: 'error', mensaje: errors.permisos }
                      : null
                  }
                  label={<LabelComponent>Permisos</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CamposPerfiles;
