import Heading from 'components/design-system/Heading/Heading';
import { useEditarCuenta } from './hooks/useEditarCuenta';
import Text from 'components/design-system/Text/Text';
import { CuentaSkeleton } from './CuentaSkeleton';
import { Form, Formik } from 'formik';
import { schemaEditarCuenta } from './schema/schemaCuenta';
import { CuentaCampos } from './CuentaCampos';
import { Divider } from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { useHistory } from 'react-router-dom';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { ChevronLeft } from '@mui/icons-material';
import { DICTONARY } from 'const/Dictonary';
import { useParams } from 'react-router-dom';
import { AlertaError } from 'utils/Alertas';

const EditarUsuarios = () => {
  const { codigoUsuario } = useParams();
  const isNuevoUsuario = codigoUsuario === 'nuevo';

  const {
    isLoading,
    isLoadingSucursales,
    isLoadingEmpresas,

    imagenPerfil,
    empresas,
    sucursales,

    valorInicial,
    onSubmit,
    handleObtenerSucursales
  } = useEditarCuenta(isNuevoUsuario);

  const navigate = useHistory();

  return (
    <SidebarLayout>
      <div>
        <div className="mb-4">
          <Heading type="h2" className="mb-0">
            <IconButtonComponent
              onClick={() => navigate.goBack()}
              type="secondary"
              className="mr-2"
            >
              <ChevronLeft />
            </IconButtonComponent>
            {isNuevoUsuario ? 'Nuevo usuario' : 'Editar usuario'}
          </Heading>
          <Text className="mb-0">
            {isNuevoUsuario
              ? 'Completa el formulario para crear un nuevo usuario'
              : 'Actualiza la información personal o de contacto del usuario'}
          </Text>
        </div>
        <div className="bg-white rounded shadow-md p-5 mb-6">
          {isLoading ? (
            <CuentaSkeleton />
          ) : (
            <Formik
              initialValues={valorInicial()}
              validationSchema={schemaEditarCuenta}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={async (values, { setSubmitting, setFieldValue }) => {
                try {
                  await onSubmit(values);
                  setFieldValue('files', []);
                  setSubmitting(false);
                } catch (error) {
                  AlertaError('Ha ocurrido un error al actualizar el usuario');
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
                handleChange,
                handleBlur
              }) => (
                <Form id="editarUsuario">
                  <CuentaCampos
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    isSubmitting={isSubmitting}
                    isLoadingSucursales={isLoadingSucursales}
                    isLoadingEmpresas={isLoadingEmpresas}
                    handleObtenerSucursales={handleObtenerSucursales}
                    imagenPerfil={imagenPerfil}
                    empresas={empresas}
                    sucursales={sucursales}
                    isNuevoUsuario={isNuevoUsuario}
                  />
                  <div className="col-span-12 my-5">
                    <Divider />
                  </div>

                  <div className="col-span-12 justify-between">
                    <div className="text-end">
                      <ButtonComponent
                        form="editarUsuario"
                        buttonType="submit"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        className="w-full xs:w-auto justify-center"
                      >
                        {isNuevoUsuario ? (
                          'Crear usuario'
                        ) : (
                          <>
                            {isSubmitting
                              ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD
                                  .EDITAR_IS_LOADING
                              : DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR}
                          </>
                        )}
                      </ButtonComponent>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </SidebarLayout>
  );
};

export default EditarUsuarios;
