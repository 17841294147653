import React, { useContext } from 'react';
import Home from '@mui/icons-material/Home';
import { DICTONARY } from 'const/Dictonary';
import {
  Dashboard,
  HubOutlined,
  Link,
  ManageHistory
} from '@mui/icons-material';
import { PERMISOS } from 'const/Permisos';
import { AuthContext } from 'context';
import LinkComponent from 'components/design-system/Link/Link';
import { ContenidoRestringido } from 'components/design-system/ContenidoRestringido/ContenidoRestringido';
import Text from 'components/design-system/Text/Text';
import { SIDEBAR_MENU } from 'layouts/Sidebar/SidebarMenus';
import Sidebar from 'layouts/Sidebar/Sidebar';
import TrendingUp from '@mui/icons-material/TrendingUp';
import { RUTAS } from 'const/Rutas';

const ResponsiveMenu = () => {
  const {
    perfiles,
    logeduser,
    tipos,
    isTienePermisos,
    perfiles: { isSuperAdmin }
  } = useContext(AuthContext);
  const codigoEmpresa = logeduser?.sucursal
    ? logeduser.sucursal.empresa.codigoEmpresa
    : null;

  const tieneNegocio = logeduser?.sucursal ? true : false;
  const MENU_ADMINISTRACION = SIDEBAR_MENU(
    { ...perfiles, ...tipos },
    codigoEmpresa,
    tieneNegocio
  );

  const MENU_NAVEGACION = [
    {
      section: 'Navegación',
      enabled: true,
      items: [
        {
          nombre: 'Inicio',
          icon: <Home className="text-uv-primary-0" />,
          path: RUTAS.HOME_USER,
          subItems: [],
          enabled: true
        },
        {
          nombre: 'Market Place',
          icon: <HubOutlined className="text-uv-primary-0" />,
          subItems: [
            {
              nombre: 'Buscar',
              path: RUTAS.MARKETPLACE_RESIDUOS,
              enabled: isTienePermisos(PERMISOS.busquedaResiduoRecurso)
            },
            {
              nombre: 'Transacciones',
              path: RUTAS.TRANSACCIONES,
              enabled: isTienePermisos(PERMISOS.transacciones)
            },
            {
              nombre: 'Publicar Residuo-recurso',
              path: RUTAS.RESIDUOS,
              enabled: isTienePermisos(PERMISOS.residuoRecurso)
            }
          ],
          enabled:
            isTienePermisos(PERMISOS.busquedaResiduoRecurso) ||
            isTienePermisos(PERMISOS.transacciones) ||
            isTienePermisos(PERMISOS.residuoRecurso)
        },
        {
          nombre: 'Gestiona',
          icon: <ManageHistory className="text-uv-primary-0" />,
          subItems: [
            {
              contenidoAdicional: (
                <div className="col-span-12 max-w-[248px]">
                  <ContenidoRestringido>
                    <Text size="S">
                      Solo{' '}
                      <b className="text-black">
                        usuarios con negocios registrados
                      </b>{' '}
                      pueden acceder a las opciones de reportes.
                      <LinkComponent
                        href={RUTAS.REGISTRAR_NEGOCIO}
                        className="ml-2"
                      >
                        Registrar un negocio
                      </LinkComponent>
                    </Text>
                  </ContenidoRestringido>
                </div>
              ),
              path: RUTAS.REGISTRAR_NEGOCIO,
              enabled: !tieneNegocio && !isSuperAdmin
            },
            {
              nombre: 'Recurso Adquirido',
              path: RUTAS.GESTIONA_RECURSO_ADQUIRIDO,
              enabled: isTienePermisos(PERMISOS.recursoAdquirido)
            },
            {
              nombre: 'Residuo Declarado',
              path: RUTAS.GESTIONA_RESIDUO_DECLARADO,
              enabled: isTienePermisos(PERMISOS.residuoDeclarado)
            },
            {
              nombre: 'Calculadora Indicadores',
              path: RUTAS.FORMULARIO_CALCULO_INDICADORES,
              enabled: isTienePermisos(PERMISOS.calculadoraIndicadores)
            }
          ],
          enabled:
            isTienePermisos(PERMISOS.recursoAdquirido) ||
            isTienePermisos(PERMISOS.residuoDeclarado) ||
            isTienePermisos(PERMISOS.calculadoraIndicadores) ||
            (!tieneNegocio && isTienePermisos(PERMISOS.registrarNegocio))
        },
        {
          nombre: 'Reportes',
          icon: <TrendingUp className="text-uv-primary-0" />,
          subItems: [
            {
              contenidoAdicional: (
                <div className="col-span-12 max-w-[248px]">
                  <ContenidoRestringido>
                    <Text size="S">
                      Solo{' '}
                      <b className="text-black">
                        usuarios con negocios registrados
                      </b>{' '}
                      pueden acceder a las opciones de reportes.
                      <LinkComponent
                        href={RUTAS.REGISTRAR_NEGOCIO}
                        className="ml-2"
                      >
                        Registrar un negocio
                      </LinkComponent>
                    </Text>
                  </ContenidoRestringido>
                </div>
              ),
              path: RUTAS.REGISTRAR_NEGOCIO,
              enabled: !tieneNegocio && !isSuperAdmin
            },
            {
              nombre: 'Indicadores',
              path: isSuperAdmin
                ? RUTAS.ADMINISTRAR_INDICADORES
                : `${RUTAS.NEGOCIO}/${logeduser.sucursal?.empresa?.codigoEmpresa}/indicadores`,
              enabled: isTienePermisos(PERMISOS.indicadoresOnline)
            },
            {
              nombre: 'Descargar reportes',
              path: RUTAS.EXPORTAR_REPORTES,
              enabled: isTienePermisos(PERMISOS.descargarReportes)
            }
          ],
          enabled:
            isTienePermisos(PERMISOS.indicadoresOnline) ||
            isTienePermisos(PERMISOS.descargarReportes) ||
            (!tieneNegocio && isTienePermisos(PERMISOS.registrarNegocio))
        },
        {
          nombre: 'Tutoriales',
          icon: <Link className="text-uv-primary-0" />,
          subItems: [
            {
              nombre: 'Buscar material de información',
              path: RUTAS.MATERIAL_DIFUSION,
              enabled: isTienePermisos(PERMISOS.buscarMaterialInformacion)
            },
            {
              nombre: 'Subir material de información',
              path: RUTAS.MATERIAL_DIFUSION_CARGAR,
              enabled: isTienePermisos(PERMISOS.cargarMaterialInformacion)
            },
            {
              nombre: 'Ver tutoriales',
              onClick: () =>
                window.open(DICTONARY.URL.PORTAL_DIFUSION_VIDEOS, '_blank'),
              enabled: true
            },
            {
              nombre: 'Portal de información',
              onClick: () =>
                window.open(DICTONARY.URL.PORTAL_DIFUSION, '_blank'),
              enabled: true
            }
          ],
          enabled: true
        },
        {
          nombre: 'Análisis Territorial',
          icon: <Dashboard className="text-uv-primary-0" />,
          subItems: [
            {
              nombre: 'Análisis línea base',
              path: RUTAS.ANALISIS_TERRITORIAL_BASE,
              enabled: isTienePermisos(PERMISOS.lineaBase)
            },
            {
              nombre: 'Potencial de Sinergías',
              path: RUTAS.ANALISIS_TERRITORIAL_SINERGIAS,
              enabled: isTienePermisos(PERMISOS.potencialSinergia)
            },
            {
              nombre: 'Transacciones',
              path: RUTAS.TRANSACCIONES,
              enabled: isTienePermisos(PERMISOS.transaccionesAT)
            }
          ],
          enabled:
            isTienePermisos(PERMISOS.lineaBase) ||
            isTienePermisos(PERMISOS.potencialSinergia) ||
            isTienePermisos(PERMISOS.transaccionesAT)
        }
      ]
    }
  ];

  return (
    <div className="py-4">
      <Sidebar className="px-4" menu={MENU_NAVEGACION} />
      <Sidebar className="px-4" menu={MENU_ADMINISTRACION} />
    </div>
  );
};

export default ResponsiveMenu;
