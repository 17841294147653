import Text from 'components/design-system/Text/Text';
import iconReduccionCambioClimatico from 'assets/images/reportes/reduccion-cambio-climatico.svg';
import iconReduccionDemandaAcumulada from 'assets/images/reportes/reduccion-demanda-acumulada.svg';
import iconReduccionFormacionMaterial from 'assets/images/reportes/reduccion-formacion-material.svg';
import iconReduccionRecursoFosil from 'assets/images/reportes/reduccion-recurso-fosil.svg';
import iconHorasDeTrabajo from 'assets/images/reportes/horas-de-trabajo.svg';
import iconSalario from 'assets/images/reportes/salario.svg';
import iconParidadMujeres from 'assets/images/reportes/paridad-mujeres.svg';

export const sinIndicadores = [
  {
    heading: 'Reducción impactos ambientales',
    items: [
      {
        image: iconReduccionCambioClimatico,
        imageAlt: 'Reduccion Recurso Fosil',
        heading: (
          <Text>
            Cambio
            <br />
            climático
          </Text>
        ),
        description: 'kg CO2eq',
        element: '15'
      },
      {
        image: iconReduccionRecursoFosil,
        imageAlt: 'Reduccion Recurso Fosil',
        heading: (
          <Text>
            Agotamiento de
            <br />
            recurso fósil
          </Text>
        ),
        description: 'kg petróleo eq',
        element: '30'
      },
      {
        image: iconReduccionFormacionMaterial,
        imageAlt: 'Reduccion Formacion Material',
        heading: (
          <Text>
            Formación de
            <br />
            material particulado
          </Text>
        ),
        description: 'kgMP 2,5eq',
        element: '125'
      },
      {
        image: iconReduccionDemandaAcumulada,
        imageAlt: 'Reduccion Demanda Acumulada',
        heading: (
          <Text>
            Demanda acumulada
            <br />
            de energía
          </Text>
        ),
        description: 'MJ eq',
        element: '19'
      }
    ]
  },

  {
    heading: 'Indicadores Sociales',
    items: [
      {
        image: iconHorasDeTrabajo,
        imageAlt: 'Horas de trabajo',
        heading: <Text>Horas de trabajo</Text>,
        description: 'H totales',
        element: '340'
      },
      {
        image: iconParidadMujeres,
        imageAlt: 'Paridad Mujeres',
        heading: <Text>Paridad Mujeres</Text>,
        description: 'HM',
        element: '280'
      },
      {
        image: iconSalario,
        imageAlt: 'Salario',
        heading: <Text>Salario</Text>,
        description: 'Mil pesos',
        element: '750'
      }
    ]
  }
];
