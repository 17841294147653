const desplegarIndice = valorIndice => {
  if(!valorIndice){ 
    return <div className="indice rango4">0</div>
  }

  let largoIndice = valorIndice.toString().length;

  if(valorIndice.toString() === "0.0"){
    return <div className="indice rango1 font-primary">0</div>;
  }

  if (largoIndice <= 14) {
    return <div className="indice rango1 font-primary">{valorIndice}</div>;
  }

  if (largoIndice <= 18) {
    return <div className="indice rango2">{valorIndice}</div>;
  }

  if (largoIndice <= 24) {
    return <div className="indice rango3">{valorIndice}</div>;
  }

  if (largoIndice > 30) {
    return <div className="indice rango4">{valorIndice}</div>;
  }
};

export const Cifra = ({ valor }) => {
  return (
    <div>
      {desplegarIndice(valor)}
    </div>
  );
};
