import { useEffect, useMemo, useState } from 'react';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
import {
  handleKeyPress,
  handlePaste
} from 'views/Business/utils/handleKeyPress';
import { useDireccion } from 'hooks/useDireccion';
import MapAddress from 'components/MapAddress/MapAddress';
import InputNumber from 'components/design-system/input/InputNumber';
import { DICTONARY } from 'const/Dictonary';
import { CircularProgress } from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import { GpsOff, MyLocation } from '@mui/icons-material';
import { AutoCompleteAsyncController } from 'components/design-system/AutoCompleteAsync/AutoCompleteAsyncController';
import {
  obtenerEmpresaReceptor,
  obtenerEmpresasReceptoras
} from 'views/CargaMasiva/hooks/useServices';
import Add from '@mui/icons-material/Add';
import { isValorValido } from 'helpers/validaCampos';

const Receptor = ({
  // Formik
  errors,
  handleChange,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
  // Empresas SII
  cargaEmpresasSIIReceptor,
  empresasSIIReceptor,
  isLoadingEmpresasSIIReceptor,
  // Tipo de Tratamiento
  tipoTratamiento,
  isLoadingTipoTratamiento,
  // Tipo Formulario
  tipoForm
}) => {
  const [razonSocialEmpresaReceptor, setRazonSocialEmpresaReceptor] = useState(
    values.receptorRazonSocial || ''
  );

  //Si el formulario es un ingreso y no se ha ingresado ningun dato previamente de declaración mostraré la opción
  //de carga de un nuevo generador.
  const [isNuevo, setIsNuevo] = useState(
    tipoForm === DICTONARY.TIPO_FORMULARIO.ingresar &&
      !(
        values.nombreEmpresaReceptor ||
        values.rutEmpresaReceptor ||
        values.receptorRazonSocial ||
        values.regionEmpresaReceptor ||
        values.comunaEmpresaReceptor ||
        values.nombreViaEmpresaReceptor ||
        values.latitudDireccionEmpresaReceptor ||
        values.longitudDireccionEmpresaReceptor ||
        values.tipoTratamiento ||
        values.numeroGuiaDespacho ||
        values.codigoSINADER
      )
      ? null
      : false
  );

  const [IsLoadingEmpresa, setIsLoadingEmpresa] = useState(false);

  const {
    latLon,
    setLatLon,
    addressQuerysearch,
    addressQueryParams,
    regiones,
    cargaListaRegiones,
    handleObtenerDireccion,
    handleObtenerComunas,
    listadoComunas,
    cargaListaComunas,
    setBuscandoDireccion,
    buscandoDireccion
  } = useDireccion();

  useEffect(() => {
    if (!empresasSIIReceptor) return;
    if (empresasSIIReceptor.razonSocial) {
      setFieldValue('receptorRazonSocial', empresasSIIReceptor.razonSocial);
      setRazonSocialEmpresaReceptor(empresasSIIReceptor.razonSocial);
      if (touched.rutEmpresaReceptor) {
        setFieldValue('nombreEmpresaReceptor', empresasSIIReceptor.razonSocial);
      }
    } else {
      setRazonSocialEmpresaReceptor('Sin información');
    }
  }, [empresasSIIReceptor, isLoadingEmpresasSIIReceptor]);

  // Carga RUT al ingresar al formulario
  useMemo(() => {
    if (values.rutEmpresaReceptor) {
      cargaEmpresasSIIReceptor(values.rutEmpresaReceptor);
    }
  }, [cargaEmpresasSIIReceptor, values.rutEmpresaReceptor]);

  //  Servicio Comunas
  useMemo(() => {
    handleObtenerComunas(values.regionEmpresaReceptor?.codigoRegion || null);
  }, [handleObtenerComunas, values.regionEmpresaReceptor]);

  // Estados de carga de servicios
  useMemo(() => {
    if (isSubmitting) return;
    handleObtenerDireccion({
      region: values.regionEmpresaReceptor,
      comuna: values.comunaEmpresaReceptor,
      nombreVia: values.nombreViaEmpresaReceptor,
      latitudDireccion: values.latitudDireccionEmpresaReceptor,
      longitudDireccion: values.longitudDireccionEmpresaReceptor
    });
  }, [isSubmitting, handleObtenerDireccion, values]);

  useMemo(() => {
    const {
      nombreViaEmpresaReceptor,
      comunaEmpresaReceptor,
      regionEmpresaReceptor,
      isPointEditing
    } = values;

    if (isSubmitting || !latLon) return;

    const hasNombreVia =
      nombreViaEmpresaReceptor && touched.nombreViaEmpresaReceptor;
    const hasComuna = comunaEmpresaReceptor && touched.comunaEmpresaReceptor;
    const hasRegion = regionEmpresaReceptor && touched.regionEmpresaReceptor;

    if (hasNombreVia || hasComuna || hasRegion || isPointEditing) {
      setBuscandoDireccion(true);
      setFieldValue('latitudDireccionEmpresaReceptor', latLon[0]);
      setFieldValue('longitudDireccionEmpresaReceptor', latLon[1]);
    }
  }, [
    isSubmitting,
    latLon,
    setBuscandoDireccion,
    setFieldValue,
    touched.comunaEmpresaReceptor,
    touched.nombreViaEmpresaReceptor,
    touched.regionEmpresaReceptor,
    values
  ]);

  useEffect(() => {
    const fecthEmpresaReceptor = async codigoEmpresaReceptor => {
      const empresaReceptorCargada = await obtenerEmpresaReceptor({
        codigoEmpresaReceptor: codigoEmpresaReceptor,
        setIsLoading: setIsLoadingEmpresa
      });
      if (empresaReceptorCargada) {
        if (isValorValido(empresaReceptorCargada.nombreEmpresa)) {
          setFieldValue(
            'nombreEmpresaReceptor',
            empresaReceptorCargada.nombreEmpresa
          );
        }

        if (isValorValido(empresaReceptorCargada.rut)) {
          setFieldValue('rutEmpresaReceptor', empresaReceptorCargada.rut);
        }

        if (isValorValido(empresaReceptorCargada.direccion)) {
          setFieldValue(
            'regionEmpresaReceptor',
            empresaReceptorCargada.direccion?.comuna?.region
          );
          setFieldValue(
            'comunaEmpresaReceptor',
            empresaReceptorCargada.direccion?.comuna
          );
          setFieldValue(
            'nombreViaEmpresaReceptor',
            empresaReceptorCargada.direccion?.nombreVia
          );
          setFieldValue(
            'latitudDireccionEmpresaReceptor',
            empresaReceptorCargada.direccion?.latitudDireccion
          );
          setFieldValue(
            'longitudDireccionEmpresaReceptor',
            empresaReceptorCargada.direccion?.longitudDireccion
          );
        }

        if (isValorValido(empresaReceptorCargada.codigoSINADER)) {
          setFieldValue('codigoSINADER', empresaReceptorCargada.codigoSINADER);
        }
      }
      setIsNuevo(false);
    };

    if (values.receptorPreCargado) {
      fecthEmpresaReceptor(values.receptorPreCargado.value);
    }
  }, [values.receptorPreCargado]);

  const limpiarCampos = () => {
    setFieldValue('rutEmpresaReceptor', null);
    setFieldValue('receptorPreCargado', null);
    setFieldValue('nombreEmpresaReceptor', null);
    setFieldValue('receptorRazonSocial', null);
    setFieldValue('regionEmpresaReceptor', null);
    setFieldValue('comunaEmpresaReceptor', null);
    setFieldValue('nombreViaEmpresaReceptor', null);
    setFieldValue('latitudDireccionEmpresaReceptor', null);
    setFieldValue('longitudDireccionEmpresaReceptor', null);
    setFieldValue('tipoTratamiento', null);
    setFieldValue('numeroGuiaDespacho', null);
    setFieldValue('codigoSINADER', null);
    setFieldValue('receptorRazonSocial', null);
    setRazonSocialEmpresaReceptor(null);
  };

  if (isNuevo === null) {
    return (
      <div className="col-span-12">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12">
            <Heading type="h4" className="mb-0">
              Receptor
            </Heading>
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-4">
            <AutoCompleteAsyncController
              name="receptorPreCargado"
              accesor="label"
              required
              fullWidth
              disabled={isSubmitting}
              label={
                <LabelComponent>
                  Selecciona un receptor ya ingresado
                </LabelComponent>
              }
              setFieldValue={data => setFieldValue('receptorPreCargado', data)}
              setFieldTouched={() => setFieldTouched('receptorPreCargado')}
              isSubmitting={isSubmitting}
              fetchCall={controllerValues =>
                obtenerEmpresasReceptoras({
                  setLoadingEmpresas: controllerValues.setIsLoading,
                  setEmpresas: controllerValues.setData,
                  paginationEmpresas: controllerValues.paginationEmpresas,
                  nombreEmpresa: controllerValues.inputValue,
                  setRowCountEmpresas: controllerValues.setRowCountEmpresas
                })
              }
            />
          </div>
          <div className="hidden md:block col-span-1">
            <div className="flex h-full justify-center items-center relative">
              <div className="border-l absolute w-0.5 h-full z-0"></div>
              <span className="bg-white p-2 relative z-10">O</span>
            </div>
          </div>
          <div className="col-span-12 md:col-span-5 xl:col-span-3">
            <div>
              <LabelComponent>Añadir un nuevo receptor</LabelComponent>
            </div>
            <ButtonComponent onClick={() => setIsNuevo(true)}>
              <Add className="mr-2" /> Nuevo receptor
            </ButtonComponent>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-4">
        {isNuevo ? (
          <div className="col-span-12 md:col-span-12 flex items-center gap-4">
            <Heading type="h4" className="mb-0">
              Añadir un nuevo receptor
            </Heading>
            <ButtonComponent
              type="secondary"
              onClick={() => {
                setIsNuevo(null);
                limpiarCampos();
              }}
            >
              Cancelar
            </ButtonComponent>
          </div>
        ) : (
          <div className="col-span-12 md:col-span-12 flex items-center gap-4">
            <Heading type="h4" className="mb-0">
              {values.nombreEmpresaReceptor
                ? values.nombreEmpresaReceptor
                : 'Añadir un nuevo receptor'}
            </Heading>
            <ButtonComponent
              type="secondary"
              onClick={() => {
                setIsNuevo(null);
                limpiarCampos();
              }}
            >
              Cancelar
            </ButtonComponent>
          </div>
        )}

        <div className="col-span-12 xs:col-span-12">
          <div className="grid grid-cols-12 gap-4">
            {/* Rut Empresa Receptor */}
            <div className="col-span-12 md:col-span-4">
              <InputComponent
                autoComplete="off"
                disabled={isSubmitting}
                fullWidth
                name={'rutEmpresaReceptor'}
                value={values.rutEmpresaReceptor || ''}
                onChange={event => {
                  const { value } = event.target;
                  if (
                    value.length >= 9 &&
                    value.charAt(value.length - 2) === '-'
                  ) {
                    setFieldValue('rutEmpresaReceptor', value.trim());
                    cargaEmpresasSIIReceptor(value);
                  } else {
                    setFieldValue('rutEmpresaReceptor', value.trim());
                    setRazonSocialEmpresaReceptor('Sin información');
                  }
                  setFieldTouched('regionEmpresaReceptor');
                  setFieldTouched('tipoTratamiento');
                }}
                onBlur={() => {
                  setFieldTouched('rutEmpresaReceptor');
                }}
                outerInputProps={{
                  inputProps: {
                    maxLength: 12,
                    onKeyPress: handleKeyPress
                  }
                }}
                placeholder="Ej: 12345678-9"
                onPaste={handlePaste}
                touched={touched.rutEmpresaReceptor}
                estado={
                  errors.rutEmpresaReceptor
                    ? { tipo: 'error', mensaje: errors.rutEmpresaReceptor }
                    : null
                }
                label={<LabelComponent esOpcional>Rut </LabelComponent>}
              />
            </div>
            {/* Razon Social */}
            {razonSocialEmpresaReceptor && (
              <div className="col-span-12 md:col-span-4">
                <LabelComponent
                  tooltipText={
                    <>
                      <b>La razón social</b> ha sido{' '}
                      <b>obtenida de forma automática</b> con la información
                      entregada por el{' '}
                      <b>Servicio de Impuestos Internos (SII).</b>
                    </>
                  }
                >
                  Razón social
                </LabelComponent>
                <div className="rounded bg-neutral-90 p-2.5 relative">
                  <Text className="min-h-[1.25rem]">
                    {empresasSIIReceptor &&
                    empresasSIIReceptor.razonSocial &&
                    !isLoadingEmpresasSIIReceptor
                      ? razonSocialEmpresaReceptor
                      : !touched.rutEmpresaReceptor &&
                        !errors.rutEmpresaReceptor
                      ? ''
                      : 'Sin información'}
                    {isLoadingEmpresasSIIReceptor &&
                      touched.rutEmpresaReceptor &&
                      !errors.rutEmpresaReceptor && (
                        <CircularProgress
                          color="inherit"
                          className="opacity-40 !w-5 !h-5 absolute right-2.5 top-2.5"
                        />
                      )}
                  </Text>
                </div>
              </div>
            )}
            {/* Nombre */}
            <div className="col-span-12 md:col-span-4">
              <InputComponent
                autoComplete="off"
                disabled={isSubmitting}
                fullWidth
                name={'nombreEmpresaReceptor'}
                value={values.nombreEmpresaReceptor || ''}
                onChange={handleChange}
                onBlur={() => {
                  setFieldTouched('nombreEmpresaReceptor');
                }}
                touched={touched.nombreEmpresaReceptor}
                estado={
                  errors.nombreEmpresaReceptor
                    ? { tipo: 'error', mensaje: errors.nombreEmpresaReceptor }
                    : null
                }
                label={
                  <LabelComponent
                    tooltipText={
                      <>
                        <b>Nombre de fantasía</b> que sirve para{' '}
                        <b>agrupar los residuos declarados</b> de esta empresa.
                        Si se obtuvo <b>el dato de razón social</b> este{' '}
                        <b>será sugerido como nombre de empresa</b>. Sin embargo
                        puede <b>editar el campo</b> e indicar otro nombre para
                        la empresa.
                      </>
                    }
                    esOpcional
                  >
                    Nombre empresa{' '}
                  </LabelComponent>
                }
              />
            </div>
            {/* Codigo Sinader */}
            <div className="col-span-12 md:col-span-4">
              <InputNumber
                autoComplete="off"
                disabled={isSubmitting}
                maxDecimals={DICTONARY.NUMERO_DECIMALES}
                name={'codigoSINADER'}
                placeholder={!values.codigoSINADER ? '' : ''}
                touched={touched.codigoSINADER}
                type="text"
                value={values.codigoSINADER_str || values.codigoSINADER || ''}
                onChange={event => {
                  const { value } = event.target;
                  const sanitizedValue = value
                    .replaceAll('.', '')
                    .replaceAll(',', '.');
                  const floatValue = parseFloat(sanitizedValue);
                  // maximo 10 digitos

                  if (!isNaN(floatValue)) {
                    setFieldValue('codigoSINADER', floatValue);
                  }
                }}
                onFocus={event => {
                  const { value } = event.target;
                  setFieldTouched('codigoSINADER');
                  if (
                    !event ||
                    !event.target ||
                    typeof event.target.value !== 'string'
                  ) {
                    return;
                  }
                  let sanitizedValue = value.split('.').join('') || '';
                  setFieldValue('codigoSINADER_str', sanitizedValue);
                }}
                onBlur={() => {
                  setFieldTouched('codigoSINADER');
                }}
                label={
                  <LabelComponent esOpcional>Código Sinader</LabelComponent>
                }
                estado={
                  errors.codigoSINADER
                    ? { tipo: 'error', mensaje: errors.codigoSINADER }
                    : null
                }
              />
            </div>
            {/* Tipo de Tratamiento */}
            <div className="col-span-12 md:col-span-4 xl:col-span-4">
              <AutocompleteComponent
                fullWidth
                accesor="nombreTipoTratamiento"
                clearOnEscape={true}
                disabled={isSubmitting || isLoadingTipoTratamiento}
                name="tipoTratamiento"
                touched={touched.tipoTratamiento}
                onBlur={() => setFieldTouched('tipoTratamiento')}
                value={values.tipoTratamiento || ''}
                onChange={(event, newValue, reason) => {
                  let data = { name: 'tipoTratamiento', value: newValue };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  setFieldValue('tipoTratamiento', data.value);
                }}
                options={tipoTratamiento || []}
                getOptionLabel={option => option.nombreTipoTratamiento || ''}
                noOptionsText={
                  isLoadingTipoTratamiento ? 'Cargando...' : 'Sin resultados'
                }
              >
                <InputComponent
                  fullWidth
                  autoComplete="off"
                  disabled={isSubmitting || isLoadingTipoTratamiento}
                  touched={touched.tipoTratamiento}
                  onBlur={() => {
                    setFieldTouched('tipoTratamiento');
                  }}
                  placeholder={
                    isLoadingTipoTratamiento
                      ? 'Cargando...'
                      : tipoTratamiento.length === 0 &&
                        !isLoadingTipoTratamiento
                      ? ''
                      : 'Seleccione una opción'
                  }
                  estado={
                    errors.tipoTratamiento
                      ? { tipo: 'error', mensaje: errors.tipoTratamiento }
                      : null
                  }
                  label={
                    <LabelComponent esOpcional>
                      Tipo de tratamiento
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>
            {/* Nº Guia de Despacho */}
            <div className="col-span-12 md:col-span-4 xl:col-span-4">
              <InputNumber
                autoComplete="off"
                disabled={isSubmitting}
                maxDecimals={DICTONARY.NUMERO_DECIMALES}
                name={'numeroGuiaDespacho'}
                placeholder={!values.numeroGuiaDespacho ? '' : ''}
                touched={touched.numeroGuiaDespacho}
                type="text"
                value={
                  values.numeroGuiaDespacho_str ||
                  values.numeroGuiaDespacho ||
                  ''
                }
                onChange={event => {
                  const { value } = event.target;
                  const sanitizedValue = value
                    .replaceAll('.', '')
                    .replaceAll(',', '.');
                  const floatValue = parseFloat(sanitizedValue);
                  if (!isNaN(floatValue)) {
                    setFieldValue('numeroGuiaDespacho', floatValue);
                  }
                }}
                onFocus={event => {
                  const { value } = event.target;
                  setFieldTouched('numeroGuiaDespacho');
                  if (
                    !event ||
                    !event.target ||
                    typeof event.target.value !== 'string'
                  ) {
                    return;
                  }
                  let sanitizedValue = value.split('.').join('') || '';
                  setFieldValue('numeroGuiaDespacho_str', sanitizedValue);
                }}
                onBlur={() => {
                  setFieldTouched('numeroGuiaDespacho');
                }}
                label={
                  <LabelComponent esOpcional>
                    Nº guía de despacho
                  </LabelComponent>
                }
                estado={
                  errors.numeroGuiaDespacho
                    ? { tipo: 'error', mensaje: errors.numeroGuiaDespacho }
                    : null
                }
              />
            </div>
            <div className="col-span-12 md:col-span-12 mt-4">
              <Heading type="h4" className="mb-0 flex gap-2 items-center">
                Dirección
                <Text className="text-uv-secondary-0">(opcional)</Text>
              </Heading>
            </div>
            {/* Region */}
            <div className="col-span-12 md:col-span-4 xl:col-span-3">
              <AutocompleteComponent
                name="regionEmpresaReceptor"
                clearOnEscape={true}
                accesor="nombreRegion"
                openOnFocus={true}
                options={regiones}
                disabled={!cargaListaRegiones}
                onChange={(ev, region, reason) => {
                  let data = { name: 'regionEmpresaReceptor', value: region };
                  if (reason === 'clear') {
                    setFieldValue('comunaEmpresaReceptor', null);
                    setFieldValue('regionEmpresaReceptor', null);
                  } else {
                    setFieldValue('comunaEmpresaReceptor', null);
                    setFieldValue('regionEmpresaReceptor', data.value);
                  }
                  setFieldTouched('regionEmpresaReceptor');
                  setFieldTouched('comunaEmpresaReceptor');
                  setFieldTouched('nombreViaEmpresaReceptor');
                }}
                onBlur={() => setFieldTouched('regionEmpresaReceptor')}
                value={values.regionEmpresaReceptor}
                getOptionLabel={option => option.nombreRegion || ''}
                isOptionEqualToValue={(option, value) =>
                  option.nombreRegion ===
                  values.regionEmpresaReceptor.nombreRegion
                }
              >
                <InputComponent
                  autoComplete={'off'}
                  fullWidth
                  touched={touched.regionEmpresaReceptor ? true : false}
                  estado={
                    errors.regionEmpresaReceptor
                      ? {
                          tipo: 'error',
                          mensaje: errors.regionEmpresaReceptor
                        }
                      : null
                  }
                  label={<LabelComponent>Región</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>

            {/* Comuna */}
            <div className="col-span-12 md:col-span-4 xl:col-span-3">
              <AutocompleteComponent
                name="comunaEmpresaReceptor"
                accesor="nombreComuna"
                options={listadoComunas}
                disabled={!cargaListaComunas}
                onChange={(ev, comuna, reason) => {
                  let data = { name: 'comunaEmpresaReceptor', value: comuna };
                  if (reason === 'clear') {
                    setFieldValue('regionEmpresaReceptor', null);
                    setFieldValue('comunaEmpresaReceptor', null);
                  } else {
                    setFieldValue('comunaEmpresaReceptor', data.value);
                    setFieldValue('regionEmpresaReceptor', data.value.region);
                  }
                }}
                value={values.comunaEmpresaReceptor}
                getOptionLabel={option => option.nombreComuna || ''}
                isOptionEqualToValue={(option, value) =>
                  option.nombreComuna ===
                  values.comunaEmpresaReceptor.nombreComuna
                }
                onBlur={() => setFieldTouched('comunaEmpresaReceptor')}
              >
                <InputComponent
                  autoComplete={'off'}
                  fullWidth
                  touched={touched.comunaEmpresaReceptor ? true : false}
                  estado={
                    errors.comunaEmpresaReceptor
                      ? {
                          tipo: 'error',
                          mensaje: errors.comunaEmpresaReceptor
                        }
                      : null
                  }
                  label={<LabelComponent>Comuna</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>
            {/* Tipo de Via */}
            <div className="col-span-12 md:col-span-4 xl:col-span-6">
              <InputComponent
                autoComplete={'off'}
                fullWidth
                label={<LabelComponent>Nombre y número calle</LabelComponent>}
                name="nombreViaEmpresaReceptor"
                value={values.nombreViaEmpresaReceptor}
                onChange={e => {
                  handleChange(e);
                  setFieldTouched('regionEmpresaReceptor');
                  setFieldTouched('comunaEmpresaReceptor');
                  setFieldTouched('nombreViaEmpresaReceptor');
                }}
                onBlur={() => setFieldTouched('nombreViaEmpresaReceptor')}
                touched={touched.nombreViaEmpresaReceptor ? true : false}
                placeholder="Ej: Providencia, 1091"
                estado={
                  errors.nombreViaEmpresaReceptor
                    ? {
                        tipo: 'error',
                        mensaje: errors.nombreViaEmpresaReceptor
                      }
                    : null
                }
              />
            </div>
            {/* Mapa */}
            <div className="col-span-12 xxs:col-span-12 xs:col-span-12 ">
              <div
                className={`${
                  values.isPointEditing ? ' bg-warning-light' : 'bg-info-light '
                }
                              sm:flex px-4 py-2.5 justify-between rounded items-center gap-4`}
              >
                <Text>
                  {values.isPointEditing
                    ? DICTONARY.MAPA_MENSAJES.INFO_DESACTIVAR_EDICION
                    : DICTONARY.MAPA_MENSAJES.INFO_ACTIVAR_EDICION}
                </Text>
                <ButtonComponent
                  className="min-w-[190px] md:min-w-fit mt-4 sm:mt-0"
                  onClick={() => {
                    setFieldValue('isPointEditing', !values.isPointEditing);
                  }}
                >
                  {values.isPointEditing && latLon ? (
                    <>
                      Desactivar edición <GpsOff className="ml-2" />
                    </>
                  ) : (
                    <>
                      Edición manual <MyLocation className="ml-2" />
                    </>
                  )}
                </ButtonComponent>
              </div>
            </div>
            <div className="col-span-12 rounded overflow-hidden">
              <MapAddress
                addressQuerysearch={addressQuerysearch}
                addressQueryParams={addressQueryParams}
                latLon={latLon}
                setLatLon={setLatLon}
                isPointEditing={values.isPointEditing}
                setIsPointEditing={value =>
                  setFieldValue('isPointEditing', value)
                }
                isLoading={isSubmitting}
                buscandoDireccion={buscandoDireccion}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receptor;
