import { useState } from 'react';

export const HandleMateriaPrima = () => {
  const [listasOtrosTiposRecursos, setListasOtrosTiposRecursos] = useState([]);

  const handleDeleteMateriaPrima =
    (materiaPrima, values, setFieldValue) => event => {
      setFieldValue('tieneOtroTipoRecurso', false);
      let materiasPrimas = values.otroTipoRecurso;

      materiasPrimas = materiasPrimas.filter(m => m !== materiaPrima);
      //  console.log('materiasPrimas delete :: ', materiaPrima);

      setFieldValue('otroTipoRecurso', materiasPrimas);
      setListasOtrosTiposRecursos(materiasPrimas);

      values.otroTipoRecurso == [] &&
        setFieldValue('tieneOtroTipoRecurso', false);
    };

  const handleAddOtraMateriaPrima = (values, setFieldValue) => {
    if (values._otraMateriaPrima.length < 3) return;
    if (!values._otraMateriaPrima) return;

    setFieldValue('tieneOtroTipoRecurso', true);

    setFieldValue('_otraMateriaPrima', '');

    // evitar duplicados  que devuelva true si existe
    const existe = listasOtrosTiposRecursos.some(
      item => item.nombreRecurso === values._otraMateriaPrima
    );

    if (existe) {
      console.log('El elemento ya existe en la lista.');
      return;
    } else {
      // console.log("El elemento no existe en la lista.");
      setListasOtrosTiposRecursos([
        ...listasOtrosTiposRecursos,
        { nombreRecurso: values._otraMateriaPrima }
      ]);

      setFieldValue('otroTipoRecurso', [
        ...listasOtrosTiposRecursos,
        { nombreRecurso: values._otraMateriaPrima }
      ]);
    }
  };

  const handleOtraMateriaKeyPress = (event, values, setFieldValue) => {
    if (event.key === 'Enter') {
      handleAddOtraMateriaPrima(values, setFieldValue);
    }
  };

  return {
    handleDeleteMateriaPrima,
    handleAddOtraMateriaPrima,
    handleOtraMateriaKeyPress,
    setListasOtrosTiposRecursos,
    listasOtrosTiposRecursos
  };
};

// solo oferentes
export const CalculoListaLer = () => {
  const [tieneSubtiposLer, setTieneSubtiposLer] = useState(true);
  const [listaLerNivelDos, setListaLerNivelDos] = useState([]);
  const [listaLerNivelTres, setListaLerNivelTres] = useState([]);

  const calculoListaSubLer = (listaLer, selected, setFieldValue) => {
    // console.log(selected);
    if (selected) {
      const newOptions = listaLer.filter(
        ler => ler.codigoNivelAnterior === selected.codigoLER
      );
      setListaLerNivelDos(newOptions);
      setTieneSubtiposLer(true);
    } else {
      setTieneSubtiposLer(false);
      setListaLerNivelDos([]);
      setFieldValue('codigoSubLER', '');
      setFieldValue('nombreSubLER', '');
      setListaLerNivelTres([]);
      setFieldValue('codigoSubSubLER', '');
      setFieldValue('nombreSubSubLER', '');
    }
  };
  return {
    calculoListaSubLer,
    listaLerNivelDos,
    listaLerNivelTres,
    tieneSubtiposLer,
    setListaLerNivelTres
  };
};

export const CalculoListaSubLer = () => {
  const calculoListaSubSubLer = (
    listaLer,
    selected,
    setFieldValue,
    setListaLerNivelTres,
    setTieneSubSubtiposLer
  ) => {
    if (selected) {
      const newOptions = listaLer.filter(
        ler => ler.codigoNivelAnterior === selected.codigoLER
      );

      setListaLerNivelTres(newOptions);
      setTieneSubSubtiposLer(true);
    } else {
      setTieneSubSubtiposLer(false);
      setListaLerNivelTres([]);
      setFieldValue('codigoSubSubLER', '');
      setFieldValue('nombreSubSubLER', '');
    }
  };

  return {
    calculoListaSubSubLer
  };
};
