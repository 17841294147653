import API from './api';

const transacciones = {
  estados: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/transacciones/estados',
      params: params,
      interceptor: conInterceptor
    });
  }
};

export default transacciones;
