import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Hidden
} from '@mui/material';
import AlertComponent from 'components/design-system/Alert/Alert';
import CheckboxComponent from 'components/design-system/Checkbox/Checkbox';
import Heading from 'components/design-system/Heading/Heading';
import SwitchComponent from 'components/design-system/Switch/Switch';
import Text from 'components/design-system/Text/Text';
import { memo, useCallback, useState } from 'react';
import { useResiduos } from '../hooks/useResiduos';

export const SucursalResiduos = ({
  setFieldValue,
  setFieldTouched,
  tipoResiduoSeleccionados
}) => {
  const { residuos, isLoading } = useResiduos(tipoResiduoSeleccionados);

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12">
        <Heading type="h3">Tipos de residuos</Heading>
        <Text className="mb-8">
          Selecciona los tipos de residuos del interés de tu organización.
        </Text>
      </div>

      <div className="col-span-12 md:col-span-9 mb-4">
        <AlertComponent type="warning">
          <Heading type="h5">Atención</Heading>
          <Text>
            Solo podrás <b className="text-black">obtener resultados </b>
            en el{' '}
            <b className="text-black">
              marketplace (match automático y filtro){' '}
            </b>
            basados en los <b className="text-black">datos seleccionados. </b>
          </Text>
        </AlertComponent>
      </div>

      {isLoading ? (
        <div className="bg-background-primary p-5 rounded w-full max-h-[360px] flex h-[20vh] items-center justify-center col-span-12">
          <div className="w-1/3 text-center">
            <CircularProgress
              thickness={4}
              size={60}
              className="text-uv-primary-70"
            />
          </div>
        </div>
      ) : (
        <ListadoResiduos
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          residuos={residuos}
        />
      )}
    </div>
  );
};

const ListadoResiduos = memo(({ residuos, setFieldValue, setFieldTouched }) => {
  const [todosSeleccionados, setTodosSeleccionados] = useState(false);
  const [residuoBox, setResiduoBox] = useState(residuos);

  const handleSeleccionarResiduo = useCallback(
    (residuo, valor) => {
      setResiduoBox(items => {
        const index = items.findIndex(
          ind => ind.codigoTipoResiduo === residuo.codigoTipoResiduo
        );
        let residuoAux = items[index];
        residuoAux.checked = valor;
        let obj = [
          ...items.slice(0, index),
          residuoAux,
          ...items.slice(index + 1)
        ];

        setFieldTouched('codigosTipoResiduo');
        setFieldValue('codigosTipoResiduo', obj);

        return obj;
      });
    },
    [setResiduoBox]
  );

  const handleChangeSeleccionarTodo = (event, value) => {
    setTodosSeleccionados(!todosSeleccionados);
    setResiduoBox(items => {
      let residuoAux = items.map(residuo => {
        return {
          ...residuo,
          checked: !todosSeleccionados
        };
      });
      setFieldTouched('codigosTipoResiduo');
      setFieldValue('codigosTipoResiduo', residuoAux);
      return residuoAux;
    });
  };

  return (
    <div className="col-span-12">
      <FormControlLabel
        className="my-3 mx-0"
        control={
          <SwitchComponent
            checked={todosSeleccionados}
            onChange={handleChangeSeleccionarTodo}
            name="seleccionartodo"
          />
        }
        label={
          <Text className="font-semibold">Seleccionar todos los residuos</Text>
        }
      />
      <div className="bg-background-primary p-5 rounded overflow-hidden w-full overflow-y-scroll max-h-[360px] scrollbar-uv">
        <FormGroup>
          {residuoBox &&
            residuoBox.map((item, key) => (
              <ItemResiduo
                key={key}
                item={item}
                isChecked={item.checked}
                handleSeleccionarResiduo={handleSeleccionarResiduo}
              />
            ))}
        </FormGroup>
      </div>
    </div>
  );
});

const ItemResiduo = memo(({ item, handleSeleccionarResiduo, isChecked }) => {
  const onSeleccionarResiduo = (residuo, value) => {
    handleSeleccionarResiduo(residuo, value);
  };

  return (
    <FormControlLabel
      className="w-full"
      control={
        <CheckboxComponent
          checked={isChecked}
          name="codigosTipoResiduo"
          value={item.codigoTipoResiduo}
          onChange={(ev, value) => {
            onSeleccionarResiduo(item, value);
          }}
        />
      }
      label={
        <div className="ml-2">
          <Hidden smUp>
            <Text size="S" className="mb-1 text-black">
              {item.nombreTipo}
            </Text>
          </Hidden>
          <Hidden smDown>
            <Heading type="h5" className="mb-1">
              {item.nombreTipo}
            </Heading>
          </Hidden>
        </div>
      }
    />
  );
});
