import React from 'react';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import IconComponent from 'components/design-system/icon/Icon';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

export const CuadroAdminResumen = ({
  resumen,
  resumenCuenta,
  isLoading,
  numColumn
}) => {
  return (
    <div className="mb-6">
      <div className="bg-white rounded shadow-md">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 bg-uv-primary-90 p-4">
            <div className="p-2 grid grid-cols-12 gap-4 items-center">
              {resumen.map((item, index) => (
                <React.Fragment key={index}>
                  <div
                    className={`col-span-12 sm:col-span-6 ${
                      item.enabled ? '' : 'hidden'
                    }`}
                  >
                    <span className="mb-2 block font-secondary text-xs text-gray-700 opacity-50 tracking-widest font-normal">
                      {item.titulo}
                    </span>
                    <div className="flex items-center">
                      <div className="w-12 h-12 rounded-full bg-white align-middle justify-center content-center items-center m-0  min-w-[48px]">
                        <IconComponent
                          color="primary"
                          className="text-[#2E8567]"
                        >
                          {item.icono}
                        </IconComponent>
                      </div>
                      {isLoading ? (
                        <Skeleton
                          variant="text"
                          className="w-full h-12 relative z-0 flex mx-2"
                        />
                      ) : (
                        <>
                          <Heading
                            type="H2"
                            className="text-black ml-3 mr-4 my-0"
                          >
                            {resumenCuenta[item.accesor]}
                          </Heading>
                          <Text className="text-base text-black leading-5 font-normal">
                            {item.descripcion}
                          </Text>
                        </>
                      )}
                    </div>
                  </div>

                  {(index + 1) % (numColumn || 3) === 0 &&
                    index + 1 < resumen.length && (
                      <>
                        {(index + 1) % (numColumn || 3) === 0 &&
                          index + 1 < resumen.length && (
                            <div
                              className={`col-span-12 ${
                                item.enabled ? '' : 'hidden'
                              }`}
                            >
                              <Divider className="my-0" />
                            </div>
                          )}
                      </>
                    )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
