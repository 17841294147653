import { useCallback, useMemo } from 'react';

const useCargaServiciosRecursosAdquiridos = ({
  ObtieneEmpresasSII,
  ObtieneEnvaseEmbalaje,
  ObtieneLevantamiento,
  ObtieneSucursales,
  ObtieneTipoTransporte,
  ObtieneTiposRecursosAdquiridos,
  ObtieneUnidadMedida,
}) => {

  // LLamadas a servicios
  const cargaSucursales = useCallback(async () => {
    await ObtieneSucursales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);// No hay dependencias intencionalmente

  const cargaTipoRecursosAdquiridos = useCallback(async () => {
    await ObtieneTiposRecursosAdquiridos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);// No hay dependencias intencionalmente

  const cargaLevantamiento = useCallback(async () => {
    await ObtieneLevantamiento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);// No hay dependencias intencionalmente

  const cargaUnidadMedida = useCallback(async () => {
    await ObtieneUnidadMedida();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);// No hay dependencias intencionalmente

  const cargaEmpresasSII = useCallback(async (rutEmpresa) => {
    await ObtieneEmpresasSII(rutEmpresa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);// No hay dependencias intencionalmente


  const cargaTipoTransporte = useCallback(async () => {
    await ObtieneTipoTransporte();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);// No hay dependencias intencionalmente

  const cargaEnvaseEmbalaje = useCallback(async () => {
    await ObtieneEnvaseEmbalaje();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);// No hay dependencias intencionalmente


  // Carga de datos

  useMemo(() => {
    cargaSucursales();
  }, [cargaSucursales]);

  useMemo(() => {
    cargaTipoRecursosAdquiridos();
  }, [cargaTipoRecursosAdquiridos]);

  useMemo(() => {
    cargaLevantamiento();
  }, [cargaLevantamiento]);

  useMemo(() => {
    cargaUnidadMedida();
  }, [cargaUnidadMedida]);


  useMemo(() => {
    cargaTipoTransporte();
  }, [cargaTipoTransporte]);

  useMemo(() => {
    cargaEnvaseEmbalaje();
  }, [cargaEnvaseEmbalaje]);

  return {
    cargaSucursales,
    cargaTipoRecursosAdquiridos,
    cargaLevantamiento,
    cargaUnidadMedida,
    cargaEmpresasSII,
    cargaTipoTransporte,
    cargaEnvaseEmbalaje,
  }
}

export default useCargaServiciosRecursosAdquiridos;
