import AlertComponent from 'components/design-system/Alert/Alert';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import CheckboxComponent from 'components/design-system/Checkbox/Checkbox';
import { Divider, FormControlLabel, FormGroup } from '@mui/material';
import { useMemo } from 'react';
import { DICTONARY } from 'const/Dictonary';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';

export const BusinessRole = ({
  values,
  errors,
  touched,
  onChange,
  handleBlur
}) => {
  const ROL_DISABLE = useMemo(() => {
    const DISABLED = {
      RECICLADOR: false,
      GENERADORRECEPTOR: false,
      GESTORES: false
    };

    DISABLED['RECICLADOR'] =
      values.codigoRolesEmpresa.includes(
        DICTONARY.ROL.TRANSPORTISTA.CODIGO.toString()
      ) ||
      values.codigoRolesEmpresa.includes(
        DICTONARY.ROL.GESTOR.CODIGO.toString()
      ) ||
      values.codigoRolesEmpresa.includes(
        DICTONARY.ROL.ASESOR.CODIGO.toString()
      ) ||
      values.codigoRolesEmpresa.includes(
        DICTONARY.ROL.GENERADOR.CODIGO.toString()
      ) ||
      values.codigoRolesEmpresa.includes(
        DICTONARY.ROL.RECEPTOR.CODIGO.toString()
      );

    DISABLED['GENERADORRECEPTOR'] =
      values.codigoRolesEmpresa.includes(
        DICTONARY.ROL.TRANSPORTISTA.CODIGO.toString()
      ) ||
      values.codigoRolesEmpresa.includes(
        DICTONARY.ROL.GESTOR.CODIGO.toString()
      ) ||
      values.codigoRolesEmpresa.includes(
        DICTONARY.ROL.ASESOR.CODIGO.toString()
      ) ||
      values.codigoRolesEmpresa.includes(
        DICTONARY.ROL.RECICLADORDEBASE.CODIGO.toString()
      );

    DISABLED['GESTORES'] =
      values.codigoRolesEmpresa.includes(
        DICTONARY.ROL.GENERADOR.CODIGO.toString()
      ) ||
      values.codigoRolesEmpresa.includes(
        DICTONARY.ROL.RECEPTOR.CODIGO.toString()
      ) ||
      values.codigoRolesEmpresa.includes(
        DICTONARY.ROL.RECICLADORDEBASE.CODIGO.toString()
      );

    return DISABLED;
  }, [values.codigoRolesEmpresa]);

  return (
    <>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <Heading type="h3">Rol del negocio</Heading>
          <Text className="mb-8">
            Selecciona el tipo de rol que deseas desempeñar en la plataforma.
          </Text>
        </div>

        <div className="col-span-12 md:col-span-9">
          <AlertComponent type="warning">
            <Heading type="h5">Atención</Heading>
            <Text>
              Esta opción{' '}
              <b className="text-black">
                no podrás editarla posteriormente y determina las
                funcionalidades en la plataforma
              </b>{' '}
              por lo que te recomendamos entender los límites y funcionalidades
              de cada una.{' '}
            </Text>
          </AlertComponent>
        </div>
      </div>

      <div className="mt-5 mb-2">
        <Text
          className={`${
            errors.codigoRolesEmpresa &&
            touched.codigoRolesEmpresa &&
            'text-error-dark'
          }`}
        >
          Selecciona{' '}
          <b
            className={`${
              errors.codigoRolesEmpresa && touched.codigoRolesEmpresa
                ? 'text-error-dark'
                : 'text-black'
            }`}
          >
            al menos un tipo de rol
          </b>{' '}
          de una categoría.
        </Text>
        <div className="mx-4 pt-4">
          <div className="border border-dashed h-4 w-4 border-neutral-60 rotate-45 absolute ml-16 -mt-2 border-b-0 border-r-0"></div>
          <div className="w-0 h-0 border-l-[16px] border-l-transparent border-r-[17px] border-r-transparent border-b-[16px] border-b-white absolute ml-14 -mt-2"></div>
          <div className="border border-dashed border-b-0 rounded-t-2xl h-3 border-neutral-70"></div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12 sm:col-span-4">
          <RoleCuadro errors={errors} touched={touched}>
            <FormGroup>
              <FormControlLabel
                control={
                  <CheckboxComponent
                    name="codigoRolesEmpresa"
                    value={DICTONARY.ROL.RECICLADORDEBASE.CODIGO}
                    onBlur={handleBlur}
                    onChange={onChange}
                    disabled={ROL_DISABLE.RECICLADOR}
                    checked={values.codigoRolesEmpresa.includes(
                      DICTONARY.ROL.RECICLADORDEBASE.CODIGO.toString()
                    )}
                  />
                }
                label={
                  <div className="ml-2">
                    <Heading disabled={ROL_DISABLE.RECICLADOR} type="h5">
                      <DICTONARY.ROL.RECICLADORDEBASE.ICONO className="text-uv-primary-0 mr-1" />
                      Reciclador de base
                    </Heading>
                    <Text>
                      {' '}
                      <b className="text-black">Buscar y ofrecer</b> residuos,
                      subproductos o materias primas secundarias (MPS) ya
                      clasificados y/o pre-tratados.
                    </Text>
                  </div>
                }
              />
            </FormGroup>
          </RoleCuadro>
        </div>

        <div className="col-span-12 sm:col-span-4">
          <RoleCuadro errors={errors} touched={touched}>
            <FormGroup>
              <FormControlLabel
                control={
                  <CheckboxComponent
                    name="codigoRolesEmpresa"
                    value={DICTONARY.ROL.GENERADOR.CODIGO}
                    onBlur={handleBlur}
                    onChange={onChange}
                    disabled={ROL_DISABLE.GENERADORRECEPTOR}
                    checked={values.codigoRolesEmpresa.includes(
                      DICTONARY.ROL.GENERADOR.CODIGO.toString()
                    )}
                  />
                }
                label={
                  <div className="ml-2">
                    <Heading disabled={ROL_DISABLE.GENERADORRECEPTOR} type="h5">
                      <DICTONARY.ROL.GENERADOR.ICONO className="text-oferente mr-1" />
                      Generador
                    </Heading>
                    <Text>
                      {' '}
                      <b className="text-black">Ofrecer</b> residuos,
                      subproductos o materias primas secundarias (MPS).
                    </Text>
                  </div>
                }
              />

              <div className="my-4">
                <Divider />
              </div>

              <FormControlLabel
                control={
                  <CheckboxComponent
                    name="codigoRolesEmpresa"
                    value={DICTONARY.ROL.RECEPTOR.CODIGO}
                    onBlur={handleBlur}
                    onChange={onChange}
                    disabled={ROL_DISABLE.GENERADORRECEPTOR}
                    checked={values.codigoRolesEmpresa.includes(
                      DICTONARY.ROL.RECEPTOR.CODIGO.toString()
                    )}
                  />
                }
                label={
                  <div className="ml-2">
                    <Heading disabled={ROL_DISABLE.GENERADORRECEPTOR} type="h5">
                      <DICTONARY.ROL.RECEPTOR.ICONO className="text-demandante mr-1" />
                      Receptor
                    </Heading>
                    <Text>
                      {' '}
                      <b className="text-black">Buscar</b> residuos,
                      subproductos o materias primas secundarias (MPS) para
                      incorporarlo a su proceso productivo.
                    </Text>
                  </div>
                }
              />
            </FormGroup>
          </RoleCuadro>
          <div className="text-center pt-2">
            <Text>Puedes seleccionar ambos tipos roles en esta categoría</Text>
          </div>
        </div>

        <div className="col-span-12 sm:col-span-4">
          <RoleCuadro errors={errors} touched={touched}>
            <FormGroup>
              <FormControlLabel
                control={
                  <CheckboxComponent
                    name="codigoRolesEmpresa"
                    value={DICTONARY.ROL.TRANSPORTISTA.CODIGO}
                    onChange={e => {
                      onChange(e);
                    }}
                    onBlur={handleBlur}
                    disabled={ROL_DISABLE.GESTORES}
                    checked={values.codigoRolesEmpresa.includes(
                      DICTONARY.ROL.TRANSPORTISTA.CODIGO.toString()
                    )}
                  />
                }
                label={
                  <div className="ml-2">
                    <Heading disabled={ROL_DISABLE.GESTORES} type="h5">
                      <DICTONARY.ROL.TRANSPORTISTA.ICONO className="text-gestor mr-1" />
                      Transportista
                    </Heading>
                    <Text>
                      {' '}
                      <b className="text-black">Conectar</b> con otros actores
                      para <b className="text-black">movilizar</b> los residuos,
                      subproductos o materias primas secundarias (MPS)
                      transaccionados.{' '}
                    </Text>
                  </div>
                }
              />

              <div className="my-4">
                <Divider />
              </div>

              <FormControlLabel
                control={
                  <CheckboxComponent
                    name="codigoRolesEmpresa"
                    value={DICTONARY.ROL.GESTOR.CODIGO}
                    onChange={onChange}
                    onBlur={handleBlur}
                    disabled={ROL_DISABLE.GESTORES}
                    checked={values.codigoRolesEmpresa.includes(
                      DICTONARY.ROL.GESTOR.CODIGO.toString()
                    )}
                  />
                }
                label={
                  <div className="ml-2">
                    <Heading disabled={ROL_DISABLE.GESTORES} type="h5">
                      <DICTONARY.ROL.GESTOR.ICONO className="text-gestor mr-1" />
                      Gestor
                    </Heading>
                    <Text>
                      {' '}
                      <b className="text-black">Conectar</b> con otros actores
                      para la{' '}
                      <b className="text-black">recepción y almacenamiento</b>{' '}
                      de residuos, subproductos o materias primas secundarias
                      (MPS) como{' '}
                      <b className="text-black">negocio autorizado.</b>{' '}
                    </Text>
                  </div>
                }
              />

              <div className="my-4">
                <Divider />
              </div>

              <FormControlLabel
                control={
                  <CheckboxComponent
                    name="codigoRolesEmpresa"
                    value={DICTONARY.ROL.ASESOR.CODIGO}
                    onChange={onChange}
                    disabled={ROL_DISABLE.GESTORES}
                    checked={values.codigoRolesEmpresa.includes(
                      DICTONARY.ROL.ASESOR.CODIGO.toString()
                    )}
                  />
                }
                label={
                  <div className="ml-2">
                    <Heading disabled={ROL_DISABLE.GESTORES} type="h5">
                      <DICTONARY.ROL.ASESOR.ICONO className="text-gestor mr-1" />
                      Asesor
                    </Heading>
                    <Text>
                      {' '}
                      <b className="text-black">Conectar</b> con generadores y/o
                      receptores para <b className="text-black">apoyar</b>{' '}
                      decisiones técnico-comerciales
                    </Text>
                  </div>
                }
              />
            </FormGroup>
          </RoleCuadro>
          <div className="text-center pt-2">
            <Text>
              Puedes seleccionar múltiples roles en esta misma categoría
            </Text>
          </div>
        </div>
      </div>

      {values.codigoRolesEmpresa.includes(
        DICTONARY.ROL.RECICLADORDEBASE.CODIGO.toString()
      ) && (
        <div className="col-span-12 mt-5">
          <Heading type="h3">Zona o área de trabajo</Heading>
          <InputComponent
            fullWidth
            label={
              <LabelComponent>
                Indica el área en el que trabaja el negocio
              </LabelComponent>
            }
            name="areaTrabajo"
            onChange={onChange}
            onBlur={handleBlur}
            value={values.areaTrabajo}
            touched={touched.areaTrabajo}
            estado={
              errors.areaTrabajo
                ? { tipo: 'error', mensaje: errors.areaTrabajo }
                : null
            }
          />
        </div>
      )}
    </>
  );
};

const RoleCuadro = ({ errors, touched, children }) => {
  return (
    <div
      className={`${
        errors.codigoRolesEmpresa && touched.codigoRolesEmpresa
          ? 'border-error-dark'
          : 'border-gray-200'
      } border rounded py-4 px-5`}
    >
      {children}
    </div>
  );
};

export default BusinessRole;
