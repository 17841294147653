import React, { useRef, useEffect, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { ForceGraph2D } from 'react-force-graph';
import { Add, Close, Info, MoreVert, Remove } from '@mui/icons-material';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { Divider, Skeleton } from '@mui/material';
import Text from 'components/design-system/Text/Text';
import { MensajeMapa } from '../LineaBase/MapaBase/MensajeMapa';

const GraficoNodos = ({
  graphData,
  isLoadingNodos,
  filtro,
  sectoresEconomicos
}) => {
  const [showSimbologia, setShowSimbologia] = useState(false);
  const graphRef = useRef();
  const refContainer = useRef();
  const [anchoContenedor, setAnchoContenedor] = useState(1280);
  const onlyWidth = useWindowWidth();
  const empresasSeleccionadas = [
    ...new Set([].concat(...Object.values(filtro)))
  ];
  const numeroNodosDestino =
    graphData && graphData.nodes && empresasSeleccionadas
      ? graphData.nodes.length - empresasSeleccionadas.length
      : 0;
  const numeroEmpresasSeleccionadas = empresasSeleccionadas
    ? empresasSeleccionadas.filter(item => item !== null).length
    : 0;

  const obtenerAnchoContenedor = () => {
    return theme.screens.find(key => window.innerWidth > key) - 32;
  };

  const theme = {
    screens: [1280, 1280, 1024, 768, 640, 480, 0]
  };

  useEffect(() => {
    setAnchoContenedor(obtenerAnchoContenedor());
  }, [onlyWidth]);

  const handleZoomIn = () => {
    graphRef.current.zoom(graphRef.current.zoom() * 1.3); // Aumentar el zoom en un 20%
  };

  const handleZoomOut = () => {
    graphRef.current.zoom(graphRef.current.zoom() / 1.3); // Reducir el zoom en un 20%
  };

  if (!graphData && !isLoadingNodos) {
    return (
      <div className="relative w-full h-[400px] items-center justify-center flex">
        <Text size="L" className="text-neutral-60">
          <Info className="mr-1" /> Para visualizar el gráfico presiona un
          registro de la tabla o selecciona una empresa en el filtro
        </Text>
      </div>
    );
  }

  if (isLoadingNodos) {
    return (
      <div className="relative w-full h-[400px]">
        <Skeleton
          variant="square"
          className="w-full h-full relative z-0 min-w-full flex justify-center items-center"
        />
      </div>
    );
  }

  return (
    <div
      ref={refContainer}
      className="relative w-full h-[400px] flex items-center justify-center"
    >
      <ForceGraph2D
        ref={graphRef}
        height={400}
        width={anchoContenedor}
        graphData={graphData}
        nodeLabel={'razonSocial'}
        onNodeClick={() => {}}
        linkDistance={0}
        linkStrength={0.8}
        collideStrength={0.8}
        d3VelocityDecay={0.8}
        linkThreeObject={link => {
          const sprite = link;
          sprite.color = 'lightgrey';
          sprite.textHeight = 1.5;
          sprite.textSize = '10px';
          return sprite;
        }}
        nodeColor={'codigoHex'}
        nodeAutoColorBy={'codigoHex'}
        dagLevelDistance={30}
        yOffset={0}
        dagMode={'td'}
        nodeRelSize={1}
        minZoom={2}
      />
      <div className="absolute left-2.5 bottom-2.5 flex flex-col bg-white shadow-sm rounded w-[30px] border border-solid">
        <IconButtonComponent
          type="black"
          onClick={() => handleZoomIn()}
          className="w-[30px] h-[30px]"
        >
          <Add className="text-black" />
        </IconButtonComponent>
        <Divider />
        <IconButtonComponent
          type="black"
          onClick={() => handleZoomOut()}
          className="w-[30px] h-[30px]"
        >
          <Remove className="text-black" />
        </IconButtonComponent>
      </div>

      <div className="absolute z-[1000] p-3 top-2 left-2.5">
        <MensajeMapa>
          <Text size="S">
            Se han graficado <strong>{numeroNodosDestino} nodos</strong> de
            destino de <br />
            <strong>
              {numeroEmpresasSeleccionadas > 1
                ? `las ${numeroEmpresasSeleccionadas} empresas seleccionadas`
                : `${numeroEmpresasSeleccionadas} empresa seleccionada`}
            </strong>
          </Text>
        </MensajeMapa>
      </div>

      {sectoresEconomicos.length > 0 && (
        <>
          {showSimbologia === true ? (
            <div className="bg-white absolute bottom-0 right-0 top-0 w-full md:w-3/6 lg:w-2/5 p-5 z-[1000] shadow-md ">
              <div>
                <div className="relative">
                  <span className="mb-8 block font-secondary text-xs text-neutral-60 tracking-wider font-medium">
                    Simbología Sector Económico
                  </span>
                  <div className="absolute -right-1 -top-1.5 z-10">
                    <IconButtonComponent
                      onClick={() => setShowSimbologia(false)}
                      size="small"
                      type="neutral"
                    >
                      <Close />
                    </IconButtonComponent>
                  </div>
                </div>
                <ul className="columns-2">
                  {sectoresEconomicos.map(sector => {
                    return (
                      <li
                        key={sector.codigoSectorEconomico}
                        className="relative mb-1.5 pl-6 break-inside-avoid-column"
                      >
                        <div
                          className="w-3 h-3 rounded-full block mr-2 absolute left-0 top-1"
                          style={{ backgroundColor: sector.codigoHex }}
                        ></div>
                        <Text className="text-xs">
                          {sector.nombreSectorEconomico}
                        </Text>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : (
            <button
              onClick={() => setShowSimbologia(true)}
              className="absolute right-2 bottom-2 rounded bg-white shadow-md z-[10000] h-14 w-14  justify-center items-center flex"
            >
              <MoreVert className="text-uv-secondary-0" />
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default GraficoNodos;
