import { Marker, Popup } from 'react-leaflet';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import PlaceRounded from '@mui/icons-material/PlaceRounded';
import imgMarcadorRelleno from 'assets/images/smp/marcadorRelleno.svg';
import imgMarcadorRellenoIndicador from 'assets/images/smp/marcadorRellenoIndicador.svg';
import imgShadow from 'assets/images/smp/marker-shadow.png';
import L from 'leaflet';
import { numeroBaseDatosAFront } from 'helpers/formateoNumeros';

const markerRelleno = new L.Icon({
  iconUrl: imgMarcadorRelleno,
  iconRetinaUrl: imgMarcadorRelleno,
  iconAnchor: [20, 40],
  popupAnchor: [0, -35],
  iconSize: [50, 50],
  shadowUrl: imgShadow,
  shadowSize: [29, 40],
  shadowAnchor: [7, 40]
});

const markerRellenoIndicador = new L.Icon({
  iconUrl: imgMarcadorRellenoIndicador,
  iconRetinaUrl: imgMarcadorRellenoIndicador,
  iconAnchor: [20, 40],
  popupAnchor: [0, -35],
  iconSize: [50, 50],
  shadowUrl: imgShadow,
  shadowSize: [29, 40],
  shadowAnchor: [7, 40]
});

/**
 * @name MarcadorEmpresa
 * Componente que muestra marcador en el mapa
 * @param sucursal datos de la sucursal asociada al marcador
 * @param markers referencia al marcador posicionado en el mapa
 * @param getMapIcon renderiza el tipo de icono a desplegar según el rol de la empresa
 */
export const MarcadorRellenoSanitario = ({ rellenoSanitario, markers }) => {
  const marcador = {
    nombre: rellenoSanitario.nombreRellenoSanitario || '',
    coordenadas: [
      rellenoSanitario.direccion.latitudDireccion,
      rellenoSanitario.direccion.longitudDireccion
    ],
    direccion: rellenoSanitario.direccion
      ? `${
          rellenoSanitario.direccion.nombreVia
            ? rellenoSanitario.direccion.nombreVia
            : ''
        } ${
          rellenoSanitario.direccion.comuna
            ? rellenoSanitario.direccion.comuna.nombreComuna
            : ''
        } ${
          rellenoSanitario.direccion.comuna.region
            ? ', ' + rellenoSanitario.direccion.comuna.region.nombreRegion
            : ''
        }`
      : 'Sin dirección',
    toneladas: rellenoSanitario.toneladas || '',
    vidaUtil: rellenoSanitario.vidaUtil || '',
    autorizacionesAmbientales: rellenoSanitario.autorizacionesAmbientales || '',
    isCalculoIndicadores: rellenoSanitario.calculoIndicadores || ''
  };

  return (
    <Marker
      position={marcador.coordenadas}
      boundsOptions={[marcador.coordenadas]}
      ref={el => {
        markers.current = {
          ...markers.current,
          [marcador.codigoSucursal]: el
        };
      }}
      key={marcador.codigoSucursal}
      icon={
        marcador.isCalculoIndicadores ? markerRellenoIndicador : markerRelleno
      }
    >
      <Popup>
        <div className="market-popup">
          <div className="p-4">
            <div className="relative">
              <Heading type="h4" className="pr-10">
                {marcador.nombre}
              </Heading>
              <div className="relative">
                <div className="flex items-center">
                  <PlaceRounded className="mr-2 text-uv-secondary-10" />
                  <Text className="!m-0">{marcador.direccion}</Text>
                </div>
                {marcador.toneladas && (
                  <div className="mt-4">
                    <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase pb-1">
                      (Ton/año) 2017
                    </span>
                    <Text className="!m-0">
                      {numeroBaseDatosAFront(marcador.toneladas)}
                    </Text>
                  </div>
                )}

                {marcador.vidaUtil && (
                  <div className="mt-4">
                    <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase pb-1">
                      Vida útil (año de cierre)
                    </span>
                    <Text className="!m-0">{marcador.vidaUtil}</Text>
                  </div>
                )}

                {marcador.autorizacionesAmbientales && (
                  <div className="mt-4">
                    <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase pb-1">
                      Autorizaciones Ambiental
                    </span>
                    <Text className="!m-0">
                      {marcador.autorizacionesAmbientales}
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </Marker>
  );
};
