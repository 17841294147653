import ImageNotSupported from '@mui/icons-material/ImageNotSupported';
import { Skeleton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import { useEffect, useState } from 'react';
import archivos from 'services/archivos';
import ReactFullscreen from 'react-easyfullscreen';
/**
 * @name Galeria
 * Despliega galería de imagenes de residio
 * @param codigoResiduo ID del codigoResiduo utilizado para que el servicio busque las imagenes.
 */
export const Galeria = ({ codigoResiduo }) => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadArchivos = async () => {
    setIsLoading(true);

    try {
      const response = await archivos.getArchivosResiduos(
        { codigoResiduo: codigoResiduo },
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      if (response.status === 200) {
        if (response.data.error) throw new Error('SIN_IMAGENES');
        setImages(response.data?.archivosResiduos);
      }
    } catch (error) {
      setImages([]);
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadArchivos();
  }, [codigoResiduo]);

  return (
    <div className="flex gap-2 my-4">
      {isLoading && (
        <Skeleton
          variant="rectangular"
          className="w-full min-h-[14rem] rounded relative z-0 min-w-full flex justify-center items-center"
        >
          <CircularProgress
            thickness={4}
            size={60}
            className="text-neutral-50 mb-4 relative z-10 visible"
          />
        </Skeleton>
      )}

      {!isLoading && images.length === 0 && (
        <div className="bg-neutral-100 min-h-[14rem] w-full rounded flex text-center items-center mx-auto flex-col justify-center">
          <ImageNotSupported className="text-6xl text-neutral-80 mb-2" />
          <Text className="mx-auto">Sin imágenes</Text>
        </div>
      )}

      {!isLoading && images && images.length > 0 && (
        <>
          {images.length > 1 ? (
            <>
              <div className="w-4/5">
                <ReactFullscreen>
                  {({ ref, onRequest, onExit, onToggle }) => (
                    <div
                      ref={ref}
                      onClick={() => onToggle()}
                      aria-hidden="true"
                      role="button"
                      tabIndex="0"
                      className="w-full h-full flex flex-col justify-center items-center cursor-pointer"
                    >
                      <img
                        className="object-cover rounded"
                        alt={images[0].nombreArchivo}
                        src={`data:image/png;base64, ${images[0].base64}`}
                      />
                    </div>
                  )}
                </ReactFullscreen>
              </div>
              <div className="w-1/5 gap-2 flex flex-col">
                <ReactFullscreen>
                  {({ ref, onRequest, onExit, onToggle }) => (
                    <div
                      ref={ref}
                      onClick={() => onToggle()}
                      aria-hidden="true"
                      role="button"
                      tabIndex="0"
                      className="w-full h-full flex flex-col justify-center items-center cursor-pointer gap-2"
                    >
                      {images.map((image, index) => (
                        <div key={index}>
                          {index > 0 && (
                            <img
                              className="flex-1 rounded"
                              alt={image.nombreArchivo}
                              src={`data:image/png;base64, ${image.base64}`}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </ReactFullscreen>
              </div>
            </>
          ) : (
            <div className="w-full">
              <ReactFullscreen>
                {({ ref, onRequest, onExit, onToggle }) => (
                  <div
                    ref={ref}
                    onClick={() => onToggle()}
                    aria-hidden="true"
                    role="button"
                    tabIndex="0"
                    className="w-full h-full flex flex-col justify-center items-center cursor-pointer"
                  >
                    <img
                      className="object-cover rounded"
                      alt={images[0].nombreArchivo}
                      src={`data:image/png;base64, ${images[0].base64}`}
                    />
                  </div>
                )}
              </ReactFullscreen>
            </div>
          )}
        </>
      )}
    </div>
  );
};
