import ChevronLeft from '@mui/icons-material/ChevronLeft';
import imgIniciarTransaccion from 'assets/images/marketplace/iniciar-transaccion.svg';
import { formatNumber } from 'utils';
import DoneAllOutlined from '@mui/icons-material/DoneAllOutlined';
import Inventory2Outlined from '@mui/icons-material/Inventory2Outlined';
import PlaceOutlined from '@mui/icons-material/PlaceOutlined';
import ReplayOutlined from '@mui/icons-material/ReplayOutlined';
import residuos from 'services/residuos';
import AlertComponent from 'components/design-system/Alert/Alert';
import { DICTONARY } from 'const/Dictonary';
import { useContext, useEffect, useState } from 'react';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import ButtonComponent from 'components/design-system/Button/Button';
import { CircularProgress, Divider } from '@mui/material';
import Text from 'components/design-system/Text/Text';
import { MatchContext } from 'views/Marketplace/Residuos/context/MatchContext';
import { getFormatoCantidadUnidad } from 'views/Chat/utils/utils';

/**
 * @name IniciarTransaccion
 * Maneja el flujo de los elementos para cuando un usuario inicia una transacción
 * @param residuo residuo por el cual se está transaccionando
 * @param handleCancelarInicioTransaccion función en el caso de que el usuario no desea efectuar la transacción
 * @param transaccionar Boolean que habilita o no la opción de transacción
 */
export const IniciarTransaccion = ({
  residuo,
  handleCancelarInicioTransaccion,
  transaccionar
}) => {
  const { transacciones, setTransacciones } = useContext(MatchContext);

  const {
    nombreLER,
    cantidad,
    precioReferencial,
    sucursal,
    codigoResiduo
  } = residuo;
  const [estado, setEstado] = useState(DICTONARY.ESTADOS.INIT);

  const oniniciarTransaccion = async () => {
    setEstado(DICTONARY.ESTADOS.CARGANDO);
    try {
      const response = await residuos.iniciarTransaccion({
        residuo: {
          codigoResiduo: codigoResiduo
        }
      });
      if (response.status === 200) {
        setEstado(DICTONARY.ESTADOS.EXITO);
        const codigosTransacciones = transacciones;
        codigosTransacciones.push(codigoResiduo);
        setTransacciones(codigosTransacciones);
      } else {
        setEstado(DICTONARY.ESTADOS.ERROR);
      }
    } catch (error) {
      console.log(error);
      setEstado(DICTONARY.ESTADOS.ERROR);
    }
  };

  const handleReiniciarEstados = () => {
    handleCancelarInicioTransaccion();
    setEstado(DICTONARY.ESTADOS.INIT);
  };

  useEffect(() => {
    //Reiniciar estado una vez se selecciona un nuevo residuo
    handleReiniciarEstados();
  }, [residuo]);

  return (
    <div>
      {transaccionar && (
        <div
          id="detalle-oferente"
          className="h-[65vh] scrollbar-uv overflow-y-auto p-4 pb-16"
        >
          <div>
            <div className="relative pl-10 mb-4">
              <Heading type="h3">
                {estado === DICTONARY.ESTADOS.EXITO
                  ? 'Transacción iniciada'
                  : 'Confirmar transacción'}
                <div className="absolute -left-1 -top-1">
                  <IconButtonComponent
                    disabled={estado === DICTONARY.ESTADOS.CARGANDO}
                    onClick={handleCancelarInicioTransaccion}
                    size="small"
                    type="neutral"
                  >
                    <ChevronLeft />
                  </IconButtonComponent>
                </div>
              </Heading>
            </div>

            {estado !== DICTONARY.ESTADOS.EXITO && (
              <div className="flex gap-4">
                <div className="w-3/5">
                  <ButtonComponent
                    isLoading={estado === DICTONARY.ESTADOS.CARGANDO}
                    disabled={estado === DICTONARY.ESTADOS.CARGANDO}
                    onClick={oniniciarTransaccion}
                    className="px-2 w-full justify-center"
                  >
                    {estado === DICTONARY.ESTADOS.ERROR ? (
                      <>
                        Reintentar <ReplayOutlined className="ml-2" />
                      </>
                    ) : (
                      <>
                        Confirmar <DoneAllOutlined className="ml-2" />
                      </>
                    )}
                  </ButtonComponent>
                </div>
                <div className="w-2/5">
                  <ButtonComponent
                    disabled={estado === DICTONARY.ESTADOS.CARGANDO}
                    onClick={handleCancelarInicioTransaccion}
                    className="px-2 w-full text-center justify-center"
                    type="secondary"
                  >
                    Cancelar
                  </ButtonComponent>
                </div>
              </div>
            )}

            <Divider className="my-4 mb-3" />
            <div className="relative">
              <Text>Información de la transacción</Text>
              <div className="flex items-center mt-3">
                <PlaceOutlined className="mr-2 text-uv-secondary-10 w-10 text-3xl" />
                <div>
                  <Text className="font-semibold text-black">
                    {sucursal.empresa}
                  </Text>
                  <Text>
                    {sucursal.direccion?.nombreVia}{', '}
                    {sucursal.direccion?.comuna}{',  Región '}
                    {sucursal.direccion?.region}
                  </Text>
                </div>
              </div>
              <div className="flex items-center mt-3 mb-5">
                <Inventory2Outlined className="mr-2 text-uv-secondary-10 w-10 text-3xl" />
                <div>
                  <Text className="font-semibold text-black">
                    {nombreLER ? nombreLER : 'Nombre residuo'}
                  </Text>
                  <div className="flex items-center">
                    {cantidad && (
                      <Text className="text-sm mr-2">
                        {`${getFormatoCantidadUnidad(residuo)}`}
                      </Text>
                    )}
                    {precioReferencial !== null && (
                      <Text className="text-sm">
                        ${formatNumber(precioReferencial, '0')}
                      </Text>
                    )}
                  </div>
                </div>
              </div>
              <div>
                {(estado === DICTONARY.ESTADOS.INIT ||
                  estado === DICTONARY.ESTADOS.EXITO) && (
                  <img
                    src={imgIniciarTransaccion}
                    className="mx-auto mb-2"
                    alt="Iniciar transacción"
                  />
                )}

                {estado === DICTONARY.ESTADOS.CARGANDO && (
                  <div className="text-center">
                    <CircularProgress
                      thickness={2}
                      size={50}
                      className="text-uv-primary-0 mb-2"
                    />
                    <Text>Iniciando transacción...</Text>
                  </div>
                )}

                {estado === DICTONARY.ESTADOS.ERROR && (
                  <AlertComponent type="warning">
                    <Text>
                      Ha ocurrido un error en la inicialización de la
                      transacción. Intentalo nuevamente, si el problema persiste
                      comunicate con el administrador.
                    </Text>
                  </AlertComponent>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
