import { ChevronLeft } from '@mui/icons-material';
import { Divider, Skeleton } from '@mui/material';
import AlertComponent from 'components/design-system/Alert/Alert';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';

export const SkeletonEditarIndicador = () => {
  const row = [0, 1, 2, 3];

  return (
    <div className="bg-white rounded p-5 shadow-sm mb-5">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Heading type="h4" className="mb-0">
            <Skeleton
              variant="rounded"
              className="w-48 h-10 rounded relative z-0 flex justify-center items-center mb-3"
            />
          </Heading>
          <Text>
            Sucursal a la cúal se agregarán los datos de generación de
            indicadores
          </Text>
        </div>

        <div className="col-span-10">
          <AlertComponent type="warning">
            <Text>
              Estos datos son utilizados para la generación de indicadores
              ambientales y sociales útiles para su negocio y la región. En cada
              campo, ingrese los datos correspondientes al año anterior o a los
              últimos 12 meses.
            </Text>
          </AlertComponent>
        </div>

        <div className="col-span-12 my-2">
          <Divider />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-5">
        {row.map((item, index) => (
          <div className="col-span-3" key={index}>
            <Skeleton
              variant="rounded"
              className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
            />
          </div>
        ))}

        <div className="col-span-12">
          <Divider />
        </div>

        <div className="col-span-3">
          <Skeleton
            variant="rounded"
            className="w-full h-10 rounded relative z-0 flex justify-center items-center mt-3"
          />
        </div>

        <div className="col-span-9">
          <Heading type="h4">
            Participantes en el proceso de gestión del residuo
          </Heading>
          <Skeleton
            variant="rounded"
            className="w-full h-80 rounded relative z-0 flex justify-center items-center mt-3"
          />
        </div>

        <div className="col-span-12">
          <Divider />
        </div>

        <div className="col-span-12 justify-between">
          <div className="flex justify-between">
            <ButtonComponent type="secondary" className="mr-2" disabled={true}>
              <ChevronLeft /> Volver
            </ButtonComponent>

            <ButtonComponent
              form="editarIndicadores"
              buttonType="submit"
              disabled={true}
            >
              {DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR}
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  );
};
