import { DICTONARY } from 'const/Dictonary';
import { ResiduoDetalleDemandante } from './ResiduoDetalle/ResiduoDetalleDemandante';
import { ResiduoDetalleOferente } from './ResiduoDetalle/ResiduoDetalleOferente';
import { MatchContext } from 'views/Marketplace/Residuos/context/MatchContext';
import { useContext } from 'react';

/**
 * @name MatchResiduoDetalle
 * Define que componente mostrar para desplegar el detalle de esté en el popup del residuo.
 * @param residuo residuo por el cual se está visualizando el detalle
 * @param onCerrar función para cuando se desea cerrar el popup de detalle.
 */
export const MatchResiduoDetalle = () => {
  const { residuoSeleccionado, setResiduoSeleccionado } =
    useContext(MatchContext);

  if (!residuoSeleccionado) {
    return;
  }

  return (
    <div className="w-full shadow-md rounded bg-white p-1 relative h-[80vh]">
      {residuoSeleccionado.rol.codigoRol ===
        DICTONARY.ROL.RECEPTOR.CODIGO && (
        <ResiduoDetalleDemandante
          onCerrar={() => setResiduoSeleccionado(null)}
          residuo={residuoSeleccionado}
        />
      )}

      {residuoSeleccionado.rol.codigoRol === DICTONARY.ROL.GENERADOR.CODIGO && (
        <ResiduoDetalleOferente
          onCerrar={() => setResiduoSeleccionado(null)}
          residuo={residuoSeleccionado}
        />
      )}
    </div>
  );
};
