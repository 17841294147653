import LineaBase from './LineaBase';
import { LineaBaseProvider } from './context/LineaBaseProvider';

const ATerritorialEmpresas = () => {
  return (
    <LineaBaseProvider>
      <LineaBase />
    </LineaBaseProvider>
  );
};

export default ATerritorialEmpresas;
