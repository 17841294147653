import { useCallback, useContext, useEffect } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { Add, ChevronLeft, Close, Delete, Edit } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Hidden,
  Tooltip,
  DialogActions
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import AlertComponent from 'components/design-system/Alert/Alert';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Table from 'components/design-system/Table/Table';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import { RUTAS } from 'const/Rutas';
import { useColumnTablesListadoResiduos } from '../hooks/Tabla/useColumnTablesListadoResiduos';
import { useServicesResiduos } from '../hooks/useServicesResiduos';
import { useHelpersResiduos } from '../hooks/Helpers/useHelpersResiduos';
import moment from 'moment';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';

const ListadoResiduos = () => {
  const navigate = useHistory();
  const {
    perfiles: { isSuperAdmin },
    isTienePermisos
  } = useContext(AuthContext);
  // Columns
  const columnTables = useColumnTablesListadoResiduos();

  // Exportaciones de useServicesRecursosAdquiridos
  const {
    // Funciones
    obtieneListaResiduos,
    // States
    residuos,
    setResiduos,
    isLoadingLista,
    setIsLoadingLista,
    pagination,
    setPagination,
    rowCount,
    setRowCount,
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters
  } = useServicesResiduos();

  const cargaResiduos = useCallback(async () => {
    await obtieneListaResiduos(
      columnFilters,
      setIsLoadingLista,
      setResiduos,
      pagination,
      setRowCount
    );
  }, [
    columnFilters,
    pagination,
    obtieneListaResiduos,
    setIsLoadingLista,
    setResiduos,
    setRowCount
  ]);

  useEffect(() => {
    obtieneListaResiduos(
      columnFilters,
      pagination,
      setIsLoadingLista,
      setResiduos,
      setRowCount
    );
  }, [
    columnFilters,
    pagination,
    pagination.pageIndex,
    pagination.pageSize,
    obtieneListaResiduos,
    setIsLoadingLista,
    setResiduos,
    setRowCount
  ]);

  // Helpers
  const {
    handleClickEditar,
    handleClickEliminar,
    handleClickDuplicar,
    handleClickOpen,
    handleClose,
    setOpen,
    open,
    selectedRow
  } = useHelpersResiduos();

  // Constants and variables
  const title = 'Administración de residuos';
  const subTitle =
    'Crea, edita, duplica y elimina los registros de los residuos publicados en el Marketplace';
  const requireWasteTitle = 'Requerir residuo';
  const offerWasteTitle = 'Ofrecer residuo';
  const tableTitle = 'Residuo ingresado';

  return (
    <SidebarLayout>
      <div className="mb-10">
        <div className="md:flex items-center mb-4 justify-between">
          <div className="mb-4">
            <Heading type="h2" className="mb-0">
              <IconButtonComponent
                onClick={() => navigate.goBack()}
                type="secondary"
                className="mr-2"
              >
                <ChevronLeft />
              </IconButtonComponent>
              {title}
            </Heading>
            <Text className="mb-0">{subTitle}</Text>
          </div>
          <div>
            {isTienePermisos(PERMISOS.requerirResiduo) && (
              <Link
                to={`${RUTAS.RESIDUOS}/${DICTONARY.ROL.RECEPTOR.NOMBRE}/ingresar`}
              >
                <ButtonComponent className="mb-3 md:mb-0 mr-3" type="primary">
                  <Add className="mr-2" /> {requireWasteTitle}
                </ButtonComponent>
              </Link>
            )}

            {isTienePermisos(PERMISOS.ofrecerResiduo) && (
              <Link
                to={`${RUTAS.RESIDUOS}/${DICTONARY.ROL.GENERADOR.NOMBRE}/ingresar`}
              >
                <ButtonComponent type="primary">
                  <Add className="mr-2" /> {offerWasteTitle}
                </ButtonComponent>
              </Link>
            )}
          </div>
        </div>

        <div className="md:container md:mx-auto">
          <Table
            headTable={
              <Heading type="h4" className="p-2">
                {tableTitle}
              </Heading>
            }
            data={residuos}
            columns={columnTables}
            rowCount={rowCount}
            isLoading={isLoadingLista}
            enablePagination={true}
            enableGlobalFilter={false}
            enableRowActions={
              isTienePermisos(PERMISOS.eliminarResiduoDemanda) ||
              isTienePermisos(PERMISOS.editarResiduoDemanda) ||
              isTienePermisos(PERMISOS.duplicarResiduoDemanda) ||
              isTienePermisos(PERMISOS.eliminarResiduoOferta) ||
              isTienePermisos(PERMISOS.editarResiduoOferta) ||
              isTienePermisos(PERMISOS.duplicarResiduoOferta)
            }
            onPaginationChange={setPagination}
            state={{
              columnFilters,
              pagination,
              isLoading: isLoadingLista,
              showProgressBars: false,
              globalFilter,
              columnVisibility: {
                'sucursal.empresa.nombreEmpresa': isSuperAdmin ? true : false
              }
            }}
            manualPagination
            actions={({ row, table }) => (
              <div>
                {row.original.rol.codigoRol ===
                  DICTONARY.ROL.GENERADOR.CODIGO &&
                  isTienePermisos(PERMISOS.duplicarResiduoOferta) && (
                    <Tooltip arrow placement="left" title="Duplicar">
                      <IconButtonComponent
                        size="small"
                        className="mr-2"
                        disabled={isLoadingLista}
                        type={isLoadingLista ? 'disabled' : 'secondary'}
                        onClick={() => {
                          handleClickDuplicar(row, isLoadingLista);
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButtonComponent>
                    </Tooltip>
                  )}

                {row.original.rol.codigoRol ===
                  DICTONARY.ROL.GENERADOR.CODIGO &&
                  isTienePermisos(PERMISOS.editarResiduoOferta) && (
                    <Tooltip arrow placement="left" title="Editar">
                      <IconButtonComponent
                        size="small"
                        className="mr-2"
                        disabled={isLoadingLista}
                        type={isLoadingLista ? 'disabled' : 'secondary'}
                        onClick={() => handleClickEditar(row, table)}
                      >
                        <Edit />
                      </IconButtonComponent>
                    </Tooltip>
                  )}

                {row.original.rol.codigoRol ===
                  DICTONARY.ROL.GENERADOR.CODIGO &&
                  isTienePermisos(PERMISOS.eliminarResiduoOferta) && (
                    <Tooltip arrow placement="right" title="Eliminar">
                      <IconButtonComponent
                        size="small"
                        disabled={isLoadingLista}
                        type={isLoadingLista ? 'disabled' : 'error'}
                        onClick={() => handleClickOpen(row, table)}
                      >
                        <Delete />
                      </IconButtonComponent>
                    </Tooltip>
                  )}

                {row.original.rol.codigoRol === DICTONARY.ROL.RECEPTOR.CODIGO &&
                  isTienePermisos(PERMISOS.duplicarResiduoDemanda) && (
                    <Tooltip arrow placement="left" title="Duplicar">
                      <IconButtonComponent
                        size="small"
                        className="mr-2"
                        disabled={isLoadingLista}
                        type={isLoadingLista ? 'disabled' : 'secondary'}
                        onClick={() => {
                          handleClickDuplicar(row, isLoadingLista);
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButtonComponent>
                    </Tooltip>
                  )}

                {row.original.rol.codigoRol === DICTONARY.ROL.RECEPTOR.CODIGO &&
                  isTienePermisos(PERMISOS.editarResiduoDemanda) && (
                    <Tooltip arrow placement="left" title="Editar">
                      <IconButtonComponent
                        size="small"
                        className="mr-2"
                        disabled={isLoadingLista}
                        type={isLoadingLista ? 'disabled' : 'secondary'}
                        onClick={() => handleClickEditar(row, table)}
                      >
                        <Edit />
                      </IconButtonComponent>
                    </Tooltip>
                  )}

                {row.original.rol.codigoRol === DICTONARY.ROL.RECEPTOR.CODIGO &&
                  isTienePermisos(PERMISOS.eliminarResiduoDemanda) && (
                    <Tooltip arrow placement="right" title="Eliminar">
                      <IconButtonComponent
                        size="small"
                        disabled={isLoadingLista}
                        type={isLoadingLista ? 'disabled' : 'error'}
                        onClick={() => handleClickOpen(row, table)}
                      >
                        <Delete />
                      </IconButtonComponent>
                    </Tooltip>
                  )}
              </div>
            )}
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
          />
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            className="border-b border-neutral-90"
          >
            <Heading type="h3" className="mb-0">
              Confirmación de eliminación de residuo
            </Heading>
          </DialogTitle>
          <DialogContent className="p-5">
            <DialogContentText id="alert-dialog-description" className="pt-4">
              <Text>
                ¿Estás seguro que deseas eliminar completamente la siguiente
                sucursal?
              </Text>
              <div className="my-4">
                <div className="grid grid-cols-12 sm:gap-4">
                  <div className="col-span-12 sm:col-span-3 sm:text-end">
                    <Text type="span">CÓDIGO LER</Text>
                  </div>
                  <div className="col-span-12 sm:col-span-9 mb-4 sm:mb-0">
                    <Text>{selectedRow.original?.codigoResiduo}</Text>
                  </div>

                  <div className="col-span-12 sm:col-span-3 sm:text-end">
                    <Text type="span">NOMBRE RESIDUO</Text>
                  </div>
                  <div className="col-span-12 sm:col-span-9 mb-4 sm:mb-0">
                    <Text>{selectedRow.original?.nombre}</Text>
                  </div>

                  <div className="col-span-12 sm:col-span-3 sm:text-end">
                    <Text type="span">CANTIDAD</Text>
                  </div>
                  <div className="col-span-12 sm:col-span-9 mb-4 sm:mb-0">
                    <Text>{selectedRow.original?.cantidad}</Text>
                  </div>

                  <div className="col-span-12 sm:col-span-3 sm:text-end">
                    <Text type="span">SUCURSAL</Text>
                  </div>
                  <div className="col-span-12 sm:col-span-9 mb-4 sm:mb-0">
                    <Text>
                      {selectedRow.original?.sucursal?.nombreSucursal}
                    </Text>
                  </div>

                  <div className="col-span-12 sm:col-span-3 sm:text-end">
                    <Text type="span">DISPONIBILIDAD</Text>
                  </div>
                  <div className="col-span-12 sm:col-span-9 mb-4 sm:mb-0">
                    <Text>
                      {selectedRow.original?.fechaDisponibilidad !== 'N/A'
                        ? moment(
                            selectedRow.original?.fechaDisponibilidad
                          ).format('DD-MM-YYYY')
                        : 'N/A'}
                    </Text>
                  </div>
                </div>
              </div>
              <AlertComponent type="error">
                Esta operación es irreversible y{' '}
                <b>se perderán todos los datos asociados a este residuo</b>
              </AlertComponent>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="border-t border-neutral-90 p-4 justify-between">
            <ButtonComponent
              type="secondary"
              onClick={handleClose}
              className="justify-center"
            >
              <Hidden smDown>Cancelar</Hidden>
              <Hidden smUp>
                <Close />
              </Hidden>
            </ButtonComponent>
            <ButtonComponent
              type="primary"
              onClick={() =>
                handleClickEliminar(
                  selectedRow,
                  residuos,
                  setResiduos,
                  setOpen,
                  cargaResiduos
                )
              }
              autoFocus
              className="w-full xs:w-auto justify-center"
            >
              Confirmar
            </ButtonComponent>
          </DialogActions>
        </Dialog>
      </div>
    </SidebarLayout>
  );
};

export default withRouter(ListadoResiduos);
