
import {
  ObtieneTipoRecursosPorCodigosTipoRecursos  
} from 'hooks';

// Utils
import {
  HandleMateriaPrima
} from 'utils/useUtilidades';

export const useRecursos = ({
  codigosTipoResiduo,
  tieneTipoRecursos,
}) => {


  // SERVICIO nombresTipoResiduos
  const {
    listaTipoRecursos,
    cargandoListaTipoRecursos,
    handleTipoRecursos,
  } = ObtieneTipoRecursosPorCodigosTipoRecursos(codigosTipoResiduo);

  
    // Transformación nombresTipoRecursos
    const {
      handleDeleteMateriaPrima,
      handleAddOtraMateriaPrima,
      handleOtraMateriaKeyPress,
      setListasOtrosTiposRecursos
    } = HandleMateriaPrima();


  return {
    listaTipoRecursos,
    cargandoListaTipoRecursos,
    handleTipoRecursos,
    handleDeleteMateriaPrima,
    handleAddOtraMateriaPrima,
    handleOtraMateriaKeyPress,
    setListasOtrosTiposRecursos
  }
}
