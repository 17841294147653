export const ICON_RECICLADORBASE_TIPO_A = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 100 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 82.5085V27.5085L50.0089 0L0 27.5085V82.5085L50.0089 110L100 82.5085Z"
        fill="url(#paint0_linear_1101_118)"
      />
      <path
        d="M97.967 81.1456V28.6154L50.2288 2.35034L2.47266 28.6154V81.1456L50.2288 107.411L97.967 81.1456Z"
        fill="url(#paint1_linear_1101_118)"
      />
      <path
        d="M95.7966 79.7834V29.5697L50.1503 4.46289L4.50391 29.5697V79.7834L50.1503 104.89L95.7966 79.7834Z"
        fill="url(#paint2_linear_1101_118)"
      />
      <path
        d="M50.4861 10.3907L90.7507 32.5338V76.82L50.4861 98.963L10.2215 76.82V32.5338L50.4861 10.3907ZM50.4861 9.69238L9.5957 32.1761V77.1606L50.4861 99.6614L91.3765 77.1606V32.1761L50.4861 9.69238Z"
        fill="url(#paint3_linear_1101_118)"
      />
      <path
        d="M50.2021 85.3027L51.6325 88.028L54.7972 88.4709L52.5086 90.6L53.045 93.5979L50.2021 92.1841L47.3772 93.5979L47.9136 90.6L45.625 88.4709L48.7897 88.028L50.2021 85.3027Z"
        fill="#FFC450"
      />
      <path
        d="M61.0565 82.5082L61.9862 84.2967L64.0781 84.5862L62.5584 85.9829L62.916 87.9588L61.0565 87.022L59.197 87.9588L59.5546 85.9829L58.0527 84.5862L60.1268 84.2967L61.0565 82.5082Z"
        fill="#FFC450"
      />
      <path
        d="M39.9237 82.5082L40.8534 84.2967L42.9274 84.5862L41.4256 85.9829L41.7831 87.9588L39.9237 87.022L38.0642 87.9588L38.4218 85.9829L36.9199 84.5862L38.9939 84.2967L39.9237 82.5082Z"
        fill="#FFC450"
      />
      <g filter="url(#filter0_d_1101_118)">
        <path
          d="M36.1023 37.6515L39.7468 31.5163L42.3719 27.0896C43.3658 25.407 45.7615 25.407 46.7555 27.0896L50.5275 33.4578L47.3927 38.7905L44.844 42.9842L36.1023 37.6515ZM76.7528 52.7177L72.675 45.8318L63.8568 51.0092L69.4892 60.5615H72.3692C74.3061 60.5615 76.0647 59.4484 76.9312 57.688C77.288 56.9632 77.4664 56.1866 77.4664 55.3841C77.4664 54.4522 77.2116 53.5461 76.7528 52.7177ZM62.1747 73.505H65.9976C67.9346 73.505 69.6931 72.3918 70.5597 70.6315L74.2552 63.1502H62.1747V57.9728L51.9802 68.3276L62.1747 78.6824V73.505ZM46.8829 63.1502H35.9239L33.783 66.8003C33.0184 68.0946 32.9674 69.6996 33.6301 71.0716C34.3437 72.5472 35.8474 73.505 37.504 73.505H46.8829V63.1502ZM36.9943 56.2901L41.4034 58.9824L37.9118 44.7704L23.9453 48.3169L28.278 50.9574L27.233 52.7436C26.341 54.271 26.2646 56.1348 27.0546 57.7139L31.2089 66.1531L36.9943 56.2901ZM64.7743 32.4482L61.4611 26.8307C60.5181 25.2775 58.8615 24.3197 57.0774 24.3197H48.0808L56.0325 37.7809L51.6489 40.4473L65.6408 43.9938L69.1324 29.7819L64.7743 32.4482Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1101_118"
          x="19.9453"
          y="24.3197"
          width="61.5215"
          height="62.3627"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1101_118"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1101_118"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1101_118"
          x1="-7.08618"
          y1="97.0093"
          x2="90.9962"
          y2="17.4887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E8B134" />
          <stop offset="0.2181" stop-color="#AA6A21" />
          <stop offset="0.527" stop-color="#FEF9AD" />
          <stop offset="0.5577" stop-color="#FBF4A8" />
          <stop offset="0.5943" stop-color="#F4E799" />
          <stop offset="0.6339" stop-color="#E8D281" />
          <stop offset="0.6755" stop-color="#D7B360" />
          <stop offset="0.7185" stop-color="#C28C35" />
          <stop offset="0.7278" stop-color="#BD832B" />
          <stop offset="1" stop-color="#CC9F47" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1101_118"
          x1="13.9093"
          y1="32.2069"
          x2="99.3744"
          y2="90.9965"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1371" stop-color="#E7A426" />
          <stop offset="0.9324" stop-color="#B16F19" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1101_118"
          x1="81.879"
          y1="95.5169"
          x2="24.8551"
          y2="14.6538"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#915A08" />
          <stop offset="0.4884" stop-color="#E8AC34" />
          <stop offset="1" stop-color="#905C09" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1101_118"
          x1="9.59499"
          y1="54.6745"
          x2="91.3844"
          y2="54.6745"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.003861" stop-color="#FDC76F" />
          <stop offset="1" stop-color="#CD9235" />
        </linearGradient>
      </defs>
    </svg>
  );
};
