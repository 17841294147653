import React, { useContext } from 'react';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import AlertComponent from 'components/design-system/Alert/Alert';
import { SinIndicadores } from './Reporte/SinIndicadores';
import { useReporte } from './Reporte/hooks/useReporte';
import InputComponent from 'components/design-system/input/Input';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import LabelComponent from 'components/design-system/Label/Label';
import { Link, useParams } from 'react-router-dom';
import ButtonComponent from 'components/design-system/Button/Button';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { Hidden } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { BloquesIndicadores } from './Reporte/BloquesIndicadores';
import PDF_FILE from './../../assets/anexo_tecnico_indicadores.pdf';
import { PERMISOS } from 'const/Permisos';
import { AuthContext } from 'context';

const Indicadores = () => {
  const { isTienePermisos } = useContext(AuthContext);
  const {
    isLoading,
    indicadores,
    conIndicadores,
    isSuperAdmin,
    empresas,
    cargaIndicadores
  } = useReporte();

  const { codigoEmpresa } = useParams();

  return (
    <SidebarLayout>
      <div className="mb-5">
        <Heading type="h2">Indicadores</Heading>
      </div>

      {!conIndicadores && !isSuperAdmin && <SinIndicadores />}

      {isSuperAdmin && (
        <div className="mb-5">
          <div className="bg-white rounded shadow-md">
            <div className="grid grid-cols-12 p-5 gap-4 items-center">
              <div className="col-span-12 sm:col-span-3">
                <Heading type="h4" className="mb-0">
                  Cargar indicadores <br className="hidden sm:block" />
                  por empresa
                </Heading>
              </div>
              <div className="col-span-12 sm:col-span-9 lg:col-span-6">
                <AutocompleteComponent
                  id="empresa"
                  name="empresa"
                  accesor="nombreEmpresa"
                  options={empresas}
                  disabled={isLoading ? true : false}
                  onChange={(ev, object, reason) => {
                    if (reason === 'clear') {
                      cargaIndicadores(null);
                    } else {
                      cargaIndicadores(object.codigoEmpresa);
                    }
                  }}
                  getOptionLabel={option => option.nombreEmpresa || ''}
                  value={empresas[0]}
                >
                  <InputComponent
                    fullWidth
                    className="h-9"
                    label={
                      <LabelComponent>
                        Selecciona una empresa para ver sus sucursales
                      </LabelComponent>
                    }
                  />
                </AutocompleteComponent>
              </div>
            </div>
          </div>
        </div>
      )}

      {!isSuperAdmin && isTienePermisos(PERMISOS.configurarIndicadores) && (
        <div className="mb-5">
          <AlertComponent type="warning">
            <div className="lg:flex">
              <div className="lg:w-2/3">
                <Text>
                  Los siguientes indicadores han sido generados a partir de las
                  transacciones que has realizado y los datos establecidos para
                  el cálculo los cuales puedes editar.
                </Text>
              </div>
              <div className="lg:w-1/3 lg:text-end mt-3 lg:mt-0">
                <Link to={`/negocio/${codigoEmpresa}/datos-indicadores`}>
                  <ButtonComponent type="primary">
                    <Hidden smDown>Editar datos para el cálculo</Hidden>
                    <Hidden smUp>
                      Editar <Edit className="ml-2" />
                    </Hidden>
                  </ButtonComponent>
                </Link>
              </div>
            </div>
          </AlertComponent>
        </div>
      )}

      <BloquesIndicadores
        indicadores={indicadores}
        isLoading={isLoading}
        isSuperAdmin={isSuperAdmin}
      />

      <div className="shadow-md bg-white p-5 mb-6">
        <Text>
          La plataforma también permite calcular indicadores sociales de horas
          de trabajo, paridad y salario generado por la valorización de
          residuos. Para obtener estos indicadores, o explorar otros indicadores
          sociales, puede escribirnos a{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:contacto@plataforma-industria-circular.cl"
            className="text-blue-700 underline"
          >
            contacto@plataforma-industria-circular.cl
          </a>{' '}
          con el asunto “solicitud de indicadores sociales”.
        </Text>

        <Heading type="h3" className="text-uv-primary-0 mt-4">
          Notas pie de página
        </Heading>

        <ul className="list-disc ml-5 mb-4">
          <li className="pb-2">
            <Text>
              Todos los indicadores ambientales han sido calculados en base a
              información declarada por el usuario o empresa, según la
              metodología, fuente de información y supuestos detallados en el
              anexo técnico descargable{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href={PDF_FILE}
                className="text-blue-700 underline"
              >
                aquí
              </a>
              .
            </Text>
          </li>
          <li className="pb-2">
            <Text>
              Para revisar el detalle de indicadores a nivel de transacciones,
              puede descargar un reporte en formato xlsx o csv desde el módulo
              reporte / descarga de reporte.
            </Text>
          </li>
          <Text>
            Para obtener un reporte o certificado con datos desagregados a nivel
            de transacciones, tipo de residuo, o períodos específicos,
            incluyendo principales hotspot por indicador, y otros resultados,
            puede contactarnos a{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:contacto@plataforma-industria-circular.cl"
              className="text-blue-700 underline"
            >
              contacto@plataforma-industria-circular.cl
            </a>{' '}
            con el asunto “solicitud de certificado”
          </Text>
        </ul>
      </div>
    </SidebarLayout>
  );
};

export default Indicadores;
