import API from './api';

const archivos = {
  getArchivos: async function (params, conInterceptor = true) {
    return await API.axios({
      method: 'get',
      url: '/archivos',
      params: params,
      interceptor: conInterceptor
    });
  },

  getArchivosResiduos: async function (params, conInterceptor = true) {
    return await API.axios({
      method: 'get',
      url: `/residuos/${params.codigoResiduo}/archivos`,
      interceptor: conInterceptor
    });
  },

  getArchivosEmpresas: async function (params, conInterceptor = true) {
    return await API.axios({
      method: 'get',
      url: `/empresas/${params.codigoEmpresa}/archivos`,
      interceptor: conInterceptor
    });
  },

  getArchivoUnico: async function (codigoArchivo, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/archivos/${codigoArchivo}`,
      interceptor: conInterceptor
    });
  },

  getReporteSinader: async function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/empresas/exportar-archivo/sinader?${params}`,
      responseType: 'blob',
      interceptor: conInterceptor
    });
  },

  getReporteResiduos: async function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/residuos/exportar-archivo?${params}`,
      responseType: 'blob',
      interceptor: conInterceptor
    });
  },

  getReporteUsuarios: async function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/usuarios/exportar-archivo?${params}`,
      responseType: 'blob',
      interceptor: conInterceptor
    });
  },

  getReporteIndicadores: async function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/indicadores/exportar-archivo?${params}`,
      responseType: 'blob',
      interceptor: conInterceptor
    });
  },

  getExportarCargaMasivaSii: async function (conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/empresas/exportar-formato-sii`,
      interceptor: conInterceptor
    });
  },

  getExportarCargaMasivaRect: async function (conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/residuos/exportar-formato-retc`,
      interceptor: conInterceptor
    });
  },

  getExportarCargaMasivaPs: async function (conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/empresas/exportar-formato-ps`,
      interceptor: conInterceptor
    });
  },

  //TODO: VALIDAR
  postCargaMasivaSii: async function (formData, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: `/empresas/carga-masiva-sii`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData,
      responseType: 'blob',
      interceptor: conInterceptor
    });
  },

  //TODO: VALIDAR
  postCargaMasivaRect: async function (formData, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: `/residuos/carga-masiva`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData,
      responseType: 'blob',
      interceptor: conInterceptor
    });
  },

  //TODO: VALIDAR
  postCargaMasivaPs: async function (formData, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: `/empresas/carga-masiva-ps`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData,
      responseType: 'blob',
      interceptor: conInterceptor
    });
  }
};

export default archivos;
