import { BotonBase } from './BotonBase';
import home4Opciones from 'assets/images/home/home4Opciones.svg';

export const Layout4Opciones = ({ opciones }) => {
  const [primeraOpcion, segundaOpcion, terceraOpcion, cuartaOpcion] = opciones;

  return (
    <>
      <div>
        <BotonBase
          onClick={primeraOpcion.onClick}
          texto={<div className="py-4">{primeraOpcion.texto}</div>}
          icono={primeraOpcion.icono}
          className="mb-5"
        />
        <BotonBase
          onClick={segundaOpcion.onClick}
          texto={segundaOpcion.texto}
          icono={segundaOpcion.icono}
        />
      </div>

      <div>
        <img src={home4Opciones} alt="Sucursal" />
      </div>
      <div>
        <BotonBase
          onClick={terceraOpcion.onClick}
          texto={terceraOpcion.texto}
          icono={terceraOpcion.icono}
          className="mb-5"
        />
        <BotonBase
          onClick={cuartaOpcion.onClick}
          texto={cuartaOpcion.texto}
          icono={cuartaOpcion.icono}
        />
      </div>
    </>
  );
};
