import { DICTONARY } from 'const/Dictonary';
import { RUTAS } from 'const/Rutas';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export const useNavegacionFormulario = (
  residuoDeclarado,
  tipoForm,
  setCodigoResiduoCargado,
  cargarEstadoVacio
) => {
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [datosIniciales, setDatosIniciales] = useState(null);

  const tipoFormulario = DICTONARY.TIPO_FORMULARIO.operaciones.includes(
    tipoForm
  )
    ? tipoForm
    : DICTONARY.TIPO_FORMULARIO.ingresar;

  const handleSeleccionInicialDeDatos = codigoResiduo => {
    if (codigoResiduo) {
      setCodigoResiduoCargado(codigoResiduo);
      setDatosIniciales(residuoDeclarado);
      return;
    }

    cargarEstadoVacio();
    setCodigoResiduoCargado(null);
    setDatosIniciales(false);
  };

  const onCancelar = () => {
    window.scrollTo(0, 0);
    if (step === 0 && tipoFormulario === DICTONARY.TIPO_FORMULARIO.ingresar) {
      setDatosIniciales(null);
      return;
    }
    history.push(RUTAS.GESTIONA_RESIDUO_DECLARADO);
  };

  const handlePasoAnterior = () => {
    if (step === 0) history.push(RUTAS.GESTIONA_RESIDUO_DECLARADO);
    if (step > 0) {
      window.scrollTo(0, 0);
      setStep(step - 1);
    }
  };

  const onSiguientePaso = (validateForm, setFieldTouched) => {
    validateForm().then(resp => {
      const validaciones = Object.keys(resp);
      validaciones.forEach(campo => {
        setFieldTouched(campo, true);
      });

      if (validaciones.length !== 0) {
        return;
      }

      window.scrollTo(0, 0);
      setStep(paso => paso + 1);
    });
  };

  const handleReiniciarPasosFormulario = () => {
    setStep(0);
    setDatosIniciales(null);
  };

  return {
    step,
    handleReiniciarPasosFormulario,
    handleSeleccionInicialDeDatos,
    onCancelar,
    handlePasoAnterior,
    onSiguientePaso,
    datosIniciales,
    tipoFormulario
  };
};
