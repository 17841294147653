import { useEffect, useState } from 'react';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import imgEmpresa from 'assets/images/business/new/empresa-icono.svg';
import imgPersonaNatural from 'assets/images/business/new/persona-natural.svg';
import { businessOptValues } from '../data/optionsValues';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import { handleKeyPress } from 'views/Business/utils/handleKeyPress';
import AlertComponent from 'components/design-system/Alert/Alert';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import { CargaActividades, CargaRubros } from 'views/Business/hooks';
import { InputAsyncController } from 'components/design-system/InputAsync/InputAsyncController';
import { verificaEmpresaRegistradaNombre } from '../helpers/verificaCorreoRegistrado';
import { CheckCircle } from '@mui/icons-material';

import { DICTONARY } from 'const/Dictonary';
import { useRubrosActividades } from 'views/Business/hooks/useRubrosActividades';

export const BusinessType = ({
  values,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  handleReset,
  handleBlur,
  onChange,
  touched,
  errors,
  isSubmitting
}) => {
  const [nombreNegocio, setNombreNegocio] = useState(values.nombre);
  const [tipoNegocioLocal, setTipoNegocioLocal] = useState(values.tipoNegocio);
  const [originalRutValue, setOriginalRutValue] = useState('');

  const {
    cargaDatosEmpresasSII,
    cargandoServicioSII,
    rubros,
    actividades,
    isLoadingActividades,
    cargaRubrosSII
  } = useRubrosActividades(values, setFieldValue);

  const obtenerTodasEmpresasAT = async ({ setLoadingFetch, value }) => {
    try {
      setLoadingFetch(true);
      const nombreExiste = await verificaEmpresaRegistradaNombre(value);
      if (nombreExiste) {
        setFieldError('nombre', 'El nombre ingresado ya ha sido registrado');
      } else {
        setFieldValue('nombre', value);
      }
    } catch (error) {
      return error;
    } finally {
      setLoadingFetch(false);
    }
  };

  // Servicio Datos SII
  useEffect(() => {
    if (values.tipoNegocio === 'persona-natural') return;
    const rutEmpresa = values.rutEmpresa;
    const tipoNegocio = values.tipoNegocio;

    if (
      rutEmpresa &&
      tipoNegocio === 'empresa' &&
      !errors.rutEmpresa &&
      rutEmpresa.length >= 9 &&
      // validar si el rut tiene guion en su penultima posicion
      rutEmpresa.charAt(values.rutEmpresa.length - 2) === '-'
    ) {
      // Llama servicio datos empresa SII
      cargaDatosEmpresasSII(rutEmpresa);
    } else {
      // Limpieza de campos
      setFieldValue('razonSocial', '');
      setFieldValue('nombreEmpresa', '');
      setFieldValue('rubro', {});
      setFieldValue('actividad', {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.rutEmpresa, setFieldValue, values.rutEmpresa, values.tipoNegocio]);

  return (
    <div>
      <Heading type="h3">¿Como deseas participar en la plataforma?</Heading>
      <Text className="mb-8">
        Haz clic sobre un cuadro para seleccionar tu opción
      </Text>

      <div className="block sm:flex gap-4 pb-2">
        {businessOptValues.map((opt, index) => {
          const { text, value, id, label } = opt;
          return (
            <div key={index} className="w-full sm:1/2 mb-4 sm:mb-0">
              <div className="h-full">
                <InputComponent
                  onChange={ev => {
                    handleReset();
                    setFieldValue('tipoNegocio', ev.target.value);
                    setTipoNegocioLocal(ev.target.value);

                    if (values.tipoNegocio === 'persona-natural') {
                      setFieldValue('rutEmpresa', '');
                      setFieldValue('razonSocial', '');
                      setFieldValue('rubro', {
                        codigoCiuRubro: 0,
                        nombreRubro: ''
                      });
                      setFieldValue('actividad', {
                        codigoCiuActividad: 0,
                        nombreActividad: ''
                      });
                    } else if (values.tipoNegocio === 'empresa') {
                      setFieldValue('nombre', '');
                    }
                  }}
                  type="radio"
                  id={id}
                  name="tipoNegocio"
                  value={value}
                  className="absolute h-0 w-0 border-none overflow-hidden"
                  {...(tipoNegocioLocal === value ? 'checked' : '')}
                />
                <div>
                  <label htmlFor={id} className="h-full">
                    <div
                      className={`${
                        tipoNegocioLocal === value
                          ? 'border-success-dark shadow-md'
                          : 'shadow-sm'
                      }
                        ${
                          errors.tipoNegocio && touched.tipoNegocio
                            ? 'border-error-dark'
                            : 'border-neutral-80'
                        } 
                      lg:flex border  rounded px-4 py-2 items-center h-full cursor-pointer relative`}
                    >
                      {tipoNegocioLocal === value && (
                        <CheckCircle className="text-success-dark absolute left-1.5 top-1.5 text-3xl" />
                      )}

                      <div className="basis-40 pr-4 transition-[width] ease-linear">
                        <img
                          alt={label}
                          src={
                            value === 'empresa' ? imgEmpresa : imgPersonaNatural
                          }
                        />
                      </div>

                      <div className="flex-1 flex-grow select-none">
                        <Heading type="h4">{label}</Heading>
                        <Text>{text}</Text>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {errors.tipoNegocio && touched.tipoNegocio && (
        <Text size="s" className="text-error-dark font-medium">
          Debes seleccionar un tipo de negocio
        </Text>
      )}

      <div className="grid grid-cols-12 gap-4 pb-6 pt-8">
        {values.tipoNegocio === 'empresa' && (
          <>
            {/* RUT empresa */}
            <div className="col-span-12 xs:col-span-6  lg:col-span-4">
              <Heading type="h4">Rut de la empresa</Heading>
              <InputComponent
                autoComplete={'off'}
                fullWidth
                name="rutEmpresa"
                onChange={e => {
                  setFieldValue('rutModificado', false);
                  onChange(e);
                }}
                onClick={e => {
                  setFieldValue('rutModificado', false);
                }}
                onBlur={e => {
                  if (
                    originalRutValue !== e.target.value &&
                    e.target.value.length >= 9
                  ) {
                    setFieldTouched('rutEmpresa');
                    setFieldValue('rutModificado', true);
                  }
                }}
                onFocus={e => {
                  setOriginalRutValue(e.target.value);
                }}
                value={values.rutEmpresa.trim()}
                outerInputProps={{
                  inputProps: {
                    maxLength: 11,
                    onKeyPress: handleKeyPress
                  }
                }}
                touched={touched.rutEmpresa}
                estado={
                  errors.rutEmpresa
                    ? { tipo: 'error', mensaje: errors.rutEmpresa }
                    : null
                }
              />
            </div>

            <div className="col-span-12 xs:col-span-12  lg:col-span-12">
              <AlertComponent>
                <strong>La razón social, rubro y actividad</strong> han sido{' '}
                <strong>obtenidos de forma automática</strong> con la
                información entregada por Servicio de Impuestos Internos (SII),
                <strong>
                  {' '}
                  según su última actualización de noviembre 2022 con datos
                  registrados hasta el 2021.
                </strong>{' '}
                <br />
                En el caso de que estos datos automáticos no corresponden con la
                situación actual de tu empresa, por favor registra tu negocio y
                posterior a esto solicita mediante el botón de Ayuda la
                actualización adjuntando los datos del SII correctos.
              </AlertComponent>
            </div>
            {/* Razon Social */}
            <div className="col-span-12 xs:col-span-12  lg:col-span-12">
              <InputComponent
                autoComplete={'off'}
                disabled={
                  cargandoServicioSII || errors.rutEmpresa || !values.rutEmpresa
                    ? true
                    : false
                }
                fullWidth
                label={<LabelComponent>Razón social</LabelComponent>}
                name="razonSocial"
                onBlur={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  handleBlur(e);
                }}
                onChange={ev => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  ev && onChange(ev) && setFieldTouched('rubro');
                  !ev && setFieldValue('razonSocial', '');
                }}
                value={values.razonSocial ? values.razonSocial : ''}
                touched={touched.razonSocial}
                estado={
                  errors.razonSocial
                    ? { tipo: 'error', mensaje: errors.razonSocial }
                    : null
                }
              />
            </div>
            {/* Rubro */}
            <div className="col-span-12 xs:col-span-6  lg:col-span-6">
              <AutocompleteComponent
                options={rubros}
                name="rubro"
                disabled={cargandoServicioSII || errors.rutEmpresa}
                accesor={'nombreRubro'}
                loadingText={!rubros.length && 'Cargando...'}
                noOptionsText="No hay coincidencias"
                onChange={(ev, rubroSelected, reason) => {
                  const data = { name: 'rubro', value: rubroSelected };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  if (!cargaRubrosSII.activado) {
                    setFieldValue('actividad', '');
                  }
                  if (data.value) {
                    setFieldValue('rubro', data.value);
                  } else {
                    setFieldValue('rubro', '');
                    setFieldValue('actividad', '');
                  }
                }}
                onBlur={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  handleBlur(e);
                }}
                getOptionLabel={option => option.nombreRubro || ''}
                value={values.rubro ? values.rubro : ''}
                isOptionEqualToValue={(option, value) =>
                  option.nombreRubro === values.rubro.nombreRubro
                }
              >
                <InputComponent
                  autoComplete={'off'}
                  disabled={
                    errors.rutEmpresa || !values.rutEmpresa
                      ? true
                      : false || cargandoServicioSII
                  }
                  fullWidth
                  label={<LabelComponent>Rubro</LabelComponent>}
                  touched={touched.rubro}
                  estado={
                    errors.rubro
                      ? { tipo: 'error', mensaje: errors.rubro }
                      : null
                  }
                />
              </AutocompleteComponent>
            </div>
            {/* Actividad */}
            <div className="col-span-12 xs:col-span-6  lg:col-span-6">
              <AutocompleteComponent
                options={actividades}
                disabled={
                  errors.rutEmpresa ||
                  cargandoServicioSII ||
                  isLoadingActividades
                }
                accesor={'nombreActividad'}
                loadingText={!actividades.length && 'Cargando...'}
                noOptionsText="No hay coincidencias"
                name="actividad"
                onChange={(ev, actividadSelected, reason) => {
                  const data = { name: 'actividad', value: actividadSelected };
                  if (reason === 'clear') {
                    data.value = '';
                  }

                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  data.value
                    ? setFieldValue('actividad', data.value)
                    : setFieldValue('actividad', '');
                }}
                onBlur={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldValue('rutModificado', false);
                  handleBlur(e);
                }}
                getOptionLabel={option => option.nombreActividad || ''}
                value={values.actividad ? values.actividad : ''}
              >
                <InputComponent
                  autoComplete={'off'}
                  disabled={
                    errors.rutEmpresa ||
                    cargandoServicioSII ||
                    isLoadingActividades
                  }
                  fullWidth
                  label={<LabelComponent>Actividad</LabelComponent>}
                  touched={touched.actividad}
                  estado={
                    errors.actividad
                      ? { tipo: 'error', mensaje: errors.actividad }
                      : null
                  }
                />
              </AutocompleteComponent>
            </div>
          </>
        )}
        {/* RN : solo mostrar cuando el tipo de negocio es persona-natural */}
        {values.tipoNegocio === 'persona-natural' && (
          <div className="col-span-12">
            <Heading type="h4" className="mb-0">
              Nombre
            </Heading>
            <Text className="mb-2">
              Corresponde al nombre por el cual los usuarios te identificarán
            </Text>

            <InputAsyncController
              name="nombre"
              touched={touched.nombre ? true : false}
              fullWidth
              setFieldValue={data => setNombreNegocio(data)}
              setFieldTouched={() => setFieldTouched('nombre')}
              value={nombreNegocio}
              isSubmitting={isSubmitting}
              onBlur={() => setFieldTouched('nombre')}
              onChange={event => {
                //setFieldValue('nombre', event.target.value);
                setNombreNegocio(event.target.value);
                setFieldTouched('nombre');
              }}
              fetchCall={controllerValues =>
                obtenerTodasEmpresasAT({
                  setLoadingFetch: controllerValues.setIsLoading,
                  value: controllerValues.inputValue
                })
              }
              estado={
                errors.nombre ? { tipo: 'error', mensaje: errors.nombre } : null
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessType;
