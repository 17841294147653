import ChatBubble from '@mui/icons-material/ChatBubble';
import Close from '@mui/icons-material/Close';
import PlaceRounded from '@mui/icons-material/PlaceRounded';
import { Divider } from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { RoleComponent } from 'components/design-system/Role/Role';
import Text from 'components/design-system/Text/Text';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MatchContext } from '../../../../Residuos/context/MatchContext';
import { Lock } from '@mui/icons-material';
import LinkComponent from 'components/design-system/Link/Link';
import { DICTONARY } from 'const/Dictonary';
import { RUTAS } from 'const/Rutas';
import { Documentos } from './Documentos';
import { useReunion } from 'hooks/useReunion';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { PERMISOS } from 'const/Permisos';
import CallIcon from '@mui/icons-material/Call';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {
  obtenerAutorizacionesDeObjeto,
  obtenerGradoAutorizacionesSanitarias
} from 'helpers/autorizaciones';
import { Anchor } from 'components/design-system/Anchor/Anchor';
import Circle from '@mui/icons-material/Circle';

export const EmpresaDetalle = () => {
  const {
    tieneNegocio,
    salasChat,
    sucursalSeleccionada,
    setSucursalSeleccionado,
    cargarSalasChat
  } = useContext(MatchContext);

  const empresa = sucursalSeleccionada;
  const { handleCrearReunion, isCreandoReunion } = useReunion();
  const [isSalaChatCreada, setIsSalaChatCreada] = useState(false);
  const [isEmpresaEnChat, setEmpresaEnChat] = useState(false);

  const handleSolicitarReunion = async () => {
    try {
      await handleCrearReunion({
        codigoSucursal: empresa.codigoSucursal,
        origenSalaChat: 'empresa'
      });
      cargarSalasChat({ tieneNegocio: tieneNegocio });
      setIsSalaChatCreada(true);
    } catch (error) {
      console.log('ERROR_AL_CREAR_SALA_CHAT ', error);
    }
  };

  const isRecicladorBase = useMemo(() => {
    return (
      (empresa &&
        empresa.rol &&
        empresa.rol.some(
          rol => rol.codigoRol === DICTONARY.ROL.RECICLADORDEBASE.CODIGO
        )) ||
      false
    );
  }, [empresa]);

  useEffect(() => {
    if (empresa && empresa.empresa) {
      setEmpresaEnChat(
        salasChat.empresas.find(suc =>
          suc.nombreSalaChat.includes(empresa.empresa)
        )
      );
    }
  }, [salasChat, empresa]);

  if (!sucursalSeleccionada) {
    return;
  }

  return (
    <div>
      <div className="w-full shadow-md bg-white">
        <div className="h-[80vh] scrollbar-uv overflow-y-auto p-4 w-full">
          <div>
            <div className="relative">
              <Heading type="h3" className="pr-10 mb-0">
                {empresa.empresa ? empresa.empresa : 'Nombre no definido'}
                <div className="absolute -right-1 top-0">
                  <IconButtonComponent
                    onClick={() => setSucursalSeleccionado(null)}
                    size="small"
                    type="neutral"
                  >
                    <Close />
                  </IconButtonComponent>
                </div>
              </Heading>
              <div className="mb-2">
                {empresa.rol &&
                  empresa.rol.map(rol => (
                    <RoleComponent
                      className="mr-1.5"
                      key={rol.codigoRol}
                      rol={rol.nombreRol}
                      verbo="nombre"
                      tipo="etiqueta"
                      gradoAutorizacionesSanitarias={obtenerGradoAutorizacionesSanitarias(
                        obtenerAutorizacionesDeObjeto(empresa)
                      )}
                    />
                  ))}
              </div>
              <div className="relative">
                <span className="pl-8 block font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase">
                  {empresa.nombreSucursal ? empresa.nombreSucursal : 'Sucursal'}
                </span>

                <div className="flex items-center">
                  <PlaceRounded className="mr-2 text-uv-secondary-10" />
                  <Text>
                    {empresa.direccion ? (
                      <>
                        {empresa.direccion?.nombreVia}
                        {', '}
                        {empresa.direccion?.comuna}
                        {',  Región '}
                        {empresa.direccion?.region}
                      </>
                    ) : (
                      'Dirección no definida'
                    )}
                  </Text>
                </div>

                {isRecicladorBase && (
                  <div className="flex items-center">
                    <CallIcon className="mr-2 text-uv-secondary-10" />
                    <Text>
                      {empresa.telefono ? (
                        <Anchor href={`tel:${empresa.telefono}`}>
                          {empresa.telefono}
                        </Anchor>
                      ) : (
                        'Teléfono no definido'
                      )}
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            {isRecicladorBase && (
              <>
                <Divider className="my-3" />
                <div className="flex items-center">
                  {' '}
                  <div className="w-4/5">
                    <span className="pl-8 block font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase">
                      Reciclador base{' '}
                      {obtenerGradoAutorizacionesSanitarias(
                        obtenerAutorizacionesDeObjeto(empresa)
                      )}
                    </span>

                    {empresa.autorizacionTransporte && (
                      <div className="flex items-center">
                        <TaskAltIcon className="mr-2 text-uv-secondary-10" />
                        <Text>Autorización sanitaria en transporte</Text>
                      </div>
                    )}

                    {empresa.autorizacionAcopio && (
                      <div className="flex items-center">
                        <TaskAltIcon className="mr-2 text-uv-secondary-10" />
                        <Text>Autorizacion sanitaria lugar de acopio</Text>
                      </div>
                    )}

                    {empresa.registroNacionalRDB && (
                      <div className="flex items-center">
                        <TaskAltIcon className="mr-2 text-uv-secondary-10" />
                        <Text>Registro nacional de recicladores de base</Text>
                      </div>
                    )}
                  </div>
                  <div className="w-1/5 flex justify-center">
                    <RoleComponent
                      arrayRol={empresa.rol}
                      tipo="marcador"
                      className="mr-4 !w-16 !h-16"
                      nombreSucursal={empresa.nombreSucursal}
                      sucursal={empresa}
                      gradoAutorizacionesSanitarias={obtenerGradoAutorizacionesSanitarias(
                        obtenerAutorizacionesDeObjeto(empresa)
                      )}
                    />
                  </div>
                </div>

                {Array.isArray(empresa.clientes) &&
                  empresa.clientes.length > 0 && (
                    <>
                      <Divider className="my-3" />
                      <div>
                        <span className="pl-8 block font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase">
                          Clientes destacados
                        </span>

                        <div className="col-span-8">
                          {empresa.clientes.map((cliente, index) => (
                            <Text
                              key={index}
                              className="mb-2 relative gap-2 flex"
                            >
                              <Circle className="text-uv-secondary-0 w-1.5 h-1.5 mt-1.5 top-0.5 relative" />
                              {cliente.nombreCliente}
                            </Text>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
              </>
            )}
            <Divider className="my-3" />

            {!tieneNegocio && (
              <div>
                <div className="bg-warning-light rounded px-4 py-2">
                  <div className="flex">
                    <Lock className="mr-2 text-warning-dark" />
                    <Text size="S">
                      Solo{' '}
                      <b className="text-black">
                        usuarios con negocios registrados
                      </b>{' '}
                      pueden comunicarse internamente en la plataforma.{' '}
                      <LinkComponent href={RUTAS.REGISTRAR_NEGOCIO}>
                        Registrar un negocio
                      </LinkComponent>
                    </Text>
                  </div>
                </div>
              </div>
            )}

            {isEmpresaEnChat ? (
              <VerificarPermisos nombrePermiso={PERMISOS.salasReunion}>
                <div className="my-4">
                  <ButtonComponent
                    onClick={() =>
                      window.open(
                        `${RUTAS.CHAT}/${isEmpresaEnChat.codigoSalaChat}`,
                        '_blank'
                      )
                    }
                    type="primary"
                  >
                    <ChatBubble className="mr-1" /> Abrir Sala de reunión
                  </ButtonComponent>
                </div>
              </VerificarPermisos>
            ) : (
              <VerificarPermisos nombrePermiso={PERMISOS.solicitarReunion}>
                <div className="my-4">
                  <ButtonComponent
                    isLoading={isCreandoReunion}
                    disabled={
                      isSalaChatCreada ||
                      !tieneNegocio ||
                      isCreandoReunion ||
                      isEmpresaEnChat
                    }
                    onClick={() => handleSolicitarReunion()}
                    type="primary"
                  >
                    <ChatBubble className="mr-1" /> Solicitar Reunión
                  </ButtonComponent>
                </div>
              </VerificarPermisos>
            )}

            <Divider className="my-3" />

            {empresa.rubro && (
              <div className="mb-4">
                <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
                  RUBRO
                </span>
                <Text className="text-black">{empresa.rubro}</Text>
              </div>
            )}

            {empresa.actividad && (
              <div className="mb-4">
                <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5 uppercase">
                  Actividad económica
                </span>
                <Text className="text-black">{empresa.actividad}</Text>
              </div>
            )}
            {isRecicladorBase && empresa.areaTrabajo && (
              <div className="mb-4">
                <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5 uppercase">
                  ÁREA DE TRABAJO
                </span>
                <Text className="text-black">{empresa.areaTrabajo}</Text>
              </div>
            )}

            <Documentos empresa={empresa} key={empresa.codigoEmpresa} />
          </div>
        </div>
      </div>
    </div>
  );
};
