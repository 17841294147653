import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { Close, Help } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table as TableMui
} from '@mui/material';
import { useState } from 'react';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';

export const DescripcionEmpresa = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const explicacionTamanioEmpresa = [
    {
      tamano: 1,
      categoria: 'Sin información',
      descripcion:
        'corresponde a contribuyentes cuya información tributaria declarada, no permite determinar un monto estimado de ventas.'
    },
    {
      tamano: 2,
      categoria: '1er Rango Micro Empresa',
      descripcion: '0.01 a 200 UF Anuales'
    },
    {
      tamano: 3,
      categoria: '2do Rango Micro Empresa',
      descripcion: '200,01 a 600 UF Anuales'
    },
    {
      tamano: 4,
      categoria: '3er Rango Micro Empresa',
      descripcion: '600,01 a 2.400 UF Anuales'
    },
    {
      tamano: 5,
      categoria: '1er Rango Pequeña Empresa',
      descripcion: '2.400,01 a 5.000 UF Anuales'
    },
    {
      tamano: 6,
      categoria: '2do Rango Pequeña Empresa',
      descripcion: '5.000,01 a 10.000 UF Anuales'
    },
    {
      tamano: 7,
      categoria: '3er Rango Pequeña Empresa',
      descripcion: '10.000,01 a 25.000 UF Anuales'
    },
    {
      tamano: 8,
      categoria: '1er Rango Mediana Empresa',
      descripcion: '25.000,01 a 50.000 UF Anuales'
    },
    {
      tamano: 9,
      categoria: '2do Rango Mediana Empresa',
      descripcion: '50.000,01 a 100.000 UF Anuales'
    },
    {
      tamano: 10,
      categoria: '1er Rango Gran Empresa',
      descripcion: '100.000,01 a 200.000 UF Anuales'
    },
    {
      tamano: 11,
      categoria: '2do Rango Gran Empresa',
      descripcion: '200.000,01 a 600.000 UF Anuales'
    },
    {
      tamano: 12,
      categoria: '3er Rango Gran Empresa',
      descripcion: '600.000,01 a 1.000.000 UF Anuales'
    },
    {
      tamano: 13,
      categoria: '4to Rango Gran Empresa',
      descripcion: 'más de 1.000.000,01 UF Anuales'
    }
  ];

  return (
    <>
      <IconButton
        size="small"
        type="neutral"
        onClick={() => handleClickOpen()}
        className="-mt-1.5"
      >
        <Help className="text-uv-secondary-0" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="relative">
          <Heading type="h4">Descripción tamaño de empresa</Heading>
          <div className="absolute right-3 top-2 z-10">
            <IconButtonComponent
              onClick={() => handleClose()}
              size="small"
              type="neutral"
            >
              <Close />
            </IconButtonComponent>
          </div>
        </DialogTitle>
        <DialogContent className="z-[10001] relative">
          <Text className="mb-4">
            El tamaño de una empresa se clasifica en los siguientes rangos, en
            base al cálculo de las ventas anuales de un contribuyente:
          </Text>

          <div className="bg-uv-primary-90 rounded">
            <TableContainer>
              <TableMui
                size="small"
                className="font-primary w-full"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Text size="M" className="font-semibold text-black">
                        Tamaño
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text size="M" className="font-semibold text-black">
                        Categoría
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text size="M" className="font-semibold text-black">
                        Descripción
                      </Text>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {explicacionTamanioEmpresa.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" width="16%">
                        <Text className="font-semibold text-black">
                          {item.tamano}
                        </Text>
                      </TableCell>
                      <TableCell width="34%" className="px-0">
                        <Text size="S">{item.categoria}</Text>
                      </TableCell>
                      <TableCell width="50%" className="pr-0">
                        <Text size="S">{item.descripcion}</Text>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TableMui>
            </TableContainer>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};
