import Heading from 'components/design-system/Heading/Heading';
import { Skeleton } from '@mui/material';
import Text from 'components/design-system/Text/Text';

export const BloquesIndicadoresSkeleton = () => {
  return (
    <div>
      <div className="mb-6">
        <Heading type="h3" className="font-bold text-uv-primary-0">
          Transacciones en módulo Marketplace
        </Heading>
        <div className="grid grid-cols-12 gap-4 mb-4">
          <div className="col-span-12 md:col-span-6">
            <Text>
              <b>Marketplace:</b> Puedes encontrar ofertas o requerimientos de
              recursos sub-utilizados (descartes, residuos), y servicios
              relacionados, y con ello aumentar tu circularidad y reducir costos
              de gestión de residuos.
            </Text>
          </div>
          <div className="col-span-12 md:col-span-6">
            <Text>El módulo permite:</Text>
            <ul>
              <li>
                <Text className="relative pl-3">
                  <span className="w-1 h-1 bg-uv-secondary-0 inline-block rounded-full absolute left-0 top-2"></span>
                  Ofertar o requerir recursos sub-utilizados
                </Text>
              </li>
              <li>
                <Text className="relative pl-3">
                  <span className="w-1 h-1 bg-uv-secondary-0 inline-block rounded-full absolute left-0 top-2"></span>
                  Conectar con empresas
                </Text>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex shadow-md flex-col md:flex-row">
          <div className="w-full md:w-2/5 lg:w-1/4 bg-uv-primary-0 rounded-tl rounded-bl">
            <div className="px-6 py-4">
              <div className="mb-8 mt-2">
                <Skeleton
                  variant="rounded"
                  className="w-full h-5 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
                <Skeleton
                  variant="rounded"
                  className="w-full h-14 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
              </div>

              <div className="mb-8 mt-2">
                <Skeleton
                  variant="rounded"
                  className="w-full h-5 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
                <Skeleton
                  variant="rounded"
                  className="w-full h-14 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
              </div>
            </div>
          </div>
          <div className="flex-grow w-auto px-6 py-4 bg-white rounded-tr rounded-br">
            <Skeleton
              variant="rounded"
              className="w-full h-7 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 mt-2 gap-5">
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <div className="grid grid-cols-12 gap-4 mb-4">
          <div className="col-span-12 md:col-span-6">
            <Heading type="h3" className="font-bold text-uv-primary-0">
              Declaración en módulo Gestiona
            </Heading>
            <Text>
              <b>Gestiona:</b> Puedes medir las “entradas” y “salidas”
              (residuos), y con ello el nivel de circularidad de tu empresa.
            </Text>
          </div>
          <div className="col-span-12 md:col-span-6">
            <Text>El módulo permite:</Text>
            <ul>
              <li>
                <Text className="relative pl-3">
                  <span className="w-1 h-1 bg-uv-secondary-0 inline-block rounded-full absolute left-0 top-2"></span>
                  Crear y administrar compras de insumos
                </Text>
              </li>
              <li>
                <Text className="relative pl-3">
                  <span className="w-1 h-1 bg-uv-secondary-0 inline-block rounded-full absolute left-0 top-2"></span>
                  Crear y administrar la generación de residuos
                </Text>
              </li>
              <li>
                <Text className="relative pl-3">
                  <span className="w-1 h-1 bg-uv-secondary-0 inline-block rounded-full absolute left-0 top-2"></span>
                  Prospectar el impacto ambiental de la gestión
                </Text>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex shadow-md flex-col md:flex-row">
          <div className="w-full md:w-2/5 lg:w-1/4 bg-uv-primary-0 rounded-tl rounded-bl">
            <div className="px-6 py-4">
              <div className="mb-8 mt-2">
                <Skeleton
                  variant="rounded"
                  className="w-full h-5 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
                <Skeleton
                  variant="rounded"
                  className="w-full h-14 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
              </div>

              <div className="mb-8 mt-2">
                <Skeleton
                  variant="rounded"
                  className="w-full h-5 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
                <Skeleton
                  variant="rounded"
                  className="w-full h-14 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
              </div>
            </div>
          </div>
          <div className="flex-grow w-auto px-6 py-4 bg-white rounded-tr rounded-br">
            <Skeleton
              variant="rounded"
              className="w-full h-7 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 mt-2 gap-5">
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
