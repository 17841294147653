import { Group } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';

export const EncabezadoEmpresa = ({ roomData, toggleMenu }) => {
  return (
    <div className="shadow-sm px-5 py-2 border-b border-b-neutral-90">
      <div className="grid grid-cols-12 gap-5 items-center">
        <div className="col-span-10">
          <Heading type="h3" className="mb-0">
            {roomData
              ? '# ' + roomData.codigoSalaChat + ': ' + roomData.nombreSalaChat
              : 'Sala sin Nombre'}
          </Heading>
        </div>
        <div className="col-span-2 text-end">
          <IconButtonComponent type="secondary" onClick={toggleMenu}>
            <Tooltip
              title="Listado de participantes del chat"
              aria-label="Listado de participantes del chat"
            >
              <Group />
            </Tooltip>
          </IconButtonComponent>
        </div>
      </div>
    </div>
  );
};
