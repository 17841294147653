import { useContext, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { useHelpersResiduosDeclarados } from './hooks/useHelpersResiduosDeclarados';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';
import { SekeltonListadoResiduosDeclarados } from './skeleton/SkeletonListadoResiduosDeclarados';
import {
  obtenerEmpresasGeneradoras,
  obtenerEmpresasReceptoras
} from '../CargaMasiva/hooks/useServices';
import { Resultados } from './Lista/Resultados';
import { FiltrarResultados } from './Lista/FiltrarResultados';

const ListaResiduosDeclarados = () => {
  const { isTienePermisos } = useContext(AuthContext);
  const [negocioSeleccionado, setNegocioSeleccionado] = useState(null);
  const { handleClickIngresar } = useHelpersResiduosDeclarados();

  const [tipoNegocio, setTipoNegocio] = useState('generador'); // generador, receptor

  const [dataNegocios, setDataNegocios] = useState([]);
  const [dataResiduos, setDataResiduos] = useState([]);
  const [isLoadingEmpresas, setLoadingEmpresas] = useState(false);
  const [rowCountEmpresas, setRowCountEmpresas] = useState(0);
  const [selectedNegocio, setSelectedNegocio] = useState(null);

  const _setDataNegocios = negocios => {
    const negociosFiltrados = negocios
      .filter(
        (negocio, index, self) =>
          index === self.findIndex(t => t.label === negocio.label)
      )
      .sort((a, b) => a.label.localeCompare(b.label));

    setDataNegocios(negociosFiltrados);
  };

  useEffect(() => {
    if (!isTienePermisos(PERMISOS.empresaGeneradoraResiduoDeclarado)) {
      setTipoNegocio('receptor');
    }
  }, []);

  const cargarEmpresas = () => {
    if (
      tipoNegocio === 'generador' &&
      isTienePermisos(PERMISOS.empresaGeneradoraResiduoDeclarado)
    ) {
      obtenerEmpresasGeneradoras({
        setLoadingEmpresas,
        paginationEmpresas: null,
        setEmpresas: _setDataNegocios,
        setRowCountEmpresas,
        nombreEmpresa: null
      });
    }

    if (tipoNegocio === 'receptor') {
      obtenerEmpresasReceptoras({
        setLoadingEmpresas,
        paginationEmpresas: null,
        setEmpresas: _setDataNegocios,
        setRowCountEmpresas,
        nombreEmpresa: null
      });
    }
  };

  useEffect(() => {
    setDataNegocios([]);
    cargarEmpresas();
  }, [tipoNegocio]);

  // Estado para mostrar más elementos TODO: se puede mostrar paginación
  const [itemsToShow, setItemsToShow] = useState(5);
  // Función para mostrar más elementos
  const handleShowMore = () => {
    setItemsToShow(prev => prev + 5);
  };

  return (
    <SidebarLayout>
      <div className="flex flex-col sm:flex-row sm:items-center mb-4 sm:justify-between gap-4">
        <div className="w-full sm:w-1/2 lg:w-2/3">
          <Heading type="h2" className="mb-0">
            Administración de residuo declarado
          </Heading>
          <Text>
            Crea, edita, duplica y elimina los registros de residuos declarados
          </Text>
        </div>
        <div>
          {isTienePermisos(PERMISOS.crearResiduoDeclarado) && (
            <ButtonComponent
              type="primary"
              onClick={() => {
                handleClickIngresar();
              }}
            >
              <Add className="mr-2" /> Residuo declarado
            </ButtonComponent>
          )}
        </div>
      </div>

      {isLoadingEmpresas ? (
        <SekeltonListadoResiduosDeclarados />
      ) : (
        <>
          <FiltrarResultados
            ocultar={negocioSeleccionado ? true : false}
            dataNegocios={dataNegocios}
            dataResiduos={dataResiduos}
            setDataResiduos={setDataResiduos}
            setItemsToShow={setItemsToShow}
            selectedNegocio={selectedNegocio}
            setSelectedNegocio={setSelectedNegocio}
            tipoNegocio={tipoNegocio}
            setTipoNegocio={setTipoNegocio}
          />
          <Resultados
            setNegocioSeleccionado={setNegocioSeleccionado}
            negocioSeleccionado={negocioSeleccionado}
            dataNegocios={dataNegocios}
            dataResiduos={dataResiduos}
            itemsToShow={itemsToShow}
            setItemsToShow={setItemsToShow}
            handleShowMore={handleShowMore}
            selectedNegocio={selectedNegocio}
            tipoNegocio={tipoNegocio}
            cargarEmpresas={cargarEmpresas}
          />
        </>
      )}
    </SidebarLayout>
  );
};

export default ListaResiduosDeclarados;
