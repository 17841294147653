import * as yup from 'yup';
import { verificaCorreoRegistrado } from '../helpers/verificaCorreoRegistrado';
import { DICTONARY } from 'const/Dictonary';
import debounce from 'lodash/debounce';

const emailRegex = DICTONARY.EMAIL_REGEX;

// Función debounced para la validación del correo
const debouncedCheckEmail = debounce(async (email, setIsLoadingEmail) => {
  setIsLoadingEmail(true);
  const isRegistered = await verificaCorreoRegistrado(email);
  setIsLoadingEmail(false);
  return !isRegistered; // Retorna true si el correo es válido
}, 500); // Tiempo de espera en ms

const schemaSignUp = yup.object().shape({
  name: yup
    .string()
    .trim()
    .matches(
      /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+\s[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+\s[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/,
      'El nombre debe contener al menos el primer nombre, apellido paterno y apellido materno'
    )
    .required('Este campo es requerido'),

  email: yup
    .string()
    .trim()
    .matches(emailRegex, 'El correo electrónico no tiene el formato correcto')
    .required('El correo electrónico es requerido')
    .test(
      'Evaluación de correo',
      'El correo electrónico ingresado está registrado',
      async function (value) {
        const { parent } = this;
        const emailModificado = parent.emailModificado || false;
        const setIsLoadingEmail = parent.setIsLoadingEmail || (() => {});

        // Si el correo no fue modificado o no cumple con el formato, no verifica
        if (!emailModificado || !emailRegex.test(value.trim())) {
          return true;
        }

        // Usar la función debounced para verificar el correo
        return await debouncedCheckEmail(value.trim(), setIsLoadingEmail);
      }
    ),

  password1: yup
    .string()
    .trim()
    .test(
      'length',
      'La contraseña debe tener al menos 8 caracteres',
      value => value.length >= 8
    )
    .matches(/([0-9])/, 'numero')
    .matches(/([A-Z])/, 'mayuscula')
    .matches(/([a-z])/, 'minuscula')
    .matches(/([!@#$%^&*(),.?":{}|<>])/, 'caracter')
    .required('La contraseña es requerida'),

  password2: yup
    .string()
    .trim()
    .oneOf([yup.ref('password1'), null], 'Las contraseñas no coinciden')
    .required('Confirme la contraseña')
});

export { schemaSignUp };
