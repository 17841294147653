export const ICON_RESIDUO_VALORIZADO = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_626_142)">
        <circle
          cx="31"
          cy="31"
          r="31"
          transform="matrix(1 0 0 -1 0 62)"
          fill="#307453"
        />
        <path
          d="M40.734 23.9551H22.468C20.794 23.9551 19.704 25.3251 19.355 26.9821L16 44.9551C16.0018 45.7618 16.3229 46.5351 16.8931 47.1058C17.4633 47.6766 18.2362 47.9984 19.043 48.0011H44.283C45.0898 47.9984 45.8627 47.6766 46.4329 47.1058C47.0031 46.5351 47.3242 45.7618 47.326 44.9551L43.774 26.9991C43.251 25.1321 42.404 23.9551 40.734 23.9551ZM44.286 44.9551H19.043L22.468 26.9991H40.734L44.286 44.9551Z"
          fill="white"
        />
        <path
          d="M31.74 26.732C33.1969 26.732 34.621 26.3 35.8324 25.4906C37.0437 24.6812 37.9878 23.5308 38.5453 22.1848C39.1028 20.8389 39.2487 19.3578 38.9645 17.929C38.6803 16.5001 37.9787 15.1876 36.9486 14.1575C35.9184 13.1273 34.6059 12.4258 33.1771 12.1415C31.7482 11.8573 30.2671 12.0032 28.9212 12.5607C27.5752 13.1182 26.4248 14.0623 25.6154 15.2737C24.806 16.485 24.374 17.9091 24.374 19.366C24.3761 21.3189 25.1529 23.1913 26.5338 24.5722C27.9147 25.9531 29.7871 26.7299 31.74 26.732ZM31.74 15.158C32.5725 15.158 33.3863 15.4049 34.0784 15.8673C34.7706 16.3298 35.3101 16.9872 35.6286 17.7563C35.9472 18.5254 36.0306 19.3717 35.8681 20.1881C35.7057 21.0046 35.3049 21.7546 34.7162 22.3432C34.1276 22.9319 33.3776 23.3327 32.5612 23.4951C31.7447 23.6575 30.8984 23.5742 30.1293 23.2556C29.3602 22.937 28.7029 22.3976 28.2404 21.7054C27.7779 21.0132 27.531 20.1995 27.531 19.367C27.5342 18.2517 27.9787 17.1829 28.7673 16.3943C29.556 15.6056 30.6247 15.1612 31.74 15.158Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_626_142">
          <rect width="62" height="62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
