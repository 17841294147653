import axios from 'axios';
import Qs from 'qs';
import URLS from '../URLS';

const API = {
  axios: axios.create({
    baseURL: URLS.baseURL,
    withCredentials: false,
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: 'repeat' });
    }
  })
};

export default API;
