export const initialValuesIngresarEmpresaAT = {
  rutEmpresa: '',
  razonSocial: '',
  tramo: null,
  actividad: '',
  nombreVia: '',
  comuna: '',
  region: '',
  potencialSinergias: []
};
