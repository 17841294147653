import { useMemo } from 'react';
import moment from 'moment';
import Text from 'components/design-system/Text/Text';

export const useColumnTablesListadoRecursosAdquiridos = () => {
  const columnTables = useMemo(
    () => [
      {
        header: 'Código',
        enableGlobalFilter: false,
        accessorKey: 'codigoRecursoAdquirido',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 20,
        // maxSize: 20,
        // minSize: 10,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        }
      },
      {
        header: 'Sucursal de recepción',
        accessorKey: 'sucursal.nombreSucursal',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>
        // size: 150,
        // maxSize: 220,
        // minSize: 100,
      },
      {
        header: 'Tipo recurso adquirido',
        accessorKey: 'tipoRecursoAdquirido.nombreTipoRecursoAdquirido',
        Cell: ({ renderedCellValue }) => (
          <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
        )
        // size: 150,
        // maxSize: 220,
        // minSize: 100,
      },
      {
        header: 'Cantidad (unidad)',
        enableGlobalFilter: false,
        accessorKey: 'cantidadUnidad',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false
        // size: 150,
        // maxSize: 220,
        // minSize: 100,
      },
      {
        header: 'Fecha de adquisición',
        enableGlobalFilter: false,
        accessorKey: 'fechaAdquisicion',
        Cell: ({ renderedCellValue }) => (
          <Text>
            {' '}
            {renderedCellValue !== 'N/A'
              ? moment(renderedCellValue).format('DD-MM-YYYY')
              : 'N/A'}
          </Text>
        ),
        enableColumnActions: false,
        enableColumnFilter: false
      }
    ],
    []
  );

  return columnTables;
};
