import HighlightOff from '@mui/icons-material/HighlightOff';
import PanoramaFishEye from '@mui/icons-material/PanoramaFishEye';
import TaskAlt from '@mui/icons-material/TaskAlt';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';
import IconComponent from 'components/design-system/icon/Icon';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
import { useState } from 'react';
import * as yup from 'yup';

const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'largo')
    .matches(/[a-z]/, 'minuscula')
    .matches(/[A-Z]/, 'mayuscula')
    .matches(/\d/, 'numero')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'caracter')
});

const ContrasenaCampos = ({
  values,
  handleChange,
  setFieldTouched,
  touched,
  errors,
  isSubmitting,
  setFieldValue
}) => {
  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1(show => !show);

  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(show => !show);

  const [showPassword3, setShowPassword3] = useState(false);
  const handleClickShowPassword3 = () => setShowPassword3(show => !show);

  const customHandler = event => {
    handleChange(event);

    passwordSchema
      .validate({ password: event.target.value }, { abortEarly: false })
      .then(() => {
        setFieldValue('validacionPassword', []);
      })
      .catch(errors => {
        setFieldValue(
          'validacionPassword',
          errors.inner.map(errorItem => errorItem.message)
        );
      });
  };

  return (
    <div className="col-span-12 md:col-span-9">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-6">
          <InputComponent
            fullWidth
            autoComplete="old-password"
            name="passwordActual"
            disabled={isSubmitting ? true : false}
            type={showPassword1 ? 'text' : 'password'}
            value={values.passwordActual}
            onChange={handleChange}
            label={<LabelComponent>Contraseña actual</LabelComponent>}
            onBlur={() => setFieldTouched('passwordActual')}
            touched={touched.passwordActual ? true : false}
            estado={
              errors.passwordActual
                ? { tipo: 'error', mensaje: errors.passwordActual }
                : null
            }
            outerInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleClickShowPassword1}>
                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className="col-span-6"></div>
        <div className="col-span-12 md:col-span-6">
          <InputComponent
            fullWidth
            name="password"
            autoComplete="nueva-password"
            disabled={isSubmitting ? true : false}
            type={showPassword2 ? 'text' : 'password'}
            value={values.password}
            onChange={event => customHandler(event)}
            label={<LabelComponent>Nueva contraseña</LabelComponent>}
            onBlur={() => setFieldTouched('password')}
            touched={touched.password ? true : false}
            estado={errors.password ? { tipo: 'error', mensaje: '' } : null}
            outerInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleClickShowPassword2}>
                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <InputComponent
            fullWidth
            name="confirmarPassword"
            autoComplete="nueva-password-repetir"
            disabled={isSubmitting ? true : false}
            type={showPassword3 ? 'text' : 'password'}
            value={values.confirmarPassword}
            onChange={handleChange}
            label={<LabelComponent>Repetir nueva contraseña</LabelComponent>}
            onBlur={() => setFieldTouched('confirmarPassword')}
            touched={touched.confirmarPassword ? true : false}
            estado={
              errors.confirmarPassword
                ? { tipo: 'error', mensaje: errors.confirmarPassword }
                : null
            }
            outerInputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleClickShowPassword3}>
                    {showPassword3 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
      </div>
      <div className="col-span-12 mt-4">
        <Text>La contraseña debe cumplir los siguientes criterios:</Text>
        {touched.password ? (
          <ul className="mt-4 mb-4">
            <li className="flex items-center mb-1">
              <IconComponent className="mr-2">
                {values.validacionPassword.length > 0 &&
                values.validacionPassword.includes('largo') ? (
                  <HighlightOff className="text-base text-error-dark" />
                ) : (
                  <TaskAlt className="text-base text-success-dark" />
                )}
              </IconComponent>
              <Text
                className={`
                  ${
                    values.validacionPassword.length > 0 &&
                    values.validacionPassword.includes('largo')
                      ? 'text-error-dark'
                      : 'text-success-dark'
                  }
                  `}
              >
                Debe tener al menos 8 carácteres
              </Text>
            </li>

            <li className="flex items-center mb-1">
              <IconComponent className="mr-2">
                {values.validacionPassword.length > 0 &&
                values.validacionPassword.includes('numero') ? (
                  <HighlightOff className="text-base text-error-dark" />
                ) : (
                  <TaskAlt className="text-base text-success-dark" />
                )}
              </IconComponent>
              <Text
                className={`
                  ${
                    values.validacionPassword.length > 0 &&
                    values.validacionPassword.includes('numero')
                      ? 'text-error-dark'
                      : 'text-success-dark'
                  }
                  `}
              >
                Tiene al menos un número
              </Text>
            </li>

            <li className="flex items-center mb-1">
              <IconComponent className="mr-2">
                {values.validacionPassword.length > 0 &&
                values.validacionPassword.includes('mayuscula') ? (
                  <HighlightOff className="text-base text-error-dark" />
                ) : (
                  <TaskAlt className="text-base text-success-dark" />
                )}
              </IconComponent>
              <Text
                className={`
                  ${
                    values.validacionPassword.length > 0 &&
                    values.validacionPassword.includes('mayuscula')
                      ? 'text-error-dark'
                      : 'text-success-dark'
                  }
                  `}
              >
                Tiene al menos una mayúscula
              </Text>
            </li>

            <li className="flex items-center mb-1">
              <IconComponent className="mr-2">
                {values.validacionPassword.length > 0 &&
                values.validacionPassword.includes('minuscula') ? (
                  <HighlightOff className="text-base text-error-dark" />
                ) : (
                  <TaskAlt className="text-base text-success-dark" />
                )}
              </IconComponent>
              <Text
                className={`
                  ${
                    values.validacionPassword.length > 0 &&
                    values.validacionPassword.includes('minuscula')
                      ? 'text-error-dark'
                      : 'text-success-dark'
                  }
                  `}
              >
                Tiene al menos una minuscula
              </Text>
            </li>

            <li className="flex items-center mb-1">
              <IconComponent className="mr-2">
                {values.validacionPassword.length > 0 &&
                values.validacionPassword.includes('caracter') ? (
                  <HighlightOff className="text-base text-error-dark" />
                ) : (
                  <TaskAlt className="text-base text-success-dark" />
                )}
              </IconComponent>
              <Text
                className={`
                  ${
                    values.validacionPassword.length > 0 &&
                    values.validacionPassword.includes('caracter')
                      ? 'text-error-dark'
                      : 'text-success-dark'
                  }
                  `}
              >
                Tiene un carácter especial ej: ej: #@,"
              </Text>
            </li>
          </ul>
        ) : (
          <ul className="mt-4 mb-4">
            <li className="flex items-center mb-1">
              <IconComponent className="mr-2">
                <PanoramaFishEye className="text-base" />
              </IconComponent>
              <Text>Debe tener al menos 8 carácteres</Text>
            </li>

            <li className="flex items-center mb-1">
              <IconComponent className="mr-2">
                <PanoramaFishEye className="text-base" />
              </IconComponent>
              <Text>Tiene al menos un número</Text>
            </li>

            <li className="flex items-center mb-1">
              <IconComponent className="mr-2">
                <PanoramaFishEye className="text-base" />
              </IconComponent>
              <Text>Tiene al menos una mayúscula</Text>
            </li>

            <li className="flex items-center mb-1">
              <IconComponent className="mr-2">
                <PanoramaFishEye className="text-base" />
              </IconComponent>
              <Text>Tiene al menos una minuscula</Text>
            </li>

            <li className="flex items-center mb-1">
              <IconComponent className="mr-2">
                <PanoramaFishEye className="text-base" />
              </IconComponent>
              <Text>Tiene un carácter especial ej: ej: #@,"</Text>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default ContrasenaCampos;
