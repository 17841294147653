import { DoDisturbOff } from '@mui/icons-material';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import LinkComponent from 'components/design-system/Link/Link';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import { RUTAS } from 'const/Rutas';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export const NoAutorizado = () => {
  return (
    <SidebarLayout>
      <div className="justify-center items-center flex flex-col sm:p-10 max-w-lg mx-auto gap-4 mb-10">
        <DoDisturbOff className="w-20 h-20 text-uv-primary-80" />
        <Heading type="h2">Acceso denegado</Heading>
        <Text className="text-center">
          La funcionalidad a la cúal estás intentando acceder está restringida
          para ciertos usuarios y el usuario con el cúal has accedido no tiene
          los permisos necesarios.
        </Text>

        <div className="flex flex-col md:flex-row gap-4">
          <LinkComponent
            to={RUTAS.HOME_USER}
            type="button"
            className="justify-center"
          >
            Volver al Inicio
          </LinkComponent>
          <Link
            to={{ pathname: DICTONARY.URL.REPORTAR_PROBLEMA }}
            target="_blank"
          >
            <ButtonComponent type="secondary">
              Reportar un problema
            </ButtonComponent>
          </Link>
        </div>
      </div>
    </SidebarLayout>
  );
};
