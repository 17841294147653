import * as yup from 'yup';
import ValidateRut from 'helpers/validateRut';
import { verificaCorreoRegistrado } from 'views/Signup/helpers/verificaCorreoRegistrado';
import { DICTONARY } from 'const/Dictonary';

const emailRegex = DICTONARY.EMAIL_REGEX;
let respuestaFinal;

const schemaEditarCuenta = yup.object().shape({
  rutUsuario: yup
    .string()
    .trim()
    .test(
      'Evaluación de rut',
      'El rut ingresado es inválido',
      value => !value || ValidateRut.isValid(value)
    ),

  primerNombre: yup
    .string()
    .max(25, 'El nombre no puede ser tan largo')
    .required('Este campo es obligatorio'),

  apellidoPaterno: yup
    .string()
    .max(25, 'El segundo nombre no puede ser tan largo')
    .required('Este campo es obligatorio'),

  apellidoMaterno: yup
    .string()
    .max(25, 'El segundo nombre no puede ser tan largo')
    .required('Este campo es obligatorio'),

  correoElectronico: yup.string().when('emailModificado', {
    is: val => val === true,
    then: emailModificado =>
      yup
        .string()
        .matches(emailRegex, 'El correo ingresado es inválido')
        .required('Este campo es obligatorio')
        .test(
          'Evaluación de correo',
          'El correo electrónico ingresado está registrado',
          async (value, { parent }) => {
            const emailModificado = parent.emailModificado;

            let emailValido = emailRegex.test(value.trim());
            if (emailValido && emailModificado) {
              respuestaFinal = await verificaCorreoRegistrado(value).then(
                resp => !resp
              );
            }
            return respuestaFinal;
          }
        )
  }),

  telefonoUsuario: yup
    .string()
    .max(25, 'El segundo nombre no puede ser tan largo'),

  profesion: yup
    .string()
    .max(50, 'El nombre de la profesion es demasiado largo'),

  imagenBase64: yup.string()
});

export { schemaEditarCuenta };
