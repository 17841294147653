export const ordenarMatchResiduos = response => {
  if (!response) {
    return [];
  }

  const { residuos } = response.data;
  const factorDisponibilidad = 0.5;
  const factorDistancia = 0.5;

  let residuosOferente = residuos.filter(residuo => residuo.nombreLER);
  let residuosDemandante = residuos.filter(residuo => !residuo.nombreLER);

  const auxOferete = residuosOferente
    .map(residuo => {
      const disponibilidad =
        residuo.calculoDisponibilidad * factorDisponibilidad;
      const distancia = residuo.sucursal.calculoDistancia * factorDistancia;

      return {
        ...residuo,
        matchPuntaje: disponibilidad + distancia,
        distancia: residuo.sucursal.calculoDistancia
      };
    })
    .sort((a, b) => a.matchPuntaje - b.matchPuntaje);

  const auxDemandante = residuosDemandante
    .map(residuo => {
      const distancia = residuo.sucursal.calculoDistancia * factorDistancia;

      return {
        ...residuo,
        matchPuntaje: distancia,
        distancia: residuo.sucursal.calculoDistancia
      };
    })
    .sort((a, b) => a.matchPuntaje - b.matchPuntaje);

  return {
    ...response.data,
    residuos: [...auxOferete, ...auxDemandante]
  };
};

export const ordenarDisponibilidadDistancia = matchParaOrdenar => {
  if (!matchParaOrdenar) {
    return [];
  }
  let residuosOferente = matchParaOrdenar.filter(residuo => residuo.nombreLER);
  let residuosDemandante = matchParaOrdenar.filter(
    residuo => !residuo.nombreLER
  );

  const auxOferete = residuosOferente.sort(
    (a, b) => a.matchPuntaje - b.matchPuntaje
  );
  const auxDemandante = residuosDemandante.sort(
    (a, b) => a.matchPuntaje - b.matchPuntaje
  );

  return [...auxOferete, ...auxDemandante];
};

export const ordenarPorCriterio = (matchParaOrdenar, criterio) => {
  if (!matchParaOrdenar) {
    return [];
  }

  let residuosOferente = matchParaOrdenar.filter(residuo => residuo.nombreLER);
  let residuosDemandante = matchParaOrdenar.filter(
    residuo => !residuo.nombreLER
  );

  const auxOferete = residuosOferente.sort((a, b) => a[criterio] - b[criterio]);
  const auxDemandante = residuosDemandante.sort(
    (a, b) => a[criterio] - b[criterio]
  );

  return [...auxOferete, ...auxDemandante];
};
