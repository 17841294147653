// Objetivo: Contiene la sección de dirección del oferente en el formulario de indicadores
import MapAddress from 'components/MapAddress/MapAddress';
// Design System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import ButtonComponent from 'components/design-system/Button/Button';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
// Material UI
import { GpsOff, MyLocation } from '@mui/icons-material';
// Hooks

// Dictonary
import { DICTONARY } from 'const/Dictonary';
import { useDireccionOferente } from '../hooks/useDireccionOferente';

export const DireccionOferente = ({
  errors,
  handleChange,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values
}) => {
  const {
    latLonOferente,
    setLatLonOferente,
    addressQuerysearchOferente,
    addressQueryParamsOferente,
    regionesOferente,
    cargaListaRegionesOferente,
    handleObtenerDireccionOferente,
    handleObtenerComunasOferente,
    listadoComunasOferente,
    cargaListaComunasOferente,
    setBuscandoDireccionOferente,
    buscandoDireccionOferente
  } = useDireccionOferente({
    values,
    isSubmitting,
    touched,
    setFieldValue
  });

  return (
    <>
      {/* Región */}
      <div className="col-span-12 md:col-span-6 xl:col-span-4">
        <div className="col-span-12 xs:col-span-6 md:col-span-3">
          <AutocompleteComponent
            name="regionOferente"
            clearOnEscape={true}
            accesor="nombreRegion"
            openOnFocus={true}
            options={regionesOferente}
            disabled={!cargaListaRegionesOferente}
            onChange={(ev, region, reason) => {
              let data = { name: 'regionOferente', value: region };
              if (reason === 'clear') {
                data.value = [];
                setFieldValue('comunaOferente', []);
              }
              setFieldValue('comunaOferente', []);
              setFieldValue('regionOferente', data.value);

              // Tocar los 3 campos para realizar la busqueda
              setFieldTouched('regionOferente');
              setFieldTouched('comunaOferente');
              setFieldTouched('nombreVia');
            }}
            onBlur={() => setFieldTouched('regionOferente')}
            value={values.regionOferente}
            getOptionLabel={option => option.nombreRegion || ''}
            isOptionEqualToValue={(option, value) =>
              option.nombreRegion === values.regionOferente.nombreRegion
            }
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              touched={touched.regionOferente ? true : false}
              estado={
                errors.regionOferente
                  ? { tipo: 'error', mensaje: errors.regionOferente }
                  : null
              }
              label={<LabelComponent>Región</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>
      </div>
      {/* Comuna */}
      <div className="col-span-12 md:col-span-6 xl:col-span-4">
        <div className="col-span-12 xs:col-span-6 md:col-span-3">
          <AutocompleteComponent
            name="comunaOferente"
            accesor="nombreComuna"
            options={listadoComunasOferente}
            disabled={!cargaListaComunasOferente}
            onChange={(ev, comuna, reason) => {
              let data = { name: 'comunaOferente', value: comuna };

              if (reason === 'clear') {
                data.value = [];
              }

              setFieldValue('comunaOferente', data.value);

              // Tocar los 3 campos para realizar la busqueda
              setFieldTouched('regionOferente');
              setFieldTouched('comunaOferente');
              setFieldTouched('nombreNumeroViaOferente');
            }}
            value={values.comunaOferente}
            getOptionLabel={option => option.nombreComuna || ''}
            isOptionEqualToValue={(option, value) =>
              option.nombreComuna === values.comunaOferente.nombreComuna
            }
            onBlur={() => setFieldTouched('comunaOferente')}
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              touched={touched.comunaOferente ? true : false}
              estado={
                errors.comunaOferente
                  ? { tipo: 'error', mensaje: errors.comunaOferente }
                  : null
              }
              label={<LabelComponent>Comuna</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>
      </div>
      {/* nombreNumeroViaOferente */}
      <div className="col-span-12 md:col-span-6 xl:col-span-4">
        <div className="col-span-12 xs:col-span-8 lg:col-span-6">
          <InputComponent
            autoComplete={'off'}
            fullWidth
            label={<LabelComponent>Nombre y número calle</LabelComponent>}
            name="nombreNumeroViaOferente"
            value={values.nombreNumeroViaOferente}
            onChange={e => {
              handleChange(e);
              // Tocar los 3 campos para realizar la busqueda
              setFieldTouched('regionOferente');
              setFieldTouched('comunaOferente');
              setFieldTouched('nombreNumeroViaOferente');
            }}
            onBlur={() => setFieldTouched('nombreNumeroViaOferente')}
            touched={touched.nombreNumeroViaOferente ? true : false}
            placeholder="Ej: Providencia, 1091"
            estado={
              errors.nombreNumeroViaOferente
                ? { tipo: 'error', mensaje: errors.nombreNumeroViaOferente }
                : null
            }
          />
        </div>
      </div>

      {/* Mapa */}
      <div className="col-span-12  xxs:col-span-12 xs:col-span-12 ">
        <div
          className={`${
            values.isPointEditingOferente
              ? ' bg-warning-light'
              : 'bg-info-light '
          }
                                sm:flex px-4 py-2.5 justify-between rounded items-center`}
        >
          <Text>
            {values.isPointEditingOferente
              ? DICTONARY.MAPA_MENSAJES.INFO_DESACTIVAR_EDICION
              : DICTONARY.MAPA_MENSAJES.INFO_ACTIVAR_EDICION}
          </Text>
          <ButtonComponent
            className="min-w-[190px] md:min-w-fit mt-4 sm:mt-0"
            onClick={() => {
              setFieldValue(
                'isPointEditingOferente',
                !values.isPointEditingOferente
              );
            }}
          >
            {values.isPointEditingOferente && latLonOferente ? (
              <>
                Desactivar edición <GpsOff className="ml-2" />
              </>
            ) : (
              <>
                Edición manual <MyLocation className="ml-2" />
              </>
            )}
          </ButtonComponent>
        </div>
        <div className="col-span-12 rounded overflow-hidden mt-2">
          <MapAddress
            addressQuerysearch={addressQuerysearchOferente}
            addressQueryParams={addressQueryParamsOferente}
            latLon={latLonOferente}
            setLatLon={setLatLonOferente}
            isPointEditing={values.isPointEditingOferente}
            setIsPointEditing={value =>
              setFieldValue('isPointEditingOferente', value)
            }
            isLoading={isSubmitting}
            buscandoDireccion={buscandoDireccionOferente}
          />
        </div>
      </div>
    </>
  );
};
