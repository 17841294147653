import { AuthContext } from 'context';
import { useContext } from 'react';

export const MenuFinal = ({ children }) => {
  const { logged } = useContext(AuthContext);

  return (
    <div
      className={`justify-end flex ${
        logged ? 'col-span-8 lg:col-span-2' : 'col-span-8 lg:col-span-9'
      } `}
    >
      {children}
    </div>
  );
};
