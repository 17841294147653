import { useState } from 'react';
import adminService from 'services/admin';
import { diccionarioVista } from '../utils/utils';
import { AlertaError, AlertaExito } from 'utils/Alertas';

export const useSubmitPerfiles = (codigoPerfil, values) => {
  const [isLoading, setIsLoading] = useState(true);
  const [perfil, setPerfil] = useState({
    nombrePerfil: '',
    descripcionPerfil: '',
    permisos: []
  });
  const [cargaData, setcargaData] = useState(false);

  /**
   * @name cargarPerfil
   * Función para cargar el perfil que se está desplegando, en el caso de que el código no viene se asume que es un nuevo perfil
   * @throws Error
   * @return estructura servicio de perfiles
   */

  const cargarPerfil = async () => {
    setIsLoading(true);

    if (!codigoPerfil) {
      setIsLoading(false);
      return;
    }
    if (codigoPerfil !== 'nuevo') {
      try {
        const responsePerfil = await adminService.getPerfil(codigoPerfil);
        // console.log('responsePerfil', responsePerfil);
        if (responsePerfil.status === 200) {
          const perfil = responsePerfil.data;
          setPerfil({
            nombrePerfil: perfil.nombrePerfil,
            descripcionPerfil: perfil.descripcionPerfil,
            permisos: perfil.permisos
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error en cargaPerfil', error);
      } finally {
        setIsLoading(false);
        setcargaData(true);
      }
    } else {
      setIsLoading(false);
      setcargaData(true);
    }
  };

  /**
   * @name handleSubmit
   * Función para enviar los datos del formulario al servicio
   * @param valores estructura formik para nuevo/edicion perfil
   * @return estructura servicio nuevo/edicion perfil
   * @throws Error
   */

  const handleSubmit = async (valores, tipoFormulario) => {
    try {
      if (tipoFormulario === 'ingresar') {
        await submitNuevoPerfil(valores, tipoFormulario);
      } else if (tipoFormulario === 'editar') {
        await submitActualizarPerfil(valores);
      }
    } catch (error) {
      console.log('Error en handleSubmit ', error);
      AlertaError(
        `No se ha podido realizar la operación de ${diccionarioVista.primerNombrePrincipal}. Por favor, intente nuevamente.`
      );
      throw new Error('Error en handleSubmit ', error);
    }
  };

  /**
   * @name submitNuevoPerfil
   * Función para enviar los datos del nuevo perfil
   * @param valores estructura formik
   * @return estructura servicio nuevo perfil
   * @throws Error
   *
   * */

  const submitNuevoPerfil = async (valores, tipoFormulario) => {
    const generar = tipoFormulario === 'ingresar' && 'crear';
    const generarado = tipoFormulario === 'ingresar' && 'creado';
    try {
      const perfilesData = estructuraServicioNuevoPerfil(valores);
      const response = await adminService.postPerfil(perfilesData);
      if (response.status === 200) {
        AlertaExito(
          `Se ha ${generarado} el ${diccionarioVista.primerNombrePrincipal} correctamente`
        );
      } else {
        AlertaError(
          `No se ha podido ${generar} la operación de crear un ${diccionarioVista.primerNombrePrincipal}. Por favor, intente nuevamente.`
        );
      }
    } catch (error) {
      console.log('Error en submitNuevoPerfil ', error);
      AlertaError(
        `No se ha podido ${generar} la operación de crear un ${diccionarioVista.primerNombrePrincipal}. Por favor, intente nuevamente.`
      );
      throw new Error('Error en submitNuevoPerfil ', error);
    }
  };

  /**
   * @name submitActualizarPerfil
   * Función para enviar los datos del formulario al servicio
   * @param valores estructura formik para la edición de un perfil
   * @return estructura servicio de actualización de perfil
   * @throws Error
   *
   * */

  const submitActualizarPerfil = async valores => {
    try {
      const perfilesData = estructuraServicioActualizarPerfil(valores);
      const response = await adminService.putPerfil(codigoPerfil, perfilesData);
      if (response.status === 200) {
        AlertaExito(
          `Se ha actualizado el ${diccionarioVista.primerNombrePrincipal} correctamente`
        );
      } else {
        AlertaError(
          `No se ha podido realizar la operación de actualizar un ${diccionarioVista.primerNombrePrincipal}. Por favor, intente nuevamente.`
        );
      }
    } catch (error) {
      console.log('Error en submitActualizarPerfil ', error);
      AlertaError(
        `No se ha podido realizar la operación de actualizar un ${diccionarioVista.primerNombrePrincipal}. Por favor, intente nuevamente.`
      );
      throw new Error('Error en submitActualizarPerfil ', error);
    }
  };

  /**
   * @name handleEliminarPerfil
   * Función para eliminar un  perfil
   *
   * @param codAdquirido codigo del perfil a eliminar
   * @param cargaRecursosAdquiridos función para cargar el listado de  perfiles
   * @return estructura servicio de eliminación de perfil
   * @throws Error
   *
   * */
  const handleEliminarPerfil = async (
    codigoPerfil,
    cargaRecursosAdquiridos
  ) => {
    try {
      const response = await adminService.deletePerfil(codigoPerfil);
      if (response.status === 200) {
        AlertaExito(
          `Se ha eliminado el ${diccionarioVista.primerNombrePrincipal} correctamente`
        );
        cargaRecursosAdquiridos();
      } else {
        AlertaError(
          `No se ha podido realizar la operación de eliminar un ${diccionarioVista.primerNombrePrincipal}. Por favor, intente nuevamente.`
        );
      }
    } catch (error) {
      console.log('Error en handleEliminarPerfil ', error);
      AlertaError(
        `No se ha podido realizar la operación de eliminar un ${diccionarioVista.primerNombrePrincipal}. Por favor, intente nuevamente.`
      );
      throw new Error('Error en handleEliminarPerfil ', error);
    }
  };

  /**
   * @name estructuraServicioNuevoPerfil
   * Función para estructurar los valores del formulario a la estructura requerida por el servicio
   * @param valores estructura formik
   * @return estructura servicio nuevo perfil
   */
  const estructuraServicioNuevoPerfil = valores => {
    try {
      const estructuraServicio = {
        nombrePerfil: valores.nombrePerfil,
        descripcionPerfil: valores.descripcionPerfil,
        permisos: valores.permisos
      };
      return estructuraServicio;
    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }
  };

  /**
   * @name estructuraServicioActualizarPerfil
   * Función para estructurar los valores del formulario a la estructura requerida por el servicio
   * @param valores estructura formik
   * @return estructura servicio actualizar perfil
   */

  const estructuraServicioActualizarPerfil = valores => {
    try {
      const estructuraServicio = {
        nombrePerfil: valores.nombrePerfil,
        descripcionPerfil: valores.descripcionPerfil,
        permisos: valores.permisos
      };
      return estructuraServicio;
    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }
  };

  return {
    perfil,
    isLoading,
    setIsLoading,
    cargarPerfil,
    cargaData,
    handleSubmit,
    handleEliminarPerfil
  };
};
