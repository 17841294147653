import { useCallback, useEffect, useState } from 'react';

export const useCalculoSubtipoRecursosReemplazo = ({
  listaTipoRecursoReemplazo2,
}) => {
  const [listaSubTipoRecursoReemplazo, setListaSubTipoRecursoReemplazo] = useState([]);
  const [
    cargandoListaSubTipoRecursoReemplazo,
    setCargandoListaSubTipoRecursoReemplazo
  ] = useState(true);

  const calculoSubTipoRecursosReeplazo = useCallback(({
    codigoRecursoReemplazo,
    setFieldValue,
  }) => {
    setCargandoListaSubTipoRecursoReemplazo(true);
    let subtipo = codigoRecursoReemplazo;

    const nuevaLista = listaTipoRecursoReemplazo2.filter(
      t => t.codigoNivelAnteriorReemplazo === subtipo
    );

    if (nuevaLista.length > 0) {
      setFieldValue('tieneTipoRecursosReemplazo', true);
    } else {
      setFieldValue('tieneTipoRecursosReemplazo', false);
      setFieldValue('subTipoRecursoReemplazo', {});
    }

    setListaSubTipoRecursoReemplazo(nuevaLista);
    setFieldValue('subTipoRecursoReemplazo', nuevaLista);
    setCargandoListaSubTipoRecursoReemplazo(false);
  }, [listaTipoRecursoReemplazo2]);



  

  return {
    listaSubTipoRecursoReemplazo,
    cargandoListaSubTipoRecursoReemplazo,
    calculoSubTipoRecursosReeplazo,
  }
}
