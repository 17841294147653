import Place from '@mui/icons-material/Place';
import Schedule from '@mui/icons-material/Schedule';
import { CircularProgress } from '@mui/material';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { AuthContext } from 'context';
import { useContext } from 'react';

/**
 * @name CargandoMatch
 * Componente que despliega información del match automático al momento de cargar
 */
export const CargandoMatch = () => {
  const { logeduser } = useContext(AuthContext);
  const obtenerDireccion = () => {
    try {
      return `${logeduser.sucursal?.direccion?.nombreVia}, ${logeduser.sucursal?.direccion?.comuna?.nombreComuna}, Región ${logeduser.sucursal?.direccion?.comuna?.region?.nombreRegion}`;
    } catch (error) {
      return '.';
    }
  };

  return (
    <div className="flex mx-auto h-[90vh] items-center justify-center">
      <div className="w-full sm:w-4/5 md:w-3/5 xl:w-1/3 text-center pb-40 xl:pb-20">
        <CircularProgress thickness={6} size={60} className="text-white mb-4" />

        <Heading type="h2" className="text-center font-light mb-4">
          Buscando <b className="font-semibold">Match</b>
        </Heading>
        <Heading type="h5" className="mb-4">
          Los resultados obtenidos se ordenarán por
        </Heading>

        <div className="text-start flex mb-6 px-8">
          <div className="mr-2">
            <Schedule className="text-uv-secondary-0" />
          </div>
          <div className="">
            <Text className="text-uv-secondary-0 tracking-wider font-medium mb-2">
              DISPONIBILIDAD
            </Text>
            <Text className="text-neutral-40">
              Según la fecha de disponibilidad más cercana, considerando hasta
              30 días desde la fecha actual.{' '}
              <b>Puedes establecer una fecha máxima manualmente</b> al cambiar
              los criterios de búsqueda.
            </Text>
          </div>
        </div>

        <div className="text-start flex px-8">
          <div className="mr-2">
            <Place className="text-uv-secondary-0" />
          </div>
          <div className="">
            <Text className="text-uv-secondary-0 tracking-wider font-medium mb-2">
              DISTANCIA
            </Text>
            <Text className="text-neutral-40">
              Según la distancia entre el publicante y la dirección{' '}
              <b>{obtenerDireccion()}</b>
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
