import React, { useEffect, useState } from 'react';
// Design System
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import ButtonComponent from 'components/design-system/Button/Button';
import AlertComponent from 'components/design-system/Alert/Alert';
// Material
import { Divider } from '@mui/material';
// Services
import usuariosService from 'services/usuarios';
import { useLocation } from 'react-router-dom';

import { DICTONARY } from 'const/Dictonary';

const VerifyEmail = props => {
  const { TIMER, REINTENTOS } = DICTONARY.VERIFYMAIL;

  const [reintentosMinimos, setReintentosMinimos] = useState(
    REINTENTOS.minimo || 0
  );

  const [reintentos, setReintentos] = useState(REINTENTOS.minimo || 0);

  const [alerta, setAlerta] = useState({
    mensaje: '',
    tipo: '',
    visible: false
  });

  const [time, setTime] = useState(TIMER.segundos); // tiempo inicial en segundos

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const correo = searchParams.get('correo');

  useEffect(() => {
    let mounted = true;
    mounted && handleClickReenviarCorreo(correo);

    return () => {
      mounted = false; // cleanup function
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correo]);

  const handleClickReenviarCorreo = correo => {
    reintentosMinimos < REINTENTOS.maximo &&
      usuariosService
        .confirmacionCorreoUsuarios(correo)
        .then(result => {
          // console.log('result :>> ', result);
          if (result.status === 200) {
            console.log('e-mail enviado con éxito');
            setAlerta({
              mensaje: 'Se ha enviado nuevamente un correo a su email',
              tipo: 'success',
              visible: true
            });
          } else {
            setAlerta({
              mensaje: 'Ha ocurrido un error al intentar enviar el e-mail.',
              tipo: 'error',
              visible: true
            });
          }
        })
        .catch(err => {
          setAlerta({
            mensaje: 'Ha ocurrido un error al intentar enviar el e-mail.',
            tipo: 'error',
            visible: true
          });
        })
        .finally(() => {
          setTimeout(() => {
            setAlerta({
              mensaje: '',
              tipo: '',
              visible: false
            });
          }, 8000);
        });
    reintentos !== 0 && setReintentosMinimos(reintentosMinimos + 1);
    setReintentos(reintentos + 1);
  };

  // TIMMER
  useEffect(() => {
    if (reintentosMinimos <= REINTENTOS.maximo) {
      const interval = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [REINTENTOS.maximo, reintentosMinimos]);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  useEffect(() => {}, [reintentos]);

  return (
    <div className="m-auto">
      <div className="max-w-[550px] w-full text-center m-auto mb-2">
        <div className="bg-white rounded shadow-md py-10 px-14 mt-5">
          <Heading type="h1" className="mb-8">
            Verificación de correo
          </Heading>
          <Text>
            En los próximos minutos te llegará un correo a la casilla{' '}
            <strong>{correo}</strong> revisa tu correo y presiona el botón de
            "Completar mi registro" para finalizar el proceso
          </Text>

          <Divider className="my-8" />

          {reintentosMinimos <= REINTENTOS.maximo ? (
            <>
              <Text className="mb-1">
                Puedes solicitar un nuevo envío de correo en:
              </Text>
              <Heading type="h4">
                {time > 0 && reintentosMinimos <= REINTENTOS.maximo ? (
                  reintentosMinimos > REINTENTOS.maximo ? (
                    ''
                  ) : (
                    <h1>{`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}</h1>
                  )
                ) : (
                  <ButtonComponent
                    type="primary"
                    onClick={() => {
                      reintentosMinimos <= REINTENTOS.maximo &&
                        handleClickReenviarCorreo(correo);
                      reintentosMinimos <= REINTENTOS.maximo &&
                        setTime(TIMER.segundos);
                    }}
                    className="min-w-[180px] justify-center"
                  >
                    Reintentar
                  </ButtonComponent>
                )}
              </Heading>
            </>
          ) : (
            <>
              <Heading type="h4">¿Aún no llega el correo?</Heading>
              <Text>
                Puedes comunicarte con el administrador <br />
                al correo <strong>asistencia@industriacircular.cl</strong>
              </Text>
            </>
          )}

          {alerta.visible && reintentos > 1 && (
            <AlertComponent type={alerta.tipo}>
              <Heading type="h5"></Heading>
              <Text>{alerta.mensaje}.</Text>
            </AlertComponent>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
