import * as yup from 'yup';

const SchemaBusinessInfo = yup.object().shape({
  comuna: yup
    .object()
    .typeError('Este campo es obligatorio')
    .required('Este campo es obligatorio'),

  nombreVia: yup
    .string()
    .trim()
    .max(100, 'La cantidad máxima de caracteres es 100')
    .min(3, 'La cantidad mínima de caracteres es 3')
    .required('Es un campo obligatorio'),

  telefonoSucursal: yup
    .string()
    .trim()
    .matches(
      /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm,
      'el teléfono ingresado no es válido'
    )
    .max(14, 'El número no es válido')
    .min(12, 'El número no es válido')
});

export { SchemaBusinessInfo };
