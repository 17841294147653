import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { DICTONARY } from 'const/Dictonary';
import { useServicesResiduosDeclarados } from 'views/ResiduoDeclarado/hooks/useServicesResiduosDeclarados';
import { useSubmitResiduosDeclarados } from 'views/ResiduoDeclarado/hooks/useSubmitResiduosDeclarados';
import useCargaServiciosResiduosDeclarados from 'views/ResiduoDeclarado/hooks/useCargaServiciosResiduosDeclarados';
import { SkeletonResiduoDeclarado } from 'views/ResiduoDeclarado/skeleton/SkeletonResiduoDeclarado';
import { SeleccionInicialDeDatos } from '../SeccionesResiduosDeclarados/SeleccionInicialDeDatos';
import { NavegacionFormulario } from 'components/design-system/NavegacionFormulario/NavegacionFormulario';
import { NavegacionPasos } from './NavegacionPasos';
import { useObtenerPasos } from 'views/ResiduoDeclarado/hooks/useObtenerPasos';
import { useNavegacionFormulario } from 'views/ResiduoDeclarado/hooks/useNavegacionFormulario';

const ResiduosDeclaradosFormik = () => {
  const params = useParams();
  const { codigoResiduoDeclarado, tipoForm } = params;
  const [codigoResiduoCargado, setCodigoResiduoCargado] = useState(
    codigoResiduoDeclarado
  );
  const [cargaNombreLER, setCargaNombreLER] = useState(true);
  const [cargaTipoResiduos, setCargaTipoResiduos] = useState(true);
  const { pasosFormulario } = useObtenerPasos();

  /**
   *  LLamada de servicios de useServicesResiduosDeclarados
   *  @description  hooks utilizado en la creación de servicios para Recursos Adquiridos
   *  @see          src/views/ResiduoDeclarado/hooks/useServicesResiduosDeclarados
   *  @params       no aplica
   *  @returns      states y funciones de servicios
   */
  const {
    // Funciones
    // ObtieneListaResiduosDeclarados,
    ObtieneComunasPorRegion,
    ObtieneEmpresasSIIReceptor,
    ObtieneEmpresasSIITransporte,
    ObtieneEmpresasSIIGenerador,
    ObtieneFormatoEntrega,
    ObtieneLevantamiento,
    ObtieneRegiones,
    ObtieneSucursales,
    ObtieneTipoTransporte,
    ObtieneTipoTratamiento,
    ObtieneUnidadMedida,
    // States Sucursales
    sucursales,
    setSucursales,
    isLoadingSucursales,
    setIsLoadingSucursales,

    // States Levantamiento de datos
    levantamiento,
    setLevantamiento,
    isLoadingLevantamiento,
    setIsLoadingLevantamiento,

    // States Unidad de medida
    unidadMedida,
    setUnidadMedida,
    isLoadingUnidadMedida,
    setIsLoadingUnidadMedida,

    // States formato de entrega
    formatoEntrega,
    setFormatoEntrega,
    isLoadingFormatoEntrega,
    setIsLoadingFormatoEntrega,

    // States Empresas SII Generador
    empresasSIIGenerador,
    setEmpresasSIIGenerador,
    isLoadingEmpresasSIIGenerador,
    setIsLoadingEmpresasSIIGenerador,

    // States Empresas SII Transporte
    empresasSIITransporte,
    setEmpresasSIITransporte,
    isLoadingEmpresasSIITransporte,
    setIsLoadingEmpresasSIITransporte,

    // States Empresas SII Receptor
    empresasSIIReceptor,
    setEmpresasSIIReceptor,
    isLoadingEmpresasSIIReceptor,
    setIsLoadingEmpresasSIIReceptor,

    // States Tipo de transporte
    tipoTransporte,
    setTipoTransporte,
    isLoadingTipoTransporte,
    setIsLoadingTipoTransporte,

    // States Tipo de Tratamiento
    tipoTratamiento,
    setTipoTratamiento,
    isLoadingTipoTratamiento,
    setIsLoadingTipoTratamiento
  } = useServicesResiduosDeclarados();

  /**
   *  Cargando Servicios de useCargaServicios
   *  @description  hooks utilizado para la ejecución de  servicios para Recursos Adquiridos
   *  @see          src/views/ResiduoDeclarado/hooks/useCargaServicios
   *  @params       todas las funciones a cargar en el hook
   *  @returns      funciones de carga de servicios para ser utilizadas en otra parte del formulario
   *
   * */
  const {
    cargaEmpresasSIITransporte,
    cargaEmpresasSIIReceptor,
    cargaEmpresasSIIGenerador
  } = useCargaServiciosResiduosDeclarados({
    ObtieneComunasPorRegion,
    ObtieneEmpresasSIIReceptor,
    ObtieneEmpresasSIITransporte,
    ObtieneEmpresasSIIGenerador,
    ObtieneFormatoEntrega,
    ObtieneLevantamiento,
    ObtieneRegiones,
    ObtieneSucursales,
    ObtieneTipoTransporte,
    ObtieneTipoTratamiento,
    ObtieneUnidadMedida
  });

  // Hooks
  const {
    residuoDeclarado,
    cargarResiduoDeclarado,
    isLoading,
    handleSubmit,
    cargarEstadoVacio
  } = useSubmitResiduosDeclarados(codigoResiduoCargado || null);
  const {
    step,
    handleReiniciarPasosFormulario,
    handleSeleccionInicialDeDatos,
    onCancelar,
    handlePasoAnterior,
    onSiguientePaso,
    datosIniciales,
    tipoFormulario
  } = useNavegacionFormulario(
    residuoDeclarado,
    tipoForm,
    setCodigoResiduoCargado,
    cargarEstadoVacio
  );

  // ejecutar cargarResiduoDeclarado() si se refresca la pagina
  useEffect(() => {
    if (!codigoResiduoCargado) return;
    cargarResiduoDeclarado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigoResiduoCargado]);

  useEffect(() => {
    // Se elimina el paso de generador si no tiene datos UVLPIC-2144
    if (tipoFormulario === DICTONARY.TIPO_FORMULARIO.editar && !isLoading) {
      const {
        nombreEmpresaGenerador,
        nombreViaEmpresaGenerador,
        comunaEmpresaGenerador,
        regionEmpresaGenerador
      } = residuoDeclarado;
      if (
        !nombreEmpresaGenerador &&
        !nombreViaEmpresaGenerador &&
        !comunaEmpresaGenerador &&
        !regionEmpresaGenerador
      ) {
        //  console.log('no tiene nombreEmpresaGenerador')
        pasosFormulario.forEach((paso, index) => {
          if (paso.codigoPaso === 'generador') {
            pasosFormulario.splice(index, 1);
          }
        });
      } else {
        // console.log('tiene nombreEmpresaGenerador')
      }
    }
  }, [isLoading, residuoDeclarado, tipoFormulario, pasosFormulario]);

  return (
    <SidebarLayout
      containerClassName="md:px-4 md:container md:mx-auto"
      childrenContainerClassName={'col-span-12 lg:col-span-10 pt-0 md:pt-10'}
    >
      {datosIniciales === null &&
      tipoFormulario === DICTONARY.TIPO_FORMULARIO.ingresar ? (
        <SeleccionInicialDeDatos
          tipoForm={tipoFormulario}
          handleSeleccionInicialDeDatos={handleSeleccionInicialDeDatos}
        />
      ) : (
        <>
          {isLoading ? (
            <SkeletonResiduoDeclarado tipoForm={tipoFormulario} />
          ) : (
            <Formik
              initialValues={residuoDeclarado}
              validationSchema={pasosFormulario[step].schema}
              validateOnChange={true}
              validateOnBlur={true}
              validateOnMount={true}
              onSubmit={async (values, { resetForm }) => {
                await handleSubmit(values, tipoFormulario);
                if (tipoFormulario === DICTONARY.TIPO_FORMULARIO.ingresar) {
                  resetForm();
                  handleReiniciarPasosFormulario();
                } else {
                  onCancelar();
                }
              }}
            >
              {props => (
                <Form id="formResiduoDeclarado">
                  <NavegacionFormulario
                    titulo={`${DICTONARY.TIPO_FORMULARIO[tipoFormulario]} residuo declarado`}
                    pasos={pasosFormulario}
                    onCancelar={onCancelar}
                    onSiguiente={() =>
                      onSiguientePaso(props.validateForm, props.setFieldTouched)
                    }
                    onFinalizar={() => props.handleSubmit()}
                    onVolver={() => handlePasoAnterior()}
                    pasoActual={step}
                    isSubmitting={props.isSubmitting}
                  >
                    <NavegacionPasos
                      {...props}
                      levantamiento={levantamiento}
                      setLevantamiento={setLevantamiento}
                      isLoadingLevantamiento={isLoadingLevantamiento}
                      setIsLoadingLevantamiento={setIsLoadingLevantamiento}
                      // Sucursales
                      sucursales={sucursales}
                      setSucursales={setSucursales}
                      isLoadingSucursales={isLoadingSucursales}
                      setIsLoadingSucursales={setIsLoadingSucursales}
                      // Formato de entrega
                      formatoEntrega={formatoEntrega}
                      setFormatoEntrega={setFormatoEntrega}
                      isLoadingFormatoEntrega={isLoadingFormatoEntrega}
                      setIsLoadingFormatoEntrega={setIsLoadingFormatoEntrega}
                      // Empresas SII Transporte
                      cargaEmpresasSIITransporte={cargaEmpresasSIITransporte}
                      empresasSIITransporte={empresasSIITransporte}
                      isLoadingEmpresasSIITransporte={
                        isLoadingEmpresasSIITransporte
                      }
                      setEmpresasSIITransporte={setEmpresasSIITransporte}
                      setIsLoadingEmpresasSIITransporte={
                        setIsLoadingEmpresasSIITransporte
                      }
                      // Unidad de medida
                      unidadMedida={unidadMedida}
                      setUnidadMedida={setUnidadMedida}
                      isLoadingUnidadMedida={isLoadingUnidadMedida}
                      setIsLoadingUnidadMedida={setIsLoadingUnidadMedida}
                      // Empresas SII Receptor
                      cargaEmpresasSIIReceptor={cargaEmpresasSIIReceptor}
                      empresasSIIReceptor={empresasSIIReceptor}
                      isLoadingEmpresasSIIReceptor={
                        isLoadingEmpresasSIIReceptor
                      }
                      setEmpresasSIIReceptor={setEmpresasSIIReceptor}
                      setIsLoadingEmpresasSIIReceptor={
                        setIsLoadingEmpresasSIIReceptor
                      }
                      //2 - Generador
                      empresasSIIGenerador={empresasSIIGenerador}
                      setEmpresasSIIGenerador={setEmpresasSIIGenerador}
                      isLoadingEmpresasSIIGenerador={
                        isLoadingEmpresasSIIGenerador
                      }
                      setIsLoadingEmpresasSIIGenerador={
                        setIsLoadingEmpresasSIIGenerador
                      }
                      cargaEmpresasSIIGenerador={cargaEmpresasSIIGenerador}
                      // Tipo de transporte
                      tipoTransporte={tipoTransporte}
                      setTipoTransporte={setTipoTransporte}
                      isLoadingTipoTransporte={isLoadingTipoTransporte}
                      setIsLoadingTipoTransporte={setIsLoadingTipoTransporte}
                      // Tipo de Tratamiento
                      tipoTratamiento={tipoTratamiento}
                      setTipoTratamiento={setTipoTratamiento}
                      isLoadingTipoTratamiento={isLoadingTipoTratamiento}
                      setIsLoadingTipoTratamiento={setIsLoadingTipoTratamiento}
                      // Tipo de Formulario
                      tipoForm={tipoFormulario}
                      //isLoading
                      isLoading={isLoading}
                      cargaNombreLER={cargaNombreLER}
                      setCargaNombreLER={setCargaNombreLER}
                      cargaTipoResiduos={cargaTipoResiduos}
                      setCargaTipoResiduos={setCargaTipoResiduos}
                      step={step}
                      pasos={pasosFormulario}
                    />
                  </NavegacionFormulario>
                </Form>
              )}
            </Formik>
          )}
        </>
      )}
    </SidebarLayout>
  );
};

export default ResiduosDeclaradosFormik;
