import { useMemo } from 'react';
import { useDireccion } from 'hooks/useDireccion';

export const useDireccionOferente = ({
  values,
  isSubmitting,
  touched,
  setFieldValue
}) => {
  const {
    latLon: latLonOferente,
    setLatLon: setLatLonOferente,
    addressQuerysearch: addressQuerysearchOferente,
    addressQueryParams: addressQueryParamsOferente,
    regiones: regionesOferente,
    cargaListaRegiones: cargaListaRegionesOferente,
    handleObtenerDireccionOferente,
    handleObtenerComunas: handleObtenerComunasOferente,
    listadoComunas: listadoComunasOferente,
    cargaListaComunas: cargaListaComunasOferente,
    setBuscandoDireccionOferente,
    buscandoDireccionOferente
  } = useDireccion();

  //  Servicio Comunas Oferente
  useMemo(() => {
    if (!values.regionOferente) return;
    if (values.regionOferente && values.regionOferente.codigoRegion) {
      handleObtenerComunasOferente(values.regionOferente.codigoRegion);
    }
  }, [handleObtenerComunasOferente, values.regionOferente]);

  // Estados de carga de servicios
  useMemo(() => {
    if (isSubmitting) return;
    handleObtenerDireccionOferente(values);
  }, [isSubmitting, handleObtenerDireccionOferente, values]);

  // Verificacion de cambios en los campos de direccion
  useMemo(() => {
    const {
      nombreViaOferente,
      comunaOferente,
      regionOferente,
      isPointEditingOferente
    } = values;

    if (isSubmitting || !latLonOferente) return;

    const hasNombreVia = nombreViaOferente && touched.nombreViaOferente;
    const hasComuna = comunaOferente && touched.comunaOferente;
    const hasRegion = regionOferente && touched.regionOferente;

    if (hasNombreVia || hasComuna || hasRegion || isPointEditingOferente) {
      setBuscandoDireccionOferente(true);
      setFieldValue('latitudDireccionOferente', latLonOferente[0]);
      setFieldValue('longitudDireccionOferente', latLonOferente[1]);
    }
  }, [
    isSubmitting,
    latLonOferente,
    setBuscandoDireccionOferente,
    setFieldValue,
    touched.comunaOferente,
    touched.nombreViaOferente,
    touched.regionOferente,
    values
  ]);

  return {
    latLonOferente,
    setLatLonOferente,
    addressQuerysearchOferente,
    addressQueryParamsOferente,
    regionesOferente,
    cargaListaRegionesOferente,
    handleObtenerDireccionOferente,
    handleObtenerComunasOferente,
    listadoComunasOferente,
    cargaListaComunasOferente,
    setBuscandoDireccionOferente,
    buscandoDireccionOferente
  };
};
