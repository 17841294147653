// Objetivo: Contiene la sección de dirección del oferente en el formulario de indicadores
import MapAddress from 'components/MapAddress/MapAddress';
// Design System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import ButtonComponent from 'components/design-system/Button/Button';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
// Material UI
import { GpsOff, MyLocation } from '@mui/icons-material';
// Hooks

// Dictonary
import { DICTONARY } from 'const/Dictonary';
import { useDireccionDemandante } from '../hooks/useDireccionDemandante';

export const DireccionDemandante = ({
  errors,
  handleChange,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values
}) => {
  const {
    latLonDemandante,
    setLatLonDemandante,
    addressQuerysearchDemandante,
    addressQueryParamsDemandante,
    regionesDemandante,
    cargaListaRegionesDemandante,
    handleObtenerDireccionDemandante,
    handleObtenerComunasDemandante,
    listadoComunasDemandante,
    cargaListaComunasDemandante,
    setBuscandoDireccionDemandante,
    buscandoDireccionDemandante
  } = useDireccionDemandante({
    values,
    isSubmitting,
    touched,
    setFieldValue
  });

  return (
    <>
      {/* Región */}
      <div className="col-span-12 md:col-span-6 xl:col-span-4">
        <div className="col-span-12 xs:col-span-6 md:col-span-3">
          <AutocompleteComponent
            name="regionDemandante"
            clearOnEscape={true}
            accesor="nombreRegion"
            openOnFocus={true}
            options={regionesDemandante}
            disabled={!cargaListaRegionesDemandante}
            onChange={(ev, region, reason) => {
              let data = { name: 'regionDemandante', value: region };
              if (reason === 'clear') {
                data.value = '';
              }
              setFieldValue('comunaDemandante', null);
              setFieldValue('regionDemandante', data.value);

              // Tocar los 3 campos para realizar la busqueda
              setFieldTouched('regionDemandante');
              setFieldTouched('comunaDemandante');
              setFieldTouched('nombreViaDemandante');
            }}
            onBlur={() => setFieldTouched('regionDemandante')}
            value={values.regionDemandante}
            getOptionLabel={option => option.nombreRegion || ''}
            isOptionEqualToValue={(option, value) =>
              option.nombreRegion === values.regionDemandante.nombreRegion
            }
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              touched={touched.regionDemandante ? true : false}
              estado={
                errors.regionDemandante
                  ? { tipo: 'error', mensaje: errors.regionDemandante }
                  : null
              }
              label={<LabelComponent>Región</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>
      </div>
      {/* Comuna */}
      <div className="col-span-12 md:col-span-6 xl:col-span-4">
        <div className="col-span-12 xs:col-span-6 md:col-span-3">
          <AutocompleteComponent
            name="comunaDemandante"
            accesor="nombreComuna"
            options={listadoComunasDemandante}
            disabled={!cargaListaComunasDemandante}
            onChange={(ev, comuna, reason) => {
              let data = { name: 'comunaDemandante', value: comuna };

              if (reason === 'clear') {
                data.value = '';
              }

              setFieldValue('comunaDemandante', data.value);

              // Tocar los 3 campos para realizar la busqueda
              setFieldTouched('regionDemandante');
              setFieldTouched('comunaDemandante');
              setFieldTouched('nombreViaDemandante');
            }}
            value={values.comunaDemandante}
            getOptionLabel={option => option.nombreComuna || ''}
            isOptionEqualToValue={(option, value) =>
              option.nombreComuna === values.comunaDemandante.nombreComuna
            }
            onBlur={() => setFieldTouched('comunaDemandante')}
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              touched={touched.comunaDemandante ? true : false}
              estado={
                errors.comuna
                  ? { tipo: 'error', mensaje: errors.comunaDemandante }
                  : null
              }
              label={<LabelComponent>Comuna</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>
      </div>
      {/* NombreVia */}
      <div className="col-span-12 md:col-span-6 xl:col-span-4">
        <div className="col-span-12 xs:col-span-8 lg:col-span-6">
          <InputComponent
            autoComplete={'off'}
            fullWidth
            label={<LabelComponent>Nombre y número calle</LabelComponent>}
            name="nombreViaDemandante"
            value={values.nombreViaDemandante}
            onChange={e => {
              handleChange(e);
              // Tocar los 3 campos para realizar la busqueda
              setFieldTouched('regionDemandante');
              setFieldTouched('comunaDemandante');
              setFieldTouched('nombreViaDemandante');
            }}
            onBlur={() => setFieldTouched('nombreViaDemandante')}
            touched={touched.nombreViaDemandante ? true : false}
            placeholder="Ej: Providencia, 1091"
            estado={
              errors.nombreViaDemandante
                ? { tipo: 'error', mensaje: errors.nombreViaDemandante }
                : null
            }
          />
        </div>
      </div>

      {/* Mapa */}
      <div className="col-span-12  xxs:col-span-12 xs:col-span-12 ">
        <div
          className={`${
            values.isPointEditingDemandante
              ? ' bg-warning-light'
              : 'bg-info-light '
          }
                                sm:flex px-4 py-2.5 justify-between rounded items-center`}
        >
          <Text>
            {values.isPointEditingDemandante
              ? DICTONARY.MAPA_MENSAJES.INFO_DESACTIVAR_EDICION
              : DICTONARY.MAPA_MENSAJES.INFO_ACTIVAR_EDICION}
          </Text>
          <ButtonComponent
            className="min-w-[190px] md:min-w-fit mt-4 sm:mt-0"
            onClick={() => {
              setFieldValue(
                'isPointEditingDemandante',
                !values.isPointEditingDemandante
              );
            }}
          >
            {values.isPointEditingDemandante && latLonDemandante ? (
              <>
                Desactivar edición <GpsOff className="ml-2" />
              </>
            ) : (
              <>
                Edición manual <MyLocation className="ml-2" />
              </>
            )}
          </ButtonComponent>
        </div>
        <div className="col-span-12 rounded overflow-hidden mt-2">
          <MapAddress
            addressQuerysearch={addressQuerysearchDemandante}
            addressQueryParams={addressQueryParamsDemandante}
            latLon={latLonDemandante}
            setLatLon={setLatLonDemandante}
            isPointEditing={values.isPointEditingDemandante}
            setIsPointEditing={value =>
              setFieldValue('isPointEditingDemandante', value)
            }
            isLoading={isSubmitting}
            buscandoDireccion={buscandoDireccionDemandante}
          />
        </div>
      </div>
    </>
  );
};
