import { SchemaBusinessType } from './SchemaBusinessType';
import { SchemaBusinessRSD } from './SchemaBusinessRSD';
import { SchemaBusinessRole } from './SchemaBusinessRole';
import { SchemaBusinessInfo } from './SchemaBusinessInfo';
import { SchemaBusinessCertifications } from './SchemaBusinessCertifications';
import { SchemaBusinessClientes } from './SchemaBusinessClientes';

const schema = [];
schema[0] = SchemaBusinessType;
schema[1] = SchemaBusinessRole;
schema[2] = SchemaBusinessInfo;
schema[3] = SchemaBusinessRSD;
schema[4] = SchemaBusinessCertifications;
schema[5] = SchemaBusinessClientes;

export { schema };
