import { DICTONARY } from 'const/Dictonary';
import { useCallback, useEffect, useState } from 'react';
import commons from 'services/commons';
import commonsServices from 'services/commons';

export const useDireccion = () => {
  const [regiones, setRegiones] = useState(false);
  const [cargaListaRegiones, setCargaListaRegiones] = useState(false);
  const [latLon, setLatLon] = useState(
    DICTONARY.COORDENAS_CHILE.REGION_METROPOLITANA
  );
  const [addressQuerysearch, setAddressQuerysearch] = useState('');
  const [addressQueryParams, setAddressQueryParams] = useState({});
  const [cargaListaComunas, setCargaListaComunas] = useState(false);
  const [listadoComunas, setlistadoComunas] = useState(false);

  const [buscandoDireccion, setBuscandoDireccion] = useState(false);
  const [buscandoDireccionOferente, setBuscandoDireccionOferente] =
    useState(false);
  const [buscandoDireccionDemandante, setBuscandoDireccionDemandante] =
    useState(false);

  const handleObtenerDireccion = useCallback(
    values => {
      if (!values.nombreVia && !values.comuna && !values.region) {
        return;
      }

      if (values.comuna) {
        let nombreRegion = '';
        if (values.region && values.region?.nombreRegion?.length > 0) {
          nombreRegion = values.region.nombreRegion;
        } else {
          return;
        }

        let nombreComuna = '';
        if (values.comuna && values.comuna?.nombreComuna?.length > 0) {
          nombreComuna = values.comuna.nombreComuna;
        } else {
          return;
        }

        let latitudDireccion = false;
        let longitudDireccion = false;
        if (
          values.latitudDireccion &&
          values.longitudDireccion &&
          !buscandoDireccion
        ) {
          latitudDireccion = values.latitudDireccion.toString();
          longitudDireccion = values.longitudDireccion.toString();
          setLatLon([latitudDireccion, longitudDireccion]);
        }
        // console.log(longitudDireccion);

        const fullAddress =
          values.nombreVia +
          ' ' +
          nombreComuna +
          ', ' +
          nombreRegion +
          ', Chile';

        const query = {
          street: values.nombreVia,
          city: nombreComuna,
          state: nombreRegion,
          country: 'Chile',
          format: 'json', // [html|xml|json|jsonv2|geojson|geocodejson]
          lat: latitudDireccion,
          lon: longitudDireccion
        };

        setAddressQueryParams(query);
        setAddressQuerysearch(fullAddress);
      }
    },
    [buscandoDireccion]
  );

  const handleObtenerDireccionOferente = useCallback(
    values => {
      if (
        !values.nombreNumeroViaOferente &&
        !values.comunaOferente &&
        !values.regionOferente
      ) {
        return;
      }

      if (values.comunaOferente) {
        let nombreRegion = '';
        if (
          values.regionOferente &&
          values.regionOferente?.nombreRegion?.length > 0
        ) {
          nombreRegion = values.regionOferente.nombreRegion;
        } else {
          return;
        }

        let nombreComuna = '';
        if (
          values.comunaOferente &&
          values.comunaOferente?.nombreComuna?.length > 0
        ) {
          nombreComuna = values.comunaOferente.nombreComuna;
        } else {
          return;
        }

        let latitudDireccion = false;
        let longitudDireccion = false;
        if (
          values.latitudDireccionOferente &&
          values.longitudDireccionOferente &&
          !buscandoDireccionOferente
        ) {
          latitudDireccion = values.latitudDireccionOferente.toString();
          longitudDireccion = values.longitudDireccionOferente.toString();
          setLatLon([latitudDireccion, longitudDireccion]);
        }
        // console.log(longitudDireccion);

        const fullAddress =
          values.nombreNumeroViaOferente +
          ' ' +
          nombreComuna +
          ', ' +
          nombreRegion +
          ', Chile';

        const query = {
          street: values.nombreNumeroViaOferente,
          city: nombreComuna,
          state: nombreRegion,
          country: 'Chile',
          format: 'json', // [html|xml|json|jsonv2|geojson|geocodejson]
          lat: latitudDireccion,
          lon: longitudDireccion
        };
        setAddressQueryParams(query);
        setAddressQuerysearch(fullAddress);
      }
    },
    [buscandoDireccionOferente]
  );

  const handleObtenerDireccionDemandante = useCallback(
    values => {
      if (
        !values.nombreViaDemandante ||
        !values.comunaDemandante ||
        !values.regionDemandante
      ) {
        return;
      }

      if (values.comunaDemandante) {
        let nombreRegion = '';
        if (
          values.regionDemandante &&
          values.regionDemandante?.nombreRegion?.length > 0
        ) {
          nombreRegion = values.regionDemandante.nombreRegion;
        } else {
          return;
        }

        let nombreComuna = '';
        if (
          values.comunaDemandante &&
          values.comunaDemandante?.nombreComuna?.length > 0
        ) {
          nombreComuna = values.comunaDemandante.nombreComuna;
        } else {
          return;
        }

        let latitudDireccion = false;
        let longitudDireccion = false;
        if (
          values.latitudDireccionDemandante &&
          values.longitudDireccionDemandante &&
          !buscandoDireccionDemandante
        ) {
          latitudDireccion = values.latitudDireccionDemandante.toString();

          longitudDireccion = values.longitudDireccionDemandante.toString();
          setLatLon([latitudDireccion, longitudDireccion]);
        }
        // console.log(longitudDireccion);

        const fullAddress =
          values.nombreViaDemandante +
          ' ' +
          nombreComuna +
          ', ' +
          nombreRegion +
          ', Chile';

        const query = {
          street: values.nombreViaDemandante,
          city: nombreComuna,
          state: nombreRegion,
          country: 'Chile',
          format: 'json', // [html|xml|json|jsonv2|geojson|geocodejson]
          lat: latitudDireccion,
          lon: longitudDireccion
        };
        setAddressQueryParams(query);
        setAddressQuerysearch(fullAddress);
      }
    },
    [buscandoDireccionDemandante]
  );

  const handleObtenerComunas = useCallback(codigoRegion => {
    const params = {
      totalPagina: DICTONARY.PAGINACION.ALL_DATA,
      ordenarPor: 'nombreComuna'
    };
    if (codigoRegion) {
      params.codigoRegion = codigoRegion;
    }

    commons
      .obtieneComunas(params)
      .then(response => {
        if (response.length === 0) return;
        setCargaListaComunas(true);
        const content = response.data.content;
        setlistadoComunas(
          content.map(comuna => {
            return {
              codigoComuna: comuna.codigoComuna,
              nombreComuna: comuna.nombreComuna,
              region: {
                codigoRegion: comuna.region.codigoRegion,
                nombreRegion: comuna.region.nombreRegion
              }
            };
          })
        );
      })
      .catch(err => {
        console.error('error: ', err);
      });
  }, []);

  const initValues = async () => {
    commonsServices
      .obtieneRegiones()
      .then(response => {
        if (response.length === 0) return;
        setCargaListaRegiones(true);
        const content = response.data.content;
        setRegiones(
          content.map(region => {
            return {
              codigoRegion: region.codigoRegion,
              nombreRegion: region.nombreRegion
            };
          })
        );
      })
      .catch(err => {
        console.error('error: ', err);
      });
  };

  useEffect(() => {
    initValues();
  }, []);

  return {
    latLon,
    setLatLon,
    addressQueryParams,
    setAddressQueryParams,
    addressQuerysearch,
    setAddressQuerysearch,
    regiones,
    cargaListaRegiones,
    handleObtenerDireccion,
    handleObtenerComunas,
    listadoComunas,
    cargaListaComunas,
    setBuscandoDireccion,
    buscandoDireccion,
    setBuscandoDireccionOferente,
    buscandoDireccionOferente,
    setBuscandoDireccionDemandante,
    buscandoDireccionDemandante,
    handleObtenerDireccionOferente,
    handleObtenerDireccionDemandante
  };
};
