import { useEffect, useState } from 'react';
import AlertComponent from 'components/design-system/Alert/Alert';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import InputNumber from 'components/design-system/input/InputNumber';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
import { TooltipComponent } from 'components/design-system/Tooltip/Tooltip';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Help } from '@mui/icons-material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import { ObtieneListaLer, ObtieneListaTipoResiduos } from 'hooks';
import { CalculoListaLer, CalculoListaSubLer } from 'utils/useUtilidades';
import { DICTONARY } from 'const/Dictonary';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { PERMISOS } from 'const/Permisos';
import { DatePickerComponent } from 'components/design-system/DatePicker/DatePicker';

const ResiduoDeclarado = ({
  // Formik
  errors,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
  // Dependencias
  isLoadingFormatoEntrega,
  isLoadingLevantamiento,
  isLoadingSucursales,
  isLoadingUnidadMedida,
  formatoEntrega,
  levantamiento,
  setCargaNombreLER,
  setCargaTipoResiduos,
  sucursales,
  unidadMedida
}) => {
  const [listaSubTipoResiduos, setListaSubTipoResiduos] = useState([]);
  // Tiene Datos
  const [tieneSubSubtiposLer, setTieneSubSubtiposLer] = useState(true);
  const [tieneTipoResiduos, setTieneTipoResiduos] = useState(true);
  // Cargando
  const [cargandoListaSubTipoResiduos, setCargandoListaSubTipoResiduos] =
    useState(true);
  //Calculo SUBLER
  const {
    calculoListaSubLer,
    listaLerNivelDos,
    listaLerNivelTres,
    // tieneSubtiposLer,
    setListaLerNivelTres
  } = CalculoListaLer();
  // SERVICIO nombresLER
  const {
    listaLer,
    cargandoListaLer,
    // handlelistaler,
    listaLerNivelUno,
    listaLerNivelDosEdit,
    listaLerNivelUnoEdit,
    listaLerNivelTresEdit
  } = ObtieneListaLer();
  // SERVICIO nombresTipoResiduos
  const {
    listaTipoResiduos,
    cargandoListaTipoResiduos,
    // handleTipoResiuduos,
    listaTipoResiduosNivelUno,
    listaTipoResiduoNivelUnoEdit
  } = ObtieneListaTipoResiduos();

  // Calculo lista SubLer
  const { calculoListaSubSubLer } = CalculoListaSubLer();

  //Calculo SUBTIPOS RESIDUOS
  const calculoSubTipoResiduos = (codigoTipoResiduo, setFieldValue) => {
    const subtipos = listaTipoResiduos.filter(
      t =>
        t.nivel === 2 && t.codigoNivelAnteriorTipoResiduo === codigoTipoResiduo
    );

    setTieneTipoResiduos(!!subtipos.length);
    setFieldValue('tieneSubTipoResiduo', !!subtipos.length);

    subtipos.length > 0 && setCargandoListaSubTipoResiduos(false);

    subtipos && setListaSubTipoResiduos(subtipos);
  };

  // Lena selectores de LER para edición
  useEffect(() => {
    if (
      listaLerNivelDosEdit.length &&
      values.nombreSubSubLER?.codigoNivelAnterior
    ) {
      const nivelDosEdit = listaLerNivelDosEdit.find(
        ler => ler.codigoLER === values.nombreSubSubLER?.codigoNivelAnterior
      );
      setFieldValue('nombreSubLER', nivelDosEdit);
    }
  }, [
    listaLer.length,
    listaLerNivelDos,
    listaLerNivelDosEdit,
    setFieldValue,
    values.nombreSubSubLER?.codigoLER,
    values.nombreSubSubLER?.codigoNivelAnterior
  ]);

  useEffect(() => {
    if (
      listaLerNivelUnoEdit.length &&
      values.nombreSubLER?.codigoNivelAnterior
    ) {
      const nivelUnoEdit = listaLerNivelUnoEdit.find(
        ler => ler.codigoLER === values.nombreSubLER.codigoNivelAnterior
      );
      setFieldValue('nombreLER', nivelUnoEdit);
    }
  }, [
    listaLer.length,
    listaLerNivelUno,
    listaLerNivelUnoEdit,
    setFieldValue,
    values.nombreSubLER?.codigoLER,
    values.nombreSubLER?.codigoNivelAnterior
  ]);

  // Lena selectores de LER para edición
  useEffect(() => {
    if (
      listaTipoResiduoNivelUnoEdit.length &&
      values.subTipoResiduo?.codigoNivelAnteriorTipoResiduo
    ) {
      const nivelUnoEdit = listaTipoResiduoNivelUnoEdit.find(
        ler =>
          ler.codigoTipoResiduo ===
          values.subTipoResiduo.codigoNivelAnteriorTipoResiduo
      );

      setFieldValue('tipoResiduo', nivelUnoEdit);
    }
  }, [
    listaTipoResiduoNivelUnoEdit,
    listaTipoResiduoNivelUnoEdit.length,
    setFieldValue,
    values.nombreSubSubLER?.codigoLER,
    values.nombreSubSubLER?.codigoNivelAnterior,
    values.subTipoResiduo?.codigoNivelAnterior,
    values.subTipoResiduo?.codigoNivelAnteriorTipoResiduo
  ]);

  useEffect(() => {
    if (cargandoListaLer) setCargaNombreLER(cargandoListaLer);
    if (cargandoListaTipoResiduos)
      setCargaTipoResiduos(cargandoListaTipoResiduos);
  }, [
    cargandoListaLer,
    cargandoListaTipoResiduos,
    setCargaNombreLER,
    setCargaTipoResiduos
  ]);

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-8">
          <AlertComponent type="warning">
            <Text className="text-black font-bold">Atención:</Text>
            Dentro de este formulario existen campos opcionales que son
            necesarios para el cálculo de indicadores o para la exportación a
            Sinader. <strong>Ver más detalles</strong>
          </AlertComponent>
        </div>

        <div className="col-span-12 md:col-span-12">
          <Heading type="h4" className="mb-0">
            Residuo
          </Heading>
        </div>

        <div className="col-span-12 xs:col-span-12">
          <div className="grid grid-cols-12 gap-4">
            {/* Residuo */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <AutocompleteComponent
                accesor="nombreTipo"
                clearOnEscape={true}
                disabled={isSubmitting || cargandoListaTipoResiduos}
                fullWidth
                loading={cargandoListaTipoResiduos}
                loadingText={cargandoListaTipoResiduos && 'Cargando...'}
                name="tipoResiduo"
                openOnFocus={true}
                options={listaTipoResiduosNivelUno || []}
                size="small"
                value={values.tipoResiduo || ''}
                variant="outlined"
                getOptionLabel={option => option.nombreTipo || ''}
                onChange={(ev, selected) => {
                  if (!selected) {
                    setCargandoListaSubTipoResiduos(true);
                    setTieneTipoResiduos(false);
                    setFieldValue('tieneSubTipoResiduo', false);
                  }
                  setFieldValue('subTipoResiduo', null);
                  setFieldValue('tipoRecurso', null);

                  selected
                    ? setFieldValue('tipoResiduo', {
                        codigoTipoResiduo: selected.codigoTipoResiduo,
                        nombreTipo: selected.nombreTipo
                      })
                    : setFieldValue('tipoResiduo', null);
                  selected &&
                    calculoSubTipoResiduos(
                      selected.codigoTipoResiduo,
                      setFieldValue
                    );

                  selected && setFieldTouched('subTipoResiduo');
                }}
              >
                <InputComponent
                  fullWidth
                  onBlur={() => setFieldTouched('tipoResiduo')}
                  touched={touched.tipoResiduo}
                  placeholder={
                    listaTipoResiduosNivelUno.length
                      ? 'Seleccione tipo de residuo'
                      : ''
                  }
                  estado={
                    errors.tipoResiduo
                      ? {
                          tipo: 'error',
                          mensaje: errors.tipoResiduo
                        }
                      : null
                  }
                  label={
                    <LabelComponent className="w-full">
                      Residuo
                      <br />
                      <span className="text-neutral-60">
                        (incluye subproductos)
                      </span>
                      <TooltipComponent
                        className="float-right"
                        title={
                          'En esta casilla tienes la opción de incorporar el nombre que utilizas habitualmente del residuo, que genera el recurso registrado, en otras palabras, es una forma abreviada de reemplazar el nombre LER. Ejemplo: LER (Madera sin sustancias peligrosas) despuntes de Tablones, cuartones, planchas, listones, entre otros.'
                        }
                      >
                        <Help
                          className="text-uv-secondary-0"
                          fontSize="small"
                        />
                      </TooltipComponent>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>
            {/* Subtipo de residuo */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <AutocompleteComponent
                accesor="nombreTipo"
                clearOnEscape={true}
                disabled={isSubmitting || !values.tipoResiduo}
                loading={cargandoListaSubTipoResiduos}
                name="subTipoResiduo"
                openOnFocus={false}
                options={listaSubTipoResiduos || []}
                size="small"
                variant="outlined"
                noOptionsText={
                  cargandoListaSubTipoResiduos ? 'Cargando...' : 'Sin opciones'
                }
                value={values.subTipoResiduo || ''}
                getOptionLabel={option => option.nombreTipo || ''}
                onChange={(ev, selected) => {
                  selected
                    ? setFieldValue('subTipoResiduo', {
                        codigoTipoResiduo: selected.codigoTipoResiduo,
                        nombreTipo: selected.nombreTipo
                      })
                    : setFieldValue('subTipoResiduo', null);

                  // Limpieza tipoRecurso
                  setFieldValue('tipoRecurso', null);
                  setFieldValue('otroTipoRecurso', null);
                  setFieldValue('_otraMateriaPrima', '');

                  if (selected && selected.recursos.length > 0) {
                    setFieldValue('tieneTipoRecursos', true);
                    setFieldTouched('tipoRecurso');
                  } else {
                    setFieldValue('tieneTipoRecursos', false);
                    setFieldTouched('_otraMateriaPrima');
                  }
                  setFieldTouched('sucursal');
                }}
              >
                <InputComponent
                  fullWidth
                  onBlur={() => setFieldTouched('subTipoResiduo')}
                  touched={touched.subTipoResiduo}
                  placeholder={
                    listaSubTipoResiduos.length
                      ? 'Seleccione subtipo de residuo'
                      : ''
                  }
                  estado={
                    errors.subTipoResiduo
                      ? {
                          tipo: 'error',
                          mensaje: errors.subTipoResiduo
                        }
                      : null
                  }
                  label={
                    <LabelComponent className="w-full">
                      Subtipo de residuo
                      <br />
                      <span className="text-neutral-60">
                        (incluye subproductos)
                      </span>
                      <TooltipComponent
                        className="float-right"
                        title={
                          'En esta casilla tienes la opción de incorporar el nombre que utilizas habitualmente del residuo, que genera el recurso registrado, en otras palabras, es una forma abreviada de reemplazar el nombre LER. Ejemplo: LER (Madera sin sustancias peligrosas) despuntes de Tablones, cuartones, planchas, listones, entre otros.'
                        }
                      >
                        <Help
                          className="text-uv-secondary-0"
                          fontSize="small"
                        />
                      </TooltipComponent>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>
            {/* Fecha de la transacción */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={es}
              >
                <DesktopDatePicker
                  className="w-full"
                  slots={{ textField: DatePickerComponent }}
                  disabled={isSubmitting}
                  name="fechaTransaccion"
                  value={values.fechaTransaccion || null}
                  format="dd/MM/yyyy"
                  onChange={date => {
                    setFieldValue('fechaTransaccion', date);
                    setFieldTouched('cantidad');
                  }}
                  label={
                    <LabelComponent className="xl:mt-4 p-1">
                      Fecha de la transacción
                    </LabelComponent>
                  }
                  touched={touched.fechaTransaccion}
                  estado={
                    errors.fechaTransaccion
                      ? { tipo: 'error', mensaje: errors.fechaTransaccion }
                      : null
                  }
                />
              </LocalizationProvider>
            </div>

            {/* Sucursal Origen */}
            <VerificarPermisos
              nombrePermiso={PERMISOS.sucursalOrigenResiduoDeclarado}
            >
              <div className="col-span-12 md:col-span-6 xl:col-span-4">
                <AutocompleteComponent
                  accesor="nombreSucursal"
                  clearOnEscape={true}
                  disabled={isSubmitting || isLoadingSucursales}
                  fullWidth
                  name={'sucursal'}
                  options={sucursales || []}
                  value={values.sucursal || ''}
                  onChange={(event, newValue, reason) => {
                    let data = { name: 'sucursal', value: newValue };
                    if (reason === 'clear') {
                      data.value = null;
                    }
                    setFieldValue('sucursal', data.value);
                    setFieldTouched('fechaTransaccion');
                  }}
                  getOptionLabel={option => option.nombreSucursal || ''}
                  noOptionsText={
                    isLoadingSucursales ? 'Cargando...' : 'Sin opciones'
                  }
                >
                  <InputComponent
                    autoComplete="off"
                    disabled={isSubmitting || isLoadingSucursales}
                    fullWidth
                    onBlur={() => {
                      setFieldTouched('sucursal');
                    }}
                    touched={touched.sucursal}
                    placeholder={
                      isLoadingSucursales
                        ? 'Cargando...'
                        : 'Selecciona una opción'
                    }
                    estado={
                      errors.sucursal
                        ? { tipo: 'error', mensaje: errors.sucursal }
                        : null
                    }
                    label={<LabelComponent>Sucursal de origen</LabelComponent>}
                  />
                </AutocompleteComponent>
              </div>
            </VerificarPermisos>

            {/* Cantidad  */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <InputNumber
                autoComplete="off"
                disabled={isSubmitting}
                label={<LabelComponent>Cantidad</LabelComponent>}
                maxDecimals={DICTONARY.NUMERO_DECIMALES}
                name={'cantidad'}
                placeholder={!values.cantidad ? '' : ''}
                touched={touched.cantidad}
                type="text"
                value={values.cantidad_str || values.cantidad || ''}
                onChange={event => {
                  const { value } = event.target;
                  if (!value) {
                    setFieldValue('cantidad_str', null);
                    setFieldValue('cantidad', null);
                    return;
                  }
                  const sanitizedValue = value
                    .replaceAll('.', '')
                    .replaceAll(',', '.');
                  const floatValue = parseFloat(sanitizedValue);
                  if (!isNaN(floatValue)) {
                    setFieldValue('cantidad', floatValue);
                  }
                  setFieldTouched('unidadMedida', true);
                }}
                onFocus={event => {
                  const { value } = event.target;
                  setFieldTouched('cantidad');
                  if (
                    !event ||
                    !event.target ||
                    typeof event.target.value !== 'string'
                  ) {
                    return;
                  }
                  let sanitizedValue = value.split('.').join('') || '';
                  setFieldValue('cantidad_str', sanitizedValue);
                }}
                onBlur={() => {
                  setFieldTouched('cantidad');
                }}
                estado={
                  errors.cantidad
                    ? { tipo: 'error', mensaje: errors.cantidad }
                    : null
                }
              />
            </div>
            {/* Unidad de Medida  */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <AutocompleteComponent
                accesor="nombreUnidadMedida"
                clearOnEscape={true}
                disabled={isSubmitting || isLoadingUnidadMedida}
                fullWidth
                name={'unidadMedida'}
                options={unidadMedida || []}
                value={values.unidadMedida || ''}
                onChange={(event, newValue, reason) => {
                  let data = { name: 'unidadMedida', value: newValue };
                  if (reason === 'clear') {
                    data.value = null;
                  }
                  setFieldValue('unidadMedida', data.value);
                  setFieldTouched('tipoTransporte');
                }}
                getOptionLabel={option => option.nombreUnidadMedida || ''}
                noOptionsText={
                  isLoadingUnidadMedida ? 'Cargando...' : 'Sin opciones'
                }
              >
                <InputComponent
                  autoComplete="off"
                  disabled={isLoadingUnidadMedida}
                  fullWidth
                  onBlur={() => {
                    setFieldTouched('unidadMedida');
                  }}
                  touched={touched.unidadMedida}
                  placeholder={
                    isLoadingUnidadMedida
                      ? 'Cargando...'
                      : 'Selecciona una opción'
                  }
                  estado={
                    errors.unidadMedida
                      ? { tipo: 'error', mensaje: errors.unidadMedida }
                      : null
                  }
                  label={<LabelComponent>Unidad</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>
            {/* Formato de Entrega */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <AutocompleteComponent
                accesor="nombreFormatoEntrega"
                clearOnEscape={true}
                disabled={isSubmitting || isLoadingFormatoEntrega}
                fullWidth
                name={'formatoEntrega'}
                options={formatoEntrega || []}
                value={values.formatoEntrega || ''}
                onChange={(event, newValue, reason) => {
                  let data = { name: 'formatoEntrega', value: newValue };
                  if (reason === 'clear') {
                    data.value = null;
                  }
                  setFieldValue('formatoEntrega', data.value);
                }}
                getOptionLabel={option => option.nombreFormatoEntrega || ''}
                noOptionsText={
                  isLoadingFormatoEntrega ? 'Cargando...' : 'Sin opciones'
                }
              >
                <InputComponent
                  autoComplete="off"
                  disabled={isSubmitting || isLoadingFormatoEntrega}
                  fullWidth
                  onBlur={() => {
                    setFieldTouched('formatoEntrega');
                  }}
                  touched={touched.formatoEntrega}
                  placeholder={
                    isLoadingFormatoEntrega
                      ? 'Cargando...'
                      : 'Selecciona una opción'
                  }
                  estado={
                    errors.formatoEntrega
                      ? { tipo: 'error', mensaje: errors.formatoEntrega }
                      : null
                  }
                  label={
                    <LabelComponent className="w-full" esOpcional>
                      Formato de entrega{' '}
                      <TooltipComponent
                        className="float-right"
                        title={
                          'Indica la forma en el que tú residuo fue retirado, si necesitaste algún envase o embalaje para retirarlo o sólo se fue a granel.'
                        }
                      >
                        <Help
                          className="text-uv-secondary-0"
                          fontSize="small"
                        />
                      </TooltipComponent>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>
            {/* Levantamiento de datos */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <AutocompleteComponent
                accesor="nombreLevantamientoDatos"
                clearOnEscape={true}
                disabled={isSubmitting || isLoadingLevantamiento}
                fullWidth
                name={'levantamientoDatos'}
                options={levantamiento || []}
                value={values.levantamientoDatos || ''}
                onChange={(event, newValue, reason) => {
                  let data = { name: 'levantamientoDatos', value: newValue };
                  if (reason === 'clear') {
                    data.value = null;
                  }
                  setFieldValue('levantamientoDatos', data.value);
                }}
                getOptionLabel={option => option.nombreLevantamientoDatos || ''}
                noOptionsText={
                  isLoadingLevantamiento ? 'Cargando...' : 'Sin opciones'
                }
              >
                <InputComponent
                  autoComplete="off"
                  disabled={isLoadingLevantamiento}
                  fullWidth
                  onBlur={() => {
                    setFieldTouched('levantamientoDatos');
                  }}
                  touched={touched.levantamientoDatos}
                  placeholder={
                    isLoadingLevantamiento
                      ? 'Cargando...'
                      : 'Selecciona una opción'
                  }
                  estado={
                    errors.levantamientoDatos
                      ? { tipo: 'error', mensaje: errors.levantamientoDatos }
                      : null
                  }
                  label={
                    <LabelComponent esOpcional>
                      Levantamiento de datos{' '}
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>

            {/* NombreLER */}
            <div className="col-span-12 mt-4 -mb-4">
              <LabelComponent className="mb-0">
                Nombre LER (Listado europeo de residuos)
                <span className="text-uv-secondary-0">(opcional)</span>
              </LabelComponent>
            </div>
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <AutocompleteComponent
                accesor="nombreLER"
                className=""
                clearOnEscape={true}
                disabled={isSubmitting || cargandoListaLer}
                fullWidth
                getOptionLabel={option => option.nombreLER || ''}
                loading={!listaLerNivelUno.length && cargandoListaLer}
                loadingText={!listaLerNivelUno.length && 'Cargando...'}
                name="nombreLER"
                noOptionsText="No hay coincidencias"
                openOnFocus={true}
                options={listaLerNivelUno || []}
                size="small"
                value={values.nombreLER || ''}
                variant="outlined"
                onChange={(ev, selected) => {
                  setFieldValue('nombreSubLER', []);
                  setFieldValue('nombreSubSubLER', []);

                  selected
                    ? setFieldValue('nombreLER', {
                        codigoLER: selected.codigoLER,
                        nombreLER: selected.nombreLER
                      })
                    : setFieldValue('nombreLER', []);

                  calculoListaSubLer(listaLer, selected, setFieldValue);

                  setFieldTouched('codigoSubLER');
                }}
                isOptionEqualToValue={(option, value) =>
                  option.nombreLER === value.nombreLER
                }
              >
                <InputComponent
                  className=""
                  fullWidth
                  onBlur={() => setFieldTouched('nombreLER')}
                  touched={touched.nombreLER}
                  placeholder={
                    listaLerNivelUno.length ? 'Seleccione nombre LER' : ''
                  }
                  estado={
                    errors.nombreLER
                      ? {
                          tipo: 'error',
                          mensaje: errors.nombreLER
                        }
                      : null
                  }
                  label={
                    <LabelComponent>
                      <span className="text-neutral-60">Categoría</span>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>
            {/* NombreSubLER */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <AutocompleteComponent
                accesor="nombreLER"
                className=""
                clearOnEscape={true}
                disabled={
                  isSubmitting || cargandoListaLer || values.nombreLER === null
                }
                getOptionLabel={option => option.nombreLER || ''}
                loading={!listaLerNivelDos.length && cargandoListaLer}
                loadingText={!listaLerNivelDos.length && 'Cargando...'}
                name="nombreSubLER"
                noOptionsText="No hay coincidencias"
                openOnFocus={false}
                options={listaLerNivelDosEdit.filter(
                  ler => ler.codigoNivelAnterior === values.nombreLER?.codigoLER
                )}
                size="small"
                value={values.nombreSubLER || ''}
                variant="outlined"
                onChange={(ev, selected) => {
                  // Limpieza al cambiar de opción
                  setFieldValue('nombreSubSubLER', []);
                  selected
                    ? setFieldValue('nombreSubLER', {
                        codigoLER: selected.codigoLER,
                        nombreLER: selected.nombreLER
                      })
                    : setFieldValue('nombreSubLER', []);

                  //Calculo SUB SUB LER
                  calculoListaSubSubLer(
                    listaLer,
                    selected,
                    setFieldValue,
                    setListaLerNivelTres,
                    setTieneSubSubtiposLer
                  );

                  setFieldTouched('nombreSubSubLER');
                  setFieldTouched('tipoResiduo');
                }}
              >
                <InputComponent
                  fullWidth
                  disabled={
                    listaLerNivelDos.length === 0 ||
                    cargandoListaLer ||
                    values.nombreLER === null
                  }
                  onBlur={() => setFieldTouched('nombreSubLER')}
                  touched={touched.nombreSubLER}
                  placeholder={
                    listaLerNivelDos.length ? 'Seleccione nombre LER' : ''
                  }
                  estado={
                    errors.nombreSubLER
                      ? {
                          tipo: 'error',
                          mensaje: errors.nombreSubLER
                        }
                      : null
                  }
                  label={
                    <LabelComponent>
                      <span className="text-neutral-60">Capítulo</span>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>
            {/* NombreSubSubLER */}
            <div className="col-span-12 md:col-span-6 xl:col-span-4">
              <AutocompleteComponent
                accesor="nombreLER"
                clearOnEscape={true}
                disabled={
                  isSubmitting || cargandoListaLer || values.nombreLER === null
                }
                getOptionLabel={option => option.nombreLER || ''}
                loading={!listaLerNivelTres.length && cargandoListaLer}
                loadingText={!listaLerNivelTres.length && 'Cargando...'}
                name="nombreSubSubLER"
                noOptionsText="No hay coincidencias"
                openOnFocus={false}
                options={listaLerNivelTresEdit.filter(
                  ler =>
                    ler.codigoNivelAnterior === values.nombreSubLER?.codigoLER
                )}
                size="small"
                value={values.nombreSubSubLER || ''}
                variant="outlined"
                onChange={(ev, selected) => {
                  selected
                    ? setFieldValue('nombreSubSubLER', {
                        codigoLER: selected.codigoLER,
                        nombreLER: selected.nombreLER
                      })
                    : setFieldValue('nombreSubSubLER', []);

                  setFieldTouched('codigoSubSubLER');
                  setFieldTouched('tipoResiduo');
                }}
              >
                <InputComponent
                  fullWidth
                  onBlur={() => setFieldTouched('nombreSubSubLER')}
                  touched={touched.nombreSubSubLER}
                  placeholder={
                    listaLerNivelTres.length ? 'Seleccione nombre LER' : ''
                  }
                  disabled={
                    isSubmitting ||
                    cargandoListaLer ||
                    values.nombreLER === null
                  }
                  estado={
                    errors.nombreSubSubLER
                      ? {
                          tipo: 'error',
                          mensaje: errors.nombreSubSubLER
                        }
                      : null
                  }
                  label={
                    <LabelComponent>
                      <span className="text-neutral-60">Sub capítulo</span>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResiduoDeclarado;
