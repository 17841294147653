import { useEffect } from 'react';
// Dependencias

// Secciones
import {
  RecursoAdquirido, // Seccion 1
  GeneradorVendedor, // Seccion 2
  Transporte, // Seccion 3
  ContenidoReciclado, // Seccion 4
  EnvaseEmbalaje, // Seccion 5
  GeneracionResiduo, // Seccion 6
  DocumentosRespaldo // Seccion 7
} from '../SeccionesRecursosAdquiridos';
// Utils
import { SaltoLinia } from '../../utils/utils';
import { UseTocarCamposFormik } from 'views/RecursoAdquirido/hooks/useUtilsFormik';

const CamposRecursosAdquiridos = ({
  // Formik
  errors,
  handleBlur,
  handleChange,
  handleReset,
  handleSubmit,
  isSubmitting,
  setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  validateForm,
  values,
  // Dependencias

  // Seccion 1 - RecursoAdquirido

  // Sucursales
  isLoadingSucursales,
  setIsLoadingSucursales,
  setSucursales,
  sucursales,
  // Tipo Recursos Adquiridos
  isLoadingRecursosAdquiridos,
  setIsLoadingTipoRecursosAdquiridos,
  setTipoRecursosAdquiridos,
  tipoRecursosAdquiridos,
  // Unidad de Medida
  isLoadingUnidadMedida,
  setIsLoadingUnidadMedida,
  setUnidadMedida,
  unidadMedida,
  // Levantamiento
  isLoadingLevantamiento,
  levantamiento,
  setIsLoadingLevantamiento,
  setLevantamiento,

  // Seccion 2 - GeneradorVendedor

  // Regiones
  cargaComunasPorRegion,
  cargaEmpresasSII,
  // Empresas SII
  empresasSII,
  isLoadingEmpresasSII,
  setEmpresasSII,
  setIsLoadingEmpresasSII,

  // Seccion 3 - Transporte

  isLoadingTipoTransporte,
  setIsLoadingTipoTransporte,
  setTipoTransporte,
  tipoTransporte,

  // Seccion 4 - ContenidoReciclado

  // Seccion 5 - EnvaseEmbalaje
  isLoadingTipoEmbalaje,
  setIsLoadingTipoEmbalaje,
  setTipoEmbalaje,
  tipoEmbalaje,

  // Seccion 6 - GeneracionResiduo

  // Seccion 7 - DocumentosRespaldo

  // Tipo Formulario
  tipoForm
}) => {
  const { editar, duplicar } = tipoForm;

  useEffect(() => {
    // Tocar todos los campos para que se muestren los errores
    if (editar || duplicar) {
      UseTocarCamposFormik(values, setFieldTouched);
    }
  }, [duplicar, editar, setFieldTouched, values]);

  return (
    <>
      <RecursoAdquirido
        // Formik
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleReset={handleReset}
        setFieldError={setFieldError}
        handleSubmit={handleSubmit}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched}
        validateForm={validateForm}
        values={values}
        // Dependencias
        isLoadingLevantamiento={isLoadingLevantamiento}
        isLoadingSucursales={isLoadingSucursales}
        setIsLoadingTipoRecursosAdquiridos={setIsLoadingTipoRecursosAdquiridos}
        isLoadingUnidadMedida={isLoadingUnidadMedida}
        levantamiento={levantamiento}
        sucursales={sucursales}
        tipoRecursosAdquiridos={tipoRecursosAdquiridos}
        unidadMedida={unidadMedida}
        tipoForm={tipoForm}
      />

      <SaltoLinia />

      <GeneradorVendedor
        // Formik
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setFieldError={setFieldError}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched}
        validateForm={validateForm}
        values={values}
        // Dependencias

        // Empresas SII
        cargaEmpresasSII={cargaEmpresasSII}
        empresasSII={empresasSII}
        isLoadingEmpresasSII={isLoadingEmpresasSII}
        // Tipo Formulario
        tipoForm={tipoForm}
      />

      <SaltoLinia />

      <Transporte
        // Formik
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleReset={handleReset}
        setFieldError={setFieldError}
        handleSubmit={handleSubmit}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched}
        validateForm={validateForm}
        values={values}
        // Dependencias
        isLoadingTipoTransporte={isLoadingTipoTransporte}
        setTipoTransporte={setTipoTransporte}
        tipoTransporte={tipoTransporte}
        tipoForm={tipoForm}
      />

      <SaltoLinia />

      <ContenidoReciclado
        // Formik
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleReset={handleReset}
        setFieldError={setFieldError}
        handleSubmit={handleSubmit}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched}
        validateForm={validateForm}
        values={values}
        // Dependencias
        tipoForm={tipoForm}
      />

      <SaltoLinia />

      <EnvaseEmbalaje
        // Formik
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleReset={handleReset}
        setFieldError={setFieldError}
        handleSubmit={handleSubmit}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched}
        validateForm={validateForm}
        values={values}
        // Dependencias
        tipoEmbalaje={tipoEmbalaje}
        isLoadingTipoEmbalaje={isLoadingTipoEmbalaje}
        tipoForm={tipoForm}
      />

      <SaltoLinia />

      <GeneracionResiduo
        // Formik
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleReset={handleReset}
        setFieldError={setFieldError}
        handleSubmit={handleSubmit}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched}
        validateForm={validateForm}
        values={values}
        // Dependencias
        tipoForm={tipoForm}
        //  no aplica ya que se consumne desde RSD
      />

      <SaltoLinia />

      <DocumentosRespaldo
        // Formik
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        handleReset={handleReset}
        setFieldError={setFieldError}
        handleSubmit={handleSubmit}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched}
        validateForm={validateForm}
        values={values}
        tipoForm={tipoForm}
        // Dependencias
        //  no aplica
      />
      <SaltoLinia />
    </>
  );
};

export default CamposRecursosAdquiridos;
