import * as yup from 'yup';

const schemaNuevaSucursal = yup.object().shape({
  nombreSucursal: yup
    .string()
    .min(3, 'El nombre es muy corto')
    .max(100, 'El nombre no puede ser tan largo')
    .required('Este campo es obligatorio'),

  region: yup
    .object()
    .typeError('Este campo es obligatorio')
    .required('Este campo es obligatorio'),

  comuna: yup
    .object()
    .typeError('Este campo es obligatorio')
    .required('Este campo es obligatorio'),

  nombreVia: yup
    .string()
    .trim()
    // validacion para direccion nombre y numero  de via
    // .matches(
    //   /^[A-Za-z0-9ñÑ\s]+(?: [A-Za-z0-9ñÑ\s]+)*,\s*\d+$/,
    //   'La dirección debe ser del tipo "nombre de calle, número"'
    // )
    .max(100, 'La cantidad máxima de caracteres es 100')
    .min(3, 'La cantidad mínima de caracteres es 3')
    .required('Es un campo obligatorio'),

  codigosTipoResiduo: yup
    .array()
    .test(
      'checked',
      'Debes seleccionar al menos un residuo',
      function (residuos) {
        if (residuos) {
          return residuos.some(residuo => residuo.checked);
        }
      }
    )
});

export { schemaNuevaSucursal };
