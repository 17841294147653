import { Divider } from '@mui/material';

export const SaltoLinia = text => {
  return (
    <div className="col-span-12 my-2">
      <Divider />
    </div>
  );
};

export const diccionarioVista = {
  tituloListado: 'Administración de recursos adquiridos',
  tituloTabla: 'Recursos adquiridos ingresados',
  nombrePrincipal: 'Recurso Adquirido',
  nombrePrincipalMayuscula: 'RECURSO ADQUIRIDO',
  nombrePrincipalSingular: 'recurso adquirido',
  nombrePrincipalPlural: 'recursos adquiridos',
  primerNombrePrincipal: 'recurso'
};
