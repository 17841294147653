export const ICON_HORAS_DE_TRABAJO = ({ className }) => {
  return (
    <svg className={className} width="54" height="37" viewBox="0 0 54 37">
      <defs>
        <clipPath id="clip-horas-de-trabajo">
          <rect width="54" height="37" />
        </clipPath>
      </defs>
      <g id="horas-de-trabajo" clip-path="url(#clip-horas-de-trabajo)">
        <g
          id="Grupo_1945"
          data-name="Grupo 1945"
          transform="translate(23226 2746)"
        >
          <path
            id="ic_people_outline_24px"
            d="M28.781,19.339a21.483,21.483,0,0,0-8.065,1.792,21.142,21.142,0,0,0-8.065-1.792C8.761,19.339,1,21.275,1,25.164v4.929H40.431V25.164C40.431,21.275,32.67,19.339,28.781,19.339ZM21.612,27.4H3.688v-2.24c0-.968,4.588-3.137,8.962-3.137s8.962,2.169,8.962,3.137Zm16.131,0H24.3v-2.24a3.261,3.261,0,0,0-.932-2.187,17.291,17.291,0,0,1,5.413-.95c4.373,0,8.962,2.169,8.962,3.137ZM12.65,17.546a6.273,6.273,0,1,0-6.273-6.273A6.28,6.28,0,0,0,12.65,17.546Zm0-9.858a3.585,3.585,0,1,1-3.585,3.585A3.6,3.6,0,0,1,12.65,7.689Zm16.131,9.858a6.273,6.273,0,1,0-6.273-6.273A6.28,6.28,0,0,0,28.781,17.546Zm0-9.858A3.585,3.585,0,1,1,25.2,11.273,3.6,3.6,0,0,1,28.781,7.689Z"
            transform="translate(-23225.242 -2748.936)"
            fill="#376B3E"
          />
          <g
            id="Grupo_1039"
            data-name="Grupo 1039"
            transform="translate(-23197.08 -2733.559)"
          >
            <ellipse
              id="Elipse_171"
              data-name="Elipse 171"
              cx="11.5"
              cy="12"
              rx="11.5"
              ry="12"
              transform="translate(0.081 -0.441)"
              fill="#43A7B6"
            />
          </g>
          <path
            id="ic_schedule_24px"
            d="M10.583,2a8.592,8.592,0,1,0,8.6,8.592A8.588,8.588,0,0,0,10.583,2Zm.009,15.466a6.874,6.874,0,1,1,6.874-6.874A6.872,6.872,0,0,1,10.592,17.466Zm.43-11.17H9.733v5.155l4.511,2.707.644-1.057-3.866-2.294Z"
            transform="translate(-23195.752 -2732.577)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
