import { DICTONARY } from 'const/Dictonary';
import { PERMISOS } from 'const/Permisos';
import { AuthContext } from 'context';
import { numeroFrontABaseDatos } from 'helpers/formateoNumeros';
import { useContext, useEffect, useState } from 'react';
import empresasService from 'services/empresas';
import { AlertaError, AlertaExito } from 'utils/Alertas';

export const useEditarIndicador = (codigoSucursal, codigoEmpresa) => {
  const { logeduser, refrescarSesion, token, isTienePermisos } =
    useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [sucursal, setSucursal] = useState();

  /**
   * @name cargarSucursal
   * Función para cargar la sucursal que se está desplegando, en el caso de que el código no viene se asume que es una nueva sucursal.
   */
  const cargarSucursal = async () => {
    setIsLoading(true);
    if (codigoSucursal !== 'nuevo') {
      try {
        const responseSucursal = await empresasService.getSucursal(
          codigoSucursal
        );
        const error = responseSucursal.data.error;
        if (error) throw new Error('ERROR_CREAR_REUNION');

        setSucursal(responseSucursal.data);
      } catch (error) {
        console.log('Error al cargar la sucursal', error);
        setSucursal(null);
      }
    }
    setIsLoading(false);
  };

  const formularioInicial = sucursalSeleccionada => {
    const roles = sucursalSeleccionada
      ? sucursalSeleccionada.empresa.roles.map(rol =>
          rol.nombreRol.replaceAll(' ', '')
        )
      : [DICTONARY.ROL.GENERADOR.NOMBRE];
    const initialValue = {
      base: {
        role: roles,
        trabajadoresMasculino: sucursalSeleccionada?.trabajadoresMasculino,
        trabajadoresFemenino: sucursalSeleccionada?.trabajadoresFemenino,
        consumoEnergetico: sucursalSeleccionada?.consumoEnergetico,
        consumoCombustible: sucursalSeleccionada?.consumoCombustible
      },
      generador: {
        residuosGenerados: sucursalSeleccionada?.residuosGenerados,
        residuosProyectados: sucursalSeleccionada?.residuosProyectados,
        procesoGestion: sucursalSeleccionada?.trabajadoresMasGestion
          ? true
          : false,
        trabajadoresMasGestion:
          sucursalSeleccionada?.trabajadoresMasGestion || '',
        trabajadoresFemGestion:
          sucursalSeleccionada?.trabajadoresFemGestion || '',
        totalHH: sucursalSeleccionada?.totalHH || '',
        remuneracionesPagadas: sucursalSeleccionada?.remuneracionesPagadas || ''
      },
      receptor: {
        residuosCompradosRecibidos:
          sucursalSeleccionada?.residuosCompradosRecibidos,
        procesoGestion: sucursalSeleccionada?.trabajadoresMasGestion
          ? true
          : false,
        trabajadoresMasGestion:
          sucursalSeleccionada?.trabajadoresMasGestion || '',
        trabajadoresFemGestion:
          sucursalSeleccionada?.trabajadoresFemGestion || '',
        totalHH: sucursalSeleccionada?.totalHH || '',
        remuneracionesPagadas: sucursalSeleccionada?.remuneracionesPagadas || ''
      },
      transportista: {},
      gestor: {},
      asesor: {}
    };

    try {
      if (isTienePermisos(PERMISOS.residuoGenerado)) {
        initialValue.generador.residuosGenerados =
          sucursalSeleccionada?.residuosGenerados;
      }
      if (isTienePermisos(PERMISOS.residuoProyectado)) {
        initialValue.generador.residuosProyectados =
          sucursalSeleccionada?.residuosProyectados;
      }
      if (isTienePermisos(PERMISOS.recibidoComprado)) {
        initialValue.receptor.residuosCompradosRecibidos =
          sucursalSeleccionada?.residuosCompradosRecibidos;
      }
      if (isTienePermisos(PERMISOS.totalProyectadoTransportar)) {
        initialValue.transportista.capacidadProyectada =
          sucursalSeleccionada?.capacidadProyectada;
      }
      if (isTienePermisos(PERMISOS.totalTransportadas)) {
        initialValue.transportista.residuosTransportados =
          sucursalSeleccionada?.residuosTransportados;
      }
      if (isTienePermisos(PERMISOS.kmRecorridos)) {
        initialValue.transportista.kilometrosRecorridos =
          sucursalSeleccionada?.kilometrosRecorridos;
      }

      if (isTienePermisos(PERMISOS.totalGestionar)) {
        initialValue.gestor.residuosGestionar =
          sucursalSeleccionada?.residuosGestionar;
      }
      if (isTienePermisos(PERMISOS.totalGestionado)) {
        initialValue.gestor.residuosGestionados =
          sucursalSeleccionada?.residuosGestionados;
      }

      if (isTienePermisos(PERMISOS.totalAsesorado)) {
        initialValue.asesor.residuosAsesorados =
          sucursalSeleccionada?.residuosAsesorados;
      }

      const externalObj = {};
      roles.map(rol => {
        let data = initialValue[rol];
        Object.keys(data).forEach(key => {
          externalObj[key] = data[key];
        });
        return data;
      });

      return {
        ...initialValue.base,
        ...externalObj
      };
    } catch (error) {
      return {
        ...initialValue.base
      };
    }
  };

  const onSubmit = async (codigoSucursal, values) => {
    const cleanValues = { ...values };
    delete cleanValues.role;

    Object.keys(cleanValues).forEach(key => {
      if (typeof cleanValues[key] === 'string') {
        const numeroFormateado = numeroFrontABaseDatos(cleanValues[key]);
        cleanValues[key] = numeroFormateado;
      }
    });

    try {
      const response = await empresasService.actualizarIndicadores(
        codigoSucursal,
        cleanValues
      );
      if (codigoSucursal === logeduser?.sucursal?.codigoSucursal) {
        await refrescarSesion(token);
      }
      const error = response.data.error;
      if (error) throw new Error('ERROR_AL_GUARDAR_EL_INDICADOR');
      AlertaExito('Se han guardado los cambios correctamente');
    } catch (error) {
      console.log(error);
      AlertaError(
        'Ha ocurrido un error al guardar los datos vuelve a intentarlo. Si el problema persiste comunicate con el administrador del sitio'
      );
    }
  };

  useEffect(() => {
    cargarSucursal();
  }, []);

  return {
    isLoading,
    sucursal,
    formularioInicial: formularioInicial,
    onSubmit: onSubmit
  };
};
