import React, { useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  BusinessCertifications,
  BusinessInfo,
  BusinessRole,
  BusinessRSD,
  BusinessSuccess,
  BusinessType
} from './steppers';
import InitialFormValues from './data/initalValues';
import { schema } from './schema/Schema';
import { useHistory } from 'react-router-dom';
import serviceEmpresas from 'services/empresas';
import { stepsIngresar } from './helpers';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { AuthContext } from 'context';
import { NavegacionFormulario } from 'components/design-system/NavegacionFormulario/NavegacionFormulario';
import BusinessClients from './steppers/BusinessClients';
import { formatoServicio } from './helpers/formatoServicio';
import { CircularProgress } from '@mui/material';
import { BusinessError } from './steppers/BusinessError';
import { RUTAS } from 'const/Rutas';

const NewBusiness = () => {
  const { refrescarSesion, token } = useContext(AuthContext);
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [errorService, setErrorService] = useState(false);

  const switchSteps = props => {
    // eslint-disable-next-line default-case
    switch (props.step) {
      case 0:
        return (
          <BusinessType
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onChange={props.handleChange}
            handleBlur={props.handleBlur}
            handleReset={props.handleReset}
            setFieldValue={props.setFieldValue}
            setFieldTouched={props.setFieldTouched}
            setFieldError={props.setFieldError}
            isSubmitting={props.isSubmitting}
          />
        );
      case 1:
        return (
          <BusinessRole
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onChange={props.handleChange}
            handleBlur={props.handleBlur}
          />
        );
      case 2:
        return (
          <BusinessInfo
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onChange={props.handleChange}
            handleBlur={props.handleBlur}
            setFieldValue={props.setFieldValue}
            setFieldTouched={props.setFieldTouched}
            setFieldError={props.setFieldError}
          />
        );
      case 3:
        return (
          <BusinessRSD
            setFieldValue={props.setFieldValue}
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onChange={props.handleChange}
            handleBlur={props.handleBlur}
            setFieldError={props.setFieldError}
          />
        );
      case 4:
        return (
          <BusinessCertifications
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            setFieldValue={props.setFieldValue}
          />
        );
      case 5:
        return (
          <BusinessClients
            setFieldValue={props.setFieldValue}
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            onChange={props.handleChange}
            handleBlur={props.handleBlur}
            setFieldTouched={props.setFieldTouched}
            setFieldError={props.setFieldError}
          />
        );

      case 6:
        return <div></div>;
    }
  };

  const onSiguientePaso = (validateForm, setFieldTouched) => {
    validateForm().then(resp => {
      const validaciones = Object.keys(resp);
      validaciones.forEach(campo => {
        setFieldTouched(campo, true);
      });

      if (validaciones.length !== 0) {
        return;
      }

      window.scrollTo(0, 0);
      setStep(paso => paso + 1);
    });
  };

  const onFinalizar = handleSubmit => {
    handleSubmit();
  };

  const handlePasoAnterior = () => {
    if (step === 0) history.push(RUTAS.HOME_USER);
    if (step > 0) {
      window.scrollTo(0, 0);
      setStep(step - 1);
    }
  };

  return (
    <SidebarLayout
      containerClassName="md:px-4 md:container md:mx-auto"
      childrenContainerClassName={'col-span-12 lg:col-span-10 pt-0 md:pt-10'}
    >
      <Formik
        initialValues={InitialFormValues}
        validationSchema={schema[step]}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={values => {
          try {
            setStep(6);
            const dataFormateada = formatoServicio(values);
            serviceEmpresas
              .empresa(dataFormateada)
              .then(response => {
                refrescarSesion(token).finally(() => {
                  setStep(7);
                  setErrorService(false);
                });
                setIsLoading(false);
              })
              .catch(error => {
                setErrorService(true);
                setIsLoading(false);
              });
          } catch (error) {
            setErrorService(true);
            setIsLoading(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleReset,
          handleSubmit,
          setFieldValue,
          handleBlur,
          setErrors,
          setFieldError,
          setFieldTouched,
          isSubmitting,
          validateForm
        }) => (
          <Form
            onSubmit={event => {
              event.preventDefault();
            }}
          >
            {errorService ? (
              <BusinessError
                onReintentar={() => {
                  setStep(0);
                  setErrorService(false);
                }}
              />
            ) : (
              <>
                {step === 7 ? (
                  <BusinessSuccess values={values} isLoading={isLoading} />
                ) : (
                  <NavegacionFormulario
                    titulo="Registro de negocio o actividad"
                    pasos={stepsIngresar}
                    onCancelar={() => history.push(RUTAS.HOME_USER)}
                    onSiguiente={() =>
                      onSiguientePaso(validateForm, setFieldTouched)
                    }
                    onFinalizar={() => onFinalizar(handleSubmit)}
                    onVolver={() => handlePasoAnterior()}
                    pasoActual={step}
                    isSubmitting={isSubmitting}
                  >
                    {isSubmitting ? (
                      <div className="h-80 flex justify-center items-center">
                        <CircularProgress
                          color="inherit"
                          size={80}
                          thickness={2}
                        />
                      </div>
                    ) : (
                      switchSteps({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError,
                        handleReset,
                        step,
                        setStep,
                        errorService,
                        isSubmitting,
                        setErrors
                      })
                    )}
                  </NavegacionFormulario>
                )}
              </>
            )}
          </Form>
        )}
      </Formik>
    </SidebarLayout>
  );
};

export default NewBusiness;
