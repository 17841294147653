import API from './api';

const adminService = {
  getPerfiles: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/perfiles`,
      params: params,
      interceptor: conInterceptor
    });
  },

  getPerfil: function (codigoPerfil, params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/perfiles/${codigoPerfil}`,
      params: params,
      interceptor: conInterceptor
    });
  },

  postPerfil: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: `/perfiles`,
      data: data,
      interceptor: conInterceptor
    });
  },

  putPerfil: function (codigoPerfil, data, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: `/perfiles/${codigoPerfil}`,
      data: data,
      interceptor: conInterceptor
    });
  },

  deletePerfil: function (codigoPerfil, conInterceptor = true) {
    return API.axios({
      method: 'delete',
      url: `/perfiles/${codigoPerfil}`,
      interceptor: conInterceptor
    });
  },

  getPermisos: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/permisos`,
      params: params,
      interceptor: conInterceptor
    });
  },

  getPermiso: function (codigoPermiso, params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/permisos/${codigoPermiso}`,
      params: params,
      interceptor: conInterceptor
    });
  }
};

export default adminService;
