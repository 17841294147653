import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { RoleComponent } from 'components/design-system/Role/Role';
import { ExpandLess, Close, Add } from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ListaUsuarios } from './ListaUsuarios';
import { ListaParticipantes } from './ListaParticipantes';
import Text from 'components/design-system/Text/Text';

export const PanelParticipantes = ({
  handleAddUserClick,
  handleRemoveUserClick,

  usuariosListAsesores,
  usuariosListGestores,
  usuariosListTransportista,
  usuariosNegocio,

  toggleMenu,
  participantes,
  disabledRoles,
  isLoadingUsuarios,
  empresasIniciales
}) => {
  const [open, setOpen] = useState({
    participantes: false,
    asesores: false,
    gestores: false,
    tranportista: false
  });
  const [usuariosEmpresaActual, setUsuariosEmpresaActual] = useState([]);

  const handleExpandir = tipo => {
    setOpen({
      participantes: false,
      asesores: false,
      gestores: false,
      tranportista: false,
      [tipo]: true
    });
  };

  const handleComprimir = () => {
    setOpen({
      participantes: false,
      asesores: false,
      gestores: false,
      tranportista: false
    });
  };


  useEffect(() => {
    let usuariosFiltrados = [];

    for (let index = 0; index < usuariosNegocio.length; index++) {
      const usuarioNegocio = usuariosNegocio[index];
      const usuarioParticipante = participantes.find(
        user => user.codigoUsuario === usuarioNegocio.codigoUsuario
      );
      if (!usuarioParticipante) {
        usuariosFiltrados.push(usuarioNegocio);
      }
    }

    setUsuariosEmpresaActual(usuariosFiltrados);
  }, [participantes, usuariosNegocio]);

  useEffect(() => {
    handleComprimir();
  }, [isLoadingUsuarios]);

  return (
    <div className="h-[80vh] flex flex-col">
      <div className="px-4 py-2 border-b border-solid border-b-neutral-90">
        <Heading type="h4" className="flex items-center justify-between mb-0">
          <div>Participantes</div>
          <IconButtonComponent type="secondary" onClick={toggleMenu}>
            <Close />
          </IconButtonComponent>
        </Heading>
      </div>

      <div className="h-full">
        {isLoadingUsuarios ? (
          <div className="w-full flex items-center justify-center h-full">
            <CircularProgress
              thickness={4}
              size={40}
              className="text-neutral-50 relative z-10 visible"
            />
          </div>
        ) : (
          <>
            <List
              className="border-b border-solid border-b-neutral-90 pb-3"
              subheader={
                <ListItem>
                  <ListItemText>
                    <span className="font-secondary text-demandante font-semibold text-xs">
                      Usuarios
                    </span>
                  </ListItemText>

                  {usuariosEmpresaActual &&
                    usuariosEmpresaActual.length > 0 && (
                      <ListItemSecondaryAction
                        className="w-8 h-8"
                        onClick={() =>
                          open.participantes
                            ? handleComprimir()
                            : handleExpandir('participantes')
                        }
                      >
                        <IconButtonComponent
                          type="secondary"
                          className="w-8 h-8"
                          edge="end"
                          aria-label="expandAdd"
                        >
                          {open.participantes ? <ExpandLess /> : <Add />}
                        </IconButtonComponent>
                      </ListItemSecondaryAction>
                    )}
                </ListItem>
              }
            >
              {open.participantes && (
                <div className="pb-4 ml-1">
                  <ListaUsuarios
                    usuarios={usuariosEmpresaActual}
                    handleAddUserClick={handleAddUserClick}
                  />
                </div>
              )}

              <ListaParticipantes
                usuarios={participantes}
                handleRemoveUserClick={handleRemoveUserClick}
                empresasIniciales={empresasIniciales}
              />
            </List>

            <List
              className="border-b border-solid border-b-neutral-90 pb-0"
              subheader={
                <ListItem>
                  <ListItemText>
                    <div
                      className={`${
                        disabledRoles.incluyeAsesor ? 'opacity-50' : ''
                      }`}
                    >
                      <RoleComponent rol="asesor" tipo="icono" size="m" />
                      <span className="font-secondary text-gestor font-semibold text-xs pl-2">
                        Asesores
                      </span>
                    </div>
                  </ListItemText>

                  {!disabledRoles.incluyeAsesor ? (
                    <ListItemSecondaryAction
                      onClick={() =>
                        open.asesores
                          ? handleComprimir()
                          : handleExpandir('asesores')
                      }
                      className="w-8 h-8"
                    >
                      <IconButtonComponent
                        type="secondary"
                        className="w-8 h-8"
                        edge="end"
                        aria-label="expandAdd"
                      >
                        {open.asesores ? <ExpandLess /> : <Add />}
                      </IconButtonComponent>
                    </ListItemSecondaryAction>
                  ) : null}
                </ListItem>
              }
            >
              {disabledRoles.incluyeAsesor && (
                <div className="mx-4 mb-4">
                  <div className="bg-warning-light rounded px-4 py-2 w-full">
                    <Text size="S">
                      No puedes agregar otros asesores, ya existe un asesor en
                      la sala.
                    </Text>
                  </div>
                </div>
              )}

              {open.asesores && !disabledRoles.incluyeAsesor && (
                <ListaUsuarios
                  usuarios={usuariosListAsesores.filter(u => !u.isAdded)}
                  handleAddUserClick={handleAddUserClick}
                />
              )}
            </List>

            <List
              className="border-b border-solid border-b-neutral-90 pb-0"
              subheader={
                <ListItem>
                  <ListItemText>
                    <div
                      className={`${
                        disabledRoles.incluyeGestor ? 'opacity-50' : ''
                      }`}
                    >
                      <RoleComponent rol="gestor" tipo="icono" size="m" />
                      <span className="font-secondary text-gestor font-semibold text-xs pl-2">
                        Gestores
                      </span>
                    </div>
                  </ListItemText>

                  {!disabledRoles.incluyeGestor ? (
                    <ListItemSecondaryAction
                      className="w-8 h-8"
                      onClick={() =>
                        open.gestores
                          ? handleComprimir()
                          : handleExpandir('gestores')
                      }
                    >
                      <IconButtonComponent
                        type="secondary"
                        className="w-8 h-8"
                        edge="end"
                        aria-label="expandAdd"
                      >
                        {open.gestores ? <ExpandLess /> : <Add />}
                      </IconButtonComponent>
                    </ListItemSecondaryAction>
                  ) : null}
                </ListItem>
              }
            >
              {disabledRoles.incluyeGestor && (
                <div className="mx-4 mb-4">
                  <div className="bg-warning-light rounded px-4 py-2 w-full">
                    <Text size="S">
                      No puedes agregar otros gestores, ya existe uno en la
                      sala.
                    </Text>
                  </div>
                </div>
              )}

              {open.gestores && !disabledRoles.incluyeGestor && (
                <ListaUsuarios
                  usuarios={usuariosListGestores.filter(u => !u.isAdded)}
                  handleAddUserClick={handleAddUserClick}
                />
              )}
            </List>

            <List
              subheader={
                <ListItem>
                  <ListItemText>
                    <div
                      className={`${
                        disabledRoles.incluyeTransportista ? 'opacity-50' : ''
                      }`}
                    >
                      <RoleComponent
                        rol="transportista"
                        tipo="icono"
                        size="m"
                      />
                      <span className="font-secondary text-gestor font-semibold text-xs pl-2">
                        Transportista
                      </span>
                    </div>
                  </ListItemText>

                  {!disabledRoles.incluyeTransportista ? (
                    <ListItemSecondaryAction
                      className="w-8 h-8"
                      onClick={() =>
                        open.tranportista
                          ? handleComprimir()
                          : handleExpandir('tranportista')
                      }
                    >
                      <IconButtonComponent
                        type="secondary"
                        className="w-8 h-8"
                        edge="end"
                        aria-label="expandAdd"
                      >
                        {open.tranportista ? <ExpandLess /> : <Add />}
                      </IconButtonComponent>
                    </ListItemSecondaryAction>
                  ) : null}
                </ListItem>
              }
            >
              {disabledRoles.incluyeTransportista && (
                <div className="mx-4 mb-4">
                  <div className="bg-warning-light rounded px-4 py-2 w-full">
                    <Text size="S">
                      No puedes agregar otros transportistas, ya existe uno en
                      la sala.
                    </Text>
                  </div>
                </div>
              )}

              {open.tranportista && !disabledRoles.incluyeTransportista && (
                <ListaUsuarios
                  usuarios={usuariosListTransportista.filter(u => !u.isAdded)}
                  handleAddUserClick={handleAddUserClick}
                />
              )}
            </List>
          </>
        )}
      </div>
    </div>
  );
};
