import { FormControlLabel, RadioGroup } from '@mui/material';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import InputComponent from 'components/design-system/input/Input';
import RadioComponent from 'components/design-system/Radio/Radio';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import { PERMISOS } from 'const/Permisos';
import { AuthContext } from 'context';
import { useContext, useState } from 'react';
import { ResponsiveFiltro } from './ResponsiveFiltro';

export const FiltrarResultados = ({
  ocultar,
  dataNegocios,
  dataResiduos,
  setItemsToShow,
  selectedNegocio,
  setSelectedNegocio,
  tipoNegocio,
  setTipoNegocio
}) => {
  // const { isLGDown } = useResponsive();

  const [selectedResiduo, setSelectedResiduo] = useState(null);

  const renderTagsNegocios = values => {
    if (values.length === 1) {
      return <Text>1 Negocio seleccionado</Text>;
    }

    return <Text>{values.length} Negocios seleccionados</Text>;
  };
  const renderTagsResiduos = values => {
    if (values.length === 1) {
      return <Text>1 Residuo seleccionado</Text>;
    }

    return <Text>{values.length} Residuos seleccionados</Text>;
  };
  const { isTienePermisos } = useContext(AuthContext);

  return (
    <div className={`border-t py-4 ${ocultar ? 'hidden' : 'block'}`}>
      <ResponsiveFiltro>
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 md:col-span-6 xl:col-span-3">
            <AutocompleteComponent
              name="nombreEmpresa"
              disable
              disableCloseOnSelect
              // accesor="nombreEmpresa"
              // getOptionLabel={option => option.nombreEmpresa || ''}
              // options={dataNegocios}
              accesor="label"
              getOptionLabel={option => option.label || ''}
              options={dataNegocios}
              renderTags={values => renderTagsNegocios(values)}
              value={selectedNegocio || ''}
              onChange={(ev, object) => {
                if (object === null) {
                  // Restablecer al estado inicial o limpiar el array
                  // setDataNegocios(residuosDeclarados);
                  setItemsToShow(5);
                  setSelectedNegocio(null);
                } else {
                  // Filtrar el array de objetos por algún criterio del objeto seleccionado
                  // const negociosFiltrados = dataNegocios.filter(
                  //   negocio => negocio.codigoEmpresa === object.codigoEmpresa
                  // );
                  const negociosFiltrados = dataNegocios.filter(
                    negocio => negocio.value === object.value
                  );

                  // Actualizar el estado con el array filtrado
                  // setDataNegocios(negociosFiltrados);
                  // setResiduos(negociosFiltrados.residuos);

                  setSelectedNegocio(object);
                }
              }}
              // onBlur={(e) =>
              //   console.log('e', e)
              // }
            >
              <InputComponent autoComplete={'off'} placeholder="Nombre" />
            </AutocompleteComponent>
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3 hidden">
            <AutocompleteComponent
              name="residuo"
              disable
              disableCloseOnSelect
              accesor="residuo"
              options={dataResiduos}
              renderTags={values => renderTagsResiduos(values)}
              getOptionLabel={option => option.residuo || ''}
              value={selectedResiduo || ''}
              onChange={(ev, object) => {
                if (object === null) {
                  // Restablecer al estado inicial o limpiar el array
                  // setDataResiduos(residuos);
                  setItemsToShow(5);
                  setSelectedResiduo(null);
                } else {
                  // Filtrar el array de objetos por algún criterio del objeto seleccionado
                  const residuosFiltrados = dataNegocios.filter(negocio =>
                    negocio.residuos.some(
                      residuo => residuo.codigo === object.codigo
                    )
                  );

                  console.log('residuosFiltrados ::>', residuosFiltrados);

                  // Actualizar el estado con el array filtrado

                  // setDataNegocios(residuosFiltrados);
                  setSelectedResiduo(object);
                }
              }}
              //onBlur={() => setFieldTouched('sectorEconomico')}
            >
              <InputComponent
                autoComplete={'off'}
                placeholder="Nombre residuo"
              />
            </AutocompleteComponent>
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3 xl:text-center">
            {isTienePermisos(PERMISOS.empresaGeneradoraResiduoDeclarado) ? (
              <RadioGroup
                aria-labelledby="Rol del negocio"
                defaultValue="generador"
                name="rol"
                onChange={ev => setTipoNegocio(ev.target.value)}
                value={tipoNegocio}
              >
                <FormControlLabel
                  value="generador"
                  control={<RadioComponent />}
                  label={<Text>{DICTONARY.ROL.GENERADOR.TITULO}</Text>}
                />
                <FormControlLabel
                  value="receptor"
                  control={<RadioComponent />}
                  label={<Text>{DICTONARY.ROL.RECEPTOR.TITULO}</Text>}
                />
              </RadioGroup>
            ) : null}
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3 xl:justify-end flex gap-4">
            {/*
            <ButtonComponent
              type={isLGDown ? 'primary' : 'secondary'}
              onClick={() => { }}
            >
              <FilterList className="mr-2" />
              Filtrar
              <span className="hidden sm:inline-block pl-1">resultados</span>
            </ButtonComponent> 
            */}

            {/*
            {isLGDown && (
              <ButtonComponent type="secondary" onClick={() => {}}>
                <Delete className="mr-2" />{' '}
                <span className="hidden sm:block md:hidden">
                  Borrar filtros
                </span>
              </ButtonComponent>
            )}
*/}
          </div>
        </div>
      </ResponsiveFiltro>
    </div>
  );
};
