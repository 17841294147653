import React, { useContext, useEffect, useState } from 'react';
import AlertComponent from 'components/design-system/Alert/Alert';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import serviceEmpresas from 'services/empresas';
import { DICTONARY } from 'const/Dictonary';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import { CargaActividades, CargaRubros } from 'views/Business/hooks';
import { PERMISOS } from 'const/Permisos';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { TooltipComponent } from 'components/design-system/Tooltip/Tooltip';
import { AuthContext } from 'context';
import { InputAsyncController } from 'components/design-system/InputAsync/InputAsyncController';
import { verificaEmpresaRegistradaNombre } from '../helpers/verificaCorreoRegistrado';

export const BusinessInfo = ({
  values,
  errors,
  touched,
  onChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  isSubmitting
}) => {
  const { isTienePermisos } = useContext(AuthContext);
  const { rubros, loadRubros, isLoadingRubros } = CargaRubros();
  const { actividades, loadActividades, isLoadingActividades } =
    CargaActividades();
  const [cargandoServicioSII, setCargandoServicioSII] = useState(false);

  useEffect(() => {
    loadRubros();
  }, []);

  // Llama servicio datos empresa SII
  const cargaDatosEmpresasSII = async rutEmpresa => {
    if (values.tipoNegocio === 'persona-natural') return;
    setCargandoServicioSII(true);

    setFieldValue('razonSocial', null);
    setFieldValue('nombreEmpresa', null);
    setFieldValue('rubro', null);
    setFieldValue('actividad', null);

    const response = await serviceEmpresas.datosEmpresasSII(
      { rutEmpresa: rutEmpresa.value },
      DICTONARY.INTERCEPTOR.DESACTIVADO
    );

    const data = response.data;
    setFieldValue('razonSocial', data.razonSocial);
    setFieldValue('nombreEmpresa', data.razonSocial);
    loadRubros();
    loadActividades(values.rubro.codigoCiuRubro);
    setCargandoServicioSII(false);
  };

  const obtenerTodasEmpresasAT = async ({
    setLoadingFetch,
    value,
    oldValue
  }) => {
    try {
      setLoadingFetch(true);
      setFieldTouched('nombreEmpresa');
      if (oldValue === value) {
        return;
      }

      const nombreExiste = await verificaEmpresaRegistradaNombre(value);
      if (nombreExiste) {
        setFieldError(
          'nombreEmpresa',
          'El nombre ingresado ya ha sido registrado'
        );
      } else {
        setFieldValue('nombreEmpresa', value);
      }
    } catch (error) {
      return error;
    } finally {
      setLoadingFetch(false);
    }
  };

  useEffect(() => {
    if (values.tipoNegocio === 'persona-natural') return;
    if (
      values.actividad &&
      values.rubro &&
      Object.keys(values.rubro).length === 0 &&
      Object.keys(values.actividad).length === 0
    ) {
      setFieldTouched('actividad');
      setFieldTouched('rubro');
      setFieldValue('rubro', '');
      setFieldValue('actividad', '');
    }
  }, [
    setFieldError,
    setFieldTouched,
    setFieldValue,
    values.actividad,
    values.rubro,
    values.tipoNegocio
  ]);

  return (
    <div>
      <Heading type="h3">Información del negocio</Heading>

      <div className="grid grid-cols-12 gap-4 pb-6">
        <div className="col-span-6">
          <InputAsyncController
            name="nombreEmpresa"
            label={<LabelComponent>Nombre Empresa</LabelComponent>}
            touched={touched.nombreEmpresa ? true : false}
            fullWidth
            setFieldValue={data => setFieldValue('nombreEmpresa', data)}
            setFieldTouched={() => setFieldTouched('nombreEmpresa')}
            value={values.nombreEmpresa ? values.nombreEmpresa : ''}
            isSubmitting={isSubmitting}
            onBlur={() => setFieldTouched('nombreEmpresa')}
            onChange={event => {
              setFieldValue('nombreEmpresa', event.target.value);
              setFieldTouched('nombreEmpresa');
            }}
            fetchCall={controllerValues =>
              obtenerTodasEmpresasAT({
                setLoadingFetch: controllerValues.setIsLoading,
                value: controllerValues.inputValue,
                oldValue: values.nombreEmpresaOriginal
              })
            }
            estado={
              errors.nombreEmpresa
                ? { tipo: 'error', mensaje: errors.nombreEmpresa }
                : null
            }
          />
        </div>

        {values.tipoNegocio === 'empresa' && (
          <>
            <div className="col-span-12 md:col-span-6">
              <InputAsyncController
                name="rutEmpresa"
                touched={touched.nombre ? true : false}
                fullWidth
                setFieldValue={data => setFieldValue('rutEmpresa', data)}
                setFieldTouched={() => setFieldTouched('rutEmpresa')}
                value={values.rutEmpresa}
                isSubmitting={isSubmitting}
                onBlur={() => setFieldTouched('nombre')}
                onChange={event => {
                  setFieldValue('rutEmpresa', event.target.value);
                  setFieldTouched('nombre');
                }}
                label={<LabelComponent>Rut Empresa</LabelComponent>}
                fetchCall={controllerValues =>
                  cargaDatosEmpresasSII({
                    setLoadingFetch: controllerValues.setIsLoading,
                    value: controllerValues.inputValue
                  })
                }
                estado={
                  errors.nombre
                    ? { tipo: 'error', mensaje: errors.nombre }
                    : null
                }
              />
            </div>
            <div className="col-span-12">
              <AlertComponent>
                <strong>La razón social, rubro y actividad</strong> han sido{' '}
                <strong>obtenidos de forma automática</strong> con la
                información entregada por Servicio de Impuestos Internos (SII),
                <strong>
                  {' '}
                  según su última actualización de noviembre 2022 con datos
                  registrados hasta el 2021.
                </strong>{' '}
                <br />
                En el caso de que estos datos automáticos no corresponden con la
                situación actual de tu empresa, por favor registra tu negocio y
                posterior a esto solicita mediante el botón de Ayuda la
                actualización adjuntando los datos del SII correctos.
              </AlertComponent>
            </div>
            <div className="col-span-12">
              <InputComponent
                autoComplete={'off'}
                fullWidth
                label={<LabelComponent>Razón social</LabelComponent>}
                name="razonSocial"
                onBlur={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldTouched('razonSocial');
                  setFieldTouched('nombreEmpresa');
                }}
                touched={touched.razonSocial}
                onChange={e => {
                  setFieldValue('nombreModificado', false);
                  onChange(e);
                }}
                value={values.razonSocial ? values.razonSocial : ''}
                estado={
                  errors.razonSocial
                    ? {
                        tipo: 'error',
                        mensaje: errors.razonSocial
                      }
                    : null
                }
              />
            </div>
            <div className="col-span-12 lg:col-span-6">
              <AutocompleteComponent
                options={rubros}
                id="rubro"
                name="rubro"
                loading={isLoadingRubros}
                accesor={'nombreRubro'}
                onChange={(ev, selected, reason) => {
                  const data = { name: 'rubro', value: selected };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  if (data.value) {
                    setFieldValue('nombreModificado', false);
                    setFieldValue('actividad', null);
                    setFieldValue('rubro', data.value);
                    setFieldTouched('actividad');

                    data.value.codigoCiuRubro && loadRubros();
                    data.value.codigoCiuRubro &&
                      loadActividades(data.value.codigoCiuRubro);
                  } else {
                    setFieldValue('rubro', '');
                    setFieldValue('actividad', '');
                  }
                }}
                onBlur={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldTouched('rubro');
                }}
                getOptionLabel={option => option.nombreRubro || ''}
                value={values.rubro}
              >
                <InputComponent
                  autoComplete={'off'}
                  touched={touched.rubro}
                  disabled={
                    errors.rutEmpresa || cargandoServicioSII ? true : false
                  }
                  fullWidth
                  label={<LabelComponent>Rubro</LabelComponent>}
                  estado={
                    errors.rubro
                      ? {
                          tipo: 'error',
                          mensaje: errors.rubro
                        }
                      : null
                  }
                />
              </AutocompleteComponent>
            </div>

            <div className="col-span-12 lg:col-span-6">
              <AutocompleteComponent
                id="actividades"
                loading={isLoadingActividades}
                name="actividad"
                accesor={'nombreActividad'}
                options={actividades}
                onChange={(ev, selected, reason) => {
                  const data = { name: 'actividad', value: selected };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  if (data.value) {
                    setFieldValue('nombreModificado', false);
                    setFieldValue('actividad', data.value);
                  }
                }}
                onBlur={e => {
                  setFieldValue('nombreModificado', false);
                  setFieldTouched('actividad');
                }}
                getOptionLabel={option => option.nombreActividad || ''}
                value={values.actividad}
              >
                <InputComponent
                  autoComplete={'off'}
                  disabled={
                    errors.rutEmpresa ||
                    errors.razonSocial ||
                    errors.nombreEmpresa
                      ? true
                      : false
                  }
                  fullWidth
                  label={<LabelComponent>Actividad</LabelComponent>}
                  touched={touched.actividad}
                  estado={
                    errors.actividad
                      ? {
                          tipo: 'error',
                          mensaje: errors.actividad
                        }
                      : null
                  }
                />
              </AutocompleteComponent>
            </div>
          </>
        )}

        {values.codigoRolesEmpresa.includes(
          DICTONARY.ROL.RECICLADORDEBASE.CODIGO.toString()
        ) && (
          <div className="col-span-12 md:col-span-6">
            <InputComponent
              label={
                <LabelComponent>
                  Tipo de negocio
                  <TooltipComponent title="Si deseas cambiar el tipo de negocio registrado deberás contactar al soporte técnico mediante el botón flotante ayuda">
                    <HelpOutline color="primary" fontSize="small" />
                  </TooltipComponent>
                </LabelComponent>
              }
              fullWidth
              value={'Reciclador de Base'}
            />
          </div>
        )}

        {isTienePermisos(PERMISOS.areaDeTrabajo) &&
          values.areaTrabajo !== null && (
            <div className="col-span-12 md:col-span-6">
              <InputComponent
                fullWidth
                label={<LabelComponent>Zona o área de trabajo</LabelComponent>}
                name="areaTrabajo"
                onChange={onChange}
                onBlur={handleBlur}
                value={values.areaTrabajo}
                touched={touched.areaTrabajo}
                estado={
                  errors.areaTrabajo
                    ? { tipo: 'error', mensaje: errors.areaTrabajo }
                    : null
                }
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default BusinessInfo;
