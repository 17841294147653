import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { AppBar, Toolbar } from '@mui/material';
import ResponsiveMenu from './responsive/ResponsiveMenu';
import { ResponsiveButtonMenu } from './responsive/components/ResponsiveButtonMenu';
import { MenuMarketPlace } from './Items/MenuMarketPlace';
import { MenuGestiona } from './Items/MenuGestiona';
import { MenuReportes } from './Items/MenuReportes';
import { MenuTutoriales } from './Items/MenuTutoriales';
import { MenuAnalistaTerritorial } from './Items/MenuAnalistaTerritorial';
import { MenuHome } from './Items/MenuHome';
import { MenuLogo } from './Items/MenuLogo';
import { MenuUsuarioLogeado } from './Items/MenuUsuarioLogeado';
import { MenuFinal } from './Items/MenuFinal';
import { MenuLogin } from './Items/MenuLogin';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { history, match, className, ...rest } = props;
  const classes = useStyles();
  const forceRefresh = to => e => {
    e.preventDefault();
    history.push(to);
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <div className="md:container md:mx-auto py-2 pt-3 pb-4 sm:pt-0 sm:pb-0 w-full">
          <div className="grid grid-cols-12 gap-4 items-center justify-between content-between">
            <MenuLogo />

            <MenuUsuarioLogeado>
              <MenuHome />
              <MenuMarketPlace forceRefresh={forceRefresh} />
              <MenuGestiona forceRefresh={forceRefresh} />
              <MenuReportes forceRefresh={forceRefresh} />
              <MenuTutoriales forceRefresh={forceRefresh} />
              <MenuAnalistaTerritorial forceRefresh={forceRefresh} />
            </MenuUsuarioLogeado>

            <MenuFinal>
              <MenuLogin forceRefresh={forceRefresh} history={history} />
              <ResponsiveButtonMenu>
                <ResponsiveMenu />
              </ResponsiveButtonMenu>
            </MenuFinal>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
