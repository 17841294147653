import { withRouter, useHistory } from 'react-router-dom';

import ButtonComponent from 'components/design-system/Button/Button';
import FormularioIngresarResiduosAT from './components/FormularioIngresarResiduosAT';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Text from 'components/design-system/Text/Text';
import { ChevronLeft, Close, Save } from '@mui/icons-material';
import { Divider, FormHelperText, Hidden } from '@mui/material';
import { Form, Formik } from 'formik';
import { SchemaIngresaResiduosAT } from './schema/SchemaIngresaResiduosAT';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { initialValuesIngresarResiduosAT } from './data/initialValuesIngresarResiduoAT';
import { useEffect, useState } from 'react';
import { CrearResiduoAT } from 'views/CargaMasiva/hooks/useServices';
import { DICTONARY } from 'const/Dictonary';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import LabelComponent from 'components/design-system/Label/Label';
import InputComponent from 'components/design-system/input/Input';
import { DatePickerComponent } from 'components/design-system/DatePicker/DatePicker';

const IngresarResiduoAT = () => {
  const navigate = useHistory();
  const [stateValidation, setStateValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getIcon = tipo => {
    const BASE = DICTONARY.ESTADO[tipo.toUpperCase()];
    return <BASE.ICONO className="mr-1 text-lg" />;
  };

  useEffect(() => {}, []);

  return (
    <SidebarLayout>
      <>
        <div className="mb-4">
          <Heading type="h2" className="mb-0">
            <IconButtonComponent
              onClick={() => navigate.goBack()}
              type="secondary"
              className="mr-2"
            >
              <ChevronLeft />
            </IconButtonComponent>
            Ingresar Residuo
          </Heading>
          <Text className="mb-0 ml-4">Análisis Territorial</Text>
        </div>
        <Formik
          initialValues={initialValuesIngresarResiduosAT}
          validationSchema={SchemaIngresaResiduosAT}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={async (values, { setSubmitting }) => {
            console.log('submiting final..');

            const respRquest = {
              nombreLER: values.nombreSubSubLER.codigoLER,
              tratamiento: values.tratamiento,
              cantidad: values.cantidad,
              empresaATOrigen: values.empresaATOrigen.value,
              empresaATDestino: values.empresaATDestino.value,
              anyoDeclaracion: parseInt(values.anyoDeclaracion.getFullYear())
            };

            try {
              await CrearResiduoAT({ setIsLoading, data: respRquest });
            } catch (error) {
              console.log('error :>> ', error);
              setIsLoading(false);
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleReset,
            handleSubmit,
            setFieldValue,
            handleBlur,
            setFieldError,
            setFieldTouched,
            isSubmitting,
            validateForm
          }) => (
            <Form
              onChange={event => {
                // console.log('errors  :>> ', errors);
                // console.log('touched :>> ', touched);
                // console.log('values  :>> ', values);
                setStateValidation(true);
              }}
            >
              <div className="px-4 md:container md:mx-auto">
                <div className="bg-white py-5 rounded shadow-md">
                  <div className="col-span-12 lg:col-span-9 pl-4 pr-5">
                    <div className="grid grid-cols-1 lg:grid-cols-3  gap-1 pb-6">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={es}
                      >
                        <DesktopDatePicker
                          className={`${
                            errors.anyoDeclaracion && 'border border-red-500'
                          } mb-0 pb-0`}
                          name="anyoDeclaracion"
                          inputFormat="yyyy"
                          label={
                            <LabelComponent>Seleccionar año</LabelComponent>
                          }
                          value={values.anyoDeclaracion}
                          defaultValue={values.anyoDeclaracion}
                          maxDate={new Date(DICTONARY.CURRENT_YEAR, 11, 31)} // Fecha máxima permitida: 31 de diciembre del año actual
                          views={['year']}
                          onChange={value => {
                            handleChange({
                              target: {
                                name: 'anyoDeclaracion',
                                value: value
                              }
                            });
                          }}
                          slots={{ textField: DatePickerComponent }}
                        />
                        {errors.anyoDeclaracion && touched.anyoDeclaracion ? (
                          <Text
                            size="s"
                            className="font-medium flex items-center py-0 mt-0 text-error-dark"
                          >
                            {getIcon('error')}
                            {errors.anyoDeclaracion}
                          </Text>
                        ) : null}
                      </LocalizationProvider>
                    </div>

                    <div className="grid grid-cols-12 gap-4 pb-6">
                      <FormularioIngresarResiduosAT
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleReset={handleReset}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        setFieldError={setFieldError}
                        setFieldTouched={setFieldTouched}
                        isSubmitting={isSubmitting}
                        validateForm={validateForm}
                      />
                    </div>

                    <Divider />

                    <div className="my-5">
                      <div className="flex justify-between">
                        <div className="text-start">
                          <ButtonComponent
                            type="secondary"
                            className="w-full xs:w-auto justify-center"
                            isLoading={isSubmitting}
                            disabled={isSubmitting}
                            onClick={() => {
                              navigate.goBack();
                            }}
                          >
                            <>
                              <Hidden smDown>Cancelar</Hidden>
                              <Hidden smUp>
                                <Close />
                              </Hidden>
                            </>
                          </ButtonComponent>
                        </div>
                        <div className="text-end">
                          <ButtonComponent
                            type="primary"
                            buttonType="submit"
                            isLoading={isSubmitting}
                            disabled={
                              stateValidation &&
                              Object.keys(errors).length === 0
                                ? false
                                : true || isLoading
                            }
                            className="w-full xs:w-auto justify-center"
                            onClick={() => {}}
                          >
                            <>
                              <Hidden smDown>
                                {isSubmitting
                                  ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD
                                      .EDITAR_IS_LOADING
                                  : DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD
                                      .EDITAR}
                              </Hidden>
                              <Hidden smUp>
                                <Save />
                              </Hidden>
                            </>
                          </ButtonComponent>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </SidebarLayout>
  );
};

export default withRouter(IngresarResiduoAT);
