import Star from '@mui/icons-material/Star';
import { RUTAS } from 'const/Rutas';
import { PreviaMatchFormik } from './PreviaMatchFormik';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import TaskAlt from '@mui/icons-material/TaskAlt';
import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import LinkComponent from 'components/design-system/Link/Link';
import Text from 'components/design-system/Text/Text';
import imgFondoMapaExtended from 'assets/images/match/bg-fondo-mapa-extended.png';
import imgMatchAutomatico from 'assets/images/marketplace/match-automatico.svg';

/**
 * @name PreviaMatch
 * Vista que es cargada cuando un usuario no tiene negocio y le permite establecer parametros iniciales de búsqueda manual.
 */
export const PreviaMatch = () => {
  return (
    <div className="bg-white h-full relative">
      <div className="grid grid-cols-12 gap-4 h-60 bg-uv-primary-10 relative">
        <div className="absolute left-0 top-0 right-0 sm:h-60">
          <div className="px-4 md:container md:mx-auto">
            <div className="grid grid-cols-12 gap-4 sm:h-60 items-center">
              <div className="col-span-12 sm:col-span-8 lg:col-span-5 xl:col-span-4 text-end mb-10 mt-4 sm:mt-0">
                <div className="text-start">
                  <Heading type="h2" className="text-white">
                    Búsqueda de Residuos
                  </Heading>
                  <Text className="text-uv-primary-80 font-normal">
                    Encuentra{' '}
                    <b className="text-white font-semibold">residuos</b> que
                    están siendo{' '}
                    <b className="text-white font-semibold">
                      ofrecidos o requeridos
                    </b>{' '}
                    por las empresas registradas y visualiza sus datos
                    georeferenciados en el mapa.{' '}
                    <b className="text-white font-semibold">
                      Los resultados estarán basados en el residuo y/o recurso
                      seleccionado
                    </b>
                    .
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-8 lg:col-span-5 xl:col-span-4 text-end mb-10 relative"></div>
        <div
          className="hidden sm:block sm:col-span-4 lg:col-span-7 xl:col-span-8 bg-no-repeat bg-top bg-cover diagonal-shape h-60"
          style={{ backgroundImage: `url(${imgFondoMapaExtended})` }}
        ></div>
      </div>

      <div className="px-4 md:container md:mx-auto z-10 relative sm:-top-10">
        <div className="bg-white rounded p-5 mb-10 shadow-md">
          <PreviaMatchFormik />
        </div>

        <div className="bg-white mb-20">
          <div className="grid grid-cols-12 gap-5 border border-neutral-90 rounded py-6 items-center px-5">
            <div className="col-span-12 sm:col-span-4 text-center mx-auto">
              <img src={imgMatchAutomatico} alt="" />
            </div>
            <div className="col-span-12 sm:col-span-8">
              <Heading type="H3" className="flex">
                <Star className="mr-2 text-uv-primary-0" /> Match automático
              </Heading>
              <Text>
                Te entrega resultados georeferenciados de residuos no peligrosos
                y recursos subutilizados que están siendo ofrecidos o requeridos
                en el Marketplace, considerando filtros adicionales como fecha
                de disponibilidad, distancia, rubro, certificaciones, y otros.
              </Text>

              <ul className="mt-5 mb-8">
                <li key={1} className="flex items-center mb-1">
                  <IconComponent className="mr-2">
                    <TaskAlt className="text-base" />
                  </IconComponent>
                  <Text>Busca según tus necesidades y prioridades</Text>
                </li>
                <li key={2} className="flex items-center mb-1">
                  <IconComponent className="mr-2">
                    <TaskAlt className="text-base" />
                  </IconComponent>
                  <Text>Accede a chats para concretar transacciones</Text>
                </li>
                <li key={3} className="flex items-center mb-1">
                  <IconComponent className="mr-2">
                    <TaskAlt className="text-base" />
                  </IconComponent>
                  <Text>Conéctate con transportistas y asesores</Text>
                </li>
              </ul>

              <LinkComponent href={RUTAS.REGISTRAR_NEGOCIO} type="button">
                Registrar un negocio
                <KeyboardArrowRight />
              </LinkComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
