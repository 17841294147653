import { Upload } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden
} from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { useState } from 'react';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import residuosServices from 'services/residuos';
import { Alerta, AlertaError, AlertaExito } from 'utils/Alertas';

export const DialogoCargarCertificado = ({
  isOpen,
  setIsOpen,
  residuo,
  recargarResiduos
}) => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = event => {
    const file = event.target.files[0];
    setFileName('');
    setFile(null);

    if (file) {
      const isValidType =
        file.type === 'application/pdf' || file.type === 'image/jpeg';
      const isValidSize = file.size <= 4 * 1024 * 1024; // 4 MB

      if (!isValidType) {
        AlertaError('El archivo debe ser de tipo PDF o JPEG.');
        return;
      }

      if (!isValidSize) {
        AlertaError('El archivo no puede pesar más de 4 MB.');
        return;
      }

      const truncatedName =
        file.name.length > 20 ? `${file.name.slice(0, 19)}...` : file.name;
      setFileName(truncatedName);
      setFile(file);
    }
  };

  const handleUploadFile = async event => {
    event.preventDefault();

    if (!file) {
      AlertaError('Debe seleccionar un archivo a subir');
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('codigoResiduoDeclarado', residuo.codigo);
      const result =
        await residuosServices.subirCertificadoResiduoDeclarado_listaresiduos(
          formData
        );

      if (result.data?.error) {
        Alerta(result.data.error.codigo);
        return; // Asegura que se salga de la función después de manejar el error
      }

      if (result.status === 200) {
        AlertaExito(
          'El certificado de recepción ha sido subido correctamente.'
        );
        setIsOpen(false);
        recargarResiduos();
      }
    } catch (error) {
      console.error('error: ', error);
      Alerta('undefined');
    } finally {
      setFileName('');
      setFile(null);
      setIsLoading(false); // Esto asegura que siempre se actualice el estado
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={isLoading ? () => {} : () => setIsOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'md'}
      key={residuo && residuo.codigo}
    >
      <DialogTitle
        id="alert-dialog-title"
        className="border-b border-neutral-90"
      >
        <Heading type="h3" className="mb-0">
          Cargar Certificado
        </Heading>
      </DialogTitle>
      <DialogContent className="p-5">
        <DialogContentText id="alert-dialog-description" className="pt-4">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <Heading type="h4">Adjuntar archivo</Heading>
            </div>
            <div className="col-span-12">
              <ButtonComponent
                className="fullWidth justify-between h-auto"
                component="label"
                variant="contained"
                endIcon={<PostAddOutlinedIcon className="ml-2" />}
                disabled={isLoading}
              >
                {fileName ? (
                  <span>
                    Archivo seleccionado:{' '}
                    <span className="block truncate">{fileName}</span>
                  </span>
                ) : (
                  'Seleccionar archivo'
                )}
                <input type="file" hidden onChange={handleFileChange} />
              </ButtonComponent>
            </div>
            <div className="col-span-12">
              <div className="flex flex-col justify-between">
                <Text className="text-black font-semibold mb-2">
                  Solo se aceptan los siguientes tipos de archivos
                </Text>
                <div className="md:min-w-[480px] sm:flex">
                  <div className="sm:w-1/2 flex flex-col justify-between">
                    <div className="flex items-center space-x-2">
                      <TaskAltIcon className="text-uv-secondary-10" />
                      <Text>Formatos JPG y PDF</Text>
                    </div>
                  </div>
                  <div className="sm:w-1/2 flex flex-col justify-between">
                    <div className="flex items-center space-x-2">
                      <TaskAltIcon className="text-uv-secondary-10" />
                      <Text>Tamaño no superior a 4 MB</Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="border-t border-neutral-90 p-4 justify-between">
        <ButtonComponent
          type="secondary"
          disabled={isLoading}
          onClose={isLoading ? () => {} : () => setIsOpen(false)}
          className="justify-center"
        >
          <Hidden smDown>Cancelar</Hidden>
          <Hidden smUp>
            <Close />
          </Hidden>
        </ButtonComponent>
        <ButtonComponent
          type="primary"
          onClick={handleUploadFile}
          endIcon={<Upload />}
          autoFocus
          className="w-full xs:w-auto justify-center h-auto"
          disabled={!file || isLoading}
          isLoading={isLoading}
        >
          {isLoading ? 'Cargando' : 'Cargar'}
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};
