export const useDescargaArchivos = ({ contenidoDocumento, nombreArchivo }) => {
  const handleDownload = file => {
    const elementoDescarga = document.createElement('a');
    const archivo = new Blob([contenidoDocumento], { type: 'text/plain' });
    elementoDescarga.href = URL.createObjectURL(archivo);
    elementoDescarga.download = nombreArchivo;
    elementoDescarga.click();
  };
  return {
    handleDownload
  };
};
