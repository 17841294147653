import { Search } from "@mui/icons-material";
import { Divider, Skeleton } from "@mui/material";
import AlertComponent from "components/design-system/Alert/Alert";
import ButtonComponent from "components/design-system/Button/Button";
import Heading from "components/design-system/Heading/Heading";
import Text from "components/design-system/Text/Text";

export const SkeletonCargarMaterial = () => {

  return(
    <div>
      <div className="mb-4">
        <Skeleton variant="rounded"
          className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
        />
      </div>

      <div className="bg-white rounded shadow-md p-5 mb-5">
        <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-3 lg:col-span-3">
        <Heading type="h4">Nombre del material</Heading>
        <Text>Te recomendamos que el nombre sea acotado y representativo</Text>
      </div>
      <div className="col-span-12 md:col-span-9 lg:col-span-9">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-8">
            <Skeleton variant="rounded"
              className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>
        </div>
      </div>

      <div className="col-span-12 my-4">
        <Divider />
      </div>

      <div className="col-span-12 md:col-span-3 lg:col-span-3">
        <Heading type="h4">Categorizar el documento</Heading>
        <Text>Selecciona una o varias categorías para categorizar el documento que vas a cargar</Text>
      </div>
      <div className="col-span-12 md:col-span-9 lg:col-span-9">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-10">
            <AlertComponent 
              className="mb-4"
              type="info">
              Cuando se selecciona alguna categoría de <strong>Marco legal y tramitación</strong> se establece por defecto que el documento es de carácter <strong>obligatorio</strong>.
            </AlertComponent>

            <AlertComponent 
              className="mb-4"
              type="info">
              Debes seleccionar al menos <strong>una categoría</strong> entre <strong>Normativa técnica, Marco legal y Publicaciones</strong>
            </AlertComponent>
          </div>
          
          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <Skeleton variant="rounded"
              className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <Skeleton variant="rounded"
              className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <Skeleton variant="rounded"
              className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>
          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <Skeleton variant="rounded"
              className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>

          <div className="col-span-12 my-4">
            <Divider />
          </div>
        </div>
      </div>

      

      <div className="col-span-12 md:col-span-3 lg:col-span-3">
        <Text>Categorías opcionales adicionales para catalogar el documento a cargar</Text>
      </div>
      <div className="col-span-12 md:col-span-9 lg:col-span-9">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <Skeleton variant="rounded"
              className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <Skeleton variant="rounded"
              className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <Skeleton variant="rounded"
              className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
            <Skeleton variant="rounded"
              className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>
        </div>
      </div>

      <div className="col-span-12 my-4">
        <Divider />
      </div>

      <div className="col-span-12 md:col-span-3 lg:col-span-3">
        <Heading type="h4">Enlaces externos</Heading>
        <Text>Puedes añadir enlaces a documentación externa en lugar de cargar un documento</Text>
      </div>
      <div className="col-span-12 md:col-span-9 lg:col-span-9">
        <Skeleton variant="rounded"
          className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
        />
      </div>

      <div className="col-span-12 my-4">
        <Divider />
      </div>

      <div className="col-span-12 md:col-span-3 lg:col-span-3">
        <Heading type="h4">Cargar documento</Heading>
        <Text>Puedes cargar un documento o imágen</Text>
      </div>
      <div className="col-span-12 md:col-span-9 lg:col-span-9">
        
        <div className="grid grid-cols-12">
          <div className={`col-span-12`}>
            <Skeleton variant="rounded"
              className="w-full h-20 rounded relative z-0 min-w-full flex justify-center items-center"
            />
          </div>
        </div>
      </div>
        </div>

        <div className="grid grid-cols-12">
          <div className="col-span-12 mt-10 mb-4">
            <Divider />
          </div>

          <div className="col-span-12">
            <div className="flex justify-between w-full">
              <ButtonComponent
                type="secondary"
                onClick={() => {}}
                disabled={true}>
                Cancelar
              </ButtonComponent>

              <ButtonComponent
                form="busquedaMaterialDifusion"
                buttonType="submit"
                type="primary"
                className="ml-4"
                disabled={true}
              >
               Cargando...
              </ButtonComponent>
            </div>
          </div>
        </div>
    </div>
  </div>
  )
};