import { PERMISOS } from 'const/Permisos';
import * as yup from 'yup';

const SchemaResiduo = isTienePermisos => {
  const schema = {
    tipoResiduo: yup
      .object()
      .required('Este campo es obligatorio')
      .typeError('Este campo es obligatorio'),
    subTipoResiduo: yup
      .object()
      .required('Este campo es obligatorio')
      .typeError('Este campo es obligatorio'),

    fechaTransaccion: yup
      .date()
      .min(new Date(1900, 0, 1), 'La fecha no puede ser menor a 1900-01-01')
      .max(new Date(), 'La fecha no puede ser mayor a la fecha actual')
      .required('Este campo es obligatorio')
      .typeError('No es una fecha válida'),
    cantidad: yup
      .number()
      .required('Este campo es obligatorio')
      .typeError('Debe ser un número'),
    unidadMedida: yup
      .object()
      .required('Este campo es obligatorio')
      .typeError('Este campo es obligatorio')
  };

  if (isTienePermisos(PERMISOS.sucursalOrigenResiduoDeclarado)) {
    schema.sucursal = yup
      .object()
      .typeError('Este campo es obligatorio')
      .required('Este campo es obligatorio');
  }

  return yup.object().shape(schema);
};

export { SchemaResiduo };
