import { Divider } from '@mui/material';
import { RoleComponent } from 'components/design-system/Role/Role';
import { Galeria } from '../Galeria/Galeria';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import moment from 'moment';
import Circle from '@mui/icons-material/Circle';

/**
 * @name DescripcionOferente
 * Despliega la descripción de la oferta de residuo para un publicante con rol oferente.
 */
export const DescripcionOferente = ({ rol, residuo }) => {
  const fechaDisponibilidadFormateada = moment(
    residuo.fechaDisponibilidad
  ).format('DD-MM-YYYY');

  return (
    <div>
      <Divider className="my-3" />
      <RoleComponent rol={rol.nombreRol} tipo="etiqueta" />
      <div className="pl-3">
        <div>
          <Heading type="h5">{residuo.nombreLER}</Heading>
          <div className="grid grid-cols-12 gap-2 items-baseline">
            <div className="col-span-4">
              <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
                RESIDUO
              </span>
            </div>
            <div className="col-span-8">
              <Text>{residuo?.tipoResiduo || ''}</Text>
            </div>
            <div className="col-span-4">
              <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
                RECURSOS
              </span>
            </div>
            <div className="col-span-8">
              {residuo.recursos.map((recurso, index) => (
                <Text key={index} className="mb-2 gap-2 flex">
                  <Circle className="text-uv-secondary-0 w-1.5 h-1.5 mt-1.5 top-0.5 relative" />
                  {recurso.nombreRecurso}
                </Text>
              ))}
            </div>
            {residuo.formatoEntrega && (
              <>
                <div className="col-span-4">
                  <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
                    FORMATO
                  </span>
                </div>
                <div className="col-span-8">
                  <Text>{residuo.formatoEntrega}</Text>
                </div>
              </>
            )}
            <div className="col-span-4">
              <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
                DISPONIBILIDAD
              </span>
            </div>
            <div className="col-span-8">
              <Text>{fechaDisponibilidadFormateada || ''}</Text>
            </div>
            {residuo.frecuencia && (
              <>
                <div className="col-span-4">
                  <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
                    FRECUENCIA
                  </span>
                </div>
                <div className="col-span-8">
                  <Text>{residuo.frecuencia}</Text>
                </div>
              </>
            )}
            {residuo.descripcionAdicional && (
              <>
                <div className="col-span-4">
                  <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5">
                    DESCRIPCION
                  </span>
                </div>
                <div className="col-span-8">
                  <Text>{residuo.descripcionAdicional}</Text>
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <Divider className="my-3" />
          <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal">
            IMÁGENES
          </span>
          <Galeria codigoResiduo={residuo.codigoResiduo} />
        </div>
      </div>
    </div>
  );
};
