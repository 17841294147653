import * as yup from 'yup';
const schemaBuscarMaterial = yup
  .object()
  .shape({
    marcoLegal: yup.array().default([]),

    normativaTecnica: yup.array().default([]),

    publicaciones: yup.array().default([]),

    tipoResiduo: yup.object().default({}).nullable(),

    tipoTratamientoResiduo: yup.object().default({}).nullable(),

    comuna:  yup.array().default([]),

    region:  yup.array().default([]),

    nombreArchivo: yup.string().default('')
  })
  .test(
    'nombre-o-campos',
    'Debe proporcionar al menos un código o un valor en otro campo',
    function (value) {
      const {
        nombreArchivo,
        marcoLegal,
        normativaTecnica,
        publicaciones,
        tipoResiduo,
        tipoTratamientoResiduo,
        region
      } = value;

      if (
        nombreArchivo.length > 0 ||
        marcoLegal.length > 0 ||
        normativaTecnica.length > 0 ||
        publicaciones.length > 0 ||
        Object.hasOwn(tipoResiduo, 'codigoTipoResiduo') ||
        Object.hasOwn(tipoTratamientoResiduo, 'codigoTipoTratamiento') ||
        region.length > 0
      ) {
        return true;
      } else {
        return this.createError({
          message: 'Debes seleccionar al menos un campo',
          path: 'ERROR_VALIDACION'
        });
      }
    }
  );

export { schemaBuscarMaterial };
