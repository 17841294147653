import { FilterList } from '@mui/icons-material';
import { AutoCompleteAsyncController } from 'components/design-system/AutoCompleteAsync/AutoCompleteAsyncController';
import ButtonComponent from 'components/design-system/Button/Button';
import CheckboxComponent from 'components/design-system/Checkbox/Checkbox';
import LabelComponent from 'components/design-system/Label/Label';
import { DICTONARY } from 'const/Dictonary';
import { Form, Formik } from 'formik';
import { obtenerTodasEmpresasAT } from 'views/CargaMasiva/hooks/useServices';

export const FiltroForm = ({ isLoading, setFiltros }) => {
  return (
    <Formik
      initialValues={{ empresas: undefined }}
      validateOnChange
      validateOnBlur
      onSubmit={(values, { setSubmitting }) => {
        try {
          setTimeout(async () => {
            let filtros = {
              codigoEmpresaAT: null,
              tipoPeticion: DICTONARY.LINEA_BASE_ESTADOS.DEFAULT
            };
            if (values.empresas && values.empresas.length > 0) {
              filtros.codigoEmpresa = values.empresas.map(
                empresa => empresa.value
              );
            }
            setFiltros(filtros);
            setSubmitting(false);
          }, 1500);
        } catch (error) {
          console.log('error >', error);
        }
      }}
    >
      {({ setFieldValue, setFieldTouched, isSubmitting }) => (
        <Form id="editarUsuario">
          <div className="grid grid-cols-12 gap-4 items-end">
            <div className="col-span-12 xs:col-span-10  sm:col-span-7 md:col-span-8 lg:col-span-6">
              <AutoCompleteAsyncController
                name="empresas"
                accesor="label"
                required
                fullWidth
                multiple
                limitTags={2}
                disabled={isSubmitting}
                label={<LabelComponent>Empresa</LabelComponent>}
                setFieldValue={data => setFieldValue('empresas', data)}
                setFieldTouched={() => setFieldTouched('empresas')}
                isSubmitting={isSubmitting}
                fetchCall={controllerValues =>
                  obtenerTodasEmpresasAT({
                    setLoadingEmpresas: controllerValues.setIsLoading,
                    setEmpresas: controllerValues.setData,
                    paginationEmpresas: controllerValues.paginationEmpresas,
                    razonSocial: controllerValues.inputValue,
                    setRowCountEmpresas: controllerValues.setRowCountEmpresas
                  })
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <CheckboxComponent
                      style={{ marginRight: 8 }}
                      checked={selected}
                      size="small"
                    />
                    {option ? option.label : ''}
                  </li>
                )}
              />
            </div>
            <div className="col-span-12 xs:col-span-2 sm:col-span-5 md:col-span-4 lg:col-span-3 text-start">
              <ButtonComponent
                form="editarUsuario"
                buttonType="submit"
                type="primary"
                isLoading={isSubmitting}
                disabled={isSubmitting || isLoading}
                className="h-10 w-full xs:w-auto justify-center"
              >
                <span className="block xs:hidden sm:block">
                  Filtrar información
                </span>{' '}
                <FilterList className="ml-2" />
              </ButtonComponent>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
