// Expresión regular para permitir solo números y letras
const regex = /^[kK0-9-]*$/;

export const handleKeyPress = event => {
  const { key } = event;
  // Verificar si la tecla presionada no es un número ni una letra
  if (!regex.test(key) && key !== 'Backspace' && key !== 'Delete') {
    event.preventDefault();
  }
};

export  const handlePaste = (event) => {
  const pastedText = event.clipboardData.getData('text');

  // evitar si el texto contiene uno o mas espacios en blanco
  if (pastedText.includes(' ')) {
    event.preventDefault();
  }

  if (pastedText.includes('.') || pastedText.includes(',')) {
    // Evitar el pegado si contiene puntos ni comas
    event.preventDefault();
  }
};
