import * as yup from 'yup';

const schemaMatchManual = yup.object().shape(
  {
    codigosTipoResiduo: yup.object().when('codigosRecurso', {
      is: val => !val || val === '',
      then: codigosTipoResiduo =>
        codigosTipoResiduo.required(
          'Debes seleccionar al menos un residuo o un tipo de recurso'
        )
    }),

    codigosRecurso: yup.object().when('codigosTipoResiduo', {
      is: val => !val || val === '',
      then: codigosRecurso =>
        codigosRecurso.required(
          'Debes seleccionar al menos un residuo o un tipo de recurso'
        )
    })
  },
  ['codigosTipoResiduo', 'codigosRecurso']
);

export { schemaMatchManual };
