import { useContext, useEffect, useState } from 'react';
import usuariosService from 'services/usuarios';
import { RUTAS } from 'const/Rutas';
import { AuthContext } from 'context';
import { AlertaExito } from 'utils/Alertas';

export const useOlvidoContrasena = () => {
  const { logeduser, token, refrescarSesion } = useContext(AuthContext);
  const [openRedirect, setOpenRedirect] = useState(true);

  const handleClickCloseRedirect = () => {
    setOpenRedirect(false);
  };

  useEffect(() => {
    if (logeduser && !logeduser.passwordTemporal) {
      window.location.pathname = RUTAS.HOME_USER;
    }
  }, [logeduser]);

  const handleOnSubmit = async (values, resetForm) => {
    const response = await usuariosService.actualizarPassword(
      logeduser.codigoUsuario,
      {
        passwordActual: values.passwordActual,
        passwordNueva: values.password
      }
    );

    if (response.status === 200) {
      AlertaExito('La contraseña se ha actualizado correctamente');
      window.location.pathname = RUTAS.HOME_USER;
      await refrescarSesion(token);
      resetForm();
    }
  };

  return {
    openRedirect,
    handleClickCloseRedirect,
    handleOnSubmit
  };
};
