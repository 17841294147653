import { Divider, Skeleton } from '@mui/material';
import { useContext } from 'react';
import { MatchContext } from 'views/Marketplace/Residuos/context/MatchContext';
import { Resultados } from '../Resultados/Resultados';
import { ResultadosSinNegocio } from '../Sinnegocio/ResultadosSinNegocio';

export const TabResiduos = () => {
  const { isCargandoMatchResiduos, tieneNegocio } = useContext(MatchContext);

  if (isCargandoMatchResiduos) {
    return (
      <div className="m-2">
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
      </div>
    );
  }

  if (tieneNegocio) {
    return <Resultados />;
  } else {
    return <ResultadosSinNegocio />;
  }
};
