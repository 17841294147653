import { ICON_RECICLADORBASE_TIPO_A } from 'icons/Roles/IconRecicladorBaseTipoA';
import { ICON_RECICLADORBASE_TIPO_B } from 'icons/Roles/IconRecicladorBaseTipoB';
import { ICON_RECICLADORBASE_TIPO_C } from 'icons/Roles/IconRecicladorBaseTipoC';

export const obtenerGradoAutorizacionesSanitarias = value => {
  const { autorizacionAcopio, autorizacionTransporte, registroNacionalRDB } =
    value;

  if (
    autorizacionAcopio === null ||
    autorizacionTransporte === null ||
    registroNacionalRDB === null
  )
    return null;

  const aux = [];
  if (autorizacionAcopio) aux.push(true);
  if (autorizacionTransporte) aux.push(true);
  if (registroNacionalRDB) aux.push(true);

  if (aux.length === 3) {
    return 'TIPO-A';
  } else if (aux.length > 1) {
    return 'TIPO-B';
  } else {
    return 'TIPO-C';
  }
};

export const obtenerAutorizacionesDeObjeto = objeto => {
  if (!objeto) return null;

  return {
    autorizacionAcopio: objeto.autorizacionAcopio,
    autorizacionTransporte: objeto.autorizacionTransporte,
    registroNacionalRDB: objeto.registroNacionalRDB
  };
};

export const RecicladorICON = ({ tipo, className }) => {
  if ('TIPO-A' === tipo) {
    return <ICON_RECICLADORBASE_TIPO_A className={className} />;
  } else if ('TIPO-B' === tipo) {
    return <ICON_RECICLADORBASE_TIPO_B className={className} />;
  } else if ('TIPO-C' === tipo) {
    return <ICON_RECICLADORBASE_TIPO_C className={className} />;
  } else {
    return <ICON_RECICLADORBASE_TIPO_C className={className} />;
  }
};
