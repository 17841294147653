import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { useEffect, useState } from 'react';

export const useTour = () => {
  const [mostrarTour, setMostrarTour] = useState(false);
  const TOUR_STEPS = {
    INICIO: 'INICIO',
    TABLA_EMPRESAS: 'TABLA_EMPRESAS',
    MAPA_EMPRESAS: 'MAPA_EMPRESAS',
    TABLA_RESIDUOS: 'TABLA_RESIDUOS',
    GRAFICO_DISTRIBUCION: 'GRAFICO_DISTRIBUCION',
    FILTROS: 'FILTROS'
  };
  const TOUR_DATA = [
    {
      selector: `#${TOUR_STEPS.INICIO}`,
      title: <Heading type="h3">Análisis línea base</Heading>,
      description: (
        <div>
          <Text>
            Esta página te permite analizar las empresas y sus residuos
            declarados en RETC además de poder visualizar su ubicación
            geográfica según el sector económico.
          </Text>
        </div>
      ),
      disableClose: true
    },
    {
      selector: `#${TOUR_STEPS.TABLA_EMPRESAS}`,
      title: <Heading type="h3">Empresas</Heading>,
      description: (
        <div>
          <Text>En la tabla aparece la información de empresas indicando:</Text>
          <ul className="my-4">
            <li className="relative pl-4">
              <div className="w-2 h-2 bg-uv-primary-0 rounded-full absolute left-0 top-1.5"></div>
              <Text>Nombre de la empresa</Text>
            </li>
            <li className="relative pl-4">
              <div className="w-2 h-2 bg-uv-primary-0 rounded-full absolute left-0 top-1.5"></div>
              <Text>Actividad económica</Text>
            </li>
            <li className="relative pl-4">
              <div className="w-2 h-2 bg-uv-primary-0 rounded-full absolute left-0 top-1.5"></div>
              <Text>Tamaño de la empresa</Text>
            </li>
            <li className="relative pl-4">
              <div className="w-2 h-2 bg-uv-primary-0 rounded-full absolute left-0 top-1.5"></div>
              <Text>Comuna</Text>
            </li>
          </ul>
        </div>
      ),
      disableClose: true
    },
    {
      selector: `#${TOUR_STEPS.MAPA_EMPRESAS}`,
      title: <Heading type="h3">Ubicación de empresas</Heading>,
      description: (
        <div>
          <Text>
            Este mapa gráfica la ubicación de las empresas desplegadas en la
            tabla representadas por color según el sector economico al cual
            pertenecen
          </Text>
        </div>
      ),
      disableClose: true
    },
    {
      selector: `#${TOUR_STEPS.TABLA_RESIDUOS}`,
      title: <Heading type="h3">Declaración de residuos</Heading>,
      description: (
        <div>
          <Text>
            Luego de que hagas click sobre una fila de la tabla empresas se
            buscará la información de residuos en RETC y aparecerá la
            información encontrada en esta tabla.
          </Text>
        </div>
      ),
      disableClose: true
    },
    {
      selector: `#${TOUR_STEPS.GRAFICO_DISTRIBUCION}`,
      title: <Heading type="h3">Gráfico de distribución</Heading>,
      description: (
        <div>
          <Text>
            Aquí se representa la cantidad de empresas y residuos segmentados
            por provincia.
          </Text>
        </div>
      ),
      disableClose: true
    },
    {
      selector: `#${TOUR_STEPS.FILTROS}`,
      title: <Heading type="h3">Filtros</Heading>,
      description: (
        <div>
          <Text>
            Recuerda que puedes utilizar los filtros por sector económico y por
            cómuna para buscar o visualizar la información de manera mas
            específica
          </Text>
        </div>
      ),
      disableClose: false
    }
  ];

  const handleOcultarGuia = ev => {
    if (ev.stepIndex === ev.allSteps.length - 1) {
      setMostrarTour(false);
      ev.goToStep(0);
      localStorage.setItem('DESPLEGAR_UV_GUIA_AT_BASE', false);
    }
  };

  useEffect(() => {
    const data = localStorage.getItem('DESPLEGAR_UV_GUIA_AT_BASE');

    if (!data) {
      localStorage.setItem('DESPLEGAR_UV_GUIA_AT_BASE', true);
      setMostrarTour(true);
    }
  }, []);

  return {
    TOUR_STEPS,
    TOUR_DATA,
    setMostrarTour,
    mostrarTour,
    handleOcultarGuia
  };
};
