import API from './api';

const residuosDeclaradosService = {
  obtenerEmpresasGeneradoras: function (params, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: '/residuos-declarados/empresas-generadoras',
      params: params,
      interceptor: conInterceptor
    });
  },

  obtenerEmpresaGeneradora: function (codigoEmpresa, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: `/residuos-declarados/empresas-generadoras/${codigoEmpresa}`,
      interceptor: conInterceptor
    });
  },

  obtenerEmpresasReceptoras: function (params, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: '/residuos-declarados/empresas-receptoras',
      params: params,
      interceptor: conInterceptor
    });
  },

  obtenerEmpresaReceptor: function (codigoEmpresa, conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: `/residuos-declarados/empresas-receptoras/${codigoEmpresa}`,
      interceptor: conInterceptor
    });
  },

  obtenerCodigosResiduosDeclarados: function (conInterceptor = true) {
    return API.axios({
      method: 'GET',
      url: '/residuos-declarados/codigos',
      interceptor: conInterceptor
    });
  }
};

export default residuosDeclaradosService;
