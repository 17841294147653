import { useEffect } from 'react';
import { UseTocarCamposFormik } from 'views/ResiduoDeclarado/hooks/useUtilsFormik';

export const NavegacionPasos = props => {
  const {
    pasos,
    step,
    tipoForm: { ingresar, editar, duplicar }
  } = props;
  const Componente = pasos[step].Componente;

  useEffect(() => {
    // console.log(touched);
    // Tocar todos los campos para que se muestren los errores
    if (editar || duplicar) {
      UseTocarCamposFormik(props.values, props.setFieldTouched);
    }
  }, [
    props.cargaNombreLER,
    props.cargaTipoResiduos,
    duplicar,
    editar,
    props.setFieldTouched,
    props.values
  ]);

  return <Componente {...props} />;
};
