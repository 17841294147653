// Desgin System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import InputNumber from 'components/design-system/input/InputNumber';
import LabelComponent from 'components/design-system/Label/Label';
import { TooltipComponent } from 'components/design-system/Tooltip/Tooltip';

// Material
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Help } from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';

// Librarys
import { es } from 'date-fns/locale';

// Utils

// Diccionary
import { DICTONARY } from 'const/Dictonary';
import { DatePickerComponent } from 'components/design-system/DatePicker/DatePicker';

const RecursoAdquirido = ({
  // Formik
  errors,
  //  handleBlur,
  //  handleChange,
  //  handleReset,
  //  handleSubmit,
  isSubmitting,
  //  setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  //  validateForm,
  values,
  // Dependencias
  isLoadingLevantamiento,
  isLoadingSucursales,
  isLoadingTipoRecursosAdquiridos,
  isLoadingUnidadMedida,
  levantamiento,
  sucursales,
  tipoRecursosAdquiridos,
  unidadMedida
}) => {
  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Heading type="h4" className="mb-0">
            Recurso adquirido
          </Heading>
        </div>
        <div className="col-span-12 xs:col-span-12">
          <div className="grid grid-cols-12 gap-4">
            {/* Sucursal de recepción */}
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
              <AutocompleteComponent
                accesor="nombreSucursal"
                clearOnEscape={true}
                disabled={isSubmitting || isLoadingSucursales}
                fullWidth
                name={'sucursal'}
                options={sucursales || []}
                value={values.sucursal || ''}
                onChange={(event, newValue, reason) => {
                  let data = { name: 'sucursal', value: newValue };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  setFieldValue('sucursal', data.value);
                  setFieldTouched('tipoRecursoAdquirido', true);
                }}
                getOptionLabel={option => option.nombreSucursal || ''}
                noOptionsText={
                  isLoadingSucursales ? 'Cargando...' : 'Sin opciones'
                }
              >
                <InputComponent
                  autoComplete="off"
                  disabled={isSubmitting || isLoadingSucursales}
                  fullWidth
                  onBlur={() => {
                    setFieldTouched('sucursal');
                  }}
                  touched={touched.sucursal}
                  placeholder={
                    isLoadingSucursales
                      ? 'Cargando...'
                      : 'Selecciona una opción'
                  }
                  estado={
                    errors.sucursal
                      ? { tipo: 'error', mensaje: errors.sucursal }
                      : null
                  }
                  label={<LabelComponent>Sucursal de recepción</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>

            {/* Tipo de recurso */}
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
              <AutocompleteComponent
                accesor="nombreTipoRecursoAdquirido"
                clearOnEscape={true}
                disabled={isSubmitting || isLoadingTipoRecursosAdquiridos}
                fullWidth
                name={'tipoRecursoAdquirido'}
                options={tipoRecursosAdquiridos || []}
                value={values.tipoRecursoAdquirido || ''}
                onChange={(event, newValue, reason) => {
                  let data = { name: 'tipoRecursoAdquirido', value: newValue };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  setFieldValue('tipoRecursoAdquirido', data.value);
                  setFieldTouched('cantidad', true);
                }}
                getOptionLabel={option =>
                  option.nombreTipoRecursoAdquirido || ''
                }
                noOptionsText={
                  isLoadingTipoRecursosAdquiridos
                    ? 'Cargando...'
                    : 'Sin opciones'
                }
              >
                <InputComponent
                  autoComplete="off"
                  disabled={isSubmitting || isLoadingTipoRecursosAdquiridos}
                  fullWidth
                  onBlur={() => {
                    setFieldTouched('tipoRecursoAdquirido');
                  }}
                  touched={touched.tipoRecursoAdquirido}
                  placeholder={
                    isLoadingTipoRecursosAdquiridos
                      ? 'Cargando...'
                      : 'Selecciona una opción'
                  }
                  estado={
                    errors.tipoRecursoAdquirido
                      ? { tipo: 'error', mensaje: errors.tipoRecursoAdquirido }
                      : null
                  }
                  label={
                    <LabelComponent className="w-full">
                      Tipo de recurso
                      <TooltipComponent
                        className="float-right"
                        title={
                          'Insumo, material, materia prima u otro recurso adquirido por la empresa.'
                        }
                      >
                        <Help
                          className="text-uv-secondary-0"
                          fontSize="small"
                        />
                      </TooltipComponent>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>

            {/* Cantidad  */}
            <div className="col-span-12 sm:col-span-6 lg:col-span-2">
              <InputNumber
                autoComplete="off"
                disabled={isSubmitting}
                label={<LabelComponent>Cantidad</LabelComponent>}
                maxDecimals={DICTONARY.NUMERO_DECIMALES}
                name={'cantidad'}
                placeholder={!values.cantidad ? '' : ''}
                touched={touched.cantidad}
                type="text"
                value={values.cantidad_str || values.cantidad || ''}
                onChange={event => {
                  const { value } = event.target;
                  if (!value) {
                    setFieldValue('cantidad_str', '');
                    setFieldValue('cantidad', null);
                    return;
                  }
                  const sanitizedValue = value
                    .replaceAll('.', '')
                    .replaceAll(',', '.');
                  const floatValue = parseFloat(sanitizedValue);
                  if (!isNaN(floatValue)) {
                    setFieldValue('cantidad', floatValue);
                  }
                  setFieldTouched('unidadMedida', true);
                }}
                onFocus={event => {
                  const { value } = event.target;
                  setFieldTouched('cantidad');
                  if (
                    !event ||
                    !event.target ||
                    typeof event.target.value !== 'string'
                  ) {
                    return;
                  }
                  let sanitizedValue = value.split('.').join('') || '';
                  setFieldValue('cantidad_str', sanitizedValue);
                }}
                onBlur={() => {
                  setFieldTouched('cantidad');
                }}
                estado={
                  errors.cantidad
                    ? { tipo: 'error', mensaje: errors.cantidad }
                    : null
                }
              />
            </div>

            {/* Unidad de Medida  */}
            <div className="col-span-12 sm:col-span-6 lg:col-span-2">
              <AutocompleteComponent
                accesor="nombreUnidadMedida"
                clearOnEscape={true}
                disabled={isSubmitting || isLoadingUnidadMedida}
                fullWidth
                name={'unidadMedida'}
                options={unidadMedida || []}
                value={values.unidadMedida || ''}
                onChange={(event, newValue, reason) => {
                  let data = { name: 'unidadMedida', value: newValue };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  setFieldValue('unidadMedida', data.value);
                  setFieldTouched('fechaAdquisicion', true);
                }}
                getOptionLabel={option => option.nombreUnidadMedida || ''}
                noOptionsText={
                  isLoadingUnidadMedida ? 'Cargando...' : 'Sin opciones'
                }
              >
                <InputComponent
                  autoComplete="off"
                  disabled={isLoadingUnidadMedida}
                  fullWidth
                  onBlur={() => {
                    setFieldTouched('unidadMedida');
                  }}
                  touched={touched.unidadMedida}
                  placeholder={
                    isLoadingUnidadMedida
                      ? 'Cargando...'
                      : 'Selecciona una opción'
                  }
                  estado={
                    errors.unidadMedida
                      ? { tipo: 'error', mensaje: errors.unidadMedida }
                      : null
                  }
                  label={<LabelComponent>Unidad</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>

            {/* Levantamiento de datos */}
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
              <AutocompleteComponent
                accesor="nombreLevantamientoDatos"
                clearOnEscape={true}
                disabled={isSubmitting || isLoadingLevantamiento}
                fullWidth
                name={'levantamientoDatos'}
                options={levantamiento || []}
                value={values.levantamientoDatos || ''}
                onChange={(event, newValue, reason) => {
                  let data = { name: 'levantamientoDatos', value: newValue };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  setFieldValue('levantamientoDatos', data.value);
                }}
                getOptionLabel={option => option.nombreLevantamientoDatos || ''}
                noOptionsText={
                  isLoadingLevantamiento ? 'Cargando...' : 'Sin opciones'
                }
              >
                <InputComponent
                  autoComplete="off"
                  disabled={isLoadingLevantamiento}
                  fullWidth
                  onBlur={() => {
                    setFieldTouched('levantamientoDatos');
                  }}
                  touched={touched.levantamientoDatos}
                  placeholder={
                    isLoadingLevantamiento
                      ? 'Cargando...'
                      : 'Selecciona una opción'
                  }
                  estado={
                    errors.levantamientoDatos
                      ? { tipo: 'error', mensaje: errors.levantamientoDatos }
                      : null
                  }
                  label={
                    <LabelComponent>
                      Levantamiento de datos{' '}
                      <span className="text-neutral-60">(opcional)</span>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>

            {/* Fecha de adquisición*/}
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={es}
              >
                <DesktopDatePicker
                  disabled={isSubmitting}
                  name="fechaAdquisicion"
                  value={values.fechaAdquisicion || null}
                  onChange={date => {
                    setFieldValue('fechaAdquisicion', date);
                    setFieldTouched('rut', true);
                  }}
                  label={
                    <LabelComponent>Fecha de la adquisición</LabelComponent>
                  }
                  inputFormat="dd/MM/yyyy"
                  slots={{ textField: DatePickerComponent }}
                />
              </LocalizationProvider>
            </div>

            {/* Número de guía de despacho */}
            <div className="col-span-12 sm:col-span-6 lg:col-span-4">
              <InputNumber
                autoComplete="off"
                disabled={isSubmitting}
                maxDecimals={DICTONARY.NUMERO_DECIMALES}
                name={'numeroGuiaDespacho'}
                placeholder={!values.numeroGuiaDespacho ? '' : ''}
                touched={touched.numeroGuiaDespacho}
                type="text"
                value={
                  values.numeroGuiaDespacho_str ||
                  values.numeroGuiaDespacho ||
                  ''
                }
                onChange={event => {
                  const { value } = event.target;
                  const sanitizedValue = value
                    .replaceAll('.', '')
                    .replaceAll(',', '.');
                  const floatValue = parseFloat(sanitizedValue);
                  if (!isNaN(floatValue)) {
                    setFieldValue('numeroGuiaDespacho', floatValue);
                  }
                }}
                onFocus={event => {
                  const { value } = event.target;
                  setFieldTouched('numeroGuiaDespacho');
                  if (
                    !event ||
                    !event.target ||
                    typeof event.target.value !== 'string'
                  ) {
                    return;
                  }
                  let sanitizedValue = value.split('.').join('') || '';
                  setFieldValue('numeroGuiaDespacho_str', sanitizedValue);
                }}
                onBlur={() => {
                  setFieldTouched('numeroGuiaDespacho');
                }}
                label={
                  <LabelComponent>
                    Nº guía de despacho{' '}
                    <span className="text-neutral-60">(opcional)</span>
                  </LabelComponent>
                }
                estado={
                  errors.numeroGuiaDespacho
                    ? { tipo: 'error', mensaje: errors.numeroGuiaDespacho }
                    : null
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecursoAdquirido;
