import Divider from '@mui/material/Divider';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import AlertComponent from 'components/design-system/Alert/Alert';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { Formik, Form } from 'formik';
import { schemaGeneracionIndicadores } from './schema/schemaEditarIndicador';
import { Campos } from './EditarIndicadoresCampos';
import { ChevronLeft } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { useEditarIndicador } from './hooks/useEditarIndicador';
import { DICTONARY } from 'const/Dictonary';
import { SkeletonEditarIndicador } from './skeleton/SkeletonEditarIndicador';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { useContext } from 'react';
import { AuthContext } from 'context';
import { RUTAS } from 'const/Rutas';

const EditarIndicador = () => {
  const { isTienePermisos } = useContext(AuthContext);
  const { codigoSucursal, codigoEmpresa } = useParams();
  const { isLoading, sucursal, formularioInicial, onSubmit } =
    useEditarIndicador(codigoSucursal || null, codigoEmpresa || null);

  window.scrollTo(0, 0);

  return (
    <SidebarLayout>
      <Heading type="h2" className="mb-4 text-lg md:text-2xl">
        <div className="flex items-center">
          <Link to={`${RUTAS.NEGOCIO}/${codigoEmpresa}/datos-indicadores`}>
            <IconButtonComponent type="secondary" className="mr-2">
              <ChevronLeft />
            </IconButtonComponent>
          </Link>
          <span>Datos de generación de indicadores</span>
        </div>
      </Heading>

      {isLoading ? (
        <SkeletonEditarIndicador />
      ) : (
        <Formik
          initialValues={formularioInicial(sucursal)}
          validateOnChange={true}
          validationSchema={schemaGeneracionIndicadores(isTienePermisos)}
          validateOnBlur={true}
          onSubmit={async (values, { resetForm }) => {
            await onSubmit(sucursal.codigoSucursal, values);
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            isSubmitting,
            setFieldValue,
            setFieldTouched
          }) => (
            <Form id="editarIndicadores" onChange={event => {}}>
              <div className="bg-white rounded p-5 shadow-sm mb-5">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12">
                    <Heading type="h4" className="mb-0">
                      {sucursal?.nombreSucursal || ''}
                    </Heading>
                    <Text>
                      Sucursal a la cúal se agregarán los datos de generación de
                      indicadores
                    </Text>
                  </div>

                  <div className="col-span-12 lg:col-span-10">
                    <AlertComponent type="warning">
                      <Text>
                        Estos datos son utilizados para la generación de
                        indicadores ambientales y sociales útiles para su
                        negocio y la región. En cada campo, ingrese los datos
                        correspondientes al año anterior o a los últimos 12
                        meses.
                      </Text>
                    </AlertComponent>
                  </div>

                  <div className="col-span-12 my-2">
                    <Divider />
                  </div>

                  <Campos
                    errors={errors}
                    handleChange={handleChange}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                  />

                  <div className="col-span-12 my-2">
                    <Divider />
                  </div>

                  <div className="col-span-12 justify-between">
                    <div className="flex justify-between">
                      <Link
                        to={`${RUTAS.NEGOCIO}/${codigoEmpresa}/datos-indicadores`}
                      >
                        <ButtonComponent type="secondary" className="mr-2">
                          <ChevronLeft /> Volver
                        </ButtonComponent>
                      </Link>

                      <ButtonComponent
                        form="editarIndicadores"
                        buttonType="submit"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                      >
                        {isSubmitting
                          ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD
                              .EDITAR_IS_LOADING
                          : DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR}
                      </ButtonComponent>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </SidebarLayout>
  );
};

export default EditarIndicador;
