export const initialValuesCalculoIndicadores = {
  cantidad: null,
  tipoResiduo: null,
  subTipoResiduo: null,

  regionOferente: '',
  comunaOferente: '',
  nombreNumeroViaOferente: '',

  latitudDireccionOferente: '',
  longitudDireccionOferente: '',

  regionDemandante: '',
  comunaDemandante: '',
  nombreViaDemandante: '',

  latitudDireccionDemandante: '',
  longitudDireccionDemandante: '',

  nTrabajadoresHombresTransporte: null,
  nTrabajadoresMujeresTransporte: null,
  cantidadToneladasTransportadas: null,
  totalRemuneracionesPagadas: null,
  tipoTransporte: '',

  nTrabajadoresHombresTratamiento: null,
  nTrabajadoresMujeresTratamiento: null,
  cantidadToneladasTratamiento: null,
  totalRemuneracionesPagadasTratamiento: null,
  tipoTratamiento: ''
};
