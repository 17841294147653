import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Heading from 'components/design-system/Heading/Heading';
import { useResponsive } from 'hooks/useResponsive';

export const ResponsiveFiltro = ({ children }) => {
  const { isLGDown } = useResponsive();

  if (!isLGDown) {
    return children;
  }

  return (
    <Accordion
      disableGutters={true}
      className="shadow-none rounded bg-uv-primary-80"
    >
      <AccordionSummary
        expandIcon={<ExpandMore className="text-uv-primary-0" />}
        className="px-4 w-full"
      >
        <Heading type="h5" className="text-uv-primary-0 mb-0">
          Filtrar resultados
        </Heading>
      </AccordionSummary>
      <AccordionDetails className="p-4">{children}</AccordionDetails>
    </Accordion>
  );
};
