import React, { useContext } from 'react';
import { CuadroNegocioResumen } from './componentes/CuadroNegocioResumen';
import { CuadroRegistrarNegocio } from './componentes/CuadroRegistrarNegocio';
import { CuadroCompletarCuenta } from './componentes/CuadroCompletarCuenta';
import { CuadroAccesosRapidos } from './componentes/AccesosRapidos/CuadroAccesosRapidos';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';

export const HomeUsuario = ({ data, tieneNegocioRegistrado, isLoading }) => {
  const { logeduser, isTienePermisos } = useContext(AuthContext);

  return (
    <>
      <CuadroAccesosRapidos />

      {isTienePermisos(PERMISOS.dashboardDatosNegocio) && (
        <CuadroNegocioResumen
          usuario={logeduser}
          resumenCuenta={data}
          isLoading={isLoading}
        />
      )}

      {isTienePermisos(PERMISOS.registrarNegocio) && (
        <CuadroRegistrarNegocio
          porcentajeUsuarioPendiente={data.porcentajeUsuarioPendiente}
        />
      )}

      {
        //Para cuando tiene negocio registrado pero no ha editado sus datos.
        tieneNegocioRegistrado && data && !isLoading && (
          <CuadroCompletarCuenta resumenCuenta={data} />
        )
      }
    </>
  );
};
