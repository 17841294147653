import Close from '@mui/icons-material/Close';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { useState } from 'react';

export const MensajeMapa = ({ children }) => {
  const [show, setShow] = useState(true);

  if (!show) {
    return;
  }
  return (
    <div className="shadow-md max-w-md mx-auto rounded-3xl py-2 px-4 bg-white">
      <div className="relative flex justify-center items-center">
        {children}
        <div className="">
          <IconButtonComponent
            onClick={() => setShow(false)}
            size="small"
            type="neutral"
          >
            <Close />
          </IconButtonComponent>
        </div>
      </div>
    </div>
  );
};
