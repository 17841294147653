import { BarraLateral } from './componentes/BarraLateral/BarraLateral';
import { useContext } from 'react';
import { MatchContext } from './context/MatchContext';
import { CriteriosBusqueda } from './componentes/CriteriosBusqueda/CriteriosBusqueda';
import { CargandoMatch } from './componentes/CargandoMatch/CargandoMatch';
import { Mapa } from './componentes/Mapa/Mapa';
import { EmpresaDetalle } from './componentes/Mapa/MapaPopup/EmpresaDetalle';
import { MatchResiduoDetalle } from './componentes/Mapa/MapaPopup/MatchResiduoDetalle';
import { useResponsive } from 'hooks/useResponsive';

/**
 * @name MatchResiduos
 * Despliega el resultado de match y maneja todos los subcomponentes.
 */
export const MatchResiduos = () => {
  const {
    residuoSeleccionado,
    sucursalSeleccionada,
    setSucursalSeleccionado,
    isCargandoMatch
  } = useContext(MatchContext);

  const { isMDDown } = useResponsive();

  return (
    <>
      <CriteriosBusqueda />
      {isCargandoMatch ? (
        <CargandoMatch />
      ) : (
        <div className="flex h-auto sm:h-[80vh] relative">
          <div className="grid grid-cols-12 w-full">
            <div className="order-2 sm:order-1 col-span-12 sm:col-span-6 lg:col-span-4">
              <BarraLateral />
            </div>

            {residuoSeleccionado && !isMDDown ? (
              <div className="order-3 lg:order-2 col-span-6 md:col-span-6 lg:col-span-4">
                <MatchResiduoDetalle />
              </div>
            ) : null}

            {sucursalSeleccionada && !isMDDown ? (
              <div className="order-3 lg:order-2 col-span-6 md:col-span-6 lg:col-span-4">
                <EmpresaDetalle />
              </div>
            ) : null}

            <div
              className={`
              ${
                residuoSeleccionado || sucursalSeleccionada
                  ? 'order-1 lg:order-3 col-span-12 sm:col-span-6 lg:col-span-4'
                  : 'order-1 md:order-3 lg:order-3 col-span-12 sm:col-span-6 lg:col-span-8'
              }`}
            >
              <Mapa />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
