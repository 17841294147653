import { Divider, Skeleton } from '@mui/material';
import imgEditarInformacion from 'assets/images/usuario/editar-informacion.svg';
import AlertComponent from 'components/design-system/Alert/Alert';
import ButtonComponent from 'components/design-system/Button/Button';
import { DICTONARY } from 'const/Dictonary';

export const CuentaSkeleton = () => {
  return (
    <>
      <div>
        <div className="grid grid-cols-12 gap-4 items-center">
          <div className="col-span-12 md:col-span-3 text-center mx-auto">
            <img
              src={imgEditarInformacion}
              alt="Actualizar información de contacto"
            />
          </div>
          <div className="col-span-12 md:col-span-9">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-6">
                <Skeleton
                  variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>
              <div className="col-span-12 md:col-span-6">
                <Skeleton
                  variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>
              <div className="col-span-12 md:col-span-6">
                <Skeleton
                  variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>
              <div className="col-span-12 md:col-span-6">
                <Skeleton
                  variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>
              <div className="col-span-12 md:col-span-6">
                <Skeleton
                  variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>
              <div className="col-span-12 md:col-span-6">
                <Skeleton
                  variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>
              <div className="col-span-12 md:col-span-6">
                <Skeleton
                  variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>
              <div className="col-span-12 md:col-span-6">
                <Skeleton
                  variant="rounded"
                  className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-12 my-5">
          <Divider />
        </div>

        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-3">
            <div className="logo-thumbnail pt-05">
              <Skeleton
                variant="circular"
                className="h-32 w-32 relative z-0 flex justify-center items-center mx-auto"
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-9">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-12">
                <Skeleton
                  variant="rounded"
                  className="w-full h-44 rounded relative z-0 min-w-full flex justify-center items-center"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 my-5">
          <Divider />
        </div>

        <div className="col-span-12 justify-between">
          <div className="text-end">
            <ButtonComponent
              form="editarCuenta"
              buttonType="submit"
              disabled={true}
            >
                {
                 DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR
                }
            </ButtonComponent>
          </div>
        </div>
      </div>
    </>
  );
};
