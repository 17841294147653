import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import Text from 'components/design-system/Text/Text';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import markerRecicladordeBaseTipoA from './../../../../../assets/images/smp/markerRecicladordeBaseTipoA.svg';
import markerRecicladordeBaseTipoB from './../../../../../assets/images/smp/markerRecicladordeBaseTipoB.svg';
import markerRecicladordeBaseTipoC from './../../../../../assets/images/smp/markerRecicladordeBaseTipoC.svg';
import React, { useContext, useState } from 'react';
import {
  ChevronLeft,
  ExpandLess,
  ExpandMore,
  Factory
} from '@mui/icons-material';
import { Divider } from '@mui/material';
import LinkComponent from 'components/design-system/Link/Link';
import Loading from 'components/Loading/Loading';
import { DICTONARY } from 'const/Dictonary';
import { AuthContext } from 'context';
import { EnlacesRecomendados } from '../helpers/EnlacesRecomendados';
import { RUTAS } from 'const/Rutas';
import { TiposDeRecicladorBase } from '../helpers/TiposdeRecicladorBase';

const TIPO_RECICLADOR = {
  A: {
    titulo: 'Tipo A',
    icono: markerRecicladordeBaseTipoA
  },
  B: {
    titulo: 'Tipo B',
    icono: markerRecicladordeBaseTipoB
  },
  C: {
    titulo: 'Tipo C',
    icono: markerRecicladordeBaseTipoC
  }
};

export const BusinessSuccess = ({ values, isLoading }) => {
  const { logeduser } = useContext(AuthContext);
  const [residuo, setResiduo] = useState(values.nombreResiduosSeleccionados);
  const [isDisplayMore, setIsDisplayMore] = useState(false);

  if (!logeduser.sucursal || !logeduser.sucursal.empresa) {
    return null;
  }

  const { nombreVia, comuna, region, codigoRolesEmpresa } = values;
  const { nombreComuna } = comuna;
  const { nombreRegion } = region;
  const direccionSucursal = `${nombreVia} , ${nombreComuna} , ${nombreRegion}`;
  const codigoEmpresa = logeduser?.sucursal
    ? logeduser.sucursal.empresa.codigoEmpresa
    : null;

  let autorizacionAcopio, autorizacionTransporte, registroNacionalRDB;
  if (logeduser?.sucursal?.empresa) {
    ({ autorizacionAcopio, autorizacionTransporte, registroNacionalRDB } =
      logeduser.sucursal.empresa);
  }

  const getTipoReciclador = () => {
    let contador = 0;
    if (autorizacionAcopio) {
      contador++;
    }
    if (autorizacionTransporte) {
      contador++;
    }
    if (registroNacionalRDB) {
      contador++;
    }

    if (contador === 0) {
      return 'C';
    }
    if (contador === 3) {
      return 'A';
    }

    return 'B';
  };

  const asignaNombreRol = codigoRolesEmpresa => {
    const valores = codigoRolesEmpresa;
    const valoresNuevos = valores.map(valor => {
      switch (valor) {
        case DICTONARY.ROL.GENERADOR.CODIGO.toString():
          return DICTONARY.ROL.GENERADOR.NOMBRE;
        case DICTONARY.ROL.RECEPTOR.CODIGO.toString():
          return DICTONARY.ROL.RECEPTOR.NOMBRE;
        case DICTONARY.ROL.TRANSPORTISTA.CODIGO.toString():
          return DICTONARY.ROL.TRANSPORTISTA.NOMBRE;
        case DICTONARY.ROL.GESTOR.CODIGO.toString():
          return DICTONARY.ROL.GESTOR.NOMBRE;
        case DICTONARY.ROL.ASESOR.CODIGO.toString():
          return DICTONARY.ROL.ASESOR.NOMBRE;
        case DICTONARY.ROL.RECICLADORDEBASE.CODIGO.toString():
          return DICTONARY.ROL.RECICLADORDEBASE.TITULO;
        default:
          return valor;
      }
    });

    let nombreRoles = valoresNuevos.join(', ');
    return nombreRoles;
  };

  return (
    <div className="px-4 md:container md:mx-auto">
      {!isLoading ? (
        <>
          <div className="shadow-md rounded-tl rounded-tr bg-white">
            <div className="px-4 sm:px-5 py-4 border-b">
              <div className="flex justify-between flex-col md:flex-row gap-4">
                <Heading
                  type="h2"
                  className="mb-0 text-xl text-[22px] sm:h-10 flex items-center"
                >
                  Tu negocio ha sido registrado
                </Heading>
                <div>
                  <LinkComponent
                    href={RUTAS.HOME_USER}
                    type="button"
                    className="pl-2"
                  >
                    <ChevronLeft className="mr-2" /> Volver al inicio
                  </LinkComponent>
                </div>
              </div>
            </div>

            <div className="px-5 pt-5 pb-8">
              <div className="border rounded grid grid-cols-12 gap-4 items-center p-5">
                <div className="col-span-12 flex items-center gap-4">
                  <div className="w-12 h-12 rounded-full bg-[#2E8567] align-middle justify-center content-center items-center min-w-[48px]">
                    <IconComponent color="primary" className="text-white">
                      <Factory className="w-8 h-8" />
                    </IconComponent>
                  </div>
                  <Heading type="h4" className="font-normal mb-0">
                    Información del{' '}
                    <b className="text-black font-semibold">
                      Negocio
                      {values.tipoNegocio === 'empresa'
                        ? ' como Empresa'
                        : ' como Persona Natural'}
                    </b>
                  </Heading>
                </div>

                <div className="col-span-12 mt-4">
                  <Divider />
                </div>

                <div className="col-span-12">
                  <div className="grid grid-cols-12 gap-2">
                    {values.tipoEmpresa === 'persona-natural' && (
                      <>
                        <div className="col-span-12 md:col-span-2">
                          <Text className="opacity-60">Nombre</Text>
                        </div>

                        <div className="col-span-10 text-left">
                          <Text>{values.nombre}</Text>
                        </div>
                      </>
                    )}

                    {values.tipoEmpresa === 'empresa' && (
                      <>
                        <div className="col-span-12 md:col-span-2">
                          <Text className="opacity-60">Razon Social</Text>
                        </div>
                        <div className="col-span-12 md:col-span-10 text-left">
                          <Text>{values.razonSocial}</Text>
                        </div>

                        <div className="col-span-12 md:col-span-2">
                          <Text className="opacity-60">Rut</Text>
                        </div>
                        <div className="col-span-12 md:col-span-10 text-left">
                          <Text>{values.rutEmpresa || ''}</Text>
                        </div>

                        <div className="col-span-12 md:col-span-2">
                          <Text className="opacity-60">Rubro</Text>
                        </div>
                        <div className="col-span-12 md:col-span-10 text-left">
                          <Text>{values.rubro.nombreRubro || ''} </Text>
                        </div>

                        <div className="col-span-12 md:col-span-2">
                          <Text className="opacity-60">Actividad</Text>
                        </div>
                        <div className="col-span-12 md:col-span-10 text-left">
                          <Text>{values.actividad.nombreActividad || ''} </Text>
                        </div>
                      </>
                    )}

                    <div className="col-span-12 md:col-span-2">
                      <Text className="opacity-60">Dirección</Text>
                    </div>
                    <div className="col-span-12 md:col-span-10 text-left">
                      <Text>{direccionSucursal}</Text>
                    </div>
                    <div className="col-span-12 md:col-span-2">
                      <Text className="opacity-60">Rol</Text>
                    </div>
                    <div className="col-span-12 md:col-span-10 text-left">
                      <Text>{asignaNombreRol(codigoRolesEmpresa) || ''}</Text>
                    </div>
                    <div className="col-span-12 md:col-span-2">
                      <Text className="opacity-60">Residuos</Text>
                    </div>
                    <div className="col-span-12 md:col-span-10 text-left">
                      <ul className="mb-3">
                        {residuo.map((item, key) => (
                          <React.Fragment key={key}>
                            {key <= 2 && (
                              <li className="flex items-center mb-1">
                                <IconComponent className="mr-2">
                                  <TaskAltIcon className="text-base" />
                                </IconComponent>
                                <Text>{item.nombre}</Text>
                              </li>
                            )}
                          </React.Fragment>
                        ))}

                        {isDisplayMore &&
                          residuo.map((item, key) => (
                            <React.Fragment key={key}>
                              {key > 2 && (
                                <li className="flex items-center mb-1">
                                  <IconComponent className="mr-2">
                                    <TaskAltIcon className="text-base" />
                                  </IconComponent>
                                  <Text>{item.nombre}</Text>
                                </li>
                              )}
                            </React.Fragment>
                          ))}
                      </ul>
                      <ButtonComponent
                        type="secondary"
                        onClick={() => {
                          setIsDisplayMore(!isDisplayMore);
                        }}
                      >
                        {isDisplayMore ? (
                          <>
                            Ocultar <ExpandLess />
                          </>
                        ) : (
                          <>
                            Ver más <ExpandMore />
                          </>
                        )}
                      </ButtonComponent>
                    </div>
                  </div>
                </div>

                {codigoRolesEmpresa.includes(
                  DICTONARY.ROL.RECICLADORDEBASE.CODIGO.toString()
                ) && (
                  <>
                    <div className="col-span-12 mt-4">
                      <Divider />
                    </div>
                    <div className="col-span-12">
                      <div className="grid grid-cols-12">
                        <div className="col-span-12 md:col-span-8 mb-4">
                          <Heading type="h3" className="font-normal">
                            Reciclador de base -
                            <b className="text-black font-semibold">
                              {' '}
                              {TIPO_RECICLADOR[getTipoReciclador()].titulo}
                            </b>
                          </Heading>
                          <Text>
                            De acuerdo a la información ingresada has sido
                            asignado automáticamente con un tipo de icono de
                            recicladores de base. En el Marketplace tu perfil de
                            negocio será visto por los usuarios con el siguiente
                            icono.
                          </Text>
                        </div>
                        <div className="col-span-12 md:col-span-4 text-center items-center">
                          <img
                            src={TIPO_RECICLADOR[getTipoReciclador()].icono}
                            alt="Reciclador Base"
                            className="m-auto my-4"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {codigoRolesEmpresa.includes(
                DICTONARY.ROL.RECICLADORDEBASE.CODIGO.toString()
              ) && <TiposDeRecicladorBase />}
            </div>
          </div>

          <EnlacesRecomendados
            codigoEmpresa={codigoEmpresa}
            isRecicladorBase={
              codigoRolesEmpresa.includes(
                DICTONARY.ROL.RECICLADORDEBASE.CODIGO.toString()
              )
                ? true
                : false
            }
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default BusinessSuccess;
