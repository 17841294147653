import React, { useContext } from 'react';
import List from '@mui/material/List';
import Factory from '@mui/icons-material/Factory';
import FindReplace from '@mui/icons-material/FindReplace';
import FileUpload from '@mui/icons-material/FileUpload';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import ListSubheaderComponent from 'components/design-system/ListSubheader/ListSubheader';
import { CompareArrows, Domain, InvertColors } from '@mui/icons-material';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { PERMISOS } from 'const/Permisos';
import { AuthContext } from 'context';
import { RUTAS } from 'const/Rutas';

const SubMenuAnalisisTerritorial = React.forwardRef(({ forceRefresh }, ref) => {
  const { isTienePermisos } = useContext(AuthContext);

  return (
    <List>
      <VerificarPermisos nombrePermiso={PERMISOS.lineaBase}>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.ANALISIS_TERRITORIAL_BASE)}
          icon={<Factory />}
          label={'Análisis línea base'}
        />
      </VerificarPermisos>

      <VerificarPermisos nombrePermiso={PERMISOS.potencialSinergia}>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.ANALISIS_TERRITORIAL_SINERGIAS)}
          icon={<FindReplace />}
          label={'Potencial de Sinergías'}
        />
      </VerificarPermisos>

      <VerificarPermisos nombrePermiso={PERMISOS.transaccionesAT}>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.TRANSACCIONES)}
          icon={<CompareArrows />}
          label={'Transacciones'}
        />
      </VerificarPermisos>

      {(isTienePermisos(PERMISOS.cargaBaseDatos) ||
        isTienePermisos(PERMISOS.empresaAT) ||
        isTienePermisos(PERMISOS.residuoAT)) && (
        <ListSubheaderComponent className="mt-5">
          Administración
        </ListSubheaderComponent>
      )}

      <VerificarPermisos nombrePermiso={PERMISOS.cargaBaseDatos}>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.GESTION_TERRITORIAL)}
          icon={<FileUpload />}
          label={'Cargar base de datos'}
        />
      </VerificarPermisos>
      <VerificarPermisos nombrePermiso={PERMISOS.empresaAT}>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.LISTA_EMPRESAS_AT)}
          icon={<Domain />}
          label={'Empresas análisis territorial'}
        />
      </VerificarPermisos>
      <VerificarPermisos nombrePermiso={PERMISOS.residuoAT}>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.LISTA_RESIDUOS_AT)}
          icon={<InvertColors />}
          label={'Residuos análisis territorial'}
        />
      </VerificarPermisos>
    </List>
  );
});

export default SubMenuAnalisisTerritorial;
