export const ICON_AGOTAMIENTO_FOSIL = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 31 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_610_120"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="31"
        height="34"
      >
        <path d="M30.6829 0H0V34H30.6829V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_610_120)">
        <path
          d="M8.35904 7.73632C9.78704 7.73632 10.8634 7.09032 10.8634 6.23286C10.8634 5.3754 9.78539 4.73022 8.35904 4.73022C6.9327 4.73022 5.85382 5.37622 5.85382 6.23286C5.85382 7.08949 6.93187 7.73632 8.35904 7.73632ZM8.35904 5.73281C9.32846 5.73281 9.86167 6.1093 9.86167 6.23037C9.86167 6.35145 9.32846 6.72793 8.35904 6.72793C7.38963 6.72793 6.85558 6.35145 6.85558 6.23037C6.85558 6.1093 7.3888 5.73281 8.35904 5.73281Z"
          fill="white"
        />
        <path
          d="M4.34979 27.6305V24.9694C4.34979 23.8548 4.58696 26.4081 12.7586 26.4081C20.9302 26.4081 21.1674 23.8548 21.1674 24.9694V27.6305C21.1674 28.745 17.4025 29.6481 12.7586 29.6481C8.11467 29.6481 4.34979 28.745 4.34979 27.6305Z"
          fill="#43A7B6"
        />
        <path
          d="M3.48278 30.5861C1.91214 29.717 1.65839 28.8446 1.65839 28.8322V5.23205C1.65839 5.22209 1.74131 4.26678 3.19253 3.32473C4.24927 2.68879 5.40882 2.24203 6.61907 2.00453C8.77185 1.56911 10.965 1.36506 13.1612 1.39585H13.2482C19.6452 1.39585 22.1985 2.76497 23.2136 3.91517C23.4293 4.12945 23.6002 4.38457 23.7162 4.66564C23.8323 4.9467 23.8911 5.24807 23.8894 5.55214L23.8513 21.9617V28.3471C23.8294 28.937 23.5772 29.4949 23.1489 29.9011C22.1289 31.0115 19.6071 32.3359 13.395 32.3359C10.3176 32.3317 6.09912 32.0282 3.48278 30.5861ZM3.404 28.0983C3.404 28.1033 3.58478 28.6746 4.86517 29.2418C6.04356 29.7626 8.4219 30.3846 13.06 30.3846C18.5962 30.3846 20.7324 29.3895 21.5492 28.5602C21.7936 28.351 21.9514 28.0583 21.992 27.7392V22.82C20.7962 23.6659 18.2064 24.7978 12.9082 24.7978C7.17053 24.7978 4.51273 23.471 3.39736 22.6152L3.404 28.0983ZM12.9082 23.4544C18.2985 23.4544 20.8302 22.3821 21.992 21.5355V15.4204C20.7962 16.2663 18.2064 17.3974 12.9082 17.3974C7.15478 17.3974 4.49863 16.0615 3.38824 15.2081L3.3957 21.387C4.56083 22.2511 7.21448 23.4469 12.9049 23.4469L12.9082 23.4544ZM12.9082 16.0548C18.2985 16.0548 20.8302 14.9826 21.992 14.1359V8.17761C20.7962 9.02346 18.2064 10.1554 12.9082 10.1554C7.13902 10.1554 4.4837 8.812 3.37912 7.95868L3.38658 13.9817C4.55087 14.8449 7.20039 16.0482 12.9049 16.0482L12.9082 16.0548ZM12.9082 8.812C18.2985 8.812 20.8302 7.73975 21.992 6.89307V5.67405C21.992 5.66824 21.8088 5.07034 20.603 4.47658C19.4943 3.93009 17.266 3.27912 12.9538 3.27912C8.64165 3.27912 6.26829 3.93009 5.03517 4.47658C4.37336 4.69769 3.79476 5.11544 3.37663 5.67405V6.73219C4.53761 7.59546 7.18629 8.80453 12.9049 8.80453L12.9082 8.812Z"
          fill="#226355"
        />
        <path
          d="M22.8048 19.0727C26.6977 19.0727 29.8535 15.9168 29.8535 12.0239C29.8535 8.13094 26.6977 4.9751 22.8048 4.9751C18.9118 4.9751 15.756 8.13094 15.756 12.0239C15.756 15.9168 18.9118 19.0727 22.8048 19.0727Z"
          fill="#43A7B6"
        />
        <path
          d="M24.1799 15.3954L25.695 13.8804L22.4667 10.652L19.8205 13.2982L16.918 10.3784L17.8551 9.44296L19.8221 11.4241L22.4667 8.77954L26.6346 12.9408L28.1496 11.4257V15.3954H24.1799Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
