import { ChevronRight } from '@mui/icons-material';
import { Divider, Skeleton } from '@mui/material';
import AlertComponent from 'components/design-system/Alert/Alert';
import LinkComponent from 'components/design-system/Link/Link';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import { useContext } from 'react';
import { MatchContext } from 'views/Marketplace/Residuos/context/MatchContext';

export const TabBusquedaAmpliada = () => {
  const {
    setCoordenadasCentroMapa,
    setSucursalATSeleccionada,
    isCargandoMatchAmpliada,
    matchAmpliadaFiltrado,
    sucursalATSeleccionada
  } = useContext(MatchContext);

  const handleSeleccionarSucursal = empresa => {
    const coordenadas = [empresa.direccion.latitud, empresa.direccion.longitud];
    setCoordenadasCentroMapa(coordenadas);
    setSucursalATSeleccionada({
      ...empresa,
      rol: null,
      empresa: empresa.razonSocial
    });
  };

  if (isCargandoMatchAmpliada) {
    return (
      <div className="m-2">
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
      </div>
    );
  }

  return (
    <div>
      {matchAmpliadaFiltrado.numeroSucursales === 0 && (
        <div className="m-4">
          <AlertComponent type="info">
            <Text size="S">
              <strong className="text-black">
                No se han encontrado resultados
              </strong>
              . Intenta realizando una nueva busqueda y modificando los
              criterios.
            </Text>
          </AlertComponent>
        </div>
      )}

      {matchAmpliadaFiltrado.sucursales.length >= 30 && (
        <div className="m-4">
          <AlertComponent size="S" type="warning">
            <Text size="S" className="mb-2 font-medium text-black">
              Se están visualizando{' '}
              <strong>
                30 de {matchAmpliadaFiltrado.numeroSucursales} empresas
              </strong>
              , <strong>para más resultados y detalles contáctanos</strong>.
            </Text>
            <LinkComponent
              href={{ pathname: DICTONARY.JIRA_CONTACTO_URL }}
              type="button"
              target="_blank"
            >
              Contactar <ChevronRight />
            </LinkComponent>
          </AlertComponent>
        </div>
      )}

      {matchAmpliadaFiltrado.sucursales.length > 0 &&
        matchAmpliadaFiltrado.sucursales.map((item, index) => {
          return (
            <div
              key={index}
              className={`flex items-center hover:bg-uv-primary-90 p-4 border-b cursor-pointer
              ${
                sucursalATSeleccionada &&
                item.codigoEmpresaAT === sucursalATSeleccionada.codigoEmpresaAT
                  ? 'bg-neutral-80 hover:bg-neutral-70'
                  : ''
              }`}
              onClick={() => handleSeleccionarSucursal(item)}
            >
              <div className="w-4/5">
                <div className="flex items-center">
                  <Text size="M" className="text-black pb-0.5">
                    {item.razonSocial}
                  </Text>
                </div>
              </div>
              <div className="w-1/5 text-right">
                <ChevronRight />
              </div>
            </div>
          );
        })}
    </div>
  );
};
