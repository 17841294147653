export const ICON_RECICLADORBASE_TIPO_B = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 108 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1101_130)">
        <path
          d="M104 82.5085V27.5085L54.0089 0L4 27.5085V82.5085L54.0089 110L104 82.5085Z"
          fill="url(#paint0_linear_1101_130)"
        />
        <path
          d="M101.961 81.1455V28.6153L54.2229 2.35022L6.4668 28.6153V81.1455L54.2229 107.411L101.961 81.1455Z"
          fill="url(#paint1_linear_1101_130)"
        />
        <path
          d="M99.8044 79.7832V29.5695L54.1581 4.46265L8.51172 29.5695V79.7832L54.1581 104.89L99.8044 79.7832Z"
          fill="url(#paint2_linear_1101_130)"
        />
        <path
          d="M54.492 10.3894L94.7566 32.5325V76.8186L54.492 98.9617L14.2273 76.8186V32.5325L54.492 10.3894ZM54.492 9.69104L13.6016 32.1748V77.1593L54.492 99.6601L95.3824 77.1593V32.1748L54.492 9.69104Z"
          fill="url(#paint3_linear_1101_130)"
        />
        <path
          d="M54.0555 88.5724L55.1591 90.808L57.642 91.1699L55.8382 92.9158L56.2626 95.3856L54.0555 94.2146L51.8484 95.3856L52.2729 92.9158L50.4902 91.1699L52.952 90.808L54.0555 88.5724Z"
          fill="#F1F2F4"
        />
        <path
          d="M54.0661 13.6265L55.173 15.8621L57.642 16.224L55.8541 17.9699L56.2798 20.4397L54.0661 19.2687L51.8525 20.4397L52.2782 17.9699L50.4902 16.224L52.9593 15.8621L54.0661 13.6265Z"
          fill="#F1F2F4"
        />
      </g>
      <g filter="url(#filter1_d_1101_130)">
        <path
          d="M37.8317 42.0365L41.7271 35.886L44.5328 31.4484C45.5952 29.7616 48.1558 29.7616 49.2182 31.4484L53.2498 37.8324L49.8992 43.1783L47.1752 47.3824L37.8317 42.0365ZM81.2804 57.14L76.9219 50.237L67.4967 55.4272L73.5169 65.0032H76.595C78.6653 65.0032 80.5449 63.8873 81.4711 62.1226C81.8525 61.396 82.0432 60.6175 82.0432 59.813C82.0432 58.8787 81.7707 57.9705 81.2804 57.14ZM65.6988 77.9788H69.7849C71.8552 77.9788 73.7348 76.8629 74.661 75.0982L78.6108 67.5983H65.6988V62.4081L54.8026 72.7885L65.6988 83.169V77.9788ZM49.3544 67.5983H37.641L35.3528 71.2574C34.5355 72.555 34.4811 74.164 35.1893 75.5394C35.9521 77.0186 37.5593 77.9788 39.3299 77.9788H49.3544V67.5983ZM38.7851 60.7213L43.4977 63.4202L39.7657 49.173L24.8379 52.7283L29.4688 55.3753L28.3519 57.166C27.3985 58.6971 27.3168 60.5656 28.1612 62.1486L32.6015 70.6086L38.7851 60.7213ZM68.4773 36.8203L64.9361 31.1889C63.9282 29.6318 62.1575 28.6716 60.2507 28.6716H50.6347L59.1338 42.1662L54.4484 44.8392L69.4035 48.3945L73.1355 34.1473L68.4773 36.8203Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1101_130"
          x="0"
          y="0"
          width="108"
          height="118"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1101_130"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1101_130"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1101_130"
          x="20.8379"
          y="28.6716"
          width="65.2051"
          height="62.4973"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1101_130"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1101_130"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1101_130"
          x1="-3.08618"
          y1="97.0092"
          x2="94.9962"
          y2="17.4887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A9AAAC" />
          <stop offset="0.2181" stop-color="#6F6F71" />
          <stop offset="0.527" stop-color="#EAEBED" />
          <stop offset="0.5728" stop-color="#E5E6E8" />
          <stop offset="0.6273" stop-color="#D7D8DA" />
          <stop offset="0.686" stop-color="#BFC0C2" />
          <stop offset="0.7278" stop-color="#A9AAAC" />
          <stop offset="1" stop-color="#EAEBED" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1101_130"
          x1="17.9034"
          y1="32.2068"
          x2="103.369"
          y2="90.9964"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1371" stop-color="#EAEBED" />
          <stop offset="0.9324" stop-color="#6F6F71" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1101_130"
          x1="85.8868"
          y1="95.5167"
          x2="28.8629"
          y2="14.6536"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B4B4B6" />
          <stop offset="0.4884" stop-color="#E3E3E3" />
          <stop offset="1" stop-color="#9E9C9F" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1101_130"
          x1="13.6008"
          y1="54.673"
          x2="95.3902"
          y2="54.673"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.003861" stop-color="#EBEBEB" />
          <stop offset="1" stop-color="#EAEBED" />
        </linearGradient>
      </defs>
    </svg>
  );
};
