import Heading from 'components/design-system/Heading/Heading';
import Table from 'components/design-system/Table/Table';
import { default as Text } from 'components/design-system/Text/Text';
import { numeroBaseDatosAFront } from 'helpers/formateoNumeros';
import { useContext, useMemo } from 'react';
import { useTablaRETC } from '../hooks/useTablaRETC';
import { LineaBaseContext } from '../context/LineaBaseContext';
import { formeateaDireccion } from 'helpers/manejoDatos';

export const TablaRETC = () => {
  const { refTablaRETC, codigoEmpresaSeleccionada, setCargando, isCargando } =
    useContext(LineaBaseContext);
  const { dataTablaRETC, pagination, setPagination, totalRegistros } =
    useTablaRETC(refTablaRETC, setCargando, codigoEmpresaSeleccionada);

  const isLoading = isCargando.tablaRETCargando;

  const columnasTablaRETC = useMemo(
    () => [
      {
        header: 'Residuo',
        accessorKey: 'nombreLER.nombreLER',
        Cell: ({ renderedCellValue }) => (
          <Text size="S">{renderedCellValue}</Text>
        )
      },
      {
        header: 'Tratamiento',
        accessorKey: 'tratamiento',
        Cell: ({ renderedCellValue }) => (
          <Text size="S">{renderedCellValue ? renderedCellValue : '-'}</Text>
        )
      },
      {
        header: 'Cantidad ',
        accessorKey: 'cantidad',
        Cell: ({ renderedCellValue }) => (
          <Text size="S">
            {numeroBaseDatosAFront(renderedCellValue) || '-'} (Ton)
          </Text>
        )
      },
      {
        header: 'Empresa',
        accessorKey: 'empresaATDestino.razonSocial',
        Cell: ({ renderedCellValue }) => (
          <Text size="S">{renderedCellValue}</Text>
        )
      },
      {
        header: 'Ubicación',
        accessorKey: 'empresaATDestino',
        Cell: ({ renderedCellValue }) => (
          <Text size="S">{formeateaDireccion(renderedCellValue)}</Text>
        )
      }
    ],
    []
  );

  return (
    <Table
      headTable={
        <Heading type="h4" className="p-2 text-uv-primary-0">
          Lista de Residuos según RETC
        </Heading>
      }
      muiTableContainerProps={{ sx: { height: '400px' } }}
      columns={columnasTablaRETC}
      data={dataTablaRETC}
      className="h-full"
      rowCount={totalRegistros}
      manualPagination
      isLoading={isLoading}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={true}
      onPaginationChange={setPagination}
      state={{
        isLoading,
        showProgressBars: false,
        pagination
      }}
    />
  );
};
