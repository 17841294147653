import { useRef } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { MapaSkeleton } from './MapaSkeleton';
import { useMapaMatch } from 'hooks/useMapaMatch';

export const MapaTransacciones = ({
  listaTransacciones,
  registroTablaSeleccionado,
  coordenadasCentro,
  isLoading
}) => {
  const { titlesurl, attribution, getMapIcon } = useMapaMatch();
  const map = useRef(null);

  return (
    <div className="col-span-12 lg:col-span-4">
      {isLoading ? (
        <MapaSkeleton />
      ) : (
        <MapContainer
          className="h-full w-full relative min-h-[600px] lg:min-h-full shadow-md"
          center={coordenadasCentro}
          ref={map}
          zoom="8"
          scrollWheelZoom={false}
        >
          <TileLayer url={titlesurl} attribution={attribution} />
          {listaTransacciones.dataMapa.length > 0 &&
            listaTransacciones.dataMapa.map((sucursal, key) => (
              <Marker
                key={key}
                position={[sucursal.latitud, sucursal.longitud]}
                boundsOptions={[sucursal.latitud, sucursal.longitud]}
                icon={getMapIcon([{ nombreRol: sucursal.rol }])}
                zIndexOffset={
                  registroTablaSeleccionado
                    ? sucursal.codigoTransaccion.includes(
                        registroTablaSeleccionado
                      )
                      ? 200
                      : 100
                    : 200
                }
                opacity={
                  registroTablaSeleccionado
                    ? sucursal.codigoTransaccion.includes(
                        registroTablaSeleccionado
                      )
                      ? 1
                      : 0.1
                    : 1
                }
              />
            ))}
        </MapContainer>
      )}
    </div>
  );
};
