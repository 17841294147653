import Heading from 'components/design-system/Heading/Heading';
import { FiltroForm } from './FiltroForm/FiltroForm';

const Filtro = ({ isLoading, setFiltros }) => {
  return (
    <div className="bg-white shadow-md py-5">
      <div className="px-4 md:container md:mx-auto">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <Heading type="h2" className="text-uv-primary-0">
              Potencial de sinergías
            </Heading>
          </div>
        </div>
        <FiltroForm isLoading={isLoading} setFiltros={setFiltros} />
      </div>
    </div>
  );
};

export default Filtro;
