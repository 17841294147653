import * as yup from 'yup';
import ValidateRut from 'helpers/validateRut';

const schemaRecursosAdquiridos = yup.object().shape({
  // Seccion 1 - Recurso Adquirido
  sucursal: yup
    .object()
    .typeError('Este campo es obligatorio')
    .required('Este campo es obligatorio'),
  tipoRecursoAdquirido: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio'),
  cantidad: yup
    .number()
    .required('Este campo es obligatorio')
    .typeError('Debe ser un número'),
  unidadMedida: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio'),
  // levantamientoDatos: yup // opcional
  //   .object()
  //   .nullable()
  // ,

  fechaAdquisicion: yup
    .date()
    .min(new Date(1900, 0, 1), 'La fecha no puede ser menor a 1900-01-01')
    // .max(new Date(), 'La fecha no puede ser mayor a la fecha actual')
    // debe tener formato yyy-mm-dd
    .required('Este campo es obligatorio')
    .typeError('No es una fecha válida'),
  numeroGuiaDespacho: yup //Integer (max 10), opcional
    .string()
    .nullable()
    .max(10, 'La cantidad máxima de caracteres es de 10 caracteres'),
  // Seccion 2 - Generador - Vendedor del recurso

  rut: yup // Integer (max 12), obligatorio
    .string()
    .trim()
    .matches(/^[^.\s]*$/, 'No se permiten puntos en este campo')
    .max(12, 'La cantidad máxima de caracteres es 11')
    .min(9, 'La cantidad mínima de caracteres es 9')
    // si tiene guion
    .matches(
      /^[0-9]+[-|‐]{1}[0-9kK]{1}$/,
      'El rut debe ser del tipo 12345678-9'
    )
    .test(
      'Evaluación de rut',
      'El rut ingresado es inválido',
      value => !value || ValidateRut.isValid(value)
    )
    .required('Este campo es obligatorio'),
  nombreEmpresaGenerador: yup
    .string()
    .trim()
    .max(128, 'La cantidad máxima de caracteres es 128')
    .min(2, 'La cantidad mínima de caracteres es 2')
    .required('Este campo es obligatorio'),

  nombreVia: yup
    .string()
    .trim()
    // validacion para direccion nombre y numero  de via
    // .matches(
    //   /^[A-Za-z0-9ñÑ\s]+(?: [A-Za-z0-9ñÑ\s]+)*,\s*\d+$/,
    //   'La dirección debe ser del tipo nombre de calle, número'
    // )
    .max(100, 'La cantidad máxima de caracteres es 100')
    .min(3, 'La cantidad mínima de caracteres es 3')
    .required('Este campo es obligatorio'),
  // Seccion 6 - Generación de residuo
  nombreLER: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio'),
  nombreSubLER: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio'),
  nombreSubSubLER: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio'),
  tipoResiduo: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio'),
  subTipoResiduo: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio'),
  procesoProductivo: yup
    .string()
    .min(3, 'El nombre es muy corto')
    .max(100, 'El nombre no puede ser tan largo')
    .nullable()
  // cantidadResiduo: yup
  //   .number()
  // ,
});

export { schemaRecursosAdquiridos };
