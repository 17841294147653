import { DICTONARY } from 'const/Dictonary';
import { useCallback, useEffect, useState } from 'react';
import analisisTerritorial from 'services/analisisTerritorial';
import { AlertaError, AlertaInfo } from 'utils/Alertas';

export const useGraficoBarra = (filtrosAplicados, setCargando) => {
  const [dataGrafico, setDataGrafico] = useState([
    {
      residuos: 0,
      empresas: 0,
      provincia: 'SIN_DATOS'
    }
  ]);

  const cargarGrafico = useCallback(async () => {
    if (Object.keys(filtrosAplicados).length === 0) return;
    try {
      setCargando('graficoBarrasCargando', true);
      let requestParams = {};

      if (Object.hasOwn(filtrosAplicados, 'codigoComuna')) {
        requestParams.codigoComuna = filtrosAplicados.codigoComuna;
      }
      if (Object.hasOwn(filtrosAplicados, 'codigosRegion')) {
        requestParams.codigoRegion = filtrosAplicados.codigosRegion;
      }
      if (Object.hasOwn(filtrosAplicados, 'codigoSectorEconomico')) {
        requestParams.codigoSectorEconomico =
          filtrosAplicados.codigoSectorEconomico;
      }

      const response =
        await analisisTerritorial.graficoDistribucionEmpresasResiduos(
          requestParams,
          DICTONARY.INTERCEPTOR.DESACTIVADO
        );

      if (response.data.error) throw new Error('SIN_REGISTROS');
      if (response.status === 200) {
        setDataGrafico(response.data);
      }
    } catch (error) {
      if (error.message === 'SIN_REGISTROS') {
        AlertaInfo('No existen registros para la solicitud realizada');
      } else {
        AlertaError(
          'Por un error en el servicio no se ha obtenido la información para el gráfico de barras'
        );
      }
      setDataGrafico([
        {
          residuos: 0,
          empresas: 0,
          provincia: 'SIN_DATOS'
        }
      ]);
    } finally {
      setCargando('graficoBarrasCargando', false);
    }
  }, [filtrosAplicados, setCargando]);

  // Se carga el mapa al iniciar el componente
  useEffect(() => {
    cargarGrafico();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtrosAplicados]);

  return {
    dataGrafico
  };
};
