import { HubOutlined } from '@mui/icons-material';
import CompareArrows from '@mui/icons-material/CompareArrows';
import Inventory from '@mui/icons-material/Inventory';
import Search from '@mui/icons-material/Search';
import { List } from '@mui/material';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import { PopperComponent } from 'components/design-system/Popper/Popper';
import Text from 'components/design-system/Text/Text';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { PERMISOS } from 'const/Permisos';
import { RUTAS } from 'const/Rutas';
import { AuthContext } from 'context';
import { useContext } from 'react';

export const MenuMarketPlace = ({ forceRefresh }) => {
  const { isTienePermisos } = useContext(AuthContext);

  return (
    <>
      {(isTienePermisos(PERMISOS.busquedaResiduoRecurso) ||
        isTienePermisos(PERMISOS.transacciones) ||
        isTienePermisos(PERMISOS.residuoRecurso)) && (
        <PopperComponent
          Button={
            <IconButtonComponent
              className="text-white rounded"
              aria-controls="conecta-menu"
              aria-haspopup="true"
            >
              <HubOutlined />
              <Text className="text-white ml-1 font-secondary font-normal">
                Marketplace
              </Text>
            </IconButtonComponent>
          }
        >
          <div className="pop-section-item px-3">
            <List>
              <VerificarPermisos
                nombrePermiso={PERMISOS.busquedaResiduoRecurso}
              >
                <ListItemButtonComponent
                  onClick={forceRefresh(RUTAS.MARKETPLACE_RESIDUOS)}
                  icon={<Search />}
                  label={'Búsqueda'}
                />
              </VerificarPermisos>

              <VerificarPermisos nombrePermiso={PERMISOS.transacciones}>
                <ListItemButtonComponent
                  onClick={forceRefresh(RUTAS.TRANSACCIONES)}
                  icon={<CompareArrows />}
                  label={'Transacciones'}
                />
              </VerificarPermisos>

              <VerificarPermisos nombrePermiso={PERMISOS.residuoRecurso}>
                <ListItemButtonComponent
                  onClick={forceRefresh(RUTAS.RESIDUOS)}
                  icon={<Inventory />}
                  label={'Publicar Residuo-recurso'}
                />
              </VerificarPermisos>
            </List>
          </div>
        </PopperComponent>
      )}
    </>
  );
};
