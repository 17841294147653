import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from '../components';
import { Main as MainLayout } from 'layouts';
import {
  NotFound as NotFoundView,
  /* Usuario */
  ListadoResiduos as ListadoResiduosView,
  IngresarResiduo as IngresarResiduoView,
  EditarResiduo as EditarResiduoView,
  DuplicarResiduo as DuplicarResiduoView,

  /* Admin */
  // ListadoEmpresas as ListadoEmpresasView,
  CargarDifusion as CargarDifusionView,

  /* LOG Actividades */
  ListadoActividad as ListadoActividadView,

  /* Modulo de difusión */
  BuscarDifusion as BuscarDifusionView,

  /* Transacciones */
  // Transacciones as TransaccionesView,
  Resumen as ResumenTransaccionesView,

  /* V2 */
  HomeUser,
  BusinessNew,
  BusinessEdit,
  Insight,
  IndicadoresListaDatos,
  InsightEdit,
  MarketplaceResiduos,
  UsuariosInvitar,
  Usuarios,
  UsuariosEditar,
  Sucursales,
  SucursalesForm,
  MiCuenta,
  Negocios,
  Reportes,
  V2Chat,
  ATerritorialEmpresas,
  PotencialSinergias,
  TransaccionesNew,
  GestionTerritorial,
  ListaEmpresasAT,
  ListaResiduosAT,
  IngresarEmpresaAT,
  IngresarResiduoAT,
  EditarEmpresaAT,
  EditarResiduoAT,
  IngresarResiduoDeclarado,
  EditarResiduoDeclarado,
  DuplicarResiduoDeclarado,
  RecursoAdquirido,
  IngresarRecursoAdquirido,
  EditarRecursoAdquirido,
  DuplicarRecursoAdquirido,
  VerificaUsuarioSucursal,
  VerificaUsuario,
  ConfirmEmail,
  IngresarPerfil,
  EditarPerfil,
  ListadoPerfiles
} from 'views';
import { CalculadoraIndicadores } from 'views/Indicadores/Calculadora/CalculadoraIndicadores';
import { PERMISOS } from 'const/Permisos';
import { NoAutorizado } from 'views/NoAutorizado/NoAutorizado';
import ListaResiduosDeclarados from 'views/ResiduoDeclarado/ListaResiduosDeclarados';
import { RUTAS } from 'const/Rutas';

export const RutasPrivadas = () => {
  return (
    // Rutas publicas para usuarios no logueados
    // Rutas privadas para usuarios logueados
    <Switch>
      {/* PAGINA NO ENCONTRADA */}
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MainLayout}
        path={RUTAS.NOT_FOUND}
      />

      {/* Listado 
      
      RESIDUOS Nuevo */}
      <RouteWithLayout
        component={ListadoResiduosView}
        exact
        layout={MainLayout}
        path={RUTAS.RESIDUOS}
        permiso={[PERMISOS.residuoRecurso]}
      />
      {/* RESIDUOS INGRESAR */}
      <RouteWithLayout
        component={IngresarResiduoView}
        exact
        layout={MainLayout}
        path={RUTAS.RESIDUOS_INGRESAR}
        permiso={[PERMISOS.ofrecerResiduo, PERMISOS.requerirResiduo]}
      />

      {/* RESIDUOS EDITAR */}
      <RouteWithLayout
        component={EditarResiduoView}
        exact
        layout={MainLayout}
        path={RUTAS.RESIDUOS_EDITAR}
        permiso={[PERMISOS.editarResiduoDemanda, PERMISOS.editarResiduoOferta]}
      />

      {/* RESIDUOS DUPLICAR */}
      <RouteWithLayout
        component={DuplicarResiduoView}
        exact
        layout={MainLayout}
        path={RUTAS.RESIDUOS_DUPLICAR}
        permiso={[
          PERMISOS.duplicarResiduoDemanda,
          PERMISOS.duplicarResiduoOferta
        ]}
      />

      {/* ADMIN */}
      <RouteWithLayout
        component={ListadoActividadView}
        exact
        layout={MainLayout}
        path={RUTAS.ADMINISTRAR_LOGS_ACTIVIDAD}
        permiso={[PERMISOS.registroActividad]}
      />

      {/* DIFUSION */}
      <RouteWithLayout
        component={CargarDifusionView}
        exact
        layout={MainLayout}
        path={RUTAS.MATERIAL_DIFUSION_CARGAR}
        permiso={[PERMISOS.cargarMaterialInformacion]}
      />

      <RouteWithLayout
        component={CargarDifusionView}
        exact
        layout={MainLayout}
        path={RUTAS.MATERIAL_DIFUSION_EDITAR}
        permiso={[PERMISOS.editarMaterialInformacion]}
      />

      <RouteWithLayout
        component={BuscarDifusionView}
        exact
        layout={MainLayout}
        path={RUTAS.MATERIAL_DIFUSION}
        permiso={[PERMISOS.buscarMaterialInformacion]}
      />

      {/* MI CUENTA */}
      <RouteWithLayout
        component={MiCuenta}
        exact
        layout={MainLayout}
        path={RUTAS.EDITAR_CUENTA}
        permiso={[PERMISOS.editarCuenta]}
      />

      {/* TRANSACCIONES */}
      <RouteWithLayout
        component={TransaccionesNew}
        exact
        layout={MainLayout}
        path={RUTAS.TRANSACCIONES}
        permiso={[PERMISOS.transacciones, PERMISOS.transaccionesAT]}
      />

      <RouteWithLayout
        component={ResumenTransaccionesView}
        exact
        layout={MainLayout}
        path={RUTAS.TRANSACCIONES_RESUMEN}
        permiso={[PERMISOS.resumenTransaccion]}
      />

      {/* CHAT */}
      <RouteWithLayout
        component={V2Chat}
        exact
        layout={MainLayout}
        path={RUTAS.CHAT_ROOM}
        permiso={[PERMISOS.salasReunion]}
      />

      {/* NEGOCIO */}
      <RouteWithLayout
        component={Negocios}
        exact
        layout={MainLayout}
        path={RUTAS.ADMINISTRAR_NEGOCIOS}
        permiso={[PERMISOS.negocio]}
      />
      <RouteWithLayout
        component={BusinessNew}
        exact
        layout={MainLayout}
        path={RUTAS.REGISTRAR_NEGOCIO}
        permiso={[PERMISOS.registrarNegocio]}
      />
      <RouteWithLayout
        component={BusinessEdit}
        exact
        layout={MainLayout}
        path={RUTAS.EDITAR_NEGOCIO}
        permiso={[PERMISOS.editarNegocio]}
      />

      {/* INDICADORES */}
      <RouteWithLayout
        component={IndicadoresListaDatos}
        layout={MainLayout}
        exact
        path={RUTAS.INDICADORES_DATOS}
        permiso={[PERMISOS.configurarIndicadores]}
      />
      <RouteWithLayout
        component={InsightEdit}
        exact
        layout={MainLayout}
        path={RUTAS.INDICADORES_EDITAR}
        permiso={[PERMISOS.configurarIndicadores]}
      />
      <RouteWithLayout
        component={Insight}
        exact
        layout={MainLayout}
        path={RUTAS.INDICADORES}
        permiso={[PERMISOS.indicadoresOnline]}
      />

      <RouteWithLayout
        component={CalculadoraIndicadores}
        exact
        layout={MainLayout}
        path={RUTAS.FORMULARIO_CALCULO_INDICADORES}
        permiso={[PERMISOS.calculadoraIndicadores]}
      />

      {/* MARKETPLACE */}
      <RouteWithLayout
        component={MarketplaceResiduos}
        exact
        layout={MainLayout}
        path={RUTAS.MARKETPLACE_RESIDUOS}
        permiso={[PERMISOS.busquedaResiduoRecurso]}
      />

      <RouteWithLayout
        component={Usuarios}
        exact
        layout={MainLayout}
        path={RUTAS.USUARIOS}
        permiso={[PERMISOS.busquedaResiduoRecurso]}
      />

      <RouteWithLayout
        component={UsuariosInvitar}
        exact
        layout={MainLayout}
        path={RUTAS.INVITAR_USUARIOS}
        permiso={[PERMISOS.invitarUsuarios]}
      />

      <RouteWithLayout
        component={UsuariosEditar}
        exact
        layout={MainLayout}
        path={RUTAS.USUARIOS_EDITAR}
        permiso={[PERMISOS.editarUsuario]}
      />

      <RouteWithLayout
        component={Sucursales}
        exact
        layout={MainLayout}
        path={RUTAS.SUCURSALES}
        permiso={[PERMISOS.sucursal]}
      />

      <RouteWithLayout
        component={SucursalesForm}
        exact
        layout={MainLayout}
        path={RUTAS.SUCURSALES_EDITAR}
        permiso={[PERMISOS.editarSucursal]}
      />

      <RouteWithLayout
        component={Reportes}
        exact
        layout={MainLayout}
        path={RUTAS.EXPORTAR_REPORTES}
        permiso={[PERMISOS.busquedaResiduoRecurso]}
      />

      <RouteWithLayout
        component={ATerritorialEmpresas}
        exact
        layout={MainLayout}
        path={RUTAS.ANALISIS_TERRITORIAL_BASE}
        permiso={[PERMISOS.lineaBase]}
      />

      <RouteWithLayout
        component={PotencialSinergias}
        exact
        layout={MainLayout}
        path={RUTAS.ANALISIS_TERRITORIAL_SINERGIAS}
        permiso={[PERMISOS.potencialSinergia]}
      />

      <RouteWithLayout
        component={GestionTerritorial}
        exact
        layout={MainLayout}
        path={RUTAS.GESTION_TERRITORIAL}
        permiso={[PERMISOS.cargaBaseDatos]}
      />

      <RouteWithLayout
        component={ListaEmpresasAT}
        exact
        layout={MainLayout}
        path={RUTAS.LISTA_EMPRESAS_AT}
        permiso={[PERMISOS.empresaAT]}
      />

      {/* Ingresar Empresa AT */}
      <RouteWithLayout
        component={IngresarEmpresaAT}
        exact
        layout={MainLayout}
        path={RUTAS.INGRESAR_EMPRESA_AT}
        permiso={[PERMISOS.crearEmpresaAT]}
      />

      {/* Editar Empresa AT */}
      <RouteWithLayout
        component={EditarEmpresaAT}
        exact
        layout={MainLayout}
        path={RUTAS.EDITAR_EMPRESA_AT}
        permiso={[PERMISOS.editarEmpresaAT]}
      />

      {/* Lista Residuos AT */}
      <RouteWithLayout
        component={ListaResiduosAT}
        exact
        layout={MainLayout}
        path={RUTAS.LISTA_RESIDUOS_AT}
        permiso={[PERMISOS.residuoAT]}
      />

      {/* Ingresar Residuo AT */}
      <RouteWithLayout
        component={IngresarResiduoAT}
        exact
        layout={MainLayout}
        path={RUTAS.INGRESAR_RESIDUO_AT}
        permiso={[PERMISOS.crearResiduoAT]}
      />

      {/* Editar Residuo AT */}
      <RouteWithLayout
        component={EditarResiduoAT}
        exact
        layout={MainLayout}
        path={RUTAS.EDITAR_RESIDUO_AT}
        permiso={[PERMISOS.editarResiduoAT]}
      />

      <RouteWithLayout
        component={ListaResiduosDeclarados}
        exact
        layout={MainLayout}
        path={RUTAS.GESTIONA_RESIDUO_DECLARADO}
        permiso={[PERMISOS.residuoDeclarado]}
      />

      {/* Ingresar Residuos declarados */}
      <RouteWithLayout
        component={IngresarResiduoDeclarado}
        exact
        layout={MainLayout}
        path={RUTAS.INGRESAR_RESIDUO_DECLARADO}
        permiso={[PERMISOS.crearResiduoDeclarado]}
      />

      {/* Editar Residuos declarados */}
      <RouteWithLayout
        component={EditarResiduoDeclarado}
        exact
        layout={MainLayout}
        path={RUTAS.EDITAR_RESIDUO_DECLARADO}
        permiso={[PERMISOS.editarResiduoDeclarado]}
      />

      {/* Duplicar Residuos declarados */}
      <RouteWithLayout
        component={DuplicarResiduoDeclarado}
        exact
        layout={MainLayout}
        path={RUTAS.DUPLICAR_RESIDUO_DECLARADO}
        permiso={[PERMISOS.duplicarResiduoDeclarado]}
      />

      {/* Lista de Recursos Adquiridos */}
      <RouteWithLayout
        component={RecursoAdquirido}
        exact
        layout={MainLayout}
        path={RUTAS.GESTIONA_RECURSO_ADQUIRIDO}
        permiso={[PERMISOS.recursoAdquirido]}
      />

      {/* Ingresar Recursos Adquiridos */}
      <RouteWithLayout
        component={IngresarRecursoAdquirido}
        exact
        layout={MainLayout}
        path={RUTAS.INGRESAR_RECURSO_ADQUIRIDO}
        permiso={[PERMISOS.crearRecursoAdquirido]}
      />

      {/* Editar Recursos Adquiridos */}
      <RouteWithLayout
        component={EditarRecursoAdquirido}
        exact
        layout={MainLayout}
        path={RUTAS.EDITAR_RECURSO_ADQUIRIDO}
        permiso={[PERMISOS.editarRecursoAdquirido]}
      />

      {/* Duplicar Recursos Adquiridos */}
      <RouteWithLayout
        component={DuplicarRecursoAdquirido}
        exact
        layout={MainLayout}
        path={RUTAS.DUPLICAR_RECURSO_ADQUIRIDO}
        permiso={[PERMISOS.duplicarRecursoAdquirido]}
      />

      {/* PERFILES */}
      <RouteWithLayout
        component={ListadoPerfiles}
        exact
        layout={MainLayout}
        path={RUTAS.ADMINISTRAR_PERFILES}
        permiso={[PERMISOS.eliminarPerfil]}
      />
      <RouteWithLayout
        component={IngresarPerfil}
        exact
        layout={MainLayout}
        path={RUTAS.ADMINISTRAR_PERFILES_INGRESAR}
        permiso={[PERMISOS.crearPerfil]}
      />
      <RouteWithLayout
        component={EditarPerfil}
        exact
        layout={MainLayout}
        path={RUTAS.ADMINISTRAR_PERFILES_EDITAR}
        permiso={[PERMISOS.editarPerfil]}
      />

      {/* HOME USUARIO */}
      <RouteWithLayout
        component={HomeUser}
        exact
        layout={MainLayout}
        path={RUTAS.HOME_USER}
      />

      {/* /verifica-cuenta */}
      <RouteWithLayout
        component={ConfirmEmail}
        exact
        layout={MainLayout}
        path={RUTAS.VERIFICAR_CUENTA}
      />

      {/* /verifica-usuario */}
      <RouteWithLayout
        component={VerificaUsuario}
        exact
        layout={MainLayout}
        path={RUTAS.VERIFICAR_USUARIO}
      />

      {/* /verifica-usuario-sucursal */}
      <RouteWithLayout
        component={VerificaUsuarioSucursal}
        exact
        layout={MainLayout}
        path={RUTAS.VERIFICAR_USUARIO_SUCURSAL}
      />

      {/* HOME  */}
      <RouteWithLayout
        component={HomeUser}
        exact
        layout={MainLayout}
        path={RUTAS.HOME}
      />

      <RouteWithLayout
        component={NoAutorizado}
        exact
        layout={MainLayout}
        path={RUTAS.NOT_ALLOWED}
      />

      <Redirect to={RUTAS.NOT_FOUND} />
    </Switch>
  );
};
