import { CircularProgress } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { Link } from 'react-router-dom';
import { Fragment, useContext, useEffect, useState } from 'react';
import { TaskAlt } from '@mui/icons-material';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';
import { RUTAS } from 'const/Rutas';

const PORCENTAJE_TOTAL_COMPLETADO = 300;

export const CuadroCompletarCuenta = ({ resumenCuenta }) => {
  const { logeduser, isTienePermisos } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    actividades: [],
    porcentajeTotal: 0
  });

  useEffect(() => {
    try {
      const codigoNegocio = logeduser?.sucursal
        ? logeduser.sucursal.empresa.codigoEmpresa
        : null;

      const porcentajeIndicadoresPendiente = parseFloat(
        resumenCuenta.porcentajeIndicadoresPendiente
      );

      const porcentajeNegocioPendiente = parseFloat(
        resumenCuenta.porcentajeNegocioPendiente
      );
      const porcentajeUsuarioPendiente = parseFloat(
        resumenCuenta.porcentajeUsuarioPendiente
      );

      const indicadoresCompletado =
        100 - porcentajeIndicadoresPendiente.toFixed(0);

      const negocioCompletado = 100 - porcentajeNegocioPendiente.toFixed(0);

      const usuarioCompletado = 100 - porcentajeUsuarioPendiente.toFixed(0);

      const totalPorcentajeCompletado =
        (isNaN(indicadoresCompletado) ? 0 : indicadoresCompletado) +
        (isNaN(negocioCompletado) ? 0 : negocioCompletado) +
        (isNaN(usuarioCompletado) ? 0 : usuarioCompletado);

      if (totalPorcentajeCompletado === PORCENTAJE_TOTAL_COMPLETADO) {
        return;
      }

      const porcentajeTotalCompletado = (totalPorcentajeCompletado * 100) / 300;
      const actividadesData = [
        {
          title: 'Completar la información de tu cuenta',
          description:
            'Para una mejor experiencia completa la información de tu perfíl',
          link: `${RUTAS.EDITAR_CUENTA}`,
          isCompleted: porcentajeUsuarioPendiente <= 0,
          hidden: !isTienePermisos(PERMISOS.editarCuenta)
        },
        {
          title: 'Completar datos para generación de indicadores',
          description:
            'Completar los datos para generar indicadores te permite tener conocimiento estadístico del impacto medioambiental de tu negocio.',
          link: `${RUTAS.NEGOCIO}/${codigoNegocio}/datos-indicadores`,
          isCompleted: porcentajeIndicadoresPendiente <= 0,
          hidden: !isTienePermisos(PERMISOS.configurarIndicadores)
        },
        {
          title: 'Completar la información de sucursales',
          description:
            'Hay información que no has completado con respecto a las sucursales de tu negocio.',
          link: `${RUTAS.NEGOCIO}/${codigoNegocio}/sucursales`,
          isCompleted: porcentajeNegocioPendiente <= 0,
          hidden: !isTienePermisos(PERMISOS.editarSucursal)
        }
      ];

      setData({
        actividades: actividadesData.sort(
          (b, a) => b.isCompleted - a.isCompleted
        ),
        porcentajeTotal: parseInt(porcentajeTotalCompletado.toFixed(0))
      });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }, [resumenCuenta, logeduser]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="mb-6">
      <div className="bg-white pl-4 py-4 rounded shadow-md">
        <div className="grid grid-cols-12 md:gap-4">
          <div className="col-span-12 md:col-span-1 text-center flex pt-4">
            <div>
              <div className="relative w-16 m-auto justify-center items-center">
                <CircularProgress
                  variant="determinate"
                  value={data.porcentajeTotal}
                  thickness={6}
                  size={50}
                  className=" text-uv-primary-0 absolute left-[7px] z-10 -top-[1px]"
                />
                <CircularProgress
                  variant="determinate"
                  value={100}
                  thickness={2}
                  size={50}
                  className="text-uv-primary-80"
                />
                <span className="absolute left-5 top-4 font-primary text-xs font-semibold text-uv-primary-0">
                  {data.porcentajeTotal}%
                </span>
              </div>
            </div>
          </div>

          <div className="col-span-12 md:col-span-11 mb-2">
            <div className="p-2 grid grid-cols-12 gap-4 items-center">
              <div className="col-span-12 md:col-span-9">
                <Heading type="H3" className="text-uv-primary-0 mb-0">
                  Tienes actividades pendientes
                </Heading>
              </div>
            </div>

            {data.actividades.map((actividad, i) => (
              <Fragment key={i}>
                {!actividad.hidden && (
                  <div className="p-2 grid grid-cols-12 gap-4 items-center border-t py-4">
                    <div className="col-span-12 md:col-span-9">
                      {actividad.isCompleted ? (
                        <div className="flex">
                          <TaskAlt className="text-success-dark mr-2" />
                          <Heading type="H4" className="text-success-dark mb-0">
                            {actividad.title}
                          </Heading>
                        </div>
                      ) : (
                        <div>
                          <Heading type="H4" className="text-uv-primary-0 mb-0">
                            {actividad.title}
                          </Heading>
                          <Text>{actividad.description}</Text>
                        </div>
                      )}
                    </div>

                    {!actividad.isCompleted && !actividad.hidden && (
                      <div className="col-span-12 md:col-span-3 text-left md:text-center">
                        <Link to={actividad.link}>
                          <ButtonComponent type="primary">
                            Editar
                            <KeyboardArrowRightIcon />
                          </ButtonComponent>
                        </Link>
                      </div>
                    )}
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
