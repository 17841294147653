export const isValorNulo = valor => {
  if (valor === null || valor === undefined) {
    return true;
  }

  if (valor instanceof Date) {
    return isNaN(valor.getTime()); // Es una fecha inválida
  }

  if (typeof valor === 'object') {
    if (Array.isArray(valor)) {
      return valor.length === 0; // Es un array vacío
    }
    return Object.keys(valor).length === 0; // Es un objeto vacío
  }

  if (typeof valor === 'string') {
    return valor.trim().length === 0; // Es un string vacío
  }

  return false; // No es nulo ni vacío
};

export const isValorValido = valor => {
  return !isValorNulo(valor);
};
