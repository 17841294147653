import { useState, useEffect, useCallback, useContext } from 'react';
import moment from 'moment';
import { useInitalValues } from './useInitalValues';
import serviceResiduos from 'services/residuos';
import { unionRecursos } from '../Helpers/useTransformaciones';
import { calculoSubTipoResiduos } from '../useServicesResiduos';
import { numeroFrontABaseDatos } from 'helpers/formateoNumeros';
import { DICTONARY } from 'const/Dictonary';
import { convertirFechaConAjuste } from 'helpers/formatFecha';
import { AuthContext } from 'context';
import { isValorValido } from 'helpers/validaCampos';
import { AlertaError, AlertaExito } from 'utils/Alertas';

export const useSubmitResiduos = (codigoResiduo, nombreRol) => {
  const { logeduser } = useContext(AuthContext);
  const sucursalContext = logeduser.sucursal;
  const [isLoading, setIsLoading] = useState(true);
  const [estadoGuardado, setEstadoGuardado] = useState(false);

  const {
    residuoDemandante,
    setResiduoDemandante,
    residuoOferente,
    setResiduoOferente
  } = useInitalValues();

  const [codigoNivelAnterior, setCodigoNivelAnterior] = useState(false);
  const [residuoEditar, setResiduoEditar] = useState(false);

  useEffect(() => {
    calculoSubTipoResiduos({
      codigoTipoResiduo: codigoNivelAnterior,
      setResiduo: setResiduoEditar
    });
  }, [codigoNivelAnterior]);

  const cargaResiduo = useCallback(async () => {
    // Esperando que tome el codigo por parametro
    if (!codigoResiduo) return;
    setIsLoading(true);

    try {
      const response = await serviceResiduos.obtenerResiduo(codigoResiduo);
      const { data } = response;

      if (response.status === 200) {
        if (nombreRol === DICTONARY.ROL.RECEPTOR.NOMBRE) {
          setCodigoNivelAnterior(
            data.tipoResiduo.codigoNivelAnteriorTipoResiduo
          );
          let dataFiltrada = {};

          dataFiltrada = {
            rol: {
              codigoRol: 2
            },
            codigoSucursal: data.sucursal.codigoSucursal || '',
            nombreSucursal: data.sucursal.nombreSucursal || '',
            tipoResiduo: residuoEditar,
            subTipoResiduo: data.tipoResiduo || {},
            tieneSubTipoResiduo: data.tipoResiduo ? true : false,

            nombreResiduo: data.nombreResiduo || '',
            tipoRecurso: data.recursos || [],
            tieneTipoRecursos: data.recursos.length > 0 ? true : false,
            otroTipoRecurso: data.recursosOtros || [],

            sucursal: data.sucursal || sucursalContext || {},
            recursoReemplazo: null,
            subTipoRecursoReemplazo: data.recursoReemplazos[0] || {},
            tieneTipoRecursosReemplazo:
              data.recursoReemplazos.length > 0 ? true : false,
            descripcionAdicional: data.descripcionAdicional || '',
            direccion: data.sucursal.direccion || {}
          };

          const { nombreVia, comuna } = dataFiltrada.direccion;
          const { nombreComuna, region } = comuna;
          const direccionFormateada = `${nombreVia}, ${nombreComuna}, Región ${region.nombreRegion}`;

          dataFiltrada.nombreDireccionSucursal = direccionFormateada;
          setResiduoDemandante(dataFiltrada);
        } else if (nombreRol === DICTONARY.ROL.GENERADOR.NOMBRE) {
          setCodigoNivelAnterior(
            data.tipoResiduo.codigoNivelAnteriorTipoResiduo
          );
          let dataFiltrada = {};

          dataFiltrada = {
            rol: {
              codigoRol: 1
            },
            // Nombre LER
            nombreLER: [],
            // Nombre Sub Tipo LER
            nombreSubLER: [],
            // Nombre Sub Sub Tipo LER
            nombreSubSubLER: {},
            // Tipo de Residuo
            tipoResiduo: residuoEditar,
            // SubTipo de Residuo
            subTipoResiduo: {
              codigoTipoResiduo: data.tipoResiduo.codigoTipoResiduo,
              nombreTipo: data.tipoResiduo.nombreTipo,
              nivel: data.tipoResiduo.nivel,
              codigoNivelAnteriorTipoResiduo:
                data.tipoResiduo.codigoNivelAnteriorTipoResiduo
            },

            tieneSubTipoResiduo: true,

            // Otro Tipo de Residuo
            otroSubtipoResiduo: '',
            tieneOtroSubtipoResiduo: false,

            // Tipo de Recursos
            tipoRecurso: data.recursos || [],
            otroTipoRecurso: [],

            // Descipción adicional
            descripcionAdicional: data.descripcionAdicional,

            // PASO 2
            codigoSucursal: data.sucursal.codigoSucursal,
            nombreSucursal: data.sucursal.nombreSucursal,
            precioReferencial: data.precioReferencial,
            frecuencia: data.frecuencia,
            fechaDisponibilidad: convertirFechaConAjuste(
              data.fechaDisponibilidad
            ), // Nuevo formato de fecha
            formatoEntrega: data.formatoEntrega,
            cantidad: data.cantidad,
            unidadMedida: data.unidadMedida,
            levantamientoDatos: data.levantamientoDatos,

            // Others
            direccion: data.sucursal.direccion,

            // PASO 3
            // Archivos
            archivosResiduos: data.archivosResiduos || [],
            sucursal: data.sucursal || {},

            // Datos de la sucursal
            empresa: data.sucursal.empresa
          };

          if (isValorValido(data.nombreLER)) {
            dataFiltrada.nombreSubSubLER = {
              codigoLER: data.nombreLER[0].codigoLER,
              nombreLER: data.nombreLER[0].nombreLER,
              codigoNivelAnterior: data.nombreLER[0].codigoNivelAnterior
            };
          }

          const { nombreVia, comuna } = dataFiltrada.direccion;
          const { nombreComuna, region } = comuna;
          const direccionFormateada = `${nombreVia}, ${nombreComuna}, Región ${region.nombreRegion}`;

          dataFiltrada.nombreDireccionSucursal = direccionFormateada;
          setResiduoOferente(dataFiltrada);
        }
      }
    } catch (error) {
      setResiduoDemandante({});
      setResiduoOferente({});
      console.log('Error en cargaResiduo ', error);
      throw new Error('Error en cargaResiduo ', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSubmit = async (valores, tipoFormularioSeleccionado) => {
    try {
      if (nombreRol === DICTONARY.ROL.RECEPTOR.NOMBRE) {
        if (
          tipoFormularioSeleccionado.ingresar ||
          tipoFormularioSeleccionado.duplicar
        ) {
          await submitNuevoResiduoDemandante(
            valores,
            tipoFormularioSeleccionado
          );
        } else if (tipoFormularioSeleccionado.editar) {
          await submitActualizarResiduoDemandante(valores);
        }
      } else if (nombreRol === DICTONARY.ROL.GENERADOR.NOMBRE) {
        if (
          tipoFormularioSeleccionado.ingresar ||
          tipoFormularioSeleccionado.duplicar
        ) {
          await submitNuevoResiduoOferente(valores, tipoFormularioSeleccionado);
        } else if (tipoFormularioSeleccionado.editar) {
          await submitActualizarResiduoOferente(valores);
        }
      }
    } catch (error) {
      console.log('Error en handleSubmit ', error);
      setEstadoGuardado({
        guardado: false,
        errorGuardado: true,
        mensajeError: error
      });
      throw new Error('Error en handleSubmit ', error);
    }
  };

  /**
   * DEMANDANTE
   * @param {*} tipoFormulario
   *
   */
  const submitNuevoResiduoDemandante = async (valores, tipoFormulario) => {
    const generar = tipoFormulario.ingresar ? 'crear' : 'duplicar';
    const generarado = tipoFormulario.ingresar ? 'creado' : 'duplicado';
    try {
      const residuosData = estructuraServicioNuevoResiduoDemandante(valores);
      const response = await serviceResiduos.crearResiduo(residuosData);
      if (response.status === 200) {
        AlertaExito(`Se ha ${generarado} el residuo correctamente`);
      }
    } catch (error) {
      console.log(`Error en ${generar} un residuo ${error}`);
      AlertaError(`No se ha podido ${generar} el residuo`);
      throw new Error('Error en submitNuevoResiduo ', error);
    }
  };

  const submitActualizarResiduoDemandante = async valores => {
    try {
      const residuosData = estructuraServicioNuevoResiduoDemandante(valores);
      const response = await serviceResiduos.editarResiduo(
        codigoResiduo,
        residuosData
      );
      if (response.status === 200) {
        AlertaExito(`Se ha actualizado el residuo correctamente`);
      }
    } catch (error) {
      console.log(`Error en actualizar un residuo ${error}`);
      AlertaError(`No se ha podido actualizar el residuo`);
      throw new Error('Error en submitActualizarResiduo ', error);
    }
  };

  /**
   * OFERENTE
   * @param {*} tipoFormulario
   *
   *
   */
  const submitNuevoResiduoOferente = async (valores, tipoFormulario) => {
    const generar = tipoFormulario.ingresar ? 'crear' : 'duplicar';
    const generarado = tipoFormulario.ingresar ? 'creado' : 'duplicado';

    try {
      const residuosData = estructuraServicioNuevoResiduoOferente(valores);
      const response = await serviceResiduos.crearResiduo(residuosData);
      if (response.status === 200) {
        AlertaExito(`Se ha ${generarado} el residuo correctamente`);
      }
    } catch (error) {
      const code = error?.response?.data?.error?.codigo;
      AlertaError(
        `No se ha podido ${generar} el residuo ${code ? 'codigo: ' + code : ''}`
      );
      throw error;
    }
  };

  const submitActualizarResiduoOferente = async valores => {
    try {
      const residuosData = estructuraServicioNuevoResiduoOferente(valores);
      const response = await serviceResiduos.editarResiduo(
        codigoResiduo,
        residuosData
      );
      if (response.status === 200) {
        AlertaExito(`Se ha actualizado el residuo correctamente`);
      }
    } catch (error) {
      AlertaError(`No se ha podido actualizar el residuo`);
      throw new Error('Error en submitActualizarResiduo ', error);
    }
  };

  const estructuraServicioNuevoResiduoDemandante = valores => {
    try {
      const arrayTipoRecurso = unionRecursos(valores);
      const estructuraServicio = {};

      if (isValorValido(valores.rol)) {
        estructuraServicio.rol = {
          codigoRol: valores.rol.codigoRol
        };
      }
      if (isValorValido(valores.sucursal.codigoSucursal)) {
        estructuraServicio.sucursal = {
          codigoSucursal: valores.sucursal.codigoSucursal
        };
      }
      if (isValorValido(valores.subTipoResiduo)) {
        estructuraServicio.tipoResiduo = {
          codigoTipoResiduo: valores.subTipoResiduo.codigoTipoResiduo
        };
      }
      if (isValorValido(valores.nombreResiduo)) {
        estructuraServicio.nombreResiduo = valores.nombreResiduo;
      }
      if (isValorValido(valores.tipoRecurso)) {
        estructuraServicio.recursos = arrayTipoRecurso;
      }
      if (isValorValido(valores.subTipoRecursoReemplazo)) {
        estructuraServicio.recursoReemplazos = [
          {
            codigoRecursoReemplazo:
              valores.subTipoRecursoReemplazo.codigoRecursoReemplazo
          }
        ];
      }
      if (isValorValido(valores.descripcionAdicional)) {
        estructuraServicio.descripcionAdicional = valores.descripcionAdicional;
      }

      return estructuraServicio;
    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }
  };

  const estructuraServicioNuevoResiduoOferente = valores => {
    try {
      const arrayTipoRecurso = unionRecursos(valores);
      const nombreLER = valores.nombreSubSubLER.codigoLER;
      const estructuraServicio = {};

      if (isValorValido(valores.rol)) {
        estructuraServicio.rol = {
          codigoRol: valores.rol.codigoRol
        };
      }
      if (isValorValido(nombreLER)) {
        estructuraServicio.nombreLER = [{ codigoLER: nombreLER }];
      }
      if (isValorValido(valores.subTipoResiduo)) {
        estructuraServicio.tipoResiduo = {
          codigoTipoResiduo: valores.subTipoResiduo.codigoTipoResiduo
        };
      }
      if (isValorValido(valores.tipoRecurso)) {
        estructuraServicio.recursos = arrayTipoRecurso;
      }
      if (isValorValido(valores.descripcionAdicional)) {
        estructuraServicio.descripcionAdicional = valores.descripcionAdicional;
      }
      if (isValorValido(valores.sucursal)) {
        estructuraServicio.sucursal = {
          codigoSucursal: valores.sucursal.codigoSucursal
        };
      }
      if (isValorValido(valores.precioReferencial)) {
        estructuraServicio.precioReferencial = numeroFrontABaseDatos(
          valores.precioReferencial
        );
      }
      if (isValorValido(valores.frecuencia)) {
        estructuraServicio.frecuencia = valores.frecuencia;
      }
      if (isValorValido(valores.fechaDisponibilidad)) {
        estructuraServicio.fechaDisponibilidad = moment(
          valores.fechaDisponibilidad
        ).format('YYYY-MM-DD');
      }
      if (isValorValido(valores.cantidad)) {
        estructuraServicio.cantidad = numeroFrontABaseDatos(valores.cantidad);
      }
      if (isValorValido(valores.unidadMedida)) {
        estructuraServicio.unidadMedida = valores.unidadMedida;
      }
      if (isValorValido(valores.formatoEntrega)) {
        estructuraServicio.formatoEntrega = valores.formatoEntrega;
      }
      if (isValorValido(valores.imagenes)) {
        estructuraServicio.imagenes = valores.imagenes;
      }
      if (isValorValido(valores.archivosResiduos)) {
        estructuraServicio.archivosResiduos = valores.archivosResiduos;
      }
      if (isValorValido(valores.levantamientoDatos)) {
        estructuraServicio.levantamientoDatos = {
          codigoLevantamientoDatos:
            valores.levantamientoDatos.codigoLevantamientoDatos
        };
      }

      return estructuraServicio;
    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }
  };

  return {
    residuoDemandante,
    setResiduoDemandante,
    residuoOferente,
    setResiduoOferente,
    isLoading,
    setIsLoading,
    estadoGuardado,

    cargaResiduo,
    handleSubmit,
    submitNuevoResiduoDemandante,
    estructuraServicioNuevoResiduoDemandante
  };
};
