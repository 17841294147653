import { useEffect, useState, useMemo } from 'react';
// Design System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';

// Hooks
import { useDireccion } from 'hooks/useDireccion';

// Utils
import {
  handleKeyPress,
  handlePaste
} from 'views/Business/utils/handleKeyPress';

// Components
import MapAddress from 'components/MapAddress/MapAddress';
import ButtonComponent from 'components/design-system/Button/Button';
import { GpsOff, MyLocation } from '@mui/icons-material';
import { DICTONARY } from 'const/Dictonary';
import { CircularProgress } from '@mui/material';

const GeneradorVendedor = ({
  // Formik
  errors,
  // handleBlur,
  handleChange,
  // handleReset,
  // handleSubmit,
  isSubmitting,
  // setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  // validateForm,
  values,
  // Dependencias

  // Empresas SII
  empresasSII,
  cargaEmpresasSII,
  isLoadingEmpresasSII,

  // Tipo Formulario
  tipoForm
}) => {
  const [razonSocial, setRazonSocial] = useState('');

  const { duplicar, editar } = tipoForm;

  const {
    latLon,
    setLatLon,
    addressQuerysearch,
    addressQueryParams,
    regiones,
    cargaListaRegiones,
    handleObtenerDireccion,
    handleObtenerComunas,
    listadoComunas,
    cargaListaComunas,
    setBuscandoDireccion,
    buscandoDireccion
  } = useDireccion();

  useEffect(() => {
    if (!empresasSII) return;
    if (empresasSII.razonSocial) {
      setRazonSocial(empresasSII.razonSocial);
      setRazonSocial(empresasSII.razonSocial);
      setFieldValue('nombreEmpresaGenerador', empresasSII.razonSocial);
    } else {
      setRazonSocial('Sin información');
    }
  }, [empresasSII, isLoadingEmpresasSII]);

  // Carga RUT al ingresar al formulario
  useMemo(() => {
    if ((duplicar || editar) && !touched.rut && values.rut) {
      cargaEmpresasSII(values.rut);
    }
  }, [cargaEmpresasSII, duplicar, editar, touched.rut, values.rut]);

  //  Servicio Comunas
  useMemo(() => {
    if (!values.region) return;
    if (values.region && values.region.codigoRegion) {
      handleObtenerComunas(values.region.codigoRegion);
    }
  }, [handleObtenerComunas, values.region]);

  // Estados de carga de servicios
  useMemo(() => {
    if (isSubmitting) return;
    handleObtenerDireccion(values);
  }, [isSubmitting, handleObtenerDireccion, values]);

  // Verificacion de cambios en los campos de direccion
  useMemo(() => {
    const { nombreVia, comuna, region, isPointEditing } = values;

    if (isSubmitting || !latLon) return;

    const hasNombreVia = nombreVia && touched.nombreVia;
    const hasComuna = comuna && touched.comuna;
    const hasRegion = region && touched.region;

    if (hasNombreVia || hasComuna || hasRegion || isPointEditing) {
      setBuscandoDireccion(true);
      setFieldValue('latitudDireccion', latLon[0]);
      setFieldValue('longitudDireccion', latLon[1]);
    }
  }, [
    isSubmitting,
    latLon,
    setBuscandoDireccion,
    setFieldValue,
    touched.comuna,
    touched.nombreVia,
    touched.region,
    values
  ]);

  return (
    <>
      <div className="col-span-12">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12">
            <Heading type="h4" className="mb-0">
              Generador - Vendedor del recurso
            </Heading>
          </div>
          <div className="col-span-12 xs:col-span-12">
            <div className="grid grid-cols-12 gap-4">
              {/* RUT */}
              <div className="col-span-12 sm:col-span-4">
                <InputComponent
                  autoComplete="off"
                  disabled={isSubmitting}
                  fullWidth
                  name={'rut'}
                  value={values.rut || ''}
                  onChange={event => {
                    const { value } = event.target;
                    setFieldTouched('nombreLER', true);

                    // verificar si el rut es valido

                    if (
                      value.length >= 9 &&
                      value.charAt(value.length - 2) === '-'
                    ) {
                      setFieldValue('rut', value.trim());
                      cargaEmpresasSII(value);
                    } else {
                      setFieldValue('rut', value.trim());
                      setRazonSocial('Sin información');
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched('rut');
                  }}
                  outerInputProps={{
                    inputProps: {
                      maxLength: 12,
                      // minLenth: 9,
                      onKeyPress: handleKeyPress
                    }
                  }}
                  onPaste={handlePaste}
                  touched={touched.rut}
                  placeholder="Ej: 12345678-9"
                  estado={
                    errors.rut ? { tipo: 'error', mensaje: errors.rut } : null
                  }
                  label={<LabelComponent>Rut </LabelComponent>}
                />
              </div>
              <div className="col-span-12 sm:col-span-4">
                <LabelComponent
                  tooltipText={
                    <>
                      <b>La razón social</b> ha sido{' '}
                      <b>obtenida de forma automática</b> con la información
                      entregada por el{' '}
                      <b>Servicio de Impuestos Internos (SII).</b>
                    </>
                  }
                >
                  Razón social
                </LabelComponent>
                <div className="rounded bg-neutral-90 p-2.5 relative">
                  <Text className="min-h-[1.25rem]">
                    {empresasSII &&
                    empresasSII.razonSocial &&
                    !isLoadingEmpresasSII &&
                    !errors.rut &&
                    touched.rut
                      ? razonSocial
                      : !touched.rut && !errors.rut
                      ? ''
                      : 'Sin información'}
                  </Text>

                  {isLoadingEmpresasSII && touched.rut && !errors.rut && (
                    <CircularProgress
                      color="inherit"
                      className="opacity-40 !w-5 !h-5 absolute right-2.5 top-2.5"
                    />
                  )}
                </div>
              </div>
              {/* Nombre */}
              <div className="col-span-12 md:col-span-4">
                <InputComponent
                  autoComplete="off"
                  disabled={isSubmitting}
                  fullWidth
                  name={'nombreEmpresaGenerador'}
                  value={values.nombreEmpresaGenerador || ''}
                  onChange={handleChange}
                  onBlur={() => {
                    setFieldTouched('nombreEmpresaGenerador');
                  }}
                  touched={touched.nombreEmpresaGenerador}
                  estado={
                    errors.nombreEmpresaGenerador
                      ? {
                          tipo: 'error',
                          mensaje: errors.nombreEmpresaGenerador
                        }
                      : null
                  }
                  label={
                    <LabelComponent
                      tooltipText={
                        <>
                          <b>Nombre de fantasía</b> que sirve para{' '}
                          <b>agrupar los residuos declarados</b> de esta
                          empresa. Si se obtuvo <b>el dato de razón social</b>{' '}
                          este <b>será sugerido como nombre de empresa</b>. Sin
                          embargo puede <b>editar el campo</b> e indicar otro
                          nombre para la empresa.
                        </>
                      }
                    >
                      Nombre empresa{' '}
                    </LabelComponent>
                  }
                />
              </div>

              {/* Región */}
              <div className="col-span-12 xs:col-span-6 md:col-span-3">
                <AutocompleteComponent
                  name="region"
                  clearOnEscape={true}
                  accesor="nombreRegion"
                  openOnFocus={true}
                  options={regiones}
                  disabled={!cargaListaRegiones}
                  onChange={(ev, region, reason) => {
                    let data = { name: 'region', value: region };
                    if (reason === 'clear') {
                      data.value = [];
                      setFieldValue('comuna', []);
                    }
                    setFieldValue('comuna', []);
                    setFieldValue('region', data.value);

                    // Tocar los 3 campos para realizar la busqueda
                    setFieldTouched('region');
                    setFieldTouched('comuna');
                    setFieldTouched('nombreVia');
                  }}
                  onBlur={() => setFieldTouched('region')}
                  value={values.region}
                  getOptionLabel={option => option.nombreRegion || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.nombreRegion === values.region.nombreRegion
                  }
                >
                  <InputComponent
                    autoComplete={'off'}
                    fullWidth
                    touched={touched.region ? true : false}
                    estado={
                      errors.region
                        ? { tipo: 'error', mensaje: errors.region }
                        : null
                    }
                    label={<LabelComponent>Región</LabelComponent>}
                  />
                </AutocompleteComponent>
              </div>

              {/* Comuna */}
              <div className="col-span-12 xs:col-span-6 md:col-span-3">
                <AutocompleteComponent
                  name="comuna"
                  accesor="nombreComuna"
                  options={listadoComunas}
                  disabled={!cargaListaComunas}
                  onChange={(ev, comuna, reason) => {
                    let data = { name: 'comuna', value: comuna };

                    if (reason === 'clear') {
                      data.value = [];
                    }

                    setFieldValue('comuna', data.value);

                    // Tocar los 3 campos para realizar la busqueda
                    setFieldTouched('region');
                    setFieldTouched('comuna');
                    setFieldTouched('nombreVia');
                  }}
                  value={values.comuna}
                  getOptionLabel={option => option.nombreComuna || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.nombreComuna === values.comuna.nombreComuna
                  }
                  onBlur={() => setFieldTouched('comuna')}
                >
                  <InputComponent
                    autoComplete={'off'}
                    fullWidth
                    touched={touched.comuna ? true : false}
                    estado={
                      errors.comuna
                        ? { tipo: 'error', mensaje: errors.comuna }
                        : null
                    }
                    label={<LabelComponent>Comuna</LabelComponent>}
                  />
                </AutocompleteComponent>
              </div>

              {/* NombreVia */}
              <div className="col-span-12 xs:col-span-8 lg:col-span-6">
                <InputComponent
                  autoComplete={'off'}
                  fullWidth
                  label={<LabelComponent>Nombre y número calle</LabelComponent>}
                  name="nombreVia"
                  value={values.nombreVia}
                  onChange={e => {
                    handleChange(e);
                    // Tocar los 3 campos para realizar la busqueda
                    setFieldTouched('region');
                    setFieldTouched('comuna');
                    setFieldTouched('nombreVia');
                  }}
                  onBlur={() => setFieldTouched('nombreVia')}
                  touched={touched.nombreVia ? true : false}
                  placeholder="Ej: Providencia, 1091"
                  estado={
                    errors.nombreVia
                      ? { tipo: 'error', mensaje: errors.nombreVia }
                      : null
                  }
                />
              </div>

              {/* Mapa */}
              <div className="col-span-12  xxs:col-span-12 xs:col-span-12 ">
                <div
                  className={`${
                    values.isPointEditing
                      ? ' bg-warning-light'
                      : 'bg-info-light '
                  }
                                sm:flex px-4 py-2.5 justify-between rounded items-center`}
                >
                  <Text>
                    {values.isPointEditing
                      ? DICTONARY.MAPA_MENSAJES.INFO_DESACTIVAR_EDICION
                      : DICTONARY.MAPA_MENSAJES.INFO_ACTIVAR_EDICION}
                  </Text>
                  <ButtonComponent
                    className="min-w-[190px] md:min-w-fit mt-4 sm:mt-0"
                    onClick={() => {
                      setFieldValue('isPointEditing', !values.isPointEditing);
                    }}
                  >
                    {values.isPointEditing && latLon ? (
                      <>
                        Desactivar edición <GpsOff className="ml-2" />
                      </>
                    ) : (
                      <>
                        Edición manual <MyLocation className="ml-2" />
                      </>
                    )}
                  </ButtonComponent>
                </div>
              </div>
              <div className="col-span-12 rounded overflow-hidden">
                <MapAddress
                  addressQuerysearch={addressQuerysearch}
                  addressQueryParams={addressQueryParams}
                  latLon={latLon}
                  setLatLon={setLatLon}
                  isPointEditing={values.isPointEditing}
                  setIsPointEditing={value =>
                    setFieldValue('isPointEditing', value)
                  }
                  isLoading={isSubmitting}
                  buscandoDireccion={buscandoDireccion}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneradorVendedor;
