import { DICTONARY } from 'const/Dictonary';
import { PERMISOS } from 'const/Permisos';
import { isValorValido } from 'helpers/validaCampos';

export const formatoInicialFormulario = (
  ListaEmpresa,
  tieneRutEmpresa,
  setCargandoListaEmpresa
) => {
  try {
    let datosIniciales = {
      tipoNegocio: tieneRutEmpresa ? 'empresa' : 'persona-natural',
      rutEmpresa: ListaEmpresa.rutEmpresa || '',
      nombreEmpresaOriginal: ListaEmpresa.nombreEmpresa || '',
      nombreEmpresa: ListaEmpresa.nombreEmpresa || '',
      razonSocial: ListaEmpresa.razonSocial || '',
      rubro: ListaEmpresa.actividad?.rubro || {},
      actividad: ListaEmpresa.actividad || {},
      codigoRolesEmpresa: ListaEmpresa.rolesFormat,
      areaTrabajo: ListaEmpresa.areaTrabajo || '',
      autorizacionTransporte: ListaEmpresa.autorizacionTransporte,
      autorizacionTransporteFile: ListaEmpresa.archivosEmpresa.find(
        file =>
          file.tipoAcreditacion ===
          DICTONARY.TIPO_ACREDITACION.AUTORIZACION_TRANSPORTE
      ) || {
        nombreArchivo: null,
        tipoAcreditacion: null,
        codigoValidacion: '',
        base64: null,
        tipoArchivo: null
      },

      autorizacionAcopio: ListaEmpresa.autorizacionAcopio,
      autorizacionAcopioFile: ListaEmpresa.archivosEmpresa.find(
        file =>
          file.tipoAcreditacion ===
          DICTONARY.TIPO_ACREDITACION.AUTORIZACION_ACOPIO
      ) || {
        nombreArchivo: null,
        tipoAcreditacion: null,
        codigoValidacion: '',
        base64: null,
        tipoArchivo: null
      },

      registroNacionalRDB: ListaEmpresa.registroNacionalRDB,
      registroNacionalRDBFile: ListaEmpresa.archivosEmpresa.find(
        file =>
          file.tipoAcreditacion ===
          DICTONARY.TIPO_ACREDITACION.REGISTRO_NACIONAL_RBD
      ) || {
        nombreArchivo: null,
        tipoAcreditacion: null,
        codigoValidacion: '',
        base64: null,
        tipoArchivo: null
      },

      inputClienteNombre: '',
      clientes: ListaEmpresa.clientes || []
    };

    const archivosRestructurados = ListaEmpresa.archivosEmpresa.filter(
      file =>
        file.tipoAcreditacion ===
        DICTONARY.TIPO_ACREDITACION.OTRAS_ACREDITACIONES
    );

    datosIniciales.archivosEmpresa = archivosRestructurados;

    return datosIniciales;
  } catch (error) {
    setCargandoListaEmpresa(true);
    return {};
  }
};

export const formatoServicio = (isTienePermisos, values) => {
  try {
    const codigoRolesEmpresa = values.codigoRolesEmpresa.map(cadena =>
      parseInt(cadena)
    );

    const formatoArchivos = values.archivosEmpresa.map(archivo => {
      return {
        nombreArchivo: archivo.nombreArchivo,
        base64: archivo.base64,
        tipoArchivo: archivo.tipoArchivo,
        tipoAcreditacion: DICTONARY.TIPO_ACREDITACION.OTRAS_ACREDITACIONES,
        codigoValidacion: null
      };
    });

    let respRquest = {
      codigoRolesEmpresa: codigoRolesEmpresa,
      tipoNegocio: values.tipoNegocio,
      nombreEmpresa: values.nombreEmpresa
    };

    if (values.tipoNegocio === 'empresa') {
      respRquest.rutEmpresa = values.rutEmpresa;
      respRquest.razonSocial = values.razonSocial;
      respRquest.codigoActividad = values.actividad.codigoCiuActividad;
      respRquest.codigoRubro = values.rubro.codigoRubro;
    }

    if (isTienePermisos(PERMISOS.areaDeTrabajo)) {
      respRquest.areaTrabajo = values.areaTrabajo;
    }

    if (isTienePermisos(PERMISOS.clientes)) {
      respRquest.clientes = values.clientes;
    }

    if (isTienePermisos(PERMISOS.registroNacionalRDB)) {
      respRquest.registroNacionalRDB = values.registroNacionalRDB;
      if (respRquest.registroNacionalRDB) {
        const file = values.registroNacionalRDBFile;
        if (isValorValido(values.registroNacionalRDBFile.base64)) {
          file.base64 = values.registroNacionalRDBFile.base64;
          formatoArchivos.push(file);
        } else if (
          isValorValido(values.registroNacionalRDBFile.codigoValidacion)
        ) {
          formatoArchivos.push(file);
        }
      }
    }

    if (isTienePermisos(PERMISOS.autorizacionSanitariaAcopio)) {
      respRquest.autorizacionAcopio = values.autorizacionAcopio;
      if (respRquest.autorizacionAcopio) {
        const file = values.autorizacionAcopioFile;
        if (isValorValido(values.autorizacionAcopioFile.base64)) {
          file.base64 = values.autorizacionAcopioFile.base64;
          formatoArchivos.push(file);
        } else if (
          isValorValido(values.autorizacionAcopioFile.codigoValidacion)
        ) {
          formatoArchivos.push(file);
        }
      }
    }

    if (isTienePermisos(PERMISOS.autorizacionSanitariaTransporte)) {
      respRquest.autorizacionTransporte = values.autorizacionTransporte;
      if (respRquest.autorizacionTransporte) {
        const file = values.autorizacionTransporteFile;
        if (isValorValido(values.autorizacionTransporteFile.base64)) {
          file.base64 = values.autorizacionTransporteFile.base64;
          formatoArchivos.push(file);
        } else if (
          isValorValido(values.autorizacionTransporteFile.codigoValidacion)
        ) {
          formatoArchivos.push(file);
        }
      }
    }

    respRquest.archivosEmpresa = formatoArchivos;

    return respRquest;
  } catch (error) {
    throw new Error('ERROR al formatear los valores del servicio');
  }
};
