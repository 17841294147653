import { useContext, useEffect, useState } from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';
import AlertComponent from 'components/design-system/Alert/Alert';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Table from 'components/design-system/Table/Table';
import Text from 'components/design-system/Text/Text';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
  Tooltip
} from '@mui/material';
import { Add, Close, Delete, Edit } from '@mui/icons-material';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { DICTONARY } from 'const/Dictonary';
import { ColumnsTableEmpresa } from '../../components';
import {
  handleConfirmarEliminacionEmpresaAT,
  handleIniciarEliminacion,
  obtenerEmpresasAT
} from '../../hooks/useServices';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';
import { RUTAS } from 'const/Rutas';

const ListaEmpresasAT = () => {
  const { isTienePermisos } = useContext(AuthContext);
  const navigate = useHistory();
  // Loading
  const [isLoading, setIsLoading] = useState(true);
  // Listado
  const [empresasAt, setEmpresasAt] = useState([]);
  //obtener region del usuario
  const { logeduser } = useContext(AuthContext);
  const codigoRegion = logeduser?.region?.codigoRegion;

  // Paginación
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] = useState(DICTONARY.PAGINACION.DEFAULT);
  const [rowCount, setRowCount] = useState(1);

  // Columnas Tabla
  const columnTables = ColumnsTableEmpresa();

  //ELIMINACION DE Empresa AT
  const [openConfirmacion, setOpenConfirmacion] = useState(false);
  const [registroEliminar, setRegistroEliminar] = useState({});
  const [isEliminando, setIsEliminando] = useState(false);

  useEffect(() => {
    obtenerEmpresasAT({
      codigoRegion,
      columnFilters,
      setIsLoading,
      pagination,
      setEmpresasAt,
      setRowCount
    });
  }, [pagination.pageIndex, pagination.pageSize, columnFilters, pagination]);
  console.log(
    'isTienePermisos(PERMISOS.editarEmpresaAT) &&  isTienePermisos(PERMISOS.eliminarEmpresaAT)',
    isTienePermisos(PERMISOS.editarEmpresaAT) &&
      isTienePermisos(PERMISOS.eliminarEmpresaAT)
  );
  return (
    <SidebarLayout>
      <div className="mb-10">
        <div className="col-span-12 mb-5 flex flex-col sm:flex-row gap-5 justify-between">
          <div>
            <Heading type="h2">
              Administración de Empresas Análisis territorial
            </Heading>
            <Text>
              Crea, edita y elimina los registros de empresas de análisis
              territorial que se encuentran registradas en la plataforma
            </Text>
          </div>
          {isTienePermisos(PERMISOS.crearEmpresaAT) && (
            <div className="w-full sm:w-3/6 md:w-2/6 lg:w-auto">
              <ButtonComponent
                type="primary"
                className="w-full xs:w-auto"
                onClick={() => {
                  navigate.push(RUTAS.INGRESAR_EMPRESA_AT);
                }}
              >
                <Add />
                Nueva Empresa AT
              </ButtonComponent>
            </div>
          )}
        </div>

        <Table
          headTable={
            <Heading type="h4" className="p-2">
              Empresas Análisis territorial registradas
            </Heading>
          }
          data={empresasAt}
          columns={columnTables}
          isLoading={isLoading}
          editing={false}
          rowCount={rowCount}
          manualFiltering
          enableGlobalFilter={false}
          enableRowActions={
            isTienePermisos(PERMISOS.editarEmpresaAT) ||
            isTienePermisos(PERMISOS.eliminarEmpresaAT)
          }
          onPaginationChange={setPagination}
          state={{
            columnFilters,
            pagination,
            isLoading,
            showProgressBars: false,
            globalFilter
            // showColumnFilters: false //para mostrar los filtros de columna
          }}
          manualPagination
          enableEditing={false}
          actions={({ row, table }) => (
            <>
              <div className={''}>
                {isTienePermisos(PERMISOS.editarEmpresaAT) && (
                  <Tooltip arrow placement="left" title="Editar">
                    <Link
                      to={`empresa-at/editar/${row.original.codigoEmpresaAT}`}
                    >
                      <IconButtonComponent
                        className="mr-2"
                        disabled={isLoading}
                        size="small"
                        type={isLoading ? 'disabled' : 'secondary'}
                      >
                        <Edit />
                      </IconButtonComponent>
                    </Link>
                  </Tooltip>
                )}
                {isTienePermisos(PERMISOS.eliminarEmpresaAT) && (
                  <Tooltip arrow placement="right" title="Eliminar">
                    <IconButtonComponent
                      disabled={isLoading}
                      size="small"
                      type={isLoading ? 'disabled' : 'error'}
                      onClick={() =>
                        handleIniciarEliminacion({
                          row,
                          table,
                          setRegistroEliminar,
                          setOpenConfirmacion
                        })
                      }
                    >
                      <Delete />
                    </IconButtonComponent>
                  </Tooltip>
                )}
              </div>
            </>
          )}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
        />
      </div>

      <Dialog
        open={openConfirmacion}
        onClose={() => setOpenConfirmacion(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="border-b border-neutral-90"
        >
          <Heading type="h3" className="mb-0">
            Confirmación de eliminación
          </Heading>
        </DialogTitle>
        <DialogContent className="p-5">
          <DialogContentText id="alert-dialog-description" className="pt-4">
            <Text>
              ¿Estás seguro que deseas eliminar completamente la siguiente
              empresa?
            </Text>
            <div className="my-4">
              <div className="flex items-center">
                {/* <Email className="mr-2 text-uv-secondary-10" /> */}
                <Heading type="h5" className="pr-10 mb-0 truncate">
                  {''}
                  {registroEliminar.razonSocial}
                </Heading>
              </div>
            </div>
            <AlertComponent type="error">
              Esta operación es irreversible y{' '}
              <b>la empresa quedará completamente eliminada de la plataforma</b>
            </AlertComponent>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="border-t border-neutral-90 p-4 justify-between">
          <ButtonComponent
            type="secondary"
            onClick={() => setOpenConfirmacion(false)}
            className="justify-center"
          >
            <Hidden smDown>Cancelar</Hidden>
            <Hidden smUp>
              <Close />
            </Hidden>
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            disabled={isEliminando}
            onClick={() =>
              handleConfirmarEliminacionEmpresaAT({
                registroEliminar,
                empresasAt,
                setEmpresasAt,
                setIsLoading,
                setOpenConfirmacion,
                setRegistroEliminar,
                setIsEliminando
              })
            }
            autoFocus
            className="w-full xs:w-auto justify-center"
          >
            Confirmar
          </ButtonComponent>
        </DialogActions>
      </Dialog>
    </SidebarLayout>
  );
};

export default withRouter(ListaEmpresasAT);
