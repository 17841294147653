export const MatchReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_CARGANDO_MATCH':
      return {
        ...state,
        isCargandoMatch: action.payload
      };

    case 'SET_IS_CARGANDO_MATCH_RESIDUOS':
      return {
        ...state,
        isCargandoMatchResiduos: action.payload
      };

    case 'SET_IS_CARGANDO_MATCH_EMPRESAS':
      return {
        ...state,
        isCargandoMatchEmpresas: action.payload
      };

    case 'SET_IS_CARGANDO_MATCH_AMPLIADO':
      return {
        ...state,
        isCargandoMatchAmpliada: action.payload
      };

    case 'SET_MATCH':
      return {
        ...state,
        match: action.payload,
        matchFiltrado: action.payload
      };

    case 'SET_MATCH_EMPRESAS':
      return {
        ...state,
        matchEmpresas: action.payload,
        matchEmpresasFiltrado: action.payload
      };

    case 'SET_MATCH_AMPLIADO':
      return {
        ...state,
        matchAmpliada: action.payload,
        matchAmpliadaFiltrado: action.payload
      };

    case 'SET_ORDENAR_MATCH':
      return {
        ...state,
        ordenarMatch: action.payload
      };

    case 'SET_IS_NUEVOS_CRITERIOS':
      return {
        ...state,
        isNuevosCriterios: action.payload
      };

    case 'SET_STATE':
      return {
        ...state,
        ...action.payload
      };

    case 'SET_MATCH_STEP':
      return {
        ...state,
        matchStep: action.payload
      };

    case 'SET_MATCH_FILTRADO':
      return {
        ...state,
        matchFiltrado: action.payload
      };

    case 'SET_RADIO_BUSQUEDA':
      return {
        ...state,
        radioBusqueda: action.payload
      };

    case 'SET_COORDENADAS_CENTRO_MAPA':
      return {
        ...state,
        coordenadasCentroMapa: action.payload
      };

    case 'SET_SELECCIONAR_ITEM':
      return {
        ...state,
        residuoSeleccionado: action.payload,
        sucursalSeleccionada: null,
        sucursalATSeleccionada: null
      };

    case 'SET_SELECCIONAR_EMPRESA':
      return {
        ...state,
        sucursalSeleccionada: action.payload,
        residuoSeleccionado: null,
        sucursalATSeleccionada: null
      };

    case 'SET_SELECCIONAR_EMPRESAAT':
      return {
        ...state,
        sucursalATSeleccionada: action.payload,
        sucursalSeleccionada: null,
        residuoSeleccionado: null
      };

    case 'SET_SUCURSAL_USUARIO':
      return {
        ...state,
        sucursalUsuario: action.payload
      };

    case 'SET_EMPRESA_SELECCIONADA':
      return {
        ...state,
        empresaSeleccionada: action.payload
      };

    case 'SET_TRANSACCIONES':
      return {
        ...state,
        transacciones: action.payload
      };

    case 'SET_SALAS_CHAT':
      return {
        ...state,
        salasChat: action.payload
      };

    case 'SET_FILTROS_APLICADOS':
      return {
        ...state,
        filtrosAplicados: action.payload
      };

    case 'SET_TAB_ACTIVA':
      return {
        ...state,
        tabActiva: action.payload,
        empresaSeleccionada: null,
        sucursalSeleccionada: null,
        residuoSeleccionado: null,
        sucursalATSeleccionada: null
      };

    default:
      return state;
  }
};
