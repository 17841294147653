import { useContext, useEffect, useReducer, useRef } from 'react';
import { LineaBaseContext } from './LineaBaseContext';
import { LineaBaseReducer } from './LineaBaseReducer';
import commons from 'services/commons';
import { DICTONARY } from 'const/Dictonary';
import { OrdenarAscendente } from 'helpers/manejoArreglos';
import { getCoordenadasMapa } from 'utils';
import { AuthContext } from 'context';

export const LineaBaseProvider = ({ children }) => {
  const {
    perfiles: { isAnalistaTerritorial },
    logeduser
  } = useContext(AuthContext);

  const getInitialState = () => {
    let estadoInicial = {
      isCargando: {
        cargaInicial: true,
        mapaCargando: true,
        tablaEmpresasCargando: true,
        graficoBarrasCargando: true,
        tablaRETCargando: false,
        sectoresEconomicos: false
      },
      sectoresEconomicos: [],
      aplicarFiltros: {
        tipoPeticion: DICTONARY.LINEA_BASE_ESTADOS.DEFAULT,
        codigoEmpresaAT: null
      },
      codigoEmpresaSeleccionada: null,
      coordenadasMapa: getCoordenadasMapa(logeduser),
      mostrarMensajeSinDireccion: false
    };

    if (isAnalistaTerritorial) {
      estadoInicial.aplicarFiltros['codigosRegion'] =
        logeduser?.region?.codigoRegion ??
        DICTONARY.DEFAULT_REGION.REGION_METROPOLITANA;
    }

    return estadoInicial;
  };

  const [state, dispatch] = useReducer(LineaBaseReducer, getInitialState());
  const refTablaRETC = useRef(null);

  const setState = state => {
    dispatch({ type: 'SET_STATE', payload: state });
  };

  const setSectoresEconomicos = sectoresEconomicos => {
    dispatch({ type: 'SET_SECTORES_ECONOMICOS', payload: sectoresEconomicos });
  };

  const setAplicarFiltros = aplicarFiltros => {
    dispatch({ type: 'SET_APLICAR_FILTROS', payload: aplicarFiltros });
  };

  const setCodigoEmpresaSeleccionada = codigoEmpresaSeleccionada => {
    dispatch({
      type: 'SET_CODIGO_EMPRESA_SELECCIONADA',
      payload: codigoEmpresaSeleccionada
    });
  };

  const setCargando = (elementoCargando, estadoCarga) => {
    dispatch({
      type: 'SET_CARGANDO',
      payload: { [elementoCargando]: estadoCarga }
    });
  };

  const setCoordenadasMapa = coordenadas => {
    dispatch({ type: 'SET_COORDENADAS_MAPA', payload: coordenadas });
  };

  useEffect(() => {
    try {
      setCargando('sectoresEconomicos', true);
      commons
        .secoresEconomicos({
          totalPagina: DICTONARY.PAGINACION.ALL_DATA
        })
        .then(response => {
          if (response.status === 200) {
            let sectoresEconomicosSelecionados = [];
            sectoresEconomicosSelecionados = response.data.content.filter(
              sector =>
                !DICTONARY.SECTOR_ECONOMICO.NO_SELECIONADOS_POR_DEFECTO.includes(
                  sector.codigoSectorEconomico
                )
            );

            setSectoresEconomicos(
              OrdenarAscendente(
                response.data.content,
                'nombreSectorEconomico',
                'string'
              )
            );
            setCargando('sectoresEconomicos', false);

            let filtrosIniciales = {
              codigoSectorEconomico: sectoresEconomicosSelecionados.map(
                sector => sector.codigoSectorEconomico
              )
            };
            if (isAnalistaTerritorial) {
              const codigoRegion =
                logeduser?.region?.codigoRegion ||
                DICTONARY.DEFAULT_REGION.REGION_METROPOLITANA;

              filtrosIniciales['codigosRegion'] = codigoRegion;
            }
            setAplicarFiltros(filtrosIniciales);
            setCargando('cargaInicial', false);
          }
        })
        .catch(() => {
          console.log('ERROR cargar sectoresEconomicos');
          setCargando('sectoresEconomicos', false);
          setCargando('cargaInicial', false);
        });
    } catch (error) {
      setCargando('sectoresEconomicos', false);
      setCargando('cargaInicial', false);
      console.log('Error en cargaInicial', error);
    }
  }, []);

  return (
    <LineaBaseContext.Provider
      value={{
        ...state,
        refTablaRETC,

        setState,
        setAplicarFiltros,
        setSectoresEconomicos,
        setCodigoEmpresaSeleccionada,
        setCargando,
        setCoordenadasMapa
      }}
    >
      {children}
    </LineaBaseContext.Provider>
  );
};
