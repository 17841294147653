export const OrdenarAscendente = (arreglo, propiedad, tipo = 'string') => {
  try {
    return arreglo.sort((a, b) => {
      if (tipo === 'string') {
        let nombreA = a[propiedad] ? a[propiedad].toUpperCase() : '-';
        let nombreB = b[propiedad] ? a[propiedad].toUpperCase() : '-';
        return nombreA < nombreB ? 1 : -1;
      }

      if (tipo === 'number') {
        return b[propiedad] - a[propiedad];
      }

      return a < b ? -1 : 1;
    });
  } catch (error) {
    //Si por algún motivo no puedo ordenar retorno el arreglo original
    return arreglo;
  }
};
