import ImgSinChat from 'assets/images/chatvacio.png';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';

export const MensajeSinSala = () => {
  return (
    <div className="grid grid-cols-12 text-center justify-center">
      <div className="col-span-12">
        <div className="pt-3 pb-3">
          <img
            src={ImgSinChat}
            alt="Salas de reunión"
            className="m-auto my-4 h-[40vh]"
          />
          <div className="max-w-md m-auto my-10">
            <Heading type="h2">Sala de reunión</Heading>
            <Text className="mb-4">
              Este espacio esta destinado para que las partes interesadas en una
              transacción puedan <strong>comunicarse y coordinar</strong> los
              detalles del residuo, desecho o subproducto a transaccionar.
            </Text>
            <Text>
              Se recomienda <strong>respaldar</strong> la información contenida
              en las conversaciones que se consideren relevantes.
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
