import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Librerias externas
import moment from 'moment';

// Services
import commonsServices from 'services/commons';
import { DICTONARY } from 'const/Dictonary';
import Table from 'components/design-system/Table/Table';
// Data
import { ColumnsTableLogs } from './data/ColumnsTable';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import Text from 'components/design-system/Text/Text';
import Heading from 'components/design-system/Heading/Heading';
import { AuthContext } from 'context';

const ListadoActividad = props => {
  const {
    logeduser,
    perfiles: { isSuperAdmin, isAdmin }
  } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [listaLogs, setListaLogs] = useState([]);
  const [rowCount, setRowCount] = useState(1);
  const [pagination, setPagination] = useState(DICTONARY.PAGINACION.DEFAULT);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaTermino, setFechaTermino] = useState(null); // Fecha actual
  const [codigoEmpresa, setCodigoEmpresa] = useState(null);

  useEffect(() => {
    if (!logeduser) return;
    setFechaInicio(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    setFechaTermino(moment().format('YYYY-MM-DD'));
  }, []);

  useEffect(() => {
    // Evitar en caso de que los rangos de fechas no hayan sido seteados
    if (!isSuperAdmin) {
      setCodigoEmpresa(logeduser.sucursal.empresa.codigoEmpresa);
    }
    isSuperAdmin && fechaInicio && fechaTermino && cargarListaLogs();

    isAdmin && fechaInicio && fechaTermino && cargarListaLogs();
  }, [
    fechaInicio,
    fechaTermino,
    isAdmin,
    isSuperAdmin,
    pagination.pageIndex,
    pagination.pageSize,
    columnFilters
  ]);

  const cargarListaLogs = async () => {
    setIsLoading(true);

    let query = {};
    let accion = null;
    let nombreSucursal = null;

    if (columnFilters) {
      accion = columnFilters.find(filtro => filtro.id.includes('accion'));
      nombreSucursal = columnFilters.find(filtro =>
        filtro.id.includes('nombreSucursal')
      );
    }

    if (isSuperAdmin && fechaInicio && fechaTermino) {
      query.fechaInicio = fechaInicio;
      query.fechaTermino = fechaTermino;
    }

    if (isAdmin && codigoEmpresa && fechaInicio && fechaTermino) {
      query.fechaInicio = fechaInicio;
      query.fechaTermino = fechaTermino;
      query.codigoEmpresa = codigoEmpresa;
    }

    if (accion) {
      query.accion = accion.value;
    }

    if (nombreSucursal) {
      query.nombreSucursal = nombreSucursal.value;
    }

    if (pagination) {
      query.numPagina = pagination.pageIndex;
      query.totalPagina = pagination.pageSize;
    }

    try {
      const response = await commonsServices.obtieneRegistrosLog(query);

      if (response.status === 200) {
        const { content, totalElements, empty } = response.data;
        if (empty) {
          console.log('No hay logs disponibles ::>');
          setListaLogs([]);
        } else if (Array.isArray(content)) {
          setListaLogs(content);
          setRowCount(totalElements);
        }
      }
    } catch (error) {
      setListaLogs([]);
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  // Columnas de la Tabla
  const columnTables = ColumnsTableLogs();

  return (
    <SidebarLayout>
      <div className="mb-10">
        <div className="mb-5">
          <Heading type="h2">Registro de actividad</Heading>
          <Text>
            Historial de actividad de los últimos 30 dias registrada por los
            usuarios en la plataforma.
          </Text>
        </div>

        <div className="px-4 md:container md:mx-auto">
          <Table
            headTable={
              <Heading type="h4" className="p-2">
                Actividades registradas
              </Heading>
            }
            columns={columnTables}
            data={listaLogs}
            rowCount={rowCount}
            isLoading={isLoading}
            enablePagination={true}
            enableGlobalFilter={false}
            enableEditing={false}
            onPaginationChange={setPagination}
            state={{
              columnFilters,
              pagination,
              isLoading,
              showProgressBars: false,
              globalFilter,
              columnVisibility: {
                'usuario.sucursal.empresa.nombreEmpresa': isSuperAdmin
                  ? true
                  : false
              }
            }}
            manualPagination
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
          />
        </div>
      </div>
    </SidebarLayout>
  );
};

ListadoActividad.propTypes = {
  history: PropTypes.object
};

export default withRouter(ListadoActividad);
