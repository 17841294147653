import { useEffect, useState } from 'react';

import {
  ObtieneListaLer,
} from 'hooks';

// Utils
import {
  CalculoListaLer,
  CalculoListaSubLer,
} from 'utils/useUtilidades';


export const useNombreLER = ({
  values,
  setFieldValue,
}) => {
  const [tieneSubSubtiposLer, setTieneSubSubtiposLer] = useState(true);
  // SERVICIO
  const {
    calculoListaSubLer,
    listaLerNivelDos,
    listaLerNivelTres,
    // tieneSubtiposLer,
    setListaLerNivelTres
  } = CalculoListaLer();

  // SERVICIO nombresLER
  const {
    listaLer,
    cargandoListaLer,
    // handlelistaler,
    listaLerNivelUno,
    listaLerNivelDosEdit,
    listaLerNivelUnoEdit,
    listaLerNivelTresEdit,
  } = ObtieneListaLer();


  // Calculo lista SubLer
  const { calculoListaSubSubLer } = CalculoListaSubLer();


  // Lena selectores de LER para edición
  useEffect(() => {
    if (
      listaLerNivelDosEdit.length &&
      values.nombreSubSubLER?.codigoNivelAnterior
    ) {
      const nivelDosEdit = listaLerNivelDosEdit.find(
        ler => ler.codigoLER === values.nombreSubSubLER?.codigoNivelAnterior
      );
      setFieldValue('nombreSubLER', nivelDosEdit);
    }
  }, [
    listaLer.length,
    listaLerNivelDos,
    listaLerNivelDosEdit,
    setFieldValue,
    values.nombreSubSubLER?.codigoLER,
    values.nombreSubSubLER?.codigoNivelAnterior
  ]);

  // Lena selectores de LER para edición
  useEffect(() => {
    if (
      listaLerNivelUnoEdit.length &&
      values.nombreSubLER?.codigoNivelAnterior
    ) {
      const nivelUnoEdit = listaLerNivelUnoEdit.find(
        ler => ler.codigoLER === values.nombreSubLER.codigoNivelAnterior
      );
      setFieldValue('nombreLER', nivelUnoEdit);
    }
  }, [
    listaLer.length,
    listaLerNivelUno,
    listaLerNivelUnoEdit,
    setFieldValue,
    values.nombreSubLER?.codigoLER,
    values.nombreSubLER?.codigoNivelAnterior
  ]);

  return {
    calculoListaSubLer,
    calculoListaSubSubLer,
    setListaLerNivelTres,
    setTieneSubSubtiposLer,
    cargandoListaLer,
    listaLer,
    listaLerNivelDos,
    listaLerNivelDosEdit,
    listaLerNivelTres,
    listaLerNivelTresEdit,
    listaLerNivelUno,
    listaLerNivelUnoEdit,
    tieneSubSubtiposLer,
  }
}
