import { DICTONARY } from 'const/Dictonary';

export const numeroBaseDatosAFront = numero => {
  //Reemplaza formato ingles 000,000.00 a 000.000,00
  if (!numero) return 0;

  return new Intl.NumberFormat('es-CL', {
    maximumFractionDigits: DICTONARY.NUMERO_DECIMALES
  }).format(numero);
};

export const formatoANumeroIngles = numero => {
  if (!numero) return 0;
  let stringNumero = numero.replaceAll(',', '');

  let numeroFormateado = parseFloat(stringNumero);
  let nueroFormatoChileno = new Intl.NumberFormat('es-CL', {
    maximumFractionDigits: DICTONARY.NUMERO_DECIMALES_HOME
  }).format(numeroFormateado);

  return nueroFormatoChileno;
};

export const numeroFrontABaseDatos = numero => {
  //Reemplaza formato ingles 000.000,00 a 000000.00
  if (typeof numero === 'string') {
    numero = numero.replaceAll('.', '').replaceAll(',', '.');
    // eliminar signo $
    if (numero.includes('$')) numero = numero.replace('$', '');
    numero = parseFloat(numero);
  }
  return numero;
};

/**
 *  Formatea los datos recibidos para que se vean con separador de miles
 * @param   {Object} data (datos de los servicios)
 * @returns {Object} datosFormateados
 */
export const formateoDataSeparadorMiles = data => {
  const datosFormateados = {};
  for (const clave in data) {
    if (data.hasOwnProperty(clave)) {
      datosFormateados[clave] =
        typeof data[clave] === 'number'
          ? data[clave].toLocaleString('es-CL')
          : data[clave];
    }
  }

  return datosFormateados;
};

export const formateaNumeroPuntosPorDecimales = numero => {
  if (!numero) return 0;
  // transformar a string
  numero = numero.toString();
  let stringNumero = numero.replaceAll('.', ',');
  let numeroFormateado = stringNumero;
  return numeroFormateado;
};
