import ValidateRut from 'helpers/validateRut';
import * as yup from 'yup';

const SchemaGenerador = yup.object().shape({
  rutEmpresaGenerador: yup // Integer (max 12), obligatorio
    .string()
    .trim()
    .matches(/^[^.\s]*$/, 'No se permiten puntos en este campo')
    .max(12, 'La cantidad máxima de caracteres es 11')
    .min(9, 'La cantidad mínima de caracteres es 9')
    .matches(
      /^[0-9]+[-|‐]{1}[0-9kK]{1}$/,
      'El rut debe ser del tipo 12345678-9'
    )
    .test(
      'Evaluación de rut',
      'El rut ingresado es inválido',
      value => !value || ValidateRut.isValid(value)
    )
    .required('Este campo es obligatorio'),

  nombreEmpresaGenerador: yup
    .string()
    .trim()
    .max(128, 'La cantidad máxima de caracteres es 128')
    .min(2, 'La cantidad mínima de caracteres es 2')
    .required('Este campo es obligatorio')

  /* regionEmpresaGenerador: yup
    .object()
    .nullable()
    .test(
      'Evaluación de region',
      'regionEmpresaGenerador Este campo es obligatorio',
      async (value, { parent }) => {
        if (parent.regionEmpresaGenerador) {
          return true;
        }
        const tieneDatos =
          parent.comunaEmpresaGenerador ||
          (parent.nombreViaEmpresaGenerador &&
            parent.nombreViaEmpresaGenerador.length > 0);
        return tieneDatos;
      }
    ),

  comunaEmpresaGenerador: yup
    .object()
    .nullable()
    .test(
      'Evaluación de comuna',
      'comunaEmpresaGenerador Este campo es obligatorio',
      async (value, { parent }) => {
        return true;
        return (
          parent.regionEmpresaGenerador || parent.nombreViaEmpresaGenerador
        );
      }
    ),

  nombreViaEmpresaGenerador: yup
    .string()
    .nullable()
    .max(100, 'La cantidad máxima de caracteres es 100')
    .min(3, 'La cantidad mínima de caracteres es 3')
    .test(
      'Evaluación de nombre via',
      'nombreViaEmpresaGenerador Este campo es obligatorio',
      async (value, { parent }) => {
        if (
          parent.nombreViaEmpresaGenerador &&
          parent.nombreViaEmpresaGenerador.length > 0
        ) {
          return true;
        }

        return parent.regionEmpresaGenerador || parent.comunaEmpresaGenerador;
      }
    ) */
});

export { SchemaGenerador };
