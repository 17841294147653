import { useDireccion } from 'hooks/useDireccion';
import { useMemo } from 'react';

export const useDireccionDemandante = ({
  values,
  isSubmitting,
  touched,
  setFieldValue
}) => {
  const {
    latLon: latLonDemandante,
    setLatLon: setLatLonDemandante,
    addressQuerysearch: addressQuerysearchDemandante,
    addressQueryParams: addressQueryParamsDemandante,
    regiones: regionesDemandante,
    cargaListaRegiones: cargaListaRegionesDemandante,
    handleObtenerDireccionDemandante,
    handleObtenerComunas: handleObtenerComunasDemandante,
    listadoComunas: listadoComunasDemandante,
    cargaListaComunas: cargaListaComunasDemandante,
    setBuscandoDireccionDemandante,
    buscandoDireccionDemandante
  } = useDireccion();

  //  Servicio Comunas DemansetLatLonDemandante
  useMemo(() => {
    if (!values.regionDemandante) return;
    if (values.regionDemandante && values.regionDemandante.codigoRegion) {
      handleObtenerComunasDemandante(values.regionDemandante.codigoRegion);
    }
  }, [handleObtenerComunasDemandante, values.regionDemandante]);

  // Estados de carga de servicios
  useMemo(() => {
    if (isSubmitting) return;
    handleObtenerDireccionDemandante(values);
  }, [isSubmitting, handleObtenerDireccionDemandante, values]);

  // Verificacion de cambios en los campos de direccion
  useMemo(() => {
    const {
      nombreViaDemandante,
      comunaDemandante,
      regionDemandante,
      isPointEditingDemandante
    } = values;

    if (isSubmitting || !latLonDemandante) return;

    const hasNombreVia = nombreViaDemandante && touched.nombreViaDemandante;
    const hasComuna = comunaDemandante && touched.comunaDemandante;
    const hasRegion = regionDemandante && touched.regionDemandante;

    if (hasNombreVia || hasComuna || hasRegion || isPointEditingDemandante) {
      setBuscandoDireccionDemandante(true);
      setFieldValue('latitudDireccionDemandante', latLonDemandante[0]);
      setFieldValue('longitudDireccionDemandante', latLonDemandante[1]);
    }
  }, [
    isSubmitting,
    latLonDemandante,
    setBuscandoDireccionDemandante,
    setFieldValue,
    touched.comunaDemandante,
    touched.nombreNumeroViaDemandante,
    touched.regionDemandante,
    values
  ]);

  return {
    latLonDemandante,
    setLatLonDemandante,
    addressQuerysearchDemandante,
    addressQueryParamsDemandante,
    regionesDemandante,
    cargaListaRegionesDemandante,
    handleObtenerDireccionDemandante,
    handleObtenerComunasDemandante,
    listadoComunasDemandante,
    cargaListaComunasDemandante,
    setBuscandoDireccionDemandante,
    buscandoDireccionDemandante
  };
};
