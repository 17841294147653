import ChevronRight from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden
} from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import { useState } from 'react';
import residuosServices from 'services/residuos';
import { Alerta, AlertaError, AlertaExito } from 'utils/Alertas';

export const DialogoSolicitarCertificado = ({ isOpen, setIsOpen, residuo }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSolicitarCertificado = async event => {
    if (!email || !email.includes('@') || !email.includes('.')) {
      AlertaError('El correo electrónico no es válido');
      return;
    }

    setIsLoading(true);

    try {
      const result = await residuosServices.solicitarCertificado({
        codigoResiduoDeclarado: residuo.codigo,
        correo: email
      });

      if (result.data?.error) {
        Alerta(result.data.error.codigo);
        return; // Asegúrate de salir si hay un error
      }

      if (result.status === 200) {
        AlertaExito('Se ha solicitado el certificado correctamente.');
        setIsOpen(false);
      }
    } catch (error) {
      Alerta('undefined');
    } finally {
      setIsLoading(false); // Asegura que siempre se deshabilite el estado de carga
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={isLoading ? () => {} : () => setIsOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        className="border-b border-neutral-90 lg:min-w-[512px]"
      >
        <Heading type="h3" className="mb-0">
          Solicitar certificado
        </Heading>
      </DialogTitle>
      <DialogContent className="p-5">
        <DialogContentText id="alert-dialog-description" className="pt-4">
          <InputComponent
            fullWidth
            label={<LabelComponent>Correo de contacto</LabelComponent>}
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="nombre@email.com"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions className="border-t border-neutral-90 p-4 justify-between">
        <ButtonComponent
          type="secondary"
          disabled={isLoading}
          onClose={isLoading ? () => {} : () => setIsOpen(false)}
          className="justify-center"
        >
          <Hidden smDown>Cancelar</Hidden>
          <Hidden smUp>
            <Close />
          </Hidden>
        </ButtonComponent>
        <ButtonComponent
          type="primary"
          onClick={handleSolicitarCertificado}
          endIcon={<ChevronRight />}
          autoFocus
          className="w-full xs:w-auto justify-center h-auto"
          disabled={!email || isLoading}
          isLoading={isLoading}
        >
          {isLoading ? 'Cargando' : 'Solicitar'}
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};
