import { useState } from 'react';
import ButtonComponent from 'components/design-system/Button/Button';
import LabelComponent from 'components/design-system/Label/Label';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Text from 'components/design-system/Text/Text';
import AlertComponent from 'components/design-system/Alert/Alert';
import Heading from 'components/design-system/Heading/Heading';
import { DICTONARY } from 'const/Dictonary';
import { useFactoresConversion } from 'hooks/useFactoresConversion';

export const TablaConversion = () => {
  const [open, setOpen] = useState(false);
  const { isCargando, factoresConversion } = useFactoresConversion();

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <LabelComponent>Tabla de conversión:</LabelComponent>
      <AlertComponent type="info">
        <Text className="mb-2 text-info-dark">
          De preferencia utilizar la unidad <strong>Kg y/o Ton</strong>, ya que
          para el <strong>cálculo de indicadores y reporte SINADER</strong> son
          las <strong>unidades por defecto</strong>. En el caso de ingresar el
          residuo <strong>con otra opción</strong> de la lista este valor será{' '}
          <strong>convertido de forma automática</strong> por la plataforma{' '}
          <strong>al momento de utilizarlo</strong>, la conversión será de
          acuerdo a los factores que manejamos en nuestra base de datos la cual
          cambia dado el <strong>residuo seleccionado</strong>. Puede revisar
          una tabla con los factores de conversión aquí.
        </Text>
        <ButtonComponent
          onClick={() => {
            setOpen(true);
          }}
        >
          Ver tabla
        </ButtonComponent>
      </AlertComponent>
      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="border-b border-neutral-90"
        >
          <Heading type="h3" className="mb-0">
            Tabla de conversión
          </Heading>
        </DialogTitle>
        <DialogContent className="p-5">
          <div className="grid grid-cols-12 gap-5 my-5">
            <div className="col-span-12">
              <Text>
                Si <strong>no existe</strong> un factor de conversión este
                registro <strong>no será considerado</strong> en los{' '}
                <strong>indicadores</strong> y el campo cantidad no aparecerá en
                el <strong>reporte SINADER</strong>. En el caso de que exista
                una conversión que no se indique en la tabla, por favor{' '}
                <strong>enviar los datos</strong> mediante el{' '}
                <strong>botón de Ayuda.</strong>
              </Text>
            </div>
            <div className="col-span-12">
              <Table
                size="small"
                aria-label="a dense table"
                className="border rounded shadow-md bg-white"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className="font-secondary text-black text-sm">
                      Residuo / Sub tipo
                    </TableCell>
                    <TableCell className="font-secondary text-black text-sm">
                      Unidades
                    </TableCell>
                    <TableCell className="font-secondary text-black text-sm">
                      Factor
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isCargando ? (
                    <>
                      {DICTONARY.SKELETON.DUMMY_10.map((factor, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Skeleton className="w-full h-6 rounded" />
                          </TableCell>
                          <TableCell>
                            <Skeleton className="w-full h-6 rounded" />
                          </TableCell>
                          <TableCell>
                            <Skeleton className="w-full h-6 rounded" />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <>
                      {factoresConversion.map((factor, index) => (
                        <TableRow key={index}>
                          <TableCell className="font-primary text-black text-xs">
                            {factor?.tipoResiduo?.nombreTipo || ''}
                          </TableCell>
                          <TableCell className="font-primary text-black text-xs">
                            {factor?.unidadMedidaOrigen?.nombreUnidadMedida ||
                              ''}{' '}
                            →{' '}
                            {factor?.unidadMedidaDestino?.nombreUnidadMedida ||
                              ''}
                          </TableCell>
                          <TableCell className="font-primary text-black text-xs">
                            {factor?.factorConversion}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="border-t border-neutral-90 p-4 justify-between">
          <ButtonComponent
            type="secondary"
            onClick={handleClickClose}
            className="justify-center"
          >
            Cerrar
          </ButtonComponent>
        </DialogActions>
      </Dialog>
    </>
  );
};
