import { Divider, IconButton, Tooltip } from '@mui/material';
import Heading from 'components/design-system/Heading/Heading';
import { FiltroForm } from './FiltroForm/FiltroForm';
import { MapaBase } from './MapaBase/MapaBase';
import { TablaLineaBase } from './Tabla/TablaLineaBase';
import { TablaRETC } from './Tabla/TablaRETC';
import { GraficoBarras } from './GraficoBarras/GraficoBarras';
import { Walktour } from 'walktour';
import { useContext } from 'react';
import { LineaBaseContext } from './context/LineaBaseContext';
import { Help } from '@mui/icons-material';
import { useTour } from './hooks/useTour';

const LineaBase = () => {
  const { refTablaRETC } = useContext(LineaBaseContext);
  const {
    TOUR_STEPS,
    TOUR_DATA,
    mostrarTour,
    setMostrarTour,
    handleOcultarGuia
  } = useTour();

  return (
    <div className="w-full">
      <Walktour
        steps={TOUR_DATA}
        isOpen={mostrarTour}
        orientationPreferences={['north', 'west']}
        prevLabel="Anterior"
        nextLabel="Siguiente"
        closeLabel="Cerrar"
        nextOnTargetClick={false}
        disableMaskInteraction={true}
        customCloseFunc={handleOcultarGuia}
      />

      <div className="bg-white shadow-md py-5">
        <div className="px-4 md:container md:mx-auto" id={TOUR_STEPS.FILTROS}>
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <Heading
                type="h2"
                className="text-uv-primary-0 flex content-center items-center"
              >
                <span>Análisis línea base</span>
                <Tooltip title="Ayuda">
                  <IconButton
                    className="ml-3"
                    onClick={() => setMostrarTour(true)}
                  >
                    <Help className="!text-uv-secondary-0" />
                  </IconButton>
                </Tooltip>
              </Heading>
            </div>
          </div>
          <FiltroForm />
        </div>
      </div>
      <div className="px-4 md:mx-auto py-6">
        <div className="grid grid-cols-12 gap-4">
          <div
            className="col-span-12 lg:col-span-6"
            id={TOUR_STEPS.MAPA_EMPRESAS}
          >
            <MapaBase />
          </div>
          <div
            className="col-span-12 lg:col-span-6"
            id={TOUR_STEPS.TABLA_EMPRESAS}
          >
            <TablaLineaBase />
          </div>
          <div className="col-span-12 lg:col-span-6 h-full">
            <div className="bg-white shadow-md rounded h-full">
              <div className="py-4 px-5">
                <Heading type="h4" className="text-uv-primary-0 m-0">
                  Distribución de N° de empresas y toneladas de residuos por
                  provincia
                </Heading>
              </div>
              <Divider />
              <div className="min-h-max" id={TOUR_STEPS.GRAFICO_DISTRIBUCION}>
                <GraficoBarras />
              </div>
            </div>
          </div>
          <div
            className="col-span-12 lg:col-span-6 h-full"
            ref={refTablaRETC}
            id={TOUR_STEPS.TABLA_RESIDUOS}
          >
            <TablaRETC />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineaBase;
