import { useContext, useEffect, useState } from 'react';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
import {
  ObtieneListaRecursos,
  ObtieneListaSucursales,
  ValidaSiExisteRecurso
} from 'hooks';
import { Place } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { RecursoDistinto } from '../../Inputs/RecursoDistinto';
import { useResiduos } from 'views/Residuos/hooks/Selectores/useResiduos';
import { useRecursos } from 'views/Residuos/hooks/Selectores/useRecursos';
import { useRecursosReemplazos } from 'views/Residuos/hooks/Selectores/useRecursosReemplazos';
import { DICTONARY } from 'const/Dictonary';
import { ObtieneCodigoRecursoExiste } from 'hooks/useServices';
import { AuthContext } from 'context';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { PERMISOS } from 'const/Permisos';
import { isValorNulo } from 'helpers/validaCampos';

export const FormularioReceptor = ({
  errors,
  handleBlur,
  handleChange,
  handleReset,
  handleSubmit,
  isSubmitting,
  setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  validateForm,
  values,
  // Props adicionales
  isLoading,
  setStateValidation,
  isNombreRolDemandante,
  tipoFormulario,
  tipoFormularioSeleccionado,
  ...restProps
}) => {
  const {
    perfiles: { isSuperAdmin },
    logeduser
  } = useContext(AuthContext);

  const [tieneTipoRecursos, setTieneTipoRecursos] = useState(false);
  const { ingresar, editar, duplicar } = tipoFormularioSeleccionado;

  const [mensajeRecursoExiste, setMensajeRecursoExiste] = useState(false);

  const getCodigoEmpresa = () => {
    const codigoEmpresaInter = logeduser?.sucursal?.empresa.codigoEmpresa;
    const codigoEmpresa =
      editar || duplicar
        ? isSuperAdmin
          ? values?.empresa?.codigoEmpresa
          : !isSuperAdmin
          ? values?.empresa?.codigoEmpresa
          : null
        : ingresar
        ? codigoEmpresaInter
        : null;

    return codigoEmpresa;
  };

  const { listaSucursales, cargandoListaSucursales } = ObtieneListaSucursales(
    getCodigoEmpresa()
  );

  const {
    listaSucursales: sucursales,
    cargandoListaSucursales: isLoadingSucursales
  } = ObtieneListaSucursales(getCodigoEmpresa());

  const {
    calculoSubTipoResiduos,
    setCargandoListaSubTipoResiduos,
    setTieneTipoResiduos,
    cargandoListaSubTipoResiduos,
    cargandoListaTipoResiduos,
    listaSubTipoResiduos,
    listaTipoResiduoNivelDosEdit,
    listaTipoResiduoNivelUnoEdit, // No se usa actualmente
    listaTipoResiduos, // No se usa actualmente
    listaTipoResiduosNivelUno,
    tieneTipoResiduos // No se usa actualmente
  } = useResiduos();

  const {
    listaTipoRecursos,
    cargandoListaTipoRecursos,
    handleDeleteMateriaPrima,
    handleAddOtraMateriaPrima,
    handleOtraMateriaKeyPress
  } = useRecursos({
    codigosTipoResiduo: values.subTipoResiduo?.codigoTipoResiduo,
    tieneTipoRecursos
  });

  const {
    listaTipoRecursoReemplazo,
    cargandoListaTipoRecursoReemplazo,
    listaSubTipoRecursoReemplazo,
    cargandoListaSubTipoRecursoReemplazo,
    calculoSubTipoRecursosReeplazo
  } = useRecursosReemplazos();

  useEffect(() => {
    //PRECARGA CAMPOS RECURSO QUE REEMPLAZA EN EDICION
    if (
      values.subTipoRecursoReemplazo &&
      isValorNulo(values.recursoReemplazo)
    ) {
      const recursoReemplazoEncontrado = listaTipoRecursoReemplazo.find(
        recurso =>
          recurso.codigoRecursoReemplazo ===
          values.subTipoRecursoReemplazo.codigoNivelAnteriorReemplazo
      );
      if (recursoReemplazoEncontrado) {
        setFieldValue('recursoReemplazo', recursoReemplazoEncontrado);
      }
    }
  }, [listaTipoRecursoReemplazo]);

  useEffect(() => {
    if (
      listaTipoResiduos &&
      values.subTipoResiduo &&
      listaTipoResiduosNivelUno
    ) {
      const subtipoResiduoAux = listaTipoResiduos.find(
        residuo =>
          residuo.codigoTipoResiduo === values.subTipoResiduo.codigoTipoResiduo
      );
      if (subtipoResiduoAux) {
        const tipoResiduoAux = listaTipoResiduosNivelUno.find(
          residuo =>
            residuo.codigoTipoResiduo ===
            subtipoResiduoAux.codigoNivelAnteriorTipoResiduo
        );
        setFieldValue('tipoResiduo', tipoResiduoAux);
        setFieldValue('subTipoResiduo', subtipoResiduoAux);
      }
    }
  }, [
    listaTipoResiduoNivelUnoEdit,
    listaSubTipoResiduos,
    setFieldValue,
    values.subTipoResiduo
  ]);

  const { listaRecursos } = ObtieneListaRecursos();

  useEffect(() => {
    setMensajeRecursoExiste(false);
    if (values.otroTipoRecurso && values.otroTipoRecurso.length > 0) {
      // eliminar de la lista de otros recursos los que tenga el mismo nombreRecruso de la lista tipoRecurso
      const obtieneNombreUltimoOtroTipoRecurso =
        values.otroTipoRecurso[values.otroTipoRecurso.length - 1].nombreRecurso;

      const existe = ValidaSiExisteRecurso({
        listaRecursos: listaRecursos,
        nombre: obtieneNombreUltimoOtroTipoRecurso
      });

      if (existe) {
        // eliminar registro que existe en la bd de la lista de otros recursos
        // const
        setFieldValue(
          'otroTipoRecurso',
          values.otroTipoRecurso.filter(
            recurso =>
              recurso.nombreRecurso !== obtieneNombreUltimoOtroTipoRecurso
          )
        );
        const { codigoRecurso, nombreRecurso } = ObtieneCodigoRecursoExiste({
          listaRecursos: listaRecursos,
          nombre: obtieneNombreUltimoOtroTipoRecurso
        });
        // que sea unico el recurso
        // los tipos de recursos deben ser unicos
        const listaTipoRecurso = values.tipoRecurso.map(
          tipoRecurso => tipoRecurso.nombreRecurso
        );
        const existeTipoRecurso = listaTipoRecurso.includes(
          obtieneNombreUltimoOtroTipoRecurso
        );

        console.log(existeTipoRecurso);
        if (existeTipoRecurso)
          return setMensajeRecursoExiste(
            'El recurso ya existe en la lista de recursos'
          );

        setFieldValue('tipoRecurso', [
          ...values.tipoRecurso,
          { codigoRecurso: codigoRecurso, nombreRecurso: nombreRecurso }
        ]);
        // eliminar de la lista de otros recursos los que tenga el mismo nombreRecruso de la lista tipoRecurso
      }
    }
  }, [listaRecursos, values.otroTipoRecurso, setFieldValue]);

  const getIcon = tipo => {
    const BASE = DICTONARY.ESTADO[tipo.toUpperCase()];
    return <BASE.ICONO className="mr-1 text-lg" />;
  };

  useEffect(() => {
    if (values.tipoRecurso.length > 0) {
      setTieneTipoRecursos(true);
    }
  }, [setFieldValue, values.tipoRecurso]);

  useEffect(() => {
    if (listaSucursales.length === 1) {
      const sucursal = listaSucursales[0];
      setFieldTouched('codigoSucursal', true);
      setFieldValue('codigoSucursal', sucursal.codigoSucursal);
      setFieldValue('nombreSucursal', sucursal.nombreSucursal);
      setFieldValue(
        'nombreDireccionSucursal',
        sucursal.direccion.nombreVia +
          ', ' +
          sucursal.direccion.comuna.nombreComuna +
          ', Región ' +
          sucursal.direccion.comuna.region.nombreRegion
      );
    }
  }, [listaSucursales, setFieldTouched, setFieldValue]);

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Heading type="h4" className="mb-0 text-uv-primary-10">
            Identificación del residuo (incluye subproductos) o recurso
          </Heading>
        </div>
      </div>
      <div className="col-span-12 xs:col-span-12">
        {/* Fila 1 */}
        <div className="grid grid-cols-12 gap-4 align-middle mt-2">
          {/* Sucursal de origen */}
          <VerificarPermisos nombrePermiso={PERMISOS.sucursalOrigenEmpresa}>
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                accesor="nombreSucursal"
                clearOnEscape={true}
                disabled={
                  isSubmitting ||
                  isLoadingSucursales ||
                  listaSucursales.length === 0
                }
                fullWidth
                name={'sucursal'}
                options={sucursales || []}
                value={values.sucursal || ''}
                onChange={(event, newValue, reason) => {
                  let data = { name: 'sucursal', value: newValue };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  const direccion = newValue ? newValue.direccion : {};
                  const nombreDireccion = `${direccion.nombreVia}, ${direccion.comuna?.nombreComuna}, Región ${direccion.comuna?.region?.nombreRegion} `;

                  setFieldValue('nombreDireccionSucursal', nombreDireccion);

                  setFieldValue('sucursal', data.value);
                  setFieldTouched('fechaTransaccion');
                }}
                getOptionLabel={option => option.nombreSucursal || ''}
                noOptionsText={
                  isLoadingSucursales ? 'Cargando...' : 'Sin opciones'
                }
              >
                <InputComponent
                  autoComplete="off"
                  disabled={isSubmitting || isLoadingSucursales}
                  fullWidth
                  onBlur={() => {
                    setFieldTouched('sucursal');
                  }}
                  touched={touched.sucursal}
                  placeholder={
                    isLoadingSucursales
                      ? 'Cargando...'
                      : 'Selecciona una opción'
                  }
                  estado={
                    errors.sucursal
                      ? { tipo: 'error', mensaje: errors.sucursal }
                      : null
                  }
                  label={<LabelComponent>Sucursal de origen</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>

            {/* Dirección */}
            <div className="col-span-12 xs:col-span-8 pl-2 pt-1  mt-7">
              <div className="address-icon ">
                <Place className="text-uv-secondary-0" />
                <Text>
                  {values.nombreDireccionSucursal
                    ? values.nombreDireccionSucursal
                    : 'Seleccionar sucursal'}
                </Text>
              </div>
            </div>
          </VerificarPermisos>
          <VerificarPermisos nombrePermiso={PERMISOS.sucursales}>
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                accesor="nombreSucursal"
                clearOnEscape={true}
                disabled={
                  isSubmitting ||
                  isLoadingSucursales ||
                  listaSucursales.length === 0
                }
                fullWidth
                name={'sucursal'}
                options={sucursales || []}
                value={values.sucursal || ''}
                onChange={(event, newValue, reason) => {
                  let data = { name: 'sucursal', value: newValue };
                  if (reason === 'clear') {
                    data.value = '';
                  }
                  const direccion = newValue ? newValue.direccion : {};
                  const nombreDireccion = `${direccion.nombreVia}, ${direccion.comuna?.nombreComuna}, Región ${direccion.comuna?.region?.nombreRegion} `;

                  setFieldValue('nombreDireccionSucursal', nombreDireccion);

                  setFieldValue('sucursal', data.value);
                  setFieldTouched('fechaTransaccion');
                }}
                getOptionLabel={option => option.nombreSucursal || ''}
                noOptionsText={
                  isLoadingSucursales ? 'Cargando...' : 'Sin opciones'
                }
              >
                <InputComponent
                  autoComplete="off"
                  disabled={isSubmitting || isLoadingSucursales}
                  fullWidth
                  onBlur={() => {
                    setFieldTouched('sucursal');
                  }}
                  touched={touched.sucursal}
                  placeholder={
                    isLoadingSucursales
                      ? 'Cargando...'
                      : 'Selecciona una opción'
                  }
                  estado={
                    errors.sucursal
                      ? { tipo: 'error', mensaje: errors.sucursal }
                      : null
                  }
                  label={<LabelComponent>Sucursal de origen</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>

            {/* Dirección */}
            <div className="col-span-12 xs:col-span-8 pl-2 pt-1  mt-7">
              <div className="address-icon ">
                <Place className="text-uv-secondary-0" />
                <Text>
                  {values.nombreDireccionSucursal
                    ? values.nombreDireccionSucursal
                    : 'Seleccionar sucursal'}
                </Text>
              </div>
            </div>
          </VerificarPermisos>
        </div>

        {/* Fila 2 */}
        <div className="grid grid-cols-12 gap-4 mt-4">
          <VerificarPermisos nombrePermiso={PERMISOS.tipoResiduo}>
            {/* Tipo Residuo */}
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                accesor="nombreTipo"
                className="mb-3 "
                clearOnEscape={true}
                disabled={isSubmitting}
                fullWidth
                loading={cargandoListaTipoResiduos}
                loadingText={cargandoListaTipoResiduos && 'Cargando...'}
                name="tipoResiduo"
                openOnFocus={true}
                options={listaTipoResiduosNivelUno || []}
                size="small"
                value={values.tipoResiduo || ''}
                variant="outlined"
                getOptionLabel={option => option.nombreTipo || ''}
                onChange={(ev, selected) => {
                  if (!selected) {
                    setCargandoListaSubTipoResiduos(true);
                    // setSubTipoRSDdisabled(true);
                    setTieneTipoResiduos(false);
                    setFieldValue('tieneSubTipoResiduo', false);
                  }

                  // Limpieza subTipoResiduo
                  setFieldValue('subTipoResiduo', []);
                  // Limpieza tipoRecurso
                  setFieldValue('tipoRecurso', []);
                  setFieldTouched('nombreResiduo');

                  selected
                    ? setFieldValue('tipoResiduo', {
                        codigoTipoResiduo: selected.codigoTipoResiduo,
                        nombreTipo: selected.nombreTipo
                      })
                    : setFieldValue('tipoResiduo', []);

                  // Calculo Sub Tipos de Residuos
                  selected &&
                    calculoSubTipoResiduos(
                      selected.codigoTipoResiduo,
                      setFieldValue
                    );

                  selected && setFieldTouched('subTipoResiduo');
                }}
              >
                <InputComponent
                  fullWidth
                  onBlur={() => setFieldTouched('tipoResiduo')}
                  touched={touched.tipoResiduo}
                  placeholder={
                    listaTipoResiduosNivelUno.length
                      ? 'Seleccione tipo de residuo'
                      : ''
                  }
                  estado={
                    errors.tipoResiduo
                      ? {
                          tipo: 'error',
                          mensaje: errors.tipoResiduo
                        }
                      : null
                  }
                  label={
                    <LabelComponent>
                      Residuo
                      <br />
                      <span className="text-neutral-60">
                        (incluye subproductos)
                      </span>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>

            {/* Sub Tipo Residuo */}
            {/* Subtipo de residuo */}
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                accesor="nombreTipo"
                className="mb-3 "
                clearOnEscape={true}
                disabled={
                  tipoFormularioSeleccionado.ingresar
                    ? values.tipoResiduo.length === 0
                    : !values.tipoResiduo
                }
                loading={cargandoListaSubTipoResiduos}
                name="subTipoResiduo"
                openOnFocus={false}
                options={listaTipoResiduoNivelDosEdit.filter(
                  tipoRSD =>
                    tipoRSD?.codigoNivelAnteriorTipoResiduo ===
                    values.tipoResiduo?.codigoTipoResiduo
                )}
                size="small"
                variant="outlined"
                noOptionsText={
                  cargandoListaSubTipoResiduos ? 'Cargando...' : 'Sin opciones'
                }
                value={values.subTipoResiduo || ''}
                getOptionLabel={option => option.nombreTipo || ''}
                onChange={(ev, selected) => {
                  selected
                    ? setFieldValue('subTipoResiduo', {
                        codigoTipoResiduo: selected.codigoTipoResiduo,
                        nombreTipo: selected.nombreTipo
                      })
                    : setFieldValue('subTipoResiduo', []);

                  // Limpieza tipoRecurso
                  setFieldValue('tipoRecurso', []);
                  setFieldValue('otroTipoRecurso', []);
                  setFieldValue('_otraMateriaPrima', '');

                  if (selected && selected.recursos.length > 0) {
                    setFieldValue('tieneTipoRecursos', true);
                    setTieneTipoRecursos(true);
                    setFieldTouched('tipoRecurso');
                  } else {
                    setFieldValue('tieneTipoRecursos', false);
                    setTieneTipoRecursos(false);
                    setFieldTouched('_otraMateriaPrima');
                  }
                  setFieldTouched('sucursal');
                }}
              >
                <InputComponent
                  fullWidth
                  onBlur={() => setFieldTouched('subTipoResiduo')}
                  touched={touched.subTipoResiduo}
                  placeholder={
                    listaSubTipoResiduos.length
                      ? 'Seleccione subtipo de residuo'
                      : ''
                  }
                  estado={
                    errors.subTipoResiduo
                      ? {
                          tipo: 'error',
                          mensaje: errors.subTipoResiduo
                        }
                      : null
                  }
                  label={
                    <LabelComponent>
                      Subtipo de residuo
                      <br />
                      <span className="text-neutral-60">
                        (incluye subproductos)
                      </span>
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
            </div>
          </VerificarPermisos>

          {/* Nombre Residuo */}
          <VerificarPermisos nombrePermiso={PERMISOS.nombreResiduo}>
            <div className="col-span-12 xs:col-span-4">
              <InputComponent
                className=""
                fullWidth
                disabled={
                  tipoFormularioSeleccionado.ingresar
                    ? values.tipoResiduo.length === 0
                    : !values.tipoResiduo
                }
                label={
                  <LabelComponent className="mt-5">
                    Nombre Residuo
                  </LabelComponent>
                }
                name="nombreResiduo"
                touched={touched.nombreResiduo}
                onChange={handleChange}
                type="text"
                value={values.nombreResiduo || ''}
                estado={
                  errors.nombreResiduo
                    ? {
                        tipo: 'error',
                        mensaje: errors.nombreResiduo
                      }
                    : null
                }
              />
            </div>
          </VerificarPermisos>
        </div>

        {/* Fila 3 */}
        <VerificarPermisos nombrePermiso={PERMISOS.recursos}>
          <div className="grid grid-cols-12 gap-4 mt-4">
            {/* Tipo Recurso */}
            <div className="col-span-12 xs:col-span-4">
              <LabelComponent>
                Seleccione el recurso
                <br />
                <span className="text-neutral-60">
                  (incluye recursos o componentes)
                </span>
              </LabelComponent>
              <Text>
                Puede seleccionar más de uno e incluso incluir uno que no se
                encuentre en el listado.
              </Text>
            </div>

            {/* Tipo Recurso */}
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                multiple
                className="mb-3 flex-1 block "
                clearOnEscape={true}
                accesor="nombreRecurso"
                disabled={!tieneTipoRecursos}
                loading={cargandoListaTipoRecursos}
                loadingText={
                  !listaTipoRecursos &&
                  'No hay recursos para el residuo seleccionado' &&
                  touched.tipoRecurso &&
                  errors.tipoRecurso
                }
                name="tipoRecurso"
                options={listaTipoRecursos}
                variant="outlined"
                onBlur={() => setFieldTouched('tipoRecurso')}
                value={values.tipoRecurso}
                getOptionLabel={option => option.nombreRecurso || ''}
                noOptionsText={
                  cargandoListaTipoRecursos ? 'Cargando...' : 'Sin opciones'
                }
                onChange={(ev, selected) => {
                  selected
                    ? setFieldValue('tipoRecurso', selected)
                    : setFieldValue('tipoRecurso', []);
                }}
              >
                <InputComponent
                  className="min-h-9 "
                  fullWidth
                  placeholder={
                    listaTipoRecursos ? 'Seleccione el recurso' : 'Sin opciones'
                  }
                  touched={touched.tipoRecurso}
                  estado={
                    errors.tipoRecurso
                      ? {
                          tipo: 'error',
                          mensaje: errors.tipoRecurso
                        }
                      : null
                  }
                  label={
                    <>
                      <LabelComponent>Recursos</LabelComponent>
                    </>
                  }
                />
                {touched.tipoResiduo &&
                !cargandoListaTipoRecursos &&
                listaTipoRecursos.length === 0 &&
                values.tipoRecurso.length === 0 ? (
                  <Text
                    size="s"
                    className="font-medium text-warning-dark flex items-center py-1"
                  >
                    * {'No hay recursos para el residuo seleccionado'}
                  </Text>
                ) : (
                  <></>
                )}
              </AutocompleteComponent>
            </div>

            {/* Otro Tipo Recurso */}
            <div className="col-span-12 xs:col-span-4">
              <InputComponent
                multiple
                className="w-full "
                disabled={errors.otroTipoRecursoExiste}
                label={<LabelComponent>Añadir uno distinto</LabelComponent>}
                name="_otraMateriaPrima"
                onChange={handleChange}
                onKeyPress={event => {
                  handleOtraMateriaKeyPress(event, values, setFieldValue);
                  setFieldTouched('tipoRecursoReemplazo');
                }}
                estado={
                  errors._otraMateriaPrima
                    ? {
                        tipo: 'error',
                        mensaje: errors._otraMateriaPrima
                      }
                    : null
                }
                touched={touched._otraMateriaPrima}
                onBlur={() => setFieldTouched('_otraMateriaPrima')}
                placeholder="o ingrese uno manualmente"
                type="text"
                value={values._otraMateriaPrima || ''}
                variant="outlined"
                outerInputProps={{
                  endAdornment: (
                    <RecursoDistinto
                      handleAddOtraMateriaPrima={handleAddOtraMateriaPrima}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )
                }}
              />

              {values.otroTipoRecurso &&
                values.otroTipoRecurso?.map((mp, i) => (
                  <Chip
                    label={mp.nombreRecurso}
                    className="mt-2"
                    onDelete={handleDeleteMateriaPrima(
                      mp,
                      values,
                      setFieldValue
                    )}
                    key={mp + i}
                  />
                ))}
              {touched._otraMateriaPrima && errors.otroTipoRecurso ? (
                <Text
                  size="s"
                  className="font-medium text-error-dark flex items-center py-1"
                >
                  {getIcon('error')} {errors.otroTipoRecurso}
                </Text>
              ) : null}
              {touched._otraMateriaPrima && mensajeRecursoExiste ? (
                <Text
                  size="s"
                  className="font-medium text-warning-dark flex items-center py-1"
                >
                  {mensajeRecursoExiste}
                </Text>
              ) : null}
            </div>
          </div>
        </VerificarPermisos>

        {/* Fila 4 */}
        <VerificarPermisos nombrePermiso={PERMISOS.recursoReemplazo}>
          <div className="grid grid-cols-12 gap-4 mt-4">
            {/* Tipo de Recursos de reemplazo */}
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                className="mb-3 "
                clearOnEscape={true}
                accesor="nombreRecursoReemplazo"
                loadingText={!listaTipoRecursoReemplazo.length && 'Cargando...'}
                id="idRecursoReemplazo"
                name="recursoReemplazo"
                openOnFocus={true}
                options={listaTipoRecursoReemplazo || []}
                size="small"
                variant="outlined"
                onBlur={() => setFieldTouched('recursoReemplazo')}
                value={values.recursoReemplazo || ''}
                getOptionLabel={option => option.nombreRecursoReemplazo || ''}
                noOptionsText={
                  cargandoListaTipoRecursoReemplazo
                    ? 'Cargando...'
                    : 'Sin opciones'
                }
                onChange={(ev, selected) => {
                  selected
                    ? setFieldValue('recursoReemplazo', {
                        codigoRecursoReemplazo: selected.codigoRecursoReemplazo,
                        nombreRecursoReemplazo: selected.nombreRecursoReemplazo
                      })
                    : setFieldValue('recursoReemplazo', []);
                  // Calculo Sub Tipos de Residuos
                  selected &&
                    calculoSubTipoRecursosReeplazo({
                      codigoRecursoReemplazo: selected.codigoRecursoReemplazo,
                      setFieldValue
                    });

                  selected && setFieldTouched('subTipoResiduo');

                  setFieldTouched('subTipoRecursoReemplazo');
                }}
              >
                <InputComponent
                  className="h-9"
                  fullWidth
                  touched={touched.recursoReemplazo}
                  estado={
                    errors.recursoReemplazo
                      ? {
                          tipo: 'error',
                          mensaje: errors.recursoReemplazo
                        }
                      : null
                  }
                  label={
                    <>
                      <LabelComponent>Recurso que reemplaza</LabelComponent>
                    </>
                  }
                />
              </AutocompleteComponent>
            </div>
            {/* SubTipo que reemplaza */}
            <div className="col-span-12 xs:col-span-4">
              <AutocompleteComponent
                className="mb-3 "
                disabled={
                  !touched.subTipoRecursoReemplazo &&
                  !values.tieneTipoRecursosReemplazo
                }
                loadingText={
                  !listaSubTipoRecursoReemplazo.length && 'Cargando...'
                }
                accesor="nombreRecursoReemplazo"
                id="idSubTipoRecursoReemplazo"
                name="subTipoRecursoReemplazo"
                clearOnEscape={true}
                openOnFocus={false}
                options={listaSubTipoRecursoReemplazo}
                size="small"
                variant="outlined"
                onBlur={() => setFieldTouched('subTipoRecursoReemplazo')}
                value={values.subTipoRecursoReemplazo || ''}
                getOptionLabel={option => option.nombreRecursoReemplazo || ''}
                noOptionsText={
                  cargandoListaSubTipoRecursoReemplazo
                    ? 'Cargando...'
                    : 'Sin opciones'
                }
                onChange={(ev, selected) => {
                  selected
                    ? setFieldValue('subTipoRecursoReemplazo', selected)
                    : setFieldValue('subTipoRecursoReemplazo', []);

                  setFieldTouched('descripcionAdicional');
                }}
              >
                <InputComponent
                  className="h-9"
                  fullWidth
                  touched={touched.subTipoRecursoReemplazo}
                  estado={
                    errors.subTipoRecursoReemplazo
                      ? {
                          tipo: 'error',
                          mensaje: errors.subTipoRecursoReemplazo
                        }
                      : null
                  }
                  label={
                    <>
                      <LabelComponent>
                        Seleccionar subtipo de recurso de reemplazo
                      </LabelComponent>
                    </>
                  }
                />
              </AutocompleteComponent>
            </div>
          </div>
        </VerificarPermisos>

        {/* Fila 5 */}
        <VerificarPermisos nombrePermiso={PERMISOS.descripcionResiduo}>
          <div className="grid grid-cols-12 gap-4 mt-4">
            {/* Descripción adicional */}
            <div className="col-span-12 xs:col-span-12">
              <InputComponent
                autoComplete={'off'}
                name="descripcionAdicional"
                type="text"
                fullWidth
                multiline
                rows={4}
                size={'small'}
                value={values.descripcionAdicional}
                onBlur={() => setFieldTouched('descripcionAdicional')}
                touched={touched.descripcionAdicional ? true : false}
                onChange={handleChange}
                label={<LabelComponent>Descripción adicional</LabelComponent>}
                estado={
                  errors.descripcionAdicional
                    ? {
                        tipo: 'error',
                        mensaje: errors.descripcionAdicional
                      }
                    : null
                }
                placeholder="Detallar calidad del producto o cualquier descipción adicional del Residuo"
              />
            </div>
          </div>
        </VerificarPermisos>
      </div>
    </div>
  );
};
