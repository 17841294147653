import { DICTONARY } from 'const/Dictonary';
import { isValorValido } from 'helpers/validaCampos';

export const formatoServicio = values => {
  const codigoRolesEmpresa = values.codigoRolesEmpresa.map(cadena =>
    parseInt(cadena)
  );
  const codigosTipoResiduo = values.codigosTipoResiduo.map(cadena =>
    parseInt(cadena)
  );
  const formatoArchivos = values.archivosEmpresa.map(archivo => {
    return {
      nombreArchivo: archivo.nombreArchivo,
      base64: archivo.base64,
      tipoArchivo: archivo.tipoArchivo,
      tipoAcreditacion: DICTONARY.TIPO_ACREDITACION.OTRAS_ACREDITACIONES,
      codigoValidacion: null
    };
  });

  let respRquest = {
    telefonoSucursal: values.telefonoSucursal,
    tipoNegocio: values.tipoNegocio,
    codigoRolesEmpresa: codigoRolesEmpresa,
    codigoComuna: values.comuna.codigoComuna,
    latitudDireccion: values.latitudDireccion,
    longitudDireccion: values.longitudDireccion,
    nombreVia: values.nombreVia,
    codigosTipoResiduo: codigosTipoResiduo
  };

  if (values.tipoNegocio === 'empresa') {
    respRquest.razonSocial = values.razonSocial;
    respRquest.rutEmpresa = values.rutEmpresa;
    respRquest.codigoActividad = values.actividad.codigoCiuActividad;
  } else if (values.tipoNegocio === 'persona-natural') {
    respRquest.nombreEmpresa = values.nombre;
  }

  if (codigoRolesEmpresa.includes(DICTONARY.ROL.RECICLADORDEBASE.CODIGO)) {
    respRquest.areaTrabajo = values.areaTrabajo;
    respRquest.registroNacionalRDB = values.registroNacionalRDB;
    respRquest.clientes = values.clientes;

    if (respRquest.registroNacionalRDB) {
      const file = values.registroNacionalRDBFile;
      if (isValorValido(values.registroNacionalRDBFile.base64)) {
        file.base64 = values.registroNacionalRDBFile.base64;
        formatoArchivos.push(file);
      } else if (
        isValorValido(values.registroNacionalRDBFile.codigoValidacion)
      ) {
        formatoArchivos.push(file);
      }
    }
  }

  if (
    codigoRolesEmpresa.includes(DICTONARY.ROL.RECICLADORDEBASE.CODIGO) ||
    codigoRolesEmpresa.includes(DICTONARY.ROL.GESTOR.CODIGO) ||
    codigoRolesEmpresa.includes(DICTONARY.ROL.RECEPTOR.CODIGO)
  ) {
    respRquest.autorizacionTransporte = values.autorizacionTransporte;
    respRquest.autorizacionAcopio = values.autorizacionAcopio;

    if (respRquest.autorizacionTransporte) {
      const file = values.autorizacionTransporteFile;
      if (isValorValido(values.autorizacionTransporteFile.base64)) {
        file.base64 = values.autorizacionTransporteFile.base64;
        formatoArchivos.push(file);
      } else if (
        isValorValido(values.autorizacionTransporteFile.codigoValidacion)
      ) {
        formatoArchivos.push(file);
      }
    }

    if (respRquest.autorizacionAcopio) {
      const file = values.autorizacionAcopioFile;
      if (isValorValido(values.autorizacionAcopioFile.base64)) {
        file.base64 = values.autorizacionAcopioFile.base64;
        formatoArchivos.push(file);
      } else if (
        isValorValido(values.autorizacionAcopioFile.codigoValidacion)
      ) {
        formatoArchivos.push(file);
      }
    }
  }

  respRquest.archivosEmpresa = formatoArchivos;

  return respRquest;
};
