import { Skeleton } from '@mui/material';

export const SekeltonListadoResiduosDeclarados = () => {
  const dummyRows = [0, 1, 2, 4, 5];

  return (
    <div>
      <div className="border-t py-4">
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12 md:col-span-6 xl:col-span-3">
            <Skeleton variant="rounded" className="w-full h-10 rounded" />
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3">
            <Skeleton variant="rounded" className="w-full h-10 rounded" />
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3 xl:text-center">
            <Skeleton variant="rounded" className="w-full h-10 rounded" />
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-3 xl:justify-end flex gap-4">
            <Skeleton variant="rounded" className="w-full h-10 rounded" />
          </div>
        </div>
      </div>

      <div>
        <Skeleton variant="rounded" className="w-48 h-6 rounded mb-3" />
        {dummyRows.map(items => (
          <div className="bg-white shadow-md p-5 py-6 border-t">
            <div
              className={`grid grid-cols-12 gap-2 lg:gap-4 w-full items-center`}
            >
              <div className="col-span-12 lg:col-span-5 flex gap-5">
                <Skeleton
                  variant="rounded"
                  className="h-10 w-12 rounded-full"
                />
                <Skeleton variant="rounded" className="w-full h-10 rounded" />
              </div>
              <div className="col-span-12 lg:col-span-1"></div>
              <div className="col-span-12 lg:col-span-6 flex gap-4 lg:justify-end flex-col sm:flex-row sm:items-center items-start">
                <Skeleton variant="rounded" className="w-full h-10 rounded" />
                <Skeleton variant="rounded" className="w-full h-10 rounded" />
                <Skeleton variant="rounded" className="w-full h-10 rounded" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
