import { Divider, Skeleton } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { RoleComponent } from 'components/design-system/Role/Role';
import Text from 'components/design-system/Text/Text';
import { useContext } from 'react';
import { MatchContext } from 'views/Marketplace/Residuos/context/MatchContext';
import AlertComponent from 'components/design-system/Alert/Alert';
import {
  obtenerAutorizacionesDeObjeto,
  obtenerGradoAutorizacionesSanitarias
} from 'helpers/autorizaciones';

export const TabEmpresas = () => {
  const {
    setCoordenadasCentroMapa,
    setSucursalSeleccionado,
    isCargandoMatchEmpresas,
    matchEmpresasFiltrado,
    sucursalSeleccionada,
    filtrosAplicados
  } = useContext(MatchContext);

  const handleSeleccionarSucursal = empresa => {
    const coordenadas = [empresa.direccion.latitud, empresa.direccion.longitud];
    setCoordenadasCentroMapa(coordenadas);
    setSucursalSeleccionado(empresa);
  };

  const handleObtenerMarcador = item => {
    const isRolesFiltrados = filtrosAplicados.rolesFiltrado.length === 0;
    const isMultipleRolesFiltrados = filtrosAplicados.rolesFiltrado.length > 1;

    if (isRolesFiltrados) {
      return {
        arrayRol: item.rol
      };
    }

    if (isMultipleRolesFiltrados) {
      const rolesEncontrados = item.rol.filter(roles =>
        filtrosAplicados.rolesFiltrado.find(
          filtrado => filtrado === roles.nombreRol
        )
      );
      return {
        arrayRol: rolesEncontrados
      };
    } else {
      return {
        rol: filtrosAplicados.rolesFiltrado[0]
      };
    }
  };

  if (isCargandoMatchEmpresas) {
    return (
      <div className="m-2">
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
        <Divider className="my-3" />
        <Skeleton
          variant="rounded"
          className="w-full h-14 rounded relative z-0 min-w-full"
        />
      </div>
    );
  }

  return (
    <div>
      {matchEmpresasFiltrado.numeroSucursales === 0 && (
        <div className="m-4">
          <AlertComponent type="info">
            <Text size="S">
              <strong className="text-black">
                No se han encontrado resultados
              </strong>
              . Intenta realizando una nueva busqueda y modificando los
              criterios.
            </Text>
          </AlertComponent>
        </div>
      )}

      {matchEmpresasFiltrado.sucursales.length > 0 &&
        matchEmpresasFiltrado.sucursales.map((item, index) => {
          return (
            <div
              key={index}
              className={`flex items-center hover:bg-uv-primary-90 p-4 border-b cursor-pointer
              ${
                sucursalSeleccionada &&
                item.codigoSucursal === sucursalSeleccionada.codigoSucursal
                  ? 'bg-neutral-80 hover:bg-neutral-70'
                  : ''
              }`}
              onClick={() => handleSeleccionarSucursal(item)}
            >
              <div className="w-4/5">
                <div className="flex items-center">
                  <RoleComponent
                    {...handleObtenerMarcador(item)}
                    tipo="marcador"
                    className="mr-4 w-7 h-7 rounded border-none"
                    size="S"
                    nombreSucursal={item.nombreSucursal}
                    sucursal={item}
                    gradoAutorizacionesSanitarias={obtenerGradoAutorizacionesSanitarias(
                      obtenerAutorizacionesDeObjeto(item)
                    )}
                  />
                  <Text size="M" className="text-black pb-0.5">
                    {item.empresa}
                    <span className="block text-xs text-neutral-40">
                      {item.nombreSucursal}
                    </span>
                  </Text>
                </div>
              </div>
              <div className="w-1/5 text-right">
                <ChevronRight />
              </div>
            </div>
          );
        })}
    </div>
  );
};
