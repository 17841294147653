export const stepsIngresar = [
  {
    nombrePaso: 'Información del negocio',
    codigoPaso: 'informacion-del-negocio',
    opcional: false
  },
  {
    nombrePaso: 'Rol del negocio',
    codigoPaso: 'rol-del-negocio',
    opcional: false
  },
  {
    nombrePaso: 'Información de casa matriz',
    codigoPaso: 'informacion-de-casa-matriz',
    opcional: false
  },
  {
    nombrePaso: 'Tipos de residuos',
    codigoPaso: 'tipos-de-residuos',
    opcional: false
  },
  {
    nombrePaso: 'Archivos adjuntos',
    codigoPaso: 'acreditaciones',
    opcional: true
  },
  {
    nombrePaso: 'Clientes destacados',
    codigoPaso: 'clientes-destacados',
    opcional: true
  }
];
