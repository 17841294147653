// Design System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import { TooltipComponent } from 'components/design-system/Tooltip/Tooltip';
// Material
import { Help } from '@mui/icons-material';

// Librarys

// Diccionary

// Hooks

// Utils

const Transporte = ({
  // Formik
  errors,
  // handleBlur,
  // handleChange,
  // handleReset,
  // handleSubmit,
  isSubmitting,
  // setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  // validateForm,
  values,
  // Dependencias
  isLoadingTipoTransporte,
  // setTipoTransporte,
  tipoTransporte
}) => {
  return (
    <>
      <div className="col-span-12">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12">
            <Heading type="h4" className="mb-0">
              Transporte
            </Heading>
          </div>
          <div className="col-span-12 xs:col-span-12">
            <div className="grid grid-cols-12 gap-4">
              {/* Tipo Transporte */}
              <div className="col-span-12 md:col-span-6 xl:col-span-4">
                <AutocompleteComponent
                  accesor="nombreTipoTransporte"
                  clearOnEscape={true}
                  disabled={isSubmitting || isLoadingTipoTransporte}
                  fullWidth
                  name={'tipoTransporte'}
                  options={tipoTransporte}
                  value={values.tipoTransporte || ''}
                  onChange={(event, newValue, reason) => {
                    let data = { name: 'tipoTransporte', value: newValue };
                    if (reason === 'clear') {
                      data.value = '';
                    }
                    setFieldValue('tipoTransporte', data.value);
                  }}
                  getOptionLabel={option => option.nombreTipoTransporte || ''}
                  noOptionsText={
                    isLoadingTipoTransporte ? 'Cargando...' : 'Sin resultados'
                  }
                >
                  <InputComponent
                    autoComplete="off"
                    disabled={isSubmitting || isLoadingTipoTransporte}
                    fullWidth
                    touched={touched.tipoTransporte}
                    onBlur={() => {
                      setFieldTouched('tipoTransporte');
                    }}
                    placeholder={
                      isLoadingTipoTransporte
                        ? 'Cargando...'
                        : tipoTransporte.length === 0 &&
                          !isLoadingTipoTransporte
                        ? ''
                        : 'Seleccione una opción'
                    }
                    estado={
                      errors.tipoTransporte
                        ? { tipo: 'error', mensaje: errors.tipoTransporte }
                        : null
                    }
                    label={
                      <LabelComponent className="w-full">
                        Tipo de transporte{' '}
                        <span className="text-neutral-60">(opcional)</span>
                        <TooltipComponent
                          className="float-right"
                          title={
                            'Indica el tipo de transporte en el que recibiste tu recurso'
                          }
                        >
                          <Help
                            className="text-uv-secondary-0"
                            fontSize="small"
                          />
                        </TooltipComponent>
                      </LabelComponent>
                    }
                  />
                </AutocompleteComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transporte;
