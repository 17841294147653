import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { PopperComponent } from 'components/design-system/Popper/Popper';
import { AuthContext } from 'context';
import { useContext } from 'react';
import Text from 'components/design-system/Text/Text';
import Dashboard from '@mui/icons-material/Dashboard';
import SubMenuAnalisisTerritorial from '../responsive/submenu/SubMenuAnalisisTerritorial';
import { PERMISOS } from 'const/Permisos';

export const MenuAnalistaTerritorial = ({ forceRefresh }) => {
  const {
    isTienePermisos,
    perfiles: { isSuperAdmin }
  } = useContext(AuthContext);

  return (
    <>
      {(isTienePermisos(PERMISOS.cargaBaseDatos) ||
        isTienePermisos(PERMISOS.empresaAT) ||
        isTienePermisos(PERMISOS.residuoAT) ||
        isTienePermisos(PERMISOS.lineaBase) ||
        isTienePermisos(PERMISOS.potencialSinergia) ||
        isTienePermisos(PERMISOS.transaccionesAT)) && (
        <PopperComponent
          Button={
            <IconButtonComponent
              className="text-white rounded"
              aria-controls="gestiona-menu"
              aria-haspopup="true"
            >
              <Dashboard />
              <Text className="text-white ml-1 font-secondary font-normal">
                {isSuperAdmin ? 'A.Territorial' : 'Análisis Territorial'}
              </Text>
            </IconButtonComponent>
          }
        >
          <div className="pop-section-item px-3">
            <SubMenuAnalisisTerritorial forceRefresh={forceRefresh} />
          </div>
        </PopperComponent>
      )}
    </>
  );
};
