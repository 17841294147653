import { DICTONARY } from 'const/Dictonary';
import * as yup from 'yup';

const SchemaBusinessRole = yup.object().shape({
  codigoRolesEmpresa: yup
    .array()
    .of(yup.number())
    .min(1, 'Debe seleccionar al menos un tipo de usuario')
    .required('El campo es obligatorio.'),

  areaTrabajo: yup.string().when('codigoRolesEmpresa', {
    is: codigoRolesEmpresa =>
      codigoRolesEmpresa.includes(DICTONARY.ROL.RECICLADORDEBASE.CODIGO),
    then: () => {
      return yup
        .string()
        .min(3, 'La cantidad mínima de caracteres es 3')
        .max(100, 'La cantidad máxima de caracteres es 100')
        .required('Es un campo obligatorio');
    }
  })
});

export { SchemaBusinessRole };
