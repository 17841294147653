import { useContext } from 'react';
import PixiOverlay from 'react-leaflet-pixi-overlay';
import { LineaBaseContext } from '../context/LineaBaseContext';
import { DICTONARY } from 'const/Dictonary';

export const RenderCanvasData = ({ datosMapaEmpresa, sectoresEconomicos }) => {
  const { aplicarFiltros, setAplicarFiltros, setCoordenadasMapa, setState } =
    useContext(LineaBaseContext);

  let sectoresEconomicosArr = [];
  sectoresEconomicos.map(item => {
    sectoresEconomicosArr[item.codigoSectorEconomico] = item.codigoHex;
    return item;
  });

  const getMarcadores = () => {
    const codigoEmpresaATFiltrado = Object.hasOwn(
      aplicarFiltros,
      'codigoEmpresaAT'
    )
      ? aplicarFiltros.codigoEmpresaAT
      : null;

    const marcadores = [];
    datosMapaEmpresa.forEach(sucursal => {
      marcadores.push({
        id: sucursal.codigoEmpresaAT,
        iconId: getIconId(sucursal, codigoEmpresaATFiltrado),
        customIcon: getIcon(sucursal, codigoEmpresaATFiltrado),
        position: sucursal.latLon,
        //popup: <div>All good!</div>,
        onClick: () => handleOnClickMapPoint(sucursal)
        //tooltip: sucursal.codigoEmpresaAT.toString(),
      });
    });

    return marcadores;
  };

  const handleOnClickMapPoint = sucursal => {
    if (!sucursal.codigoEmpresaAT) return;
    if (!aplicarFiltros.codigoEmpresaAT) {
      handleSelectPoint(sucursal);
    }

    if (sucursal.codigoEmpresaAT === aplicarFiltros.codigoEmpresaAT) {
      handleUnselectPoint();
    } else {
      handleSelectPoint(sucursal);
    }
  };

  const handleSelectPoint = sucursal => {
    setAplicarFiltros({
      ...aplicarFiltros,
      codigoEmpresaAT: sucursal.codigoEmpresaAT,
      tipoPeticion: DICTONARY.LINEA_BASE_ESTADOS.SELECCIONA_PUNTO
    });
    if(sucursal && sucursal.latLon){
      setCoordenadasMapa(sucursal.latLon);
      setState({ mostrarMensajeSinDireccion : false });
    } else {
      setState({ mostrarMensajeSinDireccion : false });
    }
  };

  const handleUnselectPoint = () => {
    setAplicarFiltros({
      ...aplicarFiltros,
      codigoEmpresaAT: null,
      tipoPeticion: DICTONARY.LINEA_BASE_ESTADOS.DESELECCIONA_PUNTO
    });
  };

  const getIconId = (sucursal, codigoEmpresaATFiltrado) => {
    if (!codigoEmpresaATFiltrado) {
      return sucursal.codigoSectorEconomico;
    }

    if (codigoEmpresaATFiltrado === sucursal.codigoEmpresaAT) {
      return sucursal.codigoSectorEconomico + 'selected';
    } else {
      return sucursal.codigoSectorEconomico + 'default';
    }
  };

  const getIcon = (sucursal, codigoEmpresaATFiltrado) => {
    const iconSeleccionado = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40" class="z-10"><defs><filter id="a" x="-1" y="-1" width="42" height="42" filterUnits="userSpaceOnUse"><feOffset dy="1" input="SourceAlpha"/><feGaussianBlur stdDeviation="1" result="b"/><feFlood flood-opacity="0.851"/><feComposite operator="in" in2="b"/><feComposite in="SourceGraphic"/></filter><clipPath id="d"><rect width="40" height="40"/></clipPath></defs><g id="c" clip-path="url(#d)"><g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#a)"><circle cx="18" cy="18" r="18" transform="translate(2 1)" fill="#fff"/></g><circle cx="13" cy="13" r="13" transform="translate(7 6)" fill="${
      sectoresEconomicosArr[sucursal.codigoSectorEconomico]
    }"/></g></svg>`;

    const iconNormal = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="z-0" ><circle cx="12" cy="12" r="6" fill="${
      sectoresEconomicosArr[sucursal.codigoSectorEconomico]
    }" /></svg>`;

    const noSeleccionado = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="z-0" ><circle cx="12" cy="12" r="6" fill="${
      sectoresEconomicosArr[sucursal.codigoSectorEconomico]
    }" opacity="0.4" /></svg>`;

    if (codigoEmpresaATFiltrado === null) {
      return iconNormal;
    } else {
      if (codigoEmpresaATFiltrado !== sucursal.codigoEmpresaAT) {
        return noSeleccionado;
      } else {
        return iconSeleccionado;
      }
    }
  };

  return <PixiOverlay markers={getMarcadores()} />;
};
