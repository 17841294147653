import API from './api';

const commons = {
  comunasPorRegion: function (codRegion, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/obtener-comunas',
      params: {
        codRegion: codRegion
      },
      interceptor: conInterceptor
    });
  },

  regiones: function ({ codRegion, codComuna }, conInterceptor = true) {
    const params = {};
    if (codRegion) {
      params.codRegion = codRegion;
    }
    if (codComuna) {
      params.codComuna = codComuna;
    }
    return API.axios({
      method: 'get',
      url: '/regiones',
      params: params,
      interceptor: conInterceptor
    });
  },

  rubros: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/rubros',
      params: params,
      interceptor: conInterceptor
    });
  },

  tipoRSD: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/tipo-residuos',
      params: params,
      interceptor: conInterceptor
    });
  },

  recursos: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/recursos',
      params: params,
      interceptor: conInterceptor
    });
  },

  nombresLER: function (
    codigoRol,
    numPagina,
    totalPagina,
    ordenarPor,
    conInterceptor = true
  ) {
    return API.axios({
      method: 'get',
      url: '/nombreler',
      params: codigoRol,
      numPagina,
      totalPagina,
      ordenarPor,
      interceptor: conInterceptor
    });
  },

  tipodisposicion: function (codigosTipoResiduo, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/tipo-disposicion',
      params: {
        codigosTipoResiduo: codigosTipoResiduo
      },
      interceptor: conInterceptor
    });
  },

  __cacheTipotratamiento: {},

  tipotratamiento: function (codigoTipoRSD, conInterceptor = true) {
    if (typeof this.__cacheTipotratamiento[codigoTipoRSD] !== 'undefined') {
      return this.__cacheTipotratamiento[codigoTipoRSD];
    }

    this.__cacheTipotratamiento[codigoTipoRSD] = API.axios({
      method: 'get',
      url: '/tipotratamientos',
      interceptor: conInterceptor,
      params: {
        codigoTipoRSD: codigoTipoRSD
      }
    });

    return this.__cacheTipotratamiento[codigoTipoRSD];
  },

  indicadores: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/indicadores/calcular',
      params: params,
      interceptor: conInterceptor
    });
  },

  obtieneComunas: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/comunas',
      params: params,
      interceptor: conInterceptor
    });
  },

  obtieneRegiones: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/regiones',
      params: params,
      interceptor: conInterceptor
    });
  },

  obtieneRecursoReemplazo: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/recursosreemplazo',
      params: params,
      interceptor: conInterceptor
    });
  },

  obtieneRegistrosLog: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/registro-log',
      params: params,
      interceptor: conInterceptor
    });
  },

  secoresEconomicos: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/sectores-economicos',
      params: params,
      interceptor: conInterceptor
    });
  },

  rellenosSanitarios: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/rellenos-sanitarios',
      params: params,
      interceptor: conInterceptor
    });
  },

  calculoIndicadores: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/indicadores/calculadora',
      data: data,
      interceptor: conInterceptor
    });
  }
};

export default commons;
