import { BotonBase } from "./BotonBase";
import home3Opciones from "assets/images/home/home3Opciones.svg";

export const Layout3Opciones = ({opciones}) => {
  const [primeraOpcion, segundaOpcion, terceraOpcion]  = opciones;

  return(
    <>
      <div>
        <BotonBase
          onClick={primeraOpcion.onClick}
          texto={<div className="py-4">
           {primeraOpcion.texto}
          </div>}
          icono={primeraOpcion.icono}
        />
      </div>

      <div>
        <img src={home3Opciones} alt="Sucursal" />
      </div>
      <div>
        <BotonBase 
          onClick={segundaOpcion.onClick}
          texto={segundaOpcion.texto}
          icono={segundaOpcion.icono}
          className="mb-5"
        />

        <BotonBase 
          onClick={terceraOpcion.onClick}
          texto={terceraOpcion.texto}
          icono={terceraOpcion.icono}
        />
      </div>
    </>
  )
};