import * as yup from 'yup';
import { PERMISOS } from 'const/Permisos';

const SchemaEditarNegocio = isTienePermisos => {
  const schema = {
    rutEmpresa: yup.string().when('tipoNegocio', {
      is: 'empresa',
      then: () => {
        return yup
          .string()
          .trim()
          .matches(/^[^.\s]*$/, 'No se permiten puntos en este campo')
          .max(11, 'La cantidad máxima de caracteres es 11')
          .min(9, 'La cantidad mínima de caracteres es 9')
          .required('Es un campo obligatorio');
      }
    }),
    razonSocial: yup.string().when('tipoNegocio', {
      is: 'empresa',
      then: () => {
        return yup.string().required('Es un campo obligatorio');
      }
    }),

    rubro: yup
      .object()
      .required('Este campo es obligatorio')
      .when('tipoNegocio', {
        is: 'empresa',
        then: () => {
          return yup
            .object()
            .typeError('Este campo es obligatorio')
            .required('Este campo es obligatorio');
        }
      }),

    actividad: yup.object().when('tipoNegocio', {
      is: 'empresa',
      then: () => {
        return yup
          .object()
          .typeError('Este campo es obligatorio')
          .required('Este campo es obligatorio');
      }
    }),

    nombreEmpresa: yup
      .string()
      .trim()
      .min(1, 'El nombre no es válido')
      .required('Es un campo obligatorio')
  };

  if (isTienePermisos(PERMISOS.areaDeTrabajo)) {
    schema.areaTrabajo = yup
      .string()
      .min(3, 'La cantidad mínima de caracteres es 3')
      .max(100, 'La cantidad máxima de caracteres es 100')
      .required('Es un campo obligatorio');
  }

  return yup.object().shape(schema);
};

export { SchemaEditarNegocio };
