import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { Cifra } from './Cifra';
import IconComponent from 'components/design-system/icon/Icon';
import { BloquesIndicadoresSkeleton } from './BloquesIndicadoresSkeleton';

export const BloquesIndicadores = ({
  indicadores,
  isLoading,
  isSuperAdmin
}) => {
  if (isLoading) {
    return <BloquesIndicadoresSkeleton />;
  }

  if (isSuperAdmin && indicadores.state === 'IDLE') {
    return <></>;
  }

  return (
    <div>
      <div className="mb-6">
        <Heading type="h3" className="font-bold text-uv-primary-0">
          Transacciones en módulo Marketplace
        </Heading>
        <div className="grid grid-cols-12 gap-4 mb-4">
          <div className="col-span-12 md:col-span-6">
            <Text>
              <b>Marketplace:</b> Puedes encontrar ofertas o requerimientos de
              recursos sub-utilizados (descartes, residuos), y servicios
              relacionados, y con ello aumentar tu circularidad y reducir costos
              de gestión de residuos.
            </Text>
          </div>
          <div className="col-span-12 md:col-span-6">
            <Text>El módulo permite:</Text>
            <ul>
              <li>
                <Text className="relative pl-3">
                  <span className="w-1 h-1 bg-uv-secondary-0 inline-block rounded-full absolute left-0 top-2"></span>
                  Ofertar o requerir recursos sub-utilizados
                </Text>
              </li>
              <li>
                <Text className="relative pl-3">
                  <span className="w-1 h-1 bg-uv-secondary-0 inline-block rounded-full absolute left-0 top-2"></span>
                  Conectar con empresas
                </Text>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex shadow-md flex-col md:flex-row">
          <div className="w-full md:w-2/5 lg:w-1/4 bg-uv-primary-0 rounded-tl rounded-bl">
            <div className="px-6 py-4">
              {indicadores.marketplace.aside.map(indicador => (
                <div className="mb-8 mt-2">
                  <Text type="label" className="text-white mb-2">
                    {indicador.titulo ? indicador.titulo : ''}
                  </Text>
                  <div className="flex items-center">
                    {indicador.icon ? indicador.icon : ''}
                    <div className="pl-3">
                      <Heading type="H2" className="text-white my-0">
                        <Cifra valor={indicador.valor ? indicador.valor : ''} />
                      </Heading>
                      <Text className=" text-white leading-5 font-normal">
                        {indicador.descripcion ? indicador.descripcion : ''}
                      </Text>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-grow w-auto px-6 py-4 bg-white rounded-tr rounded-br">
            <div className="grid grid-cols-12 items-center gap-4">
              <div className="col-span-12 lg:col-span-5">
                <Heading
                  type="h3"
                  className="text-uv-secondary-0 font-bold mb-0 text-2xl"
                >
                  Indicadores de
                  <br />
                  impacto ambiental
                </Heading>
              </div>
              <div className="col-span-12 lg:col-span-7">
                <IndicadorItem
                  size="big"
                  title={
                    indicadores.marketplace?.destacado?.titulo
                      ? indicadores.marketplace.destacado.titulo
                      : ''
                  }
                  value={
                    indicadores.marketplace?.destacado?.valor
                      ? indicadores.marketplace.destacado.valor
                      : ''
                  }
                  description={
                    indicadores.marketplace?.destacado?.descripcion
                      ? indicadores.marketplace.destacado.descripcion
                      : ''
                  }
                  icon={
                    indicadores.marketplace?.destacado?.icon
                      ? indicadores.marketplace.destacado.icon
                      : ''
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 mt-2">
              {indicadores.marketplace.principal.map((indicador, index) => (
                <IndicadorItem
                  key={index}
                  title={indicador.titulo ? indicador.titulo : ''}
                  value={indicador.valor ? indicador.valor : ''}
                  description={
                    indicador.descripcion ? indicador.descripcion : ''
                  }
                  icon={indicador.icon ? indicador.icon : ''}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <div className="grid grid-cols-12 gap-4 mb-4">
          <div className="col-span-12 md:col-span-6">
            <Heading type="h3" className="font-bold text-uv-primary-0">
              Declaración en módulo Gestiona
            </Heading>
            <Text>
              <b>Gestiona:</b> Puedes medir las “entradas” y “salidas”
              (residuos), y con ello el nivel de circularidad de tu empresa.
            </Text>
          </div>
          <div className="col-span-12 md:col-span-6">
            <Text>El módulo permite:</Text>
            <ul>
              <li>
                <Text className="relative pl-3">
                  <span className="w-1 h-1 bg-uv-secondary-0 inline-block rounded-full absolute left-0 top-2"></span>
                  Crear y administrar compras de insumos
                </Text>
              </li>
              <li>
                <Text className="relative pl-3">
                  <span className="w-1 h-1 bg-uv-secondary-0 inline-block rounded-full absolute left-0 top-2"></span>
                  Crear y administrar la generación de residuos
                </Text>
              </li>
              <li>
                <Text className="relative pl-3">
                  <span className="w-1 h-1 bg-uv-secondary-0 inline-block rounded-full absolute left-0 top-2"></span>
                  Prospectar el impacto ambiental de la gestión
                </Text>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex shadow-md flex-col md:flex-row">
          <div className="w-full md:w-2/5 lg:w-1/4 bg-uv-primary-0 rounded-tl rounded-bl">
            <div className="px-6 py-4">
              {indicadores.gestiona.aside.map(indicador => (
                <div className="mb-8 mt-2">
                  <Text type="label" className="text-white mb-2">
                    {indicador.titulo ? indicador.titulo : ''}
                  </Text>
                  <div className="flex items-center">
                    {indicador.icon ? indicador.icon : ''}
                    <div className="pl-3">
                      <Heading type="H2" className="text-white my-0">
                        <Cifra valor={indicador.valor ? indicador.valor : ''} />
                      </Heading>
                      <Text className=" text-white leading-5 font-normal">
                        {indicador.descripcion ? indicador.descripcion : ''}
                      </Text>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-grow w-auto px-6 py-4 bg-white rounded-tr rounded-br">
            <div className="grid grid-cols-12 items-center gap-4">
              <div className="col-span-12 lg:col-span-5">
                <Heading
                  type="h3"
                  className="text-uv-secondary-0 font-bold mb-0 text-2xl"
                >
                  Indicadores de
                  <br />
                  impacto ambiental
                </Heading>
              </div>
              <div className="col-span-12 lg:col-span-7">
                <IndicadorItem
                  size="big"
                  title={
                    indicadores.gestiona?.destacado?.titulo
                      ? indicadores.gestiona.destacado.titulo
                      : ''
                  }
                  value={
                    indicadores.gestiona?.destacado?.valor
                      ? indicadores.gestiona.destacado.valor
                      : ''
                  }
                  description={
                    indicadores.gestiona?.destacado?.descripcion
                      ? indicadores.gestiona.destacado.descripcion
                      : ''
                  }
                  icon={
                    indicadores.gestiona?.destacado?.icon
                      ? indicadores.gestiona.destacado.icon
                      : ''
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 mt-2 gap-5">
              {indicadores.gestiona.principal.map((indicador, index) => (
                <IndicadorItem
                  key={index}
                  title={indicador.titulo ? indicador.titulo : ''}
                  value={indicador.valor ? indicador.valor : ''}
                  description={
                    indicador.descripcion ? indicador.descripcion : ''
                  }
                  icon={indicador.icon ? indicador.icon : ''}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const IndicadorItem = ({ title, value, description, icon, size }) => {
  return (
    <div className="w-full flex-wrap py-3">
      <Text
        type="label"
        className={`text-uv-primary-0 mb-2 pr-3 ${
          size === 'big' ? 'text-sm' : ''
        } `}
      >
        {title}
      </Text>
      <div className="flex items-center">
        <IconComponent
          color="primary"
          className={`bg-uv-primary-90 rounded-full flex items-center justify-center
            ${size === 'big' ? 'w-20 h-20 p-3' : 'w-14 h-14 p-2.5'} 
          `}
        >
          {icon}
        </IconComponent>

        <div className="pl-3">
          <Heading
            type="H2"
            className={`font-bold my-0 ${size === 'big' ? 'text-3xl' : ''} `}
          >
            <Cifra valor={value} />
          </Heading>
          <Text className="text-black leading-5 font-normal">
            {description}
          </Text>
        </div>
      </div>
    </div>
  );
};
