import React, { useCallback, useContext, useEffect, useState } from 'react';
import { NavigateBefore } from '@mui/icons-material';
import { Divider } from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import { Formik, Form } from 'formik';
import { BusinessCertifications, BusinessInfo } from './steppers';
import { SchemaEditarNegocio } from './schema/Schema';
import serviceEmpresas from 'services/empresas';
import { useHistory } from 'react-router-dom';
import Loading from 'components/Loading/Loading';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { AuthContext } from 'context';
import BusinessClientesDestacados from './steppers/BusinessClientesDestacados';
import { PERMISOS } from 'const/Permisos';
import {
  formatoInicialFormulario,
  formatoServicio
} from './helpers/formatoServicio';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { AlertaError, AlertaExito } from 'utils/Alertas';
import { RUTAS } from 'const/Rutas';

const EditBusiness = ({ match }) => {
  const { refrescarSesion, token, isTienePermisos } = useContext(AuthContext);
  const { params } = match;
  const history = useHistory();

  if (!params) history.push(RUTAS.HOME_USER);
  const { codigoEmpresa } = params;
  const [codEmpresa] = useState(codigoEmpresa);
  const [ListaEmpresa, setListaEmpresa] = useState([]);
  const [cargandoListaEmpresa, setCargandoListaEmpresa] = useState(true);
  const [tieneRutEmpresa, setTieneRutEmpresa] = useState(null);

  const cargaEmpresa = useCallback(async () => {
    try {
      setCargandoListaEmpresa(true);
      const response = await serviceEmpresas.obtenerEmpresa(codEmpresa);
      const errorSucursal = response.data.error;

      if (errorSucursal) throw new Error('Error al obtener empresa');
      if (response.status === 200) {
        const { data } = response;
        setListaEmpresa({
          ...data,
          rubro: data.actividad?.rubro,
          rolesFormat: data.roles.map(r => r.codigoRol.toString())
        });
        setTieneRutEmpresa(data.rutEmpresa !== null ? true : false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCargandoListaEmpresa(false);
    }
  }, [codEmpresa]);

  useEffect(() => {
    cargaEmpresa();
  }, [cargaEmpresa]);

  return (
    <SidebarLayout>
      {cargandoListaEmpresa ? (
        <Loading />
      ) : (
        <section className="md:container md:mx-auto w-full mb-10">
          <Formik
            initialValues={formatoInicialFormulario(
              ListaEmpresa,
              tieneRutEmpresa,
              setCargandoListaEmpresa
            )}
            validationSchema={SchemaEditarNegocio(isTienePermisos)}
            validateOnChange={true}
            validateOnMount={false}
            validateOnBlur={true}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                setSubmitting(true);
                const request = formatoServicio(isTienePermisos, values);
                const response = await serviceEmpresas.editarEmpresa(
                  ListaEmpresa.codigoEmpresa,
                  request
                );
                if (response.status === 200) {
                  refrescarSesion(token, false);
                  AlertaExito('Se ha editado correctamente el negocio');
                } else {
                  AlertaError(
                    'Ha ocurrido un error al editar el negocio vuelve a intentarlo. Si el problema persiste comunícate con el administrador del sitio'
                  );
                }
                setSubmitting(false);
              } catch (error) {
                AlertaError(
                  'Ha ocurrido un error al editar el negocio vuelve a intentarlo. Si el problema persiste comunícate con el administrador del sitio'
                );
                console.log(error);
              } finally {
                window.scrollTo(0, 0);
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              handleBlur,
              setFieldError,
              setFieldTouched,
              isSubmitting,
              validateForm
            }) => (
              <Form id="formEditarNegocio">
                <Heading type="h2" className="mb-4">
                  <IconButtonComponent
                    type="secondary"
                    className="mr-2.5"
                    onClick={() => history.goBack()}
                  >
                    <ChevronLeft />
                  </IconButtonComponent>
                  Edición de negocio o actividad
                </Heading>
                <div className="bg-white p-5 rounded shadow-md">
                  <div className="grid grid-cols-12 md:gap-4">
                    <div className="col-span-12 lg:col-span-12">
                      <BusinessInfo
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        setFieldError={setFieldError}
                        isSubmitting={isSubmitting}
                      />

                      <BusinessCertifications
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldTouched={setFieldTouched}
                        setFieldError={setFieldError}
                        handleSubmit={handleSubmit}
                        codEmpresa={codEmpresa}
                      />

                      {isTienePermisos(PERMISOS.clientes) &&
                        values.clientes !== null && (
                          <BusinessClientesDestacados
                            values={values}
                            errors={errors}
                            touched={touched}
                            onChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            setFieldError={setFieldError}
                          />
                        )}
                    </div>

                    <div className="col-span-12">
                      <Divider />
                    </div>

                    <div className="col-span-12 justify-between">
                      <div className="flex justify-between">
                        <ButtonComponent
                          type="secondary"
                          onClick={() => {
                            window.scrollTo(0, 0);
                            history.goBack();
                          }}
                        >
                          <NavigateBefore /> Volver
                        </ButtonComponent>

                        <ButtonComponent
                          form="formEditarNegocio"
                          buttonType="submit"
                          isLoading={isSubmitting}
                          disabled={isSubmitting}
                          onClick={() => {
                            validateForm()
                              .then(result => {})
                              .catch(err => {
                                console.log(err);
                              });
                          }}
                        >
                          {isSubmitting
                            ? 'Guardando cambios'
                            : 'Guardar cambios'}
                        </ButtonComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      )}
    </SidebarLayout>
  );
};

export default EditBusiness;
