import { Skeleton } from '@mui/material';

export const CuadroIndicadoresSkeleton = () => {
  return (
    <>
      <Skeleton
        variant="rounded"
        className="w-full h-5 rounded flex justify-center items-center mb-4"
      />
      <div className="flex items-center">
        <Skeleton variant="rounded" className="w-14 h-14 rounded-full mb-4" />
        <div className="pl-3 flex-auto">
          <Skeleton
            variant="rounded"
            className="w-full h-5 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
          />
          <Skeleton
            variant="rounded"
            className="w-full h-5 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
          />
        </div>
      </div>
    </>
  );
};
