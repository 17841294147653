import Heading from 'components/design-system/Heading/Heading';
import Table from 'components/design-system/Table/Table';
import { default as Text } from 'components/design-system/Text/Text';
import { useContext, useEffect, useMemo } from 'react';
import { useTablaLineaBase } from '../hooks/useTablaLineaBase';
import { LineaBaseContext } from '../context/LineaBaseContext';
import { DescripcionEmpresa } from './DescripcionEmpresa';
import InventoryIcon from '@mui/icons-material/Inventory';
import { TooltipComponent } from 'components/design-system/Tooltip/Tooltip';
import { DICTONARY } from 'const/Dictonary';
import { AuthContext } from 'context';

export const TablaLineaBase = () => {
  const {
    perfiles: { isSuperAdmin }
  } = useContext(AuthContext);
  const {
    aplicarFiltros,
    setAplicarFiltros,
    setCodigoEmpresaSeleccionada,
    isCargando,
    setCargando,
    setCoordenadasMapa,
    setState
  } = useContext(LineaBaseContext);

  const isLoading = isCargando.tablaEmpresasCargando;
  const isCargandoDataInicial = isCargando.cargaInicial;

  const {
    dataEmpresas,
    empresaSeleccionada,
    setEmpresaSeleccionada,
    pagination,
    setPagination,
    totalRegistros
  } = useTablaLineaBase(aplicarFiltros, setCargando, isCargandoDataInicial);

  useEffect(() => {
    setEmpresaSeleccionada({ id: null });
    setCodigoEmpresaSeleccionada(null);
  }, [isLoading]);

  const columnasTablaEmpresa = useMemo(
    () => [
      {
        header: 'Empresa',
        accessorKey: 'tablaEmpresa',
        Cell: ({ renderedCellValue }) => (
          <div className="flex gap-2">
            {renderedCellValue.residuoATOrigen && (
              <TooltipComponent title="Declara residuos en RETC">
                <InventoryIcon className="text-uv-secondary-0" />
              </TooltipComponent>
            )}
            <Text size="S">{renderedCellValue.razonSocial}</Text>
          </div>
        ),
        size: 200,
        grow: false
      },
      {
        header: 'Actividad económica',
        accessorKey: 'actividad.nombreActividad',
        Cell: ({ renderedCellValue }) => (
          <Text size="S">{renderedCellValue ? renderedCellValue : '-'}</Text>
        ),
        size: 200,
        grow: false
      },
      {
        header: 'Tamaño empresa',
        size: 100,
        grow: false,
        Header: ({ column }) => (
          <>
            <div className="flex">
              <Text
                size="S"
                className="font-bold text-black text-[13px] pt-[3px]"
              >
                Tamaño
                <br />
                empresa
              </Text>
              <DescripcionEmpresa />
            </div>
          </>
        ),
        accessorKey: 'tramo',
        Cell: ({ renderedCellValue }) => (
          <Text size="S">{renderedCellValue}</Text>
        )
      },
      {
        header: 'Comuna',
        accessorKey: 'direccion',
        size: 100,
        grow: false,
        Cell: ({ renderedCellValue }) => (
          <Text size="S">
            {renderedCellValue ? renderedCellValue.comuna.nombreComuna : ''}
          </Text>
        )
      },
      {
        header: 'Región',
        accessorKey: 'direccion.comuna.region',
        size: 100,
        grow: false,
        Cell: ({ renderedCellValue }) => (
          <Text size="S">
            {renderedCellValue ? renderedCellValue.nombreRegion : ''}
          </Text>
        )
      }
    ],
    []
  );

  const handleSeleccionarEmpresa = row => {
    if (row.id === empresaSeleccionada.id) {
      setEmpresaSeleccionada(0);
      setAplicarFiltros({
        ...aplicarFiltros,
        codigoEmpresaAT: null,
        tipoPeticion: DICTONARY.LINEA_BASE_ESTADOS.DEFAULT
      });
      return;
    }

    setCodigoEmpresaSeleccionada(row.original.codigoEmpresaAT);
    setEmpresaSeleccionada(row);
    setAplicarFiltros({
      ...aplicarFiltros,
      codigoEmpresaAT: row.original.codigoEmpresaAT,
      tipoPeticion: DICTONARY.LINEA_BASE_ESTADOS.SELECCIONA_PUNTO_TABLA
    });
    if (
      row.original &&
      row.original.direccion &&
      row.original.direccion.latitudDireccion &&
      row.original.direccion.longitudDireccion
    ) {
      setCoordenadasMapa([
        row.original.direccion.latitudDireccion,
        row.original.direccion.longitudDireccion
      ]);
      setState({ mostrarMensajeSinDireccion: false });
    } else {
      setState({ mostrarMensajeSinDireccion: true });
    }
  };

  return (
    <>
      <Table
        headTable={
          <Heading type="h4" className="p-2 text-uv-primary-0">
            Empresas
          </Heading>
        }
        muiTableContainerProps={{ sx: { height: '600px' } }}
        columns={columnasTablaEmpresa}
        data={dataEmpresas || []}
        className="h-full"
        rowCount={totalRegistros}
        isLoading={isLoading}
        manualPagination
        enableDensityToggle={false}
        enableGlobalFilter={false}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={true}
        getRowId={(row, index) => `${row.codigoEmpresaAT}#${index}`}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleSeleccionarEmpresa(row),
          selected: empresaSeleccionada.id === row.id,
          sx: {
            cursor: 'pointer'
          }
        })}
        state={{
          isLoading,
          showProgressBars: false,
          pagination,
          columnVisibility: {
            'direccion.comuna.region': isSuperAdmin ? true : false
          }
        }}
        onPaginationChange={setPagination}
      />
    </>
  );
};
