import ChatBubble from '@mui/icons-material/ChatBubble';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import PlaceRounded from '@mui/icons-material/PlaceRounded';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { DICTONARY } from 'const/Dictonary';
import Text from 'components/design-system/Text/Text';
import { useContext, useRef, useState } from 'react';
import { IniciarTransaccion } from './IniciarTransaccion';
import { MatchContext } from 'views/Marketplace/Residuos/context/MatchContext';
import { DescripcionOferente } from './Descripcion/DescripcionOferente';
import { useReunion } from 'hooks/useReunion';
import { isBloquearBotonTransaccion } from 'helpers/logicaTransacciones';
import { AuthContext } from 'context';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { PERMISOS } from 'const/Permisos';

/**
 * @name ResiduoDetalleOferente
 * Despliega el detalle del residuo seleccionado para un publicante oferente
 * @param residuo residuo por el cual se está visualizando el detalle
 * @param onCerrar función para cuando se desea cerrar el popup de detalle.
 */
export const ResiduoDetalleOferente = ({ residuo, onCerrar }) => {
  const { logeduser } = useContext(AuthContext);
  const { rol, sucursal } = residuo;
  const { salasChat, tieneNegocio, transacciones } = useContext(MatchContext);
  const { handleCrearReunion, isCreandoReunion } = useReunion();
  const [transaccionar, setTransaccionar] = useState(false);
  const [isSalaChatCreada, setIsSalaChatCreada] = useState(false);

  const isChatDisabled = salasChat === null;
  const isBloquearIniciarTransaccion =
    isBloquearBotonTransaccion(residuo) ||
    transacciones.includes(residuo.codigoResiduo);

  const handleConfirmarInicio = () => {
    setTransaccionar(true);
  };

  const handleCancelarInicioTransaccion = () => {
    setTransaccionar(false);
  };

  const handleSolicitarReunion = async () => {
    try {
      handleCrearReunion({
        codigoSucursal: residuo.sucursal.codigoSucursal,
        origenSalaChat: 'residuo',
        codigoResiduo: residuo.codigoResiduo
      });
      setIsSalaChatCreada(true);
    } catch (error) {
      console.log('ERROR_AL_CREAR_SALA_CHAT ', error);
    }
  };

  const contenedor = useRef();
  contenedor.scrollTop = 0;
  window.scrollTo(0, 0);

  return (
    <>
      <div>
        {!transaccionar && (
          <div>
            <div
              ref={contenedor}
              id="detalle-oferente"
              className="h-[65vh] scrollbar-uv overflow-y-auto p-4 pb-20"
            >
              <div>
                <div className="relative">
                  <Heading type="h3" className="pr-10">
                    {sucursal.empresa}
                    <div className="absolute -right-1 top-0 z-10">
                      <IconButtonComponent
                        onClick={onCerrar}
                        size="small"
                        type="neutral"
                      >
                        <Close />
                      </IconButtonComponent>
                    </div>
                  </Heading>
                  <div className="relative">
                    <span className="pl-8 mb-1 block font-secondary text-xs text-neutral-60 tracking-wider font-medium uppercase">
                      {sucursal.nombreSucursal}
                    </span>
                    <div className="flex items-center">
                      <PlaceRounded className="mr-2 text-uv-secondary-10" />
                      <Text>
                        {sucursal.direccion?.nombreVia}
                        {', '}
                        {sucursal.direccion?.comuna}
                        {',  Región '}
                        {sucursal.direccion?.region}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>

              <DescripcionOferente rol={rol} residuo={residuo} />
            </div>

            {tieneNegocio && (
              <div className="absolute bottom-0 left-0 w-full p-3 bg-white shadow-top rounded">
                <div className="flex gap-4">
                  {logeduser.sucursal &&
                    logeduser.sucursal.empresa.roles.map(
                      role => role.codigoRol === DICTONARY.ROL.RECEPTOR.CODIGO
                    ) && (
                      <div className="w-1/2">
                        <VerificarPermisos
                          nombrePermiso={PERMISOS.iniciarTransaccion}
                        >
                          {isBloquearIniciarTransaccion ? (
                            <ButtonComponent
                              disabled={true}
                              className="px-2 w-full text-xs"
                            >
                              Transacción iniciada
                            </ButtonComponent>
                          ) : (
                            <ButtonComponent
                              onClick={handleConfirmarInicio}
                              className="px-2 w-full"
                            >
                              Iniciar transacción <ChevronRight />
                            </ButtonComponent>
                          )}
                        </VerificarPermisos>
                      </div>
                    )}

                  <VerificarPermisos nombrePermiso={PERMISOS.solicitarReunion}>
                    <div className="w-1/2">
                      <ButtonComponent
                        isLoading={isCreandoReunion}
                        disabled={
                          isSalaChatCreada ||
                          isChatDisabled ||
                          isCreandoReunion ||
                          salasChat.residuos?.includes(residuo.codigoResiduo)
                        }
                        onClick={() => handleSolicitarReunion()}
                        className="px-2 w-full text-xs"
                        type="secondary"
                      >
                        <ChatBubble className="mr-1 hidden xl:block" />
                        Solicitar Reunión
                      </ButtonComponent>
                    </div>
                  </VerificarPermisos>
                </div>
              </div>
            )}
          </div>
        )}

        <IniciarTransaccion
          residuo={residuo}
          handleCancelarInicioTransaccion={handleCancelarInicioTransaccion}
          transaccionar={transaccionar}
        />
      </div>
    </>
  );
};
