const InitialFormValues = {
  tipoNegocio: '',

  rutEmpresa: '',
  nombre: '',
  razonSocial: '',

  rubro: '',
  actividad: '',

  areaTrabajo: '',
  telefonoSucursal: '',

  region: null,
  comuna: null,
  nombreVia: '',

  longitudDireccion: 0,
  latitudDireccion: 0,

  codigoRolesEmpresa: [],
  codigosTipoResiduo: [],
  residuos: [],

  archivosEmpresa: [],

  autorizacionTransporte: false,
  autorizacionTransporteFile: {
    nombreArchivo: null,
    tipoAcreditacion: null,
    codigoValidacion: '',
    base64: null,
    tipoArchivo: null
  },

  autorizacionAcopio: false,
  autorizacionAcopioFile: {
    nombreArchivo: null,
    tipoAcreditacion: null,
    codigoValidacion: '',
    base64: null,
    tipoArchivo: null
  },

  registroNacionalRDB: false,
  registroNacionalRDBFile: {
    nombreArchivo: null,
    tipoAcreditacion: null,
    codigoValidacion: '',
    base64: null,
    tipoArchivo: null
  },

  codigoActividad: '',
  codigoComuna: 0,

  pisoBloque: '',
  todosResiduosSeleccionados: false,
  nombreResiduosSeleccionados: [],

  inputClienteNombre: '',
  clientes: []
};
export default InitialFormValues;
