import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import iconHorasDeTrabajo from 'assets/images/reportes/horas-de-trabajo.svg';
import iconSalario from 'assets/images/reportes/salario.svg';
import iconParidadMujeres from 'assets/images/reportes/paridad-mujeres.svg';
import serviceCommons from 'services/commons';
import empresasService from 'services/empresas';
import { formatoANumeroIngles } from 'helpers/formateoNumeros';
import { AuthContext } from 'context';
import { ICON_NUMERO_TRANSACCIONES } from 'icons/ReporteIndicadores/IconNumeroTransacciones';
import { ICON_RESIDUO_VALORIZADO } from 'icons/ReporteIndicadores/IconResiduoValorizado';
import { ICON_CAMBIO_CLIMATICO } from 'icons/ReporteIndicadores/IconCambioClimatico';
import { ICON_MATERIAL_PARTICULADO } from 'icons/ReporteIndicadores/IconMaterialParticulado';
import { ICON_DEMANDA_ACUMULADA } from 'icons/ReporteIndicadores/IconDemandaAcumulada';
import { OrdenarAscendente } from 'helpers/manejoArreglos';

export const useReporte = () => {
  const {
    logeduser,
    perfiles: { isSuperAdmin }
  } = useContext(AuthContext);

  const conIndicadores =
    logeduser?.sucursal?.consumoCombustible !== null &&
    logeduser?.sucursal?.consumoEnergetico !== null &&
    logeduser?.sucursal?.trabajadoresMasculino !== null &&
    logeduser?.sucursal?.trabajadoresFemenino !== null
      ? true
      : false;

  const { codigoEmpresa } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [resumenIndicadores, setResumenIndicadores] = useState({
    demanda: [],
    oferta: []
  });
  const [empresas, setEmpresas] = useState([]);
  const [indicadores, setIndicadores] = useState({
    marketplace: {
      aside: [],
      principal: []
    },
    gestiona: {
      aside: [],
      principal: []
    },
    horasDeTrabajo: null,
    paridadMujeres: null,
    salario: null,
    state: 'IDLE'
  });

  const renderEstadisticas = data => {
    setIndicadores({
      marketplace: {
        aside: [
          {
            titulo: 'Nº TRANSACCIONES',
            descripcion: 'Transacciones',
            icon: <ICON_NUMERO_TRANSACCIONES />,
            valor: data.transaccion
              ? formatoANumeroIngles(data.transaccion.toString())
              : data.transaccion
          },
          {
            titulo: 'RESIDUOS VALORIZADOS',
            descripcion: 'ton',
            icon: <ICON_RESIDUO_VALORIZADO />,
            valor: data.residuo?.valorizado
              ? formatoANumeroIngles(data.residuo.valorizado.toString())
              : data.residuo.valorizado
          }
        ],
        destacado: {
          titulo: 'CAMBIO CLIMÁTICO',
          descripcion: 'kg CO2eq',
          icon: <ICON_CAMBIO_CLIMATICO />,
          valor: data?.ambiental?.marketplace.ambientalCambioClimatico
            ? formatoANumeroIngles(
                data.ambiental.marketplace.ambientalCambioClimatico.toString()
              )
            : ''
        },
        principal: [
          {
            titulo: 'AGOTAMIENTO DE RECURSO FÓSIL',
            descripcion: 'kg petróleo eq',
            icon: <ICON_CAMBIO_CLIMATICO />,
            valor: data.ambiental.marketplace.ambientalRecursoFosil
              ? formatoANumeroIngles(
                  data.ambiental.marketplace.ambientalRecursoFosil.toString()
                )
              : data.ambiental.marketplace.ambientalRecursoFosil
          },
          {
            titulo: 'FORMACIÓN DE MATERIAL PARTICULADO',
            descripcion: 'kgmp 2,5eq',
            icon: <ICON_MATERIAL_PARTICULADO />,
            valor: data.ambiental.marketplace.ambientalMaterialParticulado
              ? formatoANumeroIngles(
                  data.ambiental.marketplace.ambientalMaterialParticulado.toString()
                )
              : data.ambiental.marketplace.ambientalMaterialParticulado
          },
          {
            titulo: 'DEMANDA ACUMULADA DE ENERGÍA',
            descripcion: 'MJ eq',
            icon: <ICON_DEMANDA_ACUMULADA />,
            valor: data.ambiental.marketplace.ambientalDemandaAcumulada
              ? formatoANumeroIngles(
                  data.ambiental.marketplace.ambientalDemandaAcumulada.toString()
                )
              : data.ambiental.marketplace.ambientalDemandaAcumulada
          }
        ]
      },
      gestiona: {
        aside: [
          {
            titulo: 'Nº DECLARACIONES',
            descripcion: 'declaraciones',
            icon: <ICON_NUMERO_TRANSACCIONES />,
            valor: data.declarado
              ? formatoANumeroIngles(data.declarado.toString())
              : data.declarado
          },
          {
            titulo: 'RESIDUOS DECLARADOS',
            descripcion: 'ton',
            icon: <ICON_RESIDUO_VALORIZADO />,
            valor: data.residuo?.declarado
              ? formatoANumeroIngles(data.residuo.declarado.toString())
              : data.residuo.declarado
          }
        ],
        destacado: {
          titulo: 'CAMBIO CLIMÁTICO',
          descripcion: 'kg CO2eq',
          icon: <ICON_CAMBIO_CLIMATICO />,
          valor: data?.ambiental?.gestiona.ambientalCambioClimatico
            ? formatoANumeroIngles(
                data.ambiental.gestiona.ambientalCambioClimatico.toString()
              )
            : ''
        },
        principal: [
          {
            titulo: 'AGOTAMIENTO DE RECURSO FÓSIL',
            descripcion: 'kg petróleo eq',
            icon: <ICON_CAMBIO_CLIMATICO />,
            valor: data.ambiental.gestiona.ambientalRecursoFosil
              ? formatoANumeroIngles(
                  data.ambiental.gestiona.ambientalRecursoFosil.toString()
                )
              : data.ambiental.gestiona.ambientalRecursoFosil
          },
          {
            titulo: 'FORMACIÓN DE MATERIAL PARTICULADO',
            descripcion: 'kgmp 2,5eq',
            icon: <ICON_MATERIAL_PARTICULADO />,
            valor: data.ambiental.gestiona.ambientalMaterialParticulado
              ? formatoANumeroIngles(
                  data.ambiental.gestiona.ambientalMaterialParticulado.toString()
                )
              : data.ambiental.gestiona.ambientalMaterialParticulado
          },
          {
            titulo: 'DEMANDA ACUMULADA DE ENERGÍA',
            descripcion: 'MJ eq',
            icon: <ICON_DEMANDA_ACUMULADA />,
            valor: data.ambiental.gestiona.ambientalDemandaAcumulada
              ? formatoANumeroIngles(
                  data.ambiental.gestiona.ambientalDemandaAcumulada.toString()
                )
              : data.ambiental.gestiona.ambientalDemandaAcumulada
          }
        ]
      },
      horasDeTrabajo: {
        titulo: 'HORAS DE TRABAJO',
        descripcion: 'H totales',
        icon: iconHorasDeTrabajo,
        valor: data.social.indicador.horasTrabajo
          ? formatoANumeroIngles(data.social.indicador.horasTrabajo.toString())
          : data.social.indicador.horasTrabajo
      },
      paridadMujeres: {
        titulo: 'PARIDAD MUJERES',
        descripcion: 'HM',
        icon: iconParidadMujeres,
        valor: data.social.indicador.horasTrabajoMujer
          ? formatoANumeroIngles(
              data.social.indicador.horasTrabajoMujer.toString()
            )
          : data.social.indicador.horasTrabajoMujer
      },
      salario: {
        titulo: 'SALARIO',
        descripcion: 'Mil pesos',
        icon: iconSalario,
        valor: data.social.indicador.salario
          ? formatoANumeroIngles(data.social.indicador.salario.toString())
          : data.social.indicador.salario
      },
      state: 'LOADED'
    });
  };

  const init = async () => {
    try {
      let empresas = [];

      if (isSuperAdmin) {
        const responseEmpresas =
          await empresasService.obtieneEmpresasSuperAdmin({
            totalPagina: 1000,
            ordenarPor: 'nombreEmpresa'
          });
        if (responseEmpresas.status === 200) {
          empresas = responseEmpresas.data.content.map(empresa => ({
            codigoEmpresa: empresa.codigoEmpresa,
            nombreEmpresa: empresa.nombreEmpresa,
            rutEmpresa: empresa.rutEmpresa
          }));
        }
        setEmpresas(OrdenarAscendente(empresas, 'nombreEmpresa'));
        setIsLoading(false);
      }

      cargaIndicadores(isSuperAdmin ? null : codigoEmpresa);
    } catch (error) {
      setIsLoading(false);
      console.log('Error en cargaIndicadoresIniciales ', error);
    }
  };

  const cargaIndicadores = async codigoEmpresa => {
    try {
      // se evita que se llame a la api si no se ha seleccionado una empresa
      if (!codigoEmpresa) return;
      setIsLoading(true);
      const queryIndicadores = {
        tipoIndicador: 'todos',
        codigoEmpresa: codigoEmpresa
      };

      const response = await serviceCommons.indicadores(
        queryIndicadores,
        false
      );
      if (response.status === 200) {
        renderEstadisticas(response.data);
        setResumenIndicadores(response.data.resumen);
      }
      setIsLoading(false);
    } catch (error) {
      console.log('Error en cargaIndicadoresIniciales ', error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return {
    isLoading,
    indicadores,
    conIndicadores,
    isSuperAdmin,
    empresas,
    cargaIndicadores,
    resumenIndicadores
  };
};
