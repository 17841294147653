import { DICTONARY } from 'const/Dictonary';
import { useCallback, useEffect, useState } from 'react';
import residuos from 'services/residuos';

export const useFactoresConversion = () => {
  const [factoresConversion, setFactoresConversion] = useState([]);
  const [isCargando, setCargando] = useState(true);

  const cargarFactoresConversion = useCallback(async () => {
    try {
      setCargando(true);
      const response = await residuos.factoresDeConversion(
        { totalPagina: DICTONARY.PAGINACION.ALL_DATA },
        false
      );
      setFactoresConversion(response.data.content);
    } catch (error) {
      setFactoresConversion([]);
    }
    setCargando(false);
  }, []);

  useEffect(() => {
    cargarFactoresConversion();
  }, [cargarFactoresConversion]);

  return {
    isCargando,
    factoresConversion
  };
};
