import { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import IconComponent from 'components/design-system/icon/Icon';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import ButtonComponent from 'components/design-system/Button/Button';
import { Factory as FactoryIcon } from '@mui/icons-material';
import { TaskAlt as TaskAltIcon } from '@mui/icons-material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import residuosServices from 'services/residuos';
import jwt from 'jwt-decode';
import { RUTAS } from 'const/Rutas';
import { SinSidebarLayout } from 'layouts/SinSidebarLayout';
import { Alerta, AlertaError, AlertaExito } from 'utils/Alertas';

const SubirCertificadoRecepcion = props => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const history = useHistory();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [residuoDeclarado, setResiduoDeclarado] = useState({
    cantidadunidadMedida: null,
    codigoResiduoDeclarado: null,
    correoElectronico: null,
    exp: null,
    fechaTransaccion: null,
    iat: null,
    nombreReciclador: null,
    sub: null,
    subTipoResiduo: null,
    tipoResiduo: null
  });

  // decifrando jwt
  const decifrarToken = useCallback(
    token => {
      try {
        const decifrado = jwt(token);
        return decifrado;
      } catch (error) {
        history.push(`${RUTAS.HOME}`);
        return null;
      }
    },
    [history]
  );
  const [tokenDecifrado] = useState(decifrarToken(token) || {});

  const redirectToHome = () => {
    setTimeout(() => {
      history.push(`${RUTAS.HOME}`);
    }, 3000);
  };

  useEffect(() => {
    if (
      tokenDecifrado &&
      typeof tokenDecifrado.codigoResiduoDeclarado === 'number'
    ) {
      setResiduoDeclarado(tokenDecifrado);
    } else {
      redirectToHome();
    }
  }, [tokenDecifrado]);

  const handleFileChange = event => {
    const file = event.target.files[0];
    setFileName('');
    setFile(null);

    if (file) {
      const isValidType =
        file.type === 'application/pdf' || file.type === 'image/jpeg';
      const isValidSize = file.size <= 4 * 1024 * 1024; // 4 MB

      if (!isValidType) {
        AlertaError('El archivo debe ser de tipo PDF o JPEG.');
        return;
      }

      if (!isValidSize) {
        AlertaError('El archivo no puede pesar más de 4 MB.');
        return;
      }

      const truncatedName =
        file.name.length > 20 ? `${file.name.slice(0, 19)}...` : file.name;
      setFileName(truncatedName);
      setFile(file);
    }
  };

  const handleUploadFile = async event => {
    if (file) {
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append(
          'codigoResiduoDeclarado',
          tokenDecifrado.codigoResiduoDeclarado
        );

        residuosServices
          .subirCertificadoResiduoDeclarado(token, formData)
          .then(result => {
            console.log('result: ', result);

            if (result.data?.error) {
              Alerta(result.data.error.codigo);
              return;
            }

            if (result.status === 200) {
              AlertaExito(
                'El certificado de recepción ha sido subido correctamente.'
              );
              redirectToHome();
            }
          })
          .catch(error => {
            Alerta('undefined');
          });
      } catch (error) {
        Alerta('undefined');
      } finally {
        setIsLoading(false);
      }
    } else {
      AlertaError('Debe seleccionar un archivo a subir');
    }
  };

  return (
    <SinSidebarLayout>
      <Heading type="h2">Subir certificado recepción</Heading>
      <div className="bg-white rounded shadow-md p-4 sm:p-10">
        <div className="bg-white sm:rounded p-4 sm:p-6 border-b sm:border border-neutral-90 mb-6">
          <div className="flex items-center mb-5">
            <IconComponent
              color="primary"
              className="bg-uv-primary-90 rounded-full flex w-14 h-14 items-center justify-center p-2.5"
            >
              <FactoryIcon className="w-8 h-8" />
            </IconComponent>
            <div className="pl-3">
              <Text className="text-base text-black leading-5 font-normal">
                Información del <b>Residuo recepcionado</b>
              </Text>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-y-2 sm:gap-x-10 pt-4">
            <div className="col-span-12 md:col-span-4 lg:col-span-3">
              <Text>Residuo</Text>
            </div>
            <div className="col-span-12 md:col-span-8 lg:col-span-9 mb-4 sm:mb-0">
              <Text className="text-secondary text-black">
                {residuoDeclarado.tipoResiduo}
              </Text>
            </div>
            <div className="col-span-12 md:col-span-4 lg:col-span-3">
              <Text>Sub tipo de residuo</Text>
            </div>
            <div className="col-span-12 md:col-span-8 lg:col-span-9 mb-4 sm:mb-0">
              <Text className="text-secondary text-black">
                {residuoDeclarado.subTipoResiduo}
              </Text>
            </div>

            <div className="col-span-12 md:col-span-4 lg:col-span-3">
              <Text>Cantidad</Text>
            </div>
            <div className="col-span-12 md:col-span-8 lg:col-span-9 mb-4 sm:mb-0">
              <Text className="text-secondary text-black">
                {residuoDeclarado.cantidadunidadMedida}
              </Text>
            </div>

            <div className="col-span-12 md:col-span-4 lg:col-span-3">
              <Text>Fecha transaccion</Text>
            </div>
            <div className="col-span-12 md:col-span-8 lg:col-span-9 mb-4 sm:mb-0">
              <Text className="text-secondary text-black">
                {residuoDeclarado.fechaTransaccion}
              </Text>
            </div>

            <div className="col-span-12 md:col-span-4 lg:col-span-3">
              <Text>Reciclador de Base</Text>
            </div>
            <div className="col-span-12 md:col-span-8 lg:col-span-9 mb-4 sm:mb-0">
              <Text className="text-secondary text-black">
                {residuoDeclarado.nombreReciclador}
              </Text>
            </div>
          </div>
        </div>

        <Heading type="h4">Adjuntar archivo</Heading>
        <div className="bg-white rounded shadow-md p-4">
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="flex flex-col justify-between sm:px-2">
              <ButtonComponent
                className="fullWidth justify-between h-auto"
                component="label"
                variant="contained"
                endIcon={<PostAddOutlinedIcon className="ml-2" />}
                disabled={isLoading}
              >
                {fileName ? (
                  <span>
                    Archivo seleccionado:{' '}
                    <span className="block truncate">{fileName}</span>
                  </span>
                ) : (
                  'Seleccionar archivo'
                )}
                <input type="file" hidden onChange={handleFileChange} />
              </ButtonComponent>
            </div>
            <div className="w-full lg:w-1/2 flex flex-col justify-between">
              <Text className="text-black font-semibold mb-2">
                Solo se aceptan los siguientes tipos de archivos
              </Text>
              <div className="sm:flex">
                <div className="sm:w-1/2 flex flex-col justify-between">
                  <div className="flex items-center space-x-2">
                    <TaskAltIcon className="text-uv-secondary-10" />
                    <Text>Formatos JPG y PDF</Text>
                  </div>
                </div>
                <div className="sm:w-1/2 flex flex-col justify-between">
                  <div className="flex items-center space-x-2">
                    <TaskAltIcon className="text-uv-secondary-10" />
                    <Text>Tamaño no superior a 4 MB</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end py-4">
        <ButtonComponent
          color="primary"
          endIcon={<ChevronRightIcon />}
          onClick={handleUploadFile}
          disabled={!file || isLoading}
          isLoading={isLoading}
        >
          Subir
        </ButtonComponent>
      </div>
    </SinSidebarLayout>
  );
};

export default SubirCertificadoRecepcion;
