import React, { useMemo } from 'react';
import { GpsOff, MyLocation } from '@mui/icons-material';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
import ButtonComponent from 'components/design-system/Button/Button';
import MapAddress from 'components/MapAddress/MapAddress';
import { DICTONARY } from 'const/Dictonary';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import { useDireccion } from 'hooks/useDireccion';
import { telefonoFormato } from 'components/design-system/input/InputValidations';

export const BusinessInfo = ({
  values,
  errors,
  touched,
  onChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  isSubmitting
}) => {
  const {
    latLon,
    setLatLon,
    addressQuerysearch,
    addressQueryParams,
    regiones,
    cargaListaRegiones,
    handleObtenerDireccion,
    handleObtenerComunas,
    listadoComunas,
    cargaListaComunas,
    setBuscandoDireccion,
    buscandoDireccion
  } = useDireccion();

  //  Servicio Comunas
  useMemo(() => {
    handleObtenerComunas(values.region?.codigoRegion || null);
  }, [handleObtenerComunas, values.region]);

  // Estados de carga de servicios
  useMemo(() => {
    if (isSubmitting) return;
    handleObtenerDireccion(values);
  }, [isSubmitting, handleObtenerDireccion, values]);

  // Verificacion de cambios en los campos de direccion
  useMemo(() => {
    const { nombreVia, comuna, region, isPointEditing } = values;

    if (isSubmitting || !latLon) return;

    const hasNombreVia = nombreVia && touched.nombreVia;
    const hasComuna = comuna && touched.comuna;
    const hasRegion = region && touched.region;

    if (hasNombreVia || hasComuna || hasRegion || isPointEditing) {
      setBuscandoDireccion(true);
      setFieldValue('latitudDireccion', latLon[0]);
      setFieldValue('longitudDireccion', latLon[1]);
    }
  }, [
    isSubmitting,
    latLon,
    setBuscandoDireccion,
    setFieldValue,
    touched.comuna,
    touched.nombreVia,
    touched.region,
    values
  ]);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-6 xxs:col-span-12 xs:col-span-12 lg:col-span-6 mb-4">
        <Heading type="h4" className="mb-0">
          Teléfono
        </Heading>
        <InputComponent
          autoComplete={'off'}
          fullWidth
          label={
            <LabelComponent esOpcional>
              Número de contacto de casa matríz
            </LabelComponent>
          }
          name="telefonoSucursal"
          onChange={event => {
            const newValue = telefonoFormato(event);
            setFieldValue('telefonoSucursal', newValue);
          }}
          onBlur={handleBlur}
          value={values.telefonoSucursal}
          placeholder="+56..."
          touched={touched.telefonoSucursal}
          estado={
            errors.telefonoSucursal
              ? { tipo: 'error', mensaje: errors.telefonoSucursal }
              : null
          }
        />
      </div>

      <div className="col-span-12">
        <Heading type="h4" className="mb-0">
          Dirección
        </Heading>
        <Text>
          Considera que la dirección que ingreses será usada para entregarte
          resultados basados en tu ubicación
        </Text>
      </div>

      <div className="col-span-12 xs:col-span-6 lg:col-span-3">
        <AutocompleteComponent
          name="region"
          clearOnEscape={true}
          accesor="nombreRegion"
          openOnFocus={true}
          options={regiones}
          disabled={!cargaListaRegiones}
          onChange={(ev, region, reason) => {
            let data = { name: 'region', value: region };
            if (reason === 'clear') {
              data.value = null;
              setFieldValue('comuna', null);
            }
            setFieldValue('comuna', null);
            setFieldValue('region', data.value);
          }}
          onBlur={() => setFieldTouched('region')}
          value={values.region}
          getOptionLabel={option => option.nombreRegion || ''}
          isOptionEqualToValue={(option, value) =>
            option.nombreRegion === values.region?.nombreRegion
          }
        >
          <InputComponent
            autoComplete={'off'}
            fullWidth
            touched={touched.region ? true : false}
            estado={
              errors.region ? { tipo: 'error', mensaje: errors.region } : null
            }
            label={<LabelComponent esOpcional={true}>Región</LabelComponent>}
          />
        </AutocompleteComponent>
      </div>
      <div className="col-span-12 xs:col-span-6 lg:col-span-3">
        <AutocompleteComponent
          name="comuna"
          accesor="nombreComuna"
          options={listadoComunas}
          disabled={!cargaListaComunas}
          onChange={(ev, comuna, reason) => {
            let data = { name: 'comuna', value: comuna };

            if (reason === 'clear') {
              setFieldValue('region', null);
              setFieldValue('comuna', null);
            } else {
              setFieldValue('comuna', data.value);
              setFieldValue('region', data.value.region);
            }
          }}
          value={values.comuna}
          getOptionLabel={option => option.nombreComuna || ''}
          isOptionEqualToValue={(option, value) =>
            option.nombreComuna === values.comuna?.nombreComuna
          }
          onBlur={() => setFieldTouched('comuna')}
        >
          <InputComponent
            autoComplete={'off'}
            fullWidth
            touched={touched.comuna ? true : false}
            estado={
              errors.comuna ? { tipo: 'error', mensaje: errors.comuna } : null
            }
            label={<LabelComponent>Comuna</LabelComponent>}
          />
        </AutocompleteComponent>
      </div>
      <div className="col-span-12 xs:col-span-12 lg:col-span-6">
        <div className="col-span-6 xxs:col-span-12  xs:col-span-12  lg:col-span-6">
          <InputComponent
            autoComplete={'off'}
            fullWidth
            label={<LabelComponent>Nombre y número calle</LabelComponent>}
            name="nombreVia"
            onChange={e => {
              onChange(e);
              setFieldTouched('region');
              setFieldTouched('comuna');
              setFieldTouched('nombreVia');
            }}
            onBlur={e => {
              handleBlur(e);
            }}
            value={values.nombreVia}
            placeholder="Ej: Providencia, 1091"
            touched={touched.nombreVia}
            estado={
              errors.nombreVia
                ? { tipo: 'error', mensaje: errors.nombreVia }
                : null
            }
          />
        </div>
      </div>

      <div className="col-span-12 xxs:col-span-12 xs:col-span-12">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-12 xl:col-span-9 overflow-hidden rounded">
            <MapAddress
              addressQuerysearch={addressQuerysearch}
              addressQueryParams={addressQueryParams}
              latLon={latLon}
              setLatLon={setLatLon}
              isPointEditing={values.isPointEditing}
              setIsPointEditing={value =>
                setFieldValue('isPointEditing', value)
              }
              isLoading={isSubmitting}
              buscandoDireccion={buscandoDireccion}
            />
          </div>
          <div className="col-span-12 xl:col-span-3 h-full">
            <div
              className={`${
                values.isPointEditing ? ' bg-warning-light' : 'bg-info-light '
              } p-5 justify-between rounded items-center h-full`}
            >
              <Text className="mb-4">
                {values.isPointEditing
                  ? DICTONARY.MAPA_MENSAJES.INFO_DESACTIVAR_EDICION
                  : DICTONARY.MAPA_MENSAJES.INFO_ACTIVAR_EDICION}
              </Text>
              <ButtonComponent
                className="min-w-[190px] md:min-w-fit mt-4 sm:mt-0"
                onClick={() => {
                  setFieldValue('isPointEditing', !values.isPointEditing);
                }}
              >
                {values.isPointEditing && latLon ? (
                  <>
                    Desactivar edición <GpsOff className="ml-2" />
                  </>
                ) : (
                  <>
                    Edición manual <MyLocation className="ml-2" />
                  </>
                )}
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfo;
