import { NavigateBefore } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Hidden } from '@mui/material';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import LinkComponent from 'components/design-system/Link/Link';
import { DICTONARY } from 'const/Dictonary';
import { RUTAS } from 'const/Rutas';
import { OrdenarAscendente } from 'helpers/manejoArreglos';
import { isValorNulo } from 'helpers/validaCampos';
import { useEffect, useState } from 'react';
import { obtenerCodigosResiduosDeclarados } from 'views/CargaMasiva/hooks/useServices';

export const SeleccionInicialDeDatos = ({
  tipoForm,
  handleSeleccionInicialDeDatos
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [residuoSeleccionado, setResiduoSeleccionado] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await obtenerCodigosResiduosDeclarados(setIsLoading);
      if (!Array.isArray(data)) {
        setData([]);
        return;
      }
      setData(
        OrdenarAscendente(
          data.map(item => ({
            codigoResiduoDeclarado: item.codigoResiduoDeclarado,
            nombreCodigoResiduoDeclarado:
              item.codigoResiduoDeclarado + ' codigo residuo'
          })),
          'codigoResiduoDeclarado',
          'number'
        )
      );
    };
    fetchData();
  }, []);

  return (
    <div>
      <Heading type="h2" className="mt-4 md:mt-0 mb-4 text-lg md:text-2xl">
        <div className="flex items-center">
          <LinkComponent to={`${RUTAS.GESTIONA_RESIDUO_DECLARADO}`}>
            <IconButtonComponent type="secondary" className="mr-2">
              <ChevronLeft />
            </IconButtonComponent>
          </LinkComponent>
          <span>
            {` ${DICTONARY.TIPO_FORMULARIO[tipoForm]} residuo declarado`}
          </span>
        </div>
      </Heading>
      <div className="bg-white rounded p-5 shadow-sm mb-5">
        <div className="grid grid-cols-12 gap-4 pb-40">
          <div className="col-span-12">
            <Heading type="h3">Elegir datos iniciales de declaración</Heading>
          </div>
          <div className="col-span-12 md:col-span-7">
            <div className="flex flex-col md:flex-row gap-4 md:items-end">
              <AutocompleteComponent
                classNameRoot="flex-auto"
                options={data}
                disabled={isLoading}
                accesor={'nombreCodigoResiduoDeclarado'}
                noOptionsText="No hay coincidencias"
                name="nombreCodigoResiduoDeclarado"
                getOptionLabel={option =>
                  option.nombreCodigoResiduoDeclarado || ''
                }
                value={residuoSeleccionado}
                onChange={(ev, value, reason) => {
                  let data = { name: 'comunaEmpresaGenerador', value: value };
                  if (reason === 'clear') {
                    setResiduoSeleccionado(null);
                  } else {
                    setResiduoSeleccionado(data.value);
                  }
                }}
              >
                <InputComponent
                  autoComplete={'off'}
                  className="flex-auto"
                  placeholder="Selecciona una opción"
                  disabled={isLoading}
                  fullWidth
                  label={
                    <LabelComponent>
                      Basarse en una declaración previa
                    </LabelComponent>
                  }
                />
              </AutocompleteComponent>
              <div>
                <ButtonComponent
                  className="h-10"
                  disabled={isValorNulo(residuoSeleccionado)}
                  onClick={() =>
                    handleSeleccionInicialDeDatos(
                      residuoSeleccionado.codigoResiduoDeclarado
                    )
                  }
                >
                  Continuar <ChevronRight className="ml-2" />
                </ButtonComponent>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-1 justify-center text-center flex">
            <div className="h-full w-0 border-r hidden md:block"></div>
            <div className="mt-4 h-1 w-full border-t block md:hidden"></div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <div>
              <LabelComponent>Comenzar sin datos previos</LabelComponent>
            </div>
            <ButtonComponent
              className="h-10"
              onClick={() => handleSeleccionInicialDeDatos(null)}
            >
              <Add className="mr-2" /> Crear en blanco
            </ButtonComponent>
          </div>
        </div>
        <div className="pt-4 border-t flex justify-between">
          <div>
            <LinkComponent
              to={`${RUTAS.GESTIONA_RESIDUO_DECLARADO}`}
              type="button"
            >
              <Hidden smDown>
                <NavigateBefore /> Volver
              </Hidden>
              <Hidden smUp>
                <NavigateBefore />
              </Hidden>
            </LinkComponent>
          </div>

          <div></div>
        </div>
      </div>
    </div>
  );
};
