const ValidateRut = {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  isValid: function (identificationNumber) {
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(identificationNumber)) return false;

    let sanitize = identificationNumber.trim().replaceAll('.', '');
    let tmp = sanitize.split('-');
    let digv = tmp[1];
    let rut = tmp[0];
    if (digv === 'K') digv = 'k';

    return ValidateRut.dv(rut) == digv;
  },
  dv: function (T) {
    let M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : 'k';
  },
  formatWithSlash: function (identificationNumber) {
    if (identificationNumber && !identificationNumber.includes('-')) {
      return `${identificationNumber.substring(
        0,
        identificationNumber.length - 1
      )}-${identificationNumber.substring(identificationNumber.length - 1)}`;
    } else {
      return identificationNumber;
    }
  },
  formatDotnSlash: function (identificationNumber) {
    let formated = this.formatWithSlash(identificationNumber) + '';
    for (let i = formated.indexOf('-') - 3; i > 0; i -= 3) {
      formated =
        formated.substring(0, i) + '.' + formated.substring(i, formated.length);
    }
    return formated;
  },

  formatWithoutSlash: function (identificationNumber) {
    if (!identificationNumber.includes('-')) {
      return identificationNumber;
    }
    return identificationNumber.replace('-', '');
  }
};

export default ValidateRut;
