export const SinSidebarLayout = ({
  containerClassName,
  childrenContainerClassName,
  children
}) => {
  return (
    <section className="w-full">
      <div
        className={
          containerClassName
            ? containerClassName
            : 'px-4 md:container md:mx-auto'
        }
      >
        <div className="grid grid-cols-12 gap-4">
          <div
            className={
              childrenContainerClassName
                ? childrenContainerClassName
                : 'col-span-12 pt-10'
            }
          >
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};
