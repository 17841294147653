
import { Divider } from "@mui/material"


export const SaltoLinia = (text) => {
  return <div className="col-span-12 my-8">
    <Divider />
  </div>
}

// Tooltip espesificación oferta
export const tooltipText = `Información referencial del monto en pesos ($) del residuo/recurso ofertado. 
Si usted no conoce el monto, o se trata de una transacción sin monto o una donación, 
deje el valor en 0.`;

