import Home from '@mui/icons-material/Home';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { RUTAS } from 'const/Rutas';
import { AuthContext } from 'context';
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const MenuHome = () => {
  const { logged } = useContext(AuthContext);

  const disableOpacity = disabled => {
    return {
      opacity: disabled ? 0.5 : 1
    };
  };

  return (
    <RouterLink
      to={logged ? RUTAS.HOME_USER : RUTAS.HOME}
      onClick={ev => {
        if (!logged) {
          ev.preventDefault();
        }
      }}
      style={disableOpacity(!logged)}
    >
      <IconButtonComponent className="text-white">
        <Home />
      </IconButtonComponent>
    </RouterLink>
  );
};
