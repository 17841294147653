import { useEffect, useState } from 'react';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import AlertComponent from 'components/design-system/Alert/Alert';
import { FormControlLabel, FormGroup, Hidden } from '@mui/material';
import CheckboxComponent from 'components/design-system/Checkbox/Checkbox';
import SwitchComponent from 'components/design-system/Switch/Switch';
import serviceRsd from 'services/residuos';

import { DICTONARY } from 'const/Dictonary';

export const BusinessRSD = ({
  values,
  errors,
  touched,
  onChange,
  handleBlur,
  setFieldValue,
  setFieldError
}) => {
  const [residuos, setResiduos] = useState([]);
  const [flag, setFlag] = useState(true);

  const [nombreResiduosSeleccionados, setNombreResiduosSeleccionados] =
    useState([]);

  const handleChangeSeleccionarTodo = event => {
    setFlag(!event.target.checked);
    setFieldValue(
      'todosResiduosSeleccionados',
      event.target.checked ? true : false
    );
    let arraySeleccionados = [];
    let arrayNombreResiduos = [];
    setResiduos(
      residuos.map(residuo => {
        arraySeleccionados.push(residuo.codigoTipoResiduo.toString());
        arrayNombreResiduos.push({
          codigoTipoResiduo: residuo.codigoTipoResiduo,
          nombre: residuo.nombreTipo
        });
        return {
          codigoTipoResiduo: residuo.codigoTipoResiduo,
          nombreTipo: residuo.nombreTipo,
          checked: flag ? true : false
        };
      })
    );
    setNombreResiduosSeleccionados(flag ? arrayNombreResiduos : []);
    setFieldValue('codigosTipoResiduo', flag ? arraySeleccionados : []);
  };

  useEffect(() => {}, [values.codigosTipoResiduo]);

  useEffect(() => {
    serviceRsd
      .tipoResiduos({
        totalPagina: DICTONARY.PAGINACION.DEFAULT,
        nivel: DICTONARY.TIPO_RESIDUOS.NIVEL_1
      })
      .then(response => {
        const data = response.data.content;
        setResiduos(
          data.map(residuo => {
            return {
              codigoTipoResiduo: residuo.codigoTipoResiduo,
              nombreTipo: residuo.nombreTipo,
              checked: false
            };
          })
        );
      })
      .catch(err => {
        console.error('error servicio tipoResiduos: ', err);
      });
  }, [values]);

  useEffect(() => {}, [flag]);

  useEffect(() => {
    setFieldValue('nombreResiduosSeleccionados', nombreResiduosSeleccionados);
  }, [nombreResiduosSeleccionados, setFieldValue]);

  useEffect(() => {
    values.codigosTipoResiduo.length === 0 &&
      setFieldError(
        'codigosTipoResiduo',
        'Debe seleccionar al menos un tipo de usuario'
      );
  }, [setFieldError, values.codigosTipoResiduo]);

  return (
    <div>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <Heading type="h3">
            Tipos de residuos{' '}
            <span className="text-neutral-40 font-normal ml-2">
              (asignado a sucursal casa matriz)
            </span>
          </Heading>
          <Text className="mb-8">
            Selecciona los tipos de residuos del interés de tu organización.
          </Text>
        </div>

        <div className="col-span-12 md:col-span-9 mb-4">
          <AlertComponent type="warning">
            <Heading type="h5">Atención</Heading>
            <Text>
              Solo podrás <b className="text-black">obtener resultados </b>
              en el{' '}
              <b className="text-black">
                marketplace (match automático y filtro){' '}
              </b>
              basados en los <b className="text-black">datos seleccionados. </b>
            </Text>
          </AlertComponent>
        </div>
      </div>

      <div>
        <FormControlLabel
          className="my-3 mx-0"
          control={
            <SwitchComponent
              checked={values.todosResiduosSeleccionados ? true : false}
              onChange={handleChangeSeleccionarTodo}
              name="seleccionartodo"
            />
          }
          label={
            <Text
              className={`${
                values.todosResiduosSeleccionados
                  ? 'text-uv-primary-0 font-bold'
                  : 'font-semibold'
              }`}
            >
              Seleccionar todos los residuos
            </Text>
          }
        />
        <div
          className={`bg-background-primary p-5 rounded overflow-hidden w-full overflow-y-scroll max-h-[360px] scrollbar-uv border 
          ${
            errors.codigosTipoResiduo && touched.codigosTipoResiduo
              ? 'border-error-dark'
              : 'border-background-primary'
          }
        `}
        >
          <FormGroup>
            {residuos &&
              residuos.map((item, key) => (
                <FormControlLabel
                  key={key}
                  className="w-full"
                  control={
                    <CheckboxComponent
                      name="codigosTipoResiduo"
                      touched={touched.codigosTipoResiduo}
                      value={item.codigoTipoResiduo}
                      checked={values.codigosTipoResiduo.includes(
                        item.codigoTipoResiduo.toString() || ''
                      )}
                      onChange={e => {
                        // Verificar si ya existe un objeto con el mismo código de residuo
                        const existeObjeto = nombreResiduosSeleccionados.some(
                          obj =>
                            obj.codigoTipoResiduo === item.codigoTipoResiduo
                        );
                        // Agregar objeto solo si no existe
                        if (!existeObjeto) {
                          setNombreResiduosSeleccionados([
                            ...nombreResiduosSeleccionados,
                            {
                              nombre: item.nombreTipo,
                              codigoTipoResiduo: item.codigoTipoResiduo
                            }
                          ]);
                        } else {
                          setNombreResiduosSeleccionados(
                            nombreResiduosSeleccionados.filter(
                              obj =>
                                obj.codigoTipoResiduo !== item.codigoTipoResiduo
                            )
                          );
                        }
                        onChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                  }
                  label={
                    <div className="ml-2">
                      <Hidden smDown>
                        <Heading type="h5" className="mb-1">
                          {item.nombreTipo}
                        </Heading>
                      </Hidden>
                      <Hidden smUp>
                        <Text className="text-black" size="S">
                          {item.nombreTipo}
                        </Text>
                      </Hidden>
                    </div>
                  }
                />
              ))}
          </FormGroup>
        </div>
      </div>

      {errors.codigosTipoResiduo && touched.codigosTipoResiduo && (
        <Text className="text-error-dark mt-2">
          {errors.codigosTipoResiduo ? errors.codigosTipoResiduo : ''}
        </Text>
      )}
    </div>
  );
};

export default BusinessRSD;
