import { DICTONARY } from 'const/Dictonary';
import usuariosService from 'services/usuarios';
import { AlertaExito, AlertaWarning } from 'utils/Alertas';

export const verificaCorreoRegistrado = async email => {
  let resp = false;
  await usuariosService
    .compruebaCorreoRegistrado(
      {
        correoElectronico: email.trim()
      },
      DICTONARY.INTERCEPTOR.DESACTIVADO
    )
    .then(response => {
      const { data } = response;
      if (data === true) {
        AlertaWarning('El correo ingresado ya está registrado');
        resp = true;
      } else {
        AlertaExito('El correo ingresado está disponible');
        resp = false;
      }
    })
    .catch(error => {
      console.log('error :>> ', error);
    });
  return resp;
};
