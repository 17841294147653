import { AuthContext } from 'context';
import { useContext } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { MENU } from './menus/Menu';
import { PERMISOS } from 'const/Permisos';
import { RUTAS } from 'const/Rutas';

export const SIDEBAR_MI_CUENTA = () => {
  const { logout, isTienePermisos } = useContext(AuthContext);
  return {
    section: 'Cuenta',
    enabled: true,
    items: [
      {
        nombre: 'Mi cuenta',
        icon: <PersonIcon />,
        path: RUTAS.EDITAR_CUENTA,
        subItems: [],
        enabled: isTienePermisos(PERMISOS.editarCuenta)
      },
      {
        nombre: 'Cerar Sesión',
        onClick: () => logout(),
        icon: <LogoutIcon />,
        path: RUTAS.HOME,
        subItems: [],
        enabled: true
      }
    ]
  };
};

export const SIDEBAR_MENU = (perfiles, codigoNegocio, tieneNegocio) => {
  const menu = MENU(codigoNegocio, tieneNegocio).map(m => {
    return {
      ...m,
      enabled: m.items.some(item => item.enabled)
    };
  });

  return menu;
};
