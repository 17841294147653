import { ChevronRight } from '@mui/icons-material';
import AlertComponent from 'components/design-system/Alert/Alert';
import Heading from 'components/design-system/Heading/Heading';
import { RoleComponent } from 'components/design-system/Role/Role';
import Text from 'components/design-system/Text/Text';
import {
  obtenerAutorizacionesDeObjeto,
  obtenerGradoAutorizacionesSanitarias
} from 'helpers/autorizaciones';
import { useContext } from 'react';
import { MatchContext } from 'views/Marketplace/Residuos/context/MatchContext';

export const ListadoEmpresas = () => {
  const { setEmpresaSeleccionada, matchFiltrado } = useContext(MatchContext);

  const handleSeleccionarEmpresa = empresa => {
    const residuos = matchFiltrado.residuos.filter(
      residuo => residuo.sucursal?.codigoSucursal === empresa.codigoSucursal
    );
    setEmpresaSeleccionada({
      ...empresa,
      residuos: residuos
    });
  };

  return (
    <div>
      {matchFiltrado.numeroSucursales === 0 && (
        <div className="m-4">
          <AlertComponent type="info">
            <Text size="S">
              <strong className="text-black">
                No se han encontrado resultados
              </strong>
              . Intenta realizando una nueva busqueda y modificando los
              criterios.
            </Text>
          </AlertComponent>
        </div>
      )}

      {matchFiltrado.sucursales.map((empresa, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              handleSeleccionarEmpresa(empresa);
            }}
            className="flex items-center p-4 pb-2 border-b cursor-pointer hover:bg-neutral-90"
          >
            <div className="w-4/5">
              <Heading type="h3" className="pr-10">
                {empresa.empresa}
              </Heading>
              <RoleComponent
                className="mr-2"
                arrayRol={empresa.rol}
                tipo="etiqueta"
                gradoAutorizacionesSanitarias={obtenerGradoAutorizacionesSanitarias(
                  obtenerAutorizacionesDeObjeto(empresa)
                )}
              />
            </div>
            <div className="w-1/5 text-right">
              <ChevronRight />
            </div>
          </div>
        );
      })}
    </div>
  );
};
