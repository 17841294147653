export const ICON_NUMERO_TRANSACCIONES = ({ className }) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_626_141)">
        <circle
          cx="31"
          cy="31"
          r="31"
          transform="matrix(1 0 0 -1 0 62)"
          fill="#307453"
        />
        <path
          d="M30.8175 20.636V25.818L37.7265 18.909L30.8175 12V17.182C28.3461 17.1778 25.9189 17.8376 23.7898 19.0926C21.6607 20.3475 19.9078 22.1514 18.7145 24.3156C17.5212 26.4799 16.9313 28.925 17.0064 31.3952C17.0815 33.8655 17.8189 36.2703 19.1415 38.358L21.6635 35.836C20.8644 34.3497 20.4488 32.6875 20.4545 31C20.4566 28.2521 21.5491 25.6174 23.4921 23.6742C25.435 21.7311 28.0696 20.6384 30.8175 20.636ZM42.4935 23.641L39.9715 26.163C40.8098 27.7409 41.2252 29.509 41.1771 31.2951C41.1291 33.0812 40.6194 34.8244 39.6975 36.355C38.7756 37.8856 37.4731 39.1513 35.9167 40.0289C34.3604 40.9066 32.6033 41.3662 30.8165 41.363V36.182L23.9075 43.091L30.8175 50V44.818C33.289 44.8224 35.7163 44.1627 37.8456 42.9078C39.9748 41.653 41.7279 39.8491 42.9213 37.6848C44.1147 35.5206 44.7048 33.0754 44.6297 30.605C44.5546 28.1346 43.8172 25.7298 42.4945 23.642L42.4935 23.641Z"
          fill="#FAFFFA"
        />
      </g>
      <defs>
        <clipPath id="clip0_626_141">
          <rect width="62" height="62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
