import React, { useContext, useState } from 'react';
import {
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  Hidden,
  Popper
} from '@mui/material';
import FilterList from '@mui/icons-material/FilterList';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import SelectComponent from 'components/design-system/Autocomplete/Autocomplete';
import SwitchComponent from 'components/design-system/Switch/Switch';
import Text from 'components/design-system/Text/Text';
import ButtonComponent from 'components/design-system/Button/Button';
import { Range } from 'components/design-system/Range/Range';
import TuneOutlined from '@mui/icons-material/TuneOutlined';
import { useFilterMatch } from '../../../hooks/useFilterMatch';
import { DICTONARY } from 'const/Dictonary';
import { MatchContext } from '../../../context/MatchContext';
import CheckboxComponent from 'components/design-system/Checkbox/Checkbox';
import { RoleComponent } from 'components/design-system/Role/Role';

function valuetext(value) {
  return '${value} KM';
}

/**
 * @name FiltrarMatch
 * Cuadro PopUp con opciones para filtrar el resultado de match.
 */
const FiltrarMatch = () => {
  const {
    match,
    matchEmpresas,
    matchAmpliada,
    setState,
    radioBusqueda,
    setRadioBusqueda,
    handleResetFiltros,
    tieneNegocio,
    sucursalUsuario,
    setFiltrosAplicados,
    tabActiva
  } = useContext(MatchContext);

  const {
    listRubros,
    filters,
    handleChange,
    handlerFiltrar,
    handlerBorrarFiltros,
    handleOnChangeRole,
    handleOnChangeTipoPublicacion,
    roles
  } = useFilterMatch(sucursalUsuario);

  //Modal states
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const handleClick = event => {
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleFiltrarResultados = () => {
    handlerFiltrar(
      {
        matchResiduos: match,
        matchEmpresas: matchEmpresas,
        matchAmpliada: matchAmpliada
      },
      setState
    );
    setFiltrosAplicados(filters);
  };

  const handleBorrarFiltros = () => {
    handlerBorrarFiltros(handleResetFiltros);
    setFiltrosAplicados({
      empresa: null,
      rubro: null,
      radio: 10,
      limitarRadio: false,
      rolesFiltrado: [],
      tipoPublicacion: []
    });
  };

  return (
    <div className="col-span-2 sm:col-span-1 xl:col-span-2">
      <div className="text-center">
        <ButtonComponent
          aria-describedby={id}
          onClick={handleClick}
          type="secondary"
        >
          <TuneOutlined className="justify-center min-w-[auto] mr-2" />
          <span className="hidden md:block">Filtrar resultados</span>
        </ButtonComponent>
      </div>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        className="z-30"
        placement="bottom-end"
        disablePortal
      >
        <ClickAwayListener onClickAway={handleClick}>
          <div className="bg-white shadow-popper min-w-[300px] max-w-[300px] xs:min-w-[340px] xs:max-w-[340px]">
            <div className="p-4">
              <Heading type="h5" className="mb-6">
                FILTRAR RESULTADOS
              </Heading>

              <InputComponent
                fullWidth
                label={<LabelComponent>Negocio</LabelComponent>}
                name="empresa"
                onChange={handleChange}
                value={filters.empresa}
                type="text"
                className="mb-3"
              />

              <SelectComponent
                className="mb-3"
                id="filtro-rubro"
                name="rubro"
                accesor="nombreRubro"
                value={filters.rubro}
                clearOnEscape={true}
                openOnFocus={true}
                options={listRubros}
                getOptionLabel={option => (option ? option.nombreRubro : null)}
                noOptionsText="Sin opciones"
                onChange={(ev, selected) => {
                  handleChange({
                    target: { name: 'rubro', value: selected }
                  });
                }}
              >
                <InputComponent
                  className="h-9"
                  fullWidth
                  label={<LabelComponent>Rubro</LabelComponent>}
                />
              </SelectComponent>

              {tabActiva === DICTONARY.MATCH.TABS.RESIDUOS && (
                <>
                  <LabelComponent>Tipo de publicación</LabelComponent>
                  <FormGroup>
                    <FormControlLabel
                      className="w-full"
                      name="tipoPublicacion"
                      control={
                        <CheckboxComponent
                          size="small"
                          checked={filters.tipoPublicacion.includes(
                            'generador'
                          )}
                          className="py-1"
                          name={'generador'}
                          value={'generador'}
                          onChange={(event, value) =>
                            handleOnChangeTipoPublicacion(event, value)
                          }
                        />
                      }
                      label={
                        <div className="ml-2 flex items-center">
                          <RoleComponent tipo="icono" rol={'generador'} />
                          <Text className="ml-1 capitalize">Ofrece</Text>
                        </div>
                      }
                    />
                    <FormControlLabel
                      className="w-full"
                      name="tipoPublicacion"
                      control={
                        <CheckboxComponent
                          size="small"
                          checked={filters.tipoPublicacion.includes('receptor')}
                          className="py-1"
                          name={'receptor'}
                          value={'receptor'}
                          onChange={(event, value) =>
                            handleOnChangeTipoPublicacion(event, value)
                          }
                        />
                      }
                      label={
                        <div className="ml-2 flex items-center">
                          <RoleComponent tipo="icono" rol={'receptor'} />
                          <Text className="ml-1 capitalize">Requiere</Text>
                        </div>
                      }
                    />
                  </FormGroup>
                </>
              )}

              {(tabActiva === DICTONARY.MATCH.TABS.EMPRESAS ||
                tabActiva === DICTONARY.MATCH.TABS.AMPLIADA) && (
                <>
                  <LabelComponent>Rol del negocio</LabelComponent>
                  <FormGroup>
                    {roles.map(rol => (
                      <FormControlLabel
                        key={rol}
                        className="w-full"
                        name="rolesFiltrado"
                        control={
                          <CheckboxComponent
                            size="small"
                            checked={filters.rolesFiltrado.includes(rol)}
                            className="py-1"
                            name={rol}
                            value={rol}
                            onChange={(event, value) =>
                              handleOnChangeRole(event, value)
                            }
                          />
                        }
                        label={
                          <div className="ml-2 flex items-center">
                            <RoleComponent tipo="icono" rol={rol} />
                            <Text className="ml-1 capitalize">{rol}</Text>
                          </div>
                        }
                      />
                    ))}
                  </FormGroup>
                </>
              )}

              <FormControlLabel
                className={`my-4 mx-0 ${!tieneNegocio ? 'opacity-50' : ''}`}
                name="limitarRadio"
                disabled={!tieneNegocio}
                control={
                  <SwitchComponent
                    checked={radioBusqueda.limitarRadio}
                    onChange={(ev, val) => {
                      setRadioBusqueda({
                        ...radioBusqueda,
                        limitarRadio: val
                      });
                      handleChange({
                        target: { name: 'limitarRadio', value: val }
                      });
                    }}
                    name="limitarRadio"
                  />
                }
                label={<Text className="font-semibold">Limitar distancia</Text>}
              />

              {!tieneNegocio && (
                <div className="bg-warning-light rounded px-4 py-2 mb-4">
                  <Text size="S">
                    Limitar la distancia está disponible para usuarios que hayan{' '}
                    <b className="text-black">registrado su negocio</b>
                  </Text>
                </div>
              )}

              {radioBusqueda.limitarRadio && (
                <div className="">
                  <Text>Radio de alcance</Text>
                  <div className="px-4 mb-4">
                    <Range
                      name="radio"
                      aria-label="limitar-distancia"
                      defaultValue={10}
                      max={50}
                      getAriaValueText={valuetext}
                      step={5}
                      valueLabelDisplay="auto"
                      marks={DICTONARY.MAPA_RADIO.RANGOS}
                      value={radioBusqueda.radio}
                      onChange={ev => {
                        setRadioBusqueda({
                          limitarRadio: true,
                          radio: ev.target.value
                        });
                        handleChange(ev);
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="flex gap-4">
                <div className="w-3/5">
                  <ButtonComponent
                    fullWidth
                    className="px-2 justify-center"
                    onClick={() => handleFiltrarResultados()}
                  >
                    <FilterList className="mr-1" />
                    <Hidden smUp>Filtrar</Hidden>
                    <Hidden smDown>Filtrar resultados</Hidden>
                  </ButtonComponent>
                </div>
                <div className="w-2/5">
                  <ButtonComponent
                    fullWidth
                    className="px-2 justify-center"
                    type="secondary"
                    onClick={handleBorrarFiltros}
                  >
                    <Hidden smUp>Limpiar</Hidden>
                    <Hidden smDown>Borrar filtros</Hidden>
                  </ButtonComponent>
                </div>
              </div>

              <Text size="S" className="mt-2">
                El filtro será aplicado en el resultado de todas las búsquedas.
              </Text>
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default FiltrarMatch;
