import { MyLocation } from '@mui/icons-material';
import { Divider, Skeleton } from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';

export const SucursalSkeleton = () => {
  return (
    <>
      <div className="grid grid-cols-12 gap-4 pb-6">
        <div className="col-span-12">
          <Heading type="h4" className="mb-0">
            Información de la sucursal
          </Heading>
        </div>
        <div className="col-span-4">
          <Skeleton
            variant="rounded"
            className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
          />
        </div>
        <div className="col-span-4">
          <Skeleton
            variant="rounded"
            className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
          />
        </div>

        <div className="col-span-12 mt-3 mb-3">
          <Divider />
        </div>

        <div className="col-span-12">
          <Heading type="h4" className="mb-0">
            Dirección
          </Heading>
          <Text className="mb-2">
            Considera que la dirección que ingreses será usada para entregarte
            resultados basados en tu ubicación
          </Text>
        </div>

        <div className="col-span-4">
          <Skeleton
            variant="rounded"
            className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
          />
        </div>

        <div className="col-span-4">
          <Skeleton
            variant="rounded"
            className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
          />
        </div>

        <div className="col-span-4">
          <Skeleton
            variant="rounded"
            className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
          />
        </div>

        <div className="col-span-8">
          <Skeleton
            variant="rounded"
            className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
          />
        </div>

        <div className="col-span-4">
          <Skeleton
            variant="rounded"
            className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
          />
        </div>

        <div className="col-span-12">
          <div className="bg-info-light flex px-4 py-2.5 justify-between rounded items-center">
            <Text>
              Si la ubicación en el mapa no es exacta, puedes editarlo
              manualmente
            </Text>
            <ButtonComponent disabled={true}>
              Activar edición manual <MyLocation className="ml-2" />
            </ButtonComponent>
          </div>
        </div>

        <div className="col-span-12 rounded overflow-hidden">
          <Skeleton
            variant="rounded"
            className="w-full h-80 rounded relative z-0 min-w-full flex justify-center items-center"
          />
        </div>
        <div className="col-span-12 mt-3 mb-3">
          <Divider />
        </div>
      </div>
    </>
  );
};
