import ChevronRight from "@mui/icons-material/ChevronRight";

export const BotonBase = ({
  onClick,
  icono,
  texto,
  esResponsive,
  className
}) => {

  return(
    <button 
      onClick={onClick}
      className={`hover:bg-uv-primary-80 flex items-center py-2 px-2 
        ${esResponsive 
          ? 'w-full' 
          : 'bg-white shadow-md rounded min-w-[260px]'
        }
        ${className}
      `}>
      <div className="h-12 w-12 rounded-full bg-background-primary flex justify-center items-center text-uv-primary-0 mr-2 relative min-w-[40px]">
        {icono}
      </div>
      <div className="text-left font-primary text-sm text-uv-primary-0">
        {texto}
      </div>
      <ChevronRight className="text-uv-secondary-0 ml-auto" />
    </button>
  )
}