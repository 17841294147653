export const ICON_PARIDAD_MUJERES = ({ className }) => {
  return (
    <svg className={className} width="42" height="37" viewBox="0 0 42 37">
      <defs>
        <clipPath id="clip-paridad-mujeres">
          <rect width="42" height="37" />
        </clipPath>
      </defs>
      <g id="paridad-mujeres" clip-path="url(#clip-paridad-mujeres)">
        <g
          id="Grupo_1944"
          data-name="Grupo 1944"
          transform="translate(23143 2746)"
        >
          <path
            id="ic_people_outline_24px"
            d="M28.028,18.95a20.9,20.9,0,0,0-7.847,1.744,20.569,20.569,0,0,0-7.847-1.744C8.55,18.95,1,20.834,1,24.618v4.8H39.362v-4.8C39.362,20.834,31.812,18.95,28.028,18.95ZM21.053,26.8H3.616v-2.18c0-.942,4.464-3.052,8.719-3.052s8.719,2.11,8.719,3.052Zm15.694,0H23.669v-2.18a3.173,3.173,0,0,0-.907-2.127,16.822,16.822,0,0,1,5.266-.924c4.255,0,8.719,2.11,8.719,3.052ZM12.334,17.207a6.1,6.1,0,1,0-6.1-6.1A6.11,6.11,0,0,0,12.334,17.207Zm0-9.591A3.488,3.488,0,1,1,8.847,11.1,3.5,3.5,0,0,1,12.334,7.616Zm15.694,9.591a6.1,6.1,0,1,0-6.1-6.1A6.11,6.11,0,0,0,28.028,17.207Zm0-9.591A3.488,3.488,0,1,1,24.541,11.1,3.5,3.5,0,0,1,28.028,7.616Z"
            transform="translate(-23142.242 -2748.936)"
            fill="#225B2A"
          />
        </g>
      </g>
    </svg>
  );
};
