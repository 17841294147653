//Logica para habilitar botón de inicio de transacción.
import { DICTONARY } from 'const/Dictonary';
import transacciones from 'services/transacciones';

export const isBloquearBotonTransaccionChat = (
  transacciones,
  codigoResiduo
) => {
  try {
    const noPermitir = transacciones.find(
      tr =>
        tr.residuo.codigoResiduo === codigoResiduo &&
        incluyeTransaccionEstado(tr.transaccionEstado)
    );

    return noPermitir ? true : false;
  } catch (error) {
    console.log('EVALUAR_TRANSACCION_ESTADO', error);
    return false;
  }
};

export const isBloquearBotonTransaccion = residuo => {
  try {
    if (!Object.hasOwn(residuo, 'transaccionesEstado')) {
      return false;
    }

    const noPermitir = incluyeTransaccionEstado(residuo.transaccionesEstado);
    return noPermitir ? true : false;
  } catch (error) {
    console.log('EVALUAR_TRANSACCION_ESTADO', error);
    return false;
  }
};

export const incluyeTransaccionEstado = transaccionEstado => {
  try {
    if (!transaccionEstado) {
      return false;
    }

    const estadoVigente = transaccionEstado.find(
      transaccion => transaccion.vigente
    );

    const ultimoNombre = estadoVigente.estado.nombreEstado.toUpperCase();
    const estadoCerrado =
      DICTONARY.ESTADOS_NO_APLICA_INICIAR_TRANSACCION_ARRAY.includes(
        ultimoNombre
      );

    return estadoCerrado;
  } catch (error) {
    console.log('EVALUAR_TRANSACCION_ESTADO', error);
    return false;
  }
};
