import * as yup from 'yup';

const schemaContrasena = yup.object().shape({
  passwordActual: yup.string().required('Este campo es obligatorio'),

  password: yup
    .string()
    .trim()
    .test(
      'length',
      'La contraseña debe tener al menos 8 caracteres',
      value => value.length >= 8
    )
    .matches(/([0-9])/, 'numero')
    .matches(/([A-Z])/, 'mayuscula')
    .matches(/([a-z])/, 'minuscula')
    .matches(/([!@#$%^&*(),.?":{}|<>])/, 'caracter')
    .required('Ingrese la nueva contraseña'),

  confirmarPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden')
    .required('Repita la nueva contraseña')
});

export { schemaContrasena };
