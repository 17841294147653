import { useMemo } from 'react';
import Text from 'components/design-system/Text/Text';

export const useColumnTablesListadoPerfiles = () => {
  const columnTables = useMemo(
    () => [
      {
        header: 'Código',
        enableGlobalFilter: false,
        accessorKey: 'codigoPerfil',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>,
        enableColumnActions: false,
        enableColumnFilter: false,
        muiTableHeadCellProps: {
          align: 'left'
        },
        muiTableBodyCellProps: {
          align: 'left'
        }
      },
      {
        header: 'Nombre Perfil',
        accessorKey: 'nombrePerfil',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>
      }
    ],
    []
  );

  return columnTables;
};
