import { Add, Delete } from "@mui/icons-material";
import Text from "components/design-system/Text/Text";
import IconButtonComponent from "components/design-system/IconButton/IconButton";
import InputComponent from "components/design-system/input/Input";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { schemaAgregarEnlaces } from "./schemaAgregarEnlaces";
import ButtonComponent from "components/design-system/Button/Button";

export const AgregarEnlaces = ({
  onChange,
  enlaces,
  error
}) => {
  const [listaEnlaces, setListaEnlaces] = useState(enlaces);

  
  const handleDeleteItem = (key) => {
    let nuevoListado = listaEnlaces.filter(enlaces => enlaces.key !== key); 
    setListaEnlaces(nuevoListado);
    onChange(nuevoListado);
  };

  useEffect(() => {

    if(enlaces === null || enlaces === undefined || enlaces.length === 0){
      setListaEnlaces([]);
    }else{
      setListaEnlaces(enlaces);
    }
  }, [enlaces])
  
  

  return(
    <Formik
      initialValues={{
        url: '',
        descripcion: ''
      }}
      validateOnMount={false}
      validationSchema={schemaAgregarEnlaces}
      validateOnBlur={false}
      onSubmit={(values, { resetForm }) => {
        let nuevoListado = [ 
          ...listaEnlaces,
          { key: (Math.random() * 100),
            ...values
          }
        ];
        setListaEnlaces(nuevoListado);
        resetForm();
        onChange(nuevoListado);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleChange,
        isSubmitting,
        validateForm,
        submitForm
      }) => (
        <Form id="agregarEnlaces">
          <div className={`
            border rounded pt-0 px-4 pb-4 shadow-sm
            ${error ? 'border-error-dark' : ''}
          `}>
            
            {listaEnlaces.map( itemEnlace => 
              <div 
                key={itemEnlace.key}
                className="grid grid-cols-12 gap-5 items-center border-b py-2 my-1">
                <div className="col-span-5">
                  <Text className="break-words">{itemEnlace.url}</Text>
                </div>
                <div className="col-span-5">
                  <Text className="break-words">{itemEnlace.descripcion}</Text>
                </div>
                <div className="col-span-2">
                  <IconButtonComponent 
                    onClick={() => handleDeleteItem(itemEnlace.key)}
                    type="error" size="small">
                    <Delete />
                  </IconButtonComponent>
                </div>
              </div>
            )}
            
            <div className="grid grid-cols-12 gap-5 items-start mt-4">
              <div className="col-span-5">
                <InputComponent
                  fullWidth
                  name="url"
                  value={values.url}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('url')}
                  touched={touched.url ? true : false} 
                  placeholder="Enlace (URL)"
                  estado={
                    errors.url
                      ? { tipo: 'error', mensaje: errors.url }
                      : null
                  } 
                />
              </div>
              <div className="col-span-5">
                <InputComponent
                  fullWidth
                  name="descripcion"
                  value={values.descripcion}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('descripcion')}
                  touched={touched.descripcion ? true : false}
                  placeholder="Descripción"
                  estado={
                    errors.descripcion
                      ? { tipo: 'error', mensaje: errors.descripcion }
                      : null
                  }
                />
              </div>
              <div className="col-span-2">
                <ButtonComponent 
                  onClick={(event) => {
                    event.preventDefault();
                    validateForm()
                      .then(result => {
                        submitForm()
                      })
                      .catch(err => {
                        console.log(err);
                      });
                  }}
                  form="agregarEnlaces"
                  buttonType="submit"
                  type="secondary">
                  <Add /> Añadir
                </ButtonComponent>
              </div>
            </div>
            
          </div>
        </Form>
      )}
    </Formik>
  )

};