import { BotonBase } from "./BotonBase";
import home2Opciones from "assets/images/home/home2Opciones.svg";

export const Layout2Opciones = ({opciones}) => {
  const [primeraOpcion, segundaOpcion]  = opciones;

  return(
    <>
      <div>
        <BotonBase
          onClick={primeraOpcion.onClick}
          texto={primeraOpcion.texto}
          icono={primeraOpcion.icono}
        />
      </div>

      <div>
        <img src={home2Opciones} alt="Sucursal" />
      </div>

      <div>
        <BotonBase 
          onClick={segundaOpcion.onClick}
          texto={segundaOpcion.texto}
          icono={segundaOpcion.icono}
        />
      </div>
    </>
  )
};