import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { PopperComponent } from 'components/design-system/Popper/Popper';
import { AuthContext } from 'context';
import { useContext } from 'react';
import Text from 'components/design-system/Text/Text';
import { Link } from '@mui/icons-material';
import SubMenuTutoriales from '../responsive/submenu/SubMenuTutoriales';

export const MenuTutoriales = ({ forceRefresh }) => {
  const {
    perfiles: { isAnalistaTerritorial },
    logged
  } = useContext(AuthContext);

  return (
    <>
      {logged && !isAnalistaTerritorial ? (
        <PopperComponent
          Button={
            <IconButtonComponent
              className="text-white rounded"
              aria-controls="gestiona-menu"
              aria-haspopup="true"
            >
              <Link />
              <Text
                size="M"
                className="text-white ml-1 font-secondary font-normal"
              >
                Tutoriales
              </Text>
            </IconButtonComponent>
          }
        >
          <div className="px-3">
            <SubMenuTutoriales forceRefresh={forceRefresh} />
          </div>
        </PopperComponent>
      ) : null}
    </>
  );
};
