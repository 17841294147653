import ButtonComponent from 'components/design-system/Button/Button';
import Text from 'components/design-system/Text/Text';
import { Negocio } from './Negocio';

export const Resultados = ({
  dataNegocios,
  itemsToShow,
  handleShowMore,
  tipoNegocio,
  selectedNegocio,
  cargarEmpresas
}) => {
  let length = selectedNegocio ? 1 : dataNegocios?.length || 0;
  return (
    <div className={`mb-10`}>
      <div className="flex">
        <Text className="mb-2">
          <strong>{length}</strong>{' '}
          {tipoNegocio === 'generador'
            ? length === 1
              ? 'generador'
              : 'generadores'
            : null}
          {tipoNegocio === 'receptor'
            ? length === 1
              ? 'receptor'
              : 'receptores'
            : null}
          {length === 1 ? ' encontrado' : ' encontrados'}
        </Text>
      </div>

      {selectedNegocio ? (
        <Negocio
          key={selectedNegocio.value}
          negocio={selectedNegocio}
          tipoNegocio={tipoNegocio}
          cargarEmpresas={cargarEmpresas}
        />
      ) : (
        dataNegocios
          .sort((a, b) => b.totalResiduosDeclarados - a.totalResiduosDeclarados)
          .slice(0, itemsToShow)
          .map(negocio => (
            <Negocio
              key={negocio.value}
              negocio={negocio}
              tipoNegocio={tipoNegocio}
              cargarEmpresas={cargarEmpresas}
            />
          ))
      )}

      {itemsToShow < dataNegocios.length && ( // Mostrar el botón solo si hay más elementos
        <div className="text-center m-4">
          <ButtonComponent type="secondary" onClick={handleShowMore}>
            Ver más resultados
          </ButtonComponent>
        </div>
      )}
    </div>
  );
};
