import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { BuscarMaterialFormik } from './BuscarMaterial/BuscarMaterialFormik';
import { ResultadoBusqueda } from './ResultadoBusqueda/ResultadoBusqueda';
import PropTypes from 'prop-types';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';

const Buscar = props => {
  const [buscando, setBuscando] = useState(false);
  const [listaDocumentos, setListaDocumentos] = useState([]);
  const [busquedaEfectuada, setBusquedaEfectuada] = useState(false);

  return (
    <SidebarLayout>
      <div className="px-4 md:container md:mx-auto pt-5">
        <div className="mb-4">
          <Heading type="h2" className="mb-0">
            Material de información
          </Heading>
          <Text className="mb-0">
            Busca y visualiza material de información
          </Text>
        </div>

        <div className="bg-white rounded shadow-md p-5">
          <BuscarMaterialFormik
            setBuscando={setBuscando}
            setListaDocumentos={setListaDocumentos}
            setBusquedaEfectuada={setBusquedaEfectuada}
          />
        </div>

        <div className="pt-5 mb-5">
          <ResultadoBusqueda
            buscando={buscando}
            busquedaEfectuada={busquedaEfectuada}
            listaDocumentos={listaDocumentos}
            setListaDocumentos={setListaDocumentos}
          />
        </div>
      </div>
    </SidebarLayout>
  );
};

Buscar.propTypes = {
  history: PropTypes.object
};

export default withRouter(Buscar);
