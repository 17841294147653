import AccordionComponent from 'components/design-system/Accordion/Accordion';
import Table from 'components/design-system/Table/Table';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import { useMemo } from 'react';

export const TablaSinergias = ({
  dataPotencialSinergias,
  total,
  pagination,
  isLoading,
  setPagination,
  sucursales,
  setSucursales,
  registroSeleccionado,
  setRegistroSeleccionado,
  setFiltros,
  setCoordenadasMapa,
  filaTablaSeleccionada,
  setFilaTablaSeleccionada,
  setMostrarMensajeSinDireccion
}) => {
  

  const tablaColumns = useMemo(
    () => [
      {
        header: 'Empresa origen',
        accessorKey: 'empresaATOrigen',
        Cell: ({ renderedCellValue }) => (
          <Text>
            {renderedCellValue?.razonSocial
              ? renderedCellValue.razonSocial
              : ''}
          </Text>
        )
      },
      {
        header: 'Empresa destino',
        accessorKey: 'empresaATDestino.razonSocial',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>
      },
      {
        header: 'Tipo Residuo',
        size: 400,
        accessorKey: 'empresaATOrigen.actividad',
        Cell: ({ renderedCellValue }) => (
          <div className="flex">
            {renderedCellValue &&
            Object.hasOwn(renderedCellValue, 'actividadTipoResiduoOrigen') ? (
              <>
                {renderedCellValue.actividadTipoResiduoOrigen.length > 3 ? (
                  <>
                    <AccordionComponent
                      direction="row"
                      className="mr-2"
                      previewContent={
                        <ul>
                          <Text>
                            {renderedCellValue.actividadTipoResiduoOrigen
                              .slice(0, 3)
                              .map((item, index) => (
                                <li className="relative pl-4 mb-1" key={index}>
                                  <span className="w-1 h-1 rounded bg-uv-secondary-0 absolute left-0 top-2"></span>
                                  {item.nombreTipo}
                                </li>
                              ))}
                            <li className="text-neutral-50">
                              Otros{' '}
                              <strong className="text-black">
                                {renderedCellValue.actividadTipoResiduoOrigen
                                  .length - 3}
                              </strong>{' '}
                              tipos de residuos...
                            </li>
                          </Text>
                        </ul>
                      }
                    >
                      <ul>
                        {renderedCellValue.actividadTipoResiduoOrigen.map(
                          (item, index) => (
                            <li className="relative pl-4 mb-1" key={index}>
                              <Text>
                                <span className="w-1 h-1 rounded bg-uv-secondary-0 absolute left-0 top-2"></span>
                                {item.nombreTipo}
                              </Text>
                            </li>
                          )
                        )}
                      </ul>
                    </AccordionComponent>
                  </>
                ) : (
                  <ul className="pl-12">
                    {renderedCellValue.actividadTipoResiduoOrigen.map(
                      (item, index) => (
                        <li className="relative pl-4 mb-1" key={index}>
                          <Text>
                            <span className="w-1 h-1 rounded bg-uv-secondary-0 absolute left-0 top-2"></span>
                            {item.nombreTipo}
                          </Text>
                        </li>
                      )
                    )}
                  </ul>
                )}
              </>
            ) : (
              ''
            )}
          </div>
        )
      }
    ],
    []
  );

  const handleSeleccionarEmpresa = empresa => {
    if (empresa !== registroSeleccionado) {
      setFiltros(prevState => ({
        ...prevState,
        codigoEmpresaGraficoNodos: [empresa.empresaATOrigen.codigoEmpresaAT],
        tipoPeticion: DICTONARY.LINEA_BASE_ESTADOS.SELECCIONA_PUNTO_TABLA,
        codigoEmpresaAT: empresa.empresaATOrigen.codigoEmpresaAT
      }));
      setPagination({
        pageIndex:DICTONARY.PAGINACION.DEFAULT.pageIndex,
        pageSize:DICTONARY.PAGINACION.DEFAULT.pageSize
      })
      setRegistroSeleccionado(empresa);
    }
    if(empresa.empresaATOrigen && empresa.empresaATOrigen.direccion && empresa.empresaATOrigen.direccion.latitudDireccion && empresa.empresaATOrigen.direccion.longitudDireccion){
      setCoordenadasMapa([
        empresa.empresaATOrigen.direccion.latitudDireccion,
        empresa.empresaATOrigen.direccion.longitudDireccion
      ]);
      setMostrarMensajeSinDireccion(false);
    } else {
      setMostrarMensajeSinDireccion(true);
    }
  };

  return (
    <Table
      muiTableContainerProps={{ sx: { height: '600px' } }}
      columns={tablaColumns}
      data={dataPotencialSinergias}
      className="h-full"
      rowCount={total}
      isLoading={isLoading}
      enableColumnActions={false}
      enableColumnFilters={false}
      manualPagination
      enablePagination={true}
      onPaginationChange={setPagination}
      enableTopToolbar={false}
      getRowId={(row, index) => `${row.codigoPotencialSinergia}#${index}`}
      muiTableBodyRowProps={({ row }) => ({
        onClick: ev => {
          if (
            ev.target.parentElement.nodeName === 'BUTTON' ||
            ev.target.parentElement.nodeName === 'svg'
          ) {
            return;
          }
          if (row.id === filaTablaSeleccionada.id) {
            setFilaTablaSeleccionada({ row: -1 });
            setRegistroSeleccionado(null);
            setSucursales({
              ...sucursales,
              seleccionados: []
            });
            setFiltros(prevState => ({
              ...prevState,
              tipoPeticion: DICTONARY.LINEA_BASE_ESTADOS.DESELECCIONA_PUNTO,
              codigoEmpresaAT: null
            }));
            return;
          }
          setFilaTablaSeleccionada(row);
          handleSeleccionarEmpresa(row.original);
        },
        selected: filaTablaSeleccionada.id === row.id,
        sx: {
          cursor: 'pointer'
        }
      })}
      state={{
        isLoading,
        showProgressBars: false,
        pagination
      }}
    />
  );
};
