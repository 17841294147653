// Expresión regular para permitir solo números y letras
const regex = /^[kK0-9-]*$/;

export const handleKeyPress = event => {
  const { key } = event;
  // Verificar si la tecla presionada no es un número ni una letra
  if (!regex.test(key) && key !== 'Backspace' && key !== 'Delete') {
    event.preventDefault();
  }
};
