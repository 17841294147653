import Dropzone from 'components/design-system/Dropzone/Dropzone';
import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import { FormControlLabel, RadioGroup } from '@mui/material';
import RadioComponent from 'components/design-system/Radio/Radio';
import { FileUploadComponent } from 'components/design-system/FileUpload/FileUpload';
import { Upload } from '@mui/icons-material';
import InputComponent from 'components/design-system/input/Input';

export const BusinessCertifications = ({
  values,
  errors,
  touched,
  setFieldValue
}) => {
  const isRecicladorBase = values.codigoRolesEmpresa.includes(
    DICTONARY.ROL.RECICLADORDEBASE.CODIGO.toString()
  );
  const isReceptor = values.codigoRolesEmpresa.includes(
    DICTONARY.ROL.RECEPTOR.CODIGO.toString()
  );
  const isGestor = values.codigoRolesEmpresa.includes(
    DICTONARY.ROL.GESTOR.CODIGO.toString()
  );

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 lg:col-span-9">
        {(isRecicladorBase || isReceptor || isGestor) && (
          <>
            <Heading type="h3" className="mb-4">
              Archivos adjuntos
            </Heading>
            <BloqueCargaArchivo
              titulo="¿Tienes autorización sanitaria del transporte?"
              tipoAcreditacion="autorizacionTransporte"
              campoObjeto="autorizacionTransporteFile"
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
            ></BloqueCargaArchivo>

            <BloqueCargaArchivo
              titulo="¿Tienes autorización sanitaria de lugar de acopio?"
              tipoAcreditacion="autorizacionAcopio"
              campoObjeto="autorizacionAcopioFile"
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
            ></BloqueCargaArchivo>
          </>
        )}

        {isRecicladorBase && (
          <BloqueCargaArchivo
            titulo="¿Estás inscrito en el registro Nacional de Recicladores de Base en el ministerio de medio ambiente?"
            tipoAcreditacion="registroNacionalRDB"
            campoObjeto="registroNacionalRDBFile"
            values={values}
            setFieldValue={setFieldValue}
            touched={touched}
            errors={errors}
          ></BloqueCargaArchivo>
        )}
      </div>

      <div className="col-span-12 lg:col-span-9">
        <Heading type="h3" className="mb-4">
          Otras acreditaciones o certificaciones
        </Heading>
        <Text className="mb-4">
          Puedes cargar las acreditaciones y certificaciones de tu negocio, las
          cuales serán visibles para los usuarios que quieran ver el perfíl de
          tu negocio. Este paso lo puedes realizar ahora si tienes los
          antecedentes o en otra ocación.
        </Text>
        <Dropzone
          setDataFiles={data => setFieldValue('archivosEmpresa', data)}
          dataFiles={values.archivosEmpresa || []}
          {...DICTONARY.DROPZONE.CONFIG_UPLOAD_BUSINESS_FILES}
        >
          <Text className="text-black font-medium">
            Solo se aceptan los siguientes tipos de archivos
          </Text>
          <ul className="flex flex-col md:flex-row  mt-1">
            <li className="flex items-center mb-1">
              <IconComponent className="mr-2">
                <TaskAltIcon className="text-base text-uv-primary-20" />
              </IconComponent>
              <Text className="text-neutral-40">Formatos JPG y PDF</Text>
            </li>
            <li className="flex items-center mb-1 md:ml-6">
              <IconComponent className="mr-2">
                <TaskAltIcon className="text-base text-uv-primary-20" />
              </IconComponent>
              <Text className="text-neutral-40">Tamaño no superior a 4 MB</Text>
            </li>
          </ul>
        </Dropzone>
      </div>
    </div>
  );
};

const BloqueCargaArchivo = ({
  titulo,
  tipoAcreditacion,
  campoObjeto,
  values,
  setFieldValue,
  touched,
  errors
}) => {
  return (
    <div className="mb-10">
      <Heading type="h4" className="md:pr-40">
        {titulo}
      </Heading>
      <RadioGroup
        value={values[tipoAcreditacion]}
        aria-labelledby={tipoAcreditacion}
        defaultValue={tipoAcreditacion}
        name={tipoAcreditacion}
        onChange={event => {
          setFieldValue(
            tipoAcreditacion,
            event.target.value === 'true' ? true : false
          );
        }}
      >
        <FormControlLabel
          value={true}
          control={<RadioComponent />}
          label={<Text>Si</Text>}
        />
        <FormControlLabel
          value={false}
          control={<RadioComponent />}
          label={<Text>No</Text>}
        />
      </RadioGroup>

      <div
        className={`${values[tipoAcreditacion] ? 'opacity-100' : 'opacity-50'}`}
      >
        <Text className="mb-4">
          Puedes cargar la autorización sanitaria entregada por el Ministerio de
          Medio Ambiente de tu negocio, la cual será visible para los usuarios
          que quieran ver el perfíl de tu negocio. Este paso puede realizarse
          ahora si tienes los antecedentes o en otra ocación.
        </Text>

        <Heading type="h5">
          Cargar autorización sanitaria o código de autorización
        </Heading>

        <div className="bg-white shadow-md grid grid-cols-12 md:gap-5 px-4 py-3">
          <div className="col-span-12 md:col-span-5">
            <FileUploadComponent
              setDataFiles={data => {
                if (data.length === 0) {
                  return;
                }
                const archivoCargado = data[0];
                setFieldValue(campoObjeto, {
                  ...values[campoObjeto],
                  nombreArchivo: archivoCargado.nombreArchivo,
                  tipoAcreditacion: tipoAcreditacion,
                  tipoArchivo: archivoCargado.type,
                  base64: archivoCargado.base64
                });
              }}
              dataFiles={[values[campoObjeto]]}
              enableEditingFile={false}
              disabled={!values[tipoAcreditacion]}
              {...DICTONARY.DROPZONE.CONFIG_UPLOAD_ARCHIVOS_ADJUNTOS}
            >
              <Upload className="mr-2" />
              Seleccionar archivo
            </FileUploadComponent>
          </div>
          <div className="hidden md:block col-span-2 items-center text-center pt-2">
            <Text>o</Text>
          </div>
          <div className="col-span-12 md:col-span-5">
            <InputComponent
              fullWidth
              disabled={!values[tipoAcreditacion]}
              placeholder="Código de autorización"
              onChange={event =>
                setFieldValue(campoObjeto, {
                  ...values[campoObjeto],
                  tipoAcreditacion: tipoAcreditacion,
                  codigoValidacion: event.target.value
                })
              }
              value={values[campoObjeto].codigoValidacion}
              touched={touched[tipoAcreditacion]}
              estado={
                errors[tipoAcreditacion]
                  ? {
                      tipo: 'error',
                      mensaje: errors[tipoAcreditacion]
                    }
                  : null
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessCertifications;
