import React from 'react';
import { withRouter } from 'react-router-dom';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import CuentaFormik from './InformacionContacto/CuentaFormik';
import { ContrasenaFormik } from './Contrasena/ContrasenaFormik';

const MiCuenta = props => {
  return (
    <SidebarLayout>
      <CuentaFormik />
      <ContrasenaFormik />
    </SidebarLayout>
  );
};

export default withRouter(MiCuenta);
