import { useContext, useEffect, useState } from 'react';
import { DICTONARY } from 'const/Dictonary';
import empresasService from 'services/empresas';
import { AuthContext } from 'context';
import { OrdenarAscendente } from 'helpers/manejoArreglos';

export const useIndicadores = codigoEmpresa => {
  const {
    perfiles: { isSuperAdmin }
  } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [sucursales, setSucursales] = useState([]);

  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(null);
  const [empresas, setEmpresas] = useState([]);
  const [loadingEmpresas, setLoadingEmpresas] = useState(false);

  useEffect(() => {
    if (
      isSuperAdmin &&
      empresaSeleccionada &&
      empresaSeleccionada.codigoEmpresa === codigoEmpresa &&
      sucursales.length > 0
    ) {
      return;
    }
    cargarSucursales();
  }, [empresaSeleccionada]);

  useEffect(() => {
    if (isSuperAdmin) {
      cargarEmpresas();
    }
  }, []);

  const cargarSucursales = async () => {
    setIsLoading(true);
    let listaSucursales = [];

    try {
      if (isNaN(codigoEmpresa) && isNaN(empresaSeleccionada?.codigoEmpresa)) {
        setIsLoading(false);
        return;
      }

      const response = await empresasService.obtieneSucursales({
        codigoEmpresa: empresaSeleccionada
          ? empresaSeleccionada.codigoEmpresa
          : codigoEmpresa,
        totalPagina: DICTONARY.PAGINACION.ALL_DATA
      });

      const error = response.data.error;
      if (error) throw new Error('ERROR_AL_CARGAR_SUCURSALES');

      listaSucursales = response.data.content;

      const promises = listaSucursales.map(sucursal => {
        return empresasService
          .getSucursal(sucursal.codigoSucursal)
          .then(responseSucursal => {
            return responseSucursal && responseSucursal.data
              ? responseSucursal.data
              : null;
          });
      });

      Promise.all(promises)
        .then(sucursales => {
          setSucursales(sucursales);
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      setSucursales([]);
      console.log('Error al obtener las sucursales ', error);
    }
  };

  const cargarEmpresas = async () => {
    setLoadingEmpresas(true);
    try {
      const response = await empresasService.obtieneEmpresasSuperAdmin({
        totalPagina: DICTONARY.PAGINACION.ALL_DATA,
        ordenarPor: 'nombreEmpresa'
      });

      const error = response.data.error;
      if (error) throw new Error('ERROR_AL_CARGAR_EMPRESAS');

      const empresasAux = response.data.content.map(empresa => ({
        codigoEmpresa: empresa.codigoEmpresa,
        nombreEmpresa: empresa.nombreEmpresa,
        rutEmpresa: empresa.rutEmpresa
      }));

      if (!isNaN(codigoEmpresa)) {
        setEmpresaSeleccionada(
          empresasAux.find(item => item.codigoEmpresa === codigoEmpresa)
        );
      }

      setEmpresas(OrdenarAscendente(empresasAux, 'nombreEmpresa'));
    } catch (error) {
      setEmpresas([]);
      console.log('error al cargar las empresas', error);
    }
    setLoadingEmpresas(false);
  };

  return {
    isLoading,
    isSuperAdmin,
    loadingEmpresas,

    sucursales,
    setSucursales,

    empresas,
    empresaSeleccionada,
    setEmpresaSeleccionada
  };
};
