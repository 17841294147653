// Importaciones de react
import { AuthContext } from 'context';
import { useContext, useState } from 'react';

export const useInitalValues = () => {
  const { logeduser } = useContext(AuthContext);
  const sucursalContext = logeduser.sucursal;

  const [residuoDemandante, setResiduoDemandante] = useState({
    rol: {
      codigoRol: 2
    },
    // Seleccionar la sucursal de origen
    sucursal: sucursalContext || null, // codigoSucursal registrado en la base de datos (Integer)
    direccion: sucursalContext ? sucursalContext.direccion : {},
    nombreDireccionSucursal: sucursalContext
      ? `${sucursalContext.direccion.nombreVia}, ${sucursalContext.direccion.comuna?.nombreComuna}, Región ${sucursalContext.direccion.comuna?.region?.nombreRegion} `
      : '',
    nombreSucursal: '',

    // Tipo de Residuo
    tipoResiduo: [],
    // SubTipo de Residuo
    subTipoResiduo: [],
    tieneSubTipoResiduo: false,

    //Nombre Residuo
    nombreResiduo: '',

    // Tipo de Recursos
    tipoRecurso: [],
    tieneTipoRecursos: false,

    // Otro Tipo de Recursos
    otroTipoRecurso: [],
    tieneOtroTipoRecurso: false,

    // Tipo de Recurso de Reemplazo
    recursoReemplazo: [],
    tieneTipoRecursosReemplazo: false,
    // Sub Tipo de Recurso de Reemplazo
    subTipoRecursoReemplazo: {},

    // Descripción adicional
    descripcionAdicional: ''
  });

  const [residuoOferente, setResiduoOferente] = useState({
    // PASO 1

    rol: {
      codigoRol: 1
    },

    // Nombre LER
    nombreLER: [],

    // Nombre Sub Tipo LER
    nombreSubLER: [],

    // Nombre Sub Sub Tipo LER
    nombreSubSubLER: [],

    // nombreResiduo: '',

    // Tipo de Residuo
    tipoResiduo: [],

    // SubTipo de Residuo
    subTipoResiduo: [],

    tieneSubTipoResiduo: false,
    tieneTipoRecursos: false,
    tieneOtroTipoRecurso: false,

    // Otro Tipo de Residuo
    otroSubtipoResiduo: '',
    tieneOtroSubtipoResiduo: false,

    // Tipo de Recursos
    tipoRecurso: [],

    // Otro Tipo de Recursos
    otroTipoRecurso: [],

    // Descipción adicional
    descripcionAdicional: '',

    // PASO 2
    // TODO: controlar que se llenan los campos de la sucursal cuando solo tenga una
    sucursal: sucursalContext || null, // codigoSucursal registrado en la base de datos (Integer)
    direccion: sucursalContext ? sucursalContext.direccion : {},
    nombreDireccionSucursal: sucursalContext
      ? `${sucursalContext.direccion.nombreVia}, ${sucursalContext.direccion.comuna?.nombreComuna}, Región ${sucursalContext.direccion.comuna?.region?.nombreRegion} `
      : '',
    nombreSucursal: '',
    precioReferencial: '',
    frecuencia: '',
    fechaDisponibilidad: new Date(),
    formatoEntrega: '',
    cantidad: 0,
    unidadMedida: null,

    // Paso 3
    imagenes: [],
    archivosResiduos: []
  });

  return {
    residuoDemandante,
    setResiduoDemandante,
    residuoOferente,
    setResiduoOferente
  };
};
