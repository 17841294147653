import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from '../components';
import { Main as MainLayout } from 'layouts';
import {
  NotFound as NotFoundView,
  Home as HomeView,
  Signup,
  VerifyEmail,
  ConfirmEmail,
  VerificaUsuario,
  VerificaUsuarioSucursal
} from 'views';
import SubirCertificadoRecepcion from 'views/Residuos/views/SubirCertificadoReceipcion';
import { RUTAS } from 'const/Rutas';

export const RutasPublicas = () => {
  return (
    // Rutas publicas para usuarios no logueados
    <Switch>
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MainLayout}
        path={RUTAS.NOT_FOUND}
      />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path={RUTAS.HOME}
      />
      {/* REGISTRARSE */}
      <RouteWithLayout
        component={Signup}
        exact
        layout={MainLayout}
        path={RUTAS.REGISTRARSE}
      />
      <RouteWithLayout
        component={VerifyEmail}
        exact
        layout={MainLayout}
        path={RUTAS.REGISTRARSE_VERIFICAR_EMAIL}
      />
      {/* /verifica-cuenta */}
      <RouteWithLayout
        component={ConfirmEmail}
        exact
        layout={MainLayout}
        path={RUTAS.VERIFICAR_CUENTA}
      />
      {/* /verifica-usuario */}
      <RouteWithLayout
        component={VerificaUsuario}
        exact
        layout={MainLayout}
        path={RUTAS.VERIFICAR_USUARIO}
      />
      {/* /verifica-usuario-sucursal */}
      <RouteWithLayout
        component={VerificaUsuarioSucursal}
        exact
        layout={MainLayout}
        path={RUTAS.VERIFICAR_USUARIO_SUCURSAL}
      />

      {/* /subir-certificado */}
      <RouteWithLayout
        component={SubirCertificadoRecepcion}
        exact
        layout={MainLayout}
        path={RUTAS.SUBIR_CERTIFICADO}
      />

      <RouteWithLayout
        component={() => <h1>403</h1>}
        exact
        layout={MainLayout}
        path={RUTAS.NOT_ALLOWED}
      />
      <Redirect to={RUTAS.HOME} />
    </Switch>
  );
};
