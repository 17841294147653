import { useEffect } from 'react';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import RadioComponent from 'components/design-system/Radio/Radio';
import Heading from 'components/design-system/Heading/Heading';
import AlertComponent from 'components/design-system/Alert/Alert';
import Text from 'components/design-system/Text/Text';
import { ArrowRight } from '@mui/icons-material';
import { RadioGroup, createFilterOptions } from '@mui/material';
import empresasService from 'services/empresas';
import { aceptaLetrasNumeros } from 'components/design-system/input/InputValidations';
import { DICTONARY } from 'const/Dictonary';
import { DatePickerComponent } from 'components/design-system/DatePicker/DatePicker';

const filter = createFilterOptions();
const DEFAULT_TRANSPORTISTA = {
  codigoSucursal: 0,
  nombreSucursal: 'El transportista no está registrado',
  desdeServicio: false
};

export const ModalTransaccionesFormik = ({
  handleChange,
  handleBlur,
  touched,
  setFieldTouched,
  setFieldValue,
  values,
  errors,
  listadoTiposTransporte,
  listadoEmpresasGestor,
  listadoEmpresasAsesor,
  listadoEmpresasTransportista,
  listadoTiposTratamiento
}) => {
  useEffect(() => {
    if (
      values.sucursalTransportista &&
      values.sucursalTransportista.codigoSucursal &&
      values.sucursalTransportista.codigoSucursal ===
        DEFAULT_TRANSPORTISTA.codigoSucursal
    ) {
      setFieldValue('rutTransportista', '');
      setFieldTouched('rutTransportista');
    }

    if (
      values.sucursalTransportista &&
      values.sucursalTransportista.codigoSucursal &&
      values.sucursalTransportista.codigoSucursal !==
        DEFAULT_TRANSPORTISTA.codigoSucursal
    ) {
      empresasService
        .getSucursal(values.sucursalTransportista.codigoSucursal)
        .then(response => {
          setFieldValue('rutTransportista', response.data.empresa.rutEmpresa);
          setFieldTouched('rutTransportista');
        });
    }
  }, [values.sucursalTransportista]);

  return (
    <>
      <div className="grid grid-col-span-12 gap-3">
        <div className="col-span-12 mb-4">
          <Heading type="h3">Cerrar Transacción</Heading>
          <AlertComponent type="info">
            Para terminar el proceso por favor completar los datos que se
            muestran a continuación, considerar que los datos deben estar
            relacionados con la transacción que se esta declarando como
            concretada en este proceso de cierre.
          </AlertComponent>
        </div>
      </div>
      <div className="grid grid-col-span-12 gap-4">
        <div className="col-span-12">
          <Heading type="h4">
            Selecciona las empresas que participaron en la transacción
          </Heading>
          <Text>
            Ingrese según el rol aquellas empresas que hayan participado en la
            transacción.
            <br />
            Si no participo ninguna adicional deje la casilla en blanco.{' '}
          </Text>
        </div>
        <div className="col-span-12">
          <AutocompleteComponent
            fullWidth
            clearOnEscape={true}
            disabled={!listadoEmpresasGestor}
            name="sucursalGestor"
            options={listadoEmpresasGestor}
            onChange={(ev, valueObject, reason) => {
              let data = {
                target: {
                  name: 'sucursalGestor',
                  value: valueObject
                }
              };
              if (reason === 'clear') {
                data.target.value = {
                  codigoSucursal: '',
                  nombreSucursal: ''
                };
              }
              handleChange(data);
            }}
            onBlur={() => setFieldTouched('sucursalGestor')}
            value={values.sucursalGestor}
            accesor="nombreSucursal"
            getOptionLabel={option => option.nombreSucursal || ''}
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              placeholder="Seleccione un gestor"
              touched={touched.sucursalGestor}
              estado={
                errors.sucursalGestor
                  ? {
                      tipo: 'error',
                      mensaje: errors.sucursalGestor
                    }
                  : null
              }
              label={<LabelComponent esOpcional>Gestor</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>
        <div className="col-span-12">
          <AutocompleteComponent
            fullWidth
            accesor="nombreSucursal"
            name="sucursalAsesor"
            options={listadoEmpresasAsesor}
            onChange={(ev, valueObject, reason) => {
              let data = {
                target: {
                  name: 'sucursalAsesor',
                  value: valueObject
                }
              };
              if (reason === 'clear') {
                data.target.value = {
                  codigoSucursal: '',
                  nombreSucursal: ''
                };
              }
              handleChange(data);
            }}
            touched={touched.sucursalAsesor}
            onBlur={() => setFieldTouched('sucursalAsesor')}
            value={values.sucursalAsesor}
            getOptionLabel={option => option.nombreSucursal || ''}
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              placeholder="Seleccione un asesor"
              touched={touched.sucursalAsesor}
              estado={
                errors.sucursalAsesor
                  ? {
                      tipo: 'error',
                      mensaje: errors.sucursalAsesor
                    }
                  : null
              }
              label={<LabelComponent esOpcional>Asesor</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>

        <div className="col-span-6">
          <AutocompleteComponent
            fullWidth
            accesor="nombreSucursal"
            name="sucursalTransportista"
            options={[DEFAULT_TRANSPORTISTA, ...listadoEmpresasTransportista]}
            onChange={(ev, valueObject, reason) => {
              let data = {
                target: {
                  name: 'sucursalTransportista',
                  value: valueObject
                }
              };
              if (reason === 'clear') {
                data.target.value = {
                  codigoSucursal: '',
                  nombreSucursal: ''
                };
                setFieldValue('rutTransportista', '');
              }
              handleChange(data);
            }}
            touched={touched.sucursalTransportista}
            onBlur={() => setFieldTouched('sucursalTransportista')}
            value={values.sucursalTransportista}
            getOptionLabel={option => option.nombreSucursal || ''}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              const isDisplayingDefault = filtered.some(
                option => option.codigoSucursal === 0
              );

              if (inputValue !== '' && !isDisplayingDefault) {
                return [DEFAULT_TRANSPORTISTA, ...filtered];
              }

              return filtered;
            }}
            renderOption={(props, option, { selected }) => (
              <li className="" {...props}>
                {option.desdeServicio ? (
                  <>{option.nombreSucursal}</>
                ) : (
                  <Text
                    className={`${
                      selected ? 'text-white' : 'text-uv-primary-0'
                    }`}
                  >
                    <ArrowRight /> {option.nombreSucursal}
                  </Text>
                )}
              </li>
            )}
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              placeholder="Seleccione un transportista"
              touched={touched.sucursalTransportista}
              estado={
                errors.sucursalTransportista
                  ? {
                      tipo: 'error',
                      mensaje: errors.sucursalTransportista
                    }
                  : null
              }
              label={<LabelComponent esOpcional>Transportista</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>
        <div className="col-span-6">
          <InputComponent
            disabled={values.sucursalTransportista?.codigoSucursal !== 0}
            fullWidth
            name="rutTransportista"
            value={values.rutTransportista}
            onChange={handleChange}
            label={
              <LabelComponent esOpcional>Rut transportista</LabelComponent>
            }
            onBlur={() => setFieldTouched('rutTransportista')}
            touched={touched.rutTransportista ? true : false}
            estado={
              errors.rutTransportista
                ? { tipo: 'error', mensaje: errors.rutTransportista }
                : null
            }
            helperText={
              <span className="font-primary text-xs">
                Formato ejemplo: 11200300-K
              </span>
            }
          />
        </div>
        <div className="col-span-12">
          <InputComponent
            fullWidth
            name={'patenteTransportista'}
            value={(values.patenteTransportista || '').toUpperCase()}
            onChange={event => handleChange(aceptaLetrasNumeros(event, 6))}
            onBlur={handleBlur}
            touched={touched.patenteTransportista}
            placeholder="Ej: AB1234"
            outerInputProps={{
              inputProps: {
                maxLength: 6
              }
            }}
            //onPaste={handlePaste}
            estado={
              errors.patenteTransportista
                ? { tipo: 'error', mensaje: errors.patenteTransportista }
                : null
            }
            label={<LabelComponent esOpcional>Patente</LabelComponent>}
          />
        </div>

        <div className="col-span-12 sm:col-span-6">
          <AutocompleteComponent
            className=""
            accesor="nombreTipoTratamiento"
            name="tipoTratamiento"
            options={listadoTiposTratamiento}
            clearOnEscape={true}
            openOnFocus={true}
            onChange={(ev, selected) => {
              setFieldValue('tipoTratamiento', selected);
            }}
            onBlur={() => setFieldTouched('tipoTratamiento')}
            getOptionLabel={option => option.nombreTipoTratamiento || ''}
            noOptionsText="Sin opciones"
            value={values.tipoTratamiento}
            fullWidth
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              variant="outlined"
              placeholder="Seleccione un tipo de tratamiento"
              touched={touched.tipoTratamiento}
              estado={
                errors.tipoTratamiento
                  ? {
                      tipo: 'error',
                      mensaje: errors.tipoTratamiento
                    }
                  : null
              }
              label={<LabelComponent>Tipo de tratamiento</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>
        <div className="col-span-12 sm:col-span-6">
          <AutocompleteComponent
            name="tipoTransporte"
            accesor="nombreTipoTransporte"
            options={listadoTiposTransporte}
            clearOnEscape={true}
            openOnFocus={true}
            getOptionLabel={option =>
              option ? option.nombreTipoTransporte : ''
            }
            onChange={(ev, selected) => {
              console.log(selected);
              setFieldValue('tipoTransporte', selected);
            }}
            onBlur={() => setFieldTouched('tipoTransporte')}
            noOptionsText="Sin opciones"
            value={values.tipoTransporte}
            fullWidth
          >
            <InputComponent
              autoComplete={'off'}
              fullWidth
              variant="outlined"
              placeholder="Seleccione un tipo de transporte"
              touched={touched.tipoTransporte ? true : false}
              estado={
                errors.tipoTransporte
                  ? {
                      tipo: 'error',
                      mensaje: errors.tipoTransporte
                    }
                  : null
              }
              label={<LabelComponent>Tipo de transporte</LabelComponent>}
            />
          </AutocompleteComponent>
        </div>
        <div className="col-span-12 sm:col-span-6">
          <LabelComponent>¿Quien efectuó el tratamiento?</LabelComponent>
          <RadioGroup aria-label="ejecutoTratamiento">
            <RadioComponent
              name="ejecutoTratamiento"
              value={DICTONARY.ROL.RECEPTOR.NOMBRE}
              onBlur={handleBlur}
              onChange={(ev, val) => {
                if (val) {
                  setFieldValue('ejecutoTratamiento', ev.target.value);
                }
              }}
              touched={touched.ejecutoTratamiento}
            />
            <LabelComponent className="pl-1 pr-1">
              {' '}
              {DICTONARY.ROL.RECEPTOR.TITULO}{' '}
            </LabelComponent>

            <RadioComponent
              name="ejecutoTratamiento"
              value={DICTONARY.ROL.GENERADOR.NOMBRE}
              onBlur={handleBlur}
              onChange={(ev, val) => {
                if (val) {
                  setFieldValue('ejecutoTratamiento', ev.target.value);
                }
              }}
              touched={touched.ejecutoTratamiento}
            />
            <LabelComponent className="pl-1 pr-1">
              {' '}
              {DICTONARY.ROL.GENERADOR.TITULO}
            </LabelComponent>

            {values.sucursalGestor?.codigoSucursal && (
              <>
                <RadioComponent
                  name="ejecutoTratamiento"
                  value={'gestor'}
                  onBlur={handleBlur}
                  onChange={(ev, val) => {
                    if (val) {
                      setFieldValue('ejecutoTratamiento', ev.target.value);
                    }
                  }}
                  touched={touched.ejecutoTratamiento}
                />
                <LabelComponent className="pl-1 pr-1"> Gestor</LabelComponent>
              </>
            )}
          </RadioGroup>
        </div>
        <div className="col-span-12 sm:col-span-6">
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <DesktopDatePicker
              name="fechaRecepcion"
              label={
                <LabelComponent>
                  Fecha de realización de transacción
                </LabelComponent>
              }
              inputFormat="dd/MM/yyyy"
              value={values.fechaRecepcion}
              defaultValue={values.fechaRecepcion}
              onChange={value => {
                console.log(value);
                handleChange({
                  target: {
                    name: 'fechaRecepcion',
                    value: value
                  }
                });
              }}
              slots={{ textField: DatePickerComponent }}
            />
          </LocalizationProvider>
        </div>
      </div>
    </>
  );
};
