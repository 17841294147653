import { useEffect, useMemo, useState } from 'react';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
import {
  handleKeyPress,
  handlePaste
} from 'views/Business/utils/handleKeyPress';
import { useDireccion } from 'hooks/useDireccion';
import MapAddress from 'components/MapAddress/MapAddress';
import { DICTONARY } from 'const/Dictonary';
import { CircularProgress } from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import { GpsOff, MyLocation } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import {
  obtenerEmpresaGeneradora,
  obtenerEmpresasGeneradoras
} from 'views/CargaMasiva/hooks/useServices';
import { AutoCompleteAsyncController } from 'components/design-system/AutoCompleteAsync/AutoCompleteAsyncController';
import { isValorValido } from 'helpers/validaCampos';

const Generador = ({
  errors,
  handleChange,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
  tipoForm,

  empresasSIIGenerador,
  isLoadingEmpresasSIIGenerador,
  cargaEmpresasSIIGenerador
}) => {
  const [razonSocialEmpresaGenerador, setRazonSocialEmpresaGenerador] =
    useState(values.generadorRazonSocial || '');

  //Si el formulario es un ingreso y no se ha ingresado ningun dato previamente de declaración mostraré la opción
  //de carga de un nuevo generador.
  const [isNuevo, setIsNuevo] = useState(
    tipoForm === DICTONARY.TIPO_FORMULARIO.ingresar &&
      !(
        values.rutEmpresaGenerador ||
        values.nombreEmpresaGenerador ||
        values.regionEmpresaGenerador ||
        values.comunaEmpresaGenerador ||
        values.nombreViaEmpresaGenerador
      )
      ? null
      : false
  );

  const [IsLoadingEmpresaGeneradora, setIsLoadingEmpresaGeneradora] =
    useState(false);

  const {
    latLon,
    setLatLon,
    addressQuerysearch,
    addressQueryParams,
    regiones,
    cargaListaRegiones,
    handleObtenerDireccion,
    handleObtenerComunas,
    listadoComunas,
    cargaListaComunas,
    setBuscandoDireccion,
    buscandoDireccion
  } = useDireccion();

  useEffect(() => {
    if (!empresasSIIGenerador) return;
    if (empresasSIIGenerador.razonSocial) {
      setFieldValue('generadorRazonSocial', empresasSIIGenerador.razonSocial);
      setRazonSocialEmpresaGenerador(empresasSIIGenerador.razonSocial);
      if (touched.rutEmpresaGenerador) {
        setFieldValue(
          'nombreEmpresaGenerador',
          empresasSIIGenerador.razonSocial
        );
      }
    } else {
      setRazonSocialEmpresaGenerador('Sin información');
    }
  }, [empresasSIIGenerador, isLoadingEmpresasSIIGenerador]);

  // Carga RUT al ingresar al formulario
  useMemo(() => {
    if (values.rutEmpresaGenerador) {
      cargaEmpresasSIIGenerador(values.rutEmpresaGenerador);
    }
  }, [cargaEmpresasSIIGenerador, values.rutEmpresaGenerador]);

  //  Servicio Comunas
  useMemo(() => {
    handleObtenerComunas(values.regionEmpresaGenerador?.codigoRegion || null);
  }, [handleObtenerComunas, values.regionEmpresaGenerador]);

  // Estados de carga de servicios
  useMemo(() => {
    if (isSubmitting) return;
    handleObtenerDireccion({
      region: values.regionEmpresaGenerador,
      comuna: values.comunaEmpresaGenerador,
      nombreVia: values.nombreViaEmpresaGenerador,
      latitudDireccion: values.latitudDireccionEmpresaGenerador,
      longitudDireccion: values.longitudDireccionEmpresaGenerador
    });
  }, [isSubmitting, handleObtenerDireccion, values]);

  // Verificacion de cambios en los campos de direccion
  useMemo(() => {
    const {
      nombreViaEmpresaGenerador,
      comunaEmpresaGenerador,
      regionEmpresaGenerador,
      isPointEditing
    } = values;

    if (isSubmitting || !latLon) return;

    const hasNombreVia =
      nombreViaEmpresaGenerador && touched.nombreViaEmpresaGenerador;
    const hasComuna = comunaEmpresaGenerador && touched.comunaEmpresaGenerador;
    const hasRegion = regionEmpresaGenerador && touched.regionEmpresaGenerador;

    if (hasNombreVia || hasComuna || hasRegion || isPointEditing) {
      setBuscandoDireccion(true);
      setFieldValue('latitudDireccionEmpresaGenerador', latLon[0]);
      setFieldValue('longitudDireccionEmpresaGenerador', latLon[1]);
    }
  }, [
    isSubmitting,
    latLon,
    setBuscandoDireccion,
    setFieldValue,
    touched.comunaEmpresaGenerador,
    touched.nombreViaEmpresaGenerador,
    touched.regionEmpresaGenerador,
    values
  ]);

  useEffect(() => {
    const fecthEmpresaGeneradora = async codigoEmpresaGeneradora => {
      const empresaGeneradoraCargada = await obtenerEmpresaGeneradora({
        codigoEmpresaGeneradora: codigoEmpresaGeneradora,
        setIsLoading: setIsLoadingEmpresaGeneradora
      });
      if (empresaGeneradoraCargada) {
        if (isValorValido(empresaGeneradoraCargada.nombreEmpresa)) {
          setFieldValue(
            'nombreEmpresaGenerador',
            empresaGeneradoraCargada.nombreEmpresa
          );
        }
        if (isValorValido(empresaGeneradoraCargada.rut)) {
          setFieldValue('rutEmpresaGenerador', empresaGeneradoraCargada.rut);
        }

        if (isValorValido(empresaGeneradoraCargada.direccion)) {
          setFieldValue(
            'regionEmpresaGenerador',
            empresaGeneradoraCargada.direccion?.comuna?.region
          );
          setFieldValue(
            'comunaEmpresaGenerador',
            empresaGeneradoraCargada.direccion?.comuna
          );
          setFieldValue(
            'nombreViaEmpresaGenerador',
            empresaGeneradoraCargada.direccion?.nombreVia
          );
          setFieldValue(
            'latitudDireccionEmpresaGenerador',
            empresaGeneradoraCargada.direccion?.latitudDireccion
          );
          setFieldValue(
            'longitudDireccionEmpresaGenerador',
            empresaGeneradoraCargada.direccion?.longitudDireccion
          );
        }
      }
      setIsNuevo(false);
    };

    if (values.generadorPreCargado) {
      fecthEmpresaGeneradora(values.generadorPreCargado.value);
    }
  }, [values.generadorPreCargado]);

  const limpiarCampos = () => {
    setFieldValue('nombreEmpresaGenerador', null);
    setFieldValue('rutEmpresaGenerador', null);
    setFieldValue('regionEmpresaGenerador', null);
    setFieldValue('comunaEmpresaGenerador', null);
    setFieldValue('nombreViaEmpresaGenerador', null);
    setFieldValue('latitudDireccionEmpresaGenerador', null);
    setFieldValue('longitudDireccionEmpresaGenerador', null);
    setFieldValue('generadorRazonSocial', null);
    setRazonSocialEmpresaGenerador(null);
  };

  if (isNuevo === null) {
    return (
      <div className="col-span-12">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12">
            <Heading type="h4" className="mb-0">
              Generador
            </Heading>
          </div>
          <div className="col-span-12 md:col-span-6 xl:col-span-4">
            <AutoCompleteAsyncController
              name="generadorPreCargado"
              accesor="label"
              required
              fullWidth
              disabled={isSubmitting}
              label={
                <LabelComponent>
                  Selecciona un generador ya ingresado
                </LabelComponent>
              }
              setFieldValue={data => setFieldValue('generadorPreCargado', data)}
              setFieldTouched={() => setFieldTouched('generadorPreCargado')}
              isSubmitting={isSubmitting}
              fetchCall={controllerValues =>
                obtenerEmpresasGeneradoras({
                  setLoadingEmpresas: controllerValues.setIsLoading,
                  setEmpresas: controllerValues.setData,
                  paginationEmpresas: controllerValues.paginationEmpresas,
                  nombreEmpresa: controllerValues.inputValue,
                  setRowCountEmpresas: controllerValues.setRowCountEmpresas
                })
              }
            />
          </div>
          <div className="hidden md:block col-span-1">
            <div className="flex h-full justify-center items-center relative">
              <div className="border-l absolute w-0.5 h-full z-0"></div>
              <span className="bg-white p-2 relative z-10">O</span>
            </div>
          </div>
          <div className="col-span-12 md:col-span-5 xl:col-span-3">
            <div>
              <LabelComponent>Añadir un nuevo generador</LabelComponent>
            </div>
            <ButtonComponent onClick={() => setIsNuevo(true)}>
              <Add className="mr-2" /> Nuevo generador
            </ButtonComponent>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-4">
        {isNuevo ? (
          <div className="col-span-12 md:col-span-12 flex items-center gap-4">
            <Heading type="h4" className="mb-0">
              Añadir un nuevo generador
            </Heading>
            <ButtonComponent
              type="secondary"
              onClick={() => {
                setIsNuevo(null);
                limpiarCampos();
              }}
            >
              Cancelar
            </ButtonComponent>
          </div>
        ) : (
          <div className="col-span-12 md:col-span-12 flex items-center gap-4">
            <Heading type="h4" className="mb-0">
              {values.nombreEmpresaGenerador
                ? values.nombreEmpresaGenerador
                : 'Añadir un nuevo receptor'}
            </Heading>
            <ButtonComponent
              type="secondary"
              onClick={() => {
                setIsNuevo(null);
                limpiarCampos();
              }}
            >
              Cancelar
            </ButtonComponent>
          </div>
        )}

        <div className="col-span-12 xs:col-span-12">
          <div className="grid grid-cols-12 gap-4">
            {/* Rut Empresa */}
            <div className="col-span-12 md:col-span-4">
              <InputComponent
                autoComplete="off"
                disabled={isSubmitting}
                fullWidth
                name={'rutEmpresaGenerador'}
                value={values.rutEmpresaGenerador || ''}
                onChange={event => {
                  const { value } = event.target;
                  if (
                    value.length >= 9 &&
                    value.charAt(value.length - 2) === '-'
                  ) {
                    setFieldValue('rutEmpresaGenerador', value.trim());
                    cargaEmpresasSIIGenerador(value);
                  } else {
                    setFieldValue('rutEmpresaGenerador', value.trim());
                    setRazonSocialEmpresaGenerador('Sin información');
                  }
                }}
                onBlur={() => {
                  setFieldTouched('rutEmpresaGenerador');
                }}
                outerInputProps={{
                  inputProps: {
                    maxLength: 12,
                    onKeyPress: handleKeyPress
                  }
                }}
                onPaste={handlePaste}
                touched={touched.rutEmpresaGenerador}
                placeholder="Ej: 12345678-9"
                estado={
                  errors.rutEmpresaGenerador
                    ? { tipo: 'error', mensaje: errors.rutEmpresaGenerador }
                    : null
                }
                label={<LabelComponent>Rut </LabelComponent>}
              />
            </div>
            {/* Razon Social */}
            {razonSocialEmpresaGenerador && (
              <div className="col-span-12 md:col-span-4">
                <LabelComponent
                  tooltipText={
                    <>
                      <b>La razón social</b> ha sido{' '}
                      <b>obtenida de forma automática</b> con la información
                      entregada por el{' '}
                      <b>Servicio de Impuestos Internos (SII).</b>
                    </>
                  }
                >
                  Razón social
                </LabelComponent>
                <div className="rounded bg-neutral-90 p-2.5 relative">
                  <Text className="min-h-[1.25rem]">
                    {empresasSIIGenerador &&
                    empresasSIIGenerador.razonSocial &&
                    !isLoadingEmpresasSIIGenerador
                      ? razonSocialEmpresaGenerador
                      : !touched.rutEmpresaGenerador &&
                        !errors.rutEmpresaGenerador
                      ? ''
                      : 'Sin información'}
                    {isLoadingEmpresasSIIGenerador &&
                      touched.rutEmpresaGenerador &&
                      !errors.rutEmpresaGenerador && (
                        <CircularProgress
                          color="inherit"
                          className="opacity-40 !w-5 !h-5 absolute right-2.5 top-2.5"
                        />
                      )}
                  </Text>
                </div>
              </div>
            )}
            {/* Nombre */}
            <div className="col-span-12 md:col-span-4">
              <InputComponent
                autoComplete="off"
                disabled={isSubmitting}
                fullWidth
                name={'nombreEmpresaGenerador'}
                value={values.nombreEmpresaGenerador || ''}
                onChange={handleChange}
                onBlur={() => {
                  setFieldTouched('nombreEmpresaGenerador');
                }}
                touched={touched.nombreEmpresaGenerador}
                estado={
                  errors.nombreEmpresaGenerador
                    ? { tipo: 'error', mensaje: errors.nombreEmpresaGenerador }
                    : null
                }
                label={
                  <LabelComponent
                    tooltipText={
                      <>
                        <b>Nombre de fantasía</b> que sirve para{' '}
                        <b>agrupar los residuos declarados</b> de esta empresa.
                        Si se obtuvo <b>el dato de razón social</b> este{' '}
                        <b>será sugerido como nombre de empresa</b>. Sin embargo
                        puede <b>editar el campo</b> e indicar otro nombre para
                        la empresa.
                      </>
                    }
                  >
                    Nombre empresa{' '}
                  </LabelComponent>
                }
              />
            </div>
          </div>

          <div className="grid grid-cols-12 gap-4 mt-8">
            <div className="col-span-12 md:col-span-12">
              <Heading type="h4" className="mb-0 flex gap-2 items-center">
                Dirección
                <Text className="text-uv-secondary-0">(opcional)</Text>
              </Heading>
            </div>

            {/* Region */}
            <div className="col-span-12 md:col-span-4 xl:col-span-3">
              <AutocompleteComponent
                name="regionEmpresaGenerador"
                clearOnEscape={true}
                accesor="nombreRegion"
                openOnFocus={true}
                options={regiones}
                disabled={!cargaListaRegiones}
                onChange={(ev, region, reason) => {
                  let data = { name: 'regionEmpresaGenerador', value: region };
                  if (reason === 'clear') {
                    setFieldValue('comunaEmpresaGenerador', null);
                    setFieldValue('regionEmpresaGenerador', null);
                  } else {
                    setFieldValue('comunaEmpresaGenerador', null);
                    setFieldValue('regionEmpresaGenerador', data.value);
                  }
                  setFieldTouched('regionEmpresaGenerador');
                  setFieldTouched('comunaEmpresaGenerador');
                  setFieldTouched('nombreViaEmpresaGenerador');
                }}
                onBlur={() => setFieldTouched('regionEmpresaGenerador')}
                value={values.regionEmpresaGenerador}
                getOptionLabel={option => option.nombreRegion || ''}
                isOptionEqualToValue={(option, value) =>
                  option.codigoRegion ===
                  values.regionEmpresaGenerador.codigoRegion
                }
              >
                <InputComponent
                  autoComplete={'off'}
                  fullWidth
                  touched={touched.regionEmpresaGenerador ? true : false}
                  estado={
                    errors.regionEmpresaGenerador
                      ? {
                          tipo: 'error',
                          mensaje: errors.regionEmpresaGenerador
                        }
                      : null
                  }
                  label={<LabelComponent>Región</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>

            {/* Comuna */}
            <div className="col-span-12 md:col-span-4 xl:col-span-3">
              <AutocompleteComponent
                name="comunaEmpresaGenerador"
                accesor="nombreComuna"
                options={listadoComunas}
                disabled={!cargaListaComunas}
                onChange={(ev, comuna, reason) => {
                  let data = { name: 'comunaEmpresaGenerador', value: comuna };
                  console.log(data.value);
                  if (reason === 'clear') {
                    setFieldValue('regionEmpresaGenerador', null);
                    setFieldValue('comunaEmpresaGenerador', null);
                  } else {
                    setFieldValue('comunaEmpresaGenerador', data.value);
                    setFieldValue('regionEmpresaGenerador', data.value.region);
                  }
                }}
                value={values.comunaEmpresaGenerador}
                getOptionLabel={option => option.nombreComuna || ''}
                isOptionEqualToValue={(option, value) =>
                  option.nombreComuna ===
                  values.comunaEmpresaGenerador.nombreComuna
                }
                onBlur={() => setFieldTouched('comunaEmpresaGenerador')}
              >
                <InputComponent
                  autoComplete={'off'}
                  fullWidth
                  touched={touched.comunaEmpresaGenerador ? true : false}
                  estado={
                    errors.comunaEmpresaGenerador
                      ? {
                          tipo: 'error',
                          mensaje: errors.comunaEmpresaGenerador
                        }
                      : null
                  }
                  label={<LabelComponent>Comuna</LabelComponent>}
                />
              </AutocompleteComponent>
            </div>
            {/* Tipo de Via */}
            <div className="col-span-12 md:col-span-4 xl:col-span-6">
              <InputComponent
                autoComplete={'off'}
                fullWidth
                label={<LabelComponent>Nombre y número calle</LabelComponent>}
                name="nombreViaEmpresaGenerador"
                value={values.nombreViaEmpresaGenerador}
                onChange={e => {
                  handleChange(e);
                  setFieldTouched('regionEmpresaGenerador');
                  setFieldTouched('comunaEmpresaGenerador');
                  setFieldTouched('nombreViaEmpresaGenerador');
                }}
                onBlur={() => setFieldTouched('nombreViaEmpresaGenerador')}
                touched={touched.nombreViaEmpresaGenerador ? true : false}
                placeholder="Ej: Providencia, 1091"
                estado={
                  errors.nombreViaEmpresaGenerador
                    ? {
                        tipo: 'error',
                        mensaje: errors.nombreViaEmpresaGenerador
                      }
                    : null
                }
              />
            </div>

            {/* Mapa */}
            <div className="col-span-12 xxs:col-span-12 xs:col-span-12 ">
              <div
                className={`${
                  values.isPointEditing ? ' bg-warning-light' : 'bg-info-light '
                } sm:flex px-4 py-2.5 justify-between rounded items-center gap-4`}
              >
                <Text>
                  {values.isPointEditing
                    ? DICTONARY.MAPA_MENSAJES.INFO_DESACTIVAR_EDICION
                    : DICTONARY.MAPA_MENSAJES.INFO_ACTIVAR_EDICION}
                </Text>
                <ButtonComponent
                  className="min-w-[190px] md:min-w-fit mt-4 sm:mt-0"
                  onClick={() => {
                    setFieldValue('isPointEditing', !values.isPointEditing);
                  }}
                >
                  {values.isPointEditing && latLon ? (
                    <>
                      Desactivar edición <GpsOff className="ml-2" />
                    </>
                  ) : (
                    <>
                      Edición manual <MyLocation className="ml-2" />
                    </>
                  )}
                </ButtonComponent>
              </div>
            </div>

            <div className="col-span-12 rounded overflow-hidden">
              <MapAddress
                addressQuerysearch={addressQuerysearch}
                addressQueryParams={addressQueryParams}
                latLon={latLon}
                setLatLon={setLatLon}
                isPointEditing={values.isPointEditing}
                setIsPointEditing={value =>
                  setFieldValue('isPointEditing', value)
                }
                isLoading={isSubmitting}
                buscandoDireccion={buscandoDireccion}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Generador;
