import React, { useContext } from 'react';
import List from '@mui/material/List';
import { TrendingUp } from '@mui/icons-material';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import InsightsIcon from '@mui/icons-material/Insights';
import { ContenidoRestringido } from 'components/design-system/ContenidoRestringido/ContenidoRestringido';
import LinkComponent from 'components/design-system/Link/Link';
import Text from 'components/design-system/Text/Text';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { RUTAS } from 'const/Rutas';

const SubMenuDifusion = React.forwardRef(({ forceRefresh }, ref) => {
  const {
    logeduser,
    isTienePermisos,
    perfiles: { isSuperAdmin }
  } = useContext(AuthContext);

  const tieneNegocio = logeduser?.sucursal ? true : false;

  return (
    <List>
      {!tieneNegocio &&
        isTienePermisos(PERMISOS.registrarNegocio) &&
        !isSuperAdmin && (
          <div className="col-span-12">
            <ContenidoRestringido>
              <Text size="S">
                Solo{' '}
                <b className="text-black">usuarios con negocios registrados</b>{' '}
                pueden acceder a las opciones de reportes.
                <LinkComponent href={RUTAS.REGISTRAR_NEGOCIO} className="ml-2">
                  Registrar un negocio
                </LinkComponent>
              </Text>
            </ContenidoRestringido>
          </div>
        )}

      <VerificarPermisos nombrePermiso={PERMISOS.indicadoresOnline}>
        <ListItemButtonComponent
          onClick={forceRefresh(
            isSuperAdmin
              ? RUTAS.ADMINISTRAR_INDICADORES
              : `${RUTAS.NEGOCIO}/${logeduser.sucursal?.empresa?.codigoEmpresa}/indicadores`
          )}
          icon={<InsightsIcon />}
          label={'Indicadores'}
        />
      </VerificarPermisos>

      <VerificarPermisos nombrePermiso={PERMISOS.descargarReportes}>
        <ListItemButtonComponent
          onClick={forceRefresh(RUTAS.EXPORTAR_REPORTES)}
          icon={<TrendingUp />}
          label={'Descargar reportes'}
        />
      </VerificarPermisos>
    </List>
  );
});

export default SubMenuDifusion;
