import { useContext, useEffect, useRef, useState } from 'react';
import { MatchContext } from '../../context/MatchContext';
import {
  FeatureGroup,
  MapContainer,
  Marker,
  TileLayer,
  Circle
} from 'react-leaflet';
import { DICTONARY } from 'const/Dictonary';
import { useMapaMatch } from 'hooks/useMapaMatch';
import { PopupMarker } from 'components/PopupMarker';
import { MarcadorResiduos } from './Marcador/MarcadorResiduos';
import { MarcadorEmpresa } from './Marcador/MarcadorEmpresa';
import { MarcadorAmpliada } from './Marcador/MarcadorAmpliada';
import { EmpresaATDetalle } from './MapaPopup/EmpresaATDetalle';
import { useRellenosSanitarios } from '../../hooks/useRellenosSanitarios';
import { MarcadorRellenoSanitario } from './Marcador/MarcadorRellenoSanitario';
import { useResponsive } from 'hooks/useResponsive';

/**
 * @name Mapa
 * Componente que despliega el mapa
 */
export const Mapa = () => {
  const {
    sucursalUsuario,
    matchFiltrado,
    matchEmpresasFiltrado,
    matchAmpliadaFiltrado,
    radioBusqueda,
    setCoordenadasCentroMapa,
    coordenadasCentroMapa,
    residuoSeleccionado,
    sucursalATSeleccionada,
    tieneNegocio,
    empresaSeleccionada,
    sucursalSeleccionada,
    tabActiva
  } = useContext(MatchContext);

  const { titlesurl, attribution, getMapIcon, markerUsuario } = useMapaMatch();
  const { isMDDown } = useResponsive();
  const { rellenosSanitarios } = useRellenosSanitarios();
  const [inner, setInner] = useState(coordenadasCentroMapa);
  const [bound, setBound] = useState(null);

  const group = useRef(null);
  const markers = useRef({});
  const map = useRef();

  useEffect(() => {
    if (matchFiltrado && matchFiltrado.sucursales) {
      if (matchFiltrado.sucursales.length > 0) {
        const boundArry = matchFiltrado.sucursales.map(suc => [
          suc.direccion.latitud,
          suc.direccion.longitud
        ]);
        setBound(boundArry);
      }
    }
  }, [matchFiltrado]);

  useEffect(() => {
    if (residuoSeleccionado) {
      setCoordenadasCentroMapa([
        residuoSeleccionado.sucursal.direccion.latitud,
        residuoSeleccionado.sucursal.direccion.longitud
      ]);
    }
    if (empresaSeleccionada) {
      setCoordenadasCentroMapa([
        empresaSeleccionada.direccion.latitud,
        empresaSeleccionada.direccion.longitud
      ]);
    }

    if (sucursalSeleccionada) {
      setCoordenadasCentroMapa([
        sucursalSeleccionada.direccion.latitud,
        sucursalSeleccionada.direccion.longitud
      ]);
    }

    if (sucursalATSeleccionada) {
      setCoordenadasCentroMapa([
        sucursalATSeleccionada.direccion.latitud,
        sucursalATSeleccionada.direccion.longitud
      ]);
    }
  }, [
    residuoSeleccionado,
    empresaSeleccionada,
    sucursalSeleccionada,
    sucursalATSeleccionada
  ]);

  useEffect(() => {
    if (map != null && map.current != null) {
      map.current.invalidateSize();
    }
  }, [
    residuoSeleccionado,
    empresaSeleccionada,
    sucursalSeleccionada,
    sucursalATSeleccionada
  ]);

  useEffect(() => {
    setInner(coordenadasCentroMapa);
    if (map != null && map.current != null) {
      map.current.flyTo(coordenadasCentroMapa, 12);
    }
  }, [coordenadasCentroMapa]);

  return (
    <div
      className={` w-full z-0 relative
      ${
        residuoSeleccionado || sucursalSeleccionada
          ? 'h-[40vh] sm:h-[80vh]'
          : 'h-[40vh] sm:h-[80vh]'
      }`}
    >
      <MapContainer
        className="h-full"
        center={inner}
        ref={map}
        zoom="12"
        scrollWheelZoom={tieneNegocio ? false : true}
        bounds={bound ? bound : null}
        style={{
          width: '100%'
        }}
      >
        <TileLayer url={titlesurl} attribution={attribution} />
        <FeatureGroup
          ref={group}
          key={matchFiltrado.sucursales.map(s => s.codigoSucursal).join('-')}
        >
          {radioBusqueda.limitarRadio ? (
            <Circle
              center={sucursalUsuario.coordenadas}
              radius={radioBusqueda.radio * 1000}
              fillOpacity={0.3}
              opacity={0.9}
              weight={2}
              color={'#47a7b7'}
            />
          ) : null}

          {tabActiva === DICTONARY.MATCH.TABS.AMPLIADA &&
            sucursalATSeleccionada &&
            !isMDDown && <EmpresaATDetalle />}

          {tabActiva === DICTONARY.MATCH.TABS.RESIDUOS &&
            matchFiltrado.sucursales.length > 0 &&
            matchFiltrado.sucursales.map((sucursal, index) => (
              <MarcadorResiduos
                key={index}
                sucursal={sucursal}
                markers={markers}
                getMapIcon={getMapIcon}
              />
            ))}

          {tabActiva === DICTONARY.MATCH.TABS.EMPRESAS &&
            matchEmpresasFiltrado.sucursales.length > 0 &&
            matchEmpresasFiltrado.sucursales.map((sucursal, index) => (
              <MarcadorEmpresa
                key={index}
                sucursal={sucursal}
                markers={markers}
                getMapIcon={getMapIcon}
              />
            ))}

          {tabActiva === DICTONARY.MATCH.TABS.AMPLIADA &&
            matchAmpliadaFiltrado.sucursales.length > 0 &&
            matchAmpliadaFiltrado.sucursales.map((sucursal, index) => (
              <MarcadorAmpliada
                key={index}
                sucursal={sucursal}
                markers={markers}
                getMapIcon={getMapIcon}
              />
            ))}

          {sucursalUsuario && sucursalUsuario.coordenadas && (
            <Marker
              icon={markerUsuario}
              position={sucursalUsuario.coordenadas}
              onClick={() =>
                setCoordenadasCentroMapa(sucursalUsuario.coordenadas)
              }
            >
              {sucursalUsuario.sucursal && (
                <PopupMarker
                  nombreEmpresa={
                    sucursalUsuario.sucursal?.empresa?.nombreEmpresa ||
                    'No encontrado'
                  }
                  nombreSucursal={
                    sucursalUsuario.sucursal?.nombreSucursal || 'Sin sucursal'
                  }
                  direccion={
                    `${sucursalUsuario.sucursal?.direccion?.nombreVia}, ${sucursalUsuario.sucursal?.direccion?.comuna?.nombreComuna}, Región ${sucursalUsuario.sucursal?.direccion?.comuna?.region?.nombreRegion}` ||
                    'No definido'
                  }
                />
              )}
            </Marker>
          )}

          {rellenosSanitarios.length > 0 &&
            rellenosSanitarios.map((rellenoSanitario, index) => (
              <MarcadorRellenoSanitario
                key={index}
                rellenoSanitario={rellenoSanitario}
                markers={markers}
              />
            ))}
        </FeatureGroup>
      </MapContainer>
    </div>
  );
};
