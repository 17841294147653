import Dropzone from 'components/design-system/Dropzone/Dropzone';
import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const DocumentosDeRespaldo = ({ isSubmitting, setFieldValue, values }) => {
  /*  const [archivos, setArchivos] = useState([]);
  const [archivosLocal, setArchivosLocal] = useState(values.archivos);
  const [eliminandoArchivo, setEliminandoArchivo] = useState(false);
  const [errorArchivos, setErrorArchivos] = useState(false); */

  /* useEffect(() => {
    if (archivos && archivos.length > 0) {
      if (tipoForm.ingresar) {
        setFieldValue('archivos', archivos);
      } else if (tipoForm.editar || tipoForm.duplicar) {
        if (archivosLocal.length > 0) {
          setFieldValue('archivos', archivosLocal.concat(archivos));
        } else {
          setFieldValue('archivos', archivos);
        }
      }
    }
  }, [
    archivos,
    archivosLocal,
    setFieldValue,
    tipoForm.duplicar,
    tipoForm.editar,
    tipoForm.ingresar
  ]); */

  /* const eliminarArchivo = useCallback(
    file => {
      const archivosFiltrados = archivosLocal.filter(
        archivo => archivo.codigoArchivo !== file.codigoArchivo
      );
      setArchivosLocal(archivosFiltrados);
      setFieldValue('archivos', archivosFiltrados);
      setEliminandoArchivo(true);
    },
    [archivosLocal, setFieldValue]
  ); */
  return (
    <div className="col-span-12">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Heading type="h4" className="mb-0">
            Documentos de respaldo
          </Heading>
        </div>
        <div className="col-span-12 xs:col-span-12">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 xs:col-span-12">
              <LabelComponent>
                Cargar documento de respaldo, Ej: Guía de despacho, factura u
                otro tipo de documento.{' '}
                <span className="text-neutral-60">(opcional)</span>
              </LabelComponent>

              <Dropzone
                setDataFiles={data => setFieldValue('archivos', data)}
                dataFiles={values.archivos || []}
                disabled={isSubmitting}
                enablePreviewFile={true}
                enableEditingFile={false}
                {...DICTONARY.DROPZONE.CONFIG_UPLOAD_RECURSOS_ADQUIRIDOS}
              >
                <Text className="text-black font-medium">
                  Solo se aceptan los siguientes tipos de archivos
                </Text>
                <ul className="flex mt-1">
                  <li className="flex items-center mb-1">
                    <IconComponent className="mr-2">
                      <TaskAltIcon className="text-base text-uv-primary-20" />
                    </IconComponent>
                    <Text className="text-neutral-40">PDF y JPG</Text>
                  </li>
                  <li className="flex items-center mb-1 ml-6">
                    <IconComponent className="mr-2">
                      <TaskAltIcon className="text-base text-uv-primary-20" />
                    </IconComponent>
                    <Text className="text-neutral-40">
                      Tamaño no superior a 4 MB
                    </Text>
                  </li>
                </ul>
              </Dropzone>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentosDeRespaldo;
