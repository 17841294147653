export const initialValuesIngresarResiduosAT = {
  anyoDeclaracion: '',
  nombreLER: '',
  nombreSubLER: '',
  nombreSubSubLER: '',
  tratamiento: '',
  cantidad: 0,
  empresaATOrigen: '',
  empresaATDestino: '',
  tieneEmpresaATOrigen: false,
  tieneEmpresaATDestino: false
};
