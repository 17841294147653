/**
 * Retorna la última transacción con estado vigente.
 * @param {*} codigoTransaccion
 * @param {*} transaccionEstado
 * @returns
 */
export const ObtieneUltimaTransaccion = (
  codigoTransaccion,
  transaccionEstado
) => {
  const ultimaTransaccion = transaccionEstado.find(item => item.vigente);
  if (ultimaTransaccion) {
    return ultimaTransaccion.estado;
  } else {
    console.log('DEFAULT');
    return {
      nombreEstado: 'Iniciada',
      codigoEstado: 1
    };
  }
};
