import IconComponent from 'components/design-system/icon/Icon';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Dropzone from 'components/design-system/Dropzone/Dropzone';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import Heading from 'components/design-system/Heading/Heading';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { PERMISOS } from 'const/Permisos';

const MaterialAdjunto = ({ errors, setFieldValue, values }) => {
  return (
    <VerificarPermisos nombrePermiso={PERMISOS.archivosResiduo}>
      <div className="col-span-12 mt-4">
        {/* Fila 1 */}
        <div className="col-span-12 xs:col-span-12">
          <div className="col-span-12 md:col-span-12">
            <Heading type="h4" className="mb-0 text-uv-primary-10">
              Imagenes adjuntas
            </Heading>
            <Text>
              Incluye material adjunto para que los demandantes puedan
              visualizar la información y tener una mejor visión sobre lo que
              estás ofreciendo.
            </Text>
          </div>
        </div>
        {/* Fila 2 */}
        <div className="col-span-12 xs:col-span-12">
          <div className="grid grid-cols-12 gap-1 align-middle mt-2">
            {/* Subida de archivos */}
            <div className="col-span-12 xs:col-span-12">
              <Dropzone
                setDataFiles={data => setFieldValue('archivosResiduos', data)}
                dataFiles={values.archivosResiduos || []}
                enableEditingFile={false}
                {...DICTONARY.DROPZONE.CONFIG_UPLOAD_CREATE_RSD_IMAGES}
              >
                <Text className="text-black font-medium">
                  Adjunta documentos relacionados al residuo.
                </Text>
                <ul className="flex mt-1">
                  <li className="flex items-center mb-1">
                    <IconComponent className="mr-2">
                      <TaskAltIcon className="text-base text-uv-primary-20" />
                    </IconComponent>
                    <Text className="text-neutral-40">Formato JPG</Text>
                  </li>
                  <li className="flex items-center mb-1 ml-6">
                    <IconComponent className="mr-2">
                      <TaskAltIcon className="text-base text-uv-primary-20" />
                    </IconComponent>
                    <Text className="text-neutral-40">
                      Tamaño no superior a 4 MB
                    </Text>
                  </li>
                </ul>
              </Dropzone>

              <p>
                {errors.archivosResiduos ? (
                  <div className="text-red-500">{errors.archivosResiduos}</div>
                ) : null}
              </p>
            </div>
          </div>
        </div>
      </div>
    </VerificarPermisos>
  );
};

export default MaterialAdjunto;
