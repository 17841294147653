import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import serviceChat from 'services/chat';
import serviceEmpresa from 'services/empresas';
import Room from './Room';
import { MensajeSinSala } from './componentes/MensajeSinSala';
import { PanelParticipantes } from './componentes/PanelParticipantes';
import { SkeletonChat } from './componentes/SkeletonChat';
import { ListaSalas } from './componentes/ListaSalas';
import {
  deshabilitarRoles,
  determinaRoles,
  ordenarUsuariosPorEmpresa
} from './utils/utils';
import usuariosService from 'services/usuarios';
import { DICTONARY } from 'const/Dictonary';
import { AuthContext } from 'context';
import { AlertaError, AlertaInfo } from 'utils/Alertas';

const SalaChat = ({ match, history, ...props }) => {
  const { logeduser } = useContext(AuthContext);
  const { roomid } = useParams();
  const codigoSalaChat = parseInt(roomid);
  const [isLoading, setIsLoading] = useState(true);
  const [salaResiduo, setSalaResiduo] = useState(null);
  const [reloadRoomUsers, setReloadRoomUsers] = useState(0);
  const [roomList, setRoomList] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);

  const [codigoEmpresaAsesora, setCodigoEmpresaAsesora] = useState(null);
  const [codigoEmpresaDemandante, setCodigoEmpresaDemandante] = useState(null);
  const [codigoEmpresaGestora, setCodigoEmpresaGestora] = useState(null);
  const [codigoEmpresaTransportista, setCodigoEmpresaTransportista] =
    useState(null);

  const [empresasIniciales, setEmpresasIniciales] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [disabledRoles, setDisabledRoles] = useState({
    demandante: true,
    oferente: true,
    asesor: true,
    gestor: true,
    transportista: true
  });
  const [isLoadingUsuarios, setIsLoadingUsuarios] = useState(true);

  const [usuariosNegocio, setUsuariosNegocio] = useState([]);
  const [usuariosListAsesores, setUsuariosListAsesores] = useState([]);
  const [usuariosListGestores, setUsuariosListGestores] = useState([]);
  const [usuariosListTransportista, setUsuariosListTransportista] = useState(
    []
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const listaUsuariosSala = useRef([]);

  // LISTA USUARIOS ASESORES
  useEffect(() => {
    const query = codigoEmpresaAsesora
      ? { codigoEmpresa: codigoEmpresaAsesora }
      : { codigoRol: 5 };
    serviceEmpresa
      .usuarios(query, { cache: true })
      .then(result => {
        if (Array.isArray(result.data.content)) {
          const usuariosMarcados = markAddedUsers(result.data.content);
          setUsuariosListAsesores(ordenarUsuariosPorEmpresa(usuariosMarcados));
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, [codigoEmpresaAsesora]);

  // LISTA USUARIOS GESTORES
  useEffect(() => {
    const query = codigoEmpresaGestora
      ? { codigoEmpresa: codigoEmpresaGestora }
      : { codigoRol: 4 };
    serviceEmpresa
      .usuarios(query, { cache: true })
      .then(result => {
        if (Array.isArray(result.data.content)) {
          setUsuariosListGestores(markAddedUsers(result.data.content));
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, [codigoEmpresaGestora]);

  // LISTA USUARIOS TRANSPORTISTAs
  useEffect(() => {
    const query = codigoEmpresaTransportista
      ? { codigoEmpresa: codigoEmpresaTransportista }
      : { codigoRol: 3 };

    serviceEmpresa
      .usuarios(query, { cache: true })
      .then(result => {
        if (Array.isArray(result.data.content)) {
          setUsuariosListTransportista(markAddedUsers(result.data.content));
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, [codigoEmpresaTransportista]);

  useEffect(() => {
    async function cargarSalas() {
      try {
        const response = await serviceChat.salaChat(
          null,
          DICTONARY.INTERCEPTOR.DESACTIVADO
        );
        const errorSalaChat = response.data.error;
        if (errorSalaChat) throw new Error('ERROR_CREAR_REUNION');

        const salas = response.data.content
          .map(s => ({
            ...s,
            dateFechaUltimaSesion: s.fechaCreacionUsuario
              ? new Date(s.fechaCreacionUsuario)
              : 0
          }))
          .sort((a, b) => b.dateFechaUltimaSesion - a.dateFechaUltimaSesion);
        setRoomList(salas);
        await recargarSala(salas);
        setIsLoading(false);

        const responseUsuarios = await usuariosService.getUsuarios({
          codigoEmpresa: logeduser.sucursal.empresa.codigoEmpresa
        });

        const errorUsuarios = responseUsuarios.data.error;
        if (errorUsuarios) throw new Error('ERROR_CREAR_REUNION');
        setUsuariosNegocio(
          responseUsuarios.data.content.filter(
            usuario => usuario.codigoUsuario !== logeduser.codigoUsuario
          )
        );
      } catch (error) {
        setRoomList([]);
        setUsuariosNegocio([]);
        setIsLoading(false);
      }
    }
    cargarSalas();
  }, []);

  useEffect(() => {
    if (roomList && codigoSalaChat) {
      recargarSala();
    }
  }, [codigoSalaChat, roomList]);

  const recargarSala = async salas => {
    try {
      if (codigoSalaChat > 0) {
        await cargarUsuariosSala();
        await cargarInformacionSala(salas);
      }
    } catch (error) {
      console.log('ERROR_INIT', error);
    }
  };

  const cargarInformacionSala = async salas => {
    const salasAux = salas ? salas : roomList;
    if (salasAux.length === 0) return;
    const room = salasAux.find(r => r.codigoSalaChat === codigoSalaChat);

    try {
      if (room) {
        setCurrentRoom(room);
        console.log('ROOM', room);
        setParticipantes(room.usuariosSalaChat);
        setDisabledRoles(deshabilitarRoles(room.usuariosSalaChat));
        setIsLoadingUsuarios(false);

        const isSalaResiduo = room.residuo?.codigoResiduo || null;
        if (isSalaResiduo) {
          const dataSala = {
            id: codigoSalaChat,
            nombre: room.residuo.nombreResiduo,
            sucursal: room.residuo.sucursal.nombreSucursal,
            origenSalaChat: 'residuo',
            ...room.residuo
          };

          setSalaResiduo(dataSala);
        } else {
          setSalaResiduo({
            origenSalaChat: 'empresa'
          });
        }
      }
    } catch (error) {
      const dataSala = {
        id: codigoSalaChat,
        nombre: room.residuo.nombreResiduo,
        sucursal: room.residuo.sucursal.nombreSucursal,
        origenSalaChat: 'residuo',
        ...room.residuo
      };

      setSalaResiduo(dataSala);
      AlertaInfo(
        'El residuo asociado a esta sala de chat ya no está disponible. Aun así puedes seguir usando esta sala para comunicarte con el resto de usuarios.'
      );
      throw new Error('ERROR_SERVICIO_RESIDUO', error);
    }
  };

  const cargarUsuariosSala = async () => {
    try {
      const response = await serviceChat.listaUsuariosSalaChat(
        codigoSalaChat,
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );
      const error = response.data.error;
      if (error) throw new Error('ERROR_CREAR_REUNION');

      listaUsuariosSala.current = response.data.usuarios;

      const empresas = response.data.empresasIniciales;
      let roles = determinaRoles(empresas);

      setCodigoEmpresaAsesora(roles.asesor);
      setCodigoEmpresaGestora(roles.gestor);
      setCodigoEmpresaTransportista(roles.transportista);
      setCodigoEmpresaDemandante(roles.demandante);
      console.log('setEmpresasIniciales', response.data);
      setEmpresasIniciales(response.data.empresasIniciales);
      setReloadRoomUsers(reloadRoomUsers + 1);
    } catch (error) {
      setCodigoEmpresaAsesora(null);
      setCodigoEmpresaGestora(null);
      setCodigoEmpresaTransportista(null);
      setCodigoEmpresaDemandante(null);
      setEmpresasIniciales(null);
      throw new Error('ERROR_CARGAR_USUARIOS', error);
    }
  };

  //AÑADE USUARIO SALA
  const handleAddUserClick = usuario => {
    setIsLoadingUsuarios(true);
    serviceChat
      .agregaUsuarioSalaChat({
        codigoSalaChat: codigoSalaChat,
        usuariosSalaChat: [{ codigoUsuario: usuario.codigoUsuario }]
      })
      .then(result => {
        const usuariosEnSala = [...currentRoom.usuariosSalaChat, usuario];
        setParticipantes(usuariosEnSala);
        setCurrentRoom({
          ...currentRoom,
          usuariosSalaChat: usuariosEnSala
        });
        setDisabledRoles(deshabilitarRoles(usuariosEnSala));
        setIsLoadingUsuarios(false);
      })
      .catch(err => {
        AlertaError('No se pudo agregar el usuario');
        console.error(err);
        setIsLoadingUsuarios(false);
      });
  };

  //ELIMINA USUARIO SALA
  const handleRemoveUserClick = usuario => {
    setIsLoadingUsuarios(true);
    serviceChat
      .eliminarUsuarioSalaChat({
        codigoSalaChat: codigoSalaChat,
        usuariosSalaChat: [{ codigoUsuario: usuario.codigoUsuario }]
      })
      .then(result => {
        let usuariosEnSala = currentRoom.usuariosSalaChat;
        const filtrados = usuariosEnSala.filter(
          user => user.codigoUsuario !== usuario.codigoUsuario
        );
        setParticipantes(filtrados);
        setCurrentRoom({
          ...currentRoom,
          usuariosSalaChat: filtrados
        });
        setDisabledRoles(deshabilitarRoles(filtrados));
        setIsLoadingUsuarios(false);

        if (usuario.codigoUsuario === logeduser.codigoUsuario) {
          //Caso en que me auto-elimino de una sala
          setRoomList(
            roomList.filter(
              sala => sala.codigoSalaChat !== currentRoom.codigoSalaChat
            )
          );
          history.push('/chat/0');
        }
      })
      .catch(err => {
        AlertaError('No se pudo eliminar al Usuario');
        console.error(err);
        setIsLoadingUsuarios(false);
      });
  };

  //CAMBIA DE SALA.
  const handleRoomClick = room => event => {
    history.push('/chat/' + room.codigoSalaChat);
  };

  // filtrar a los usuarios que pertenecen a la sala
  useEffect(() => {
    setUsuariosListAsesores(ul => markAddedUsers(ul));
    setUsuariosListGestores(ul => markAddedUsers(ul));
    setUsuariosListTransportista(ul => markAddedUsers(ul));
  }, [reloadRoomUsers]);

  const markAddedUsers = usuarios => {
    return usuarios.map(u => ({
      ...u,
      isAdded: listaUsuariosSala.current.includes(u.codigoUsuario)
    }));
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="px-4 md:container md:mx-auto my-5">
      <div className="bg-white rounded shadow-md min-h-[80vh]">
        {isLoading ? (
          <SkeletonChat />
        ) : (
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-3 lg:col-span-3 h-full">
              <ListaSalas
                roomList={roomList}
                codigoSalaChat={codigoSalaChat}
                handleRoomClick={handleRoomClick}
              />
            </div>

            {codigoSalaChat > 0 && salaResiduo && currentRoom && (
              <div className="col-span-12 md:col-span-9 lg:col-span-9 h-full relative">
                <Room
                  key={codigoSalaChat}
                  roomid={codigoSalaChat || null}
                  roomData={currentRoom || {}}
                  rsdData={salaResiduo}
                  listaUsuariosSala={listaUsuariosSala.current}
                  codigoEmpresaDemandante={codigoEmpresaDemandante}
                  toggleMenu={toggleMenu}
                />

                <div
                  className={`absolute right-0 top-0 max-w-[320px] bg-white shadow-2xl h-full 
                    ${isMenuOpen ? 'min-w-[310px] sm:min-w-[320px]' : ''} `}
                >
                  {isMenuOpen ? (
                    <PanelParticipantes
                      isLoadingUsuarios={isLoadingUsuarios}
                      participantes={participantes}
                      disabledRoles={disabledRoles}
                      usuariosListAsesores={usuariosListAsesores}
                      usuariosListGestores={usuariosListGestores}
                      usuariosListTransportista={usuariosListTransportista}
                      usuariosNegocio={usuariosNegocio}
                      handleAddUserClick={handleAddUserClick}
                      handleRemoveUserClick={handleRemoveUserClick}
                      roomId={codigoSalaChat}
                      toggleMenu={toggleMenu}
                      empresasIniciales={empresasIniciales}
                    />
                  ) : null}
                </div>
              </div>
            )}

            {codigoSalaChat === 0 && !isLoading && (
              <div className="col-span-12 md:col-span-9 lg:col-span-9 h-full">
                <MensajeSinSala />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

SalaChat.propTypes = {
  history: PropTypes.object
};

export default withRouter(SalaChat);
