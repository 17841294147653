// Expresión regular para permitir solo números y letras
const regex = /^[0-9-]*$/;
// Expresión regular para permitir solo números y permitir decimales
const regexDecimales = /^[0-9.]*$/;

export const handleKeyPressNumeros = event => {
  const { key } = event;
  // Verificar si la tecla presionada no es un número ni una letra
  if (!regex.test(key) && key !== 'Backspace' && key !== 'Delete') {
    event.preventDefault();
  }
};
export const handleKeyPressNumerosDecimales = event => {
  const { key } = event;
  // Verificar si la tecla presionada no es un número ni una letra
  if (!regexDecimales.test(key) && key !== 'Backspace' && key !== 'Delete') {
    event.preventDefault();
  }
};
