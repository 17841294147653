import { DICTONARY } from 'const/Dictonary';
import { useState } from 'react';

export const Paginacion = setCargaLista => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState(DICTONARY.PAGINACION.DEFAULT);

  return {
    data,
    setData,
    total,
    setTotal,
    setPagination,
    pagination
  };
};
