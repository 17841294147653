import { ToggleButtonGroup } from '@mui/material';
import { ToggleButtonComponent } from 'components/design-system/ToggleButton/ToggleButtonComponent';
import { useContext } from 'react';
import { MatchContext } from '../../../context/MatchContext';
import { PopperComponent } from 'components/design-system/Popper/Popper';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Help from '@mui/icons-material/Help';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';

/**
 * @name Ordenamiento
 * Componente que dispone los elementos de ordenamiento "DISPONIBILIDAD" "DISTANCIA" para ordenar el resultado del match según esos criterios.
 */
export const Ordenamiento = () => {
  const {
    matchFiltrado,
    setOrdenarMatch,
    ordenarMatch,
    tabActiva,
    isCargandoMatchResiduos
  } = useContext(MatchContext);

  if (tabActiva !== DICTONARY.MATCH.TABS.RESIDUOS && !isCargandoMatchResiduos) {
    return;
  }

  if (matchFiltrado.numeroSucursales === 0) {
    return;
  }

  return (
    <div className="p-4 pb-2 h-max">
      <div className="mb-3">
        <span className="block font-secondary text-xs text-black tracking-wider font-medium relative">
          ORDENAR RESULTADOS
          <div className="absolute right-0 -top-2.5 z-10">
            <PopperComponent
              Button={
                <IconButtonComponent size="small" type="neutral">
                  <Help className="text-uv-secondary-0" />
                </IconButtonComponent>
              }
            >
              <div className="py-2 px-4 min-w-[400px]">
                <div className="grid gap-4 grid-cols-12">
                  <div className="col-span-12">
                    <Heading type="h5">ORDENAMIENTO ACTUAL:</Heading>
                  </div>

                  <div
                    className={`col-span-12 
                    ${
                      ordenarMatch.includes('disponibilidad') &&
                      ordenarMatch.includes('distancia')
                        ? 'opacity-100'
                        : 'opacity-50'
                    }
                  `}
                  >
                    <div className="grid grid-cols-12">
                      <div className="col-span-5">
                        <Heading type="h5" className="text-uv-secondary-0">
                          Disponibilidad + Distancia
                        </Heading>
                      </div>
                      <div className="col-span-7">
                        <Text size="s">
                          Ordenamiento mediante un cálculo entre la
                          disponibilidad y distancia.
                        </Text>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`col-span-12 
                    ${
                      ordenarMatch.includes('disponibilidad') &&
                      !ordenarMatch.includes('distancia')
                        ? 'opacity-100'
                        : 'opacity-50'
                    }
                  `}
                  >
                    <div className="grid grid-cols-12">
                      <div className="col-span-5">
                        <Heading type="h5" className="text-uv-secondary-0">
                          Disponibilidad
                        </Heading>
                      </div>
                      <div className="col-span-7">
                        <Text size="s">
                          Ordenamiento únicamente basado en el más cercano a
                          contar de la fecha actual.
                        </Text>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`col-span-12 mb-4
                    ${
                      !ordenarMatch.includes('disponibilidad') &&
                      ordenarMatch.includes('distancia')
                        ? 'opacity-100'
                        : 'opacity-50'
                    }
                  `}
                  >
                    <div className="grid grid-cols-12">
                      <div className="col-span-5">
                        <Heading type="h5" className="text-uv-secondary-0">
                          Distancia
                        </Heading>
                      </div>
                      <div className="col-span-7">
                        <Text size="s">
                          Ordenamiento únicamente basado en el más cercano entre
                          la ubicación del residuo y la sucursal de tu negocio.
                        </Text>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`col-span-12 mb-4
                    ${
                      !ordenarMatch.includes('disponibilidad') &&
                      !ordenarMatch.includes('distancia')
                        ? 'opacity-100'
                        : 'opacity-50'
                    }
                  `}
                  >
                    <div className="grid grid-cols-12">
                      <div className="col-span-5">
                        <Heading type="h5" className="text-uv-secondary-0">
                          Sin ordenamiento
                        </Heading>
                      </div>
                      <div className="col-span-7">
                        <Text size="s">
                          Los resultados no se están ordenando bajo ningún
                          criterio.
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-12">
                    <Text size="s">
                      Por defecto el resultado del match automático se ordena
                      mediante un cálculo matemático entre la disponibilidad y
                      distancia. Dicho cálculo promueve la menor disponibilidad
                      y distancia posible.
                    </Text>
                  </div>
                </div>
              </div>
            </PopperComponent>
          </div>
        </span>
      </div>
      <div className="grid gap-4 grid-cols-2">
        <div className="col-span-2">
          <ToggleButtonGroup
            disabled={matchFiltrado.numeroResiduos === 0}
            value={ordenarMatch}
            onChange={setOrdenarMatch}
            aria-label="text formatting"
            className="w-full flex"
          >
            <ToggleButtonComponent
              nombre="disponibilidad"
              posicion="inicio"
              isActive={ordenarMatch.includes('disponibilidad')}
            />
            <ToggleButtonComponent
              nombre="distancia"
              posicion="fin"
              isActive={ordenarMatch.includes('distancia')}
            />
          </ToggleButtonGroup>
        </div>
      </div>
    </div>
  );
};
