import { useEffect } from 'react';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import LabelComponent from 'components/design-system/Label/Label';
import InputComponent from 'components/design-system/input/Input';
import CheckboxComponent from 'components/design-system/Checkbox/Checkbox';

export const BuscarMaterialCampos = ({
  values,
  handleChange,
  setFieldTouched,
  errors,
  touched,
  setFieldValue,
  listaMarcoLegal,
  listaNormativaTecnica,
  listaPublicaciones,
  listaTipoResiduo,
  listaTipoTratamiento,
  listaRegiones,
  listaComunas,
  handleObtenerComunas,
  isSubmitting,
  isLoadingComunas
}) => {
  useEffect(() => {
    if (!values.region || values.region.length === 0) {
      return;
    }
    const listaCodigoRegiones = values.region.map(region => region.codigoRegion);

    if (listaCodigoRegiones.length > 0) {
      handleObtenerComunas(listaCodigoRegiones,setFieldValue);
    }
  }, [values.region, handleObtenerComunas, setFieldValue]);


  return (
    <>
      <div className="col-span-12 md:col-span-12 lg:col-span-4">
        <div className="grid grid-cols-12 gap-5">
          <div className="col-span-12">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">
                <Heading type="h4" className="text-uv-primary-0">
                  Buscar por nombre
                </Heading>
              </div>
              <div className="col-span-12">
                <InputComponent
                  fullWidth
                  name="nombreArchivo"
                  value={values.nombreArchivo}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('nombreArchivo')}
                  touched={touched.nombreArchivo ? true : false}
                  label={<LabelComponent>Nombre del material de información</LabelComponent>}
                  placeholder=""
                  estado={
                    errors.ERROR_VALIDACION
                      ? {
                        tipo: 'error',
                        mensaje: 'Debes completar al menos 1 campo'
                      }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-span-12 md:col-span-12 lg:col-span-8 pt-4 lg:pt-0 lg:pl-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12">
                <Heading type="h4" className="text-uv-primary-0">
                  Buscar por categoría asociada al material de información
                </Heading>
              </div>

              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                <AutocompleteComponent
                  disableCloseOnSelect
                  limitTags={1}
                  id="marcoLegal"
                  multiple
                  accesor={'nombreMarcoLegal'}
                  name="marcoLegal"
                  options={listaMarcoLegal}
                  disabled={isSubmitting ? true : false}
                  onChange={(ev, marcoLegalSelected, reason) => {
                    let data = {
                      name: 'marcoLegal',
                      value: marcoLegalSelected
                    };
                    if (reason === 'clear') {
                      data.value = [];
                    }
                    setFieldValue('marcoLegal', data.value);
                  }}
                  onBlur={() => setFieldTouched('marcoLegal', true)}
                  value={values.marcoLegal}
                  getOptionLabel={option => option.nombreMarcoLegal || ''}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <CheckboxComponent
                        size="small"
                        className="py-1"
                        checked={selected}
                      />
                      {option.nombreMarcoLegal}
                    </li>
                  )}
                >
                  <InputComponent
                    fullWidth
                    touched={touched.marcoLegal ? true : false}
                    label={
                      <LabelComponent>Marco legal y tramitación</LabelComponent>
                    }
                    estado={
                      errors.ERROR_VALIDACION
                        ? {
                          tipo: 'error',
                          mensaje: 'Debes completar al menos 1 campo'
                        }
                        : null
                    }
                  />
                </AutocompleteComponent>
              </div>

              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                <AutocompleteComponent
                  id="normativaTecnica"
                  disableCloseOnSelect
                  limitTags={1}
                  accesor={'nombreNormativaTecnica'}
                  multiple
                  name="normativaTecnica"
                  options={listaNormativaTecnica}
                  disabled={isSubmitting ? true : false}
                  onChange={(ev, normativaTecnicaSelected, reason) => {
                    let data = { name: 'normativaTecnica', value: normativaTecnicaSelected };
                    if (reason === 'clear') {
                      data.value = [];
                    }
                    setFieldValue('normativaTecnica', data.value);
                  }}
                  onBlur={() => setFieldTouched('normativaTecnica', true)}
                  value={values.normativaTecnica}
                  getOptionLabel={option => option.nombreNormativaTecnica || ''}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <CheckboxComponent
                        size="small"
                        className="py-1"
                        checked={selected}
                      />
                      {option.nombreNormativaTecnica}
                    </li>
                  )}
                >
                  <InputComponent
                    fullWidth
                    touched={touched.normativaTecnica ? true : false}
                    label={<LabelComponent>Normativa técnica</LabelComponent>}
                    estado={
                      errors.ERROR_VALIDACION
                        ? {
                          tipo: 'error',
                          mensaje: 'Debes completar al menos 1 campo'
                        }
                        : null
                    }
                  />
                </AutocompleteComponent>
              </div>

              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                <AutocompleteComponent
                  id="publicaciones"
                  disableCloseOnSelect
                  limitTags={1}
                  multiple
                  accesor={'nombrePublicacion'}
                  name="publicaciones"
                  options={listaPublicaciones}
                  disabled={isSubmitting ? true : false}
                  onChange={(ev, publicacionesSelected, reason) => {
                    let data = {
                      name: 'publicaciones',
                      value: publicacionesSelected
                    };
                    if (reason === 'clear') {
                      data.value = [];
                    }
                    setFieldValue('publicaciones', data.value);
                  }}
                  onBlur={() => setFieldTouched('publicaciones', true)}
                  value={values.publicaciones}
                  getOptionLabel={option => option.nombrePublicacion || ''}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <CheckboxComponent
                        size="small"
                        className="py-1"
                        checked={selected}
                      />
                      {option.nombrePublicacion}
                    </li>
                  )}
                >
                  <InputComponent
                    fullWidth
                    className="h-9"
                    touched={touched.publicaciones ? true : false}
                    label={<LabelComponent>Publicaciones</LabelComponent>}
                    estado={
                      errors.ERROR_VALIDACION
                        ? {
                          tipo: 'error',
                          mensaje: 'Debes completar al menos 1 campo'
                        }
                        : null
                    }
                  />
                </AutocompleteComponent>
              </div>

              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                <AutocompleteComponent
                  id="tipoResiduo"
                  name="tipoResiduo"
                  options={listaTipoResiduo}
                  accesor={'nombreTipo'}
                  disabled={isSubmitting ? true : false}
                  onChange={(ev, tipoResiduoSelected, reason) => {
                    let data = {
                      name: 'tipoResiduo',
                      value: tipoResiduoSelected
                    };
                    if (reason === 'clear') {
                      data.value = {};
                    }
                    setFieldValue('tipoResiduo', data.value);
                  }}
                  onBlur={() => setFieldTouched('tipoResiduo', true)}
                  value={values.tipoResiduo}
                  getOptionLabel={option => option.nombreTipo || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.codigoTipoResiduo === value.codigoTipoResiduo
                  }
                >
                  <InputComponent
                    fullWidth
                    className="h-9"
                    touched={touched.tipoResiduo ? true : false}
                    label={<LabelComponent>Tipo residuo</LabelComponent>}
                    estado={
                      errors.ERROR_VALIDACION
                        ? {
                          tipo: 'error',
                          mensaje: 'Debes completar al menos 1 campo'
                        }
                        : null
                    }
                  />
                </AutocompleteComponent>
              </div>

              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                <AutocompleteComponent
                  id="tipoTratamientoResiduo"
                  name="tipoTratamientoResiduo"
                  accesor={'nombreTipoTratamiento'}
                  options={listaTipoTratamiento}
                  disabled={isSubmitting ? true : false}
                  onChange={(ev, tipoTratamientoResiduoSelected, reason) => {
                    let data = { name: 'tipoTratamientoResiduo', value: tipoTratamientoResiduoSelected };
                    if (reason === 'clear') {
                      data.value = {};
                    }

                    setFieldValue('tipoTratamientoResiduo', data.value);
                  }}
                  onBlur={() => setFieldTouched('tipoTratamientoResiduo', true)}
                  value={values.tipoTratamientoResiduo}
                  getOptionLabel={option => option.nombreTipoTratamiento || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.codigoTipoTratamiento === value.codigoTipoTratamiento
                  }
                >
                  <InputComponent
                    fullWidth
                    className="h-9"
                    touched={touched.tipoTratamientoResiduo ? true : false}
                    label={
                      <LabelComponent>
                        Tipo de tratamiento de Residuo
                      </LabelComponent>
                    }
                    estado={
                      errors.ERROR_VALIDACION
                        ? {
                          tipo: 'error',
                          mensaje: 'Debes completar al menos 1 campo'
                        }
                        : null
                    }
                  />
                </AutocompleteComponent>
              </div>

              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                <AutocompleteComponent
                  id="region"
                  name="region"
                  accesor="nombreRegion"
                  disableCloseOnSelect
                  options={listaRegiones}
                  // Multiple Opciones con select all
                  disabled={isSubmitting ? true : false}
                  multiple
                  limitTags={1}
                  freeSolo={false}
                  isSelectAll
                  labelSelectAll="Alcance nacional"

                  onChange={(ev, region, reason) => {

                    let data = { name: 'region', value: region };
    
                    if (reason === 'clear') {
                      data.value = [];
                      setFieldValue('comuna', []);
                    }
                    // Multiple Opciones con select all
                    if (region.find(option => option.all)) {
    
                      setFieldValue('region', values.region.length === listaRegiones.length
                        ? []
                        : listaRegiones)
    
                    } else {
                      setFieldValue('region', data.value);
                    }
                  }}
                  onBlur={() => setFieldTouched('region', true)}
                  value={values.region}
                  getOptionLabel={option => option.nombreRegion || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.codigoRegion === value.codigoRegion
                  }
                  renderOption={(props, option, { selected }) => (
                    <div className={(option.all ? 'border-b-2 border-primary-60 mt-0 pb-0.5 mb-0.5 bg-transparent  pt-0' : "")}>
                      <li {...props}>
                        <CheckboxComponent
                          size="small"
                          className={"py-1"}
                          // checked={selected}
                          checked={option.all ? !!(values.region.length === listaRegiones.length) : selected}
                        />
                        {option.nombreRegion}
                      </li>
                    </div>
                  )}
                >
                  <InputComponent
                    fullWidth
                    className="h-9"
                    touched={touched.region}
                    placeholder={
                      values.region.length === listaRegiones.length
                        ? 'Todas las regiones'
                        : 'Selecciona una o varias regiones'
                    }
                    label={<LabelComponent esOpcional>Región</LabelComponent>}
                  />
                </AutocompleteComponent>
              </div>

              <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6">
                <AutocompleteComponent
                  id="comuna"
                  name="comuna"
                  options={listaComunas}
                  accesor="nombreComuna"
                  disabled={
                    !values.region.some(obj => obj.hasOwnProperty('nombreRegion')) || isSubmitting ? true : false
                  }
                  loading={isLoadingComunas}
                  // Multiple Opciones con select all
                  disableCloseOnSelect
                  multiple
                  limitTags={1}
                  freeSolo={false}
                  isSelectAll
                  labelSelectAll="Alcance regional"
                  onChange={(ev, comuna, reason) => {

                    let data = { name: 'comuna', value: comuna };

                    if (reason === 'clear') {
                      data.value = [];
                    }

                    // Multiple Opciones con select all
                    if (comuna.find(option => option.all)) {
                      setFieldValue('comuna', values.comuna.length === listaComunas.length
                        ? []
                        : listaComunas)
                    } else {
                      setFieldValue('comuna', data.value);
                    }
                  }}
                  onBlur={() => setFieldTouched('comuna', true)}
                  value={values.comuna}
                  getOptionLabel={option => option.nombreComuna || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.codigoComuna === value.codigoComuna
                  }
                  renderOption={(props, option, { selected }) => (
                    <div className={(option.all ? 'border-b-2 border-primary-60 mt-0 pb-0.5 mb-0.5 bg-transparent  pt-0' : "")}>
                      <li {...props} >
                        <CheckboxComponent
                          size="small"
                          className={"py-1"}
                          // checked={selected}
                          checked={option.all ? !!(values.comuna.length === listaComunas.length) : selected}
                        />
                        {option.nombreComuna}
                      </li>
                    </div>
                  )}
                >
                  <InputComponent
                    fullWidth
                    className="h-9"
                    touched={touched.comuna}
                    placeholder={
                      !values.region.some(obj => obj.hasOwnProperty('nombreRegion'))
                        ? 'Debes seleccionar una región'
                        : values.comuna.length === listaComunas.length
                          ? 'Todas las comunas'
                          : 'Selecciona una o varias comunas'
                    }
                    label={<LabelComponent>Comuna</LabelComponent>}
                    estado={
                      errors.ERROR_SIN_COMUNA
                        ? { tipo: 'error', mensaje: errors.ERROR_SIN_COMUNA }
                        : null
                    }
                  />
                </AutocompleteComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
