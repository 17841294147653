import { DICTONARY } from 'const/Dictonary';
import { AuthContext } from 'context';
import { OrdenarAscendente } from 'helpers/manejoArreglos';
import { useContext } from 'react';
import adminService from 'services/admin';
import { AlertaError } from 'utils/Alertas';

export const useServices = () => {
  const { perfiles, tipoNegocio } = useContext(AuthContext);

  const cargarPerfiles = async (
    setPerfiles,
    setIsLoadingPerfiles,
    params,
    customFiltro
  ) => {
    try {
      setIsLoadingPerfiles(true);
      const { data } = await adminService.getPerfiles(params);
      const { content } = data;
      let isSuperAdmin = perfiles.isSuperAdmin;

      if (isSuperAdmin) {
        setPerfiles(OrdenarAscendente(content, 'nombrePerfil'));
      } else {
        setPerfiles(
          customFiltro
            ? OrdenarAscendente(customFiltro(content), 'nombrePerfil')
            : OrdenarAscendente(
                filtroPerfilesRestringidos(content),
                'nombrePerfil'
              )
        );
      }
    } catch (error) {
      console.log(error);
      AlertaError('Error al cargar los perfiles');
    } finally {
      setIsLoadingPerfiles(false);
    }
  };

  const filtroPerfilesNegocio = perfiles => {
    if (!perfiles) {
      throw new Error('No hay perfiles');
    }

    const perfilesNegocio = tipoNegocio;
    const perfilesFiltrados = perfiles.filter(
      perfil =>
        perfilesNegocio.findIndex(name =>
          perfil.nombrePerfil.toLowerCase().includes(name.toLowerCase())
        ) >= 0
    );
    return perfilesFiltrados;
  };

  const filtroPerfilesRestringidos = perfiles => {
    return perfiles.filter(
      perfil =>
        perfil.codigoPerfil !==
          DICTONARY.CODIGOS_PERFILES.SUPER_ADMINISTRADOR &&
        perfil.codigoPerfil !==
          DICTONARY.CODIGOS_PERFILES.ANALISTA_TERRITORIAL &&
        perfil.codigoPerfil !== DICTONARY.CODIGOS_PERFILES.USUARIO
    );
  };

  return { cargarPerfiles, filtroPerfilesNegocio };
};
