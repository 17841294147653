export const ICON_SALARIO = ({ className }) => {
  return (
    <svg className={className} width="54" height="37" viewBox="0 0 54 37">
      <defs>
        <clipPath id="clip-salario">
          <rect width="54" height="37" />
        </clipPath>
      </defs>
      <g id="salario" clip-path="url(#clip-salario)">
        <g
          id="Grupo_1943"
          data-name="Grupo 1943"
          transform="translate(23144 2746)"
        >
          <path
            id="ic_people_outline_24px"
            d="M28.028,18.95a20.9,20.9,0,0,0-7.847,1.744,20.569,20.569,0,0,0-7.847-1.744C8.55,18.95,1,20.834,1,24.618v4.8H39.362v-4.8C39.362,20.834,31.812,18.95,28.028,18.95ZM21.053,26.8H3.616v-2.18c0-.942,4.464-3.052,8.719-3.052s8.719,2.11,8.719,3.052Zm15.694,0H23.669v-2.18a3.173,3.173,0,0,0-.907-2.127,16.822,16.822,0,0,1,5.266-.924c4.255,0,8.719,2.11,8.719,3.052ZM12.334,17.207a6.1,6.1,0,1,0-6.1-6.1A6.11,6.11,0,0,0,12.334,17.207Zm0-9.591A3.488,3.488,0,1,1,8.847,11.1,3.5,3.5,0,0,1,12.334,7.616Zm15.694,9.591a6.1,6.1,0,1,0-6.1-6.1A6.11,6.11,0,0,0,28.028,17.207Zm0-9.591A3.488,3.488,0,1,1,24.541,11.1,3.5,3.5,0,0,1,28.028,7.616Z"
            transform="translate(-23142.242 -2748.936)"
            fill="#225B2A"
          />
          <g id="Grupo_1052" data-name="Grupo 1052">
            <g
              id="Grupo_1038"
              data-name="Grupo 1038"
              transform="translate(-23114.455 -2733.794)"
            >
              <ellipse
                id="Elipse_171"
                data-name="Elipse 171"
                cx="11"
                cy="11.5"
                rx="11"
                ry="11.5"
                transform="translate(0.456 -0.206)"
                fill="#43A7B6"
              />
            </g>
            <path
              id="ic_attach_money_24px"
              d="M11.865,10.993c-2.3-.6-3.035-1.214-3.035-2.175,0-1.1,1.022-1.872,2.732-1.872,1.8,0,2.469.86,2.53,2.125h2.236a4.033,4.033,0,0,0-3.248-3.855V3H10.043V5.186C8.081,5.611,6.5,6.885,6.5,8.838c0,2.337,1.933,3.5,4.755,4.179,2.53.607,3.035,1.5,3.035,2.439,0,.7-.5,1.811-2.732,1.811-2.084,0-2.9-.931-3.015-2.125H6.32c.121,2.216,1.781,3.46,3.723,3.875v2.2h3.035V19.037c1.973-.374,3.541-1.518,3.541-3.592C16.62,12.572,14.161,11.59,11.865,10.993Z"
              transform="translate(-23114.633 -2734.729)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
