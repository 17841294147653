
const usePrecioReferencial = ({
  setFieldTouched,
  setFieldValue
}) => {
  const handleFocusPrecioReferencial = event => {
    setFieldTouched('precioReferencial');
    if (!event) return;
    if (!event.target) return;
    if (!event.target.value) return;
    if (typeof event.target.value !== 'string') return;
    let stringValue = event.target.value.split('.').join('') || '';
    setFieldValue('precioReferencial_str', stringValue);
  };
  return{
    handleFocusPrecioReferencial
  }

}

export default usePrecioReferencial