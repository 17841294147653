import * as yup from 'yup';

const SchemaIngresaEmpresaAT = yup.object().shape({
  rutEmpresa: yup
    .string()
    .trim()
    .matches(/^[^.\s]*$/, 'No se permiten puntos en este campo')
    .max(11, 'La cantidad máxima de caracteres es 11')
    .min(9, 'La cantidad mínima de caracteres es 9')
    .required('Es un campo obligatorio'),
  razonSocial: yup.string().required('Es un campo obligatorio'),
  tramo: yup.number().required('Es un campo obligatorio'),
  actividad: yup.object().required('Es un campo obligatorio'),
  comuna: yup.object().required('Es un campo obligatorio'),
  nombreVia: yup
    .string()
    .trim()
    // validacion para direccion nombre y numero  de via
    // .matches(
    //   /^[A-Za-z0-9ñÑ\s]+(?: [A-Za-z0-9ñÑ\s]+)*,\s*\d+$/,
    //   'La dirección debe ser del tipo "nombre de calle, número"'
    // )
    .max(100, 'La cantidad máxima de caracteres es 100')
    .min(3, 'La cantidad mínima de caracteres es 3')
    .required('Es un campo obligatorio')
});

export { SchemaIngresaEmpresaAT };
