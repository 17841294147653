import { useContext, useEffect, useState } from 'react';
import { Marker } from 'react-leaflet';
import { MatchContext } from '../../../context/MatchContext';
import { PopupMarker } from 'components/PopupMarker';

/**
 * @name MarcadorResiduos
 * Componente que muestra marcador en el mapa
 * @param sucursal datos de la sucursal asociada al marcador
 * @param markers referencia al marcador posicionado en el mapa
 * @param getMapIcon renderiza el tipo de icono a desplegar según el rol de la empresa
 */
export const MarcadorResiduos = ({ sucursal, markers, getMapIcon }) => {
  const { tieneNegocio, residuoSeleccionado, setCoordenadasCentroMapa } =
    useContext(MatchContext);

  const [opacity, setOpacity] = useState(1);
  const [zindex, setZindex] = useState(1);

  const marcador = {
    codigoSucursal: sucursal.codigoSucursal,
    nombreEmpresa: sucursal.empresa,
    nombreSucursal: sucursal.nombreSucursal,
    direccion: `${sucursal?.direccion?.nombreVia}, ${sucursal?.direccion?.comuna}, Región ${sucursal?.direccion?.region}`,
    coordenadas: [sucursal.direccion.latitud, sucursal.direccion.longitud],
    rol: sucursal.rol
  };

  const handleSeleccionarSucursal = ev => {
    setCoordenadasCentroMapa(marcador.coordenadas);
  };

  const handlePopUpClose = () => {
    if (residuoSeleccionado === null) {
      setOpacity(1);
      setZindex(1);
      return;
    }

    if (
      residuoSeleccionado.sucursal.codigoSucursal === marcador.codigoSucursal
    ) {
      setZindex(100);
      setOpacity(1);
    } else {
      setZindex(1);
      setOpacity(0.2);
    }
  };

  useEffect(() => {
    if (residuoSeleccionado) {
      if (
        residuoSeleccionado.sucursal.codigoSucursal === marcador.codigoSucursal
      ) {
        setZindex(100);
        setOpacity(1);
      } else {
        setZindex(1);
        setOpacity(0.2);
      }
    }
  }, [residuoSeleccionado]);

  return (
    <Marker
      eventHandlers={{
        click: e => {
          handleSeleccionarSucursal();
        },
        popupclose: e => {
          handlePopUpClose();
        },
        popupopen: e => {
          setZindex(100);
          setOpacity(1);
        }
      }}
      position={marcador.coordenadas}
      boundsOptions={[marcador.coordenadas]}
      ref={el => {
        markers.current = {
          ...markers.current,
          [marcador.codigoSucursal]: el
        };
      }}
      key={marcador.codigoSucursal}
      icon={getMapIcon(marcador.rol)}
      opacity={opacity}
      zIndexOffset={zindex}
    >
      {tieneNegocio && (
        <PopupMarker
          nombreEmpresa={marcador.nombreEmpresa || 'No encontrado'}
          nombreSucursal={marcador.nombreSucursal || 'Sucursal'}
          direccion={marcador.direccion || 'No definido'}
        />
      )}
    </Marker>
  );
};
