import Dropzone from 'components/design-system/Dropzone/Dropzone';
import Heading from 'components/design-system/Heading/Heading';
import IconComponent from 'components/design-system/icon/Icon';
import LabelComponent from 'components/design-system/Label/Label';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const DocumentosRespaldo = ({ isSubmitting, setFieldValue, values }) => {
  return (
    <>
      <div className="col-span-12">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12">
            <Heading type="h4" className="mb-0">
              Documentos de respaldo
            </Heading>
          </div>
          <div className="col-span-12 xs:col-span-12">
            {/* Documentos */}
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 xs:col-span-12">
                <LabelComponent>
                  Cargar documento de respaldo, Ej: Guía de despacho, factura u
                  otro tipo de documento.{' '}
                  <span className="text-neutral-60">(opcional)</span>
                </LabelComponent>
                <Dropzone
                  setDataFiles={data => setFieldValue('archivos', data)}
                  dataFiles={values.archivos || []}
                  {...DICTONARY.DROPZONE.CONFIG_UPLOAD_BUSINESS_FILES}
                  multiple={true}
                  enableEditingFile={false}
                  disabled={isSubmitting}
                >
                  <Text className="text-black font-medium">
                    Puedes adjuntar los siguientes tipos de documentos
                  </Text>
                  <ul className="flex mt-1">
                    <li className="flex items-center mb-1">
                      <IconComponent className="mr-2">
                        <TaskAltIcon className="text-base text-uv-primary-20" />
                      </IconComponent>
                      <Text className="text-neutral-40">PDF y JPG</Text>
                    </li>
                    <li className="flex items-center mb-1 ml-6">
                      <IconComponent className="mr-2">
                        <TaskAltIcon className="text-base text-uv-primary-20" />
                      </IconComponent>
                      <Text className="text-neutral-40">
                        Tamaño no superior a 4 MB
                      </Text>
                    </li>
                  </ul>
                </Dropzone>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentosRespaldo;
