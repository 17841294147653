import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { useResponsive } from 'hooks/useResponsive';
import { useCallback, useEffect, useState } from 'react';
import { CuerpoNegocio } from './CuerpoNegocio';
import { useHelpersResiduosDeclarados } from '../hooks/useHelpersResiduosDeclarados';
import residuosServices from 'services/residuos';
import { formeateaDireccion } from 'helpers/manejoDatos';
import { toLocaleStringPLus } from 'utils';
import moment from 'moment';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccountBalance from '@mui/icons-material/AccountBalance';
import Place from '@mui/icons-material/Place';
import Add from '@mui/icons-material/Add';

export const Negocio = ({ negocio, tipoNegocio, cargarEmpresas }) => {
  const [isExpandido, setExpandido] = useState(false);
  const { isMDDown } = useResponsive();

  const { handleClickIngresar } = useHelpersResiduosDeclarados();

  // useEffect(() => {
  //   //Solo para temas ilustrativos
  //   if (isExpandido) {
  //     setIsCargando(true);
  //     setTimeout(() => {
  //       //Fetch data de negocio para completar tabla.
  //       setIsCargando(false);
  //     }, 2000);
  //   }
  // }, [isExpandido]);

  // function to get residuos using residuosServices.obtenerListaResiduosDeclarados
  const [residuos, setResiduos] = useState([]);
  const [residuosTable, setResiduosTable] = useState([]);
  const [isLoadingRecursos, setIsLoadingRecursos] = useState(true);
  const [address, setAddress] = useState('');
  const [pageTable, setPageTable] = useState({
    empty: false,
    first: true,
    last: false,
    number: 0,
    numberOfElements: 5,
    pageable: {
      pageNumber: null,
      pageSize: 5,
      sort: {},
      offset: 0,
      paged: true
    },
    size: 5,
    sort: { sorted: true, empty: false, unsorted: false },
    totalElements: 0,
    totalPages: 0
  });
  const [numPagina, setNumPagina] = useState(0);

  const getResiduos = useCallback(() => {
    setIsLoadingRecursos(true);
    residuosServices
      .obtenerListaResiduosDeclarados(
        {
          nombreGenerador:
            tipoNegocio === 'generador' ? negocio.label : undefined,
          nombreReceptor:
            tipoNegocio === 'receptor' ? negocio.label : undefined,
          numPagina: numPagina,
          totalPagina: 5
        },
        false
      )
      .then(res => {
        const {
          pageable,
          totalPages,
          totalElements,
          last,
          size,
          number,
          sort,
          numberOfElements,
          first,
          empty
        } = res.data;
        setPageTable({
          pageable,
          totalPages,
          totalElements,
          last,
          size,
          number,
          sort,
          numberOfElements,
          first,
          empty
        });

        let address;
        try {
          address = formeateaDireccion(
            tipoNegocio === 'generador'
              ? res.data.content[0].empresaGeneradora
              : res.data.content[0].empresaReceptor
          ); // TODO: cambiar segun filtro
        } catch (error) {}
        setAddress(address);

        setResiduos(res.data.content);
      })
      .catch(err => {
        console.log('Error al obtener residuos', err);
      })
      .finally(() => {
        setIsLoadingRecursos(false);
      });
  }, [negocio, numPagina]);

  useEffect(() => {
    getResiduos();
  }, [negocio, numPagina]);

  useEffect(() => {
    if (Array.isArray(residuos)) {
      setResiduosTable(
        residuos.map(residuo => {
          return {
            codigo: residuo.codigoResiduoDeclarado, // TODO: me imagino que cambia para residuo requerido
            residuo: residuo.tipoResiduo.nombreTipo,
            cantidad:
              toLocaleStringPLus(residuo.cantidad) +
              ' (' +
              residuo.unidadMedida.nombreUnidadMedida +
              ')',
            fechaDisponibilidad: moment(residuo.fechaTransaccion).format(
              'DD-MM-YYYY'
            ), // TODO: fechaTransaccion
            certificadosRecepcion: residuo.certificadosRecepcion,
            codigoDeclarado: {
              codigo: residuo.codigoResiduoDeclarado,
              isResiduoDeclarado: residuo.transaccion?.residuoDeclarado || false
            }
          };
        })
      );
    } else {
      setResiduosTable([]);
      cargarEmpresas();
    }
  }, [residuos]);

  return (
    <Accordion
      expanded={isExpandido}
      disableGutters={true}
      onChange={event => {
        if (event.target.nodeName !== 'BUTTON') {
          setExpandido(!isExpandido);
        }
      }}
      className="border-t"
    >
      <AccordionSummary
        expandIcon={
          isMDDown ? <ExpandMore className="text-uv-primary-0" /> : null
        }
        className="p-4 w-full"
      >
        {isLoadingRecursos ? (
          <div
            className={`grid grid-cols-12 gap-2 lg:gap-4 w-full items-center animate-fade-in`}
          >
            <div className="col-span-12 lg:col-span-5 flex gap-5">
              <Skeleton variant="rounded" className="h-10 w-12 rounded-full" />
              <Skeleton variant="rounded" className="w-full h-10 rounded" />
            </div>
            <div className="col-span-12 lg:col-span-1"></div>
            <div className="col-span-12 lg:col-span-6 flex gap-4 lg:justify-end flex-col sm:flex-row sm:items-center items-start">
              <Skeleton variant="rounded" className="w-full h-10 rounded" />
              <Skeleton variant="rounded" className="w-full h-10 rounded" />
              <Skeleton variant="rounded" className="w-full h-10 rounded" />
            </div>
          </div>
        ) : (
          <div
            className={`grid grid-cols-12 gap-2 lg:gap-4 w-full ${
              isExpandido ? 'items-start' : 'items-center'
            }`}
          >
            <div className="col-span-12 lg:col-span-5">
              <Heading type="h4" className="mb-0">
                {isLoadingRecursos ? (
                  <Skeleton variant="rounded" className="w-full h-10 rounded" />
                ) : (
                  <>
                    <AccountBalance className="mr-2 text-uv-primary-0" />
                    {negocio.label}
                  </>
                )}
              </Heading>
              {isExpandido && (
                <Text>
                  <Place className="mr-2 text-uv-secondary-0" />
                  {address ? address : 'Sin Información'}
                </Text>
              )}
            </div>
            <div className="col-span-12 lg:col-span-7 flex gap-4 lg:justify-end flex-col sm:flex-row sm:items-center items-start">
              {isLoadingRecursos ? (
                <Skeleton variant="rounded" className="w-full h-10 rounded" />
              ) : (
                <Text className="text-black flex items-center">
                  <div className="bg-uv-primary-90 text-uv-primary-0 rounded-full flex items-center justify-center text-base mr-2 px-2 py-1">
                    {pageTable.totalElements || 0}
                  </div>
                  {pageTable.totalElements === 1 ? (
                    <span>Residuo declarado</span>
                  ) : (
                    <span>Residuos declarados</span>
                  )}
                </Text>
              )}

              {/* <Text className="text-black flex items-center">
                <div className="bg-uv-primary-90 text-uv-primary-0 rounded-full flex justify-center text-base mr-2 px-2 py-1">
                  {negocio.cantidad}
                </div>
                {negocio.unidad}
              </Text> */}

              {((isExpandido && isMDDown) || !isMDDown) && (
                <ButtonComponent
                  type="primary"
                  onClick={handleClickIngresar}
                  className="flex-none"
                >
                  <Add className="mr-2" /> Residuo declarado
                </ButtonComponent>
              )}
            </div>
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails className="p-0 bg-white m-0 border-t">
        <CuerpoNegocio
          isCargando={isLoadingRecursos}
          residuos={residuosTable}
          pageTable={pageTable}
          setNumPagina={setNumPagina}
          recargarResiduos={getResiduos}
        />
      </AccordionDetails>
    </Accordion>
  );
};
