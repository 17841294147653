import { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { ChevronLeft, NavigateBefore } from '@mui/icons-material';
import { CamposResiduosFormik } from './CamposResiduosFormik';
import { DICTONARY } from 'const/Dictonary';
import { RUTAS } from 'const/Rutas';
import { useSubmitResiduos } from 'views/Residuos/hooks/Submit/useSubmitResiduos';
import Loading from 'components/Loading/Loading';
import { SchemaReceptor } from 'views/Residuos/schema/SchemaResiduoReceptor';
import { SaltoLinia } from 'views/Residuos/utils/utils';
import { SchemaResiduoGenerador } from 'views/Residuos/schema/SchemaResiduoGenerador';
import { AuthContext } from 'context';

const ResiduosFormik = () => {
  const { isTienePermisos } = useContext(AuthContext);
  let params = useParams();
  let { nombreRol, tipoForm, codigoResiduo } = params;

  const navigate = useHistory();
  const [tipoFormulario] = useState(tipoForm || '');
  const tiposPosibles = ['ingresar', 'editar', 'duplicar'];
  const tipoFormularioSeleccionado = tiposPosibles.reduce((obj, tipo) => {
    obj[tipo] = tipoFormulario === tipo;
    return obj;
  }, {});

  const isNombreRolOferente =
    nombreRol === DICTONARY.ROL.GENERADOR.NOMBRE ? true : false;
  const isNombreRolDemandante =
    nombreRol === DICTONARY.ROL.RECEPTOR.NOMBRE ? true : false;

  const {
    residuoDemandante,
    residuoOferente,
    isLoading,
    setIsLoading,
    handleSubmit: topHandleSubmit,
    cargaResiduo,
    estadoGuardado
  } = useSubmitResiduos(codigoResiduo, nombreRol);

  useEffect(() => {
    if (tipoFormularioSeleccionado.ingresar) setIsLoading(false);
    if (!codigoResiduo) return;
    cargaResiduo();
  }, [codigoResiduo]);

  if (isLoading) return <Loading />;

  return (
    <SidebarLayout>
      <Heading type="h2" className="mb-4 text-lg md:text-2xl">
        <div className="flex items-center">
          <IconButtonComponent
            onClick={() => navigate.push(RUTAS.RESIDUOS)}
            type="secondary"
            className="mr-2"
          >
            <ChevronLeft />
          </IconButtonComponent>
          <span>
            {` 
           ${
             tipoFormularioSeleccionado.ingresar
               ? 'Ingresar '
               : tipoFormularioSeleccionado.editar
               ? 'Editar '
               : tipoFormularioSeleccionado.duplicar
               ? 'Duplicar '
               : ''
           }
            residuo
            ${isNombreRolOferente ? 'ofertado' : 'requerido'}
             `}
          </span>
        </div>
      </Heading>

      <Formik
        initialValues={
          isNombreRolOferente ? residuoOferente : residuoDemandante
        }
        validationSchema={
          isNombreRolOferente
            ? SchemaResiduoGenerador(isTienePermisos)
            : SchemaReceptor(isTienePermisos)
        }
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={true}
        onSubmit={(values, { setSubmitting }) => {
          topHandleSubmit(values, tipoFormularioSeleccionado)
            .then(result => {
              window.scrollTo(0, 0);
              navigate.replace(RUTAS.RESIDUOS);
            })
            .catch(err => {
              console.error(err);
              setSubmitting(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleReset,
          handleSubmit,
          isSubmitting,
          setFieldError,
          setFieldTouched,
          setFieldValue,
          touched,
          validateForm,
          values
        }) => (
          <Form
            id={`form-${tipoForm}-residuo-${nombreRol}`}
            className="bg-white rounded-lg shadow p-5 mb-8"
          >
            <CamposResiduosFormik
              codigoResiduo={codigoResiduo}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleReset={handleReset}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              setFieldError={setFieldError}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              touched={touched}
              validateForm={validateForm}
              values={values}
              // Props adicionales
              estadoGuardado={estadoGuardado}
              isLoading={isLoading}
              isNombreRolOferente={isNombreRolOferente}
              isNombreRolDemandante={isNombreRolDemandante}
              tipoFormulario={tipoFormulario}
              tipoFormularioSeleccionado={tipoFormularioSeleccionado}
            />
            <SaltoLinia />
            <div className="col-span-12 justify-between mt-2">
              {isNombreRolOferente ? (
                <div className="flex justify-between">
                  <ButtonComponent
                    type="secondary"
                    className="mt-3 mb-3"
                    onClick={() => {
                      navigate.push(RUTAS.RESIDUOS);
                    }}
                  >
                    <NavigateBefore /> Volver
                  </ButtonComponent>
                  <ButtonComponent
                    buttonType={'submit'}
                    type="primary"
                    className="mt-3 mb-3"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    <span className="pr-2">
                      {tipoFormularioSeleccionado.ingresar
                        ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.INGRESAR +
                          ' residuo'
                        : tipoFormularioSeleccionado.editar
                        ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR
                        : tipoFormularioSeleccionado.duplicar
                        ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.DUPLICAR +
                          ' residuo'
                        : ''}{' '}
                    </span>
                  </ButtonComponent>
                </div>
              ) : (
                <div className={`flex justify-between`}>
                  <ButtonComponent
                    type="secondary"
                    className="mt-3 mb-3"
                    onClick={() => {
                      navigate.push(RUTAS.RESIDUOS);
                    }}
                  >
                    <NavigateBefore /> Volver
                  </ButtonComponent>
                  <ButtonComponent
                    buttonType={'submit'}
                    type="primary"
                    className="mt-3 mb-3"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    <span className="pr-2">
                      {tipoFormularioSeleccionado.ingresar
                        ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.INGRESAR +
                          ' residuo'
                        : tipoFormularioSeleccionado.editar
                        ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.EDITAR
                        : tipoFormularioSeleccionado.duplicar
                        ? DICTONARY.MENSAJES_BOTONES_ACCIONES_CRUD.DUPLICAR +
                          ' residuo'
                        : ''}{' '}
                    </span>
                  </ButtonComponent>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </SidebarLayout>
  );
};

export default ResiduosFormik;
