import ImageNotSupported from '@mui/icons-material/ImageNotSupported';
import { CircularProgress, Skeleton } from '@mui/material';
import { FilethumbComponent } from 'components/design-system/Filethumb/Filethumb';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import { useEffect, useState } from 'react';
import archivos from 'services/archivos';

export const Documentos = ({ empresa }) => {
  const [documentos, setDocumentos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadArchivos = async () => {
    try {
      setIsLoading(true);
      const response = await archivos.getArchivosEmpresas(
        {
          codigoEmpresa: empresa.codigoEmpresa
        },
        DICTONARY.INTERCEPTOR.DESACTIVADO
      );

      if (response.status === 200) {
        if (response.data.error) throw new Error('SIN_ARCHIVOS');
        setDocumentos(response.data?.archivosEmpresa);
      }
    } catch (error) {
      setDocumentos([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadArchivos();
  }, []);

  const handleDownload = file => {
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:${file.tipoArchivo};base64,${file.base64}`;
    downloadLink.download = file.nombreArchivo;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="flex gap-2 my-4">
      {isLoading && (
        <Skeleton
          variant="rectangular"
          className="w-full min-h-[14rem] rounded relative z-0 min-w-full flex justify-center items-center"
        >
          <CircularProgress
            thickness={4}
            size={60}
            className="text-neutral-50 mb-4 relative z-10 visible"
          />
        </Skeleton>
      )}

      {!isLoading && documentos.length === 0 && (
        <div className="bg-neutral-100 min-h-[14rem] w-full rounded flex text-center items-center mx-auto flex-col justify-center">
          <ImageNotSupported className="text-6xl text-neutral-80 mb-2" />
          <Text className="mx-auto">Sin documentos</Text>
        </div>
      )}

      {!isLoading && documentos && documentos.length > 0 && (
        <aside>
          <span className="block font-secondary text-xs text-neutral-60 tracking-wider font-normal pt-0.5 mb-2">
            DOCUMENTOS
          </span>
          <div className="">
            <div className="grid grid-cols-2 gap-5">
              {documentos.map((file, key) => (
                <FilethumbComponent
                  key={key}
                  file={file}
                  onDownload={file => handleDownload(file)}
                />
              ))}
            </div>
          </div>
        </aside>
      )}
    </div>
  );
};
