import * as yup from 'yup';

const SchemaTransporte = yup.object().shape({
  tipoTransporte: yup
    .object()
    .required('Este campo es obligatorio')
    .typeError('Este campo es obligatorio'),

  nombreEmpresaTransporte: yup
    .string()
    .trim()
    .max(128, 'La cantidad máxima de caracteres es 128')
    .min(2, 'La cantidad mínima de caracteres es 2')
    .nullable()
});

export { SchemaTransporte };
