import { useState } from 'react';
import { Formik, Form } from 'formik';
import { CamposCalculoIndicadores } from './CamposCalculoIndicadores';
import serviceCommons from 'services/commons';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { initialValuesCalculoIndicadores } from '../Formulario/data/initalValuesCalculoIndicadores';
import { schemaCalculoIndicadores } from '../Formulario/schema/schemaCalculoIndicadores';
import ResultadoIndicadores from './ResultadoIndicadores';
import { CircularProgress } from '@mui/material';

const ESTADOS = {
  FORMULARIO: 'FORMULARIO',
  INDICADORES: 'INDICADORES'
};

const CalculoIndicadoresFormik = ({ disponibilidad, setDisponibilidad }) => {
  const [layout, setLayout] = useState(ESTADOS.FORMULARIO);
  const [indicadores, setIndicadores] = useState({});

  const handleVolverACalcular = () => {
    if (disponibilidad.cuotaActual === disponibilidad.cuotaMaxima) {
      setDisponibilidad({
        ...disponibilidad,
        habilitado: false
      });
    }

    setLayout(ESTADOS.FORMULARIO);
  };

  return (
    <SidebarLayout>
      <Formik
        initialValues={initialValuesCalculoIndicadores}
        validationSchema={schemaCalculoIndicadores}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={true}
        onSubmit={async (values, { setSubmitting }) => {
          const request = {
            cantidad: values.cantidad,
            sucursalTransportista: {
              trabajadoresMasculino: values.nTrabajadoresHombresTransporte,
              trabajadoresFemenino: values.nTrabajadoresMujeresTransporte,
              residuosTransportados: values.cantidadToneladasTransportadas,
              remuneracionesPagadas: values.totalRemuneracionesPagadas
            },
            sucursalTratamiento: {
              trabajadoresMasculino: values.nTrabajadoresHombresTratamiento,
              trabajadoresFemenino: values.nTrabajadoresMujeresTratamiento,
              residuosGestionados: values.cantidadToneladasTratamiento,
              remuneracionesPagadas:
                values.totalRemuneracionesPagadasTratamiento
            },
            direccionDemandante: {
              latitudDireccion: values.latitudDireccionDemandante,
              longitudDireccion: values.longitudDireccionDemandante
            },
            direccionOferente: {
              latitudDireccion: values.latitudDireccionOferente,
              longitudDireccion: values.longitudDireccionOferente,
              comuna: {
                region: {
                  codigoRegion: values.regionOferente.codigoRegion
                }
              }
            },
            tipoTransporte: {
              codigoTipoTransporte: values.tipoTransporte.codigoTipoTransporte
            },
            tipoTratamiento: {
              codigoTipoTratamiento:
                values.tipoTratamiento.codigoTipoTratamiento
            },
            tipoResiduo: {
              codigoTipoResiduo: values.subTipoResiduo.codigoTipoResiduo
            }
          };

          try {
            window.scrollTo(0, 0);
            const response = await serviceCommons.calculoIndicadores(request);
            if (response.data.error) {
              throw new Error(response.data.error);
            }
            if (response.data) {
              setLayout(ESTADOS.INDICADORES);
              setIndicadores(response.data);
              setDisponibilidad({
                ...disponibilidad,
                cuotaActual: disponibilidad.cuotaActual + 1
              });
            }
          } catch (error) {
            setDisponibilidad({
              ...disponibilidad,
              habilitado: false
            });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleReset,
          handleSubmit,
          isSubmitting,
          setFieldError,
          setFieldTouched,
          setFieldValue,
          touched,
          validateForm,
          values
        }) => (
          <Form id="formResiduoDeclarado">
            {isSubmitting && (
              <div className="bg-white rounded p-5 shadow-sm mb-5 min-h-screen flex items-center justify-center">
                <CircularProgress
                  color="inherit"
                  className="text-uv-primary-0"
                />
              </div>
            )}

            {layout === ESTADOS.FORMULARIO && !isSubmitting && (
              <CamposCalculoIndicadores
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleReset={handleReset}
                handleSubmit={handleSubmit}
                setFieldError={setFieldError}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched}
                validateForm={validateForm}
                values={values}
                disponibilidad={disponibilidad}
              />
            )}

            {layout === ESTADOS.INDICADORES && !isSubmitting && (
              <ResultadoIndicadores
                onTryAgain={handleVolverACalcular}
                data={indicadores}
              />
            )}
          </Form>
        )}
      </Formik>
    </SidebarLayout>
  );
};

export default CalculoIndicadoresFormik;
