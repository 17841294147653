import { useEffect, useState, useContext } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import AlertComponent from 'components/design-system/Alert/Alert';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Table from 'components/design-system/Table/Table';
import Text from 'components/design-system/Text/Text';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
  Tooltip
} from '@mui/material';
import { Add, Close, Delete, Edit } from '@mui/icons-material';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { ColumnsTableResiduos } from '../../components';
import { DICTONARY } from 'const/Dictonary';
import {
  handleConfirmarEliminacionResiduoAT,
  handleIniciarEliminacion,
  obtenerResiduosAT
} from '../../hooks/useServices';

import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';
import { RUTAS } from 'const/Rutas';

const ListaResiduosAT = () => {
  const { isTienePermisos } = useContext(AuthContext);
  const navigate = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  // Listado
  const [residuosAt, setResiduosAt] = useState([]);
  //obtener region del usuario
  const { logeduser } = useContext(AuthContext);
  const codigoRegion = logeduser?.region?.codigoRegion;

  // Paginación
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] = useState(DICTONARY.PAGINACION.DEFAULT);
  const [rowCount, setRowCount] = useState(1);

  // Columnas Tabla
  const columnTables = ColumnsTableResiduos();

  //ELIMINACION DE Empresa AT
  const [openConfirmacion, setOpenConfirmacion] = useState(false);
  const [registroEliminar, setRegistroEliminar] = useState({});
  const [isEliminando, setIsEliminando] = useState(false);

  useEffect(() => {
    obtenerResiduosAT({
      codigoRegion,
      setIsLoading,
      pagination,
      setResiduosAt,
      setRowCount
    });
  }, [pagination.pageIndex, pagination.pageSize, columnFilters, pagination]);

  return (
    <SidebarLayout>
      <div className="mb-10">
        <div className="mb-5">
          <Heading type="h2">
            Administración de Residuos Análisis territorial
          </Heading>
          <Text>
            Crea, edita y elimina los registros de residuos de análisis
            territorial que se encuentran registradas en la plataforma
          </Text>
          {isTienePermisos(PERMISOS.crearResiduoAT) && (
            <div className="text-end">
              <ButtonComponent
                type="primary"
                className=" xxs:w-auto"
                onClick={() => {
                  navigate.push(RUTAS.INGRESAR_RESIDUO_AT);
                }}
              >
                <Add />
                Nuevo Residuo AT
              </ButtonComponent>
            </div>
          )}
        </div>

        <Table
          headTable={
            <Heading type="h4" className="p-2">
              Residuos Análisis territorial registradas
            </Heading>
          }
          data={residuosAt}
          columns={columnTables}
          isLoading={isLoading}
          editing={false}
          rowCount={rowCount}
          manualFiltering
          enableGlobalFilter={false}
          enableRowActions={
            isTienePermisos(PERMISOS.editarResiduoAT) ||
            isTienePermisos(PERMISOS.eliminarResiduoAT)
          }
          onPaginationChange={setPagination}
          state={{
            columnFilters,
            pagination,
            isLoading,
            showProgressBars: false,
            globalFilter
          }}
          manualPagination
          enableEditing={false}
          actions={({ row, table }) => (
            <div className="">
              {isTienePermisos(PERMISOS.editarResiduoAT) && (
                <Tooltip arrow placement="left" title="Editar">
                  <Link
                    to={`/residuo-at/editar/${row.original.codigoResiduoAT}`}
                  >
                    <IconButtonComponent
                      className="mr-2"
                      disabled={isLoading}
                      size="small"
                      type={isLoading ? 'disabled' : 'secondary'}
                    >
                      <Edit />
                    </IconButtonComponent>
                  </Link>
                </Tooltip>
              )}
              {isTienePermisos(PERMISOS.eliminarResiduoAT) && (
                <Tooltip arrow placement="right" title="Eliminar">
                  <IconButtonComponent
                    disabled={isLoading}
                    size="small"
                    type={isLoading ? 'disabled' : 'error'}
                    onClick={() =>
                      handleIniciarEliminacion({
                        row,
                        table,
                        setRegistroEliminar,
                        setOpenConfirmacion
                      })
                    }
                  >
                    <Delete />
                  </IconButtonComponent>
                </Tooltip>
              )}
            </div>
          )}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
        />
      </div>

      <Dialog
        open={openConfirmacion}
        onClose={() => setOpenConfirmacion(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="border-b border-neutral-90"
        >
          <Heading type="h3" className="mb-0">
            Confirmación de eliminación
          </Heading>
        </DialogTitle>
        <DialogContent className="p-5">
          <DialogContentText id="alert-dialog-description" className="pt-4">
            <Text>
              ¿Estás seguro que deseas eliminar completamente el siguiente
              residuo?
            </Text>
            <div className="my-4">
              <div className="flex items-center">
                {/* <Email className="mr-2 text-uv-secondary-10" /> */}
                <Heading type="h5" className="pr-10 mb-0 truncate">
                  {' '}
                  {registroEliminar.nombreLER?.nombreLER}
                </Heading>
              </div>
            </div>
            <AlertComponent type="error">
              Esta operación es irreversible y{' '}
              <b>el residuo quedará completamente eliminado de la plataforma</b>
            </AlertComponent>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="border-t border-neutral-90 p-4 justify-between">
          <ButtonComponent
            type="secondary"
            onClick={() => setOpenConfirmacion(false)}
            className="justify-center"
          >
            <Hidden smDown>Cancelar</Hidden>
            <Hidden smUp>
              <Close />
            </Hidden>
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            disabled={isEliminando}
            onClick={() =>
              handleConfirmarEliminacionResiduoAT({
                registroEliminar,
                residuosAt,
                setResiduosAt,
                setOpenConfirmacion,
                setRegistroEliminar,
                setIsEliminando
              })
            }
            autoFocus
            className="w-full xs:w-auto justify-center"
          >
            Confirmar
          </ButtonComponent>
        </DialogActions>
      </Dialog>
    </SidebarLayout>
  );
};

export default withRouter(ListaResiduosAT);
