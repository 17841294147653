import API from './api';

const chatServices = {
  crearSalaChat: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/sala-chat',
      data: data,
      interceptor: conInterceptor
    });
  },

  listaMensajes: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/sala-chat/${params.codigoSalaChat}/mensajes`,
      params: params,
      interceptor: conInterceptor
    });
  },

  listaUsuariosSalaChat: function (roomid, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/sala-chat/${roomid}/usuarios`,
      interceptor: conInterceptor
    });
  },

  agregaUsuarioSalaChat: function (data, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: `/sala-chat/${data.codigoSalaChat}/agregar-usuario`,
      data: data,
      interceptor: conInterceptor
    });
  },

  eliminarUsuarioSalaChat: function (data, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: `/sala-chat/${data.codigoSalaChat}/eliminar-usuario`,
      data: data,
      interceptor: conInterceptor
    });
  },

  salaChat: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/sala-chat',
      params: params,
      interceptor: conInterceptor
    });
  },

  subirArchivo: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: `/sala-chat/${data.codigoSalaChat}/archivo`,
      data: data,
      interceptor: conInterceptor
    });
  },

  obtenerArchivo: function (codigoArchivoSalaChat, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/sala-chat/archivo/${codigoArchivoSalaChat}`,
      interceptor: conInterceptor
    });
  }
};

export default chatServices;
