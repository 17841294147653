export const RUTAS = {
  HOME: '/',
  REGISTRARSE: '/signup',
  REGISTRARSE_VERIFICAR_EMAIL: '/signup/verificacion',

  VERIFICAR_CUENTA: '/verifica-cuenta',
  VERIFICAR_USUARIO: '/verifica-usuario',
  VERIFICAR_USUARIO_SUCURSAL: '/verifica-usuario-sucursal',
  CAMBIAR_CONTRASENA: '/cambiar-contrasena',
  SUBIR_CERTIFICADO: '/subir-certificado',

  HOME_USER: '/inicio',
  REGISTRAR_NEGOCIO: '/negocio/nuevo',
  EDITAR_NEGOCIO: '/negocio/editar/:codigoEmpresa',

  TRANSACCIONES: '/transacciones',
  TRANSACCIONES_RESUMEN: '/transacciones/resumen',

  NEGOCIO: '/negocio',

  MARKETPLACE_RESIDUOS: '/marketplace/residuos',

  INDICADORES: '/negocio/:codigoEmpresa/indicadores',
  INDICADORES_EDITAR:
    '/negocio/:codigoEmpresa/datos-indicadores/:codigoSucursal',
  INDICADORES_DATOS: '/negocio/:codigoEmpresa/datos-indicadores',
  FORMULARIO_CALCULO_INDICADORES: '/negocio/indicadores/calculadora',

  SUCURSALES: '/negocio/:codigoEmpresa/sucursales',
  SUCURSALES_EDITAR: '/negocio/:codigoEmpresa/sucursales/:codigoSucursal',

  EDITAR_CUENTA: '/perfil/editar',
  USUARIOS: '/negocio/:codigoEmpresa/usuarios',
  INVITAR_USUARIOS: '/negocio/usuarios/invitar',
  USUARIOS_EDITAR: '/negocio/usuarios/:codigoUsuario',

  RESIDUOS: '/negocio/residuos',
  RESIDUOS_INGRESAR: '/negocio/residuos/:nombreRol/:tipoForm',
  RESIDUOS_EDITAR: '/negocio/residuos/:nombreRol/:tipoForm/:codigoResiduo',
  RESIDUOS_DUPLICAR: '/negocio/residuos/:nombreRol/:tipoForm/:codigoResiduo',

  LOGS_ACTIVIDAD: '/negocio/actividad',

  //Rutas especificas de superAdministrador
  ADMINISTRAR_NEGOCIOS: '/negocios',
  ADMINISTRAR_SUCURSALES: '/negocio/admin/sucursales',
  ADMINISTRAR_USUARIOS: '/negocio/admin/usuarios',
  ADMINISTRAR_USUARIOS_NUEVO: '/negocio/usuarios/nuevo',
  ADMINISTRAR_DATOS_INDICADORES: '/negocio/admin/datos-indicadores',
  ADMINISTRAR_INDICADORES: '/negocio/admin/indicadores',
  ADMINISTRAR_LOGS_ACTIVIDAD: '/registro-actividad',

  ADMINISTRAR_PERFILES: '/admin/perfiles',
  ADMINISTRAR_PERFILES_EDITAR: '/admin/perfiles/editar/:codigoPerfil',
  ADMINISTRAR_PERFILES_INGRESAR: '/admin/perfiles/ingresar',

  EXPORTAR_REPORTES: '/impacta/reportes',

  CHAT: '/chat',

  MATERIAL_DIFUSION: '/difusion',
  MATERIAL_DIFUSION_EDITAR: '/admin/editar-difusion/:codigoMaterialInformacion',
  MATERIAL_DIFUSION_CARGAR: '/admin/cargar-difusion',

  CHAT_ROOM: '/chat/:roomid',

  //Rutas de error
  NOT_ALLOWED: '/sin-autorizacion',
  NOT_FOUND: '/not-found',

  ANALISIS_TERRITORIAL_BASE: '/analisis-territorial/base',
  ANALISIS_TERRITORIAL_SINERGIAS: '/analisis-territorial/sinergias',
  ANALISIS_TERRITORIAL_TRANSACCIONES: '/transacciones-v2',
  // Rutas de gestión territorial
  GESTION_TERRITORIAL: '/gestion-territorial',
  LISTA_EMPRESAS_AT: '/empresas-at',
  LISTA_RESIDUOS_AT: '/residuos-at',
  INGRESAR_EMPRESA_AT: '/empresas-at/ingresar',
  INGRESAR_RESIDUO_AT: '/residuos-at/ingresar',
  EDITAR_EMPRESA_AT: '/empresa-at/editar/:codigoEmpresa',
  EDITAR_RESIDUO_AT: '/residuo-at/editar/:codigoResiduo',
  // Rutas de residuo recurso adquirido
  GESTIONA_RECURSO_ADQUIRIDO: '/gestiona/recurso-adquirido',
  INGRESAR_RECURSO_ADQUIRIDO: '/gestiona/recurso-adquirido/ingresar',
  EDITAR_RECURSO_ADQUIRIDO:
    '/gestiona/recurso-adquirido/editar/:codigoRecursoAdquirido',
  DUPLICAR_RECURSO_ADQUIRIDO:
    '/gestiona/recurso-adquirido/duplicar/:codigoRecursoAdquirido',
  // Rutas de residuo declarado
  GESTIONA_RESIDUO_DECLARADO: '/gestiona/residuo-declarado',
  INGRESAR_RESIDUO_DECLARADO: '/gestiona/residuo-declarado/ingresar',
  INGRESAR_RESIDUO_DECLARADO_: '/gestiona/residuo-declarado/ingresar',
  EDITAR_RESIDUO_DECLARADO:
    '/gestiona/residuo-declarado/:tipoForm/:codigoResiduoDeclarado',
  DUPLICAR_RESIDUO_DECLARADO:
    '/gestiona/residuo-declarado/:tipoForm/:codigoResiduoDeclarado',

  REQUERIR_RESIDUO: '/negocio/residuos/receptor/ingresar',
  OFRECER_RESIDUO: '/negocio/residuos/generador/ingresar'
};
