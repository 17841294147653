import { Menu } from '@mui/icons-material';
import { Drawer, IconButton } from '@mui/material';
import { AuthContext } from 'context';
import { useContext, useState } from 'react';

export const ResponsiveButtonMenu = ({ children }) => {
  const { logged } = useContext(AuthContext);

  const [state, setState] = useState({ left: false });
  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  if (!logged) {
    return null;
  }

  return (
    <div className="lg:hidden">
      <IconButton color="inherit" onClick={toggleDrawer('left', true)}>
        <Menu />
      </IconButton>
      <Drawer
        anchor="left"
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        {children}
      </Drawer>
    </div>
  );
};
