import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Edit as EditIcon, Place as PlaceIcon } from '@mui/icons-material';
import { PERMISOS } from 'const/Permisos';
import Divider from '@mui/material/Divider';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { MultipleRole } from 'components/design-system/Role/MultipleRole';
import IconComponent from 'components/design-system/icon/Icon';
import Skeleton from '@mui/material/Skeleton';
import { AuthContext } from 'context';
import {
  obtenerAutorizacionesDeObjeto,
  obtenerGradoAutorizacionesSanitarias
} from 'helpers/autorizaciones';
import { RUTAS } from 'const/Rutas';

export const CuadroUsuarioResumen = ({
  resumen,
  resumenCuenta,
  isLoading,
  sucursal,
  roles,
  rolEmpresa,
  codigoEmpresa,
  numColumn
}) => {
  const history = useHistory();
  const { isTienePermisos } = useContext(AuthContext);

  return (
    <div className="mb-6">
      <div className="bg-white rounded shadow-md">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 xl:col-span-4 items-center pt-8 pb-4 px-5 h-auto">
            <div className="flex">
              <div className="mr-2">
                <MultipleRole
                  roles={roles}
                  gradoAutorizacionesSanitarias={obtenerGradoAutorizacionesSanitarias(
                    obtenerAutorizacionesDeObjeto(sucursal.empresa)
                  )}
                />
              </div>
              <div className="min-h-[55%]">
                <span className="font-secondary text-xs text-neutral-40 tracking-wider font-medium mb-0 block uppercase">
                  {rolEmpresa}
                </span>
                <Heading
                  type="H3"
                  className="flex justify-between content-center items-center mb-8"
                >
                  <span>{sucursal.empresa?.nombreEmpresa}</span>
                  {/*
                   * Solo se muestra el botón de editar si el usuario no es un funcionario
                   */}
                  {isTienePermisos(PERMISOS.editarNegocio) && (
                    <IconButtonComponent
                      type="secondary"
                      className="ml-3"
                      onClick={() =>
                        history.push(`${RUTAS.NEGOCIO}/editar/${codigoEmpresa}`)
                      }
                    >
                      <EditIcon />
                    </IconButtonComponent>
                  )}
                </Heading>
              </div>
            </div>

            <div className="relative">
              <span className="pl-7 mb-1 block font-secondary text-xs text-neutral-60 tracking-wider font-medium">
                {sucursal?.nombreSucursal || ''}
              </span>
              <div className="flex items-center">
                <PlaceIcon className="mr-1 text-uv-secondary-10" />
                <Text>
                  {sucursal.direccion?.nombreVia}
                  {', '}
                  {sucursal.direccion?.comuna?.nombreComuna}
                  {', Región '}
                  {sucursal.direccion?.comuna?.region?.nombreRegion}
                </Text>
              </div>
            </div>
          </div>
          <div className="col-span-12 xl:col-span-8 p-4">
            <div className="bg-uv-primary-90 rounded py-2 px-4">
              <div className="p-2 grid grid-cols-12 gap-4 items-center">
                {resumen.map((item, index) => (
                  <React.Fragment key={index}>
                    <div
                      className={`col-span-12 sm:col-span-6 ${
                        item.enabled ? '' : 'hidden'
                      }`}
                    >
                      <span className="mb-2 block font-secondary text-xs text-gray-700 opacity-50 tracking-widest font-normal">
                        {item.titulo}
                      </span>
                      <div className="flex items-center">
                        <div className="w-12 h-12 rounded-full bg-white align-middle justify-center content-center items-center m-0 text-center min-w-[48px]">
                          <IconComponent
                            color="primary"
                            className="text-[#2E8567]"
                          >
                            {item.icono}
                          </IconComponent>
                        </div>
                        {isLoading ? (
                          <Skeleton
                            variant="text"
                            className="w-full h-12 relative z-0 flex mx-2"
                          />
                        ) : (
                          <>
                            <Heading
                              type="H2"
                              className="text-black ml-3 mr-4 my-0"
                            >
                              {resumenCuenta[item.accesor]
                                ? resumenCuenta[item.accesor]
                                : '0'}
                            </Heading>
                            <Text className="text-base text-black leading-5 font-normal">
                              {item.descripcion}
                            </Text>
                          </>
                        )}
                      </div>
                    </div>
                    {(index + 1) % numColumn === 0 &&
                      index + 1 < resumen.length && (
                        <>
                          {(index + 1) % 2 === 0 &&
                            index + 1 < resumen.length && (
                              <div className="col-span-12">
                                <Divider className="my-0" />
                              </div>
                            )}
                        </>
                      )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
