import { useCallback, useEffect, useState } from 'react';
import serviceDocumentos from 'services/documentos';
import serviceCommons from 'services/commons';
import serviceResiduos from 'services/residuos';
import { DICTONARY } from 'const/Dictonary';
import { AlertaError } from 'utils/Alertas';

export const useCargarMaterial = (documento, archivo, isEdicion) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingComunas, setIsLoadingComunas] = useState(false);
  const [listaMarcoLegal, setListaMarcoLegal] = useState([]);
  const [listaNormativaTecnica, setListaNormativaTecnica] = useState([]);
  const [listaPublicaciones, setListaPublicaciones] = useState([]);
  const [listaTipoResiduo, setlistaTipoResiduo] = useState([]);
  const [listaComunas, setListaComunas] = useState([]);
  const [listaRegiones, setListaRegiones] = useState([]);
  const [listaTipoTratamiento, setListaTipoTratamiento] = useState([]);
  const [valoresIniciales, setValoresIniciales] = useState({
    marcoLegal: [],
    normativaTecnica: [],
    publicaciones: [],
    tipoResiduo: {},
    tipoTratamientoResiduo: {},
    comuna: [], // importante que sea un array para que funcione el componente multiple
    region: [], // importante que sea un array para que funcione el componente multiple
    nombreArchivo: '',
    url: [],
    base64: '',
    obligatorio: false,
    vieneArchivo: false,
    archivos: []
  });

  const onSubmit = useCallback(
    async values => {
      try {
        let queryDocumento = {};
        queryDocumento['obligatorio'] = false;

        if (
          values.vieneArchivo &&
          values.archivos &&
          values.archivos.length > 0
        ) {
          const archivoCargado = values.archivos[0];
          queryDocumento['tipoArchivo'] = archivoCargado.tipoArchivo;
          queryDocumento['base64'] = archivoCargado.base64;
          queryDocumento['vieneArchivo'] = true;
        }

        queryDocumento['nombreArchivo'] = values.nombreArchivo;
        queryDocumento['marcosLegales'] =
          values.marcoLegal?.map(categoria => ({
            codigoMarcoLegal: categoria.codigoMarcoLegal
          })) || [];
        queryDocumento['normativasTecnicas'] =
          values.normativaTecnica?.map(categoria => ({
            codigoNormativaTecnica: categoria.codigoNormativaTecnica
          })) || [];
        queryDocumento['publicaciones'] =
          values.publicaciones?.map(categoria => ({
            codigoPublicacion: categoria.codigoPublicacion
          })) || [];
        queryDocumento['tipoResiduo'] = values.tipoResiduo?.codigoTipoResiduo
          ? { codigoTipoResiduo: values.tipoResiduo.codigoTipoResiduo }
          : null;
        queryDocumento['tipoTratamiento'] = values.tipoTratamientoResiduo
          ?.codigoTipoTratamiento
          ? {
              codigoTipoTratamiento:
                values.tipoTratamientoResiduo.codigoTipoTratamiento
            }
          : null;
        queryDocumento['urls'] =
          values.url?.map(enlace => ({
            url: enlace.url,
            descripcion: enlace.descripcion
          })) || [];
        queryDocumento['comunas'] =
          values.comuna?.map(comuna => ({
            codigoComuna: comuna.codigoComuna
          })) || [];
        // queryDocumento['region'] = values.region?.nombreRegion || '';

        if (values.obligatorio === true) queryDocumento['obligatorio'] = true;

        Object.keys(queryDocumento).forEach(key => {
          // Eliminar de queryDocumentos los campos que no se van a enviar al backend como nombreArchivo, base64, vieneArchivo, archivos en caso de que isEdicion sea true
          if (
            isEdicion &&
            (key === 'nombreArchivo' ||
              key === 'base64' ||
              key === 'vieneArchivo' ||
              key === 'archivos')
          )
            delete queryDocumento[key];

          if (!queryDocumento[key] || queryDocumento[key].length === 0) {
            // no eliminar el campo obligatorio
            if (key !== 'obligatorio') delete queryDocumento[key];
          }
        });

        console.log('PREVIO_REQUEST', queryDocumento);

        if (isEdicion) {
          const codigoMaterialInformacion = documento.codigoMaterialInformacion;
          const response =
            await serviceDocumentos.actualizarMaterialInformacion(
              codigoMaterialInformacion,
              queryDocumento,
              DICTONARY.INTERCEPTOR.DESACTIVADO
            );
          return response;
        } else {
          const response = await serviceDocumentos.guardarMaterialInformacion(
            queryDocumento,
            DICTONARY.INTERCEPTOR.DESACTIVADO
          );
          return response;
        }
      } catch (error) {
        AlertaError(
          'Ha ocurrido un error al guardar el material. Recarga la página para volver a intentarlo de lo contrario comunicate con el administrador si el problema persiste.'
        );
        return [];
      }
    },
    [isEdicion]
  );

  const handleObtenerComunas = useCallback(
    async (codigoRegion, setFieldValue) => {
      if (!codigoRegion) return;
      setIsLoadingComunas(true);

      const params = {
        codigosRegion: codigoRegion,
        totalPagina: DICTONARY.PAGINACION.ALL_DATA
      };

      try {
        const response = await serviceCommons.obtieneComunas(params);

        if (response.length === 0) return;

        const content = response.data.content;

        setListaComunas(content);

        setFieldValue('comuna', content);
      } catch (err) {
        console.error('error: ', err);
      } finally {
        setIsLoadingComunas(false);
      }
    },
    [setIsLoadingComunas, setListaComunas]
  );

  const cargarDatosIniciales = (
    listaMarcoLegal,
    listaNormativaTecnica,
    listaPublicaciones,
    listaTipoResiduo,
    listaTipoTratamiento,
    listaRegiones
  ) => {
    try {
      setListaMarcoLegal(listaMarcoLegal);
      setListaNormativaTecnica(listaNormativaTecnica);
      setListaPublicaciones(listaPublicaciones);
      setlistaTipoResiduo(listaTipoResiduo);
      setListaTipoTratamiento(listaTipoTratamiento);
      setListaRegiones(listaRegiones);

      if (isEdicion) {
        let valoresIniciales = {
          marcoLegal: [],
          normativaTecnica: [],
          publicaciones: [],
          tipoResiduo: {},
          tipoTratamientoResiduo: {},
          comuna: [],
          region: [],
          nombreArchivo: '',
          url: [],
          base64: '',
          obligatorio: false,
          vieneArchivo: false,
          archivos: []
        };
        // Nuevo nombre
        const material = documento;

        // console.log(material);

        if (material.nombreArchivo)
          valoresIniciales['nombreArchivo'] = material.nombreArchivo;

        if (material.marcosLegales && material.marcosLegales.length > 0) {
          valoresIniciales['marcoLegal'] = material.marcosLegales.map(marco => {
            return {
              codigoMarcoLegal: marco.codigoMarcoLegal,
              nombreMarcoLegal: marco.nombreMarcoLegal
            };
          });
        }

        if (
          material.normativasTecnicas &&
          material.normativasTecnicas.length > 0
        ) {
          valoresIniciales['normativaTecnica'] =
            material.normativasTecnicas.map(normativa => {
              return {
                codigoNormativaTecnica: normativa.codigoNormativaTecnica,
                nombreNormativaTecnica: normativa.nombreNormativaTecnica
              };
            });
        }

        if (material.publicaciones && material.publicaciones.length > 0) {
          valoresIniciales['publicaciones'] = material.publicaciones.map(
            publicacion => {
              return {
                codigoPublicacion: publicacion.codigoPublicacion,
                nombrePublicacion: publicacion.nombrePublicacion
              };
            }
          );
        }

        if (material.tipoResiduo) {
          valoresIniciales['tipoResiduo'] = {
            codigoTipoResiduo: material.tipoResiduo.codigoTipoResiduo,
            nombreTipo: material.tipoResiduo.nombreTipo
          };
        }

        if (material.tipoTratamiento) {
          valoresIniciales['tipoTratamientoResiduo'] = {
            codigoTipoTratamiento:
              material.tipoTratamiento.codigoTipoTratamiento,
            nombreTipoTratamiento:
              material.tipoTratamiento.nombreTipoTratamiento
          };
        }

        if (material.comunas && material.comunas.length > 0) {
          const data = material.comunas;

          valoresIniciales['comuna'] = data.map(comuna => {
            return {
              codigoComuna: comuna.codigoComuna,
              nombreComuna: comuna.nombreComuna
            };
          });

          // Use a Set to store unique regions
          const uniqueRegionsSet = new Set();

          // Iterate through the array and add each unique region to the Set
          data.forEach(({ region }) => {
            if (region) {
              uniqueRegionsSet.add(region);
            }
          });

          // Convert the Set back to an array
          const uniqueRegionsArray = Array.from(uniqueRegionsSet);

          valoresIniciales['region'] = uniqueRegionsArray.map(region => {
            return {
              codigoRegion: region.codigoRegion,
              nombreRegion: region.nombreRegion
            };
          });
        }

        valoresIniciales['obligatorio'] = material.obligatorio;
        // valoresIniciales['vieneArchivo'] = documento.vieneArchivo;
        valoresIniciales['url'] = material.urls;

        if (archivo && archivo.base64Archivo && archivo.nombreArchivo) {
          const nombreArchivo = archivo.nombreArchivo;
          const base64Archivo = archivo.base64Archivo;

          const tipoArchivo = nombreArchivo.split('.').pop();

          let base64 = '';
          if (tipoArchivo.includes('pdf')) {
            base64 = `data:application/pdf;base64,${base64Archivo}`;
          } else if (
            tipoArchivo.includes('jpg') ||
            tipoArchivo.includes('jpeg') ||
            tipoArchivo.includes('png')
          ) {
            base64 = `data:image/${tipoArchivo.substring(
              1
            )};base64,${base64Archivo}`;
          } else {
            base64 = `data:application/${tipoArchivo.substring(
              1
            )};base64,${base64Archivo}`;
          }

          valoresIniciales['archivos'] = [
            {
              base64Archivo: base64,
              nombreArchivo: nombreArchivo,
              tipoArchivo: `${tipoArchivo}`
            }
          ];
        }
        // console.log('valoresIniciales ::>', valoresIniciales);
        setValoresIniciales(valoresIniciales);
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error al cargar datos iniciales: ', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);

    const promiseMarcoLegal = new Promise((resolve, reject) => {
      serviceDocumentos
        .obtenerListaMarcosLegales(
          {
            totalPagina: DICTONARY.PAGINACION.ALL_DATA
          },
          DICTONARY.INTERCEPTOR.DESACTIVADO
        )
        .then(result => {
          const content = result.data.content;

          if (Array.isArray(content)) {
            const listaOrdenada = content.sort((a, b) =>
              a.nombreMarcoLegal > b.nombreMarcoLegal ? 1 : -1
            );

            resolve(listaOrdenada);
          } else {
            resolve([]);
          }
        })
        .catch(err => {
          reject(err);
        });
    });

    const promiseNormativaTecnica = new Promise((resolve, reject) => {
      serviceDocumentos
        .obtenerListaNormativasTecnicas(
          {
            totalPagina: DICTONARY.PAGINACION.ALL_DATA
          },
          DICTONARY.INTERCEPTOR.DESACTIVADO
        )
        .then(result => {
          const content = result.data.content;

          if (Array.isArray(content)) {
            const listaOrdenada = content.sort((a, b) =>
              a.nombreNormativaTecnica > b.nombreNormativaTecnica ? 1 : -1
            );

            resolve(listaOrdenada);
          } else {
            resolve([]);
          }
        })
        .catch(err => {
          reject(err);
        });
    });

    const promisePublicaciones = new Promise((resolve, reject) => {
      serviceDocumentos
        .obtenerListaPublicaciones(
          {
            totalPagina: DICTONARY.PAGINACION.ALL_DATA
          },
          DICTONARY.INTERCEPTOR.DESACTIVADO
        )
        .then(result => {
          if (Array.isArray(result.data.content)) {
            const listaOrdenada = result.data.content.sort((a, b) =>
              a.nombrePublicacion > b.nombrePublicacion ? 1 : -1
            );

            resolve(listaOrdenada);
          } else {
            resolve([]);
          }
        })
        .catch(err => {
          reject(err);
        });
    });

    const promiseTipoResiduo = new Promise((resolve, reject) => {
      serviceCommons
        .tipoRSD(
          {
            nivel: DICTONARY.TIPO_RESIDUOS.NIVEL_1,
            totalPagina: DICTONARY.PAGINACION.ALL_DATA
          },
          DICTONARY.INTERCEPTOR.DESACTIVADO
        )
        .then(result => {
          if (Array.isArray(result.data.content)) {
            let listaRSDTemp = result.data.content;
            listaRSDTemp.push(
              {
                codigoTipoResiduo: 999_999,
                nombreTipo: 'Material particulado'
              },
              {
                codigoTipoResiduo: 999_998,
                nombreTipo: 'Sustancias peligrosas'
              }
            );
            listaRSDTemp.sort((a, b) => (a.nombreTipo > b.nombreTipo ? 1 : -1));

            //setlistaTipoResiduo(listaRSDTemp);
            resolve(listaRSDTemp);
          }
        })
        .catch(err => {
          reject([]);
          console.error('Error al tratar de obtener lista tipoRSD >> ', err);
        });
    });

    const promiseTipoTratamiento = new Promise((resolve, reject) => {
      const params = {
        totalPagina: DICTONARY.PAGINACION.ALL_DATA
      };
      serviceResiduos
        .obtenerListaTipoTratamiento(params, DICTONARY.INTERCEPTOR.DESACTIVADO)
        .then(result => {
          let newLista = [];
          if (Array.isArray(result.data.content)) {
            newLista = result.data.content.reduce((filtered, curr) => {
              const exixst = filtered.find(
                t => t.nombreTipoTratamiento === curr.nombreTipoTratamiento
              );
              if (exixst) {
                return filtered;
              } else {
                return [...filtered, curr];
              }
            }, []);
          }

          //setListaTipoTratamiento(newLista);
          resolve(newLista);
        })
        .catch(err => {
          console.error(
            'Error al tratar de obtener lista de tipoTratamiento >> ',
            err
          );
          reject([]);
        });
    });

    const promiseRegiones = new Promise((resolve, reject) => {
      serviceCommons
        .regiones({}, DICTONARY.INTERCEPTOR.DESACTIVADO)
        .then(result => {
          if (Array.isArray(result.data.content)) {
            //setListaRegiones(result.data.content);
            resolve(result.data.content);
          }
        })
        .catch(err => {
          console.error('Error al obtener la lista de regiones: ', err);
          reject([]);
        });
    });

    Promise.all([
      promiseMarcoLegal,
      promiseNormativaTecnica,
      promisePublicaciones,
      promiseTipoResiduo,
      promiseTipoTratamiento,
      promiseRegiones
    ]).then(results => {
      const listaMarcoLegal = results[0];
      const listaNormativaTecnica = results[1];
      const listaPublicaciones = results[2];
      const listaTipoResiduo = results[3];
      const listaTipoTratamiento = results[4];
      const listaRegiones = results[5];

      cargarDatosIniciales(
        listaMarcoLegal,
        listaNormativaTecnica,
        listaPublicaciones,
        listaTipoResiduo,
        listaTipoTratamiento,
        listaRegiones
      );
    });
  }, []);

  return {
    onSubmit,
    handleObtenerComunas,
    isLoading,
    isLoadingComunas,
    listaMarcoLegal,
    listaNormativaTecnica,
    listaPublicaciones,
    listaTipoResiduo,
    listaTipoTratamiento,
    listaComunas,
    listaRegiones,
    valoresIniciales
  };
};
