import { Add } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import ButtonComponent from 'components/design-system/Button/Button'
import React from 'react'

export const RecursoDistinto = ({
  handleAddOtraMateriaPrima,
  values,
  setFieldValue,
}) => {
  return (
    <IconButton
    className="p-0 absolute right-0"
    onClick={() =>
      handleAddOtraMateriaPrima(
        values,
        setFieldValue
      )
    }
  >
    <ButtonComponent
      type="primary"
      className=""
      buttonType="button"
    >
      <Add />
    </ButtonComponent>
  </IconButton>
  )
}
