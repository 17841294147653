import React, { useContext, useEffect, useMemo, useState } from 'react';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import AlertComponent from 'components/design-system/Alert/Alert';
import { Form, Formik } from 'formik';
import ButtonComponent from 'components/design-system/Button/Button';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Send from '@mui/icons-material/Send';
import Table from 'components/design-system/Table/Table';
import empresasService from 'services/empresas';
import { SchemaUsers } from './schema/SchemaUsers';
import { DICTONARY } from 'const/Dictonary';
import usuariosService from 'services/usuarios';
import moment from 'moment';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import { AuthContext } from 'context';

const InvitarUsuarios = () => {
  const { logeduser } = useContext(AuthContext);
  const [cargandoListaEmpresas, setCargandoListaEmpresas] = useState(true);

  const [cargandoListaSucursales, setCargandoListaSucursales] = useState(true);
  const [cargandoListaCorreos, setCargandoListaCorreos] = useState(true);

  const [listaSucursales, setListaSucursales] = useState([]);
  const [listaCorreos, setListaCorreos] = useState([]);

  const [estadoSolicitud, setEstadoSolicitud] = useState(false);
  const [errorSolicitud, setErrorEstadoSolicitud] = useState(false);

  const [ususariosInvitados, setUsusariosInvitados] = useState([]);

  const [sucursalSelected, setSucursalSelected] = useState(null);
  const [correoSelected, setCorreoSelected] = useState(null);
  const [isCorreoNuevo, setIsCorreoNuevo] = useState(false);

  const { codigoEmpresa } = logeduser.sucursal?.empresa || {};

  const [codigoEmpresaUsuario] = useState(codigoEmpresa);

  const {
    LABEL_SUCURSAL,
    LABEL_EMAIL,
    TEXTO_ALERTA_ENVIADO,
    TEXTO_BOTON_ENVIAR,
    TEXTO_ALERTA_NO_ENVIADO,
    LABEL_EMAIL_NUEVOS,
    TEXTO_ES_CORREO_NUEVO
  } = DICTONARY.USUARIOS;

  const columnTables = useMemo(
    () => [
      {
        header: 'Email',
        accessorKey: 'correoElectronico',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>
      },
      {
        header: 'Fecha de solicitud',
        accessorKey: 'fechaHoraSolcitud',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>
      },
      {
        header: 'Estado',
        accessorKey: 'estado',
        Cell: ({ renderedCellValue }) => <Text>{renderedCellValue}</Text>
      }
    ],
    []
  );
  // Obtiene Sucucursales
  useEffect(() => {
    const query = {
      codigoEmpresa: codigoEmpresaUsuario,
      totalPagina: DICTONARY.PAGINACION.ALL_DATA
    };
    empresasService
      .obtieneSucursales(query)
      .then(({ data }) => {
        const { content, empty } = data;
        if (empty) {
          setListaSucursales([]);
          return;
        } else if (Array.isArray(content)) {
          setCargandoListaSucursales(false);
          setListaSucursales(data.content);
        }
      })
      .catch(error => {
        setCargandoListaSucursales(false);
        setListaSucursales([]);
        return error;
      });
  }, [codigoEmpresaUsuario]);

  // Obtiene Correos sin sucursal
  useEffect(() => {
    usuariosService
      .correosSinSucursal()
      .then(({ data }) => {
        setCargandoListaCorreos(false);
        setListaCorreos(data);
      })
      .catch(error => {
        setCargandoListaCorreos(false);
        setListaCorreos([]);
        return error;
      });
  }, []);

  const obtenerInvitados = async () => {
    try {
      const { data } = await usuariosService.invitados(codigoEmpresaUsuario);

      setCargandoListaEmpresas(false);
      const dataFormateada = data.map(invitado => ({
        ...invitado,
        fechaHoraSolcitud: moment(invitado.fechaHoraSolcitud).format(
          'DD/MM/YYYY HH:mm'
        )
      }));

      setUsusariosInvitados(dataFormateada);
    } catch (error) {
      setCargandoListaEmpresas(false);
      setUsusariosInvitados([]);
      return error;
    }
  };

  // Obtiene Invitados
  useEffect(() => {
    obtenerInvitados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigoEmpresaUsuario]);

  return (
    <SidebarLayout>
      <div className="mb-5">
        <Heading type="h2">Invitar a usuarios</Heading>
        <Text>
          Añade a usuarios{' '}
          <strong>{isCorreoNuevo ? 'nuevos' : 'ya existentes'}</strong> en la
          plataforma a participar en tu negocio
        </Text>
      </div>

      <div className="bg-white rounded p-5 shadow-sm mb-5">
        <Formik
          initialValues={{
            nombreSucursal: '',
            codigoSucursal: 0,
            emailUsuario: ''
          }}
          validationSchema={SchemaUsers}
          validateOnMount={true}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={async (values, { resetForm }) => {
            const { emailUsuario, codigoSucursal } = values;
            try {
              await usuariosService
                .invitarUsuario(emailUsuario, codigoSucursal)
                .then(res => {
                  setEstadoSolicitud(true);
                  //  se llama servicio invitados
                  setTimeout(() => {
                    obtenerInvitados();
                  }, 2000);
                })
                .catch(error => {
                  setEstadoSolicitud(false);
                  setErrorEstadoSolicitud(true);
                  console.log(error);
                  return error;
                });
            } catch (error) {
              setEstadoSolicitud(false);
              setErrorEstadoSolicitud(true);
              console.log(error);
            }
          }}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            resetForm
          }) => (
            <Form onChange={event => {}}>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-4">
                  <AutocompleteComponent
                    className="mb-3"
                    id="sucursal"
                    accesor="nombreSucursal"
                    name="codigoSucursal"
                    clearOnEscape={true}
                    openOnFocus={false}
                    onBlur={() => {
                      setFieldTouched('codigoSucursal');
                      setFieldTouched('nombreSucursal');
                    }}
                    options={listaSucursales}
                    value={
                      listaSucursales.find(
                        s => s.codigoSucursal === sucursalSelected
                      ) || ''
                    }
                    getOptionLabel={option => option.nombreSucursal || ''}
                    noOptionsText={
                      cargandoListaSucursales ? 'Cargando...' : 'Sin opciones'
                    }
                    onChange={(ev, selected, reason) => {
                      let data = { name: 'codigoSucursal', value: selected };
                      if (reason === 'clear') {
                        data.value = '';
                      }
                      setSucursalSelected(
                        selected ? data.value.codigoSucursal : ''
                      );
                      setFieldValue(
                        'codigoSucursal',
                        data.value.codigoSucursal
                      );
                      setFieldValue(
                        'nombreSucursal',
                        data.value.nombreSucursal
                      );
                    }}
                  >
                    <InputComponent
                      className="h-9"
                      fullWidth
                      label={<LabelComponent>{LABEL_SUCURSAL}</LabelComponent>}
                    />
                  </AutocompleteComponent>
                  {errors.nombreSucursal && touched.nombreSucursal && (
                    <Text className="text-error">{errors.nombreSucursal}</Text>
                  )}
                </div>

                <div className="col-span-12 md:col-span-4">
                  <AutocompleteComponent
                    className="mb-3"
                    id="emailUsuario"
                    name="emailUsuario"
                    isOptionEqualToValue={(option, value) => option === value}
                    clearOnEscape={true}
                    openOnFocus={false}
                    disabled={cargandoListaCorreos}
                    onBlur={() => {
                      setFieldTouched('emailUsuario');
                    }}
                    options={listaCorreos}
                    value={
                      listaCorreos.find(s => s === correoSelected) ||
                      values.emailUsuario
                    }
                    getOptionLabel={option => option || ''}
                    noOptionsText={
                      cargandoListaCorreos ? 'Cargando...' : 'Sin opciones'
                    }
                    onChange={(ev, selected) => {
                      setCorreoSelected(selected ? selected : '');
                      setIsCorreoNuevo(false);
                      setFieldValue('emailUsuario', selected);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setFieldValue('emailUsuario', newInputValue);
                      !errors.emailUsuario && setIsCorreoNuevo(true);
                      setFieldTouched('codigoSucursal');
                      setFieldTouched('nombreSucursal');
                    }}
                  >
                    <InputComponent
                      className="h-9"
                      fullWidth
                      label={
                        <LabelComponent>
                          {isCorreoNuevo ? LABEL_EMAIL_NUEVOS : LABEL_EMAIL}
                        </LabelComponent>
                      }
                    />
                  </AutocompleteComponent>
                  {errors.emailUsuario && touched.emailUsuario && (
                    <Text className="text-error">{errors.emailUsuario}</Text>
                  )}
                  {!errors.emailUsuario &&
                    touched.emailUsuario &&
                    isCorreoNuevo && (
                      <Text className="text-uv-primary-30 text-xs ">
                        {TEXTO_ES_CORREO_NUEVO}
                      </Text>
                    )}
                </div>
                <div className="col-span-12 md:col-span-4">
                  <ButtonComponent
                    isLoading={isSubmitting}
                    disabled={
                      Object.keys(errors).length === 0 && !isSubmitting
                        ? false
                        : true
                    }
                    buttonType="submit"
                    className="md:mt-7"
                  >
                    {TEXTO_BOTON_ENVIAR} <Send className="ml-2" />
                  </ButtonComponent>
                </div>
                {estadoSolicitud && !errorSolicitud ? (
                  <div className="col-span-12 md:col-span-10">
                    <AlertComponent type="success">
                      <Text>{TEXTO_ALERTA_ENVIADO}</Text>
                    </AlertComponent>
                  </div>
                ) : (
                  errorSolicitud && (
                    <div className="col-span-12 md:col-span-10">
                      <AlertComponent type="error">
                        <Text>{TEXTO_ALERTA_NO_ENVIADO}</Text>
                      </AlertComponent>
                    </div>
                  )
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className="mb-5">
        <Table
          headTable={
            <Heading type="h4" className="p-2">
              Solicitudes enviadas
            </Heading>
          }
          data={ususariosInvitados}
          columns={columnTables}
          isLoading={cargandoListaEmpresas}
          enableEditing={false}
        />
      </div>
    </SidebarLayout>
  );
};

export default InvitarUsuarios;
