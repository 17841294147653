import React, { useEffect, useState } from 'react';
import { CircularProgress, Divider } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import IconComponent from 'components/design-system/icon/Icon';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import BadgeIcon from '@mui/icons-material/Badge';
import { Link } from 'react-router-dom';
import ButtonComponent from 'components/design-system/Button/Button';
import { TaskAlt } from '@mui/icons-material';
import { RUTAS } from 'const/Rutas';

export const CuadroRegistrarNegocio = ({ porcentajeUsuarioPendiente }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(0);

  const esPerfilCompletado = porcentajeUsuarioPendiente <= 0;

  useEffect(() => {
    try {
      const porcentaje = porcentajeUsuarioPendiente.toFixed(0) || 0;
      console.log('porcentaje ::>', porcentaje);
      if (porcentaje) {
        const porcentajeUsuarioCompletado = 100 - porcentaje;
        const porcentajeTotal = (porcentajeUsuarioCompletado * 100) / 300;
        setData(porcentajeTotal.toFixed(0));
      }
    } catch (error) {
      setData(0);
    }

    setIsLoading(false);
  }, [porcentajeUsuarioPendiente]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="mb-6">
      <div className="bg-white p-4  md:px-6 py-4 rounded shadow-md">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-4 lg:col-span-3 mx-auto sm:pr-4 lg:pr-0 text-center flex items-center sm:border-r">
            <div className="md:pr-4">
              <div className="relative w-16 m-auto">
                <CircularProgress
                  variant="determinate"
                  value={parseFloat(data)}
                  thickness={4}
                  size={68}
                  className=" text-uv-primary-0 absolute left-0 z-10 -top-[2px]"
                />
                <CircularProgress
                  variant="determinate"
                  value={100}
                  thickness={2}
                  size={64}
                  className="text-uv-primary-80"
                />
                <span className="absolute left-5 top-5 font-primary text-base font-semibold text-uv-primary-0">
                  {data}%
                </span>
              </div>
              <Heading type="H2" className="!text-uv-primary-0">
                Completa
                <br />
                tu registro
              </Heading>
              <Text>
                Tienes{' '}
                {esPerfilCompletado
                  ? '1 actividad pendiente'
                  : '2 actividades pendientes'}
              </Text>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-8 lg:col-span-9">
            <div className="p-2 grid grid-cols-12 gap-4">
              <div className="col-span-12 xs:col-span-2 text-center">
                <div className="w-12 h-12 rounded-full bg-background-primary align-middle justify-center content-center items-center m-auto">
                  <IconComponent color="primary">
                    <AccountBalanceIcon className="w-8 h-8" />
                  </IconComponent>
                </div>
              </div>
              <div className="col-span-12 xs:col-span-10">
                <Heading type="H3">Registra tu negocio o actividad</Heading>
                <Text>
                  Para ofrecerte mayor funcionalidad, búsquedas y resultados más
                  apropiados a tu perfíl es necesario completar el registro
                  definiendo si participarás como empresa o persona privada.
                </Text>

                <ul className="mt-4 mb-4">
                  <li className="flex items-center mb-1">
                    <IconComponent className="mr-2">
                      <TaskAltIcon className="text-base" />
                    </IconComponent>
                    <Text>Podrás participar en transacciones</Text>
                  </li>
                  <li className="flex items-center mb-1">
                    <IconComponent className="mr-2">
                      <TaskAltIcon className="text-base" />
                    </IconComponent>
                    <Text>Mejores opciones en el buscador de materias</Text>
                  </li>
                  <li className="flex items-center mb-1">
                    <IconComponent className="mr-2">
                      <TaskAltIcon className="text-base" />
                    </IconComponent>
                    <Text>Match automático según tus necesidades</Text>
                  </li>
                </ul>

                <Link to={RUTAS.REGISTRAR_NEGOCIO}>
                  <ButtonComponent type="primary">
                    Iniciar el registro
                    <KeyboardArrowRightIcon />
                  </ButtonComponent>
                </Link>
              </div>

              <div className="col-span-12">
                <Divider />
              </div>

              <div className="col-span-12 xs:col-span-2 text-center">
                <div className="w-12 h-12 rounded-full bg-background-primary align-middle justify-center content-center items-center m-auto">
                  <IconComponent color="primary">
                    {esPerfilCompletado ? (
                      <TaskAlt className="text-success-dark w-8 h-8" />
                    ) : (
                      <BadgeIcon className="w-8 h-8" />
                    )}
                  </IconComponent>
                </div>
              </div>
              <div className="col-span-12 xs:col-span-10">
                <Heading
                  type="H3"
                  className={`${esPerfilCompletado ? 'text-success-dark' : ''}`}
                >
                  Completa la información de tu cuenta
                </Heading>

                {!esPerfilCompletado && (
                  <Link to={RUTAS.EDITAR_CUENTA}>
                    <ButtonComponent type="primary">
                      Editar ahora
                      <KeyboardArrowRightIcon />
                    </ButtonComponent>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
