import { DICTONARY } from 'const/Dictonary';
import { useEffect, useState } from 'react';
import serviceEmpresas from 'services/empresas';
import { CargaActividades, CargaRubros } from 'views/Business/hooks';

export const useRubrosActividades = (values, setFieldValue) => {
  const [cargandoServicioSII, setCargandoServicioSII] = useState(false);
  const [cargaRubrosSII, setCargaRubrosSII] = useState({
    actividades: null,
    activado: false
  });

  const { rubros, loadRubros, isLoadingRubros, setRubros } = CargaRubros();
  const { actividades, loadActividades, isLoadingActividades, setActividades } =
    CargaActividades();

  useEffect(() => {
    handleCargarActividades(values.rubro, values.tipoNegocio);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.rubro, values.tipoNegocio]);

  const handleCargarActividades = (rubro, tipoNegocio) => {
    if (tipoNegocio === 'persona-natural') return;
    if (rubro) {
      const codigoCiuRubroFiltrado = rubro.codigoCiuRubro;
      if (cargaRubrosSII.activado) {
        const actividadesFiltradas = cargaRubrosSII.actividades.filter(
          item => item.rubro.codigoCiuRubro === codigoCiuRubroFiltrado
        );
        if (
          actividadesFiltradas &&
          Array.isArray(actividadesFiltradas) &&
          actividadesFiltradas.length === 1
        ) {
          setFieldValue('actividad', actividadesFiltradas[0]);
        }
        setActividades(actividadesFiltradas);
      } else {
        codigoCiuRubroFiltrado && loadActividades(codigoCiuRubroFiltrado);
      }
    }
  };

  const cargaDatosEmpresasSII = async rutEmpresa => {
    if (values.tipoNegocio === 'persona-natural') return;
    setCargandoServicioSII(true);

    const params = { rutEmpresa: rutEmpresa };
    const response = await serviceEmpresas.datosEmpresasSII(
      params,
      DICTONARY.INTERCEPTOR.DESACTIVADO
    );
    const data = response.data;
    setFieldValue('razonSocial', data.razonSocial);
    const actividadesEmpresa = data.actividades;

    if (Array.isArray(actividadesEmpresa) && actividadesEmpresa.length > 0) {
      let arrayRubrosValues = [];

      data.actividades.map(res => {
        const rubro = res.rubro;
        arrayRubrosValues.push({
          codigoCiuRubro: rubro.codigoCiuRubro,
          nombreRubro: rubro.nombreRubro
        });
        return arrayRubrosValues;
      });
      setCargaRubrosSII({
        actividades: data.actividades,
        activado: true
      });
      setRubros(arrayRubrosValues);
      setCargandoServicioSII(false);
    } else {
      setCargandoServicioSII(false);
      setFieldValue('actividad', {});
      loadRubros();
      setCargaRubrosSII({
        actividades: null,
        activado: false
      });
    }
  };

  return {
    cargaDatosEmpresasSII,
    cargandoServicioSII,
    cargaRubrosSII,
    rubros,
    actividades,
    isLoadingActividades
  };
};
