import React from 'react';
import './Loading.css';

const Loading = (props) => {
   //si no se envian las clases creadas para indicadores se asigna las originales por defecto
   const classWrapper = props.classWrapper ===undefined ? "wrapperLoading": props.classWrapper;
   const classChase = props.classChase ===undefined ? "sk-chase": props.classChase;

   return (
      <div className={classWrapper}>
         <div className={classChase}>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
         </div>
      </div>
   )
}

export default Loading;