// Design System
import Heading from 'components/design-system/Heading/Heading';
import InputNumber from 'components/design-system/input/InputNumber';
import LabelComponent from 'components/design-system/Label/Label';
import { TooltipComponent } from 'components/design-system/Tooltip/Tooltip';

// Material
import { Help } from '@mui/icons-material';
// Diccionarity
import { DICTONARY } from 'const/Dictonary';

const ContenidoReciclado = ({
  // Formik
  errors,
  // handleBlur,
  // handleChange,
  // handleReset,
  // handleSubmit,
  isSubmitting,
  // setFieldError,
  setFieldTouched,
  setFieldValue,
  touched,
  // validateForm,
  values
  // Dependencias
}) => {
  return (
    <>
      <div className="col-span-12">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12">
            <Heading type="h4" className="mb-0">
              Contenido reciclado y renovable del recurso
            </Heading>
          </div>
          <div className="col-span-12 xs:col-span-12">
            <div className="grid grid-cols-12 gap-4">
              {/* Contenido reciclado */}
              <div className="col-span-12 sm:col-span-6 xl:col-span-4">
                <InputNumber
                  autoComplete="off"
                  suffix="%"
                  isAllowed={values => {
                    const { floatValue } = values;
                    if (!floatValue) return true;
                    return floatValue;
                  }}
                  disabled={isSubmitting}
                  label={
                    <LabelComponent className="w-full">
                      Reciclado post-consumo{' '}
                      <span className="text-neutral-60">(opcional)</span>
                      <TooltipComponent
                        className="float-right"
                        title={
                          'Indica la cantidad de material reciclado que incorpora el recurso registrado.'
                        }
                      >
                        <Help
                          className="text-uv-secondary-0"
                          fontSize="small"
                        />
                      </TooltipComponent>
                    </LabelComponent>
                  }
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  name={'contenidoReciclado'}
                  placeholder="% del peso total"
                  touched={touched.contenidoReciclado}
                  type="text"
                  value={values.contenidoReciclado || ''}
                  onChange={event => {
                    const { value } = event.target;
                    const sanitizedValue = value
                      .replaceAll('.', '')
                      .replaceAll(',', '.');
                    const floatValue = parseFloat(sanitizedValue);
                    if (!isNaN(floatValue)) {
                      setFieldValue('contenidoReciclado', floatValue);
                    }
                  }}
                  onFocus={event => {
                    const { value } = event.target;
                    setFieldTouched('contenidoReciclado');
                    if (
                      !event ||
                      !event.target ||
                      typeof event.target.value !== 'string'
                    ) {
                      return;
                    }
                    let sanitizedValue = value.split('.').join('') || '';
                    setFieldValue('contenidoReciclado', sanitizedValue);
                  }}
                  onBlur={() => {
                    setFieldTouched('contenidoReciclado');
                  }}
                  estado={
                    errors.contenidoReciclado
                      ? { tipo: 'error', mensaje: errors.contenidoReciclado }
                      : null
                  }
                />
              </div>

              {/* Contenido renovable */}
              <div className="col-span-12 sm:col-span-6 xl:col-span-4">
                <InputNumber
                  autoComplete="off"
                  suffix="%"
                  isAllowed={values => {
                    const { floatValue } = values;
                    if (!floatValue) return true;
                    return floatValue;
                  }}
                  disabled={isSubmitting}
                  name={'contenidoRenovable'}
                  label={
                    <LabelComponent className="w-full">
                      Renovable{' '}
                      <span className="text-neutral-60">(opcional)</span>
                      <TooltipComponent
                        className="float-right"
                        title={
                          'Indica la cantidad de material renovable que incorpora el recurso registrado'
                        }
                      >
                        <Help
                          className="text-uv-secondary-0"
                          fontSize="small"
                        />
                      </TooltipComponent>
                    </LabelComponent>
                  }
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  placeholder="% del peso total"
                  touched={touched.contenidoRenovable}
                  type="text"
                  value={values.contenidoRenovable || ''}
                  onChange={event => {
                    const { value } = event.target;
                    const sanitizedValue = value
                      .replaceAll('.', '')
                      .replaceAll(',', '.');
                    const floatValue = parseFloat(sanitizedValue);
                    if (!isNaN(floatValue)) {
                      setFieldValue('contenidoRenovable', floatValue);
                    }
                  }}
                  onFocus={event => {
                    const { value } = event.target;
                    setFieldTouched('contenidoRenovable');
                    if (
                      !event ||
                      !event.target ||
                      typeof event.target.value !== 'string'
                    ) {
                      return;
                    }
                    let sanitizedValue = value.split('.').join('') || '';
                    setFieldValue('contenidoRenovable', sanitizedValue);
                  }}
                  onBlur={() => {
                    setFieldTouched('contenidoRenovable');
                  }}
                  estado={
                    errors.contenidoRenovable
                      ? { tipo: 'error', mensaje: errors.contenidoRenovable }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContenidoReciclado;
