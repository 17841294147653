import { Delete, FilterList } from '@mui/icons-material';
import ButtonComponent from 'components/design-system/Button/Button';
import { Form, Formik } from 'formik';
import { FiltroFormCampos } from './FiltroFormCampos';
import { useContext } from 'react';
import { LineaBaseContext } from '../context/LineaBaseContext';
import { DICTONARY } from 'const/Dictonary';
import { FiltroFormLoading } from './FiltroFormLoading';
import { AuthContext } from 'context';

export const FiltroForm = () => {
  const {
    perfiles: { isSuperAdmin, isAnalistaTerritorial },
    logeduser
  } = useContext(AuthContext);
  const { sectoresEconomicos, setAplicarFiltros, isCargando } =
    useContext(LineaBaseContext);

  if (isCargando.sectoresEconomicos) {
    return <FiltroFormLoading />;
  }

  const getInitialValues = sectoresEconomicos => {
    const valoresIniciales = {
      region: '',
      comuna: '',
      sectorEconomico: null
    };

    let sectoresEconomicosSelecionados = [];
    sectoresEconomicosSelecionados = sectoresEconomicos.filter(
      sector =>
        !DICTONARY.SECTOR_ECONOMICO.NO_SELECIONADOS_POR_DEFECTO.includes(
          sector.codigoSectorEconomico
        )
    );

    valoresIniciales.sectorEconomico = sectoresEconomicosSelecionados;

    if (isAnalistaTerritorial) {
      valoresIniciales.region = logeduser?.region || '';
    }

    return valoresIniciales;
  };

  return (
    <Formik
      validateOnChange
      validateOnBlur
      initialValues={getInitialValues(sectoresEconomicos)}
      onSubmit={(values, { setSubmitting }) => {
        try {
          let params = {
            empresaSeleccionada: false,
            tipoPeticion: DICTONARY.LINEA_BASE_ESTADOS.FILTRA_FORMULARIO
          };
          if (values.region && values.region.codigoRegion) {
            params.codigosRegion = values.region.codigoRegion;
          }

          if (values.comuna && values.comuna.codigoComuna) {
            params.codigoComuna = values.comuna.codigoComuna;
          }

          if (values.sectorEconomico && values.sectorEconomico.length > 0) {
            params.codigoSectorEconomico = values.sectorEconomico.map(
              sector => sector.codigoSectorEconomico
            );
          }
          setAplicarFiltros(params);
          setSubmitting(false);
        } catch (error) {
          console.log('error >', error);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        isSubmitting,
        setFieldTouched,
        initialStatus
      }) => (
        <Form id="filtroForm">
          <div className="grid grid-cols-12 gap-4 items-start">
            <FiltroFormCampos
              values={values}
              initialStatus={initialStatus}
              sectorEconomico={sectoresEconomicos}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              isDisabled={
                isCargando.mapaCargando ||
                isCargando.tablaEmpresasCargando ||
                isCargando.graficoBarrasCargando ||
                isCargando.tablaRETCargando
              }
            />
            <div className="col-span-12 sm:col-span-6 lg:col-span-4 text-end pt-7">
              <div className="flex justify-end gap-4">
                <ButtonComponent
                  type="secondary"
                  disabled={
                    isCargando.mapaCargando ||
                    isCargando.tablaEmpresasCargando ||
                    isCargando.graficoBarrasCargando ||
                    isCargando.tablaRETCargando
                  }
                  onClick={() => {
                    setFieldValue('sectorEconomico', []);
                    setFieldValue('comuna', '');
                    if (isSuperAdmin) {
                      setFieldValue('region', '');
                    }
                  }}
                >
                  <Delete />
                </ButtonComponent>

                <ButtonComponent
                  form="filtroForm"
                  buttonType="submit"
                  type="primary"
                  isLoading={isSubmitting}
                  disabled={
                    isCargando.mapaCargando ||
                    isCargando.tablaEmpresasCargando ||
                    isCargando.graficoBarrasCargando ||
                    isCargando.tablaRETCargando
                  }
                  className="h-10"
                >
                  <span className="hidden xs:block">Filtrar información</span>{' '}
                  <FilterList className="ml-2" />
                </ButtonComponent>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
