import { useContext } from 'react';
import { MatchContext } from 'views/Marketplace/Residuos/context/MatchContext';
import { ListadoEmpresas } from './ListadoEmpresas';
import { ListadoResiduos } from './ListadoResiduos';

export const ResultadosSinNegocio = () => {
  const { empresaSeleccionada } = useContext(MatchContext);

  return (
    <div>{empresaSeleccionada ? <ListadoResiduos /> : <ListadoEmpresas />}</div>
  );
};
