import { useState } from 'react';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { Save } from '@mui/icons-material';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import ButtonComponent from 'components/design-system/Button/Button';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import { Divider } from '@mui/material';

export const BusinessClientesDestacados = ({
  values,
  errors,
  touched,
  onChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setFieldError
}) => {
  const handleAgregarCliente = () => {
    if (values.inputClienteNombre.trim().length <= 0) {
      setFieldTouched('inputClienteNombre');
      setFieldError('inputClienteNombre');
    }

    const clientesAux = values.clientes;
    clientesAux.push({
      nombreCliente: values.inputClienteNombre
    });

    setFieldValue('clientes', clientesAux);
    setFieldValue('inputClienteNombre', '');
  };

  const handleEditarcliente = (value, index) => {
    const clienteEditado = values.clientes;
    clienteEditado[index] = { nombreCliente: value };
    setFieldValue('clientes', clienteEditado);
  };

  const handleEliminarCliente = index => {
    const clienteAux = values.clientes.filter((cliente, i) => i !== index);
    setFieldValue('clientes', clienteAux);
  };

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 py-5">
        <Divider />
      </div>
      <div className="col-span-12">
        <Heading type="h3" className="mb-4">
          Clientes destacados{' '}
          <span className="normal-case text-uv-secondary-0 text-sm">
            (opcional)
          </span>
        </Heading>

        <div className="mb-10">
          <div>
            <Text className="mb-4">
              Ingresa el nombre de los clientes destacados con lo que has
              trabajado (máximo 5), las cuales serán visibles para los usuarios
              que quieran ver el perfíl de tu negocio.
            </Text>

            <div className="bg-white shadow-md px-4 py-3 rounded">
              <div className="grid grid-cols-12 sm:gap-5 items-end mb-4">
                <div className="col-span-9">
                  <InputComponent
                    fullWidth
                    name="inputClienteNombre"
                    label={<LabelComponent>Nombre del cliente</LabelComponent>}
                    onChange={onChange}
                    onBlur={handleBlur}
                    value={values.inputClienteNombre}
                    touched={touched.inputClienteNombre}
                    estado={
                      errors.inputClienteNombre
                        ? { tipo: 'error', mensaje: errors.inputClienteNombre }
                        : null
                    }
                  />
                </div>
                <div className="col-span-3 text-end">
                  <ButtonComponent
                    fullWidth
                    className="justify-center h-10"
                    onClick={handleAgregarCliente}
                    disabled={
                      (values.inputClienteNombre &&
                        values.inputClienteNombre.trim().length === 0) ||
                      values.clientes.length === 5
                    }
                  >
                    <Add />
                  </ButtonComponent>
                </div>
              </div>

              {values.clientes.length > 0 && (
                <div>
                  <div className="grid grid-cols-12 gap-5 items-center py-2">
                    <div className="col-span-12 sm:col-span-9">
                      <Text className="text-black font-bold">Cliente</Text>
                    </div>
                    <div className="col-span-12 hidden sm:block sm:col-span-3 sm:text-center">
                      <Text className="text-black font-bold">Acciones</Text>
                    </div>
                  </div>
                  {values.clientes.map((cliente, index) => (
                    <ClienteItem
                      item={cliente}
                      key={index}
                      index={index}
                      handleEditarcliente={handleEditarcliente}
                      handleEliminarCliente={handleEliminarCliente}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessClientesDestacados;

const ClienteItem = ({
  item,
  index,
  handleEditarcliente,
  handleEliminarCliente
}) => {
  const [clienteNombre, setClienteNombre] = useState(null);

  return (
    <div className="grid grid-cols-12 gap-2 sm:gap-5 items-center border-t py-2">
      <div className="col-span-12 sm:col-span-9">
        {clienteNombre ? (
          <InputComponent
            fullWidth
            value={clienteNombre}
            onChange={event => setClienteNombre(event.target.value)}
          />
        ) : (
          <Text>{item.nombreCliente}</Text>
        )}
      </div>
      <div className="col-span-12 sm:col-span-3 text-center sm:justify-center flex gap-4">
        {clienteNombre ? (
          <IconButtonComponent
            type="secondary"
            onClick={() => {
              setClienteNombre(null);
              handleEditarcliente(clienteNombre, index);
            }}
          >
            <Save />
          </IconButtonComponent>
        ) : (
          <IconButtonComponent
            type="secondary"
            onClick={() => {
              setClienteNombre(item.nombreCliente);
            }}
          >
            <Edit />
          </IconButtonComponent>
        )}
        <IconButtonComponent
          disabled={clienteNombre ? true : false}
          type="error"
          onClick={() => handleEliminarCliente(index)}
        >
          <Delete />
        </IconButtonComponent>
      </div>
    </div>
  );
};
