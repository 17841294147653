import { DICTONARY } from 'const/Dictonary';
import { PERMISOS } from 'const/Permisos';
import * as yup from 'yup';

const schemaGeneracionIndicadores = isTienePermisos => {
  const schema = {
    trabajadoresMasculino: yup
      .string()
      .max(15, 'El valor es demasiado largo')
      .required('Este campo es obligatorio')
      .typeError('La cantidad máxima es 1.000.000'),

    trabajadoresFemenino: yup
      .string()
      .max(15, 'El valor es demasiado largo')
      .required('Este campo es obligatorio')
      .typeError('La cantidad máxima es 1.000.000'),

    consumoEnergetico: yup
      .string()
      .max(15, 'El valor es demasiado largo')
      .required('Este campo es obligatorio'),

    consumoCombustible: yup
      .string()
      .max(15, 'El valor es demasiado largo')
      .required('Este campo es obligatorio'),

    //Solo para generador o receptor
    trabajadoresMasGestion: yup.string().when('role', {
      is: isTienePermisos(PERMISOS.procesoGestion),
      then: trabajadoresMasGestion =>
        yup.string().when('procesoGestion', {
          is: true,
          then: trabajadoresMasGestion =>
            trabajadoresMasGestion
              .max(15, 'El valor es demasiado largo')
              .required('Campo obligatorio')
        })
    }),

    trabajadoresFemGestion: yup.string().when('role', {
      is: isTienePermisos(PERMISOS.procesoGestion),
      then: trabajadoresFemGestion =>
        yup.string().when('procesoGestion', {
          is: true,
          then: trabajadoresFemGestion =>
            trabajadoresFemGestion
              .max(15, 'El valor es demasiado largo')
              .required('Campo obligatorio')
              .typeError('El número no es válido')
        })
    }),

    totalHH: yup.string().when('role', {
      is: isTienePermisos(PERMISOS.procesoGestion),
      then: totalHH =>
        yup.string().when('procesoGestion', {
          is: true,
          then: totalHH =>
            totalHH
              .max(15, 'El valor es demasiado largo')
              .required('Campo obligatorio')
              .typeError('El número no es válido')
        })
    }),

    remuneracionesPagadas: yup.string().when('role', {
      is: isTienePermisos(PERMISOS.procesoGestion),
      then: remuneracionesPagadas =>
        yup.string().when('procesoGestion', {
          is: true,
          then: remuneracionesPagadas =>
            remuneracionesPagadas.required('Campo obligatorio')
        })
    })
  };

  if (isTienePermisos(PERMISOS.residuoGenerado)) {
    schema.residuosGenerados = yup.string().when('role', {
      is: roles => roles.includes(DICTONARY.ROL.GENERADOR.NOMBRE),
      then: residuosGenerados =>
        residuosGenerados
          .max(15, 'El valor es demasiado largo')
          .typeError('El número no es válido')
          .required('Campo obligatorio')
    });
  }

  if (isTienePermisos(PERMISOS.residuoProyectado)) {
    schema.residuosProyectados = yup.string().when('role', {
      is: roles => roles.includes(DICTONARY.ROL.GENERADOR.NOMBRE),
      then: residuosProyectados =>
        residuosProyectados
          .max(15, 'El valor es demasiado largo')
          .required('Campo obligatorio')
          .typeError('El número no es válido')
    });
  }

  if (isTienePermisos(PERMISOS.recibidoComprado)) {
    schema.residuosCompradosRecibidos = yup.string().when('role', {
      is: roles => roles.includes(DICTONARY.ROL.RECEPTOR.NOMBRE),
      then: residuosCompradosRecibidos =>
        residuosCompradosRecibidos
          .max(15, 'El valor es demasiado largo')
          .required('Campo obligatorio')
          .typeError('El número no es válido')
    });
  }

  if (isTienePermisos(PERMISOS.totalProyectadoTransportar)) {
    schema.capacidadProyectada = yup.string().when('role', {
      is: roles => roles.includes(DICTONARY.ROL.TRANSPORTISTA.NOMBRE),
      then: capacidadProyectada =>
        capacidadProyectada
          .max(15, 'El valor es demasiado largo')
          .required('Campo obligatorio')
          .typeError('El número no es válido')
    });
  }

  if (isTienePermisos(PERMISOS.totalTransportadas)) {
    schema.residuosTransportados = yup.string().when('role', {
      is: roles => roles.includes(DICTONARY.ROL.TRANSPORTISTA.NOMBRE),
      then: residuosTransportados =>
        residuosTransportados
          .max(15, 'El valor es demasiado largo')
          .required('Campo obligatorio')
          .typeError('El número no es válido')
    });
  }

  if (isTienePermisos(PERMISOS.kmRecorridos)) {
    schema.kilometrosRecorridos = yup.string().when('role', {
      is: roles => roles.includes(DICTONARY.ROL.TRANSPORTISTA.NOMBRE),
      then: kilometrosRecorridos =>
        kilometrosRecorridos
          .max(15, 'El valor es demasiado largo')
          .required('Campo obligatorio')
          .typeError('El número no es válido')
    });
  }

  if (isTienePermisos(PERMISOS.totalGestionar)) {
    schema.residuosGestionar = yup.string().when('role', {
      is: roles => roles.includes(DICTONARY.ROL.GESTOR.NOMBRE),
      then: residuosGestionar =>
        residuosGestionar
          .max(15, 'El valor es demasiado largo')
          .required('Campo obligatorio')
          .typeError('El número no es válido')
    });
  }

  if (isTienePermisos(PERMISOS.totalGestionado)) {
    schema.residuosGestionados = yup.string().when('role', {
      is: roles => roles.includes(DICTONARY.ROL.GESTOR.NOMBRE),
      then: residuosGestionados =>
        residuosGestionados
          .max(15, 'El valor es demasiado largo')
          .required('Campo obligatorio')
          .typeError('El número no es válido')
    });
  }

  if (isTienePermisos(PERMISOS.totalAsesorado)) {
    schema.residuosAsesorados = yup.string().when('role', {
      is: roles => roles.includes(DICTONARY.ROL.ASESOR.NOMBRE),
      then: residuosAsesorados =>
        residuosAsesorados
          .max(15, 'El valor es demasiado largo')
          .required('Campo obligatorio')
          .typeError('El número no es válido')
    });
  }

  return yup.object().shape(schema);
};

export { schemaGeneracionIndicadores };
