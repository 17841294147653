// Listado de funciones a refactorizar

// FORMATO FECHA - cambio de formato UTC a dd/mm/yyyy
export const formateaFechaUTC = fechaString => {
  const date = new Date(fechaString);

  // Adiciona 10 horas para converter para o horário local
  date.setHours(date.getHours() + 10);

  // Formata a data como dd/mm/yyyy no formato Chile
  const formattedDate = date.toLocaleDateString('es-CL', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

  return formattedDate;
};

// FORMATO FECHA - cambio de formato newDate() a dd/mm/yyyy
export const formateaFechaDate = fechaString => {
  const date = new Date(fechaString);

  // Adiciona 10 horas para converter para o horário local
  date.setHours(date.getHours() + 10);

  // Formata a data como yyyy-mm-dd no formato EUU
  const formattedDate = date.toISOString().slice(0, 10);

  return formattedDate;
};

export const formateaSucursales = sucursales => {
  // mapear sucursales  filtrado
  console.log('sucursales ::', sucursales);
  const sucursalesFiltrado = sucursales.map(sucursal => {
    return {
      codigoSucursalTransaccion: sucursal.codigoSucursalTransaccion,
      ejecutoTratamiento: sucursal.ejecutoTratamiento,
      nombreRol: sucursal.nombreRol,
      sucursal: {
        codigoSucursal: sucursal.sucursal.codigoSucursal
      }
    };
  });

  return sucursalesFiltrado;
};
