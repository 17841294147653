import { DICTONARY } from 'const/Dictonary';
import PixiOverlay from 'react-leaflet-pixi-overlay';
const EMPRESA_SVG_ICON_SELECCIONADO =
  '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40"><defs><clipPath id="a"><rect width="40" height="40"/></clipPath><clipPath id="b"><path d="M0,0H40V40H0Z"/></clipPath></defs><g id="c" clip-path="url(#a)"><g clip-path="url(#a)"><g clip-path="url(#b)"><g transform="translate(5.6 2.616)"><path d="M21.9,35.346a.394.394,0,0,1-.3-.139l-3.542-4.1-.12-.139H12.7a5.207,5.207,0,0,1-5.2-5.2V8.97a5.206,5.206,0,0,1,5.2-5.2H30.3a5.206,5.206,0,0,1,5.2,5.2v16.8a5.206,5.206,0,0,1-5.2,5.2H25.861l-.119.139-3.54,4.1a.394.394,0,0,1-.3.139Z" transform="translate(-7.1 -3.37)" fill="#222d63"/><path d="M21.8,34.846l3.778-4.376H30.2a4.806,4.806,0,0,0,4.8-4.8V8.87a4.806,4.806,0,0,0-4.8-4.8H12.6a4.806,4.806,0,0,0-4.8,4.8v16.8a4.806,4.806,0,0,0,4.8,4.8h5.421L21.8,34.846m0,.8a.8.8,0,0,1-.606-.277l-3.539-4.1H12.6A5.6,5.6,0,0,1,7,25.67V8.87a5.6,5.6,0,0,1,5.6-5.6H30.2a5.6,5.6,0,0,1,5.6,5.6v16.8a5.6,5.6,0,0,1-5.6,5.6H25.946l-3.541,4.1a.8.8,0,0,1-.6.277Z" transform="translate(-7 -3.27)" fill="#fff"/></g><path d="M29.348,21.4h1.6V23h-1.6Zm0-3.2h1.6v1.6h-1.6Zm0-3.2h1.6v1.6h-1.6ZM26.74,15l1.008.667V15Z" transform="translate(-5.348 -3)" fill="#fff"/><path d="M23,11v1.208l1.6,1.064v-.667h7.2V23.8H28.6v1.6h4.8V11Z" transform="translate(-4.6 -2.2)" fill="#fff"/><path d="M19.736,13.7,25.2,17.34v8.6H14V17.524ZM21.2,24.34h2.4V18.068l-3.864-2.456L15.6,18.244v6.1H18v-4.8h3.2Z" transform="translate(-2.8 -2.74)" fill="#fff"/><path d="M13,8H32.2V27.2H13Z" transform="translate(-2.6 -1.6)" fill="none"/></g></g></g></svg>';

const EMPRESA_SVG_ICON_DEFAULT =
  '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26"><defs><clipPath id="a"><rect width="26" height="26"/></clipPath><clipPath id="b"><path d="M0,0H25.895V25.895H0Z"/></clipPath></defs><g id="c" clip-path="url(#a)"><g clip-path="url(#a)"><g clip-path="url(#b)"><g transform="translate(3.625 1.693)"><path d="M16.822,24.211a.255.255,0,0,1-.2-.09l-2.293-2.653-.078-.09H10.866A3.371,3.371,0,0,1,7.5,18.012V7.136A3.37,3.37,0,0,1,10.866,3.77H22.26a3.37,3.37,0,0,1,3.366,3.366V18.012a3.37,3.37,0,0,1-3.366,3.366H19.386l-.077.09-2.292,2.653a.255.255,0,0,1-.2.09Z" transform="translate(-7.241 -3.511)" fill="#222d63"/><path d="M16.581,23.711l2.446-2.833h2.992a3.111,3.111,0,0,0,3.107-3.107V6.9a3.111,3.111,0,0,0-3.107-3.107H10.625A3.111,3.111,0,0,0,7.518,6.9V17.771a3.111,3.111,0,0,0,3.107,3.107h3.509l2.446,2.833m0,.518a.515.515,0,0,1-.392-.179L13.9,21.4H10.625A3.625,3.625,0,0,1,7,17.771V6.9A3.625,3.625,0,0,1,10.625,3.27H22.019A3.625,3.625,0,0,1,25.644,6.9V17.771A3.625,3.625,0,0,1,22.019,21.4H19.265L16.972,24.05a.515.515,0,0,1-.391.179Z" transform="translate(-7 -3.27)" fill="#fff"/></g><path d="M28.428,19.143h1.036v1.036H28.428Zm0-2.072h1.036v1.036H28.428Zm0-2.072h1.036v1.036H28.428ZM26.74,15l.652.432V15Z" transform="translate(-12.891 -7.232)" fill="#fff"/><path d="M23,11v.782l1.036.689v-.432H28.7v7.247H26.625v1.036h3.107V11Z" transform="translate(-11.088 -5.303)" fill="#fff"/><path d="M17.713,13.7l3.537,2.356v5.567H14V16.176Zm.948,6.888h1.554v-4.06l-2.5-1.59-2.677,1.7v3.946h1.554V17.481h2.072Z" transform="translate(-6.749 -6.605)" fill="#fff"/><path d="M13,8H25.429V20.429H13Z" transform="translate(-6.267 -3.857)" fill="none"/></g></g></g></svg>';

const EMPRESA_SVG_ICON_NO_SELECCIONADO =
  '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26"><defs><clipPath id="a"><rect width="26" height="26"/></clipPath><clipPath id="b"><path d="M0,0H26.864V26.864H0Z"/></clipPath></defs><g id="c" clip-path="url(#a)"><g clip-path="url(#a)"><g clip-path="url(#b)"><g transform="translate(3.761 1.757)"><path d="M17.171,24.976a.265.265,0,0,1-.2-.093l-2.379-2.752-.081-.093H10.992A3.5,3.5,0,0,1,7.5,18.545V7.262A3.5,3.5,0,0,1,10.992,3.77h11.82A3.5,3.5,0,0,1,26.3,7.262V18.545a3.5,3.5,0,0,1-3.492,3.492H19.831l-.08.093-2.377,2.753a.265.265,0,0,1-.2.093Z" transform="translate(-7.231 -3.501)" fill="#888"/><path d="M16.94,24.476l2.537-2.939h3.1A3.227,3.227,0,0,0,25.8,18.314V7.031a3.227,3.227,0,0,0-3.224-3.224H10.761A3.227,3.227,0,0,0,7.537,7.031V18.314a3.227,3.227,0,0,0,3.224,3.224H14.4l2.537,2.939m0,.537a.534.534,0,0,1-.407-.186l-2.377-2.753H10.761A3.761,3.761,0,0,1,7,18.314V7.031A3.761,3.761,0,0,1,10.761,3.27h11.82a3.761,3.761,0,0,1,3.761,3.761V18.314a3.761,3.761,0,0,1-3.761,3.761H19.724l-2.378,2.753a.534.534,0,0,1-.406.186Z" transform="translate(-7 -3.27)" fill="#fff"/></g><path d="M28.491,19.3h1.075v1.075H28.491Zm0-2.149h1.075v1.075H28.491Zm0-2.149h1.075v1.075H28.491ZM26.74,15l.677.448V15Z" transform="translate(-12.373 -6.941)" fill="#fff"/><path d="M23,11v.811l1.075.715v-.448H28.91V19.6H26.761v1.075h3.224V11Z" transform="translate(-10.643 -5.09)" fill="#fff"/><path d="M17.852,13.7l3.67,2.445V21.92H14V16.268Zm.984,7.146h1.612V16.633l-2.6-1.649-2.777,1.768v4.094h1.612V17.622h2.149Z" transform="translate(-6.478 -6.339)" fill="#fff"/><path d="M13,8H25.895V20.895H13Z" transform="translate(-6.015 -3.702)" fill="none"/></g></g></g></svg>';

export const RenderCanvasData = ({
  data,
  setRegistroSeleccionado,
  setFiltros,
  filtros,
  setCoordenadasMapa,
  setFilaTablaSeleccionada,
  setMostrarMensajeSinDireccion
}) => {
  const codigoEmpresaFiltrado = filtros?.codigoEmpresaAT || null;

  const getMarcadores = () => {
    const marcadores = [];
    let selectedMarker;

    data.forEach(sucursal => {
      if (codigoEmpresaFiltrado === sucursal.codigoEmpresaAT) {
        selectedMarker = {
          id: sucursal.codigoEmpresaAT,
          iconId: getIconID(sucursal), // used for cache
          customIcon: getIcon(sucursal),
          position: sucursal.latLon
        };
      }

      marcadores.push({
        id: sucursal.codigoEmpresaAT,
        iconId: getIconID(sucursal), // used for cache
        customIcon: getIcon(sucursal),
        position: sucursal.latLon,
        onClick: () => handleOnClickMapPoint(sucursal)
      });
    });

    if (selectedMarker) {
      marcadores.push(selectedMarker);
    }

    return marcadores;
  };

  const handleOnClickMapPoint = sucursal => {
    if (!sucursal.codigoEmpresaAT) return;
    setRegistroSeleccionado(null);
    setFilaTablaSeleccionada({row: -1 })

    if(filtros.codigoEmpresaAT === null){
      handleSelectPoint(sucursal);
    }

    if (sucursal.codigoEmpresaAT === filtros.codigoEmpresaAT) {
      handleUnselectPoint();
    } else {
      handleSelectPoint(sucursal);
    }
  };

  const handleSelectPoint = sucursal => {    
    setFiltros({
      ...filtros,
      codigoEmpresaAT: sucursal.codigoEmpresaAT,
      tipoPeticion: DICTONARY.LINEA_BASE_ESTADOS.SELECCIONA_PUNTO
    });
    if(sucursal && sucursal.latLon){
      setCoordenadasMapa(sucursal.latLon);
      setMostrarMensajeSinDireccion(false);
    } else {
      setMostrarMensajeSinDireccion(true);
    }
  };

  const handleUnselectPoint = () => {
    setFiltros({
      ...filtros,
      codigoEmpresaAT: null,
      tipoPeticion: DICTONARY.LINEA_BASE_ESTADOS.DESELECCIONA_PUNTO
    });
  };

  const getIconID = sucursal => {
    if (!codigoEmpresaFiltrado) {
      return 'default';
    }

    return codigoEmpresaFiltrado === sucursal.codigoEmpresaAT
      ? 'empresa'
      : 'empresa-opacity';
  };

  const getIcon = sucursal => {
    if (codigoEmpresaFiltrado === null) {
      return EMPRESA_SVG_ICON_DEFAULT;
    } else {
      if (codigoEmpresaFiltrado === sucursal.codigoEmpresaAT) {
        return EMPRESA_SVG_ICON_SELECCIONADO;
      } else {
        return EMPRESA_SVG_ICON_NO_SELECCIONADO;
      }
    }
  };

  return <PixiOverlay markers={getMarcadores()} />;
};
