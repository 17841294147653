import Loading from 'components/Loading/Loading';
import { useDisponibilidadCalculadora } from './hooks/useDisponibilidadCalculadora';
import { LimiteSuperado } from './LimteSuperado';
import CalculoIndicadoresFormik from './CalculoIndicadoresFormik';

export const CalculadoraIndicadores = () => {
  const { disponibilidad, setDisponibilidad, isCargandoDisponibilidad } =
    useDisponibilidadCalculadora();

  if (isCargandoDisponibilidad) {
    return <Loading />;
  }

  if (!disponibilidad.habilitado) {
    return <LimiteSuperado disponibilidad={disponibilidad} />;
  }

  return (
    <CalculoIndicadoresFormik
      disponibilidad={disponibilidad}
      setDisponibilidad={setDisponibilidad}
    />
  );
};
