import { useCallback, useMemo } from 'react';

const useCargaServicios = ({
  ObtieneEmpresasSIITransporte,
  ObtieneEmpresasSIIReceptor,
  ObtieneEmpresasSIIGenerador,
  ObtieneFormatoEntrega,
  ObtieneLevantamiento,
  ObtieneSucursales,
  ObtieneTipoTransporte,
  ObtieneTipoTratamiento,
  ObtieneUnidadMedida
}) => {
  // LLamadas a servicios
  const cargaSucursales = useCallback(async () => {
    await ObtieneSucursales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No hay dependencias intencionalmente

  const cargaLevantamiento = useCallback(async () => {
    await ObtieneLevantamiento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No hay dependencias intencionalmente

  const cargaFormatoEntrega = useCallback(async () => {
    await ObtieneFormatoEntrega();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No hay dependencias intencionalmente

  const cargaEmpresasSIITransporte = useCallback(async rutEmpresa => {
    await ObtieneEmpresasSIITransporte(rutEmpresa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No hay dependencias intencionalmente

  const cargaUnidadMedida = useCallback(async () => {
    await ObtieneUnidadMedida();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No hay dependencias intencionalmente

  const cargaEmpresasSIIReceptor = useCallback(async rutEmpresa => {
    await ObtieneEmpresasSIIReceptor(rutEmpresa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No hay dependencias intencionalmente

  const cargaEmpresasSIIGenerador = useCallback(async rutEmpresa => {
    await ObtieneEmpresasSIIGenerador(rutEmpresa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No hay dependencias intencionalmente

  const cargaTipoTransporte = useCallback(async () => {
    await ObtieneTipoTransporte();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No hay dependencias intencionalmente

  const cargaTipoTratamiento = useCallback(async () => {
    await ObtieneTipoTratamiento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No hay dependencias intencionalmente

  // Carga de datos

  useMemo(() => {
    cargaSucursales();
  }, [cargaSucursales]);

  useMemo(() => {
    cargaLevantamiento();
  }, [cargaLevantamiento]);

  useMemo(() => {
    cargaFormatoEntrega();
  }, [cargaFormatoEntrega]);

  useMemo(() => {
    cargaUnidadMedida();
  }, [cargaUnidadMedida]);

  useMemo(() => {
    cargaTipoTransporte();
  }, [cargaTipoTransporte]);

  useMemo(() => {
    cargaTipoTratamiento();
  }, [cargaTipoTratamiento]);

  return {
    cargaEmpresasSIIReceptor,
    cargaEmpresasSIITransporte,
    cargaEmpresasSIIGenerador,
    cargaFormatoEntrega,
    cargaLevantamiento,
    cargaUnidadMedida,
    cargaSucursales,
    cargaTipoTransporte,
    cargaTipoTratamiento
  };
};

export default useCargaServicios;
