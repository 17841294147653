import { ChatBubble, ChevronLeft, ChevronRight } from '@mui/icons-material';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import LinkComponent from 'components/design-system/Link/Link';
import { RoleComponent } from 'components/design-system/Role/Role';
import Text from 'components/design-system/Text/Text';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { DICTONARY } from 'const/Dictonary';
import { PERMISOS } from 'const/Permisos';
import { RUTAS } from 'const/Rutas';
import { numeroBaseDatosAFront } from 'helpers/formateoNumeros';
import { useContext } from 'react';
import { MatchContext } from 'views/Marketplace/Residuos/context/MatchContext';

export const ListadoResiduos = () => {
  const {
    empresaSeleccionada,
    setEmpresaSeleccionada,
    setResiduoSeleccionado
  } = useContext(MatchContext);

  if (!empresaSeleccionada) {
    return;
  }

  return (
    <div className="">
      <div
        onClick={() => {
          setEmpresaSeleccionada(null);
        }}
        className="flex items-center p-4 pb-2 border-b cursor-pointer hover:bg-neutral-90"
      >
        <div className="w-1/5 text-left">
          <ChevronLeft />
        </div>
        <div className="w-4/5">
          <Heading type="h3" className="pr-10">
            {empresaSeleccionada.empresa}
          </Heading>
          <RoleComponent
            className="mr-2"
            arrayRol={empresaSeleccionada.rol}
            tipo="etiqueta"
          />
        </div>
      </div>
      <div className="h-[52vh] scrollbar-uv overflow-y-auto pb-36">
        {empresaSeleccionada.residuos.map(residuo => {
          return (
            <div
              onClick={() => {
                setResiduoSeleccionado({
                  ...residuo,
                  sucursal: {
                    ...residuo.sucursal,
                    ...empresaSeleccionada.sucursal,
                    empresa: empresaSeleccionada.empresa,
                    direccion: empresaSeleccionada.direccion
                  }
                });
              }}
              className="flex items-center p-4 pb-2 border-b cursor-pointer hover:bg-neutral-90"
            >
              <div className="w-4/5">
                <RoleComponent
                  className="mr-2"
                  rol={residuo.rol.nombreRol}
                  tipo="etiqueta"
                />
                <Text className="pb-2">
                  {residuo.rol.codigoRol === DICTONARY.ROL.GENERADOR.CODIGO
                    ? residuo.nombreLER
                    : residuo.nombreResiduo}
                </Text>
                {residuo.rol.codigoRol === DICTONARY.ROL.GENERADOR.CODIGO && (
                  <div className="flex py-2">
                    {residuo.cantidad && (
                      <Text className="text-black font-semibold text-sm">
                        {numeroBaseDatosAFront(residuo.cantidad)}{' '}
                        {residuo.unidad}
                      </Text>
                    )}

                    {residuo.precioReferencial !== null && (
                      <>
                        <span className="border-r mx-2"></span>
                        <Text className="text-black font-semibold text-sm">
                          ${numeroBaseDatosAFront(residuo.precioReferencial)}
                        </Text>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="w-1/5 text-right">
                <ChevronRight />
              </div>
            </div>
          );
        })}
      </div>

      <div className="absolute bottom-0 left-0 w-full p-3 bg-white shadow-top rounded">
        <div className="mb-3">
          <div className="bg-warning-light rounded p-2">
            <Text className="text-black text-xs">
              Solo negocios registrados pueden transaccionar e interactuar en la
              plataforma.
              <LinkComponent
                className="ml-1 font-bold"
                href={RUTAS.REGISTRAR_NEGOCIO}
              >
                Registrar un negocio
              </LinkComponent>
            </Text>
          </div>
        </div>

        <div className="flex gap-4">
          <VerificarPermisos nombrePermiso={PERMISOS.iniciarTransaccion}>
            <div className="w-1/2">
              <ButtonComponent disabled={true} className="px-2 w-full">
                Iniciar transacción <ChevronRight />
              </ButtonComponent>
            </div>
          </VerificarPermisos>

          <VerificarPermisos nombrePermiso={PERMISOS.solicitarReunion}>
            <div className="w-1/2">
              <ButtonComponent
                disabled={true}
                className="px-2 w-full"
                type="secondary"
              >
                <ChatBubble className="mr-1" /> Solicitar Reunión
              </ButtonComponent>
            </div>
          </VerificarPermisos>
        </div>
      </div>
    </div>
  );
};
