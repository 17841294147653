import { Skeleton } from "@mui/material";

export const FiltroFormLoading = () => {
  
  return(
    <div className="grid grid-cols-12 gap-4 items-start">
      <div className="col-span-12 sm:col-span-6 lg:col-span-4">
        <Skeleton
          variant="rounded"
          className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
        />
      </div>
      <div className="col-span-12 sm:col-span-6 lg:col-span-4">
        <Skeleton
          variant="rounded"
          className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
        />
      </div>

      <div className="col-span-12 sm:col-span-6 lg:col-span-4">
        <Skeleton
          variant="rounded"
          className="w-full h-10 rounded relative z-0 min-w-full flex justify-center items-center"
        />
      </div>
    </div>
  )
}